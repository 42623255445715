import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import Driver from 'driver.js';
import { CookieService } from '@ngx-toolkit/cookie';
import { take } from 'rxjs/operators';
import { AvaliacaoApiService } from 'app/api/avaliacaoApi.service';
import { LucorApiModelsAvaliacao } from 'app/model/lucorApiModelsAvaliacao';

@Component({
  selector: 'app-avaliacoes-estatisticas',
  templateUrl: './avaliacoes-estatisticas.component.html',
  styleUrls: ['./avaliacoes-estatisticas.component.css']
})
export class AvaliacoesEstatisticasComponent implements OnInit {

  avaliacoes: LucorApiModelsAvaliacao[];
  avaliacoesDetalhes: LucorApiModelsAvaliacao;
  idAvaliacao: number;
  avaliadosIds: Array<any> = new Array<any>();
  avaliadosPadraoExterno: Array<any> = new Array<any>();
  avaliadosPadraoInterno: Array<any> = new Array<any>();

  @Output() selectAllChecked = new EventEmitter();

  sexoMasculinoCount: number;
  sexoFemininoCount: number;
  sexoTotalCount: number = 0;

  checkCookie = false;
  disableTour = false;
  driver = new Driver({
    className: 'tour-lucor',
    doneBtnText: 'Entendi', // Text on the final button
    closeBtnText: 'Fechar', // Text on the close button for this step
    nextBtnText: 'Próximo', // Next button text for this step
    prevBtnText: 'Anterior', // Previous button text for this step
  });

  // Comportamento Observado

  // Dominância
  countDomNatAlta: Array<any> = new Array<any>();
  countDomNatBaixa: Array<any> = new Array<any>();
  countDomAdaAlta: Array<any> = new Array<any>();
  countDomAdaBaixa: Array<any> = new Array<any>();

  avaliadosDomNatAlta: string;
  avaliadosDomNatBaixa: string;
  avaliadosDomAdaAlta: string;
  avaliadosDomAdaBaixa: string;

  // Influência
  countInfNatAlta: Array<any> = new Array<any>();
  countInfNatBaixa: Array<any> = new Array<any>();
  countInfAdaAlta: Array<any> = new Array<any>();
  countInfAdaBaixa: Array<any> = new Array<any>();

  avaliadosInfNatAlta: string;
  avaliadosInfNatBaixa: string;
  avaliadosInfAdaAlta: string;
  avaliadosInfAdaBaixa: string;

  // Estabilidade
  countEstNatAlta: Array<any> = new Array<any>();
  countEstNatBaixa: Array<any> = new Array<any>();
  countEstAdaAlta: Array<any> = new Array<any>();
  countEstAdaBaixa: Array<any> = new Array<any>();

  avaliadosEstNatAlta: string;
  avaliadosEstNatBaixa: string;
  avaliadosEstAdaAlta: string;
  avaliadosEstAdaBaixa: string;

  // Cautela
  countCauNatAlta: Array<any> = new Array<any>();
  countCauNatBaixa: Array<any> = new Array<any>();
  countCauAdaAlta: Array<any> = new Array<any>();
  countCauAdaBaixa: Array<any> = new Array<any>();

  avaliadosCauNatAlta: string;
  avaliadosCauNatBaixa: string;
  avaliadosCauAdaAlta: string;
  avaliadosCauAdaBaixa: string;

  firstChartDataCom: Array<any> = new Array<any>();
  secondChartDataCom: Array<any> = new Array<any>();
  firstChartDataComAda: Array<any> = new Array<any>();
  secondChartDataComAda: Array<any> = new Array<any>();

  // Motivadores
  countEstMuitoAlta: Array<any> = new Array<any>();
  countEstAlta: Array<any> = new Array<any>();
  countEstMediana: Array<any> = new Array<any>();
  countEstBaixa: Array<any> = new Array<any>();
  countEstMuitoBaixa: Array<any> = new Array<any>();

  avaliadosEstMuitoAlta: string;
  avaliadosEstAlta: string;
  avaliadosEstMediana: string;
  avaliadosEstBaixa: string;
  avaliadosEstMuitoBaixa: string;

  countEcoMuitoAlta: Array<any> = new Array<any>();
  countEcoAlta: Array<any> = new Array<any>();
  countEcoMediana: Array<any> = new Array<any>();
  countEcoBaixa: Array<any> = new Array<any>();
  countEcoMuitoBaixa: Array<any> = new Array<any>();

  avaliadosEcoMuitoAlta: string;
  avaliadosEcoAlta: string;
  avaliadosEcoMediana: string;
  avaliadosEcoBaixa: string;
  avaliadosEcoMuitoBaixa: string;

  countIndMuitoAlta: Array<any> = new Array<any>();
  countIndAlta: Array<any> = new Array<any>();
  countIndMediana: Array<any> = new Array<any>();
  countIndBaixa: Array<any> = new Array<any>();
  countIndMuitoBaixa: Array<any> = new Array<any>();

  avaliadosIndMuitoAlta: string;
  avaliadosIndAlta: string;
  avaliadosIndMediana: string;
  avaliadosIndBaixa: string;
  avaliadosIndMuitoBaixa: string;

  countPolMuitoAlta: Array<any> = new Array<any>();
  countPolAlta: Array<any> = new Array<any>();
  countPolMediana: Array<any> = new Array<any>();
  countPolBaixa: Array<any> = new Array<any>();
  countPolMuitoBaixa: Array<any> = new Array<any>();

  avaliadosPolMuitoAlta: string;
  avaliadosPolAlta: string;
  avaliadosPolMediana: string;
  avaliadosPolBaixa: string;
  avaliadosPolMuitoBaixa: string;

  countAltMuitoAlta: Array<any> = new Array<any>();
  countAltAlta: Array<any> = new Array<any>();
  countAltMediana: Array<any> = new Array<any>();
  countAltBaixa: Array<any> = new Array<any>();
  countAltMuitoBaixa: Array<any> = new Array<any>();

  avaliadosAltMuitoAlta: string;
  avaliadosAltAlta: string;
  avaliadosAltMediana: string;
  avaliadosAltBaixa: string;
  avaliadosAltMuitoBaixa: string;

  countRegMuitoAlta: Array<any> = new Array<any>();
  countRegAlta: Array<any> = new Array<any>();
  countRegMediana: Array<any> = new Array<any>();
  countRegBaixa: Array<any> = new Array<any>();
  countRegMuitoBaixa: Array<any> = new Array<any>();

  avaliadosRegMuitoAlta: string;
  avaliadosRegAlta: string;
  avaliadosRegMediana: string;
  avaliadosRegBaixa: string;
  avaliadosRegMuitoBaixa: string;

  countTeoMuitoAlta: Array<any> = new Array<any>();
  countTeoAlta: Array<any> = new Array<any>();
  countTeoMediana: Array<any> = new Array<any>();
  countTeoBaixa: Array<any> = new Array<any>();
  countTeoMuitoBaixa: Array<any> = new Array<any>();

  avaliadosTeoMuitoAlta: string;
  avaliadosTeoAlta: string;
  avaliadosTeoMediana: string;
  avaliadosTeoBaixa: string;
  avaliadosTeoMuitoBaixa: string;

  firstChartDataMot: Array<any> = new Array<any>();
  secondChartDataMot: Array<any> = new Array<any>();
  thirdChartDataMot: Array<any> = new Array<any>();
  fourthChartDataMot: Array<any> = new Array<any>();
  fifthChartDataMot: Array<any> = new Array<any>();

  // Axiologia - Ranking
  labelNomeAvaliado: Array<any> = new Array<any>(); // Label dos Gráficos
  chartDataAxiFatores: Array<any> = new Array<any>();
  chartDataAxiRanking: Array<any> = new Array<any>();

  // Axiologia - Balanço Dimensional - Empatia
  countEmpatiaAlta: Array<any> = new Array<any>();
  countEmpatiaMediana: Array<any> = new Array<any>();
  countEmpatiaBaixa: Array<any> = new Array<any>();
  firstChartDataEmpatia: Array<any> = new Array<any>();

  // Axiologia - Balanço Dimensional - Pensamento Prático
  countPensamentoAlta: Array<any> = new Array<any>();
  countPensamentoMediana: Array<any> = new Array<any>();
  countPensamentoBaixa: Array<any> = new Array<any>();
  firstChartDataPensamento: Array<any> = new Array<any>();

  // Axiologia - Balanço Dimensional - Julgamento de Sistemas
  countJulgamentoAlta: Array<any> = new Array<any>();
  countJulgamentoMediana: Array<any> = new Array<any>();
  countJulgamentoBaixa: Array<any> = new Array<any>();
  firstChartDataJulgamento: Array<any> = new Array<any>();

  // Axiologia - Balanço Dimensional - Autoestima
  countAutoestimaAlta: Array<any> = new Array<any>();
  countAutoestimaMediana: Array<any> = new Array<any>();
  countAutoestimaBaixa: Array<any> = new Array<any>();
  firstChartDataAutoestima: Array<any> = new Array<any>();

  // Axiologia - Balanço Dimensional - Consciência de Papéis
  countConscienciaAlta: Array<any> = new Array<any>();
  countConscienciaMediana: Array<any> = new Array<any>();
  countConscienciaBaixa: Array<any> = new Array<any>();
  firstChartDataConsciencia: Array<any> = new Array<any>();

  // Axiologia - Balanço Dimensional - Autodireção
  countAutodirecaoAlta: Array<any> = new Array<any>();
  countAutodirecaoMediana: Array<any> = new Array<any>();
  countAutodirecaoBaixa: Array<any> = new Array<any>();
  firstChartDataAutodirecao: Array<any> = new Array<any>();

  countSinal: Array<any> = new Array<any>();
  // Tendência Emocional Externa
  tendenciaExterna: Array<any> = new Array<any>();
  countEmpatiaSinal: Array<any> = new Array<any>();
  countPensamentoSinal: Array<any> = new Array<any>();
  countJulgamentoSinal: Array<any> = new Array<any>();

  // Tendência Emocional Interna
  tendenciaInterna: Array<any> = new Array<any>();
  countAutoestimaSinal: Array<any> = new Array<any>();
  countConscienciaSinal: Array<any> = new Array<any>();
  countAutodirecaoSinal: Array<any> = new Array<any>();

  // Padrões Externos
  padraoExterno: Array<any> = new Array<any>();

  countPessimista: Array<any> = new Array<any>();
  countVigilante: Array<any> = new Array<any>();
  countReativo: Array<any> = new Array<any>();
  countComerciante: Array<any> = new Array<any>();
  countConformista: Array<any> = new Array<any>();
  countOperador: Array<any> = new Array<any>();
  countCetico: Array<any> = new Array<any>();
  countCientifico: Array<any> = new Array<any>();
  countJornalista: Array<any> = new Array<any>();
  countAcolhedor: Array<any> = new Array<any>();
  countReformador: Array<any> = new Array<any>();
  countCompassivo: Array<any> = new Array<any>();
  countFacilitador: Array<any> = new Array<any>();
  countColaborador: Array<any> = new Array<any>();
  countIndependente: Array<any> = new Array<any>();
  countJuiz: Array<any> = new Array<any>();
  countHumorista: Array<any> = new Array<any>();
  countConsultor: Array<any> = new Array<any>();
  countOpositor: Array<any> = new Array<any>();
  countVirtuoso: Array<any> = new Array<any>();
  firstChartDataExterno: Array<any> = new Array<any>();

  // Padrões Internos
  padraoInterno: Array<any> = new Array<any>();

  countNegativo: Array<any> = new Array<any>();
  countDirigido: Array<any> = new Array<any>();
  countPassivo: Array<any> = new Array<any>();
  countInfluenciavel: Array<any> = new Array<any>();
  countCompulsivo: Array<any> = new Array<any>();
  countTrabalhador: Array<any> = new Array<any>();
  countRelutante: Array<any> = new Array<any>();
  countAssertivo: Array<any> = new Array<any>();
  countIntrospectivo: Array<any> = new Array<any>();
  countCentrado: Array<any> = new Array<any>();
  countDedicado: Array<any> = new Array<any>();
  countIndulgente: Array<any> = new Array<any>();
  countElevado: Array<any> = new Array<any>();
  countSensivel: Array<any> = new Array<any>();
  countDinamico: Array<any> = new Array<any>();
  countExpressivo: Array<any> = new Array<any>();
  countDependente: Array<any> = new Array<any>();
  countDiscriminador: Array<any> = new Array<any>();
  countFlexivel: Array<any> = new Array<any>();
  countAberto: Array<any> = new Array<any>();

  firstChartDataInterno: Array<any> = new Array<any>();

  @Output() mostraEstatisticas = new EventEmitter();

  // Gráfico Disc Natural
  public chartTypeDiscNat = 'bar';

  public chartDatasetsDiscNat: Array<any> = [
    { data: [0, 0, 0, 0, 0], label: 'Alta' },
    { data: [0, 0, 0, 0, 0], label: 'Baixa' }
  ];

  public chartLabelsDiscNat: Array<any> = [
    'Dominância', 'Influência', 'Estabilidade', 'Cautela'];

  public chartColorsDiscNat: Array<any> = [
    {
      borderColor: [],
      backgroundColor: [
        'rgba(29,233,182, 1)',
        'rgba(29,233,182, 1)',
        'rgba(29,233,182, 1)',
        'rgba(29,233,182, 1)',
        ],
      borderWidth: 0,
    },
    {
      borderColor: [],
      backgroundColor: [
        'rgba(245, 91, 71, 1)',
        'rgba(245, 91, 71, 1)',
        'rgba(245, 91, 71, 1)',
        'rgba(245, 91, 71, 1)',
        ],
      borderWidth: 0,
    }
  ];

  maxTicks = 0;

  public chartOptionsDiscNat: any = {
    responsive: true,
    scales: {
      yAxes: [{
          ticks: {
            max: this.maxTicks,
            beginAtZero: true,
            stepSize: 1
          }
      }]
    }
  };

   // Gráfico Disc Adaptável
   public chartTypeDiscAda = 'bar';

   public chartDatasetsDiscAda: Array<any> = [
     { data: [0, 0, 0, 0, 0], label: 'Alta' },
     { data: [0, 0, 0, 0, 0], label: 'Baixa' }
   ];

   public chartLabelsDiscAda: Array<any> = [
     'Dominância', 'Influência', 'Estabilidade', 'Cautela'];

   public chartColorsDiscAda: Array<any> = [
    {
      borderColor: [],
      backgroundColor: [
        'rgba(29,233,182, 1)',
        'rgba(29,233,182, 1)',
        'rgba(29,233,182, 1)',
        'rgba(29,233,182, 1)',
        ],
      borderWidth: 0,
    },
    {
      borderColor: [],
      backgroundColor: [
        'rgba(245, 91, 71, 1)',
        'rgba(245, 91, 71, 1)',
        'rgba(245, 91, 71, 1)',
        'rgba(245, 91, 71, 1)',
        ],
      borderWidth: 0,
    }
   ];

   public chartOptionsDiscAda: any = {
     responsive: true
   };

  public chartTypeMot = 'bar';

  public chartDatasetsMot: Array<any> = [];

  public chartLabelsMot: Array<any> = [
      'Estética', 'Econômico', 'Individualista', 'Político', 'Altruísta', 'Regulador', 'Teórico'];

  public chartColorsMot: Array<any> = [
     {
       borderColor: [],
       backgroundColor: [
        'rgba(5,255,18, 1)',
        'rgba(5,255,18, 1)',
        'rgba(5,255,18, 1)',
        'rgba(5,255,18, 1)',
        'rgba(5,255,18, 1)',
        'rgba(5,255,18, 1)',
        'rgba(5,255,18, 1)',
         ],
       borderWidth: 0,
     },
     {
       borderColor: [],
       backgroundColor: [
        'rgba(29,233,182, 1)',
        'rgba(29,233,182, 1)',
        'rgba(29,233,182, 1)',
        'rgba(29,233,182, 1)',
        'rgba(29,233,182, 1)',
        'rgba(29,233,182, 1)',
        'rgba(29,233,182, 1)',
         ],
       borderWidth: 0,
     },
     {
      borderColor: [],
      backgroundColor: [
       'rgba(255,234,0, 1)',
       'rgba(255,234,0, 1)',
       'rgba(255,234,0, 1)',
       'rgba(255,234,0, 1)',
       'rgba(255,234,0, 1)',
       'rgba(255,234,0, 1)',
       'rgba(255,234,0, 1)',
        ],
      borderWidth: 0,
    },
    {
      borderColor: [],
      backgroundColor: [
       'rgba(245, 91, 71, 1)',
       'rgba(245, 91, 71, 1)',
       'rgba(245, 91, 71, 1)',
       'rgba(245, 91, 71, 1)',
       'rgba(245, 91, 71, 1)',
       'rgba(245, 91, 71, 1)',
       'rgba(245, 91, 71, 1)',
        ],
      borderWidth: 0,
    },
    {
      borderColor: [],
      backgroundColor: [
       'rgba(217, 33, 9, 1)',
       'rgba(217, 33, 9, 1)',
       'rgba(217, 33, 9, 1)',
       'rgba(217, 33, 9, 1)',
       'rgba(217, 33, 9, 1)',
       'rgba(217, 33, 9, 1)',
       'rgba(217, 33, 9, 1)',
        ],
      borderWidth: 0,
    }
  ];

  public chartOptionsMot: any = {
      responsive: true
  };

  // Gráfico Axiologia - Ranking
  public chartTypeAxiRanking = 'bar';

  public chartDatasetsAxiRanking: Array<any> = [];

  public chartLabelsAxiRanking: Array<any> =
    this.labelNomeAvaliado;

  public chartColorsAxiRanking: Array<any> = [
    {
      borderColor: [],
      backgroundColor: 'rgba(105, 0, 132, 1)',
      borderWidth: 0,
    },
  ];

  public chartOptionsAxiRanking: any = {
    responsive: true,
    scales: {
      yAxes: [{
          ticks: {
            max: 100,
            beginAtZero: true,
            stepSize: 10
          }
      }]
    }
  };


  // Gráfico Balanço Dimensional - Empatia
  public chartTypeEmpatia = 'bar';

  public chartDatasetsEmpatia: Array<any> = [
     { data: [0, 0, 0], label: 'Empatia' },

  ];

  public chartLabelsEmpatia: Array<any> = [
     'Alta', 'Mediana', 'Baixa'];

  public chartColorsEmpatia: Array<any> = [
    {
      borderColor: [],
      backgroundColor: [
        'rgba(29,233,182, 1)',
        'rgba(255,234,0, 1)',
        'rgba(245, 91, 71, 1)',
        ],
      borderWidth: 0,
    }
  ];

  public chartOptionsEmpatia: any = {
     responsive: true
  };

  // Gráfico Balanço Dimensional - Pensamento Prático
  public chartTypePensamento = 'bar';

  public chartDatasetsPensamento: Array<any> = [
    { data: [0, 0, 0], label: 'Pensamento Prático' },

  ];

  public chartLabelsPensamento: Array<any> = [
      'Alta', 'Mediana', 'Baixa'];

  public chartColorsPensamento: Array<any> = [
     {
       borderColor: [],
       backgroundColor: [
         'rgba(29,233,182, 1)',
         'rgba(255,234,0, 1)',
         'rgba(245, 91, 71, 1)',
         ],
       borderWidth: 0,
     }
  ];

  public chartOptionsPensamento: any = {
      responsive: true
  };


  // Gráfico Balanço Dimensional - Julgamento de Sistemas
  public chartTypeJulgamento = 'bar';

  public chartDatasetsJulgamento: Array<any> = [
      { data: [0, 0, 0], label: 'Julgamento de Sistemas' },
  ];

  public chartLabelsJulgamento: Array<any> = [
      'Alta', 'Mediana', 'Baixa'];

  public chartColorsJulgamento: Array<any> = [
     {
       borderColor: [],
       backgroundColor: [
         'rgba(29,233,182, 1)',
         'rgba(255,234,0, 1)',
         'rgba(245, 91, 71, 1)',
         ],
       borderWidth: 0,
     }
  ];

  public chartOptionsJulgamento: any = {
      responsive: true
  };


  // Padrão Externo
  public chartTypeExterno = 'bar';

  public chartDatasetsExterno: Array<any> = [];

  public chartLabelsExterno: Array<any> = [];

  public chartColorsExterno: Array<any> = [
     {
       borderColor: [],
       backgroundColor: [
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)'
         ],
       borderWidth: 0,
     }
  ];

  public chartOptionsExterno: any = {
      responsive: true,
      scales: {
        yAxes: [{
            ticks: {
              beginAtZero: true,
              stepSize: 10
            }
        }]
      }
  };


  // Gráfico Balanço Dimensional - Autoestima
  public chartTypeAutoestima = 'bar';

  public chartDatasetsAutoestima: Array<any> = [
    { data: [0, 0, 0], label: 'Autoestima' },

  ];

  public chartLabelsAutoestima: Array<any> = [
         'Alta', 'Mediana', 'Baixa'];

  public chartColorsAutoestima: Array<any> = [
        {
          borderColor: [],
          backgroundColor: [
            'rgba(29,233,182, 1)',
            'rgba(255,234,0, 1)',
            'rgba(245, 91, 71, 1)',
            ],
          borderWidth: 0,
        }
  ];

  public chartOptionsAutoestima: any = {
         responsive: true
  };

  // Gráfico Balanço Dimensional - Consciência de Papéis
  public chartTypeConsciencia = 'bar';

  public chartDatasetsConsciencia: Array<any> = [
   { data: [0, 0, 0], label: 'Consciência de Papéis' },
  ];

  public chartLabelsConsciencia: Array<any> = [
         'Alta', 'Mediana', 'Baixa'];

  public chartColorsConsciencia: Array<any> = [
        {
          borderColor: [],
          backgroundColor: [
            'rgba(29,233,182, 1)',
            'rgba(255,234,0, 1)',
            'rgba(245, 91, 71, 1)',
            ],
          borderWidth: 0,
        }
  ];

  public chartOptionsConsciencia: any = {
     responsive: true
  };

  // Gráfico Balanço Dimensional - Autodireção
  public chartTypeAutodirecao = 'bar';

  public chartDatasetsAutodirecao: Array<any> = [
   { data: [0, 0, 0], label: 'Autodirecao' },
  ];

  public chartLabelsAutodirecao: Array<any> = [
         'Alta', 'Mediana', 'Baixa'];

  public chartColorsAutodirecao: Array<any> = [
        {
          borderColor: [],
          backgroundColor: [
            'rgba(29,233,182, 1)',
            'rgba(255,234,0, 1)',
            'rgba(245, 91, 71, 1)',
            ],
          borderWidth: 0,
        }
  ];

  public chartOptionsAutodirecao: any = {
         responsive: true
  };

  // Padrão Interno
  public chartTypeInterno = 'bar';

  public chartDatasetsInterno: Array<any> = [];

  public chartLabelsInterno: Array<any> = [];

  public chartColorsInterno: Array<any> = [
     {
       borderColor: [],
       backgroundColor: [
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)',
        'rgba(0,230,118, 1)'
         ],
       borderWidth: 0,
     }
  ];

  public chartOptionsInterno: any = {
      responsive: true,
      scales: {
        yAxes: [{
            ticks: {
              beginAtZero: true,
              stepSize: 10
            }
        }]
      }
  };


  /*
  * -- comum aos gráficos
  */
  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }


  constructor(
    private avaliacoesService: AvaliacaoApiService,
    private location: Location,
    private route: ActivatedRoute,
    private cookieService: CookieService
  ) {
    this.idAvaliacao = +this.route.snapshot.paramMap.get('Id');
  }

  ngOnInit() {
    this.goTop();
    this.getAvaliacaoById();
    this.checkCookie = this.cookieService.hasItem('tourAvaGrupo');
  }

  startTour(): void {
    this.disableTour = true;
    // Define the steps for tour
    setTimeout(() => {
      this.driver.defineSteps([
        {
          element: '#one-element-tour',
          popover: {
            className: 'first-step-popover-class',
            title: 'Vamos fazer um tour?',
            description: 'Você pode usar as teclas <i class="fas fa-caret-left icon-tour"></i> e <i class="fas fa-caret-right icon-tour"></i> para avançar e voltar. Acesse aqui sempre que precisar.',
            position: 'left',
            closeBtnText: 'Eu vejo depois.',
            nextBtnText: 'Vamos lá!',
            prevBtnText: '<i class="fas fa-plane-departure"></i>'
          }
        },
        {
          element: '#two-element-tour',
          popover: {
            title: 'Status da Avaliação',
            description: 'Mostra a data de atualização da avaliação e quantas pessoas ela contém.',
            position: 'bottom'
          }
        },
        {
          element: '#three-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Comportamento Observado',
            description: 'Esta ciência nos permite conhecer o <strong>estilo comportamental</strong> de cada respondente no seu <strong>estilo natural</strong> (inconsciente) e no seu <strong>estilo adaptável</strong> (consciente).<br>Você pode ver mais detalhes sobre esta ciência indo até o menu, na aba "Ciências".',
            position: 'bottom'
          }
        },
        {
          element: '#four-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Estilo Natural',
            description: 'É o estilo comportamental inconsciente.',
            position: 'bottom'
          }
        },
        {
          element: '#five-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Itens da tabela',
            description: 'A tabela lista os avaliados e suas respectivas médias de acordo com cada fator da ciênca.<br>Você pode clicar no nome da pessoa para ver o perfil individual completo.',
            position: 'bottom'
          }
        },
        {
          element: '#six-element-tour',
          popover: {
            title: 'Média númerica do fator',
            description: 'Este campo mostra a média relacionada ao fator indicado na coluna.',
            position: 'right'
          }
        },
        {
          element: '#seven-element-tour',
          popover: {
            title: 'Classificação',
            description: 'Esta é a classificação baseada na média. Para esta ciência a classificação pode ser "Alta ou Baixa"',
            position: 'right'
          }
        },
        {
          element: '#eight-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Visão Geral',
            description: 'Esta área mostra os dados deste grupo através de gráficos nos dois estilos comportamentais.',
            position: 'top'
          }
        },
        {
          element: '#nine-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Motivadores',
            description: 'Esta ciência nos permite conhecer o que gera motivação em cada respondente. Aqui é o comportamento <strong>semi-observável</strong>.<br>Você pode ver mais detalhes sobre esta ciência indo até o menu, na aba "Ciências".',
            position: 'bottom'
          }
        },
        {
          element: '#ten-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Legendas',
            description: 'Estas legendas indicam a classificação de acordo com a média. Para esta ciência as classificações se dividem em: "Muito Alta, Alta, Mediana, Baixa e Muito Baixa".',
            position: 'bottom'
          }
        },
        {
          element: '#eleven-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Itens da tabela',
            description: 'A tabela lista os avaliados e suas respectivas médias de acordo com cada fator da ciênca.<br>Você pode clicar no nome da pessoa para ver o perfil individual completo.',
            position: 'bottom'
          }
        },
        {
          element: '#twelve-element-tour',
          popover: {
            title: 'Média númerica do fator',
            description: 'Este campo mostra a média relacionada ao fator indicado na coluna.',
            position: 'right'
          }
        },
        {
          element: '#thirteen-element-tour',
          popover: {
            title: 'Classificação',
            description: 'Esta é a classificação com base na média do respectivo fator. O significado da cor pode ser verificada na legenda acima da tabela.',
            position: 'right'
          }
        },
        {
          element: '#fourteen-element-tour',
          popover: {
            title: 'Visão do Grupo',
            description: 'Exibe, através do gráfico, a composição do grupo de acordo com as médias de cada fator.',
            position: 'top'
          }
        }
        ,
        {
          element: '#fifteen-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Axiologia',
            description: 'É a ciência do estudo de como as pessoas pensam. Como comparam e determinam valor para decisões e indivíduos.<br>Você pode ver mais detalhes sobre esta ciência indo até o menu, na aba "Ciências".',
            position: 'bottom'
          }
        },
        {
          element: '#sixteen-element-tour',
          popover: {
            title: 'Ranking',
            description: 'Este ranking é calculado pelas médias de todas as competências de cada avaliado.',
            position: 'top'
          }
        },
        {
          element: '#seventeen-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Balanço Dimensional',
            description: 'O nível de desenvolvimento fala da capacidade de ver uma dimensão específica, assim, quanto mais claramente vemos uma dimensão do pensamento, mais capazes somos de usá-la e, portanto, melhores seremos nela.',
            position: 'top'
          }
        },
        {
          element: '#eighteen-element-tour',
          popover: {
            title: 'Tabela de classificação',
            description: 'Esta é a classificação no Padrão Externo, que tem como base os fatores "Empatia, Pensamento Prático e Julgamento de Sistemas".',
            position: 'top'
          }
        },
        {
          element: '#nineteen-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Visão do Grupo',
            description: 'Visão geral da classificação do grupo dentro dos três fatores.',
            position: 'top'
          }
        },
        {
          element: '#twenty-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Tendência Emocional Externa',
            description: 'O <strong>temperamento externo</strong> é o grau de otimismo ou pessimismo com o qual tendemos a ver o mundo ao redor. Cada um de nós pode escolher ver as coisas por uma ótica excessivamente positiva ou negativa, mas ter uma visão neutra é preferencial.',
            position: 'top'
          }
        },
        {
          element: '#twenty-one-element-tour',
          popover: {
            title: 'Gráfico',
            description: 'Composição do grupo de acordo com os padrões externos.',
            position: 'top'
          }
        },
        {
          element: '#twenty-two-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Padrão Externo',
            description: 'Como a pessoa vê o mundo ao redor. Clique no padrão para ver a descrição e no nome para ver o perfil completo do avaliado.',
            position: 'top'
          }
        },
        {
          element: '#twenty-four-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Balanço Dimensional',
            description: 'O nível de desenvolvimento fala da capacidade de ver uma dimensão específica, assim, quanto mais claramente vemos uma dimensão do pensamento, mais capazes somos de usá-la e, portanto, melhor seremos nela.',
            position: 'top'
          }
        },
        {
          element: '#twenty-five-element-tour',
          popover: {
            title: 'Tabela de classificação',
            description: 'Esta é a classificação no Padrão Interno, que tem como base os fatores "Autoestima, Consciência de Papéis e Autodireção".',
            position: 'top'
          }
        },
        {
          element: '#twenty-six-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Visão do Grupo',
            description: 'Visão geral da classificação do grupo dentro dos três fatores.',
            position: 'top'
          }
        },
        {
          element: '#twenty-seven-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Tendência Emocional Interna',
            description: 'O temperamento interno é o grau de otimismo ou pessimismo com o qual tendemos a ver a nós mesmos e a nossa própria vida. Cada um de nós pode escolher ver as coisas por uma ótica excessivamente positiva ou negativa, porém ter uma visão neutra é preferencial.',
            position: 'top'
          }
        },
        {
          element: '#twenty-eight-element-tour',
          popover: {
            title: 'Gráfico',
            description: 'Composição do grupo de acordo com os padrões internos.',
            position: 'top'
          }
        },
        {
          element: '#twenty-nine-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Padrão Interno',
            description: 'Como a pessoa vê a sim mesma e a sua própria vida. Clique no padrão para ver a descrição e no nome para ver o perfil completo do avaliado.',
            position: 'top'
          }
        }
      ]);
      // Start the introduction
      this.driver.start();
      this.disableTour = false;
    }, 2000);
  }

  setCookie() {
    this.cookieService.setItem( 'tourAvaGrupo', '1' );
  }

  getAvaliacaoById(): void {
    this.avaliacoesService.getAvaliacaoByIdAsync(this.idAvaliacao)
    .pipe(take(1))
      .subscribe(
        avaliacoesDetalhes => {
          this.avaliacoesDetalhes = avaliacoesDetalhes;
          if (this.avaliacoesDetalhes) {
            this.getCountSexo();
            this.getEstatistica();
            this.getGraficoAxiRanking();
            this.getBalancoDimensional();
          }
          if (this.avaliacoesDetalhes && this.checkCookie === false) {
            this.startTour();
            this.setCookie();
          }
        });
  }

  getCountSexo() {
    const sexoMasculino = [];
    const sexoFeminino = [];
    const sexoTotal = [];
    this.avaliacoesDetalhes.avaliados.forEach(sexo => {
        if (sexo.sexo === 'Masculino') {
          sexoMasculino.push(sexo.sexo);
        }
        if (sexo.sexo === 'Feminino') {
          sexoFeminino.push(sexo.sexo);
        }
        if (sexo.sexo) {
          sexoTotal.push(sexo.sexo);
        }
    });

    this.sexoMasculinoCount = sexoMasculino.length;
    this.sexoFemininoCount = sexoFeminino.length;
    this.sexoTotalCount = sexoTotal.length;

    this.maxTicks = this.sexoTotalCount;

    this.chartOptionsDiscNat = {
      responsive: true,
      scales: {
       yAxes: [{
           ticks: {
             // max: this.maxTicks,
             beginAtZero: true,
             stepSize: 10
           }
       }]
     }
    };
    this.chartOptionsDiscAda = {
      responsive: true,
      scales: {
       yAxes: [{
           ticks: {
             // max: this.maxTicks,
             beginAtZero: true,
             stepSize: 10
           }
       }]
     }
    };
  }

  getEstatistica() {

    // Comportamento Observado - Variáveis para adicionar os avaliados
    let pushAvaliadosDomNatAlta = [];
    let pushAvaliadosDomNatBaixa = [];
    let pushAvaliadosDomAdaAlta = [];
    let pushAvaliadosDomAdaBaixa = [];

    let pushAvaliadosInfNatAlta = [];
    let pushAvaliadosInfNatBaixa = [];
    let pushAvaliadosInfAdaAlta = [];
    let pushAvaliadosInfAdaBaixa = [];

    let pushAvaliadosEstNatAlta = [];
    let pushAvaliadosEstNatBaixa = [];
    let pushAvaliadosEstAdaAlta = [];
    let pushAvaliadosEstAdaBaixa = [];

    let pushAvaliadosCauNatAlta = [];
    let pushAvaliadosCauNatBaixa = [];
    let pushAvaliadosCauAdaAlta = [];
    let pushAvaliadosCauAdaBaixa = [];

    // Motivadores - Variáveis para adiconar os avaliados
    let pushAvaliadosEstMuitoAlta = [];
    let pushAvaliadosEstAlta = [];
    let pushAvaliadosEstMediana = [];
    let pushAvaliadosEstBaixa = [];
    let pushAvaliadosEstMuitoBaixa = [];

    let pushAvaliadosEcoMuitoAlta = [];
    let pushAvaliadosEcoAlta = [];
    let pushAvaliadosEcoMediana = [];
    let pushAvaliadosEcoBaixa = [];
    let pushAvaliadosEcoMuitoBaixa = [];

    let pushAvaliadosIndMuitoAlta = [];
    let pushAvaliadosIndAlta = [];
    let pushAvaliadosIndMediana = [];
    let pushAvaliadosIndBaixa = [];
    let pushAvaliadosIndMuitoBaixa = [];

    let pushAvaliadosPolMuitoAlta = [];
    let pushAvaliadosPolAlta = [];
    let pushAvaliadosPolMediana = [];
    let pushAvaliadosPolBaixa = [];
    let pushAvaliadosPolMuitoBaixa = [];

    let pushAvaliadosAltMuitoAlta = [];
    let pushAvaliadosAltAlta = [];
    let pushAvaliadosAltMediana = [];
    let pushAvaliadosAltBaixa = [];
    let pushAvaliadosAltMuitoBaixa = [];

    let pushAvaliadosRegMuitoAlta = [];
    let pushAvaliadosRegAlta = [];
    let pushAvaliadosRegMediana = [];
    let pushAvaliadosRegBaixa = [];
    let pushAvaliadosRegMuitoBaixa = [];

    let pushAvaliadosTeoMuitoAlta = [];
    let pushAvaliadosTeoAlta = [];
    let pushAvaliadosTeoMediana = [];
    let pushAvaliadosTeoBaixa = [];
    let pushAvaliadosTeoMuitoBaixa = [];

      this.avaliacoesDetalhes.avaliados.forEach(avaliados => {
        avaliados.resultadosCiencias.forEach(resultadosCiencias => {
          /* --- Comportamento Observado --*/
          if (resultadosCiencias.ciencia === 'Comportamento Observado') {
            resultadosCiencias.resultados.forEach(resultados => {
              // Dominância Natural
              if (resultados.nomeFator === 'DISC NATURAL - Dominância') {
                if (resultados.classificacao === 'Alta') {
                  this.countDomNatAlta.push({ classificacao: resultados.classificacao });
                  pushAvaliadosDomNatAlta.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Baixa') {
                  this.countDomNatBaixa.push({ classificacao: resultados.classificacao });
                  pushAvaliadosDomNatBaixa.push(avaliados.nome);
                }
              }
              // Dominância Adaptável
              if (resultados.nomeFator === 'DISC ADAPTÁVEL - Dominância') {
                if (resultados.classificacao === 'Alta') {
                  this.countDomAdaAlta.push({ classificacao: resultados.classificacao });
                  pushAvaliadosDomAdaAlta.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Baixa') {
                  this.countDomAdaBaixa.push({ classificacao: resultados.classificacao });
                  pushAvaliadosDomAdaBaixa.push(avaliados.nome);
                }
              }

              // Influência Natural
              if (resultados.nomeFator === 'DISC NATURAL - Influência') {
                if (resultados.classificacao === 'Alta') {
                  this.countInfNatAlta.push({ classificacao: resultados.classificacao });
                  pushAvaliadosInfNatAlta.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Baixa') {
                  this.countInfNatBaixa.push({ classificacao: resultados.classificacao });
                  pushAvaliadosInfNatBaixa.push(avaliados.nome);
                }
              }
              // Influência Adaptável
              if (resultados.nomeFator === 'DISC ADAPTÁVEL - Influência') {
                if (resultados.classificacao === 'Alta') {
                  this.countInfAdaAlta.push({ classificacao: resultados.classificacao });
                  pushAvaliadosInfAdaAlta.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Baixa') {
                  this.countInfAdaBaixa.push({ classificacao: resultados.classificacao });
                  pushAvaliadosInfAdaBaixa.push(avaliados.nome);
                }
              }

              // Estabilidade Natural
              if (resultados.nomeFator === 'DISC NATURAL - Estabilidade') {
                if (resultados.classificacao === 'Alta') {
                  this.countEstNatAlta.push({ classificacao: resultados.classificacao });
                  pushAvaliadosEstNatAlta.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Baixa') {
                  this.countEstNatBaixa.push({ classificacao: resultados.classificacao });
                  pushAvaliadosEstNatBaixa.push(avaliados.nome);
                }
              }
              // Estabilidade Adaptável
              if (resultados.nomeFator === 'DISC ADAPTÁVEL - Estabilidade') {
                if (resultados.classificacao === 'Alta') {
                  this.countEstAdaAlta.push({ classificacao: resultados.classificacao });
                  pushAvaliadosEstAdaAlta.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Baixa') {
                  this.countEstAdaBaixa.push({ classificacao: resultados.classificacao });
                  pushAvaliadosEstAdaBaixa.push(avaliados.nome);
                }
              }

              // Cautela Natural
              if (resultados.nomeFator === 'DISC NATURAL - Cautela') {
                if (resultados.classificacao === 'Alta') {
                  this.countCauNatAlta.push({ classificacao: resultados.classificacao });
                  pushAvaliadosCauNatAlta.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Baixa') {
                  this.countCauNatBaixa.push({ classificacao: resultados.classificacao });
                  pushAvaliadosCauNatBaixa.push(avaliados.nome);
                }
              }
              // Cautela Adaptável
              if (resultados.nomeFator === 'DISC ADAPTÁVEL - Cautela') {
                if (resultados.classificacao === 'Alta') {
                  this.countCauAdaAlta.push({ classificacao: resultados.classificacao });
                  pushAvaliadosCauAdaAlta.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Baixa') {
                  this.countCauAdaBaixa.push({ classificacao: resultados.classificacao });
                  pushAvaliadosCauAdaBaixa.push(avaliados.nome);
                }
              }


            });
          } /* --- Comportamento Observado --*/

          if (resultadosCiencias.ciencia === 'Motivadores') {
            resultadosCiencias.resultados.forEach(resultados => {
              // Estética
              if (resultados.nomeFator === 'Estética') {
                if (resultados.classificacao === 'Muito Alta') {
                  this.countEstMuitoAlta.push({ classificacao: resultados.classificacao });
                  pushAvaliadosEstMuitoAlta.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Alta') {
                  this.countEstAlta.push({ classificacao: resultados.classificacao });
                  pushAvaliadosEstAlta.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Mediana') {
                  this.countEstMediana.push({ classificacao: resultados.classificacao });
                  pushAvaliadosEstMediana.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Baixa') {
                  this.countEstBaixa.push({ classificacao: resultados.classificacao });
                  pushAvaliadosEstBaixa.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Muito Baixa') {
                  this.countEstMuitoBaixa.push({ classificacao: resultados.classificacao });
                  pushAvaliadosEstMuitoBaixa.push(avaliados.nome);
                }
              }
              if (resultados.nomeFator === 'Econômico') {
                if (resultados.classificacao === 'Muito Alta') {
                  this.countEcoMuitoAlta.push({ classificacao: resultados.classificacao });
                  pushAvaliadosEcoMuitoAlta.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Alta') {
                  this.countEcoAlta.push({ classificacao: resultados.classificacao });
                  pushAvaliadosEcoAlta.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Mediana') {
                  this.countEcoMediana.push({ classificacao: resultados.classificacao });
                  pushAvaliadosEcoMediana.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Baixa') {
                  this.countEcoBaixa.push({ classificacao: resultados.classificacao });
                  pushAvaliadosEcoBaixa.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Muito Baixa') {
                  this.countEcoMuitoBaixa.push({ classificacao: resultados.classificacao });
                  pushAvaliadosEcoMuitoBaixa.push(avaliados.nome);
                }
              }
              if (resultados.nomeFator === 'Individualista') {
                if (resultados.classificacao === 'Muito Alta') {
                  this.countIndMuitoAlta.push({ classificacao: resultados.classificacao });
                  pushAvaliadosIndMuitoAlta.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Alta') {
                  this.countIndAlta.push({ classificacao: resultados.classificacao });
                  pushAvaliadosIndAlta.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Mediana') {
                  this.countIndMediana.push({ classificacao: resultados.classificacao });
                  pushAvaliadosIndMediana.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Baixa') {
                  this.countIndBaixa.push({ classificacao: resultados.classificacao });
                  pushAvaliadosIndBaixa.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Muito Baixa') {
                  this.countIndMuitoBaixa.push({ classificacao: resultados.classificacao });
                  pushAvaliadosIndMuitoBaixa.push(avaliados.nome);
                }
              }
              if (resultados.nomeFator === 'Político') {
                if (resultados.classificacao === 'Muito Alta') {
                  this.countPolMuitoAlta.push({ classificacao: resultados.classificacao });
                  pushAvaliadosPolMuitoAlta.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Alta') {
                  this.countPolAlta.push({ classificacao: resultados.classificacao });
                  pushAvaliadosPolAlta.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Mediana') {
                  this.countPolMediana.push({ classificacao: resultados.classificacao });
                  pushAvaliadosPolMediana.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Baixa') {
                  this.countPolBaixa.push({ classificacao: resultados.classificacao });
                  pushAvaliadosPolBaixa.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Muito Baixa') {
                  this.countPolMuitoBaixa.push({ classificacao: resultados.classificacao });
                  pushAvaliadosPolMuitoBaixa.push(avaliados.nome);
                }
              }
              if (resultados.nomeFator === 'Altruísta') {
                if (resultados.classificacao === 'Muito Alta') {
                  this.countAltMuitoAlta.push({ classificacao: resultados.classificacao });
                  pushAvaliadosAltMuitoAlta.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Alta') {
                  this.countAltAlta.push({ classificacao: resultados.classificacao });
                  pushAvaliadosAltAlta.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Mediana') {
                  this.countAltMediana.push({ classificacao: resultados.classificacao });
                  pushAvaliadosAltMediana.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Baixa') {
                  this.countAltBaixa.push({ classificacao: resultados.classificacao });
                  pushAvaliadosAltBaixa.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Muito Baixa') {
                  this.countAltMuitoBaixa.push({ classificacao: resultados.classificacao });
                  pushAvaliadosAltMuitoBaixa.push(avaliados.nome);
                }
              }
              if (resultados.nomeFator === 'Regulador') {
                if (resultados.classificacao === 'Muito Alta') {
                  this.countRegMuitoAlta.push({ classificacao: resultados.classificacao });
                  pushAvaliadosRegMuitoAlta.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Alta') {
                  this.countRegAlta.push({ classificacao: resultados.classificacao });
                  pushAvaliadosRegAlta.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Mediana') {
                  this.countRegMediana.push({ classificacao: resultados.classificacao });
                  pushAvaliadosRegMediana.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Baixa') {
                  this.countRegBaixa.push({ classificacao: resultados.classificacao });
                  pushAvaliadosRegBaixa.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Muito Baixa') {
                  this.countRegMuitoBaixa.push({ classificacao: resultados.classificacao });
                  pushAvaliadosRegMuitoBaixa.push(avaliados.nome);
                }
              }
              if (resultados.nomeFator === 'Teórico') {
                if (resultados.classificacao === 'Muito Alta') {
                  this.countTeoMuitoAlta.push({ classificacao: resultados.classificacao });
                  pushAvaliadosTeoMuitoAlta.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Alta') {
                  this.countTeoAlta.push({ classificacao: resultados.classificacao });
                  pushAvaliadosTeoAlta.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Mediana') {
                  this.countTeoMediana.push({ classificacao: resultados.classificacao });
                  pushAvaliadosTeoMediana.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Baixa') {
                  this.countTeoBaixa.push({ classificacao: resultados.classificacao });
                  pushAvaliadosTeoBaixa.push(avaliados.nome);
                }
                if (resultados.classificacao === 'Muito Baixa') {
                  this.countTeoMuitoBaixa.push({ classificacao: resultados.classificacao });
                  pushAvaliadosTeoMuitoBaixa.push(avaliados.nome);
                }
              }
            });
          }

        });
      });

      // Comportamento Observado
      // Dominânica Natural
      pushAvaliadosDomNatAlta = pushAvaliadosDomNatAlta.sort();
      this.avaliadosDomNatAlta = pushAvaliadosDomNatAlta.join('<br >');
      pushAvaliadosDomNatBaixa = pushAvaliadosDomNatBaixa.sort();
      this.avaliadosDomNatBaixa = pushAvaliadosDomNatBaixa.join('<br >');
      // Dominância Adapatada
      pushAvaliadosDomAdaAlta = pushAvaliadosDomAdaAlta.sort();
      this.avaliadosDomAdaAlta = pushAvaliadosDomAdaAlta.join('<br >');
      pushAvaliadosDomAdaBaixa = pushAvaliadosDomAdaBaixa.sort();
      this.avaliadosDomAdaBaixa = pushAvaliadosDomAdaBaixa.join('<br >');

      // Influência Natural
      pushAvaliadosInfNatAlta = pushAvaliadosInfNatAlta.sort();
      this.avaliadosInfNatAlta = pushAvaliadosInfNatAlta.join('<br >');
      pushAvaliadosInfNatBaixa = pushAvaliadosInfNatBaixa.sort();
      this.avaliadosInfNatBaixa = pushAvaliadosInfNatBaixa.join('<br >');
      // Influência Adapatada
      pushAvaliadosInfAdaAlta = pushAvaliadosInfAdaAlta.sort();
      this.avaliadosInfAdaAlta = pushAvaliadosInfAdaAlta.join('<br >');
      pushAvaliadosInfAdaBaixa = pushAvaliadosInfAdaBaixa.sort();
      this.avaliadosInfAdaBaixa = pushAvaliadosInfAdaBaixa.join('<br >');

      // Estabilidade Natural
      pushAvaliadosEstNatAlta = pushAvaliadosEstNatAlta.sort();
      this.avaliadosEstNatAlta = pushAvaliadosEstNatAlta.join('<br >');
      pushAvaliadosEstNatBaixa = pushAvaliadosEstNatBaixa.sort();
      this.avaliadosEstNatBaixa = pushAvaliadosEstNatBaixa.join('<br >');
      // Estabilidade Adapatada
      pushAvaliadosEstAdaAlta = pushAvaliadosEstAdaAlta.sort();
      this.avaliadosEstAdaAlta = pushAvaliadosEstAdaAlta.join('<br >');
      pushAvaliadosEstAdaBaixa = pushAvaliadosEstAdaBaixa.sort();
      this.avaliadosEstAdaBaixa = pushAvaliadosEstAdaBaixa.join('<br >');

      // Cautela Natural
      pushAvaliadosCauNatAlta = pushAvaliadosCauNatAlta.sort();
      this.avaliadosCauNatAlta = pushAvaliadosCauNatAlta.join('<br >');
      pushAvaliadosCauNatBaixa = pushAvaliadosCauNatBaixa.sort();
      this.avaliadosCauNatBaixa = pushAvaliadosCauNatBaixa.join('<br >');
      // Cautela Adapatada
      pushAvaliadosCauAdaAlta = pushAvaliadosCauAdaAlta.sort();
      this.avaliadosCauAdaAlta = pushAvaliadosCauAdaAlta.join('<br >');
      pushAvaliadosCauAdaBaixa = pushAvaliadosCauAdaBaixa.sort();
      this.avaliadosCauAdaBaixa = pushAvaliadosCauAdaBaixa.join('<br >');

      this.firstChartDataCom.push(this.countDomNatAlta.length);
      this.firstChartDataCom.push(this.countInfNatAlta.length);
      this.firstChartDataCom.push(this.countEstNatAlta.length);
      this.firstChartDataCom.push(this.countCauNatAlta.length);
      this.firstChartDataCom.push(0);

      this.secondChartDataCom.push(this.countDomNatBaixa.length);
      this.secondChartDataCom.push(this.countInfNatBaixa.length);
      this.secondChartDataCom.push(this.countEstNatBaixa.length);
      this.secondChartDataCom.push(this.countCauNatBaixa.length);
      this.secondChartDataCom.push(0);

      this.chartDatasetsDiscNat = [
        { data: this.firstChartDataCom, label: 'Alta' },
        { data: this.secondChartDataCom, label: 'Baixa' },
      ];

      this.firstChartDataComAda.push(this.countDomAdaAlta.length);
      this.firstChartDataComAda.push(this.countInfAdaAlta.length);
      this.firstChartDataComAda.push(this.countEstAdaAlta.length);
      this.firstChartDataComAda.push(this.countCauAdaAlta.length);
      this.firstChartDataComAda.push(0);

      this.secondChartDataComAda.push(this.countDomAdaBaixa.length);
      this.secondChartDataComAda.push(this.countInfAdaBaixa.length);
      this.secondChartDataComAda.push(this.countEstAdaBaixa.length);
      this.secondChartDataComAda.push(this.countCauAdaBaixa.length);
      this.secondChartDataComAda.push(0);

      this.chartDatasetsDiscAda = [
        { data: this.firstChartDataComAda, label: 'Alta' },
        { data: this.secondChartDataComAda, label: 'Baixa' },
      ];

      // Motivadores
      pushAvaliadosEstMuitoAlta = pushAvaliadosEstMuitoAlta.sort();
      this.avaliadosEstMuitoAlta = pushAvaliadosEstMuitoAlta.join('<br >');
      pushAvaliadosEstAlta = pushAvaliadosEstAlta.sort();
      this.avaliadosEstAlta = pushAvaliadosEstAlta.join('<br >');
      pushAvaliadosEstMediana = pushAvaliadosEstMediana.sort();
      this.avaliadosEstMediana = pushAvaliadosEstMediana.join('<br >');
      pushAvaliadosEstBaixa = pushAvaliadosEstBaixa.sort();
      this.avaliadosEstBaixa = pushAvaliadosEstBaixa.join('<br >');
      pushAvaliadosEstMuitoBaixa = pushAvaliadosEstMuitoBaixa.sort();
      this.avaliadosEstMuitoBaixa = pushAvaliadosEstMuitoBaixa.join('<br >');

      pushAvaliadosEcoMuitoAlta = pushAvaliadosEcoMuitoAlta.sort();
      this.avaliadosEcoMuitoAlta = pushAvaliadosEcoMuitoAlta.join('<br >');
      pushAvaliadosEcoAlta = pushAvaliadosEcoAlta.sort();
      this.avaliadosEcoAlta = pushAvaliadosEcoAlta.join('<br >');
      pushAvaliadosEcoMediana = pushAvaliadosEcoMediana.sort();
      this.avaliadosEcoMediana = pushAvaliadosEcoMediana.join('<br >');
      pushAvaliadosEcoBaixa = pushAvaliadosEcoBaixa.sort();
      this.avaliadosEcoBaixa = pushAvaliadosEcoBaixa.join('<br >');
      pushAvaliadosEcoMuitoBaixa = pushAvaliadosEcoMuitoBaixa.sort();
      this.avaliadosEcoMuitoBaixa = pushAvaliadosEcoMuitoBaixa.join('<br >');

      pushAvaliadosIndMuitoAlta = pushAvaliadosIndMuitoAlta.sort();
      this.avaliadosIndMuitoAlta = pushAvaliadosIndMuitoAlta.join('<br >');
      pushAvaliadosIndAlta = pushAvaliadosIndAlta.sort();
      this.avaliadosIndAlta = pushAvaliadosIndAlta.join('<br >');
      pushAvaliadosIndMediana = pushAvaliadosIndMediana.sort();
      this.avaliadosIndMediana = pushAvaliadosIndMediana.join('<br >');
      pushAvaliadosIndBaixa = pushAvaliadosIndBaixa.sort();
      this.avaliadosIndBaixa = pushAvaliadosIndBaixa.join('<br >');
      pushAvaliadosIndMuitoBaixa = pushAvaliadosIndMuitoBaixa.sort();
      this.avaliadosIndMuitoBaixa = pushAvaliadosIndMuitoBaixa.join('<br >');

      pushAvaliadosPolMuitoAlta = pushAvaliadosPolMuitoAlta.sort();
      this.avaliadosPolMuitoAlta = pushAvaliadosPolMuitoAlta.join('<br >');
      pushAvaliadosPolAlta = pushAvaliadosPolAlta.sort();
      this.avaliadosPolAlta = pushAvaliadosPolAlta.join('<br >');
      pushAvaliadosPolMediana = pushAvaliadosPolMediana.sort();
      this.avaliadosPolMediana = pushAvaliadosPolMediana.join('<br >');
      pushAvaliadosPolBaixa = pushAvaliadosPolBaixa.sort();
      this.avaliadosPolBaixa = pushAvaliadosPolBaixa.join('<br >');
      pushAvaliadosPolMuitoBaixa = pushAvaliadosPolMuitoBaixa.sort();
      this.avaliadosPolMuitoBaixa = pushAvaliadosPolMuitoBaixa.join('<br >');

      pushAvaliadosAltMuitoAlta = pushAvaliadosAltMuitoAlta.sort();
      this.avaliadosAltMuitoAlta = pushAvaliadosAltMuitoAlta.join('<br >');
      pushAvaliadosAltAlta = pushAvaliadosAltAlta.sort();
      this.avaliadosAltAlta = pushAvaliadosAltAlta.join('<br >');
      pushAvaliadosAltMediana = pushAvaliadosAltMediana.sort();
      this.avaliadosAltMediana = pushAvaliadosAltMediana.join('<br >');
      pushAvaliadosAltBaixa = pushAvaliadosAltBaixa.sort();
      this.avaliadosAltBaixa = pushAvaliadosAltBaixa.join('<br >');
      pushAvaliadosAltMuitoBaixa = pushAvaliadosAltMuitoBaixa.sort();
      this.avaliadosAltMuitoBaixa = pushAvaliadosAltMuitoBaixa.join('<br >');

      pushAvaliadosRegMuitoAlta = pushAvaliadosRegMuitoAlta.sort();
      this.avaliadosRegMuitoAlta = pushAvaliadosRegMuitoAlta.join('<br >');
      pushAvaliadosRegAlta = pushAvaliadosRegAlta.sort();
      this.avaliadosRegAlta = pushAvaliadosRegAlta.join('<br >');
      pushAvaliadosRegMediana = pushAvaliadosRegMediana.sort();
      this.avaliadosRegMediana = pushAvaliadosRegMediana.join('<br >');
      pushAvaliadosRegBaixa = pushAvaliadosRegBaixa.sort();
      this.avaliadosRegBaixa = pushAvaliadosRegBaixa.join('<br >');
      pushAvaliadosRegMuitoBaixa = pushAvaliadosRegMuitoBaixa.sort();
      this.avaliadosRegMuitoBaixa = pushAvaliadosRegMuitoBaixa.join('<br >');

      pushAvaliadosTeoMuitoAlta = pushAvaliadosTeoMuitoAlta.sort();
      this.avaliadosTeoMuitoAlta = pushAvaliadosTeoMuitoAlta.join('<br >');
      pushAvaliadosTeoAlta = pushAvaliadosTeoAlta.sort();
      this.avaliadosTeoAlta = pushAvaliadosTeoAlta.join('<br >');
      pushAvaliadosTeoMediana = pushAvaliadosTeoMediana.sort();
      this.avaliadosTeoMediana = pushAvaliadosTeoMediana.join('<br >');
      pushAvaliadosTeoBaixa = pushAvaliadosTeoBaixa.sort();
      this.avaliadosTeoBaixa = pushAvaliadosTeoBaixa.join('<br >');
      pushAvaliadosTeoMuitoBaixa = pushAvaliadosTeoMuitoBaixa.sort();
      this.avaliadosTeoMuitoBaixa = pushAvaliadosTeoMuitoBaixa.join('<br >');


      this.firstChartDataMot.push(this.countEstMuitoAlta.length);
      this.firstChartDataMot.push(this.countEcoMuitoAlta.length);
      this.firstChartDataMot.push(this.countIndMuitoAlta.length);
      this.firstChartDataMot.push(this.countPolMuitoAlta.length);
      this.firstChartDataMot.push(this.countAltMuitoAlta.length);
      this.firstChartDataMot.push(this.countRegMuitoAlta.length);
      this.firstChartDataMot.push(this.countTeoMuitoAlta.length);
      this.firstChartDataMot.push(0);

      this.secondChartDataMot.push(this.countEstAlta.length);
      this.secondChartDataMot.push(this.countEcoAlta.length);
      this.secondChartDataMot.push(this.countIndAlta.length);
      this.secondChartDataMot.push(this.countPolAlta.length);
      this.secondChartDataMot.push(this.countAltAlta.length);
      this.secondChartDataMot.push(this.countRegAlta.length);
      this.secondChartDataMot.push(this.countTeoAlta.length);
      this.secondChartDataMot.push(0);

      this.thirdChartDataMot.push(this.countEstMediana.length);
      this.thirdChartDataMot.push(this.countEcoMediana.length);
      this.thirdChartDataMot.push(this.countIndMediana.length);
      this.thirdChartDataMot.push(this.countPolMediana.length);
      this.thirdChartDataMot.push(this.countAltMediana.length);
      this.thirdChartDataMot.push(this.countRegMediana.length);
      this.thirdChartDataMot.push(this.countTeoMediana.length);
      this.thirdChartDataMot.push(0);

      this.fourthChartDataMot.push(this.countEstBaixa.length);
      this.fourthChartDataMot.push(this.countEcoBaixa.length);
      this.fourthChartDataMot.push(this.countIndBaixa.length);
      this.fourthChartDataMot.push(this.countPolBaixa.length);
      this.fourthChartDataMot.push(this.countAltBaixa.length);
      this.fourthChartDataMot.push(this.countRegBaixa.length);
      this.fourthChartDataMot.push(this.countTeoBaixa.length);
      this.fourthChartDataMot.push(0);

      this.fifthChartDataMot.push(this.countEstMuitoBaixa.length);
      this.fifthChartDataMot.push(this.countEcoMuitoBaixa.length);
      this.fifthChartDataMot.push(this.countIndMuitoBaixa.length);
      this.fifthChartDataMot.push(this.countPolMuitoBaixa.length);
      this.fifthChartDataMot.push(this.countAltMuitoBaixa.length);
      this.fifthChartDataMot.push(this.countRegMuitoBaixa.length);
      this.fifthChartDataMot.push(this.countTeoMuitoBaixa.length);
      this.fifthChartDataMot.push(0);

      this.chartDatasetsMot = [
        { data: this.firstChartDataMot, label: 'Muito Alta' },
        { data: this.secondChartDataMot, label: 'Alta' },
        { data: this.thirdChartDataMot, label: 'Mediana' },
        { data: this.fourthChartDataMot, label: 'Baixa' },
        { data: this.fifthChartDataMot, label: 'Muito Baixa' },
      ];

  
  }

  getGraficoAxiRanking() {

    let arrayLabel = [];
    let arrayData = [];
    let arrayOfObj;
    let sortedArrayOfObj;
    let newArrayLabel = [];
    let newArrayData = [];

      this.chartDataAxiRanking = []; // reinicia o array

      this.avaliacoesDetalhes.avaliados.forEach(avaliado => {
        avaliado.resultadosCiencias.forEach(ciencia => {
            if (ciencia.ciencia === 'Axiologia') {
              ciencia.resultados.forEach(fator => {
                if (fator.nomeFator === 'Média das Competências') {
                  this.labelNomeAvaliado.push(
                    avaliado.nome
                  );
                  this.chartDataAxiRanking.push(
                    fator.valor
                  );
                 }
               });
            }
          });
        });
  

    // =========================== Ordenar a exibição do Ranking


    arrayLabel = this.labelNomeAvaliado;
    arrayData = this.chartDataAxiRanking;

    arrayOfObj = arrayLabel.map((d, i) => {
      return {
        label: d,
        data: arrayData[i] || 0
      };
    });

    sortedArrayOfObj = arrayOfObj.sort((a, b) => {
      return  b.data - a.data;
    });

    newArrayLabel = [];
    newArrayData = [];
    sortedArrayOfObj.forEach((d) => {
      newArrayLabel.push(d.label);
      newArrayData.push(d.data);
    });

    // ===========================

    this.chartDataAxiRanking = newArrayData;
    this.chartLabelsAxiRanking = newArrayLabel;

    this.chartDataAxiRanking.push(0);

    this.chartDatasetsAxiRanking = [
      { data: this.chartDataAxiRanking, label: 'Média das Competências' }
    ];

  }

  getBalancoDimensional() {

    const contagemExterno: Array<any> = new Array<any>();
    const contagemInterno: Array<any> = new Array<any>();

      this.avaliacoesDetalhes.avaliados.forEach(avaliados => {

        this.avaliadosIds.push(avaliados.id);

        avaliados.resultadosCiencias.forEach(resultadosCiencias => {
          if (resultadosCiencias.ciencia === 'Balanço Dimensional') {
            resultadosCiencias.resultados.forEach(resultados => {


              if (resultados.fatorId === 97) {
                       this.countEmpatiaSinal.push({ avaliadoNome: avaliados.nome, avaliado: avaliados.id, sinal: resultados.sinal });
                    }
              if (resultados.fatorId === 98) {
                      this.countPensamentoSinal.push({ avaliadoNome: avaliados.nome, avaliado: avaliados.id, sinal: resultados.sinal });
                    }
              if (resultados.fatorId === 99) {
                      this.countJulgamentoSinal.push({ avaliadoNome: avaliados.nome, avaliado: avaliados.id, sinal: resultados.sinal });
                    }

              if (resultados.fatorId === 100) {
                      this.countAutoestimaSinal.push({ avaliadoNome: avaliados.nome, avaliado: avaliados.id, sinal: resultados.sinal });
                   }
              if (resultados.fatorId === 101) {
                     this.countConscienciaSinal.push({ avaliadoNome: avaliados.nome, avaliado: avaliados.id, sinal: resultados.sinal });
                   }
              if (resultados.fatorId === 102) {
                     this.countAutodirecaoSinal.push({ avaliadoNome: avaliados.nome, avaliado: avaliados.id, sinal: resultados.sinal });
                   }


              /*-- Externo --*/

              // Empatia
              if (resultados.fatorId === 97) {
                if (resultados.classificacao === 'Alta') {
                  this.countEmpatiaAlta.push({ avaliadoNome: avaliados.nome, classificacao: resultados.classificacao });
                }
                if (resultados.classificacao === 'Mediana') {
                  this.countEmpatiaMediana.push({ avaliadoNome: avaliados.nome, classificacao: resultados.classificacao });
                }
                if (resultados.classificacao === 'Baixa') {
                  this.countEmpatiaBaixa.push({ avaliadoNome: avaliados.nome, classificacao: resultados.classificacao });
                }
              }

              // Pensamento Prático
              if (resultados.fatorId === 98) {
                if (resultados.classificacao === 'Alta') {
                  this.countPensamentoAlta.push({ avaliadoNome: avaliados.nome, classificacao: resultados.classificacao });
                }
                if (resultados.classificacao === 'Mediana') {
                  this.countPensamentoMediana.push({ avaliadoNome: avaliados.nome, classificacao: resultados.classificacao });
                }
                if (resultados.classificacao === 'Baixa') {
                  this.countPensamentoBaixa.push({ avaliadoNome: avaliados.nome, classificacao: resultados.classificacao });
                }
              }

              // Julgamento de Sistemas
              if (resultados.fatorId === 99) {
                if (resultados.classificacao === 'Alta') {
                  this.countJulgamentoAlta.push({ avaliadoNome: avaliados.nome, classificacao: resultados.classificacao });
                }
                if (resultados.classificacao === 'Mediana') {
                  this.countJulgamentoMediana.push({ avaliadoNome: avaliados.nome, classificacao: resultados.classificacao });
                }
                if (resultados.classificacao === 'Baixa') {
                  this.countJulgamentoBaixa.push({ avaliadoNome: avaliados.nome, classificacao: resultados.classificacao });
                }
              }

              /*-- Interno --*/

              // Autoestima
              if (resultados.fatorId === 100) {
                if (resultados.classificacao === 'Alta') {
                  this.countAutoestimaAlta.push({ avaliadoNome: avaliados.nome, classificacao: resultados.classificacao });
                }
                if (resultados.classificacao === 'Mediana') {
                  this.countAutoestimaMediana.push({ avaliadoNome: avaliados.nome, classificacao: resultados.classificacao });
                }
                if (resultados.classificacao === 'Baixa') {
                  this.countAutoestimaBaixa.push({ avaliadoNome: avaliados.nome, classificacao: resultados.classificacao });
                }
              }

              // Consciência de Papéis
              if (resultados.fatorId === 101) {
                if (resultados.classificacao === 'Alta') {
                  this.countConscienciaAlta.push({ avaliadoNome: avaliados.nome, classificacao: resultados.classificacao });
                }
                if (resultados.classificacao === 'Mediana') {
                  this.countConscienciaMediana.push({ avaliadoNome: avaliados.nome, classificacao: resultados.classificacao });
                }
                if (resultados.classificacao === 'Baixa') {
                  this.countConscienciaBaixa.push({ avaliadoNome: avaliados.nome, classificacao: resultados.classificacao });
                }
              }

              // Autodireção
              if (resultados.fatorId === 102) {
                if (resultados.classificacao === 'Alta') {
                  this.countAutodirecaoAlta.push({ avaliadoNome: avaliados.nome, classificacao: resultados.classificacao });
                }
                if (resultados.classificacao === 'Mediana') {
                  this.countAutodirecaoMediana.push({ avaliadoNome: avaliados.nome, classificacao: resultados.classificacao });
                }
                if (resultados.classificacao === 'Baixa') {
                  this.countAutodirecaoBaixa.push({ avaliadoNome: avaliados.nome, classificacao: resultados.classificacao });
                }
              }
            });
          }
        });
      });

      contagemExterno.push(this.countEmpatiaSinal, this.countPensamentoSinal, this.countJulgamentoSinal);
      contagemInterno.push(this.countAutoestimaSinal, this.countConscienciaSinal, this.countAutodirecaoSinal);

      // Axiologia - Balanço Dimensional - Perspectiva Empática
      this.firstChartDataEmpatia.push(this.countEmpatiaAlta.length, this.countEmpatiaMediana.length, this.countEmpatiaBaixa.length);
      this.firstChartDataEmpatia.push(0);

      this.chartDatasetsEmpatia = [
        { data: this.firstChartDataEmpatia, label: 'Empatia' }
      ];

      // Axiologia - Balanço Dimensional - Pensamento Prático
      this.firstChartDataPensamento.push(this.countPensamentoAlta.length, this.countPensamentoMediana.length, this.countPensamentoBaixa.length);
      this.firstChartDataPensamento.push(0);

      this.chartDatasetsPensamento = [
        { data: this.firstChartDataPensamento, label: 'Pensamento Prático' }
      ];

      // Axiologia - Balanço Dimensional - Julgamento de Sistemas
      this.firstChartDataJulgamento.push(this.countJulgamentoAlta.length, this.countJulgamentoMediana.length, this.countJulgamentoBaixa.length);
      this.firstChartDataJulgamento.push(0);

      this.chartDatasetsJulgamento = [
        { data: this.firstChartDataJulgamento, label: 'Julgamento de Sistemas' }
      ];

      // Axiologia - Balanço Dimensional - Autoestima
      this.firstChartDataAutoestima.push(this.countAutoestimaAlta.length, this.countAutoestimaMediana.length, this.countAutoestimaBaixa.length);
      this.firstChartDataAutoestima.push(0);

      this.chartDatasetsAutoestima = [
        { data: this.firstChartDataAutoestima, label: 'Autoestima' }
      ];

      // Axiologia - Balanço Dimensional - Consciência de Papéis
      this.firstChartDataConsciencia.push(this.countConscienciaAlta.length, this.countConscienciaMediana.length, this.countConscienciaBaixa.length);
      this.firstChartDataConsciencia.push(0);

      this.chartDatasetsConsciencia = [
        { data: this.firstChartDataConsciencia, label: 'Consciência de Papéis' }
      ];

      // Axiologia - Balanço Dimensional - Autodireção
      this.firstChartDataAutodirecao.push(this.countAutodirecaoAlta.length, this.countAutodirecaoMediana.length, this.countAutodirecaoBaixa.length);
      this.firstChartDataAutodirecao.push(0);

      this.chartDatasetsAutodirecao = [
        { data: this.firstChartDataAutodirecao, label: 'Autodireção' }
      ];

    // Filtro para separar os avaliados e seus sinais (externo)
    contagemExterno.forEach(c => {
          let avaliadoIgual = false; // Inicia com false para criar o primeiro array
          if (c) {
            c.forEach(e => {
          this.tendenciaExterna.forEach(t => {
              if (this.tendenciaExterna && e.avaliado === t.avaliado) {
                t.tendencia.push(
                  e.sinal
                  );
                avaliadoIgual = true;
              }
            });
          if (!avaliadoIgual) {
              this.tendenciaExterna.push({
                avaliadoNome: e.avaliadoNome,
                avaliado: e.avaliado,
                tendencia: [e.sinal]
              });
            }
            });
          }
    });


    // Filtro para separar os avaliados e seus sinais (interno)
    contagemInterno.forEach(i => {
      let avaliadoIgual = false; // Inicia com false para criar o primeiro array
      if (i) {
        i.forEach(e => {
      this.tendenciaInterna.forEach(t => {
          if (this.tendenciaInterna && e.avaliado === t.avaliado) {
            t.tendencia.push(
              e.sinal
              );
            avaliadoIgual = true;
          }
        });
      if (!avaliadoIgual) {
          this.tendenciaInterna.push({
            avaliadoNome: e.avaliadoNome,
            avaliado: e.avaliado,
            tendencia: [e.sinal]
          });
        }
        });
      }
    });

    this.getPadraoExterno();
    this.getPadraoInterno();
  }

  getPadraoExterno() {
    const pessimista = ['-', '-', '-'];
    const vigilante = ['-', '-', '+'];
    const reativo = ['-', '-', '='];
    const comerciante = ['-', '+', '-'];
    const conformista = ['-', '+', '+'];
    const operador = ['-', '+', '='];
    const cetico = ['-', '=', '-'];
    const cientifico = ['-', '=', '+'];
    const jornalista = ['-', '=', '='];
    const acolhedor = ['+', '-', '-'];
    const reformador = ['+', '-', '+'];
    const compassivo = ['+', '-', '='];
    const facilitador = ['+', '+', '-'];
    const colaborador = ['+', '=', '-'];
    const independente = ['=', '-', '-'];
    const juiz = ['=', '-', '+'];
    const humorista = ['=', '-', '='];
    const consultor = ['=', '+', '-'];
    const opositor = ['=', '=', '-'];
    const virtuoso = ['=', '=', '='];

    if (this.tendenciaExterna) {
      this.tendenciaExterna.forEach(sinais => {
          // Comparação dos sinais para definir Padrão Externo
          if (JSON.stringify(pessimista) === JSON.stringify(sinais.tendencia)) {
            this.padraoExterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Pessimista',
              descricao: 'Seu temperamento externo sugere que você pode se tornar muito negativo em relação às emoções. Talvez você sinta que você tem uma falta de compreensão ou distância emocional em relação ao mundo ao seu redor (ou seja, outros, sociedade, etc.). Há uma visão pessimista de todas as três dimensões do pensamento neste exato momento em sua vida. A dimensão Pessoas é vista de forma negativa e isso pode indicar alguma relutância, medo ou pessimismo em relação aos outros. Talvez você defina uma distância emocional entre você e os demais sob o pretexto do profissionalismo. A dimensão Tarefas também é vista negativamente, o que revela uma distância semelhante da sociedade e uma provável falta de motivação por coisas materiais. A dimensão Sistemas também apresenta um foco pessimista e aponta para pontos de vista negativos em relação aos sistemas e a ordem estabelecidos. Isso até mesmo pode indicar certos níveis de rebeldia. Este foco geral expressa uma negatividade generalizada em relação ao mundo, o que pode resultar em respostas isoladas ou desfavoráveis a fatores externos como pessoas, tarefas ou sistemas.'
            });
          }
          if (JSON.stringify(vigilante) === JSON.stringify(sinais.tendencia)) {
            this.padraoExterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Vigilante',
              descricao: 'Seu temperamento mostra que você é uma pessoa que protege ou observa a fim de manter e preservar a ordem. Seu temperamento poderia ser interpretado como o “verdadeiro seguidor" porque você pode se tornar muito dogmático sobre a ordem ou estrutura as quais apoia. Em casos extremos, você pode até mesmo apresentar lealdade cega em relação a ordem preferida e, portanto, dependerá de suas regras para orientar seu próprio comportamento. A dimensão Pessoas é vista de forma negativa, o que demonstra alguma distância dos outros, ou problemas para compreendê-los e, possivelmente, até mesmo desconfiança sobre eles. A dimensão Tarefas também é vista negativamente, o que pode resultar em sentimentos de se sentir “fora do grupo” ou tendência a estar excessivamente focado em problemas dentro do mesmo. Já na dimensão Sistemas, a supervalorização das regras revela uma forte preferência por ordem ou estrutura externas. Em situações estressantes, você pode se tornar dogmático para que as coisas sejam feitas "do jeito certo", mesmo à custa de pessoas e suas necessidades ou perda de prazos objetivos. No entanto, você normalmente prefere ter planos que são claramente definidos e tem um forte desejo de cumprir todas as regras.'
             });
          }
          if (JSON.stringify(reativo) === JSON.stringify(sinais.tendencia)) {
            this.padraoExterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Reativo',
              descricao: 'Seu temperamento revela uma tendência para reagir à experiência externa referente ao sistema e a ordem. Devido as suas visões negativas sobre as dimensões Pessoas e Tarefas, você pode responder a percepção de autoridade de maneira mais forte. Você tem uma visão muito boa e equilibrada da dimensão Sistemas, sem vê-la de forma excessivamente positiva ou negativa. Seu foco nesta dimensão é genuíno, sincero e preciso. Como resultado, você tem uma compreensão clara e adequada para a necessidade de sistemas, não se tornando excessivamente dependente deles, mas também não se rebelando contra eles. Seu foco na dimensão Pessoas indica algum medo em aproximar-se demais dos outros, enquanto a dimensão Tarefas indica algum ceticismo sobre a convenção socialmente aceita. Em geral, você é alguém muito capaz de manter o controle de uma situação para alcançar seus objetivos, embora ter uma visão um pouco mais positiva das relações pessoais e a necessidade de prazos concretos, mesmo com o custo de fazer isso de maneira perfeita, pode ser recompensador para você.'
             });
          }
          if (JSON.stringify(comerciante) === JSON.stringify(sinais.tendencia)) {
            this.padraoExterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Comerciante',
              descricao: 'Seu temperamento descreve uma pessoa que vive principalmente no plano de valor social (comercial). Você vê o mundo em termos de coisas e seu valor comparativo, ou como tarefas a serem completadas. Outras pessoas também podem ser vistas, por você, como coisas, ou melhor, como um instrumento para atingir o objetivo. Você possui uma boa apreciação pelas habilidades organizacionais lógicas e pode gastar grande esforço na busca das suas metas, não costumando hesitar em exercitar-se nessa busca, e considerando isso energizante, às vezes. Você pode ter alguma predisposição para ser mais reativo do que proativo. Pode até haver uma tendência para algum foco excessivo nos resultados à custa do quadro geral. Um exemplo disto, é poder quebrar alguma regra para obter uma venda. Sua visão ligeiramente negativa da dimensão Pessoas pode indicar alguém mais capaz de manipular os outros (de forma positiva ou negativa) do que outros padrões apresentam. A dimensão Sistemas revela a visão negativa das regras, estrutura ou ordem estabelecidas, indicando uma pessoa que pode tender a ignorá-las mais do que alguém com uma atitude mais positiva em relação a esta dada dimensão. A combinação de seus temperamentos fala sobre uma pessoa que pode ser ideal para muitas posições de vendas ou papéis que exijam pouca supervisão, liberdade e persuasão dos demais de uma maneira muito específica.'
             });
          }
          if (JSON.stringify(conformista) === JSON.stringify(sinais.tendencia)) {
            this.padraoExterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Conformista',
              descricao: 'Seu temperamento indica uma pessoa que busca relacionamentos sociais ou profissionais acima de relacionamentos pessoais. Você aprecia mais as coisas práticas e detalhadas, e espera que as coisas sejam feitas do jeito certo e estejam de acordo com as regras estabelecidas pela instituição ou organização.  Você pode ser adepto a transformar conceitos em ação. Você tem uma visão um tanto negativa da dimensão Pessoas, no entanto, isso indica que pode ver os outros mais como partes do todo do que como indivíduos únicos fora da estrutura social dentro da qual há interação. Você coloca alguma ênfase sobre as dimensões do pensamento referentes às Tarefas e Sistemas, o que indica uma preferência a se conformar com uma norma ou padrão social e uma maior capacidade de resposta à autoridade, organização ou sistema impostos. Você é alguém que vai trabalhar extremamente duro para fazer as coisas bem feitas e do jeito certo. A qualidade é muito importante para você.'
             });
          }
          if (JSON.stringify(operador) === JSON.stringify(sinais.tendencia)) {
            this.padraoExterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Operador',
              descricao: 'Seu temperamento indica uma pessoa que coloca toda a sua energia no trabalho ou no objetivo. Pessoas desse padrão são, muitas vezes, praticantes de todos os tipos de trabalhos manuais, artesãos e artistas, ou seja, pessoas que dominam a questão de como certas tarefas devem ser feitas detalhadamente. Você tem uma visão um pouco negativa da dimensão Pessoas, o que revela alguma distância emocional para com os outros, ou talvez uma falta de compreensão em comparação ao quão bem você vê a tarefa em questão. Você vê a dimensão Sistemas de uma maneira neutra agradável, nem excessivamente otimista e nem excessivamente pessimista. Como resultado, você tem uma boa atitude em relação à autoridade e pode trabalhar igualmente bem sob supervisão ou por conta própria. Você tende a apreciar coisas materiais e pode encontrar um bom equilíbrio entre seguir as regras ou quebrá-las quando necessário.'
             });
          }
          if (JSON.stringify(cetico) === JSON.stringify(sinais.tendencia)) {
            this.padraoExterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Cético',
              descricao: 'Seu temperamento indica uma cautela ao envolvimento com o ambiente externo imediato. Você vê a dimensão Tarefas de uma maneira equilibrada, o que resulta em uma harmonia com o mundo social ao seu redor. Você nem valoriza excessivamente e nem desvaloriza a importância do objetivo, e pode se concentrar claramente no que precisa ser feito para alcançar o sucesso. Sua visão negativa das dimensões Pessoas e Sistemas, no entanto, pode fazer com que você se afaste de ambientes altamente estruturados em favor da mudança. Você pode deixar de valorizar as pessoas com a devida importância que elas têm, favorecendo, ao invés disso, o trabalho com elas mais em uma base profissional.'
            });
          }
          if (JSON.stringify(cientifico) === JSON.stringify(sinais.tendencia)) {
            this.padraoExterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Científico',
              descricao: 'Seu temperamento é muito comumente encontrado. Ele indica a qualidade da avaliação geralmente encontrada em cientistas e pesquisadores de todos os tipos. Sua visão da dimensão Sistemas revela uma preferência pela orientação formal de padrões estabelecidos, autoridade ou estrutura existente de algum tipo (por exemplo, regras, procedimentos, sistemas, leis, etc.). Isto indica uma tendência para a conformidade com os sistemas e a ordem, enquanto que a dimensão Pessoas indica uma menor preferência por envolvimentos emocionais com os outros em favor do foco na ordem do que está sendo feito. Você alcançou uma visão equilibrada da dimensão Tarefas, o que mostra que não há nem muita e nem pouca ênfase na importância da realização das mesmas. Ao invés disso, você dá apenas a atenção adequada para obter resultados sem ficar obcecado por eles. Você pode ver os outros como "parte do plano", ou elementos do sistema, mais do que pessoas com seu próprio pensamento, necessidades e desejos.'
            });
          }
          if (JSON.stringify(jornalista) === JSON.stringify(sinais.tendencia)) {
            this.padraoExterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Jornalista',
              descricao: 'Seu temperamento mostra uma pequena distância emocional das pessoas com uma visão bem equilibrada do mundo social e da sua ordem. Jornalistas e repórteres muitas vezes desenvolvem esse temperamento para o mundo devido à demanda de objetividade nestas profissões. Algo para se alertar é a respeito da possibilidade de se viver uma vida excessivamente objetiva, um tanto quanto impessoal. Sua visão neutra das dimensões do pensamento Tarefas e Sistemas indica a compreensão muita clara do que precisa ser feito, na maioria dos casos. Você provavelmente não se perde nos detalhes, permanece objetivo e profissional (mesmo às vezes as custas das emoções pessoais), e tem uma apreciação saudável por regras e ordens, mas não a ponto de as seguir cegamente sem motivo.'
            });
          }
          if (JSON.stringify(acolhedor) === JSON.stringify(sinais.tendencia)) {
            this.padraoExterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Acolhedor',
              descricao: 'Seu temperamento identifica uma qualidade de serviço acolhedora para a humanidade, como o "bom samaritano" que ajudou o homem a sair da vala, simplesmente porque ele era um ser humano necessitado. Você tem pontos de vista um pouco negativos sobre as dimensões Tarefas e Sistemas, o que indica pouca preocupação com a convenção social e uma preferência para trabalhar fora do sistema de regras ou ordem estabelecidas. Isto não quer dizer que você quebre as regras de maneira excessiva, apenas que prefere trabalhar com menos estrutura. Você pode ter uma ligeira tendência para enfatizar excessivamente a dimensão do pensamento referente às pessoas, o que pode levar a decisões muito otimistas, onde os outros estão envolvidos. Conflito com os demais é algo muito ruim para este perfil e, provavelmente, você trabalha duro para evitar que ele ocorra. Você também pode pegar os problemas dos outros para si mesmo, o que talvez lhe cause um aumento de estresse e ansiedade, se não houver o devido cuidado.'
             });
          }
          if (JSON.stringify(reformador) === JSON.stringify(sinais.tendencia)) {
            this.padraoExterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Reformador',
              descricao: 'Seu temperamento identifica alguém que tem uma grande preocupação com as outras pessoas e uma aceitação aberta às regras e estrutura estabelecidas. A sua visão excessivamente positiva das dimensões Pessoas e Sistemas deixa a dimensão Tarefas subestimada em importância. Como resultado, você pode colocar maior ênfase nas necessidades e problemas dos outros, e também em fazer as coisas de acordo com o plano estabelecido, do que em colocar na qualidade do trabalho. Você provavelmente gosta de trabalhar com pessoas em um ambiente estruturado em oposição ao trabalho solitário e em um lugar muito bagunçado.'
            });
          }
          if (JSON.stringify(compassivo) === JSON.stringify(sinais.tendencia)) {
            this.padraoExterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Compassivo',
              descricao: 'Seu temperamento é caracterizado pelo alto nível de preferência para as pessoas e pelo menor foco nos objetivos ou na convenção social. Você geralmente é gentil e generoso. Sua pontuação neutra na dimensão Sistemas revela o equilíbrio entre os aspectos positivos e negativos da autoridade. Isso indica que você avaliará as regras de forma objetiva, vendo as normas que são irrelevantes como realmente irrelevantes e as efetivas como efetivas. As regras e os sistemas são vistos em termos de como afetam as pessoas, e não são adotados obstinadamente ou cegamente. Você vê claramente a necessidade de tal ordem, mas não está tão fascinado pela necessidade de fazer as coisas a sua própria maneira ou a maneira da empresa, quando isso interferir na sua capacidade de ser adaptar e mudar conforme o necessário para obter resultados. Você pode ter uma pequena tendência para confiar demais nos outros com o tempo e, provavelmente, não gosta de confrontos, mais do que a maioria das outras pessoas.'
            });
          }
          if (JSON.stringify(facilitador) === JSON.stringify(sinais.tendencia)) {
            this.padraoExterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Facilitador',
              descricao: 'Seu temperamento é caracterizado como alguém que gosta de mostrar às pessoas como fazer as coisas por si próprias. As pessoas desse temperamento não funcionam bem sob forte supervisão direta devido a visão negativa da dimensão Sistemas. Você prefere a liderança casual que lhe dá espaço para se mover e tomar decisões por conta própria, sendo que prefere ser quem supervisiona e não o supervisionado. Sua visão das dimensões Pessoas e Tarefas indica que você coloca mais importância nas necessidades das pessoas e nos objetivos do que nas regras que os governam. Você pode tender, no entanto, a confiar um pouco demais nos outros.'
            });
          }
          if (JSON.stringify(colaborador) === JSON.stringify(sinais.tendencia)) {
            this.padraoExterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Colaborador',
              descricao: 'Seu temperamento mostra que você é alguém que está muito envolvido com as pessoas no contexto empresarial e social, sendo menos preocupado com as regras. Pessoas com esse temperamento de valor são bons amigos e companheiros, e fazem excelentes companheiros de equipe e colegas de trabalho. A dimensão Pessoas é um tanto quanto supervalorizada, o que pode resultar em que você invista mais tempo e esforço a fim de construir relacionamentos pessoais do que para obter as coisas feitas. Você é bastante altruísta, mas isso também pode ser uma falha, às vezes. Sua visão negativa da dimensão Sistemas revela cautela ou até mesmo desrespeito às regras e à autoridade estabelecidas. Você pode recusar as relações mais formais (profissionais) com outras pessoas a favor de uma interação mais pessoal.'
            });
          }
          if (JSON.stringify(independente) === JSON.stringify(sinais.tendencia)) {
            this.padraoExterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Independente',
              descricao: 'Seu temperamento mostra que você é alguém um pouco discordante do sistema, ordem e convenção social estabelecidos. Isto não é necessariamente algo ruim. A criatividade pode estimular você a deixar o caminho mais conhecido para encontrar novas maneiras de fazer as coisas. Você tem um alto nível de foco em pessoas no geral, e está aberto a aceitar os outros. Sua visão negativa da dimensão Tarefas pode causar problemas quando alinhada ao menor interesse pela convenção social estabelecida e a disposição de derrubar ou tolerar a perda dos sistemas de ordem existentes, mostrada pelo seu baixo foco na dimensão Sistemas. Você pode ser muito intuitivo, visionário e, muitas vezes, não convencional.'
            });
          }
          if (JSON.stringify(juiz) === JSON.stringify(sinais.tendencia)) {
            this.padraoExterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Juiz',
              descricao: 'Seu temperamento indica que você é alguém que se relaciona com o mundo principalmente em termos do sistema e ordem estabelecidos (por exemplo, regras, normas e lei). Muitas das suas energias estão focadas em fazer as coisas caber dentro do sistema ou da ordem. A sua dimensão Tarefas revela uma distância consciente da convenção social, enquanto a sua dimensão Pessoas indica uma objetividade equilibrada em relação aos outros, sendo que você nem confia demais e nem é tão frio. Você consegue um bom equilíbrio entre a valorização das pessoas sem ficar tão envolvido com elas a ponto de perder a objetividade. Este temperamento não é diferente do encontrado em juízes, os quais são encarregados de aplicar a lei escrita às pessoas com equidade objetiva, e sem considerar o status social.'
             });
          }
          if (JSON.stringify(humorista) === JSON.stringify(sinais.tendencia)) {
            this.padraoExterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Humorista',
              descricao: 'Seu temperamento indica que você é alguém que pode encontrar humor e ironia nas convenções sociais. Você tem prazer na brincadeira com os outros, podendo levar a vida em um tom mais leve e, possivelmente, até mesmo desfrutar de uma boa piada prática. Você aceita tanto as pessoas como elas são quanto o sistema e a ordem estabelecidos. Tal equilíbrio em relação à autoridade e a outras pessoas torna mais fácil para alguém como você rir da vida, ou não levar tudo tão a sério. Se houvesse um nome para este temperamento, seria “humorista”. Não ultrapassando ou subestimando pessoas ou regras, você exerce menos esforço em lidar com ambos, portanto, menor ansiedade. Sua visão de detalhes, organização e constância, no entanto, é mais pessimista.'
             });
          }
          if (JSON.stringify(consultor) === JSON.stringify(sinais.tendencia)) {
            this.padraoExterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Consultor',
              descricao: 'Seu temperamento caracteriza uma pessoa levemente mais focada na dimensão social do que em pessoas como indivíduos únicos ou em regras organizacionais ou ordem. Considerando que sua visão da dimensão Pessoas é equilibrada, o que significa que você nem supervaloriza e nem subestima os demais, você tende a ver estrutura e ordem que são muito bem definidas como algo ruim. A questão importante é: "seu trabalho exige que você se conforme às regras ou se esforce para mudá-las?". Você pode ser muito criativo quando estiver no ambiente certo, além de entender bem as pessoas.'
            });
          }
          if (JSON.stringify(opositor) === JSON.stringify(sinais.tendencia)) {
            this.padraoExterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Opositor',
              descricao: 'Seu temperamento descreve alguém que se opõe ao sistema e à ordem estabelecidos. Pessoas que têm esse temperamento podem ser manifestantes e críticos com base em uma avaliação objetiva de outras pessoas e do mundo social. Você se dá bem com os outros, geralmente não é excessivamente simpático, e geralmente entende o que precisa ser feito para alcançar o sucesso. Você tenderá a encontrar novas maneiras ao redor, ou evitar, controles existentes.'
            });
          }
          if (JSON.stringify(virtuoso) === JSON.stringify(sinais.tendencia)) {
            this.padraoExterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Virtuoso',
              descricao: 'Seu temperamento revela uma pessoa que alcançou equilíbrio dinâmico em todas as dimensões do pensamento. Você tem um excelente nível de foco em todas as três dimensões, o que lhe permite sentir-se à vontade na maioria das situações, ser livre de controles, adaptável, versátil e aberto a coisas novas. Você tem uma sensação elevada sobre si mesmo.'
            });
          }
      });
    }

    // Contagem de Padrões externos
    this.padraoExterno.forEach(p => {
      if (p.padrao === 'Pessimista') { this.countPessimista.push(p.padrao); }
      if (p.padrao === 'Vigilante') { this.countVigilante.push(p.padrao); }
      if (p.padrao === 'Reativo') { this.countReativo.push(p.padrao); }
      if (p.padrao === 'Comerciante') { this.countComerciante.push(p.padrao); }
      if (p.padrao === 'Conformista') { this.countConformista.push(p.padrao); }
      if (p.padrao === 'Operador') { this.countOperador.push(p.padrao); }
      if (p.padrao === 'Cético') { this.countCetico.push(p.padrao); }
      if (p.padrao === 'Científico') { this.countCientifico.push(p.padrao); }
      if (p.padrao === 'Jornalista') { this.countJornalista.push(p.padrao); }
      if (p.padrao === 'Acolhedor') { this.countAcolhedor.push(p.padrao); }
      if (p.padrao === 'Reformador') { this.countReformador.push(p.padrao); }
      if (p.padrao === 'Compassivo') { this.countCompassivo.push(p.padrao); }
      if (p.padrao === 'Facilitador') { this.countFacilitador.push(p.padrao); }
      if (p.padrao === 'Colaborador') { this.countColaborador.push(p.padrao); }
      if (p.padrao === 'Independente') { this.countIndependente.push(p.padrao); }
      if (p.padrao === 'Juiz') { this.countJuiz.push(p.padrao); }
      if (p.padrao === 'Humorista') { this.countHumorista.push(p.padrao); }
      if (p.padrao === 'Consultor') { this.countConsultor.push(p.padrao); }
      if (p.padrao === 'Opositor') { this.countOpositor.push(p.padrao); }
      if (p.padrao === 'Virtuoso') { this.countVirtuoso.push(p.padrao); }
    });


    // Separação dos avaliados por padrão
    this.padraoExterno.forEach(padrao => {
      let padraoIgual = false;
      this.avaliadosPadraoExterno.forEach(lista => {
        if (this.avaliadosPadraoExterno && padrao.padrao === lista.padrao) {
          lista.avaliados.push({
            nomeAvaliado: padrao.avaliadoNome,
            idAvaliado: padrao.avaliado
            });
          padraoIgual = true;
        }
      });
      if (!padraoIgual) {
        this.avaliadosPadraoExterno.push({
          padrao: padrao.padrao,
          descricao: padrao.descricao,
          avaliados: [{
            nomeAvaliado: padrao.avaliadoNome,
            idAvaliado: padrao.avaliado
          }]
        });
      }
    });

    // Gráfico Padrão Externo
    const labelsExterno: Array<any> = new Array<any>();

    if (this.countPessimista.length > 0) {
      this.firstChartDataExterno.push(this.countPessimista.length);
      labelsExterno.push('Pessimista');
    }
    if (this.countVigilante.length > 0) {
      this.firstChartDataExterno.push(this.countVigilante.length);
      labelsExterno.push('Vigilante');
    }
    if (this.countReativo.length > 0) {
      this.firstChartDataExterno.push(this.countReativo.length);
      labelsExterno.push('Reativo');
    }
    if (this.countComerciante.length > 0) {
      this.firstChartDataExterno.push(this.countComerciante.length);
      labelsExterno.push('Comerciante');
    }
    if (this.countConformista.length > 0) {
      this.firstChartDataExterno.push(this.countConformista.length);
      labelsExterno.push('Conformista');
    }
    if (this.countOperador.length > 0) {
      this.firstChartDataExterno.push(this.countOperador.length);
      labelsExterno.push('Operador');
    }
    if (this.countCetico.length > 0) {
      this.firstChartDataExterno.push(this.countCetico.length);
      labelsExterno.push('Cético');
    }
    if (this.countCientifico.length > 0) {
      this.firstChartDataExterno.push(this.countCientifico.length);
      labelsExterno.push('Científico');
    }
    if (this.countJornalista.length > 0) {
      this.firstChartDataExterno.push(this.countJornalista.length);
      labelsExterno.push('Jornalista');
    }
    if (this.countAcolhedor.length > 0) {
      this.firstChartDataExterno.push(this.countAcolhedor.length);
      labelsExterno.push('Acolhedor');
    }
    if (this.countReformador.length > 0) {
      this.firstChartDataExterno.push(this.countReformador.length);
      labelsExterno.push('Reformador');
    }
    if (this.countCompassivo.length > 0) {
      this.firstChartDataExterno.push(this.countCompassivo.length);
      labelsExterno.push('Compassivo');
    }
    if (this.countFacilitador.length > 0) {
      this.firstChartDataExterno.push(this.countFacilitador.length);
      labelsExterno.push('Facilitador');
    }
    if (this.countColaborador.length > 0) {
      this.firstChartDataExterno.push(this.countColaborador.length);
      labelsExterno.push('Colaborador');
    }
    if (this.countIndependente.length > 0) {
      this.firstChartDataExterno.push(this.countIndependente.length);
      labelsExterno.push('Independente');
    }
    if (this.countJuiz.length > 0) {
      this.firstChartDataExterno.push(this.countJuiz.length);
      labelsExterno.push('Juiz');
    }
    if (this.countHumorista.length > 0) {
      this.firstChartDataExterno.push(this.countHumorista.length);
      labelsExterno.push('Humorista');
    }
    if (this.countConsultor.length > 0) {
      this.firstChartDataExterno.push(this.countConsultor.length);
      labelsExterno.push('Consultor');
    }
    if (this.countOpositor.length > 0) {
      this.firstChartDataExterno.push(this.countOpositor.length);
      labelsExterno.push('Opositor');
    }
    if (this.countVirtuoso.length > 0) {
      this.firstChartDataExterno.push(this.countVirtuoso.length);
      labelsExterno.push('Virtuoso');
    }

    this.firstChartDataExterno.push(0);

    this.chartDatasetsExterno = [
      { data: this.firstChartDataExterno, label: 'Tendência Emocional Externa' }
    ];

    this.chartLabelsExterno = labelsExterno;

    this.updateGraficoExterno();
  }

  updateGraficoExterno() {
    this.chartDatasetsExterno = [
      { data: this.firstChartDataExterno, label: 'Tendência Emocional Externa' }
    ];
  }

  getPadraoInterno() {
    const negativo = ['-', '-', '-'];
    const dirigido = ['-', '-', '+'];
    const passivo = ['-', '-', '='];
    const influenciavel = ['-', '+', '-'];
    const compulsivo = ['-', '+', '+'];
    const trabalhador = ['-', '+', '='];
    const relutante = ['-', '=', '-'];
    const assertivo = ['-', '=', '+'];
    const introspectivo = ['-', '=', '='];
    const centrado = ['+', '-', '-'];
    const dedicado = ['+', '-', '+'];
    const indulgente = ['+', '-', '='];
    const elevado = ['+', '+', '-'];
    const sensivel = ['+', '=', '-'];
    const dinamico = ['=', '-', '-'];
    const expressivo = ['=', '-', '+'];
    const dependente = ['=', '-', '='];
    const discriminador = ['=', '+', '-'];
    const flexivel = ['=', '=', '-'];
    const aberto = ['=', '=', '='];


    if (this.tendenciaInterna) {
      this.tendenciaInterna.forEach(sinais => {
          // Comparação dos sinais para definir Padrão interno
          if (JSON.stringify(negativo) === JSON.stringify(sinais.tendencia)) {
            this.padraoInterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Negativo',
              descricao: 'Seu conceito sobre si mesmo fornece muito pouca ou nenhuma autoestima. Você provavelmente sente uma tremenda sensação de culpa e dúvida. Você é extremamente difícil para si próprio, nunca se sente satisfeito com os resultados obtidos e provavelmente tem dificuldade em ficar entusiasmado com qualquer coisa. Você vai postergar as coisas devido à incapacidade de tomar decisões e se comprometer.' });
          }
          if (JSON.stringify(dirigido) === JSON.stringify(sinais.tendencia)) {
            this.padraoInterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Dirigido',
              descricao: 'Você é uma pessoa que pode ter uma preocupação excessiva com os objetivos que você estabeleceu para si mesmo. Você subestima seu próprio valor e, ao contrário, baseia sua autoestima em como você segue suas próprias regras, as quais você aderiu tenazmente, e tende a supervalorizar a auto-organização. Você é extremamente autodisciplinado e, como resultado, provavelmente é muito confiável, pontual e meticuloso. No entanto, provavelmente, é bastante dogmático sobre suas crenças pessoais e não é susceptível a mudar ou ceder às interpretações dos outros, mesmo quando contrárias e elas. Você sente-se frustrado com mais frequência do que deveria, e mais frequentemente, ainda, consigo mesmo.' });
          }
          if (JSON.stringify(passivo) === JSON.stringify(sinais.tendencia)) {
            this.padraoInterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Passivo',
              descricao: 'Você é alguém um pouco incerto e inseguro sobre a sua própria identificação, neste momento, tendo problemas para agir enquanto se encontra assim. Você passa por alguma dúvida pessoal e insegurança, e pode exibir uma falta de confiança em seu trabalho. Como alternativa, age, principalmente, na direção de garantir que as regras sejam cumpridas enquanto você decide quem deve ser. Embora esteja mais confiante no que tem valor para você e no que deseja fazer, é a direção ou a melhor maneira de toma-la que é indefinida para você, neste momento.' });
          }
          if (JSON.stringify(influenciavel) === JSON.stringify(sinais.tendencia)) {
            this.padraoInterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Influenciável',
              descricao: 'Você é uma pessoa que facilmente se envolve em atividades de forma experimental ou impulsiva, enquanto você tenta cristalizar sua própria direção ou objetivos. Você não está claro quanto à melhor maneira de alcançar seus objetivos e tem dúvidas sobre si mesmo. Isso pode fazer com que coloque mais importância no que os outros pensam sobre você e suas realizações, já que seus próprios guias não estão claros. Você ainda não estabeleceu regras certas e definitivas para a auto-organização e pode estar se questionando como quer prosseguir na vida, neste exato momento. Devido a esses fatores, você é mais propenso a ser influenciado pelo seu entorno imediato e pode ser considerado mais suscetível como resultado.' });
          }
          if (JSON.stringify(compulsivo) === JSON.stringify(sinais.tendencia)) {
            this.padraoInterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Compulsivo',
              descricao: 'Você é motivado por conquistar objetivos. Você tende a subvalorizar sua própria autoestima e, ao invés disso, colocar muita ênfase na realização de ter as coisas feitas de forma correta e em acordo às suas expectativas, ou de outras pessoas. Apesar da subestimação que apresenta por si mesmo, você ainda tende a mostrar autoconfiança e pode estar muito confortável em situações sociais. Normalmente você é bem organizado.' });
          }
          if (JSON.stringify(trabalhador) === JSON.stringify(sinais.tendencia)) {
            this.padraoInterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Trabalhador',
              descricao: 'Você é trabalhador. Você se identifica fortemente com seu papel social/trabalho primário. Você se subvaloriza um pouco, o que é compensado pela supervalorização colocada no seu trabalho. Sua função é quem você é e você coloca um alto valor nesse papel. Embora haja tamanha colocação do seu próprio valor em seu trabalho, você está um quanto tanto confuso sobre onde quer estar no futuro. Você não é nem rígido e nem influenciável em relação à forma como você faz as coisas e, como resultado, tende a pesar adequadamente as consequências das ações antes de tomá-las. Diante disso, no entanto, você ainda é mais reativo do que você é proativo.' });
          }
          if (JSON.stringify(relutante) === JSON.stringify(sinais.tendencia)) {
            this.padraoInterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Relutante',
              descricao: 'Você é alguém que não se identifica demais com seus papéis sociais/de trabalho primários na vida. Você está relutante em envolver-se completamente no papel escolhido ou na carreira, pois não sente um sentimento de conquista pessoal a partir dessas coisas. Você não tem certeza sobre o que quer fazer e a melhor maneira de prosseguir. Há muita confusão dentro de você neste exato momento. Quem você é, como você deve ser, é algo que você está tentando descobrir. Como resultado de tudo isso, você pode não ter uma visão tão positiva de você como deveria.' });
          }
          if (JSON.stringify(assertivo) === JSON.stringify(sinais.tendencia)) {
            this.padraoInterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Assertivo',
              descricao: 'Você identifica fortemente sua autoimagem com sua crença de como você deve fazer as coisas e, portanto, pode ser bastante assertivo quando se trata de agir de uma certa maneira. Há mais em jogo do que simplesmente "fazer isso do meu jeito". Para você, a conformidade com as regras que você define para como as coisas devem ser feitas está diretamente ligada a sua autoestima. Os resultados tornam-se secundários à forma de como você seguiu as diretrizes. Não fazer as coisas da maneira correta, mesmo que o trabalho seja feito, equivale a um fracasso em sua mente. Você não se valoriza o suficiente neste momento, ao invés disso, procura dar valor a si mesmo através do quanto segue às regras que você considera orientar suas ações e a sua vida. Para você é mais importante como você faz isso do que o que você faz. Você tem alguma incerteza e medo da mudança.' });
          }
          if (JSON.stringify(introspectivo) === JSON.stringify(sinais.tendencia)) {
            this.padraoInterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Introspectivo',
              descricao: 'Você tem uma atitude objetiva em relação aos seus próprios guias ou crenças sobre como você deve fazer as coisas e como deve viver a vida. Você vê seu trabalho como uma maneira de se expressar mais completamente, mas não como a coisa mais importante em sua vida por qualquer meio. Você se vê como mais do que a soma de seu trabalho, mas ainda assim, não se dá tanto crédito como deveria. Você é um pouco severo demais consigo mesmo.' });
          }
          if (JSON.stringify(centrado) === JSON.stringify(sinais.tendencia)) {
            this.padraoInterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Centrado',
              descricao: 'Você tem uma consciência apurada do seu próprio valor, e valoriza esse aspecto em si próprio mais do que em relação ao que você faz ou o quão bem você faz (seu cargo ou desempenho). Você pode tender a sentir, às vezes, que os outros não te dão crédito suficiente ou apreciam sua contribuição, no entanto, você tende a permanecer no controle de si mesmo e sabe quando ser assertivo e quando permanecer reservado. Sobre o que acredita, você tenderá a crer nisso completamente, podendo se tornar muito obstinado por tais crenças concretas.' });
          }
          if (JSON.stringify(dedicado) === JSON.stringify(sinais.tendencia)) {
            this.padraoInterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Dedicado',
              descricao: 'Você tem clareza sobre quem você é como pessoa e aceita as suas verdadeiras limitações e pontos fortes, sendo realista sobre si mesmo, no geral. No entanto, tende a ver o seu trabalho ou os principais papéis da sua vida como algo que nunca poderá verdadeiramente cumprir como pessoa. Tais coisas são vistas por você como meramente meios para um fim que permita atender outras necessidades internas de autoexpressão. Devido a esta atitude, é muito improvável que você fique “queimado” em um emprego ou que se sacrificasse demais.' });
          }
          if (JSON.stringify(indulgente) === JSON.stringify(sinais.tendencia)) {
            this.padraoInterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Indulgente',
              descricao: 'Você alcançou uma apreciação objetiva para você enquanto pessoa. Você não subestima seu próprio valor. Ao invés disso, é mais provável que você subvalorize a capacidade de qualquer trabalho que venha a realizar que faça-o sentir-se completo ou totalmente satisfeito. Você coloca mais valor em ser disciplinado e seguir seu próprio sentido, direção ou suas próprias regras internas.' });
          }
          if (JSON.stringify(elevado) === JSON.stringify(sinais.tendencia)) {
            this.padraoInterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Elevado',
              descricao: 'Você tende a colocar muito foco em seu papel e aparência, e tem uma visão muito positiva sobre si próprio. Você pode não ser tão motivado quanto outros por já ter uma visão muito maior sobre si mesmo, o que pode levá-lo, realmente, a ter uma leve falta de ambição, já que, de certa forma, sentir que precisa melhorar serve como motivador. Você sente que já alcançou níveis significativos de desenvolvimento em muitas áreas e terá que olhar a fundo para encontrar sua motivação, ao invés de simplesmente sentir que precisa provar quem é em qualquer lugar. Você também pode colocar importância demais em seu trabalho em relação ao quanto isso define você como pessoa. Você está um pouco confuso quanto à melhor maneira de avançar um nível mais alto de sucesso na sua vida neste momento.' });
          }
          if (JSON.stringify(sensivel) === JSON.stringify(sinais.tendencia)) {
            this.padraoInterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Sensível',
              descricao: 'Você se valoriza por quem você é como uma pessoa acima do seu trabalho ou a maneira pela qual você o faz. Você tem muita confiança interna em suas habilidades, mas às vezes essa confiança pode ser injustificada. Você pode ter dificuldade de julgar de forma realista suas próprias limitações e, como resultado, pode acabar sentindo que os outros não apreciam seu verdadeiro valor. Você não coloca nem muita ou nem pouca importância em seu trabalho, mas tem dúvidas sobre a melhor maneira de fazê-lo neste exato momento. Talvez algo tenha mudado sobre o caminho (por exemplo, técnicas, produtos, clientela, etc.) no qual você faz seu trabalho, causando em você esta confusão.' });
          }
          if (JSON.stringify(dinamico) === JSON.stringify(sinais.tendencia)) {
            this.padraoInterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Dinâmico',
              descricao: 'Seu senso de autoestima é muito suscetível e pode ser facilmente influenciado pelas opiniões dos outros, mas no momento você tem um senso realista das suas próprias habilidades. Você tende a ser dinâmico, enérgico e possuir boa identidade. Você subestima um dos seus principais papéis de vida neste momento, e, como resultado, pode apresentar uma falta de motivação e persistência. Isso pode torná-lo propenso a alternar de um lado para o outro entre sentimentos de satisfação e insatisfação em relação ao seu papel do dia-a-dia.' });
          }
          if (JSON.stringify(expressivo) === JSON.stringify(sinais.tendencia)) {
            this.padraoInterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Expressivo',
              descricao: 'Seu senso de autovalor é sólido e bastante preciso. Você nem subestima e nem supervaloriza as suas próprias habilidades, e define limitações apropriadas a si mesmo. No entanto, você também é muito orientado a seguir seu próprio senso de direção. Você acredita que as coisas devem ser feitas de uma certa maneira e você se esforçará para garantir que elas sejam feitas, porque isso, para você, é a chave para o sucesso. Você pode ser determinado quando se trata de aderir a estas regras. Na maior parte do tempo, você é pontual, consciente e bem organizado.' });
          }
          if (JSON.stringify(dependente) === JSON.stringify(sinais.tendencia)) {
            this.padraoInterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Dependente',
              descricao: 'Sua atitude sobre você mesmo pode ser facilmente influenciada pelas opiniões dos outros. Você não avalia supervaloriza ou nem subvaloriza seu próprio valor, mas permite que as opiniões aos outros lhe influenciem de uma maneira ou de outra. Neste momento da sua vida, você hesita em exercer muita energia em um dos seus principais papéis e está buscando a aprovação externa dos outros antes de fazer tal investimento. Você pode se distrair mais facilmente e evitar o compromisso devido a esse fato.' });
          }
          if (JSON.stringify(discriminador) === JSON.stringify(sinais.tendencia)) {
            this.padraoInterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Discriminador',
              descricao: 'Você se aceita por quem você é e tem um senso claro das suas próprias habilidades. Você é bastante confortável consigo mesmo, em geral, mas escolhe aqueles papéis que você sente que elevarão a sua autoestima. Talvez você coloque demasiadamente uma grande importância em seu trabalho e a sua capacidade de fazê-lo feliz como pessoa por dentro. Você também tem alguma dúvida sobre a melhor maneira de prosseguir ou qual direção seguir no futuro.' });
          }
          if (JSON.stringify(flexivel) === JSON.stringify(sinais.tendencia)) {
            this.padraoInterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Flexível',
              descricao: 'Você é alguém fácil de lidar e flexível. Você está confortável com você mesmo e sente que está bem no que faz, mas está incerto sobre o que deseja fazer ou ser no futuro. O futuro não está bem claro para você agora, sendo que tende a concentrar-se mais no aqui e agora como resultado. Talvez você possa ter dificuldade em cumprir os horários que definiu e estipular prazos devido a esta incerteza.' });
          }
          if (JSON.stringify(aberto) === JSON.stringify(sinais.tendencia)) {
            this.padraoInterno.push({
              avaliado: sinais.avaliado,
              avaliadoNome: sinais.avaliadoNome,
              padrao: 'Aberto',
              descricao: 'Você está muito aberto a todos os pontos de vista. Você nem valoriza demais e nem desvaloriza qualquer dimensão do pensamento e, como resultado, tem uma capacidade muito boa para julgar seu próprio valor e habilidades, mantendo o seu trabalho em boa perspectiva, ou seja, não dando nem pouca e nem atenção demais a ele. Você tem clareza sobre o que quer fazer e tem um plano para como atingir isso.' });
          }
      });
    }

    // Contagem de Padrões internos
    this.padraoInterno.forEach(p => {
      if (p.padrao === 'Negativo') { this.countNegativo.push(p.padrao); }
      if (p.padrao === 'Dirigido') { this.countDirigido.push(p.padrao); }
      if (p.padrao === 'Passivo') { this.countPassivo.push(p.padrao); }
      if (p.padrao === 'Influenciável') { this.countInfluenciavel.push(p.padrao); }
      if (p.padrao === 'Compulsivo') { this.countCompulsivo.push(p.padrao); }
      if (p.padrao === 'Trabalhador') { this.countTrabalhador.push(p.padrao); }
      if (p.padrao === 'Relutante') { this.countRelutante.push(p.padrao); }
      if (p.padrao === 'Assertivo') { this.countAssertivo.push(p.padrao); }
      if (p.padrao === 'Introspectivo') { this.countIntrospectivo.push(p.padrao); }
      if (p.padrao === 'Centrado') { this.countCentrado.push(p.padrao); }
      if (p.padrao === 'Dedicado') { this.countDedicado.push(p.padrao); }
      if (p.padrao === 'Indulgente') { this.countIndulgente.push(p.padrao); }
      if (p.padrao === 'Elevado') { this.countElevado.push(p.padrao); }
      if (p.padrao === 'Sensível') { this.countSensivel.push(p.padrao); }
      if (p.padrao === 'Dinâmico') { this.countDinamico.push(p.padrao); }
      if (p.padrao === 'Expressivo') { this.countExpressivo.push(p.padrao); }
      if (p.padrao === 'Dependente') { this.countDependente.push(p.padrao); }
      if (p.padrao === 'Discriminador') { this.countDiscriminador.push(p.padrao); }
      if (p.padrao === 'Flexível') { this.countFlexivel.push(p.padrao); }
      if (p.padrao === 'Aberto') { this.countAberto.push(p.padrao); }
    });

    // Separação dos avaliados por padrão
    this.padraoInterno.forEach(padrao => {
      let padraoIgual = false;
      this.avaliadosPadraoInterno.forEach(lista => {
        if (this.avaliadosPadraoInterno && padrao.padrao === lista.padrao) {
          lista.avaliados.push({
            nomeAvaliado: padrao.avaliadoNome,
            idAvaliado: padrao.avaliado
            });
          padraoIgual = true;
        }
      });
      if (!padraoIgual) {
        this.avaliadosPadraoInterno.push({
          padrao: padrao.padrao,
          descricao: padrao.descricao,
          avaliados: [{
            nomeAvaliado: padrao.avaliadoNome,
            idAvaliado: padrao.avaliado
          }]
        });
      }
    });

    // Gráfico Padrão Interno
    const labelsInterno: Array<any> = new Array<any>();

    if (this.countNegativo.length > 0) {
      this.firstChartDataInterno.push(this.countNegativo.length);
      labelsInterno.push('Negativo');
    }
    if (this.countDirigido.length > 0) {
      this.firstChartDataInterno.push(this.countDirigido.length);
      labelsInterno.push('Dirigido');
    }
    if (this.countPassivo.length > 0) {
      this.firstChartDataInterno.push(this.countPassivo.length);
      labelsInterno.push('Passivo');
    }
    if (this.countInfluenciavel.length > 0) {
      this.firstChartDataInterno.push(this.countInfluenciavel.length);
      labelsInterno.push('Influenciável');
    }
    if (this.countCompulsivo.length > 0) {
      this.firstChartDataInterno.push(this.countCompulsivo.length);
      labelsInterno.push('Compulsivo');
    }
    if (this.countTrabalhador.length > 0) {
      this.firstChartDataInterno.push(this.countTrabalhador.length);
      labelsInterno.push('Trabalhador');
    }
    if (this.countRelutante.length > 0) {
      this.firstChartDataInterno.push(this.countRelutante.length);
      labelsInterno.push('Relutante');
    }
    if (this.countAssertivo.length > 0) {
      this.firstChartDataInterno.push(this.countAssertivo.length);
      labelsInterno.push('Assertivo');
    }
    if (this.countIntrospectivo.length > 0) {
      this.firstChartDataInterno.push(this.countIntrospectivo.length);
      labelsInterno.push('Introspectivo');
    }
    if (this.countCentrado.length > 0) {
      this.firstChartDataInterno.push(this.countCentrado.length);
      labelsInterno.push('Centrado');
    }
    if (this.countDedicado.length > 0) {
      this.firstChartDataInterno.push(this.countDedicado.length);
      labelsInterno.push('Dedicado');
    }
    if (this.countIndulgente.length > 0) {
      this.firstChartDataInterno.push(this.countIndulgente.length);
      labelsInterno.push('Indulgente');
    }
    if (this.countElevado.length > 0) {
      this.firstChartDataInterno.push(this.countElevado.length);
      labelsInterno.push('Elevado');
    }
    if (this.countSensivel.length > 0) {
      this.firstChartDataInterno.push(this.countSensivel.length);
      labelsInterno.push('Sensível');
    }
    if (this.countDinamico.length > 0) {
      this.firstChartDataInterno.push(this.countDinamico.length);
      labelsInterno.push('Dinâmico');
    }
    if (this.countExpressivo.length > 0) {
      this.firstChartDataInterno.push(this.countExpressivo.length);
      labelsInterno.push('Expressivo');
    }
    if (this.countDependente.length > 0) {
      this.firstChartDataInterno.push(this.countDependente.length);
      labelsInterno.push('Dependente');
    }
    if (this.countDiscriminador.length > 0) {
      this.firstChartDataInterno.push(this.countDiscriminador.length);
      labelsInterno.push('Discriminador');
    }
    if (this.countFlexivel.length > 0) {
      this.firstChartDataInterno.push(this.countFlexivel.length);
      labelsInterno.push('Flexível');
    }
    if (this.countAberto.length > 0) {
      this.firstChartDataInterno.push(this.countAberto.length);
      labelsInterno.push('Aberto');
    }

    this.firstChartDataInterno.push(0);

    this.chartDatasetsInterno = [
      { data: this.firstChartDataInterno, label: 'Tendência Emocional Interna' }
    ];

    this.chartLabelsInterno = labelsInterno;

    this.updateGraficoInterno();

  }

  updateGraficoInterno() {
    this.chartDatasetsInterno = [
      { data: this.firstChartDataInterno, label: 'Tendência Emocional Interna' }
    ];
  }

  goBack(): void {
    this.location.back();
  }

  goTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

}
