import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { forkJoin, Observable, EMPTY } from 'rxjs';
import { share, switchMap, take } from 'rxjs/operators';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { AvaliacaoApiService } from 'app/api/avaliacaoApi.service';
import { CargoApiService } from 'app/api/cargoApi.service';
import { ClienteApiService } from 'app/api/clienteApi.service';
import { RegisterService } from 'app/api/register.service';
import { ServicoApiService } from 'app/api/servicoApi.service';
import { MensagensApiService } from 'app/api/mensagensApi.service';
import {
  LucorApiModelsAvaliacao,
  LucorApiModelsServico,
  LucorApiModelsPessoaJuridica,
  LucorApiModelsDetailsCargo,
  LucorApiModelsNewPerfil,
  LucorApiModelsDetailsAvaliacaoCargo,
  LucorApiModelsNewNewMensagemEmail } from 'app/model/models';
import { SubSink } from 'subsink';

import { DataTableDirective } from 'angular-datatables';
import { LanguageDataTable } from 'app/shared/datatable/translate-datatable';

@Component({
  selector: 'app-cargos-atualizar',
  templateUrl: './cargos-atualizar.component.html',
  styleUrls: ['./cargos-atualizar.component.css']
})
export class CargosAtualizarComponent implements OnInit, OnDestroy {

  cargoDetalhe: LucorApiModelsDetailsCargo;
  avaliacoes = [];
  avaliacoesDrop: LucorApiModelsAvaliacao[];
  avaliacoesSelecionadas: LucorApiModelsAvaliacao[];
  avaliacoesCliente$: Observable<LucorApiModelsAvaliacao[]>;
  avaliacoesAssociadasCargo$: Observable<LucorApiModelsDetailsAvaliacaoCargo[]>;
  avaliacoesAssociadas: LucorApiModelsDetailsAvaliacaoCargo[];
  cargo$: Observable<LucorApiModelsDetailsCargo>;
  servico$: Observable<LucorApiModelsServico[]>;
  clientes$: Observable<LucorApiModelsPessoaJuridica[]>;
  perfisAxiologia$: Observable<LucorApiModelsNewPerfil[]>;
  perfisComportamento$: Observable<LucorApiModelsNewPerfil[]>;
  perfisMotivadores$: Observable<LucorApiModelsNewPerfil[]>;
  addFormBasico: FormGroup;
  dadosBasicosRef;

  usuarioLogado: number;
  usuarioNome: string;
  idCliente: number;
  nomeCliente: string;
  idCargo: number;
  idServico: number;
  idAvaliacao: number;
  submitted = false;
  atualizando = false;
  carregando = false;
  order = 'nomeArquivoOrigem';
  reverse = true;

  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  orderCondition: number = 3;
 

  initUnidades: number;

  checkAvaliacoes: LucorApiModelsDetailsAvaliacaoCargo[] = [];
  checkAvaliacao = false;

  @ViewChild('linkBasicsData') linkBasicsData: ElementRef;
  @ViewChild('linkAssociation') linkAssociation: ElementRef;
  @ViewChild('linkProfile') linkProfile: ElementRef;
  @ViewChild('basicsData') basicsData: ElementRef;
  @ViewChild('association') association: ElementRef;
  @ViewChild('profile') profile: ElementRef;

  saveUpdate: boolean = false;

  private subs = new SubSink();

  constructor(
    private cargosService: CargoApiService,
    private avaliacoesService: AvaliacaoApiService,
    private clientesService: ClienteApiService,
    private servicosService: ServicoApiService,
    private usuariosService: RegisterService,
    private mensagemService: MensagensApiService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertModalService
  ) {

    this.subs.sink = this.route.data.subscribe(
        (dados) => {
          this.cargoDetalhe = dados.cargosResolver;
          this.idCargo = this.cargoDetalhe.id;
          this.idCliente = this.cargoDetalhe.pessoaJuridicaId;
          this.idServico = this.cargoDetalhe.servicoId;
          this.initUnidades = this.cargoDetalhe.addUnidades;
    });

    this.addFormBasico = this.formBuilder.group({
        titulo: ['', Validators.required],
        descricao: [''],
        area: [''],
        nivel: [''],
        pessoaJuridicaId: [''],
        servicoId: [null],
        qtdeUnidades: [0],
        addUnidades: [0],
        urlLink: [''],
      });

  }

  ngOnInit() {
    this.subs.sink = this.usuariosService.getUsuarioLogadoAsync()
    .subscribe(usuario => {
      this.usuarioNome = usuario.userName;
      this.usuarioLogado = usuario.pessoaJuridicaId;
    });
    this.getClientes();
    if (this.idCliente) {
      this.avaliacoesCliente$ = this.avaliacoesService.getAvaliacoesByCliente(this.idCliente).pipe(share());
      this.getServicoByCliente(this.idCliente);
    }

    this.addFormBasico.setValue({
      titulo: this.cargoDetalhe.titulo,
      descricao: this.cargoDetalhe.descricao,
      area: this.cargoDetalhe.area,
      nivel: this.cargoDetalhe.nivel,
      pessoaJuridicaId: this.cargoDetalhe.pessoaJuridicaId,
      servicoId: this.cargoDetalhe.servicoId || null,
      qtdeUnidades: this.cargoDetalhe.qtdeUnidades || 0,
      addUnidades: this.cargoDetalhe.addUnidades || 0,
      urlLink: this.cargoDetalhe.urlLink || null
    });

    this.dadosBasicosRef = {
      titulo: this.cargoDetalhe.titulo,
      descricao: this.cargoDetalhe.descricao,
      area: this.cargoDetalhe.area,
      nivel: this.cargoDetalhe.nivel,
      pessoaJuridicaId: this.cargoDetalhe.pessoaJuridicaId,
      servicoId: this.cargoDetalhe.servicoId,
      qtdeUnidades: this.cargoDetalhe.qtdeUnidades,
      addUnidades: this.cargoDetalhe.addUnidades,
      urlLink: this.cargoDetalhe.urlLink
    }

    this.avaliacoesAssociadasCargo$ = this.cargosService.getAvaliacoesCargo(this.idCargo).pipe(share());

    const consultaAvaliacoesAssociadas = forkJoin([
        this.avaliacoesAssociadasCargo$.forEach(avaliacoes => {
          avaliacoes.forEach(avaliacao => {
          this.checkAvaliacoes.push(avaliacao);
        });
      })
    ]);

    consultaAvaliacoesAssociadas.subscribe(() => {
      if (this.checkAvaliacoes.length > 0) {
        console.log(this.checkAvaliacoes);
        this.getAvaliacoesAssociadas();
      } else {
        this.getAvaliacoes();
      }
    });

    /// this.getPerfis();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      responsive: true,
      order: [],
      language: LanguageDataTable.portugues_datatables
    };


  }

  saveFull() {
    this.saveUpdate = true;
    this.saveCargo();
  }

  get f() { return this.addFormBasico.controls; }

  /*
  getPerfis() {
    this.perfisAxiologia$ = this.cargosService.getPerfisCargo(this.idCargo, 1);
    this.perfisComportamento$ = this.cargosService.getPerfisCargo(this.idCargo, 2);
    this.perfisMotivadores$ = this.cargosService.getPerfisCargo(this.idCargo, 3);
  }
  */
 
  getClientes() {
    let arrayClientes: LucorApiModelsPessoaJuridica[];
    this.clientes$ = this.clientesService.getClientesAsync().pipe(share());
    this.clientes$.forEach(clientes => {
      arrayClientes = clientes;
      clientes.forEach(cliente => {
      if (cliente.id === this.cargoDetalhe.pessoaJuridicaId) {
        this.nomeCliente = cliente.razaoSocial;
      }
      if (arrayClientes.length === 1) {
          this.getServicoByCliente(cliente.id);
        }
      });
    });
  }

  selectServico(id: number) {
    this.idServico = id;
    if (this.idServico) {
      this.addFormBasico.patchValue({ servicoId: this.idServico });
    }
  }

  getServicoByCliente(id: number) {
    this.servico$ = this.servicosService.getServicoByClienteAsync(id).pipe(share());
  }

  setIdCliente(event: number) {
    this.avaliacoesDrop = [];
    this.avaliacoesSelecionadas = [];
    this.idCliente = event;
    if (!this.idCliente) {
      return this.idCliente = undefined;
    } else {
      this.getAvaliacoesAssociadas();
    }
  }

  getAvaliacoesAssociadas() {

      this.avaliacoesDrop = [];
      this.avaliacoesSelecionadas = [];
      this.avaliacoes = [];
      this.carregando = true;

      this.avaliacoesCliente$.forEach( avaliacoes => {
          avaliacoes.forEach( avaliacao => {
            this.avaliacoes.push(avaliacao);
            this.avaliacoesDrop.push({
              nomeArquivoOrigem: avaliacao.nomeArquivoOrigem,
              createdAt: avaliacao.createdAt,
              updatedAt: avaliacao.updatedAt,
              id: avaliacao.id
            });

            const selectAvaliacao = forkJoin([
              this.avaliacoesAssociadasCargo$.forEach(avaliacoesAssociadas => {
                avaliacoesAssociadas.forEach(avaliacaoAssociada => {
                if (+avaliacao.id === +avaliacaoAssociada.avaliacaoId ) {
                  this.avaliacoesSelecionadas.push({
                    nomeArquivoOrigem: avaliacaoAssociada.nomeArquivo,
                    createdAt: avaliacaoAssociada.createdAt,
                    updatedAt: avaliacaoAssociada.updatedAt,
                    id: avaliacaoAssociada.avaliacaoId
                  });
                } else {
                  this.avaliacoesDrop = this.avaliacoesDrop.filter(
                    drop => +drop.id !== +avaliacaoAssociada.avaliacaoId
                  );
                }
                });
                this.avaliacoesAssociadas = avaliacoesAssociadas;
              }),
            ]);
            selectAvaliacao.subscribe(() => {

              if (this.avaliacoesAssociadas.length === 0 && this.avaliacoesDrop.length === 0) {
                this.avaliacoes.forEach(drop => {
                  this.avaliacoesDrop.push(drop);
                });
              }

              this.carregando = false;

            });
        });
      });
  }

  getAvaliacoes() {

    this.avaliacoesDrop = [];
    this.avaliacoesSelecionadas = [];
    this.carregando = true;

    if (this.idCliente) {
      this.avaliacoesCliente$
        .forEach(avaliacoes => {
          avaliacoes.forEach(avaliacao => {
            this.avaliacoesDrop.push({
              nomeArquivoOrigem: avaliacao.nomeArquivoOrigem,
              createdAt: avaliacao.createdAt,
              updatedAt: avaliacao.updatedAt,
              id: avaliacao.id
            });
          });
      });

      console.log(this.avaliacoesDrop);
      this.carregando = false;

    } else {
      this.carregando = false;
        this.alertService.showAlertInfo('Olá! Este pode ser um Cargo Modelo, por isso, nenhum cliente está associado a ele.');
    }



  }

  saveCargo(): void {

    let formBasico = Object.keys(this.addFormBasico.value);
    let dadosDiferentes = formBasico.some((chave) => { // Verifica se houve alteração nos dados
      return this.addFormBasico.value[chave] !== this.dadosBasicosRef[chave];
    });

    if (dadosDiferentes) { 

      this.submitted = true;
  
      if (this.addFormBasico.invalid) { return; }
      
      let addUnidades = this.addFormBasico.get('addUnidades').value;
  
      this.subs.sink = this.cargosService.atualizarCargoAsync(this.idCargo, this.addFormBasico.value)
      .subscribe(success => {
        if (!this.saveUpdate) {
          this.alertService.showAlertSuccess('Dados Básicos atualizados com sucesso!');
        }
        this.idCargo = success.id;
        if (this.usuarioLogado && addUnidades !== this.initUnidades) {
          this.enviarNotificacao();
          this.initUnidades = success.addUnidades;
        }
        this.dadosBasicosRef = this.addFormBasico.value;
      }, error => {
        console.error(error);
        this.alertService.showAlertDanger('Erro ao atualizar Cargo. Por favor, tente mais tarde.');
      });
      this.goTop();

      

    } else {
      if (!this.saveUpdate) {
        this.alertService.showAlertWarning('Nenhum campo foi editado, por isso, os dados básicos não foram alterados.');
      }
    }

  }

  onChangeAvaliacao(event: any) {

    this.atualizando = true;

    if (event.target.checked === true) {
      this.avaliacoesDrop.forEach(avaliacao => {
        if (+event.target.value === +avaliacao.id) {
          this.avaliacoesSelecionadas.push(avaliacao);
          this.avaliacoesDrop = this.avaliacoesDrop.filter(drop => {
            if (+drop.id !== +event.target.value) {
              return drop;
            }
          });

          this.subs.sink = this.cargosService.associarAvaliacaoCargo(this.idCargo, avaliacao.id)
            .subscribe(success => {
              // this.alertService.showAlertSuccess('Avaliação associada ao cargo com sucesso!');
              this.atualizando = false;
            }, error => {
              this.alertService.showAlertDanger('Erro ao associar avaliação. Tente mais tarde.');
              console.error(error);
              this.atualizando = false;
            });
        }
      });
    } else {
      this.avaliacoesSelecionadas.forEach(avaliacao => {
        if (+avaliacao.id === +event.target.value) {
          this.avaliacoesDrop = this.avaliacoesDrop.filter(drop => +drop.id !== +avaliacao.id);
          this.avaliacoesDrop.push({
            nomeArquivoOrigem: avaliacao.nomeArquivoOrigem,
            createdAt: avaliacao.createdAt,
            updatedAt: avaliacao.updatedAt,
            id: avaliacao.id
          });
          this.subs.sink = this.cargosService.desassociarAvaliacaoCargo(this.idCargo, avaliacao.id)
              .subscribe(success => {
                console.log('Avaliação dessassociada: ', avaliacao.nomeArquivoOrigem);
                this.atualizando = false;
              }, error => {
                console.error(error);
                this.atualizando = false;
              });
        }

        this.avaliacoesSelecionadas = this.avaliacoesSelecionadas.filter(selecao => {
          if (+event.target.value !== +selecao.id) {
            return selecao;
          }
        });
      });
    }
    return this.avaliacoesSelecionadas;
  }

  saveAssociacao() {
    this.alertService.showAlertSuccess('Cargo atualizado com sucesso!');
    setTimeout(() => {
      const link: any[] = ['/cargos', this.idCargo];
      this.router.navigate(link);
    }, 1000);
  }

  onDelete(cargo: LucorApiModelsDetailsCargo) {
    const result$ = this.alertService.showConfirm('Confirmação', 'Tem certeza que deseja excluir esse cargo?');
    result$.asObservable()
    .pipe(
      take(1),
      switchMap(result => result ? this.cargosService.deleteCargoAsync(cargo.id) : EMPTY)
    )
    .subscribe(
      success => {
        this.alertService.showAlertSuccess('Cargo excluído com sucesso!');
        this.router.navigate(['/cargos']);
      },
      error => {
        this.alertService.showAlertDanger('Erro ao excluir cargo. Tente novamente mais tarde.');
      }
    );
  }


  goTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  goBack(): void {
    this.router.navigateByUrl('/SkipPage', {skipLocationChange: true}).then(()=>
    this.router.navigate(['cargos/' + this.idCargo]));
  }

  private enviarNotificacao() {

    let unidades = this.addFormBasico.get('addUnidades').value;
    let cargo = this.cargoDetalhe.titulo;
    let idCargo = this.cargoDetalhe.id;
    let assunto = `[Novas Unidades] solicitadas por ${this.usuarioNome}`;
    let mensagem = `<html> <head> <title>Notificações LUCOR</title> <meta http-equiv="Content-Type" content="text/html; charset=utf-8"> </head> <body bgcolor="#e4e4e4" leftmargin="0" topmargin="0" marginwidth="0" marginheight="0" style="width: 680px; margin: 0 auto;"> <table style="font-family: arial, sans serif; text-align: center;" id="Tabela_01" width="640" height="490" border="0" cellpadding="0" cellspacing="0"> <tr> <td> <img src="https://www.lucor.com.br/img/topo-email-lucor.jpg" width="680" height="141" alt="LUCOR"></td></tr><tr style="background-color: #FFFFFF;"> <td> <p style="padding-top: 20px; font-size: 24px; line-height: 24px; font-weight: bold;">Olá, Luciana! :)</p></td></tr><tr style="background-color: #FFFFFF;"> <td> <p style="font-size: 16px; line-height: 26px; padding: 10px 20px;"><br>Há uma solicitação de ${unidades} novas unidades para o cargo <strong><a href="https://applucor.com.br/cargos/${idCargo}">${cargo}</a></strong>.<br>Cliente: <strong>${this.nomeCliente}</strong><br>Usuário: <strong>${this.usuarioNome}</strong></p></td></tr><tr style="background-color: #FFFFFF;"> <td><a href="https://applucor.com.br/cargos/${idCargo}" target="_blank" type="button" style="background-color: #82d0f5; padding: 10px 20px; font-size: 22px; font-weight: bold; border-radius: 8px 8px; border: none; cursor: pointer; margin: 20px auto 20px; display: table; text-decoration: none; color: #373431">Ver o cargo</a></td></tr><tr> <td><br><small>Mensagem enviada automaticamente. Não é necessário responder. <br><br></small></td></tr><tr> <td> <p style="font-size: 16px; font-weight: bold; padding: 20px 10px;">www.lucor.com.br</p></td></tr></table> </body> </html>`;
    
    let notificacao: LucorApiModelsNewNewMensagemEmail = {
      "destinatarios": [
        "luciana.orso@lucor.com.br"
      ],
      "assunto": assunto,
      "corpo": mensagem,
      "corpoEhHtml": true,
      "cc": ["fabricio.soares@lucor.com.br"],
      "cco": [],
      "tipoMensagem": null,
      "enviarImediatamente": true
    }
    
    this.subs.sink =  this.mensagemService.addRacunhoEmailAsync(notificacao)
      .subscribe(enviado => { console.log(enviado)}, error => console.error(error));
    
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
