import { Component, OnInit, OnDestroy } from '@angular/core';
import { CookieService } from '@ngx-toolkit/cookie';
import { LucorApiModelsAvaliacao } from 'app/model/lucorApiModelsAvaliacao';
import { AvaliacaoApiService } from 'app/api/avaliacaoApi.service';
import { SubSink } from 'subsink';
import Driver from 'driver.js';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {

  avaliacoes: LucorApiModelsAvaliacao[];
  avaliados = new Array<any>();

  order = 'dataImportacao';
  reverse = true;

  checkCookie = false;
  disableTour = false;
  driver = new Driver({
    className: 'tour-lucor',
    doneBtnText: 'Entendi', // Text on the final button
    closeBtnText: 'Fechar', // Text on the close button for this step
    nextBtnText: 'Próximo', // Next button text for this step
    prevBtnText: 'Anterior', // Previous button text for this step
  });

  private subs = new SubSink();

  constructor(
    private avaliacoesService: AvaliacaoApiService,
    private cookieService: CookieService) {}

  ngOnInit() {
    this.checkCookie = this.cookieService.hasItem('tourHome');
    this.getAvaliacoes();
  }

  startTour(): void {
    this.disableTour = true;
    // Define the steps for tour
    setTimeout(() => {
      this.driver.defineSteps([
        {
          element: '#one-element-tour',
          popover: {
            className: 'first-step-popover-class',
            title: 'Vamos fazer um tour?',
            description: 'Você pode usar as teclas <i class="fas fa-caret-left icon-tour"></i> e <i class="fas fa-caret-right icon-tour"></i> para avançar e voltar. Acesse aqui sempre que precisar.',
            position: 'left',
            closeBtnText: 'Eu vejo depois.',
            nextBtnText: 'Vamos lá!',
            prevBtnText: '<i class="fas fa-plane-departure"></i>'
          }
        },
        {
          element: '#two-element-tour',
          popover: {
            title: 'Avaliações cadastradas',
            description: 'As avaliações são os "bancos de dados" dos avaliados que responderam a ferramenta de análise comportamental.',
            position: 'right'
          }
        },
        {
          element: '#three-element-tour',
          popover: {
            title: 'Pessoas',
            description: 'Essa é a quantidade total de pessoas que você tem disponível para analisar.',
            position: 'right'
          }
        },
        {
          element: '#four-element-tour',
          popover: {
            title: 'Cargos',
            description: 'Esta é a área onde você poderá criar perfis para cargos ou programas.<br> Ao associar uma avalição ao perfil criado já é possível visualizar o ranking de aproximação.',
            position: 'right'
          }
        },
        {
          element: '#five-element-tour',
          popover: {
            title: 'Mapeamento',
            description: 'Aqui você tem acesso a todas as informações dos avaliados. Os dados estarão disponíveis em números e gráficos, tanto do indíviduo quanto do grupo.',
            position: 'left'
          }
        },
        {
          element: '#six-element-tour',
          popover: {
            title: 'Perfis Clone',
            description: 'Visualize um perfil clone gerado a partir de outros perfis de referência e crie cargos com os perfis clonados.',
            position: 'left'
          }
        },
        {
          element: '#eight-element-tour',
          popover: {
            title: 'Avaliações Recentes',
            description: 'Esta lista mostra as últimas avaliações que foram cadastradas na plataforma. Você pode clicar em "Detalhes" para ver todos os dados da avaliação.',
            position: 'top'
          }
        }
      ]);
      // Start the introduction
      this.driver.start();
      this.disableTour = false;
    }, 2000);
  }

  setCookie() {
    this.cookieService.setItem( 'tourHome', '1' );
  }


  getAvaliacoes(): void {
    this.subs.sink = this.avaliacoesService.getAvaliacoesAsync()
      .subscribe(avaliacoes => {
        this.avaliacoes = avaliacoes; 
        setTimeout(() => {
          if (!this.checkCookie) {
              this.startTour();
              this.setCookie();
          }
        }, 4000);
      });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
