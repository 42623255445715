import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { 
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    public authService: AuthService,
    private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      let handled: boolean = false;
  
      if (this.authService.getJwtToken()) {
        request = this.addToken(request, this.authService.getJwtToken());
      }

      return next.handle(request)
      .pipe(
        retry(1),
        catchError((returnedError) => {
          let errorMessage = null;
  
          if (returnedError.error instanceof ErrorEvent) {
            errorMessage = `Error Event: ${returnedError.error.message}`;
          } else if (returnedError instanceof HttpErrorResponse) {
            errorMessage = `${returnedError.error}`;
            handled = this.handleServerSideError(returnedError);
          } 
  
          // console.error(errorMessage ? errorMessage : returnedError);
          
          if (!handled) {
            if (errorMessage) {
              return throwError(errorMessage);
            } else {
              return throwError("Ocorreu um problema inesperado!");
            }
          } else {
            return of(returnedError);
          }
        })
      )
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private handleServerSideError(error: HttpErrorResponse): boolean {
    let handled: boolean = false;
    switch (error.status) {
      case 401:
        console.error("Acesso não autorizado! Necessário realizar o login.");
        this.authService.logout();
        handled = true;
        break;
      case 403:
        console.warn("Esse usuário não tem permissão para acessar essa página!");
        this.router.navigate(['/page-error']);
        handled = true;
        break;
      case 404:
        console.warn("Essa página não existe!");
        this.router.navigate(['/page-error']);
        handled = true;
        break;
      case 500:
        console.warn("O servidor encontrou uma situação com a qual não sabe lidar.");
        this.router.navigate(['/page-error']);
        handled = true;
        break;
      case 503:
        console.warn("O servidor está indisponível.");
        this.router.navigate(['/page-error']);
        handled = true;
        break;
      case 504:
        console.warn("O servidor está demorando para responder.");
        this.router.navigate(['/page-error']);
        handled = true;
        break;
      case 0:
        console.error("Status 0. Ocorreu um erro desconhecido!");
        this.router.navigate(['/page-error']);
        handled = true;
        break;
    }
    return handled;
  }

}
