import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LucorApiModelsNewUpdUser } from 'app/model/lucorApiModelsNewUpdUser';
import { LucorApiModelsUpdUpdPassword } from 'app/model/lucorApiModelsUpdUpdPassword';
import { LucorApiModelsPessoaJuridica } from 'app/model/lucorApiModelsPessoaJuridica';
import { LucorApiModelsDetailsUser } from 'app/model/lucorApiModelsDetailsUser';
import { RegisterService } from 'app/api/register.service';
import { ClienteApiService } from 'app/api/clienteApi.service';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { SubSink } from 'subsink';


@Component({
  selector: 'app-usuarios-editar',
  templateUrl: './usuarios-editar.component.html',
  styleUrls: ['./usuarios-editar.component.css']
})
export class UsuariosEditarComponent implements OnInit, OnDestroy {

  usuario: LucorApiModelsNewUpdUser;
  usuarioLogado: LucorApiModelsDetailsUser;
  cliente: LucorApiModelsPessoaJuridica;
  clientes: LucorApiModelsPessoaJuridica[];
  novaSenha: LucorApiModelsUpdUpdPassword;
  idUsuario: string;
  idCliente: number;

  alterarSenhaForm: FormGroup;

  private subs = new SubSink();

  constructor(
    private usuariosService: RegisterService,
    private clientesService: ClienteApiService,
    private usuarioLogadoService: RegisterService,
    private route: ActivatedRoute,
    private location: Location,
    private formBuilder: FormBuilder,
    private alertService: AlertModalService) {}

  ngOnInit() {
    this.idUsuario = this.route.snapshot.paramMap.get('Id');
    this.getUsuarioLogado();
    this.getClientes();

    this.alterarSenhaForm = this.formBuilder.group({
      senhaAtual: [''],
      novaSenha: [''],
    });
  }

  getUsuarioLogado() {
    this.subs.sink = this.usuarioLogadoService.getUsuarioLogadoAsync()
    .subscribe(
      usuarioLogado => {
        this.usuarioLogado = usuarioLogado;
        this.getUsuario();
      }
    );
  }

  getUsuario() {
    if (this.usuarioLogado.pessoaJuridicaId === null) { 
      this.subs.sink = this.usuariosService.getUsuarioByIdAsync(this.idUsuario)
      .subscribe(
        usuario => {
          this.usuario = usuario; // Define o usuário que está sendo editado
          this.getCliente();
        }
      );
    } else { 
      this.usuario = this.usuarioLogado; 
      this.getCliente();
    }
  }

  getClientes() {
    this.subs.sink = this.clientesService.getClientesAsync()
        .subscribe(
        clientes => {
          this.clientes = clientes;
        }
      );
  }

  getCliente() {
    if (this.usuario) {
      this.idCliente = this.usuario.pessoaJuridicaId;
      if (this.idCliente !== null) {
        this.subs.sink = this.clientesService.getClienteById(this.idCliente)
        .subscribe(
        cliente => {
          this.cliente = cliente;
        }
      );
      }
    }
  }

  saveUsuario(): void {
    if (this.usuarioLogado.pessoaJuridicaId === null) {
      this.subs.sink = this.usuariosService.atualizarUsuarioAsync(this.idUsuario, this.usuario)
      .subscribe(success => {
        this.alertService.showAlertSuccess('Usuário salvo com sucesso!');
        this.location.back();
      }, error => {
        console.log(error);
        this.alertService.showAlertDanger('Erro ao salvar usuário. Tente novamente mais tarde.');
      });
    } else {
      this.subs.sink = this.usuariosService.atualizarUsuarioLogadoAsync(this.usuario)
      .subscribe(success => {
        this.alertService.showAlertSuccess('Usuário salvo com sucesso!');
        this.location.back();
      }, error => {
        console.log(error);
        this.alertService.showAlertDanger('Erro ao salvar usuário. Tente novamente mais tarde.');
      });
    }
  }

  alterarSenha(): void {
    if (this.usuarioLogado.pessoaJuridicaId === null) {
      this.subs.sink = this.usuariosService.atualizarSenhaAsync(this.idUsuario, this.alterarSenhaForm.value)
      .subscribe(success => {
        this.alertService.showAlertSuccess('Senha alterada com sucesso!');
        this.resetFormSenha();
      }, error => {
        console.log(error);
        this.alertService.showAlertDanger('Erro ao alterar senha. Tente novamente mais tarde.');
      });
    } else {
      this.subs.sink = this.usuariosService.atualizarSenhaUsuarioLogadoAsync(this.alterarSenhaForm.value)
      .subscribe(success => {
        this.alertService.showAlertSuccess('Senha alterada com sucesso!');
        this.resetFormSenha();
      }, error => {
        console.log(error);
        this.alertService.showAlertDanger('Erro ao alterar senha. Tente novamente mais tarde.');
      });
    }
  }

  goBack(): void {
    this.location.back();
  }

  resetFormSenha() {
    this.alterarSenhaForm.reset();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}

