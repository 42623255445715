import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { take, switchMap } from 'rxjs/operators';
import { RegisterService } from 'app/api/register.service';
import { ClienteApiService } from 'app/api/clienteApi.service';
import { ServicoApiService } from 'app/api/servicoApi.service';
import { LucorApiModelsDetailsUser } from 'app/model/lucorApiModelsDetailsUser';
import { LucorApiModelsPessoaJuridica } from 'app/model/lucorApiModelsPessoaJuridica';
import { LucorApiModelsServico } from 'app/model/lucorApiModelsServico';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { DataTableDirective } from 'angular-datatables';
import { LanguageDataTable } from 'app/shared/datatable/translate-datatable';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-servicos',
  templateUrl: './servicos.component.html',
  styleUrls: ['./servicos.component.css']
})
export class ServicosComponent implements OnInit, OnDestroy {

  clientes$: Observable<LucorApiModelsPessoaJuridica[]>;
  cliente$: Observable<LucorApiModelsPessoaJuridica>;
  servicos$: Observable<LucorApiModelsServico[]>;
  usuarioLogado: LucorApiModelsDetailsUser;

  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};

  private subs = new SubSink();

  constructor(
    private servicosService: ServicoApiService,
    private clientesService: ClienteApiService,
    private usuarioService: RegisterService,
    private alertService: AlertModalService) { }

  ngOnInit() {
    this.getUsuarioLogado();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      processing: true,
      responsive: true,
      order: [[0, "asc"]],
      language: LanguageDataTable.portugues_datatables
    };
  }

  onDelete(servico: LucorApiModelsServico) {
    const result$ = this.alertService.showConfirm('Confirmação', 'Tem certeza que deseja excluir esse serviço?');
    result$.asObservable()
      .pipe(
        take(1),
        switchMap(result => result ? this.servicosService.deleteServicoAsync(servico.id) : EMPTY)
      )
      .subscribe(
        success => {
          this.alertService.showAlertSuccess('Serviço excluído com sucesso!');
          this.ngOnInit();
        },
        error => {
          this.alertService.showAlertDanger('Erro ao excluir o serviço. Tente novamente mais tarde.');
        }
      );
  }

  private getUsuarioLogado() {
    this.subs.sink = this.usuarioService.getUsuarioLogadoAsync()
      .subscribe(usuarioLogado => {
        this.usuarioLogado = usuarioLogado;
        if (this.usuarioLogado.pessoaJuridicaId !== null) {
          this.getServicosByCliente(this.usuarioLogado.pessoaJuridicaId);
        } else {
          this.getServicos();
          this.getClientes();
        }
      });
  }

  private getClientes() {
    this.clientes$ = this.clientesService.getClientesAsync();
  }

  private getServicos(): void {
    this.servicos$ = this.servicosService.getServicosAsync();
  }

  private getServicosByCliente(id: number): void {
    this.servicos$ = this.servicosService.getServicoByClienteAsync(id);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
