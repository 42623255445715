import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { LucorApiModelsUpdFiltroFator } from 'app/model/lucorApiModelsUpdFiltroFator';
import { LucorApiModelsCiencia } from 'app/model/lucorApiModelsCiencia';
import { CienciaApiService } from 'app/api/cienciaApi.service';
import { LucorApiModelsFator } from 'app/model/lucorApiModelsFator';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-set-palavras',
  templateUrl: './set-palavras.component.html',
  styleUrls: ['./set-palavras.component.css']
})
export class SetPalavrasComponent implements OnInit, OnDestroy {

  ciencia: LucorApiModelsCiencia;
  filtroFator: LucorApiModelsUpdFiltroFator;
  filtroNome: string;
  palavrasChave: string;
  @Input() idCiencia: number;
  @Input() fatores: LucorApiModelsFator;

  private subs = new SubSink();

  constructor(
    private cienciaService: CienciaApiService,
    private alertService: AlertModalService) { }

  ngOnInit() {
    this.filtroNome = this.fatores.nome;
    this.palavrasChave = this.fatores.palavrasChave;
  }

  cadastraPalavras(): void {

    this.filtroFator = { filtroPorNome: this.filtroNome, palavrasChave: this.palavrasChave };

    this.subs.sink = this.cienciaService.updateFatoresCiencia(this.idCiencia, this.filtroFator)
    .subscribe(success => {
      this.alertService.showAlertSuccess('Palavras-chave salvas com sucesso!');
    }, error => {
      console.log(error);
      this.alertService.showAlertDanger('Erro ao salvar Palavras-chave. Tente mais tarde.');
    });

  }

  
  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
