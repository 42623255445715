import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SetPerfilCargo } from './set-perfil-cargo';

@Component({
  selector: 'app-set-perfil-cargo',
  templateUrl: './set-perfil-cargo.component.html',
  styleUrls: ['./set-perfil-cargo.component.css']
})
export class SetPerfilCargoComponent implements OnInit {

  perfilIdeal;
  perfilMinimo;
  perfilMaximo;
  @Input() perfilCargo: SetPerfilCargo;
  noCheck: boolean;

  @Input() nomeCiencia;
  @Output() setReset = new EventEmitter();
  @Output() selectedFatores = new EventEmitter();
  @Output() confirmaFatores = new EventEmitter();

  private _reset: boolean;

  constructor() { }

  get reset(): boolean {
    // transform value for display
    return this._reset;
  }
  @Input()
  set reset(reset: boolean) {
    // console.log('got reset: ', reset);
    this._reset = reset;

    if (reset === true) {
      this.resetForm();
    } else { reset = false; }

  }

  ngOnInit() {
    /*
    if (this.perfilCargo.fator === null) {
      this.noCheck = true;
      this.perfilCargo.selected = true;
    }
    */
    this.noCheck = true;
    this.perfilCargo.selected = true;
  }

  onChangeFatores(event) {
    this.perfilCargo.selected = event.target.checked;
    this.perfilCargo.fator = event.target.value;
    this.confirmaFatores.emit();
  }
  onChangeMin(event) {
    this.perfilCargo.minimo = event;
    this.confirmaFatores.emit();
  }
  onChangeIdeal(event) {
    this.perfilCargo.ideal = event;
    this.confirmaFatores.emit();
  }
  onChangeMax(event) {
    this.perfilCargo.maximo = event;
    this.confirmaFatores.emit();
  }
  resetForm(): void {
    this.perfilCargo.selected = false;
    this.perfilIdeal = 'Ideal';
    this.perfilMinimo = 'Mínimo';
    this.perfilMaximo = 'Máximo';
    this.updateReset();
}

updateReset() {
  setTimeout(() => {
    this.setReset.emit(false);
  }, 1000);
}
}
