import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../core/auth/guards/auth.guard';
import { AvaliacoesComponent } from './components/avaliacoes/avaliacoes.component';
import { AvaliacoesDetalhesComponent } from './components/avaliacoes-detalhes/avaliacoes-detalhes.component';
import { AvaliacoesAvaliadosComponent } from './components/avaliacoes-avaliados/avaliacoes-avaliados.component';
import { AvaliacoesCompararComponent } from './components/avaliacoes-comparar/avaliacoes-comparar.component';
import { AvaliacoesEstatisticasComponent } from './components/avaliacoes-estatisticas/avaliacoes-estatisticas.component';
import { AvaliacoesFiltroComponent } from './components/avaliacoes-filtro/avaliacoes-filtro.component';
import { AvaliacoesCadastrarComponent } from './components/avaliacoes-cadastrar/avaliacoes-cadastrar.component';
// import { AvaliacoesResolver } from '../../core/resolvers/avaliacoes.resolver';

const avaliacoesRoutes: Routes = [

  { path: 'avaliacoes', canActivate: [AuthGuard],
    data: { breadcrumbs: 'Avaliações' },
    children: [
      { path: '', component: AvaliacoesComponent, canActivate: [AuthGuard] },
      { path: 'cadastrar', component: AvaliacoesCadastrarComponent, canActivate: [AuthGuard],
        data: { breadcrumbs: 'Cadastrar Avaliações' }
      },
      { path: ':Id', canActivate: [AuthGuard],
        // resolve: { avaliacaoResolver: AvaliacoesResolver },
        // data: { breadcrumbs: '{{ avaliacaoResolver.nomeArquivoOrigem }}' },
        data: { breadcrumbs: 'Detalhes da Avaliação' },
        children: [
          { path: '', component: AvaliacoesDetalhesComponent, canActivate: [AuthGuard] },

          { path: 'comparar', component: AvaliacoesCompararComponent, canActivate: [AuthGuard],
            data: { breadcrumbs: 'Comparar Perfis' }
          },

          { path: 'estatisticas', component: AvaliacoesEstatisticasComponent, canActivate: [AuthGuard],
            data: { breadcrumbs: 'Dados do Grupo' }
          },

          { path: 'avaliado/:Av', component: AvaliacoesAvaliadosComponent, canActivate: [AuthGuard],
            data: { breadcrumbs: 'Perfil do Avaliado' }
          },

          { path: ':IdCl/:IdSer', component: AvaliacoesFiltroComponent, canActivate: [AuthGuard],
            data: { breadcrumbs: 'Analisar Perfis' }
          },
        ]
      }
    ]
  }
];


@NgModule({
  imports: [RouterModule.forChild(avaliacoesRoutes)],
  exports: [RouterModule]
})
export class AvaliacoesRoutingModule { }

