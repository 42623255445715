import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-error',
  templateUrl: './page-error.component.html',
  styleUrls: ['./page-error.component.css']
})
export class PageErrorComponent implements OnInit {

  constructor(
    private location: Location,
    private router: Router) { }

  ngOnInit(): void {
  }

  goBack(): void {
    this.router.navigate(['/home']);
  }

}
