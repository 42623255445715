/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.110
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 *
 */
export interface LucorApiModelsNewServico {
    codigo: string;
    codigoTipoServico: string;
    dataOrcamento?: Date;
    dataExecucao?: Date;
    valorOrcado?: number;
    valorFaturado?: number;
    unidadesContratadas?: number;
    unidadesUtilizadas?: number;
    unidadesRestantes?: number;
}
