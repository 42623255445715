import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filterAll'
})
export class FilterPipeAll implements PipeTransform {

  transform(items: any[], searchText: string): any[] {
    if (!items) { return []; }
    if (!searchText) { return items; }

    searchText = searchText.toLowerCase();

    return items.filter(it => {

      if (it.palavrasChave && it.palavrasChave !== null) { // Fatores em Ciências

        return (it.palavrasChave.toLowerCase().includes(searchText) || it.nome.toLowerCase().includes(searchText));

      } else if (it.fator || it.fator === null) {

         if (it.fator === null) {
           return false;
         } else {
           it.fator = it.fator;
         }

         return it.fator.toLowerCase().includes(searchText);

      } else if (it.nomeFator || it.nomeFator === null) { // Fatores

        if (it.nomeFator === null) {
          return false;
        } else {
          it.nomeFator = it.nomeFator;
        }

        return it.nomeFator.toLowerCase().includes(searchText);

      } else if (it.nomeArquivoOrigem || it.nomeArquivoOrigem === null) { // Arquivos

        if (it.nomeArquivoOrigem === null) {
          return false;
        } else {
          it.nomeArquivoOrigem = it.nomeArquivoOrigem;
        }

        return it.nomeArquivoOrigem.toLowerCase().includes(searchText);

      } else if (it.userName || it.userName === null) { // Usuários

        if (it.userName === null) {
          return false;
        } else {
          it.userName = it.userName;
        }

        return it.userName.toLowerCase().includes(searchText);

      } else if (it.nomeAvaliado || it.nomeAvaliado === null) { // Avaliados

        if (it.nomeAvaliado === null) {
          return false;
        } else {
          it.nomeAvaliado = it.nomeAvaliado;
        }

        return it.nomeAvaliado.toLowerCase().includes(searchText);

      } else if (it.titulo || it.titulo === null) { // Cargos

        if (it.titulo === null) {
          return false;
        } else {
          it.titulo = it.titulo;
        }

        return it.titulo.toLowerCase().includes(searchText);

      } else if (it.tipoAcao || it.tipoAcao === null) { // Auditoria

        if (it.tipoAcao === null) {
          return false;
        } else {
          it.tipoAcao = it.tipoAcao;
        }

        return it.tipoAcao.toLowerCase().includes(searchText);

      } else {

        return it.nome.toLowerCase().includes(searchText);

      }

    });
  }

}
