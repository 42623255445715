/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.45
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { LucorApiModelsNewServico } from '../model/lucorApiModelsNewServico';
import { LucorApiModelsServico } from '../model/lucorApiModelsServico';
import { LucorApiModelsUpdServico } from '../model/lucorApiModelsUpdServico';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { environment } from 'environments/environment';
import { publishReplay, refCount } from 'rxjs/operators';


@Injectable()
export class ServicoApiService {

    protected basePath = environment.apiBasePath;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    _servicos: Observable<any> = null; // Variável criada para utilizar o Cache ###


    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    clearCache() {
        this._servicos = null; // Limpa a variável para excluir o Cache das avaliações. ###
    }

    /**
     * Incluir Novo Serviço
     *
     * @param clienteId
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addServicoAsync(clienteId: number, body?: LucorApiModelsNewServico, observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsServico>;
    public addServicoAsync(clienteId: number, body?: LucorApiModelsNewServico, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsServico>>;
    public addServicoAsync(clienteId: number, body?: LucorApiModelsNewServico, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsServico>>;
    public addServicoAsync(clienteId: number, body?: LucorApiModelsNewServico, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clienteId === null || clienteId === undefined) {
            throw new Error('Required parameter clienteId was null or undefined when calling addServicoAsync.');
        }

        this.clearCache(); // ###

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LucorApiModelsServico>(`${this.basePath}/servico/cliente/${encodeURIComponent(String(clienteId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Excluir Serviço
     *
     * @param servicoId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteServicoAsync(servicoId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteServicoAsync(servicoId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteServicoAsync(servicoId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteServicoAsync(servicoId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (servicoId === null || servicoId === undefined) {
            throw new Error('Required parameter servicoId was null or undefined when calling deleteServicoAsync.');
        }

        this.clearCache(); // ###

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [];

        return this.httpClient.delete<any>(`${this.basePath}/servico/${encodeURIComponent(String(servicoId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Listar Serviços do Cliente
     *
     * @param clienteId
     * @param withoutChildren
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getServicoByClienteAsync(clienteId: number, withoutChildren?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<LucorApiModelsServico>>;
    public getServicoByClienteAsync(clienteId: number, withoutChildren?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LucorApiModelsServico>>>;
    public getServicoByClienteAsync(clienteId: number, withoutChildren?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LucorApiModelsServico>>>;
    public getServicoByClienteAsync(clienteId: number, withoutChildren?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clienteId === null || clienteId === undefined) {
            throw new Error('Required parameter clienteId was null or undefined when calling getServicoByClienteAsync.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (withoutChildren !== undefined && withoutChildren !== null) {
            queryParameters = queryParameters.set('withoutChildren', withoutChildren as any);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<LucorApiModelsServico>>(`${this.basePath}/servico/cliente/${encodeURIComponent(String(clienteId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Obter Serviço pelo Id
     *
     * @param servicoId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getServicoByIdAsync(servicoId: number, observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsServico>;
    public getServicoByIdAsync(servicoId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsServico>>;
    public getServicoByIdAsync(servicoId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsServico>>;
    public getServicoByIdAsync(servicoId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (servicoId === null || servicoId === undefined) {
            throw new Error('Required parameter servicoId was null or undefined when calling getServicoByIdAsync.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [];

        return this.httpClient.get<LucorApiModelsServico>(`${this.basePath}/servico/${encodeURIComponent(String(servicoId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Listar Serviços
     *
     * @param withoutChildren
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getServicosAsync(withoutChildren?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<LucorApiModelsServico>>;
    public getServicosAsync(withoutChildren?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LucorApiModelsServico>>>;
    public getServicosAsync(withoutChildren?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LucorApiModelsServico>>>;
    public getServicosAsync(withoutChildren?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (withoutChildren !== undefined && withoutChildren !== null) {
            queryParameters = queryParameters.set('withoutChildren', withoutChildren as any);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (!this._servicos) { // ###
            this._servicos = this.httpClient.get<Array<LucorApiModelsServico>>(`${this.basePath}/servico`,
                {
                  params: queryParameters,
                  withCredentials: this.configuration.withCredentials,
                  headers,
                  observe,
                  reportProgress
                }
            ).pipe(publishReplay(1), refCount());
        }
        return this._servicos; // ###
    }

    /**
     * Atualizar Serviço
     *
     * @param servicoId
     * @param clienteId
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateServicoAsync(servicoId: number, clienteId: number, body?: LucorApiModelsUpdServico, observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsServico>;
    public updateServicoAsync(servicoId: number, clienteId: number, body?: LucorApiModelsUpdServico, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsServico>>;
    public updateServicoAsync(servicoId: number, clienteId: number, body?: LucorApiModelsUpdServico, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsServico>>;
    public updateServicoAsync(servicoId: number, clienteId: number, body?: LucorApiModelsUpdServico, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (servicoId === null || servicoId === undefined) {
            throw new Error('Required parameter servicoId was null or undefined when calling updateServicoAsync.');
        }

        if (clienteId === null || clienteId === undefined) {
            throw new Error('Required parameter clienteId was null or undefined when calling updateServicoAsync.');
        }

        this.clearCache(); // ###

        let headers = this.defaultHeaders;


        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<LucorApiModelsServico>(`${this.basePath}/servico/${encodeURIComponent(String(servicoId))}/cliente/${encodeURIComponent(String(clienteId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

}
