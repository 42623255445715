import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { ServicosCadastrarComponent } from './components/servicos-cadastrar/servicos-cadastrar.component';
import { ServicosDetalhesComponent } from './components/servicos-detalhes/servicos-detalhes.component';
import { ServicosEditarComponent } from './components/servicos-editar/servicos-editar.component';
import { ServicosComponent } from './components/servicos/servicos.component';


const servicosRoutes: Routes = [

    { path: 'servicos', data: { breadcrumbs: 'Serviços' },
        canActivate: [AuthGuard],
        children: [
            { path: '', component: ServicosComponent, canActivate: [AuthGuard] },
            { path: 'cadastrar', component: ServicosCadastrarComponent,
                data: { breadcrumbs: 'Cadastrar serviço' },
                canActivate: [AuthGuard]
            },
            { path: ':Id',
                data: { breadcrumbs: 'Detalhes do serviço' },
                canActivate: [AuthGuard],
                children: [
                    { path: '', component: ServicosDetalhesComponent, canActivate: [AuthGuard] },
                    { path: 'editar', component: ServicosEditarComponent,
                        data: { breadcrumbs: 'Editar serviço' },
                        canActivate: [AuthGuard]
                    },
                ]
            },
        ]
    }

];

@NgModule({
    imports: [RouterModule.forChild(servicosRoutes)],
    exports: [RouterModule]
})

export class ServicosRoutingModule {}
