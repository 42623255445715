/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.45
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 *
 */
export interface LucorApiModelsNewEndereco {
    /**
     * Gets or Sets Logradouro
     */
    logradouro: string;
    /**
     * Gets or Sets Numero
     */
    numero: string;
    /**
     * Gets or Sets Complemento
     */
    complemento?: string;
    /**
     * Gets or Sets Bairro
     */
    bairro: string;
    /**
     * Gets or Sets Municipio
     */
    municipio: string;
    /**
     * Gets or Sets Estado
     */
    estado: string;
    /**
     * Gets or Sets Pais
     */
    pais: string;
    /**
     * Gets or Sets CEP
     */
    cep: string;
}
