import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PopoverModule } from 'angular-bootstrap-md';
import { OrderModule } from 'ngx-order-pipe';
import { SharedModule } from 'app/shared/shared.module';
import { ServicosComponent } from './components/servicos/servicos.component';
import { ServicosDetalhesComponent } from './components/servicos-detalhes/servicos-detalhes.component';
import { ServicosEditarComponent } from './components/servicos-editar/servicos-editar.component';
import { ServicosCadastrarComponent } from './components/servicos-cadastrar/servicos-cadastrar.component';
import { ServicosRoutingModule } from './servicos.routing.module';
// import { CurrencyMaskModule } from 'ngx-currency-mask';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        SharedModule,
        OrderModule,
        PopoverModule,
        // CurrencyMaskModule,
        ServicosRoutingModule
    ],
    declarations: [
        ServicosComponent,
        ServicosDetalhesComponent,
        ServicosEditarComponent,
        ServicosCadastrarComponent,
    ],
    providers: [
        SharedModule,
        OrderModule
      ],
  })

  export class ServicosModule {}
