import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { ClientesRoutingModule } from './clientes.routing.module';
import { ClientesComponent } from './components/clientes/clientes.component';
import { DetalhesClienteComponent } from './components/detalhes-cliente/detalhes-cliente.component';
import { EditarClienteComponent } from './components/editar-cliente/editar-cliente.component';
import { AdicionarClienteComponent } from './components/adicionar-cliente/adicionar-cliente.component';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    SharedModule,
    ClientesRoutingModule
  ],
  declarations: [
    ClientesComponent,
    DetalhesClienteComponent,
    EditarClienteComponent,
    AdicionarClienteComponent
  ]
})

export class ClientesModule {}
