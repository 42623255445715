import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { CienciaApiService } from 'app/api/api';
import { LucorApiModelsCiencia } from 'app/model/lucorApiModelsCiencia';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-palavras-chave',
  templateUrl: './palavras-chave.component.html',
  styleUrls: ['./palavras-chave.component.css']
})
export class PalavrasChaveComponent implements OnInit, OnDestroy {

  ciencia: LucorApiModelsCiencia;
  usuarioLogado: string;
  idCiencia: number;
  nomeCiencia: string;

  order = 'nome';
  searchText = '';

  private subs = new SubSink();

  constructor(
    private cienciasService: CienciaApiService,
    private location: Location,
    private route: ActivatedRoute) {
      this.idCiencia = +this.route.snapshot.paramMap.get('Id');
      this.nomeCiencia = this.route.snapshot.paramMap.get('Ci');
      this.usuarioLogado = localStorage.getItem('userRole');
     }

  ngOnInit() {
    this.getUsuarioLogado();
    this.getFatores();
    this.goTop();
  }

  getUsuarioLogado() {
    return this.usuarioLogado;
  }

  getFatores() {
    this.subs.sink = this.cienciasService.getCienciaByIdAsync(this.idCiencia)
    .subscribe(ciencia => {
      this.ciencia = ciencia;
    });
  }

  goTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  /*
  * Campo de pesquisa por nome do fator
  */
  setSearchTerm(term: string) {
    this.searchText = term;
  }

  getSearchTerm() {
    return this.searchText;
  }

  clearFilter() {
    this.searchText = '';
  }

  goBack(): void {
    this.location.back();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }


}
