import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Observable, EMPTY } from 'rxjs';
import { take, switchMap, share } from 'rxjs/operators';
import { CookieService } from '@ngx-toolkit/cookie';
import Driver from 'driver.js';
import { SubSink } from 'subsink';
import { LucorApiModelsAvaliacao } from 'app/model/lucorApiModelsAvaliacao';
import { LucorApiModelsServico } from 'app/model/lucorApiModelsServico';
import { AvaliacaoApiService } from 'app/api/avaliacaoApi.service';
import { ServicoApiService } from 'app/api/servicoApi.service';
import { LucorApiModelsPessoaJuridica } from 'app/model/lucorApiModelsPessoaJuridica';
import { ClienteApiService } from 'app/api/clienteApi.service';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { DataTableDirective } from 'angular-datatables';
import { LanguageDataTable } from 'app/shared/datatable/translate-datatable';


@Component({
  selector: 'app-avaliacoes',
  templateUrl: './avaliacoes.component.html',
  styleUrls: ['./avaliacoes.component.css']
})

export class AvaliacoesComponent implements OnInit, OnDestroy {

  servicos$: Observable<LucorApiModelsServico[]>;
  clientes$: Observable<LucorApiModelsPessoaJuridica[]>;
  avaliacoes$: Observable<LucorApiModelsAvaliacao[]>;
  avaliacoesByCliente: LucorApiModelsAvaliacao[];
  avaliacoesTotais: LucorApiModelsAvaliacao[];
  atualizar = false;
  idAvaliacao: number;
  avaliacaoByCliente: LucorApiModelsAvaliacao;
  getAvaliacoesClientes =  false;
  usuarioLogado: string;
  loadingLista = false;

  order = 'dataImportacao';
  reverse = true;
  orderCondition: number = 2;

  checkCookie = false;
  disableTour = false;
  driver = new Driver({
    className: 'tour-lucor',
    doneBtnText: 'Entendi', // Text on the final button
    closeBtnText: 'Fechar', // Text on the close button for this step
    nextBtnText: 'Próximo', // Next button text for this step
    prevBtnText: 'Anterior', // Previous button text for this step
  });

  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  filterCliente: string;
  dtOptions: DataTables.Settings = {};

  private subs = new SubSink();

  constructor(
    private avaliacoesService: AvaliacaoApiService,
    private servicosService: ServicoApiService,
    private clientesService: ClienteApiService,
    private alertService: AlertModalService,
    private location: Location,
    private cookieService: CookieService
  ) {  }


  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 15,
      processing: true,
      responsive: true,
      language: LanguageDataTable.portugues_datatables,
      order: [[this.orderCondition, "desc"]],
    };
    this.goTop();
    this.getAvaliacoesAsync();
    this.getServicos();
    this.getClientes();
    this.usuarioLogado = localStorage.getItem('userRole');
    this.checkCookie = this.cookieService.hasItem('tourAvaliacao');
    if (this.checkCookie === false) {
      this.startTour();
      this.setCookie();
    }
  }

  startTour(): void {
    this.disableTour = true;
    // Define the steps for tour
    setTimeout(() => {
      this.driver.defineSteps([
        {
          element: '#one-element-tour',
          popover: {
            className: 'first-step-popover-class',
            title: 'Vamos fazer um tour?',
            description: 'Você pode usar as teclas <i class="fas fa-caret-left icon-tour"></i> e <i class="fas fa-caret-right icon-tour"></i> para avançar e voltar. Acesse aqui sempre que precisar.',
            position: 'left',
            closeBtnText: 'Eu vejo depois.',
            nextBtnText: 'Vamos lá!',
            prevBtnText: '<i class="fas fa-plane-departure"></i>'
          }
        },
        {
          element: '#four-element-tour',
          stageBackground: '#2b3138',
          popover: {
            title: 'Nome da Avaliação',
            description: 'O nome da avaliação geralmente estará relacionado com o nome do cargo ou programa ao qual servirá de base.',
            position: 'right'
          }
        },
        // {
        //   element: '#five-element-tour',
        //   stageBackground: '#2b3138',
        //   popover: {
        //     title: 'Nome do Serviço',
        //     description: 'Este é o serviço onde a avaliação está vinculada.',
        //     position: 'right'
        //   }
        // },
        {
          element: '#seven-element-tour',
          stageBackground: '#2b3138',
          popover: {
            title: 'Data da Importação',
            description: 'Dia e hora em que a avaliação foi cadastrada na plataforma.',
            position: 'left'
          }
        },
        {
          element: '#eight-element-tour',
          stageBackground: '#2b3138',
          popover: {
            title: 'Botão Detalhes',
            description: 'Aqui começa a diversão. É onde você terá acesso a todos os dados dos avaliados, poderá mapear os perfis e compará-los.',
            position: 'left'
          }
        },
        {
          element: '.dataTables_filter',
          stageBackground: '#2b3138',
          popover: {
            title: 'Campo de pesquisa',
            description: 'Pesquise pelo nome da avaliação, serviço ou data.',
            position: 'left'
          }
        },
      ]);
      // Start the introduction
      this.driver.start();
      this.disableTour = false;
    }, 2000);
  }

  setCookie() {
    this.cookieService.setItem( 'tourAvaliacao', '1' );
  }

  getAvaliacoesAsync(): void {
    this.getAvaliacoesClientes = false;
    this.avaliacoes$ = this.avaliacoesService.getAvaliacoesAsync().pipe(share());
    this.avaliacoes$.forEach(avaliacoes => this.avaliacoesTotais = avaliacoes);
  }

  filterByCliente(): void {
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {

      const cliente = data[0] || 0;
      if (this.filterCliente == cliente) {
        return true;
      } else if (this.filterCliente == undefined) {
        return this.avaliacoesTotais;
      }
      return false;
    });

    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  getServicos(): void {
    this.servicos$ = this.servicosService.getServicosAsync();
  }

  getClientes() {
    this.clientes$ = this.clientesService.getClientesAsync();
  }

  goAtualizar(event) {
    this.atualizar = true;
    this.idAvaliacao = event.id;
    this.avaliacaoByCliente = event;
  }

  exitAtualizar() {
    this.atualizar = false;
  }
  
  hiddenAtualizar(refresh?: boolean) {
    if (refresh) {
      this.avaliacoesTotais = [];
      this.avaliacoes$.forEach(avaliacoes => {
        this.avaliacoesTotais = avaliacoes
      });
      this.avaliacoes$ = this.avaliacoes$;
      //this.getAvaliacoesAsync();
    }
    location.reload();
    this.atualizar = false;
  }

  onDelete(avaliacao) {

    const result$ = this.alertService.showConfirm('Confirmação', 'Tem certeza que deseja excluir essa avaliação?');
    result$.asObservable()
    .pipe(
      take(1),
      switchMap(result => result ? this.avaliacoesService.deleteAvaliacaoAsync(avaliacao.id) : EMPTY)
    ).subscribe(
      success => {
        this.alertService.showAlertSuccess('Avaliação excluída com sucesso!');
        this.getAvaliacoesAsync();
      },
      error => {
        this.alertService.showAlertDanger('Erro ao excluir avaliação. Tente novamente mais tarde.');
      }
    );
  }

  goBack(): void {
    this.location.back();
  }

  goTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    $.fn['dataTable'].ext.search.pop();
  }

}
