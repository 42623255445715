import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CepPipe } from './pipes/cep-pipe';
import { FilterPipe } from './pipes/app.filter';
import { FilterPipeAll } from './pipes/app.filterAll';
import { FilterPipePalavrasChave } from './pipes/app.filterPalavrasChave';
import { FilterTablePipe } from './pipes/app.filterTable';
import { FilterFatorPipe } from './pipes/app.filterFator';
import { FilterSortPipe } from './pipes/app.filterSort';
import { FilterCargoPipe } from './pipes/app.filterCargo';
import { FilterSelecionadosPipe } from './pipes/app.filterListagemSelecionados';
import { RemoveExtension } from './pipes/app.removeExtension';
import { NameResume } from './pipes/app.nameResume';
import { LocalDateTimePipe } from './pipes/local-date-time.pipe';
import { LoaderComponent } from './components/loader/loader.component';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxPaginationModule } from 'ngx-pagination';
import { DataTablesModule } from 'angular-datatables';

@NgModule({
  imports: [
    CommonModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    NgxPaginationModule,
    DataTablesModule,
   ],
  declarations: [
    CepPipe,
    FilterPipe,
    FilterPipeAll,
    FilterPipePalavrasChave,
    FilterTablePipe,
    FilterFatorPipe,
    FilterSortPipe,
    FilterCargoPipe,
    FilterSelecionadosPipe,
    RemoveExtension,
    NameResume,
    LoaderComponent,
    LocalDateTimePipe
  ],
  exports: [
    CepPipe,
    FilterPipe,
    FilterPipeAll,
    FilterPipePalavrasChave,
    FilterTablePipe,
    FilterFatorPipe,
    FilterSortPipe,
    FilterCargoPipe,
    FilterSelecionadosPipe,
    RemoveExtension,
    NameResume,
    LoaderComponent,
    LocalDateTimePipe,
    BsDatepickerModule,
    DatepickerModule,
    NgxPaginationModule,
    DataTablesModule,
   ],
   providers: [
    LocalDateTimePipe
   ]
})
export class SharedModule {}
