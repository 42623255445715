import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClienteApiService } from 'app/api/clienteApi.service';
import { RegisterService } from 'app/api/register.service';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { LucorApiModelsPessoaJuridica } from 'app/model/lucorApiModelsPessoaJuridica';
import { EMPTY } from 'rxjs';
import { take, switchMap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { LanguageDataTable } from 'app/shared/datatable/translate-datatable';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css']
})
export class ClientesComponent implements OnInit, OnDestroy {

  usuarioLogado;
  clientes: LucorApiModelsPessoaJuridica[];

  dtOptions: DataTables.Settings = {};

  private subs = new SubSink();

  constructor(
    private clientesService: ClienteApiService,
    private usuario: RegisterService,
    private router: Router,
    private alertService: AlertModalService) 
    { }

  ngOnInit() {
    this.getUsuarioLogado();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 15,
      processing: true,
      responsive: true,
      order: [[1, "asc"]],
      language: LanguageDataTable.portugues_datatables
    };
  }

  getUsuarioLogado() {
    this.subs.sink = this.usuario.getUsuarioLogadoAsync()
      .subscribe(usuarioLogado => {
        this.usuarioLogado = usuarioLogado.pessoaJuridicaId;
        if (this.usuarioLogado) {
          this.router.navigate(['/clientes/' + this.usuarioLogado]);
        } else {
          this.getClientes();
        }
      });
  }

  getClientes(): void {
    this.subs.sink = this.clientesService.getClientesAsync()
    .subscribe(
        clientes => {
        return this.clientes = clientes;
      });
  }

  onDelete(cliente: LucorApiModelsPessoaJuridica) {
    const result$ = this.alertService.showConfirm('Confirmação', 'Tem certeza que deseja excluir esse cliente?');
    result$.asObservable()
    .pipe(
      take(1),
      switchMap(result => result ? this.clientesService.deleteClienteAsync(cliente.id) : EMPTY)
    )
    .subscribe(
      success => {
        this.alertService.showAlertSuccess('Cliente excluído com sucesso!');
        this.ngOnInit();
      },
      error => {
        this.alertService.showAlertDanger('Erro ao excluir o cliente. Tente novamente mais tarde.');
      }
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
