import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { CargoApiService } from 'app/api/cargoApi.service';
import { LucorApiModelsDetailsCargo } from 'app/model/lucorApiModelsDetailsCargo';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { DataTableDirective } from 'angular-datatables';
import { LanguageDataTable } from 'app/shared/datatable/translate-datatable';
import { Observable, EMPTY  } from 'rxjs';
import { take, switchMap, share } from 'rxjs/operators';
import { CookieService } from '@ngx-toolkit/cookie';
import Driver from 'driver.js';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-cargos-modelos',
  templateUrl: './cargos-modelos.component.html',
  styleUrls: ['./cargos-modelos.component.css']
})
export class CargosModelosComponent implements OnInit, OnDestroy {

  cargos$: Observable<LucorApiModelsDetailsCargo[]>;
  cargosModelos: LucorApiModelsDetailsCargo[] = [];
  cargosExcluidos: LucorApiModelsDetailsCargo[] = [];
  newCargos = [];
  checkUser = false;
  usuario: string;
  role: string;
  date: Date = new Date();
  loadingLista = false;

  order = 'titulo';
  orderExcluded = 'titulo';
  reverse = true;
  reverseExcluded = true;
  searchText = '';
  searchTextExcluded = '';
  public paginaAtual = 1;
  public itensPorPagina = 25;
  public paginaAtualExcluded = 1;
  public itensPorPaginaExcluded = 15;

  checkCookie = false;
  disableTour = false;
  driver = new Driver({
    className: 'tour-lucor',
    doneBtnText: 'Entendi', // Text on the final button
    closeBtnText: 'Fechar', // Text on the close button for this step
    nextBtnText: 'Próximo', // Next button text for this step
    prevBtnText: 'Anterior', // Previous button text for this step
  });

  @ViewChild('closeModal') closeModal: ElementRef;
  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  orderCondition: number = 3;

  private subs = new SubSink();

  constructor(
    private cargosService: CargoApiService,
    private alertService: AlertModalService,
    private location: Location,
    private cookieService: CookieService
  ) {
    this.usuario = localStorage.getItem('User');
    this.role = localStorage.getItem('userRole');
    if (this.role === 'admin') {
      this.checkUser = true;
      // this.orderCondition = 3;
    }
   }

  ngOnInit() {
    this.goTop();
    this.getCargosAsync();
    this.startDataTable();
    this.checkCookie = this.cookieService.hasItem('tourCargosModelos');
    if (this.checkCookie === false) {
      this.startTour(2000);
      this.setCookie();
    }
  }

  startDataTable() {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 15,
        processing: true,
        responsive: true,
        order: [[this.orderCondition, "desc"]],
        language: LanguageDataTable.portugues_datatables
      };
  }

  startTour(time: number): void {
    this.disableTour = true;
    // Define the steps for tour
    setTimeout(() => {
      this.driver.defineSteps([
        {
          element: '#one-element-tour',
          popover: {
            className: 'first-step-popover-class',
            title: 'Vamos fazer um tour?',
            description: 'Você pode usar as teclas <i class="fas fa-caret-left icon-tour"></i> e <i class="fas fa-caret-right icon-tour"></i> para avançar e voltar. Acesse aqui sempre que precisar.',
            position: 'left',
            closeBtnText: 'Eu vejo depois.',
            nextBtnText: 'Vamos lá!',
            prevBtnText: '<i class="fas fa-plane-departure"></i>'
          }
        },
        // {
        //   element: '#two-element-tour',
        //   popover: {
        //     title: 'Botão "Cadastrar"',
        //     description: 'Aqui é onde você irá criar novos perfis de cargos ou programas.',
        //     position: 'right'
        //   }
        // },
        {
          element: '#three-element-tour',
          popover: {
            title: 'Botão "Ver Modelos de Cargos"',
            description: 'Clique aqui para visualizar Modelos de Cargos. Você poderá criar seus cargos clonando modelos. Clique novamente para ver seus cargos.',
            position: 'right'
          }
        },
        // {
        //   element: '#four-element-tour',
        //   popover: {
        //     title: 'Lixeira',
        //     description: 'Aqui estão os cargos excluídos. Você pode restaurá-los sempre que precisar. Os itens na "Lixeira" com mais de 60 dias serão removidos automaticamente.',
        //     position: 'right'
        //   }
        // },
        {
          element: '#five-element-tour',
          stageBackground: '#7a7a7a',
          popover: {
            title: 'Nome do Cargo ou Programa',
            description: 'Este é o nome do cargo ou programa modelo.',
            position: 'right'
          }
        },
        {
          element: '#six-element-tour',
          stageBackground: '#7a7a7a',
          popover: {
            title: 'Nível do Cargo',
            description: 'Este é o nível do cargo ou programa. Poderá ser Júnior, Pleno, Sênior, etc, ou um outro título personalizado.',
            position: 'left'
          }
        },
        {
          element: '#seven-element-tour',
          stageBackground: '#7a7a7a',
          popover: {
            title: 'Área ou Departamento',
            description: 'Esta é a área ou o departamento ao qual o cargo ou programa pertence.',
            position: 'left'
          }
        },
        {
          element: '#eight-element-tour',
          popover: {
            title: 'Botão "Detalhes"',
            description: 'Na página de detalhes você terá acesso ao Ranking de Adequação, Perfil Comportamental do cargo ou programa e demais dados.',
            position: 'left'
          }
        }
        // {
        //   element: '#nine-element-tour',
        //   popover: {
        //     title: 'Botão Excluir',
        //     description: 'Aqui você exclui o cargo. Use com cuidado!',
        //     position: 'left'
        //   }
        // }
      ]);
      // Start the introduction
      this.driver.start();
      this.disableTour = false;
    }, time);
  }

  setCookie() {
    this.cookieService.setItem( 'tourCargosModelos', '1' );
  }

  getCargosAsync(): void {
    this.loadingLista = true;
    this.cargosExcluidos = [];

    this.cargos$ = this.cargosService.getCargosAsync(true, true).pipe(share());

    this.cargos$.forEach(
      cargosTotais => {
        cargosTotais.forEach(cargosSelect => {
          if (!cargosSelect.excludedAt && !cargosSelect.pessoaJuridicaId) {
            this.cargosModelos.push(cargosSelect);
          }
        });
        this.loadingLista = false;
      });

  }


  onDelete(cargo: LucorApiModelsDetailsCargo) {
    const result$ = this.alertService.showConfirm('Confirmação', 'Tem certeza que deseja remover esse cargo?');
    result$.asObservable()
    .pipe(
      take(1),
      switchMap(result => result ? this.cargosService.deleteCargoAsync(cargo.id) : EMPTY)
    )
    .subscribe(success => {
        this.alertService.showAlertSuccess('Cargo excluído com sucesso!');
        this.getCargosAsync();
      }, error => {
        console.error(error);
        this.alertService.showAlertDanger(error);
      }
    );
  }

  restaurarCargoExcluido(id: number): void {
    this.subs.sink = this.cargosService.restaurarCargoExcluido(id)
    .subscribe(
      success => {
        this.alertService.showAlertSuccess('Cargo restaurado com sucesso!');
        this.getCargosAsync();
      },
      error => {
        console.error(error);
        this.alertService.showAlertDanger('Erro ao restaurar o cargo. Tente novamente mais tarde.');
      }
    );
  }

  setSearchTerm(term: string) {
    this.searchText = term;
  }

  getSearchTerm() {
    return this.searchText;
  }

  getSearchTermExcluded(value?: string) {
    return this.searchTextExcluded;
  }

  clearFilter() {
    this.searchText = '';
  }

  clearFilterExcluded() {
    this.searchTextExcluded = '';
  }

  setOrderExcluded(value: string) {
    if (this.orderExcluded === value) {
      this.reverseExcluded = !this.reverseExcluded;
    }
    this.orderExcluded = value;
  }

  goTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  goBack(): void {
    this.location.back();
  }

  closeModalCargo() {
    setTimeout(() => {
      this.closeModal.nativeElement.click(); // fechar modal
    }, 500);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    $.fn['dataTable'].ext.search.pop();
  }

}
