import { LucorApiModelsDetailsCargo } from './../../model/lucorApiModelsDetailsCargo';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CargoApiService } from 'app/api/cargoApi.service';

@Injectable({
  providedIn: 'root'
})
export class CargosResolver implements Resolve<LucorApiModelsDetailsCargo> {

  constructor(private cargosService: CargoApiService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LucorApiModelsDetailsCargo> {
    if (route.params && route.params[`IdS`]) {
      return this.cargosService.getCargoByIdAsync(route.params[`IdS`]);
    }
  }
}
