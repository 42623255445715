import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'nameResume' })

export class NameResume implements PipeTransform {
  transform(item) {
    if (!item) { return; }
    var name = item.split(' ');
    var lastname = '';
    if (name[1]?.length > 2) {
      lastname = name[1];
    }
    return name[0] + ' ' + lastname;
  }
}
