import { Component, OnInit, Injectable } from '@angular/core';
import { AppComponent } from 'app/core/app.component';
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable()

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent implements OnInit {

  acessoCliente: boolean;
  checkUser: any;

  private jwtHelper = new JwtHelperService();

  constructor(
    private appComponent: AppComponent
    ) {}

  ngOnInit() {
    this.checkUser = this.jwtHelper.decodeToken(localStorage.getItem('token'));
    if (this.checkUser) {
      this.setAcessoCliente();
    }
  }

  onLogout() {
    this.appComponent.onLogout();
  }

  private setAcessoCliente() {
    return this.checkUser.role === 'Administrador' ? this.acessoCliente = false 
    : this.checkUser.role === 'Cliente' ? this.acessoCliente = true 
    : this.acessoCliente = true;
  }

}
