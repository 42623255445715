import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RegisterService } from 'app/api/register.service';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})

export class ResetPasswordComponent implements OnInit, OnDestroy {

  email: string;
  token: string;
  year: number | Date;
  submitted = false;
  resetForm: FormGroup;
  fullPattern = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*{_-]).{6,}$";

  @ViewChild('passwordGen') passwordGen: ElementRef;

  private btnEye: any;
  private inputEye: any;

  private subs = new SubSink();

  constructor(
    private registerService: RegisterService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertModalService) {
      this.email = this.route.snapshot.queryParams['email'];
      let getUrlToken = `${encodeURIComponent(String(this.route.snapshot.queryParams['token']))}`;
      this.token = `${decodeURIComponent(getUrlToken)}`.replace(/\ /g, '+');
    }

  ngOnInit(): void {
    this.resetForm = this.formBuilder.group({
      email: [this.email],
      token: [this.token],
      novaSenha: ['', [ 
        Validators.required,
        Validators.pattern(this.fullPattern)
      ]]
    });

    this.year = new Date().getFullYear();
    this.btnEye = document.querySelector('.fa-eye');
    this.inputEye = document.querySelector('#novaSenha');

  }

  get f() { return this.resetForm.controls; }

  reset() {

    this.submitted = true;

    if (this.resetForm.invalid) { 
      return; 
    }

    this.subs.sink = this.registerService.resetarSenha(this.resetForm.value)
    .subscribe(success => {
      console.log(success);
      this.alertService.showAlertSuccess('Senha alterada com sucesso. Já pode fazer o seu login agora!')
      setTimeout(() => {
        this.router.navigate(['/login']);
      }, 2000);
    }, error => {
      console.error(error);
      this.submitted = false;
      this.alertService.showAlertDanger(error);
    });
  }

  viewPass() {
    this.btnEye.addEventListener('mouseenter', () => {
      if (this.inputEye.getAttribute('type') === 'password') {
        this.inputEye.setAttribute('type', 'text');
      }
    });
    this.btnEye.addEventListener('mouseleave', () => {
      this.inputEye.setAttribute('type', 'password');
    });
  }

  generatorPassword() {
    const passwordLength = 12;
    const addUpper =  true;
    const addNumbers =  true;
    const addSymbols =  true;

    const lowerCharacters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    const upperCharacters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const symbols = ['#', '?', '!', '@', '$', '%', '^', '&', '*', '{', '_', '-'];
    
    const getRandom = array => array[Math.floor(Math.random() * array.length)];

    let newpassword = '';

    if (addUpper) {
      newpassword = newpassword.concat(getRandom(upperCharacters));
    }

    if (addNumbers) {
      newpassword = newpassword.concat(getRandom(numbers));
    }

    if (addSymbols) {
      newpassword = newpassword.concat(getRandom(symbols));
    }

    for (let i = 1; i < passwordLength - 3; i++) {
      newpassword = newpassword.concat(getRandom(lowerCharacters));
    }

    newpassword.split('').sort(() => 0.5 - Math.random()).join('');

    if (this.inputEye.getAttribute('type') === 'password') {
      this.inputEye.setAttribute('type', 'text');
    }

    this.resetForm.setValue({
      email: this.email,
      token: this.token,
      novaSenha: newpassword
    })

    this.passwordGen.nativeElement.value = newpassword;

  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }


}
