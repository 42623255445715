import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AvaliacaoApiService } from '../../api/avaliacaoApi.service';
import { LucorApiModelsAvaliacao } from '../../model/lucorApiModelsAvaliacao';

@Injectable({
  providedIn: 'root'
})
export class AvaliacoesResolver implements Resolve<LucorApiModelsAvaliacao> {

  constructor(private avaliacoesService: AvaliacaoApiService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LucorApiModelsAvaliacao> {
    if (route.params && route.params[`Id`]) {
      return this.avaliacoesService.getAvaliacaoByIdAsync(route.params[`Id`]);
    }
  }
}
