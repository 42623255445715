import { Component, OnInit, Injectable, ViewChild, ElementRef, HostListener } from '@angular/core';
import { defer, fromEvent, merge, Observable, of } from 'rxjs';
import { share, switchMapTo } from 'rxjs/operators';
import { PpBreadcrumbsService, Breadcrumb } from 'pp-breadcrumbs';
import { LucorApiModelsDetailsUser } from 'app/model/lucorApiModelsDetailsUser';
import { AuthService } from 'app/core/auth/services/auth.service';
import { UserContextService } from 'app/core/auth/services/user-context.service';
import { RegisterService } from 'app/api/register.service';

@Injectable()

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {

  constructor( 
              public userContextService: UserContextService,
              public authService: AuthService,
              private registerService: RegisterService,
              private breadcrumbsService: PpBreadcrumbsService) {}

  title = 'LUCOR';
  subtitle = 'Tecnologia em Gestão de Pessoas.';
  year: number;
  verificaUsuario$: Observable<LucorApiModelsDetailsUser>;
  profile = false;
  isShow: boolean;
  topPosToStartShowing = 200;
  userFirstName: string;
  authLogin = false;
  acessoCliente: boolean;
 
  public isOffline$;
  private opened = false;
  
  @ViewChild('sideBarView') sideBarView: ElementRef;
  @ViewChild('menuProfile') menuProfile: ElementRef;

  @HostListener('document:click', ['$event'])
  onMouseDown(event): void {
    this.func(event.target);
  }

  func(element) {
    if (element && !element.classList.contains('nav-side') && !element.classList.contains('checkToggle')) {
      this.checkSidebar();
    } else {
      this.toggleSidebar();
    }
  }

  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.isShow = scrollPosition >= this.topPosToStartShowing ? true : false;
  }

  ngOnInit() {

    this.onlineOffline();
    this.authLogin = this.authService.isLoggedIn();
    if (this.authService.isLoggedIn()) {
      if (this.userContextService.user) {
        this.userContextService.user.pessoaJuridicaId === null ? this.acessoCliente = false : this.acessoCliente = true;
        this.setUsuario();
      } else {
        this.verificaUsuario$ = this.registerService.getUsuarioLogadoAsync().pipe(share());
        this.verificaUsuario$.forEach(usuarioLogado => {
          usuarioLogado.pessoaJuridicaId === null ? this.acessoCliente = false : this.acessoCliente = true;
          this.userContextService.user = usuarioLogado;
          if (usuarioLogado) {
            this.setUsuario();
          }
        });
      }
    }
    this.year = new Date().getFullYear();
    this.opened = false;
    this.breadcrumbs();

  }

  onLogout() {
    this.authService.logout();
    this.userContextService.user = undefined;
    this.authLogin = false;
  }

  setUsuario() {
    this.userFirstName = this.userContextService.user.userName.split('.')[0];
  }

  openProfile() {
    this.profile = !this.profile;
    if (this.profile === true) {
      this.menuProfile.nativeElement.classList.add('open');
    } else {
      this.menuProfile.nativeElement.classList.remove('open');
    }
  }

  gotoTop() {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
  }

  breadcrumbs() {
    this.breadcrumbsService.postProcess = (breadcrumbs: Breadcrumb[]) => {
      if (breadcrumbs.length >= 0) {
        breadcrumbs.unshift({ text: `Dashboard`, path: 'home' });
      }
      return breadcrumbs;
    };
  }

  private openSideBar() {
    if (!this.opened) {
      this.sideBarView.nativeElement.classList.remove('open');
    } else {
     this.sideBarView.nativeElement.classList.add('open');
    }
  }

  private checkSidebar() {
    if (this.opened) {
      this.toggleSidebar();
    }
  }

  private toggleSidebar() {
    this.opened = !this.opened;
    this.openSideBar();
  }

  private onlineOffline() {
    const initialEvent$ = of(null);
    const onlineEvent$ = fromEvent(window, 'online');
    const offlineEvent$ = fromEvent(window, 'offline');
    const isOfflineDefer$ = defer(() => of(!window.navigator.onLine));

    this.isOffline$ = merge(initialEvent$, onlineEvent$, offlineEvent$).pipe(
      switchMapTo(isOfflineDefer$)
    );

  }

}
