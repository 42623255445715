/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.87
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UpdResetPassword { 
    /**
     * Gets or Sets Email
     */
    email: string;
    /**
     * Gets or Sets Token
     */
    token: string;
    /**
     * Gets or Sets NovaSenha
     */
    novaSenha: string;
}