import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../core/auth/guards/auth.guard';
import { CargosResolver } from '../../core/resolvers/cargos.resolver';
import { CargosComponent } from './components/cargos/cargos.component';
import { CargosDetalhesComponent } from './components/cargos-detalhes/cargos-detalhes.component';
import { CargosCadastrarComponent } from './components/cargos-cadastrar/cargos-cadastrar.component';
import { CargosAtualizarComponent } from './components/cargos-atualizar/cargos-atualizar.component';
import { AvaliacoesAvaliadosComponent } from '../avaliacoes/components/avaliacoes-avaliados/avaliacoes-avaliados.component';
import { CargosModelosComponent } from './components/cargos-modelos/cargos-modelos.component';

const cargosRoutes: Routes = [

  { path: 'cargos', canActivate: [AuthGuard],
    data: { breadcrumbs: 'Cargos' },
    children: [
      { path: '', component: CargosComponent, canActivate: [AuthGuard] },
      { path: 'cadastrar', component: CargosCadastrarComponent, canActivate: [AuthGuard],
        data: { breadcrumbs: 'Cadastrar Cargo' } },

      { path: ':IdS', canActivate: [AuthGuard],
        resolve: { cargosResolver: CargosResolver },
        data: { breadcrumbs: '{{ cargosResolver.titulo }}' },
        children: [
          { path: '', component: CargosDetalhesComponent, canActivate: [AuthGuard] },
          { path: ':Id/avaliado/:Av', component: AvaliacoesAvaliadosComponent, canActivate: [AuthGuard],
            resolve: { cargosResolver: CargosResolver },
            data: { breadcrumbs: 'Detalhes do Avaliado' } },
          { path: 'atualizar', component: CargosAtualizarComponent, canActivate: [AuthGuard],
            resolve: { cargosResolver: CargosResolver },
            data: { breadcrumbs: 'Atualizar Cargo' } },
        ]
      }
    ]
  },
  {  
    path: 'modelos', component: CargosModelosComponent, canActivate: [AuthGuard],
    data: { breadcrumbs: 'Modelos de cargos' }
  }
];


@NgModule({
  imports: [RouterModule.forChild(cargosRoutes)],
  exports: [RouterModule]
})
export class CargosRoutingModule { }

