import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { CienciaApiService } from 'app/api/api';
import { LucorApiModelsCiencia } from 'app/model/lucorApiModelsCiencia';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-ciencias',
  templateUrl: './ciencias.component.html',
  styleUrls: ['./ciencias.component.css']
})
export class CienciasComponent implements OnInit, OnDestroy {

  ciencias: LucorApiModelsCiencia[];
  idAxiologia: number;
  idComportamento: number;
  idMotivadores: number;
  nomeAxiologia: string;
  nomeComportamento: string;
  nomeMotivadores: string;

  private subs = new SubSink();

  constructor(
    private cienciaService: CienciaApiService,
    private location: Location
    ) { }

  ngOnInit() {
    this.getCiencias();
  }

  getCiencias(): void {
    this.subs.sink = this.cienciaService.getCienciasAsync()
    .subscribe(ciencias => {
      this.ciencias = ciencias;
      this.ciencias.forEach(ciencia => {
            if (ciencia.nome === 'Axiologia') {
              this.idAxiologia = ciencia.id;
              this.nomeAxiologia = 'axiologia';
            }
            if (ciencia.nome === 'Comportamento Observado') {
              this.idComportamento = ciencia.id;
              this.nomeComportamento = 'comportamento-observado';
            }
            if (ciencia.nome === 'Motivadores') {
              this.idMotivadores = ciencia.id;
              this.nomeMotivadores = 'motivadores';
            }
      });
    });
  }

  goBack(): void {
    this.location.back();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
