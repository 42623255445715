import { Observable } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SubSink } from 'subsink';
import { ServicoApiService } from 'app/api/servicoApi.service';
import { ClienteApiService } from 'app/api/clienteApi.service';
import { TipoServicoApiService } from 'app/api/tipoServicoApi.service';
import { LucorApiModelsPessoaJuridica } from 'app/model/models';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { LucorApiModelsNewTipoServico } from 'app/model/lucorApiModelsNewTipoServico';

@Component({
  selector: 'app-servicos-cadastrar',
  templateUrl: './servicos-cadastrar.component.html',
  styleUrls: ['./servicos-cadastrar.component.css']
})
export class ServicosCadastrarComponent implements OnInit, OnDestroy {

  clientes$: Observable<LucorApiModelsPessoaJuridica[]>;
  tipoServico$: Observable<LucorApiModelsNewTipoServico[]>;
  addForm: FormGroup;
  idCliente: number;
  submitted = false;

  private subs = new SubSink();

  constructor(
    private clientesService: ClienteApiService,
    private servicosService: ServicoApiService,
    private tiposServicosService: TipoServicoApiService,
    private alertService: AlertModalService,
    private formBuilder: FormBuilder,
    private router: Router,
    private location: Location) { }

  ngOnInit() {
    this.getTipoServico();
    this.getClientes();

    this.addForm = this.formBuilder.group({
      codigo: ['', Validators.required],
      codigoTipoServico: ['', Validators.required],
      unidadesContratadas: [0],
      unidadesUtilizadas: [0],
      unidadesRestantes: [0],
      dataOrcamento: [null],
      dataExecucao: [null],
      valorOrcado: [0],
      valorFaturado: [0],
    });
  }

  get f() { return this.addForm.controls; }


  getClientes() {
    this.clientes$ = this.clientesService.getClientesAsync();
  }

  getTipoServico(): void {
    this.tipoServico$ = this.tiposServicosService.getTiposServicoAsync();
  }

  saveServico(): void {

    this.submitted = true;

    if (!this.idCliente || this.addForm.invalid) {
      return;
    }
    this.subs.sink = this.servicosService.addServicoAsync(this.idCliente, this.addForm.value)
    .subscribe(success => {
      this.alertService.showAlertSuccess('Serviço cadastrado com sucesso!');
      this.router.navigate(['servicos']);
    }, error => {
      console.error(error);
      this.alertService.showAlertDanger(error);
    });
  }

  goBack(): void {
    this.location.back();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
