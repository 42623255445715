import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { CargoApiService } from 'app/api/cargoApi.service';
import { RegisterService } from 'app/api/register.service';
import { ClienteApiService } from 'app/api/clienteApi.service';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { LucorApiModelsPessoaJuridica } from 'app/model/lucorApiModelsPessoaJuridica';
import { LucorApiModelsDetailsUser } from 'app/model/lucorApiModelsDetailsUser';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-cargos-clonar',
  templateUrl: './cargos-clonar.component.html',
  styleUrls: ['./cargos-clonar.component.css']
})
export class CargosClonarComponent implements OnInit, OnDestroy {

  clientes$: Observable<LucorApiModelsPessoaJuridica[]>;
  usuarioLogado$: Observable<LucorApiModelsDetailsUser>;
  usuarioLogadoPj: number;
  @Input() idCargo: number;
  @Input() titulo: number;
  @Input() pessoaJuridicaId: number;
  @Input() nivel: string;
  @Input() descricao: string;
  @Input() area: string;
  @Output() updateLista = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  addForm: FormGroup;
  submitted = false;

  private subs = new SubSink();

  constructor(
    private cargosService: CargoApiService,
    private clientesService: ClienteApiService,
    private registerService: RegisterService,
    private formBuilder: FormBuilder,
    private location: Location,
    private alertService: AlertModalService
  ) {
    this.addForm = this.formBuilder.group({
      novoTitulo: ['', Validators.required],
      novaDescricao: null,
      novaArea: null,
      novoNivel: null,
      incluirAvaliacoesAssociadas: null,
      incluirPerfisAssociados: null,
      pessoaJuridicaId: this.pessoaJuridicaId,
    });
  }

  ngOnInit() {
    this.loadForm();
    this.getClientes();
    this.usuarioLogado$ = this.registerService.getUsuarioLogadoAsync().pipe(share());
    this.usuarioLogado$.forEach(usuarioLogado => { this.usuarioLogadoPj = usuarioLogado.pessoaJuridicaId })
  }

  loadForm() {
    this.addForm.setValue({
      novoTitulo: this.titulo,
      novaDescricao: this.descricao,
      novaArea: this.area,
      novoNivel: this.nivel,
      incluirAvaliacoesAssociadas: null,
      incluirPerfisAssociados: null,
      pessoaJuridicaId: this.pessoaJuridicaId,
    });
  }

  get f() { return this.addForm.controls; }

  clonarCargo(): void {

    this.submitted = true;
    if (this.addForm.invalid) { return; }

    const novoTitulo = this.addForm.value.novoTitulo;
    const novaDescricao = this.addForm.value.novaDescricao;
    const novaArea = this.addForm.value.novaArea;
    const novoNivel = this.addForm.value.novoNivel;
    const incluirAvaliacoesAssociadas = this.addForm.value.incluirAvaliacoesAssociadas;
    const incluirPerfisAssociados = this.addForm.value.incluirPerfisAssociados;
    let novaPessoaJuridicaId: number;
    if (this.usuarioLogadoPj === null) {
      novaPessoaJuridicaId = this.pessoaJuridicaId;
    } else {
      novaPessoaJuridicaId = this.usuarioLogadoPj;
    }
    if (this.addForm.value.pessoaJuridicaId) {
      novaPessoaJuridicaId = this.addForm.value.pessoaJuridicaId;
    } 

    this.subs.sink = this.cargosService.duplicarCargoAsync(
      this.idCargo,
      novoTitulo,
      novaDescricao,
      novaArea,
      novoNivel,
      incluirAvaliacoesAssociadas,
      incluirPerfisAssociados,
      novaPessoaJuridicaId)
    .subscribe(success => {
      this.alertService.showAlertSuccess('Cargo clonado com sucesso!');
      this.updateLista.emit(success.id);
      this.closeModal.emit();
    }, error => {
      this.alertService.showAlertDanger('Erro ao clonar Cargo. Tente mais tarde.');
    });
  }

  private getClientes() {
    this.clientes$ = this.clientesService.getClientesAsync();
  }

  goBack(): void {
    this.location.back();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
