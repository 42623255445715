import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { OrderModule } from 'ngx-order-pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { MDBBootstrapModule, ChartsModule } from 'angular-bootstrap-md';
import { AvaliacoesModule } from 'app/modules/avaliacoes/avaliacoes.module';
import { SelecionadosComponent } from './components/selecionados/selecionados.component';
import { SelecionadosRoutingModule } from './selecionados.routing.module';
import { SelecionadosDetalhesComponent } from './components/selecionados-detalhes/selecionados-detalhes.component';
import { CargosModule } from '../cargos/cargos.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    SharedModule,
    OrderModule,
    NgxPaginationModule,
    ChartsModule,
    MDBBootstrapModule.forRoot(),
    SelecionadosRoutingModule,
    AvaliacoesModule,
    CargosModule
  ],
  declarations: [
    SelecionadosComponent,
    SelecionadosDetalhesComponent,
  ]
})

export class SelecionadosModule {}

