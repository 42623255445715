import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CargoApiService } from 'app/api/cargoApi.service';
import { CienciaApiService } from 'app/api/api';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { LucorApiModelsCiencia, LucorApiModelsDetailsCargo } from 'app/model/models';
import { LucorApiModelsNewPerfil } from 'app/model/lucorApiModelsNewPerfil';
import { SubSink } from 'subsink';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-cargos-filtro',
  templateUrl: './cargos-filtro.component.html',
  styleUrls: ['./cargos-filtro.component.css'],
})

export class CargosFiltroComponent implements OnInit, OnDestroy {

  cargo: LucorApiModelsDetailsCargo;
  ciencias: LucorApiModelsCiencia[];
  resultadoFiltroServico: LucorApiModelsNewPerfil[];

  idCiencia: number;
  
  resultadoFiltroAxiologia;
  resultadoFiltroComportamento;
  resultadoFiltroMotivadores;
  
  printWait = false;
  reverse = true;

  reloadPerfisAxio = false;
  reloadPerfisCompo = false;
  reloadPerfisMoti = false;
  errorPerfisAxio = false;
  errorPerfisCompo = false;
  errorPerfisMoti = false;
  checkResponse = [];
  loadUpdate = [];
  loading = false;
  
  @Input() idCargo: number;

  private updatePerfis;

  get saveUpdate() {
    return this.updatePerfis;
  }

  @Input()
  set saveUpdate(saveUpdate) {
    this.updatePerfis = saveUpdate;
    if (saveUpdate) {
      this.savePerfil();
    }
  }

  private subs = new SubSink();

  constructor(
    private cargosService: CargoApiService,
    private cienciasService: CienciaApiService,
    private alertService: AlertModalService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getCiencia();
  }

  getCiencia(): void {
    this.subs.sink = this.cienciasService.getCienciasAsync()
      .subscribe(
        ciencias => {
          this.ciencias = ciencias;
        });
  }

  /*
   * Recebe o filtro gerado no cargos-search-axiologia.component
   */
  recebeResultadoFiltroAxiologia(resultadoFiltroAxiologia) {
    this.resultadoFiltroAxiologia = resultadoFiltroAxiologia;
  }

  /*
  * Recebe o filtro gerado no cargos-search-comportamento.component
  */
  recebeResultadoFiltroComportamento(resultadoFiltroComportamento) {
    this.resultadoFiltroComportamento = resultadoFiltroComportamento;
  }

  /*
  * Recebe o filtro gerado no cargos-search-motivadores.component
  */
  recebeResultadoFiltroMotivadores(resultadoFiltroMotivadores) {
    this.resultadoFiltroMotivadores = resultadoFiltroMotivadores;
  }

  savePerfil() {

    this.checkResponse = [];
    this.loadUpdate = [];
    this.loading = true;
    this.reloadPerfisAxio = false;
    this.reloadPerfisCompo = false;
    this.reloadPerfisMoti = false;
    
    const perfilAxiologia = this.resultadoFiltroAxiologia;
    const perfilComportamento = this.resultadoFiltroComportamento;
    const perfilMotivadores = this.resultadoFiltroMotivadores;

    this.getUpdatePerfis(perfilAxiologia, perfilComportamento, perfilMotivadores);
 
  }

  async updatePerfisAxiologia(filtroAxiologia) {

    let arrayCienciaAxiologia: Array<LucorApiModelsNewPerfil> = [];
    let stop = false;

    if (filtroAxiologia) {

      const idCiencia = 1;

      if (filtroAxiologia?.length === 0) {
        arrayCienciaAxiologia = [{ "minimo": 0, "ideal": 0, "maximo": 0, "fator": null }];

        this.subs.sink = this.cargosService.updatePerfisCargo(this.idCargo, idCiencia, arrayCienciaAxiologia, 'response')
          .subscribe(
            success => { 
              this.reloadPerfisAxio = true; 
              this.loadUpdate.push(1);
              this.setUpdatePerfis(this.loadUpdate);
            }, 
            error => { 
              this.errorPerfisAxio = true;
              this.loadUpdate.push(1);
              this.setUpdatePerfis(this.loadUpdate);
            }
          );

      } else { 

         filtroAxiologia.find(fator => {
            
           if (fator.fator && !stop) {   
            this.subs.sink = this.cargosService.updatePerfisCargo(this.idCargo, idCiencia, filtroAxiologia, 'response').pipe(take(1))
              .subscribe(
                success => {
                  this.reloadPerfisAxio = true;
                  this.loadUpdate.push(1);
                  this.setUpdatePerfis(this.loadUpdate);
                }, 
                error => { 
                  this.errorPerfisAxio = true;
                  this.reloadPerfisAxio = false;
                  this.loadUpdate.push(1);
                  this.setUpdatePerfis(this.loadUpdate);
                  // stop = true;
                }
                );
              } else if (!fator.fator && !stop) {
                  this.reloadPerfisAxio = false;
                  this.loadUpdate.push(1);
                  this.setUpdatePerfis(this.loadUpdate);
              }
                  
            stop = true;

          }); 
          
    }

    } else { 
      this.reloadPerfisAxio = false;
      this.loadUpdate.push(1);
      this.setUpdatePerfis(this.loadUpdate);
    }

  }


  async updatePerfisComportamento(filtroComportamento) {

    let arrayCienciaComportamento: Array<LucorApiModelsNewPerfil> = [];
    let stop = false;

    if (filtroComportamento) {

      const idCiencia = 2;

      if (filtroComportamento?.length === 0) {
        arrayCienciaComportamento = [{ "minimo": 0, "ideal": 0, "maximo": 0, "fator": null }];

        this.subs.sink = this.cargosService.updatePerfisCargo(this.idCargo, idCiencia, arrayCienciaComportamento, 'response')
          .subscribe(
            success => { 
              this.reloadPerfisCompo = true; 
              this.loadUpdate.push(2);
              this.setUpdatePerfis(this.loadUpdate);
            }, 
            error => { 
              this.errorPerfisCompo = true;
              this.loadUpdate.push(2);
              this.setUpdatePerfis(this.loadUpdate);
            }
          );

      } else { 

         filtroComportamento.find(fator => {  

           if (fator.fator && !stop) {
              
              this.subs.sink = this.cargosService.updatePerfisCargo(this.idCargo, idCiencia, filtroComportamento, 'response').pipe(take(1))
              .subscribe(
                success => {
                  this.reloadPerfisCompo = true;
                  this.loadUpdate.push(2);
                  this.setUpdatePerfis(this.loadUpdate);
                }, 
                error => { 
                  console.error(error);
                  this.errorPerfisCompo = true;
                  this.loadUpdate.push(2);
                  this.setUpdatePerfis(this.loadUpdate);
                  // stop = true;
                }
                );
              } else if (!fator.fator && !stop){
                  this.reloadPerfisCompo = false;
                  this.loadUpdate.push(2);
                  this.setUpdatePerfis(this.loadUpdate);
              }
                  
          stop = true;

        });
        
        }

    } else { 
      this.reloadPerfisCompo = false; 
      this.loadUpdate.push(2)
      this.setUpdatePerfis(this.loadUpdate);
    }

  }


  async updatePerfisMotivadores(filtroMotivadores) {

    let arrayCienciaMotivadores: Array<LucorApiModelsNewPerfil> = [];
    let stop = false;

    if (filtroMotivadores) {

      const idCiencia = 3;

      if (filtroMotivadores?.length === 0) {
        arrayCienciaMotivadores = [{ "minimo": 0, "ideal": 0, "maximo": 0, "fator": null }];

        this.subs.sink = this.cargosService.updatePerfisCargo(this.idCargo, idCiencia, arrayCienciaMotivadores, 'response')
          .subscribe(
              success => {
                this.reloadPerfisMoti = true;
                this.loadUpdate.push(3);
                this.setUpdatePerfis(this.loadUpdate);
              }, 
              error => {
                this.errorPerfisMoti = true;
                this.loadUpdate.push(3);
                this.setUpdatePerfis(this.loadUpdate);
              }
          );

      } else { 
    
       filtroMotivadores.find(fator => { 
          
           if (fator.fator && !stop) {
            
              this.subs.sink = this.cargosService.updatePerfisCargo(this.idCargo, idCiencia, filtroMotivadores, 'response').pipe(take(1))
              .subscribe(
                success => {
                  this.reloadPerfisMoti = true;
                  this.loadUpdate.push(3);
                  this.setUpdatePerfis(this.loadUpdate);
                }, 
                error => { 
                  this.errorPerfisMoti = true;
                  this.reloadPerfisMoti = false;
                  this.loadUpdate.push(3);
                  this.setUpdatePerfis(this.loadUpdate);
                }
                );  
                
                
              } else if (!fator.fator && !stop) {
                  this.reloadPerfisMoti = false;
                  this.loadUpdate.push(3);
                  this.setUpdatePerfis(this.loadUpdate);
              }

              stop = true;
          
        });


      }

    } else { 
      this.reloadPerfisMoti = false; 
      this.loadUpdate.push(3);
      this.setUpdatePerfis(this.loadUpdate);
    }

  }

  async getUpdatePerfis(filtroAxiologia, filtroComportamento, filtroMotivadores) {

    if (filtroAxiologia) {
      await this.updatePerfisAxiologia(filtroAxiologia);
    } else { 
      this.reloadPerfisAxio = false; 
      this.loadUpdate.push(1);
      this.setUpdatePerfis(this.loadUpdate);
    }

    if (filtroComportamento) {
      await this.updatePerfisComportamento(filtroComportamento);
    } else { 
      this.reloadPerfisCompo = false; 
      this.loadUpdate.push(2);
      this.setUpdatePerfis(this.loadUpdate);
    }

    if (filtroMotivadores) {
      await this.updatePerfisMotivadores(filtroMotivadores);
    } else { 
      this.reloadPerfisMoti = false; 
      this.loadUpdate.push(3);
      this.setUpdatePerfis(this.loadUpdate);
    }

    if (!filtroAxiologia && !filtroComportamento && !filtroMotivadores) {
      this.alertService.showAlertWarning('Nenhum perfil foi selecionado!');
    }

  }

  async setUpdatePerfis(response: Array<number>) {

    this.checkResponse = [...new Set(response)];

    if (this.checkResponse?.length === 3) {  
      
      this.loading = false;

      if (this.errorPerfisAxio || this.errorPerfisCompo || this.errorPerfisMoti) {
        this.alertService.showAlertDanger('Erro ao cadastrar Perfis. Por favor, verifique os filtros e tente novamente.');
      } 

      if (!this.reloadPerfisAxio && !this.reloadPerfisCompo && !this.reloadPerfisMoti) {
        if (!this.saveUpdate) {
          this.alertService.showAlertWarning('Não houve alteração nos Perfis Comportamentais!');
        } else {
          this.goBack();
        }
      } 

      if (this.reloadPerfisAxio || this.reloadPerfisCompo || this.reloadPerfisMoti) {
        if (!this.saveUpdate) {
          this.alertService.showAlertSuccess('Perfis cadastrados com sucesso!');
        } else {
          this.goBack();
        }
        this.goTop();
      }

      return;

    }


  }

  customComparator(itemA, itemB) { // Adicionado para customizar a função 'comparator' do OrderBy
    const hasNumber = /\d/;
    const verificaTipo = hasNumber.test(itemA); // Verifica se a variável contém número
    if (verificaTipo === true) {
          return  itemA - itemB; // Corrige a situação que retorna algo assim: [1, 10, 12, 15, 5, 8, 9]
        } else {
         return itemA.localeCompare(itemB); // Corrige a situação da palavra acentuada ficar por último na lista
        }
  }

  goBack(): void {
    this.router.navigateByUrl('/SkipPage', {skipLocationChange: true}).then(()=>
    this.router.navigate(['cargos/' + this.idCargo]));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private goTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

}
