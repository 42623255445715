import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Tokens } from '../models/tokens';
import { AlertModalService } from './../../../shared/alert-modal.service';
import { LoginService } from './../../../api/login.service';

@Injectable({ providedIn: 'root' })

export class AuthService {

  checkTokenAccess = false;
  checkToken = null;
  systemVersion: string;

  private jwtHelper = new JwtHelperService();

  private readonly errorMessage = 'Ocorreu um erro de rede durante sua solicitação de login. Tente novamente. Se essa condição persistir, contate o provedor de serviços de rede.';
  private readonly JWT_TOKEN = 'token';

  constructor(
    private alertService: AlertModalService,
    private loginService: LoginService,
    private router: Router) {}

  public sysVersion() {
    return this.systemVersion = this.loginService.systemVersion();
  }

  public isLoggedIn() {
    return !!this.getJwtToken();
  }

  post(user: { userName: string, password: string }): Observable<boolean> {
    return this.loginService.postAsync(user)
      .pipe(
        tap(token => {
          // console.log(token);
          if (!token.authenticated) {
            if (token.message === 'Falha ao autenticar') {
              this.alertService.showAlertDanger('Login inválido. Tente novamente.');
            } else {
              this.alertService.showAlertDanger(token.message + '!');
            }
          } else {
            this.doLoginUser(token);
          }
        }),
        // mapTo(true),
        catchError(error => {
          console.error('Detalhes do erro: ', error.message);
          this.alertService.showAlertDanger(this.errorMessage);
          return of(false);
        }));
  }

  logout() {
    this.removeTokens();
  }

  getJwtToken() {
    let hasToken = localStorage.getItem(this.JWT_TOKEN);
    if (hasToken != undefined || hasToken != null) {
      let checkTokenExpired = this.jwtHelper.isTokenExpired(localStorage.getItem(this.JWT_TOKEN));
      return checkTokenExpired ? undefined : hasToken;
    } else {
      return undefined;
    }
  }

  private doLoginUser(tokens: Tokens) {
    this.checkToken = tokens.accessToken;
    if (this.checkToken) {
      this.checkTokenAccess = true;
      this.storeTokens(tokens);
    } else {
      this.removeTokens();
    }
  }

  private storeTokens(tokens: Tokens) {
    localStorage.setItem(this.JWT_TOKEN, tokens.accessToken);
    localStorage.setItem('_date', tokens.expiration.toLocaleString());
  }

  private removeTokens() {
    this.checkTokenAccess = false;
    localStorage.clear();
    this.router.navigate(['/login']);
  }

}

