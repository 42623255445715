import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { RegisterService } from 'app/api/register.service';
import { AvaliacaoApiService } from './../../../../api/avaliacaoApi.service';
import { ClienteApiService } from './../../../../api/clienteApi.service';
import { ServicoApiService } from './../../../../api/servicoApi.service';
import { LucorApiModelsAvaliacao } from './../../../../model/lucorApiModelsAvaliacao';
import { LucorApiModelsServico } from './../../../../model/lucorApiModelsServico';
import { LucorApiModelsDetailsUser } from './../../../../model/lucorApiModelsDetailsUser';
import { LucorApiModelsPessoaJuridica } from 'app/model/models';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

@Component({
  selector: 'app-servicos-detalhes',
  templateUrl: './servicos-detalhes.component.html',
  styleUrls: ['./servicos-detalhes.component.css']
})
export class ServicosDetalhesComponent implements OnInit {

  avaliacoes$: Observable<LucorApiModelsAvaliacao[]>;
  clientes$: Observable<LucorApiModelsPessoaJuridica[]>;
  servico$: Observable<LucorApiModelsServico>;
  usuario$: Observable<LucorApiModelsDetailsUser>;
  usuarioLogado: LucorApiModelsDetailsUser;
  idServico: number;
  unidadesContratadas: number;
  unidadesUtilizadas: number;
  unidadesRestantes: number;

  constructor(
    private servicosService: ServicoApiService,
    private clientesService: ClienteApiService,
    private avaliacoesService: AvaliacaoApiService,
    private usuarioService: RegisterService,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.idServico = +this.route.snapshot.paramMap.get('Id');
    this.usuario$ = this.usuarioService.getUsuarioLogadoAsync().pipe(share());
  }

  ngOnInit() {
    this.usuario$.forEach(usuarioLogado => this.usuarioLogado = usuarioLogado);
    this.getServicoById();
    this.getClientes();
    this.getAvaliacoesByServico();
  }

  getServicoById() {
    this.servico$ = this.servicosService.getServicoByIdAsync(this.idServico).pipe(share());
    this.servico$.forEach(servico => {
      this.unidadesContratadas = servico.unidadesContratadas;
      if (this.unidadesContratadas) {
        this.calculoUnidades();
      }
    })
  }

  getClientes() {
    this.clientes$ = this.clientesService.getClientesAsync();
  }

  getAvaliacoesByServico() {
    this.avaliacoes$ = this.avaliacoesService.getAvaliacaoByServicoAsync(this.idServico).pipe(share());
    this.avaliacoes$.forEach(avalia => console.log(avalia))
  }

  calculoUnidades() {
    let soma = [];
    this.avaliacoes$.forEach(avaliacoes => {
      avaliacoes.forEach(avaliacao => 
        {
          avaliacao.avaliados.forEach(avaliado => {
            soma.push(avaliado);
          });   
        });
        this.unidadesUtilizadas = soma.length;
        this.unidadesRestantes = this.unidadesContratadas - this.unidadesUtilizadas;
    });
  }

  goBack(): void {
    this.location.back();
  }

}
