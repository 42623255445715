import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AlertModalService } from 'app/shared/alert-modal.service';

import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-cargos-detalhes-export-pdf',
  templateUrl: './cargos-detalhes-export-pdf.component.html',
  styleUrls: ['./cargos-detalhes-export-pdf.component.css']
})
export class CargosDetalhesExportPDFComponent implements OnInit {

  @Input() newArrayAderencias;
  @Input() newArrayAxiologia: Array<any>;
  @Input() newArrayComportamento: Array<any>;
  @Input() newArrayMotivadores: Array<any>;
  @Input() tituloCargo: string;
  @Input() cutoffLimit: number;

  newArrayChartsAxiologia: Array<any> = new Array();
  newArrayChartsComportamento: Array<any> = new Array();
  newArrayChartsMotivadores: Array<any> = new Array();

  checkTime: any;

  @Output() deactivePDF = new EventEmitter();

  @Input ()
  set printPDF(printPDF: boolean) {
    if (printPDF) {
      this.checkTime = setInterval(() => {
          this.alertService.showAlertWarning('Ops! O tempo de execução expirou. Verifique sua conexão ou atualize a página e tente novamente.')
          this.deactivePDF.emit(false);
        }, 60000);
    } else {
      clearInterval(this.checkTime);
    }
  }
  
  @ViewChild('textRanking') textRanking: ElementRef;
  @ViewChild('introP1') introP1: ElementRef;
  @ViewChild('introP2') introP2: ElementRef;
  @ViewChild('introP3') introP3: ElementRef;
  @ViewChild('introP4') introP4: ElementRef;
  @ViewChild('introP5') introP5: ElementRef;
  @ViewChild('introP6') introP6: ElementRef;
  @ViewChild('introP7') introP7: ElementRef;
  @ViewChild('introP8') introP8: ElementRef;
  @ViewChild('introP9') introP9: ElementRef;


  // Gráfico Visão do Grupo
  public chartTypeVisaoGrupo = 'bar';
  public chartDatasetsVisaoGrupo = [];
  public chartLabelsVisaoGrupo: Array<any> = [];
  public chartColorsVisaoGrupo: Array<any> = [
     {
       borderColor: 'rgba(145, 206, 244, 1)',
       backgroundColor: 'rgba(145, 206, 244, 0.5)',
       borderWidth: 2,
     }
  ];
  public chartOptionsVisaoGrupo: any = {
    responsive: true,
    title: {
      display: true,
      text: 'Ranking de Porcentagem de Adequação',
      fontSize: 18
    },
    legend: { display: false },
    scales: {
      yAxes: [{ticks: {
        max: 100,
        beginAtZero: true,
      }}]
    }
  };


  //** */

  setChartLabelsAxiologia: Array<any> = new Array<any>();
  setChartDatasetsAxiologia: Array<any> = [];

  // Gráfico Resultado Axiologia Pessoa x Cargo
  public chartTypeAxiologia = 'radar';
  public chartDatasetsAxiologia: Array<any> = [];
  public chartLabelsAxiologia: Array<any> = [];
  public chartColorsAxiologia: Array<any> = [
     {
       borderColor: 'rgba(255, 168, 5, 1)',
       backgroundColor: 'rgba(255, 168, 5, 0.0)',
       borderWidth: 2,
     },
     {
       borderColor: 'rgba(145, 206, 244, 1)',
       backgroundColor: 'rgba(145, 206, 244, 0.5)',
       borderWidth: 2,
     }
  ];
  public chartOptionsAxiologia: any = {
     responsive: true,
     scale: {
       angleLines: {
           display: true
       },
       ticks: {
           suggestedMin: 0,
           suggestedMax: 100
       }
     }
  };
 
  setChartLabelsComportamento: Array<any> = new Array<any>();
  setChartDatasetsComportamento: Array<any> = [];
 
   // Gráfico Resultado Comportamento Pessoa x Cargo
   public chartTypeComportamento = 'radar';
   public chartDatasetsComportamento: Array<any> = [];
   public chartLabelsComportamento: Array<any> = [];
   public chartColorsComportamento: Array<any> = [
     {
       borderColor: 'rgba(255, 168, 5, 1)',
       backgroundColor: 'rgba(255, 168, 5, 0.0)',
       borderWidth: 2,
     },
     {
       borderColor: 'rgba(145, 206, 244, 1)',
       backgroundColor: 'rgba(145, 206, 244, 0.5)',
       borderWidth: 2,
     }
   ];
   public chartOptionsComportamento: any = {
     responsive: true,
     scale: {
       angleLines: {
           display: true
       },
       ticks: {
           suggestedMin: 0,
           suggestedMax: 100
       }
     }
   };
 
   setChartLabelsMotivadores: Array<any> = new Array<any>();
   setChartDatasetsMotivadores: Array<any> = [];
 
   // Gráfico Resultado Motivadores Pessoa x Cargo
   public chartTypeMotivadores = 'radar';
   public chartDatasetsMotivadores: Array<any> = [];
   public chartLabelsMotivadores: Array<any> = [];
   public chartColorsMotivadores: Array<any> = [
     {
       borderColor: 'rgba(255, 168, 5, 1)',
       backgroundColor: 'rgba(255, 168, 5, 0.0)',
       borderWidth: 2,
     },
     {
       borderColor: 'rgba(145, 206, 244, 1)',
       backgroundColor: 'rgba(145, 206, 244, 0.5)',
       borderWidth: 2,
     }
   ];
   public chartOptionsMotivadores: any = {
     responsive: true,
     scale: {
       angleLines: {
           display: true
       },
       ticks: {
           suggestedMin: 0,
           suggestedMax: 100
       }
     }
   };

  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }


  constructor(private alertService: AlertModalService,) { }

  ngOnInit(): void {

    let promiseInit;
    let axioTRUE = false;
    let compoTRUE = false;
    let motiTRUE = false;

    this.newArrayAderencias.sort(function (a, b) {
      if (a.avaliadoId > b.avaliadoId) { return 1; }
      if (a.avaliadoId < b.avaliadoId) { return -1; }
      return 0;
    });

    this.newArrayAderencias.forEach(aderencias => {
      aderencias.ranking.forEach(aproximacao => {
        aderencias.aderenciaCiencias.forEach(aderencia => {
          aderencia.rankings.forEach(ranking => {

            promiseInit = new Promise((resolve, reject) => {

              if (this.newArrayComportamento.length > 1) {

                if (aderencia.cienciaId === 2) {

                  this.newArrayComportamento.forEach(avaliado => {
                    if (avaliado.idAvaliado === aderencias.avaliadoId 
                      && aproximacao.percentualAproximacao >= (this.cutoffLimit - 0.5)
                      && ranking.descricao === 'Comportamento Observado') {
                      this.setGraficoResultadoComportamento(avaliado, ranking.posicao, ranking.percentualAproximacao);
                      compoTRUE = true;
                    }
                  });


                }

              } else {
                compoTRUE = true;
              }

              if (this.newArrayMotivadores.length > 1) {

                if (aderencia.cienciaId === 3) {

                  this.newArrayMotivadores.forEach(avaliado => {
                    if (avaliado.idAvaliado === aderencias.avaliadoId && aproximacao.percentualAproximacao >= (this.cutoffLimit - 0.5)) {
                      this.setGraficoResultadoMotivadores(avaliado, ranking.posicao, ranking.percentualAproximacao);
                      motiTRUE = true;
                    }
                  });

                }

              } else {
                motiTRUE = true;
              }

              
              if (this.newArrayAxiologia.length > 1) {

                if (aderencia.cienciaId === 1) {
                  this.newArrayAxiologia.forEach(avaliado => {
                    if (avaliado.idAvaliado === aderencias.avaliadoId && aproximacao.percentualAproximacao >= (this.cutoffLimit - 0.5)) {
                      this.setGraficoResultadoAxiologia(avaliado, ranking.posicao, ranking.percentualAproximacao);
                      axioTRUE = true;
                    }
                  });

                }

              } else {
                axioTRUE = true;
              }


              if (axioTRUE && compoTRUE && motiTRUE) {
                let result = [this.newArrayChartsComportamento, this.newArrayChartsMotivadores, this.newArrayChartsAxiologia]
                resolve(result);
              }

            });

          });
        });
      });
    });

    promiseInit.then((resultado) => {

      let resultadoComportamento = resultado[0];
      let resultadoMotivadores = resultado[1];
      let resultadoAxiologia = resultado[2];
      
      setTimeout(() => {
          this.downloadAsPDF(resultadoComportamento, resultadoMotivadores, resultadoAxiologia);
        }, 1000);

    });
    
  }

  public downloadAsPDF(resultadoComportamento, resultadoMotivadores, resultadoAxiologia) {

    let cargo: string = this.tituloCargo;
    let atualPage;
    let pageSize;
    let pageHeight; 
    let pageWidth; 
    
    const textRankingIntro = this.textRanking.nativeElement.textContent;
    const textP1 = this.introP1.nativeElement.textContent;
    const textP2 = this.introP2.nativeElement.textContent;
    const textP3 = this.introP3.nativeElement.textContent;
    const textP4 = this.introP4.nativeElement.textContent;
    const textP5 = this.introP5.nativeElement.textContent;
    const textP6 = this.introP6.nativeElement.textContent;
    const textP7 = this.introP7.nativeElement.textContent;
    const textP8 = this.introP8.nativeElement.textContent;
    const textP9 = this.introP9.nativeElement.textContent;

    let doc = new jsPDF('p', 'mm', 'a4');

    let pageInfo = () => {  // Footer

      atualPage = "Página " + doc.getNumberOfPages(); // Paginação
      pageSize = doc.internal.pageSize;
      pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
      pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      doc.setFontSize(10).setFont(undefined,'normal');
      doc.text(atualPage, 10, pageHeight - 10);
      doc.addImage('../../assets/imagens/icone-lucor-azul.png', pageWidth - 20, pageHeight - 24, 10, 14);

    }

    let options = function(data) {
      // Header
      doc.setFontSize(16).setFont(undefined,'bold');
      doc.text('Ranking de Adequação', data.settings.margin.left, 15);
      doc.setFontSize(12).setFont(undefined,'normal');
      pageSize = doc.internal.pageSize;
      let maxWidthParagraf = pageSize.width - 30;
      doc.text(textRankingIntro, data.settings.margin.left, 22, { align: 'justify', lineHeightFactor: 1.2, maxWidth: maxWidthParagraf });

      pageInfo(); // Footer

    };

    autoTable(doc, 
      { 
        html: '#basic-table', 
        margin: {
          top: 34
        }, 
        tableWidth: 180,
        columnStyles: {
          0: {cellWidth: 10, halign: "center"},
          1: {cellWidth: 140},
          2: {cellWidth: 30, halign: "center"},
        },
        didDrawPage : options // Adiciona o título e a paginação
      } 
    );

    // Add New Page - Início dos Gráficos
    doc.addPage();

    doc.setFontSize(20).setFont(undefined,'bold');
    doc.text("Gráficos de Compatibilidade", 15, 120);
    doc.setFontSize(14).setFont(undefined,'normal');
    doc.text("Os Gráficos de Compatibilidade são apresentados por ordem de aproximação do avaliado em relação ao perfil de referência.", 15, 130, { maxWidth: 110 });
    pageInfo();

    addCharts();

   function addCharts() {

      let canvas;
      let canvasImg;
      let chartPositionX;
      let chartPositionY;

      if (resultadoComportamento.length) {

        doc.addPage();

        resultadoComportamento.sort(function (a, b) {
          if (a.posicao > b.posicao) { return 1; }
          if (a.posicao < b.posicao) { return -1; }
          return 0;
        });

        doc.setFontSize(15).setFont(undefined,'bold');
        doc.text("Comportamento Observado", 15, 20);

        resultadoComportamento.forEach((avaliados, index) => {

          // console.log(resultadoComportamento);

          if (index === 0) {
            pageInfo();
          }

          if (index !== 0 && index % 2 === 0) {
            doc.addPage();
            pageInfo();
          }

          canvas = <HTMLCanvasElement> document.getElementById('chartComp_' + avaliados.idAvaliado);
          
          //creates image
          canvasImg = canvas.toDataURL("image/png", 1.0);

          chartPositionX = 15;
          chartPositionY = 42;

          if (index !== 0 && index % 2 !== 0) {
            chartPositionY = 170;
          } 

          let titleAvaliado = avaliados.avaliado + ' | ' + avaliados.aproximacao + '%';

          doc.setFontSize(11).setFont(undefined,'bold');
          doc.text(titleAvaliado, 15, chartPositionY - 4);
          doc.addImage(canvasImg, 'JPEG', chartPositionX, chartPositionY, 170, 90);

    
        });
      }


      if (resultadoMotivadores.length) {

        doc.addPage();

        resultadoMotivadores.sort(function (a, b) {
          if (a.posicao > b.posicao) { return 1; }
          if (a.posicao < b.posicao) { return -1; }
          return 0;
        });

        doc.setFontSize(15).setFont(undefined,'bold');
        doc.text("Motivadores", 15, 20);

        resultadoMotivadores.forEach((avaliados, index) => {

          if (index === 0) {
            pageInfo();
          }

          if (index !== 0 && index % 2 === 0) {
            doc.addPage();
            pageInfo();
          }

          canvas = <HTMLCanvasElement> document.getElementById('chartMot_' + avaliados.idAvaliado);
          
          //creates image
          canvasImg = canvas.toDataURL("image/png", 1.0);

          chartPositionX = 15;
          chartPositionY = 42;

          if (index !== 0 && index % 2 !== 0) {
            chartPositionY = 170;
          } 

          let titleAvaliado = avaliados.avaliado + ' | ' + avaliados.aproximacao + '%';

          doc.setFontSize(11).setFont(undefined,'bold');
          doc.text(titleAvaliado, 15, chartPositionY - 4);
          doc.addImage(canvasImg, 'JPEG', chartPositionX, chartPositionY, 170, 90);

    
        });
      }


      if (resultadoAxiologia.length) {

        doc.addPage();

        resultadoAxiologia.sort(function (a, b) {
          if (a.posicao > b.posicao) { return 1; }
          if (a.posicao < b.posicao) { return -1; }
          return 0;
        });

        doc.setFontSize(15).setFont(undefined,'bold');
        doc.text("Competências", 15, 20);

        resultadoAxiologia.forEach((avaliados, index) => {

          if (index === 0) {
            pageInfo();
          }

          if (index !== 0 && index % 2 === 0) {
            doc.addPage();
            pageInfo();
          }

          canvas = <HTMLCanvasElement> document.getElementById('chartAxio_' + avaliados.idAvaliado);
          
          //creates image
          canvasImg = canvas.toDataURL("image/png", 1.0);

          chartPositionX = 15;
          chartPositionY = 42;

          if (index !== 0 && index % 2 !== 0) {
            chartPositionY = 170;
          } 

          let titleAvaliado = avaliados.avaliado + ' | ' + avaliados.aproximacao + '%';

          doc.setFontSize(11).setFont(undefined,'bold');
          doc.text(titleAvaliado, 15, chartPositionY - 4);
          doc.addImage(canvasImg, 'JPEG', chartPositionX, chartPositionY, 170, 90);

        });
      }

    }

    // Capa
    doc.insertPage(1);
    doc.addImage('../../assets/imagens/logo-lucor-principal.png', 35, 60, 60, 26);
    doc.setFontSize(24).setFont(undefined,'bold');
    doc.text("Análise Comportamental", 35, 120);
    doc.setFontSize(18).setFont(undefined,'normal');
    doc.text(cargo, 35, 130);
    
    // Texto visão geral
    pageSize = doc.internal.pageSize;
    let maxWidthParagraf = pageSize.width - 30;
    let bottomCopy = pageSize.height - 10;
    doc.addPage();
    doc.setFontSize(18).setFont(undefined,'bold');
    doc.text('Visão Geral', 15, 15);
    doc.setFontSize(12).setFont(undefined, 'normal');;
    doc.text(textP1, 15, 36, { align: 'justify', lineHeightFactor: 1.2, maxWidth: maxWidthParagraf });
    doc.text(textP2, 15, 55, { align: 'justify', lineHeightFactor: 1.2, maxWidth: maxWidthParagraf });
    doc.text(textP3, 15, 70, { align: 'justify', lineHeightFactor: 1.2, maxWidth: maxWidthParagraf });
    doc.text(textP4, 15, 85, { align: 'justify', lineHeightFactor: 1.2, maxWidth: maxWidthParagraf });
    doc.text(textP5, 15, 115, { align: 'justify', lineHeightFactor: 1.2, maxWidth: maxWidthParagraf });
    doc.text(textP6, 15, 146, { align: 'justify', lineHeightFactor: 1.2, maxWidth: maxWidthParagraf });
    doc.text(textP7, 15, 165, { align: 'justify', lineHeightFactor: 1.2, maxWidth: maxWidthParagraf });
    doc.text(textP8, 15, 184, { align: 'justify', lineHeightFactor: 1.2, maxWidth: maxWidthParagraf });
    doc.text(textP9, 15, 203, { align: 'justify', lineHeightFactor: 1.2, maxWidth: maxWidthParagraf });

    doc.setFontSize(9).setFont(undefined,'normal');
    doc.text('LUCOR Tecnologia em Gestão de Pessoas © Todos os direitos reservados.', 15, bottomCopy, {align: 'left', maxWidth: maxWidthParagraf });
    doc.addImage('../../assets/imagens/icone-lucor-azul.png', pageSize.width - 20, pageSize.height - 24, 10, 14);

    // Finish --> Print PDF
    doc.save('Análise_LUCOR__' + cargo + '.pdf');

    clearInterval(this.checkTime);

    this.deactivePDF.emit(false);
    this.alertService.showAlertSuccess('Sua análise em PDF está pronta!');

  }


  private setGraficoResultadoAxiologia(avaliado?, posicao?, apx?) {

    const valuesChartAxiologia = this.newArrayAxiologia;

    this.setChartLabelsAxiologia = [];
    this.chartDatasetsAxiologia = [];
    this.chartLabelsAxiologia = [];

    valuesChartAxiologia.forEach((avaliadosCargo, index) => {
      avaliadosCargo.resultados.map(fatoresAxiologia => {
        if (index === 0) {
          this.setChartLabelsAxiologia.push(fatoresAxiologia.nomeFator);
        }

      });
    });


    let fatorIgual: boolean;
    valuesChartAxiologia.forEach(fatoresAxio => {

      fatoresAxio.resultados.forEach(fatorAxio => {

      fatorIgual = false;

      this.chartDatasetsAxiologia.forEach(chartData => {

        if (this.chartDatasetsAxiologia
          && +fatoresAxio.idAvaliado === +avaliado.idAvaliado
          && fatoresAxio.nomeAvaliado === chartData.label
          && fatoresAxio.nomeAvaliado !== 'Perfil de referência'
          || fatoresAxio.nomeAvaliado === 'Perfil de referência'
          && chartData.label === 'Perfil de referência') {
          chartData.data.push(
              fatorAxio.valor
          );
          fatorIgual = true;
        }
      });

      if (!fatorIgual && fatoresAxio.idAvaliado === avaliado.idAvaliado) {
        this.chartDatasetsAxiologia.push({
          data: [fatorAxio.valor],
          label: fatoresAxio.nomeAvaliado
        });
      }
      if (!fatorIgual && fatoresAxio.nomeAvaliado === 'Perfil de referência') {
        this.chartDatasetsAxiologia.push({
          data: [fatorAxio.valor],
          label: fatoresAxio.nomeAvaliado
        });
      }
     });
    });

    this.chartLabelsAxiologia = this.setChartLabelsAxiologia;
    this.chartDatasetsAxiologia = this.chartDatasetsAxiologia;

    fatorIgual = false;

          this.newArrayChartsAxiologia.map(result => {
      
            if (!fatorIgual && result.idAvaliado === avaliado.idAvaliado) {
              result.charts.push({
                labels: this.setChartLabelsAxiologia,
                data: this.chartDatasetsAxiologia
              })
            }
          });
      
          this.newArrayChartsAxiologia.push({
            idAvaliado: avaliado.idAvaliado,
            avaliado: avaliado.nomeAvaliado,
            ciencia: 1,
            posicao: posicao,
            aproximacao: apx.toFixed(0),
            charts: [{
              labels: this.setChartLabelsAxiologia,
              data: this.chartDatasetsAxiologia
            }]
          });

  }

  private setGraficoResultadoComportamento(avaliado?, posicao?, apx?) {

    const valuesChartComportamento = this.newArrayComportamento;

    this.setChartLabelsComportamento = [];
    this.chartDatasetsComportamento = [];
    this.chartLabelsComportamento = [];

    valuesChartComportamento.forEach((avaliadosCargo, index) => {
      avaliadosCargo.resultados.map(fatoresComportamento => {
        if (index === 0) {
          switch (fatoresComportamento.nomeFator) {
            case 'DISC NATURAL - Dominância':
              this.setChartLabelsComportamento.push('Dominância');
            break;

            case 'DISC NATURAL - Influência':
              this.setChartLabelsComportamento.push('Influência');
            break;

            case 'DISC NATURAL - Estabilidade':
              this.setChartLabelsComportamento.push('Estabilidade');
            break;

            case 'DISC NATURAL - Cautela':
              this.setChartLabelsComportamento.push('Cautela');
            break;

            case 'DISC ADAPTÁVEL - Dominância':
              this.setChartLabelsComportamento.push('Dominância (A)');
            break;

            case 'DISC ADAPTÁVEL - Influência':
              this.setChartLabelsComportamento.push('Influência (A)');
            break;

            case 'DISC ADAPTÁVEL - Estabilidade':
              this.setChartLabelsComportamento.push('Estabilidade (A)');
            break;

            case 'DISC ADAPTÁVEL - Cautela':
              this.setChartLabelsComportamento.push('Cautela (A)');
            break;
          }
          // this.setChartLabelsComportamento.push(fatoresComportamento.nomeFator);
        }

      });
    });

    let fatorIgual: boolean;
    valuesChartComportamento.forEach(fatoresComp => {

      fatoresComp.resultados.forEach(fatorComp => {

      fatorIgual = false;

      this.chartDatasetsComportamento.forEach(chartData => {

        if (this.chartDatasetsComportamento
          && +fatoresComp.idAvaliado === +avaliado.idAvaliado
          && fatoresComp.nomeAvaliado === chartData.label
          && fatoresComp.nomeAvaliado !== 'Perfil de referência'
          || fatoresComp.nomeAvaliado === 'Perfil de referência'
          && chartData.label === 'Perfil de referência') {
          chartData.data.push(
              fatorComp.valor
          );
          fatorIgual = true;
        }
      });

      if (!fatorIgual && fatoresComp.idAvaliado === avaliado.idAvaliado) {
        this.chartDatasetsComportamento.push({
          data: [fatorComp.valor],
          label: fatoresComp.nomeAvaliado
        });
      }
      if (!fatorIgual && fatoresComp.nomeAvaliado === 'Perfil de referência') {
        this.chartDatasetsComportamento.push({
          data: [fatorComp.valor],
          label: fatoresComp.nomeAvaliado
        });
      }
     });
    });

    this.chartLabelsComportamento = this.setChartLabelsComportamento;
    this.chartDatasetsComportamento = this.chartDatasetsComportamento;

    fatorIgual = false;

    this.newArrayChartsComportamento.map(result => {

      if (!fatorIgual && result.idAvaliado === avaliado.idAvaliado) {
        result.charts.push({
          labels: this.setChartLabelsComportamento,
          data: this.chartDatasetsComportamento
        })
      }
    });

    this.newArrayChartsComportamento.push({
      idAvaliado: avaliado.idAvaliado,
      avaliado: avaliado.nomeAvaliado,
      ciencia: 2,
      posicao: posicao,
      aproximacao: apx.toFixed(0),
      charts: [{
        labels: this.setChartLabelsComportamento,
        data: this.chartDatasetsComportamento
      }]
    });

  }

  private setGraficoResultadoMotivadores(avaliado?, posicao?, apx?) {

    const valuesChartMotivadores = this.newArrayMotivadores;

    this.setChartLabelsMotivadores = [];
    this.chartDatasetsMotivadores = [];
    this.chartLabelsMotivadores = [];

    valuesChartMotivadores.forEach((avaliadosCargo, index) => {
      avaliadosCargo.resultados.map(fatoresMotivadores => {
        if (index === 0) {
          this.setChartLabelsMotivadores.push(fatoresMotivadores.nomeFator);
        }

      });
    });

    let fatorIgual: boolean;

    valuesChartMotivadores.forEach(fatoresMotivadores => {

      fatoresMotivadores.resultados.forEach(fatorMotivadores => {

      fatorIgual = false;

      this.chartDatasetsMotivadores.forEach(chartData => {

        if (this.chartDatasetsMotivadores
          && +fatoresMotivadores.idAvaliado === +avaliado.idAvaliado
          && fatoresMotivadores.nomeAvaliado === chartData.label
          && fatoresMotivadores.nomeAvaliado !== 'Perfil de referência'
          || fatoresMotivadores.nomeAvaliado === 'Perfil de referência'
          && chartData.label === 'Perfil de referência') {
          chartData.data.push(
              fatorMotivadores.valor
          );
          fatorIgual = true;
        }

      });

      if (!fatorIgual && +fatoresMotivadores.idAvaliado === +avaliado.idAvaliado) {
        this.chartDatasetsMotivadores.push({
          data: [fatorMotivadores.valor],
          label: fatoresMotivadores.nomeAvaliado
        });
      }
      if (!fatorIgual && fatoresMotivadores.nomeAvaliado === 'Perfil de referência') {
        this.chartDatasetsMotivadores.push({
          data: [fatorMotivadores.valor],
          label: fatoresMotivadores.nomeAvaliado
        });
      }

    });

  });

    this.chartLabelsMotivadores = this.setChartLabelsMotivadores;
    this.chartDatasetsMotivadores = this.chartDatasetsMotivadores;

    fatorIgual = false;

    this.newArrayChartsMotivadores.map(result => {

      if (!fatorIgual && result.idAvaliado === avaliado.idAvaliado) {
        result.charts.push({
          labels: this.setChartLabelsMotivadores,
          data: this.chartDatasetsMotivadores
        })
      }
    });

    this.newArrayChartsMotivadores.push({
      idAvaliado: avaliado.idAvaliado,
      avaliado: avaliado.nomeAvaliado,
      ciencia: 3,
      posicao: posicao,
      aproximacao: apx.toFixed(0),
      charts: [{
        labels: this.setChartLabelsMotivadores,
        data: this.chartDatasetsMotivadores,
      }]
    });

  }

}
