/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.109
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 *
 */
export interface LucorApiModelsNewNewUser {
  /**
   * Gets or Sets UserName
   */
  userName: string;
  /**
   * Gets or Sets Email
   */
  email: string;
  /**
   * Gets or Sets Password
   */
  password: string;
  /**
   * Gets or Sets PessoaJuridicaId
   */
  pessoaJuridicaId?: number;
}
