/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.49
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface LucorApiModelsUpdUpdConfiguracaoEmail { 
    /**
     * Gets or Sets Server
     */
    server?: string;
    /**
     * Gets or Sets Port
     */
    port?: number;
    /**
     * Gets or Sets SmtpUsername
     */
    smtpUsername?: string;
    /**
     * Gets or Sets SmtpPassword
     */
    smtpPassword?: string;
    /**
     * Gets or Sets FromEmail
     */
    fromEmail?: string;
    /**
     * Gets or Sets FromDisplayName
     */
    fromDisplayName?: string;
    /**
     * Gets or Sets EnviarEmailInconsistencia
     */
    enviarEmailInconsistencia?: boolean;
}
