import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filterPalavrasChave'
})
export class FilterPipePalavrasChave implements PipeTransform {

  transform(items: any[], searchPalavra: string): any[] {
    if (!items) { return []; }
    if (!searchPalavra) { return items; }

    searchPalavra = searchPalavra.toLowerCase();
    return items.filter(it => {
      return it.palavrasChave.toLowerCase().includes(searchPalavra);
    });
  }

}
