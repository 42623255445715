import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  frase: string;
  frases = [
    '"Liderança é a capacidade de traduzir visão em realidade."<br><small>-- Warren Bennis</small>',
    '"A definição convencional de gestão é ter o trabalho feito pelas pessoas, mas a real definição de gestão é desenvolver as pessoas por meio do trabalho."<br><small>-- Agha Hasan Abedi</small>',
    '"Nós somos o que fazemos repetidamente. Excelência, portanto, não é um ato, mas um hábito."<br><small>-- Aristóteles</small>',
    '"Sempre trate os seus empregados exatamente como você gostaria que eles tratassem os seus melhores clientes."<br><small>-- Stephen R. Covey</small>',
    '"Você precisa construir um time tão talentoso que faça com que você se sinta quase inconfortável ao redor deles."<br><small>-- Brian Chesky</small>',
    '"Boa gestão é a arte de tornar os problemas tão interessantes e suas soluções tão construtivas que todos vão querer trabalhar e lidar com eles."<br><small>-- Paul Hawken</small>',
    '"Gerenciamento é substituir músculos por pensamentos, folclore e superstição por conhecimento, e força por cooperação."<br><small>-- Peter Drucker</small>',
    '"O que pode ser medido pode ser melhorado."<br><small>-- Peter Drucker</small>',
    '"Se você não pode medir, você não pode gerenciar."<br><small>-- Peter Drucker</small>'
  ];

  @Input() backdrop = false;

  constructor() { }

  ngOnInit() {
    this.randomFrases();
  }

  randomFrases() {
    this.frase = this.frases[Math.floor(Math.random() * this.frases.length)];
    setTimeout(() => {
      this.refreshFrase();
    }, 6000);
  }

  refreshFrase() {
    this.randomFrases();
  }

}
