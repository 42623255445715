import { publishReplay, refCount } from 'rxjs/operators';
/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.45
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { LucorApiModelsNewPessoaJuridica } from '../model/lucorApiModelsNewPessoaJuridica';
import { LucorApiModelsPessoaJuridica } from '../model/lucorApiModelsPessoaJuridica';
import { LucorApiModelsUpdPessoaJuridica } from '../model/lucorApiModelsUpdPessoaJuridica';


import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { environment } from 'environments/environment';


@Injectable()
export class ClienteApiService {

    protected basePath = environment.apiBasePath;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    _clientes: Observable<any> = null; // Variável criada para utilizar o Cache ###

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    clearCache() {
        this._clientes = null; // Limpa a variável para excluir o Cache das avaliações. ###
    }

    /**
     * Incluir Novo Cliente
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addClienteAsync(body?: LucorApiModelsNewPessoaJuridica, observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsPessoaJuridica>;
    public addClienteAsync(body?: LucorApiModelsNewPessoaJuridica, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsPessoaJuridica>>;
    public addClienteAsync(body?: LucorApiModelsNewPessoaJuridica, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsPessoaJuridica>>;
    public addClienteAsync(body?: LucorApiModelsNewPessoaJuridica, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        this.clearCache(); // ###

        let headers = this.defaultHeaders;


        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LucorApiModelsPessoaJuridica>(`${this.basePath}/cliente`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Excluir Cliente
     *
     * @param clienteId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteClienteAsync(clienteId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteClienteAsync(clienteId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteClienteAsync(clienteId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteClienteAsync(clienteId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clienteId === null || clienteId === undefined) {
            throw new Error('Required parameter clienteId was null or undefined when calling deleteClienteAsync.');
        }

        this.clearCache(); // ###

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [];

        return this.httpClient.delete<any>(`${this.basePath}/cliente/${encodeURIComponent(String(clienteId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Obter Cliente pelo Id
     *
     * @param clienteId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getClienteById(clienteId: number, observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsPessoaJuridica>;
    public getClienteById(clienteId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsPessoaJuridica>>;
    public getClienteById(clienteId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsPessoaJuridica>>;
    public getClienteById(clienteId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clienteId === null || clienteId === undefined) {
            throw new Error('Required parameter clienteId was null or undefined when calling getClienteById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [];

        return this.httpClient.get<LucorApiModelsPessoaJuridica>(`${this.basePath}/cliente/${encodeURIComponent(String(clienteId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Listar Clientes
     *
     * @param withoutChildren
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getClientesAsync(withoutChildren?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<LucorApiModelsPessoaJuridica>>;
    public getClientesAsync(withoutChildren?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LucorApiModelsPessoaJuridica>>>;
    public getClientesAsync(withoutChildren?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LucorApiModelsPessoaJuridica>>>;
    public getClientesAsync(withoutChildren?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (withoutChildren !== undefined && withoutChildren !== null) {
            queryParameters = queryParameters.set('withoutChildren', withoutChildren as any);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [];

        if (!this._clientes) {
            this._clientes = this.httpClient.get<Array<LucorApiModelsPessoaJuridica>>(`${this.basePath}/cliente`,
                {
                    params: queryParameters,
                    withCredentials: this.configuration.withCredentials,
                    headers,
                    observe,
                    reportProgress
                }
            ).pipe(publishReplay(1), refCount());
        }
        return this._clientes;
    }

    /**
     * Atualizar Cliente
     *
     * @param clienteId
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateClienteAsync(clienteId: number, body?: LucorApiModelsUpdPessoaJuridica, observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsPessoaJuridica>;
    public updateClienteAsync(clienteId: number, body?: LucorApiModelsUpdPessoaJuridica, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsPessoaJuridica>>;
    public updateClienteAsync(clienteId: number, body?: LucorApiModelsUpdPessoaJuridica, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsPessoaJuridica>>;
    public updateClienteAsync(clienteId: number, body?: LucorApiModelsUpdPessoaJuridica, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clienteId === null || clienteId === undefined) {
            throw new Error('Required parameter clienteId was null or undefined when calling updateClienteAsync.');
        }

        this.clearCache(); // ###

        let headers = this.defaultHeaders;


        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<LucorApiModelsPessoaJuridica>(`${this.basePath}/cliente/${encodeURIComponent(String(clienteId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

}
