/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.68
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type SexoEnum = 'Masculino' | 'Feminino';

export const SexoEnum = {
    Masculino: 'Masculino' as SexoEnum,
    Feminino: 'Feminino' as SexoEnum
};
