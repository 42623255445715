import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MDBBootstrapModule, ChartsModule } from 'angular-bootstrap-md';
import { OrderModule } from 'ngx-order-pipe';
import { NgxFileDropModule } from 'ngx-file-drop';
import { SharedModule } from 'app/shared/shared.module';
import { ColorPickerModule } from 'ngx-color-picker';

import { AvaliacoesComponent } from './components/avaliacoes/avaliacoes.component';
import { AvaliacoesFiltroComponent } from './components/avaliacoes-filtro/avaliacoes-filtro.component';
import { AvaliacoesSearchAxiologiaComponent } from './components/avaliacoes-search-axiologia/avaliacoes-search-axiologia.component';
import { AvaliacoesSearchComportamentoComponent } from './components/avaliacoes-search-comportamento/avaliacoes-search-comportamento.component';
import { AvaliacoesSearchMotivadoresComponent } from './components/avaliacoes-search-motivadores/avaliacoes-search-motivadores.component';
import { AvaliacoesDetalhesComponent } from './components/avaliacoes-detalhes/avaliacoes-detalhes.component';
import { AvaliacoesAvaliadosComponent } from './components/avaliacoes-avaliados/avaliacoes-avaliados.component';
import { AvaliacoesCompararComponent } from './components/avaliacoes-comparar/avaliacoes-comparar.component';
import { AvaliacoesEstatisticasComponent } from './components/avaliacoes-estatisticas/avaliacoes-estatisticas.component';
import { AvaliacoesCadastrarComponent } from './components/avaliacoes-cadastrar/avaliacoes-cadastrar.component';
import { AvaliacoesAtualizarComponent } from './components/avaliacoes-atualizar/avaliacoes-atualizar.component';
import { SetPerfilComponent } from './components/set-perfil/set-perfil.component';
import { SetFatoresComponent } from './components/set-fatores/set-fatores.component';
import { AvaliacoesRoutingModule } from './avaliacoes.routing.module';
import { SetPerfilCargoComponent } from './components/set-perfil-cargo/set-perfil-cargo.component';
import { AvaliacoesResolver } from 'app/core/resolvers/avaliacoes.resolver';
import { AvaliacoesClonarComponent } from './components/avaliacoes-clonar/avaliacoes-clonar.component';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    SharedModule,
    OrderModule,
    NgxFileDropModule,
    ChartsModule,
    MDBBootstrapModule.forRoot(),
    AvaliacoesRoutingModule,
    ColorPickerModule
  ],
  declarations: [
    AvaliacoesComponent,
    AvaliacoesFiltroComponent,
    AvaliacoesSearchAxiologiaComponent,
    AvaliacoesSearchComportamentoComponent,
    AvaliacoesSearchMotivadoresComponent,
    AvaliacoesDetalhesComponent,
    AvaliacoesAvaliadosComponent,
    AvaliacoesCompararComponent,
    AvaliacoesEstatisticasComponent,
    AvaliacoesCadastrarComponent,
    AvaliacoesAtualizarComponent,
    SetPerfilComponent,
    SetFatoresComponent,
    SetPerfilCargoComponent,
    AvaliacoesClonarComponent
  ],
  exports: [],
  providers: [
    AvaliacoesResolver
  ]
})

export class AvaliacoesModule {}
