import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../core/auth/guards/auth.guard';
import { ClientesResolver } from '../../core/resolvers/clientes.resolver';
import { ClientesComponent } from './components/clientes/clientes.component';
import { DetalhesClienteComponent } from './components/detalhes-cliente/detalhes-cliente.component';
import { EditarClienteComponent } from './components/editar-cliente/editar-cliente.component';
import { AdicionarClienteComponent } from './components/adicionar-cliente/adicionar-cliente.component';

const clientesRoutes: Routes = [
  { path: 'clientes', data: { breadcrumbs: 'Clientes' },
    canActivate: [AuthGuard],
    children: [
      { path: '', component: ClientesComponent, canActivate: [AuthGuard] },
      { path: 'cadastrar', component: AdicionarClienteComponent,
        data: { breadcrumbs: 'Cadastrar cliente' },
        canActivate: [AuthGuard]
      },
      { path: ':Id',
        data: { breadcrumbs: '{{ clienteResolver.razaoSocial }}' },
        resolve: { clienteResolver: ClientesResolver },
        canActivate: [AuthGuard],
        children: [
          { path: '', component: DetalhesClienteComponent, canActivate: [AuthGuard] },
          { path: 'editar', component: EditarClienteComponent,
            data: { breadcrumbs: 'Editar' },
            resolve: { clienteResolver: ClientesResolver },
            canActivate: [AuthGuard]
          },
        ]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(clientesRoutes)],
  exports: [RouterModule]
})
export class ClientesRoutingModule {}

