import { Component, OnDestroy, OnInit } from '@angular/core';
import { take, switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { Location } from '@angular/common';
import { RegisterService } from 'app/api/register.service';
import { ClienteApiService } from 'app/api/clienteApi.service';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { LucorApiModelsDetailsUser } from 'app/model/lucorApiModelsDetailsUser';
import { LucorApiModelsPessoaJuridica } from 'app/model/lucorApiModelsPessoaJuridica';
import { LanguageDataTable } from 'app/shared/datatable/translate-datatable';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit, OnDestroy {

  usuarios: LucorApiModelsDetailsUser[];
  clientes: LucorApiModelsPessoaJuridica[];
  show = true;

  dtOptions: DataTables.Settings = {};
  
  public paginaAtual: number = 0;
  public itensPorPagina: number;
  public totalItems: any;

  private subs = new SubSink();

  constructor(
    private usuariosService: RegisterService,
    private clientesService: ClienteApiService,
    private alertService: AlertModalService,
    private location: Location) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'numbers',
      pageLength: 25,
      processing: true,
      responsive: true,
      order:[0, 'asc'],
      language: LanguageDataTable.portugues_datatables
    };
    this.getUsuarios();
    this.getClientes();
  }

  offUser(usuario: LucorApiModelsDetailsUser) {

    const result$ = this.alertService.showConfirm('Confirmação', 'Tem certeza que bloquear o acesso desse usuário?');
    result$.asObservable()
    .pipe(
      take(1),
      switchMap(result => result ? this.usuariosService.bloquearUsuario(usuario.id) : EMPTY)
    )
    .subscribe(
      success => {
        this.alertService.showAlertSuccess('Acesso do usuário bloquado com sucesso!');
        this.getUsuarios();
        this.show = false // tirar tabela do DOM
        setTimeout(() => {
          this.show = true;
        }, 1500);
      },
      error => {
        this.alertService.showAlertDanger('Erro ao bloquear o usuário. Tente novamente mais tarde.');
      }
    );
  }

  onUser(usuario: LucorApiModelsDetailsUser) {

    const result$ = this.alertService.showConfirm('Confirmação', 'Tem certeza que desbloquear o acesso desse usuário?');
    result$.asObservable()
    .pipe(
      take(1),
      switchMap(result => result ? this.usuariosService.desbloquearUsuario(usuario.id) : EMPTY)
    )
    .subscribe(
      success => {
        this.alertService.showAlertSuccess('Acesso do usuário desbloquado com sucesso!');
        this.getUsuarios();
        this.show = false // tirar tabela do DOM
        setTimeout(() => {
          this.show = true;
        }, 1500);
      },
      error => {
        this.alertService.showAlertDanger('Erro ao desbloquear o usuário. Tente novamente mais tarde.');
      }
    );
  }

  onDelete(usuario: LucorApiModelsDetailsUser) {

    const result$ = this.alertService.showConfirm('Confirmação', 'Tem certeza que deseja excluir esse usuário?');
    result$.asObservable()
    .pipe(
      take(1),
      switchMap(result => result ? this.usuariosService.excluirUsuarioAsync(usuario.id) : EMPTY)
    )
    .subscribe(
      success => {
        this.alertService.showAlertSuccess('Usuário excluído com sucesso!');
        this.getUsuarios();
      },
      error => {
        this.alertService.showAlertDanger('Erro ao excluir o usuário. Tente novamente mais tarde.');
      }
    );
  }

  goBack(): void {
    this.location.back();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private getUsuarios() {
    this.subs.sink = this.usuariosService.getUsuarios()
    .subscribe(
      usuarios => {
        this.usuarios = usuarios;
      });
  }

  private getClientes() {
    this.subs.sink = this.clientesService.getClientesAsync()
    .subscribe(
      clientes => {
        this.clientes = clientes;
      });
  }

}

