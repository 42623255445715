/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.49
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Observable } from 'rxjs';
import { LucorApiModelsDetailsAnexo } from '../model/lucorApiModelsDetailsAnexo';
import { LucorApiModelsDetailsConfiguracaoEmail } from '../model/lucorApiModelsDetailsConfiguracaoEmail';
import { LucorApiModelsDetailsDestinatario } from '../model/lucorApiModelsDetailsDestinatario';
import { LucorApiModelsDetailsMensagemEmail } from '../model/lucorApiModelsDetailsMensagemEmail';
import { LucorApiModelsNewNewMensagemEmail } from '../model/lucorApiModelsNewNewMensagemEmail';
import { LucorApiModelsUpdUpdConfiguracaoEmail } from '../model/lucorApiModelsUpdUpdConfiguracaoEmail';
import { LucorApiModelsUpdUpdMensagemEmail } from '../model/lucorApiModelsUpdUpdMensagemEmail';
import { MicrosoftAspNetCoreMvcFileContentResult } from '../model/microsoftAspNetCoreMvcFileContentResult';
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { environment } from 'environments/environment';


@Injectable()
export class MensagensApiService {

    protected basePath = environment.apiBasePath;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Incluir Anexo ao Rascunho de Mensagem de E-mail
     *
     * @param mensagemId
     * @param upfile
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addAnexoEmailAsync(mensagemId: number, upfile?: Blob, observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsDetailsAnexo>;
    public addAnexoEmailAsync(mensagemId: number, upfile?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsDetailsAnexo>>;
    public addAnexoEmailAsync(mensagemId: number, upfile?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsDetailsAnexo>>;
    public addAnexoEmailAsync(mensagemId: number, upfile?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mensagemId === null || mensagemId === undefined) {
            throw new Error('Required parameter mensagemId was null or undefined when calling addAnexoEmailAsync.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (upfile !== undefined) {
          formParams = formParams.append('upfile', upfile as any) || formParams;
      }

        return this.httpClient.post<LucorApiModelsDetailsAnexo>(`${this.basePath}/mensagens/${encodeURIComponent(String(mensagemId))}/anexos`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Incluir Rascunho de Mensagem de E-mail
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addRacunhoEmailAsync(body?: LucorApiModelsNewNewMensagemEmail, observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsDetailsMensagemEmail>;
    public addRacunhoEmailAsync(body?: LucorApiModelsNewNewMensagemEmail, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsDetailsMensagemEmail>>;
    public addRacunhoEmailAsync(body?: LucorApiModelsNewNewMensagemEmail, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsDetailsMensagemEmail>>;
    public addRacunhoEmailAsync(body?: LucorApiModelsNewNewMensagemEmail, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LucorApiModelsDetailsMensagemEmail>(`${this.basePath}/mensagens`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Definir Configurações de Envio de Emails
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public configurarEmailAsync(body?: LucorApiModelsUpdUpdConfiguracaoEmail, observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsDetailsConfiguracaoEmail>;
    public configurarEmailAsync(body?: LucorApiModelsUpdUpdConfiguracaoEmail, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsDetailsConfiguracaoEmail>>;
    public configurarEmailAsync(body?: LucorApiModelsUpdUpdConfiguracaoEmail, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsDetailsConfiguracaoEmail>>;
    public configurarEmailAsync(body?: LucorApiModelsUpdUpdConfiguracaoEmail, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LucorApiModelsDetailsConfiguracaoEmail>(`${this.basePath}/mensagens/configuracao`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Enviar Mensagem de E-mail
     *
     * @param mensagemId
     * @param reenviarSeJaEnviada
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public enviarEmailAsync(mensagemId: number, reenviarSeJaEnviada: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public enviarEmailAsync(mensagemId: number, reenviarSeJaEnviada: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public enviarEmailAsync(mensagemId: number, reenviarSeJaEnviada: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public enviarEmailAsync(mensagemId: number, reenviarSeJaEnviada: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mensagemId === null || mensagemId === undefined) {
            throw new Error('Required parameter mensagemId was null or undefined when calling enviarEmailAsync.');
        }

        if (reenviarSeJaEnviada === null || reenviarSeJaEnviada === undefined) {
            throw new Error('Required parameter reenviarSeJaEnviada was null or undefined when calling enviarEmailAsync.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (reenviarSeJaEnviada !== undefined && reenviarSeJaEnviada !== null) {
            queryParameters = queryParameters.set('reenviarSeJaEnviada', <any>reenviarSeJaEnviada);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<any>(`${this.basePath}/mensagens/${encodeURIComponent(String(mensagemId))}/enviar`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Enviar E-mail de Teste
     *
     * @param enderecoDestinatario
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public enviarEmailTesteAsync(enderecoDestinatario?: LucorApiModelsDetailsDestinatario, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public enviarEmailTesteAsync(enderecoDestinatario?: LucorApiModelsDetailsDestinatario, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public enviarEmailTesteAsync(enderecoDestinatario?: LucorApiModelsDetailsDestinatario, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public enviarEmailTesteAsync(enderecoDestinatario?: LucorApiModelsDetailsDestinatario, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<string>(`${this.basePath}/mensagens/configuracao/testar`,
            enderecoDestinatario,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Excluir Anexo de Mensagem de E-mail
     *
     * @param anexoId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public excluirAnexoEmailAsync(anexoId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public excluirAnexoEmailAsync(anexoId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public excluirAnexoEmailAsync(anexoId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public excluirAnexoEmailAsync(anexoId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (anexoId === null || anexoId === undefined) {
            throw new Error('Required parameter anexoId was null or undefined when calling excluirAnexoEmailAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/mensagens/anexos/${encodeURIComponent(String(anexoId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Excluir Rascunho de Mensagem de E-mail
     *
     * @param mensagemId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public excluirEmailAsync(mensagemId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public excluirEmailAsync(mensagemId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public excluirEmailAsync(mensagemId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public excluirEmailAsync(mensagemId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mensagemId === null || mensagemId === undefined) {
            throw new Error('Required parameter mensagemId was null or undefined when calling excluirEmailAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/mensagens/${encodeURIComponent(String(mensagemId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Listar Anexos da Mensagem de E-mail
     *
     * @param mensagemId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listarAnexosMensagemEmailAsync(mensagemId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<LucorApiModelsDetailsAnexo>>;
    public listarAnexosMensagemEmailAsync(mensagemId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LucorApiModelsDetailsAnexo>>>;
    public listarAnexosMensagemEmailAsync(mensagemId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LucorApiModelsDetailsAnexo>>>;
    public listarAnexosMensagemEmailAsync(mensagemId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mensagemId === null || mensagemId === undefined) {
            throw new Error('Required parameter mensagemId was null or undefined when calling listarAnexosMensagemEmailAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<LucorApiModelsDetailsAnexo>>(`${this.basePath}/mensagens/${encodeURIComponent(String(mensagemId))}/anexos`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Listar Mensagens de E-mail
     *
     * @param pessoaJuridicaId
     * @param emailAddress
     * @param avaliacaoId
     * @param statusEnvio
     * @param tipoMensagem
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listarMensagensEmailAsync(pessoaJuridicaId?: number, emailAddress?: string, avaliacaoId?: number, statusEnvio?: 'rascunho' | 'enviado', tipoMensagem?: 'inconsistencia', observe?: 'body', reportProgress?: boolean): Observable<Array<LucorApiModelsDetailsMensagemEmail>>;
    public listarMensagensEmailAsync(pessoaJuridicaId?: number, emailAddress?: string, avaliacaoId?: number, statusEnvio?: 'rascunho' | 'enviado', tipoMensagem?: 'inconsistencia', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LucorApiModelsDetailsMensagemEmail>>>;
    public listarMensagensEmailAsync(pessoaJuridicaId?: number, emailAddress?: string, avaliacaoId?: number, statusEnvio?: 'rascunho' | 'enviado', tipoMensagem?: 'inconsistencia', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LucorApiModelsDetailsMensagemEmail>>>;
    public listarMensagensEmailAsync(pessoaJuridicaId?: number, emailAddress?: string, avaliacaoId?: number, statusEnvio?: 'rascunho' | 'enviado', tipoMensagem?: 'inconsistencia', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (pessoaJuridicaId !== undefined && pessoaJuridicaId !== null) {
            queryParameters = queryParameters.set('pessoaJuridicaId', pessoaJuridicaId as any);
        }
        if (emailAddress !== undefined && emailAddress !== null) {
            queryParameters = queryParameters.set('emailAddress', emailAddress as any);
        }
        if (avaliacaoId !== undefined && avaliacaoId !== null) {
            queryParameters = queryParameters.set('avaliacaoId', avaliacaoId as any);
        }
        if (statusEnvio !== undefined && statusEnvio !== null) {
            queryParameters = queryParameters.set('statusEnvio', statusEnvio as any);
        }
        if (tipoMensagem !== undefined && tipoMensagem !== null) {
            queryParameters = queryParameters.set('tipoMensagem', tipoMensagem as any);
        }

        let headers = this.defaultHeaders;


        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<LucorApiModelsDetailsMensagemEmail>>(`${this.basePath}/mensagens`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Fazer Download do Anexo da Mensagem de E-mail
     *
     * @param anexoId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public obterAnexoMensagemEmailAsync(anexoId: number, observe?: 'body', reportProgress?: boolean): Observable<MicrosoftAspNetCoreMvcFileContentResult>;
    public obterAnexoMensagemEmailAsync(anexoId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MicrosoftAspNetCoreMvcFileContentResult>>;
    public obterAnexoMensagemEmailAsync(anexoId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MicrosoftAspNetCoreMvcFileContentResult>>;
    public obterAnexoMensagemEmailAsync(anexoId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (anexoId === null || anexoId === undefined) {
            throw new Error('Required parameter anexoId was null or undefined when calling obterAnexoMensagemEmailAsync.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MicrosoftAspNetCoreMvcFileContentResult>(`${this.basePath}/mensagens/anexos/${encodeURIComponent(String(anexoId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Obter Configurações de Envio de Emails
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public obterConfiguracaoEmailAsync(observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsDetailsConfiguracaoEmail>;
    public obterConfiguracaoEmailAsync(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsDetailsConfiguracaoEmail>>;
    public obterConfiguracaoEmailAsync(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsDetailsConfiguracaoEmail>>;
    public obterConfiguracaoEmailAsync(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<LucorApiModelsDetailsConfiguracaoEmail>(`${this.basePath}/mensagens/configuracao`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Consultar Mensagem de E-mail
     *
     * @param mensagemId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public obterMensagemEmailAsync(mensagemId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<LucorApiModelsDetailsMensagemEmail>>;
    public obterMensagemEmailAsync(mensagemId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LucorApiModelsDetailsMensagemEmail>>>;
    public obterMensagemEmailAsync(mensagemId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LucorApiModelsDetailsMensagemEmail>>>;
    public obterMensagemEmailAsync(mensagemId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mensagemId === null || mensagemId === undefined) {
            throw new Error('Required parameter mensagemId was null or undefined when calling obterMensagemEmailAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<LucorApiModelsDetailsMensagemEmail>>(`${this.basePath}/mensagens/${encodeURIComponent(String(mensagemId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Atualizar Rascunho de Mensagem de E-mail
     *
     * @param mensagemId
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateEmailAsync(mensagemId: number, body?: LucorApiModelsUpdUpdMensagemEmail, observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsDetailsMensagemEmail>;
    public updateEmailAsync(mensagemId: number, body?: LucorApiModelsUpdUpdMensagemEmail, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsDetailsMensagemEmail>>;
    public updateEmailAsync(mensagemId: number, body?: LucorApiModelsUpdUpdMensagemEmail, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsDetailsMensagemEmail>>;
    public updateEmailAsync(mensagemId: number, body?: LucorApiModelsUpdUpdMensagemEmail, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mensagemId === null || mensagemId === undefined) {
            throw new Error('Required parameter mensagemId was null or undefined when calling updateEmailAsync.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<LucorApiModelsDetailsMensagemEmail>(`${this.basePath}/mensagens/${encodeURIComponent(String(mensagemId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

}
