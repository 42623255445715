import { Component, OnDestroy, OnInit } from '@angular/core';
import { CienciaApiService, LucorApiModelsCiencia } from 'app';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { SubSink } from 'subsink';


@Component({
  selector: 'app-palavras-chave-cadastrar',
  templateUrl: './palavras-chave-cadastrar.component.html',
  styleUrls: ['./palavras-chave-cadastrar.component.css']
})
export class PalavrasChaveCadastrarComponent implements OnInit, OnDestroy {

  ciencias: LucorApiModelsCiencia[];
  ciencia: LucorApiModelsCiencia;
  idCiencia: number;

  searchText = '';
  order = 'nome';

  private subs = new SubSink();

  constructor(
    private cienciasService: CienciaApiService,
    private location: Location,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.idCiencia = +this.route.snapshot.paramMap.get('Id');
    this.getCiencia();
    this.goTop();

  }

  getCiencia() {
    this.subs.sink = this.cienciasService.getCienciaByIdAsync(this.idCiencia)
    .subscribe(ciencia => {
      this.ciencia = ciencia;
    });
  }

  /*
* Campo de pesquisa por nome do fator
*/
setSearchTerm(term: string) {
  this.searchText = term;
}

getSearchTerm() {
    return this.searchText;
}

clearFilter() {
  this.searchText = '';
}


  goTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  goBack(): void {
    this.location.back();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }


}
