/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.45
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 *
 */
export interface LucorApiModelsFator {
    nome: string;
    descricao?: string;
    palavrasChave?: string;
    id?: number;
    createdAt?: Date;
    updatedAt?: Date;

    /* Adicionados */
    minimo: number;
    ideal: number;
    maximo: number;
    selected: boolean;
}
