/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.105
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type TipoAcao = 'Login' | 'Inclusao' | 'Edicao' | 'Exclusao' | 'Restauracao';

export const TipoAcao = {
    Login: 'Login' as TipoAcao,
    Inclusao: 'Inclusao' as TipoAcao,
    Edicao: 'Edicao' as TipoAcao,
    Exclusao: 'Exclusao' as TipoAcao,
    Restauracao: 'Restauracao' as TipoAcao
};