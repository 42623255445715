import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EMPTY, Observable, Subject } from 'rxjs';
import { share, switchMap, take } from 'rxjs/operators';
import Driver from 'driver.js';
import { CookieService } from '@ngx-toolkit/cookie';
import { FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { LucorApiModelsAvaliacao } from 'app/model/lucorApiModelsAvaliacao';
import { AvaliacaoApiService } from 'app/api/avaliacaoApi.service';
import { ServicoApiService } from 'app/api/servicoApi.service';
import { LucorApiModelsServico } from 'app/model/lucorApiModelsServico';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { LanguageDataTable } from 'app/shared/datatable/translate-datatable';
import { ExcelExportService } from 'app/shared/xlsx-download/excel-export.service';
import { LocalDateTimePipe  } from 'app/shared/pipes/local-date-time.pipe';

@Component({
  selector: 'app-avaliacoes-detalhes',
  templateUrl: './avaliacoes-detalhes.component.html',
  styleUrls: ['./avaliacoes-detalhes.component.css']
})

export class AvaliacoesDetalhesComponent implements OnInit, OnDestroy {

  avaliacao: LucorApiModelsAvaliacao;
  avaliacao$: Observable<LucorApiModelsAvaliacao>;
  idAvaliacao: number;
  servico$: Observable<LucorApiModelsServico>;
  idServico: number;
  userRole: string;

  atualizar = false;
  avaliacaoByCliente: LucorApiModelsAvaliacao;

  setPrintDados = [];
  setPrintListaAvaliados: string;
  headerDados = [ 'Avaliados', 'Média', 'Classificação', 'Data' ];
  printWait = false;

  order = 'nome';
  reverse = false;
  comparar = false;
  clonar = false;

  avaliadosCheck: number;
  avaliadosList: Array<any> = new Array<any>();
  avaliadosSelected: Array<any> = new Array<any>();
  avaliadosIds: Array<any> = new Array<any>();
  linkCurriculo: SafeResourceUrl;
  linkRelatorio: SafeResourceUrl;

  addAnexo: FormGroup;
  addAnexosLote: FormGroup;
  public files: NgxFileDropEntry[] = [];
  fileSend: File;
  fileDate;
  newFile = [];
  uploadSend;
  uploadSendMulti;
  uploading = false;
  successUpload = false;
  successUploadMulti = false;
  updateListagem = false;
  clickRelatorioDel = null;
  clickCurriculoDel = null;
  submitted = false;

  selectedCount: number;
  selectedAvaliados;
  selectAllChecked = false; // verifica se estão todos selecionados e marca no cabeçalho da listagem

  checkCookie = false;
  disableTour = false;
  driver = new Driver({
    className: 'tour-lucor',
    doneBtnText: 'Entendi', // Text on the final button
    closeBtnText: 'Fechar', // Text on the close button for this step
    nextBtnText: 'Próximo', // Next button text for this step
    prevBtnText: 'Anterior', // Previous button text for this step
  });

  dtOptions: DataTables.Settings = {};

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private servicosService: ServicoApiService,
    private avaliacaoService: AvaliacaoApiService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private location: Location,
    private cookieService: CookieService,
    private alertService: AlertModalService,
    private excelExportService: ExcelExportService,
    private sanitizer: DomSanitizer,
    private localDatePipe: LocalDateTimePipe
  ) {
    this.addAnexo = this.formBuilder.group({
      tipoAnexo: ['', Validators.required],
      avaliadoId:  null,
      sobrescreverExistente: null
    });
    this.addAnexosLote = this.formBuilder.group({
      sobrescreverExistentes: null
    });
  }

  get f() { return this.addAnexo.controls; }
  get tipoAnexoValue(): FormGroup { return this.addAnexo.get('tipoAnexo') as FormGroup; }
  get avaliadoIdValue(): FormGroup { return this.addAnexo.get('avaliadoId') as FormGroup; }
  get sobrescreverExistenteValue(): FormGroup { return this.addAnexo.get('sobrescreverExistente') as FormGroup;}
  get sobrescreverExistentesValue(): FormGroup { return this.addAnexosLote.get('sobrescreverExistentes') as FormGroup;}

  ngOnInit() {
    $.fn['dataTable'].ext.search.pop();
    this.idAvaliacao = +this.route.snapshot.paramMap.get('Id');
    this.getAvaliacaoById(this.idAvaliacao);
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 15,
      processing: true,
      responsive: true,
      order:[],
      language: LanguageDataTable.portugues_datatables
    };
    this.userRole = localStorage.getItem('userRole');
    this.linkCurriculo = null;
    this.linkRelatorio = null;
    this.goTop();
    this.selectedCount = 0;
    this.checkCookie = this.cookieService.hasItem('tourAvaDetalhes');
  }

  startTour(time: number): void {
    this.disableTour = true;
    // Define the steps for tour
    setTimeout(() => {
      this.driver.defineSteps([
        {
          element: '#one-element-tour',
          popover: {
            className: 'first-step-popover-class',
            title: 'Vamos fazer um tour?',
            description: 'Você pode usar as teclas <i class="fas fa-caret-left icon-tour"></i> e <i class="fas fa-caret-right icon-tour"></i> para avançar e voltar. Acesse aqui sempre que precisar.',
            position: 'left',
            closeBtnText: 'Eu vejo depois.',
            nextBtnText: 'Vamos lá!',
            prevBtnText: '<i class="fas fa-plane-departure"></i>'
          }
        },
        {
          element: '#two-element-tour',
          popover: {
            title: 'Botão "Dados do Grupo"',
            description: 'Aqui você verá os dados do perfil comportamental do grupo.',
            position: 'right'
          }
        },
        {
          element: '#three-element-tour',
          popover: {
            title: 'Botão "Analisar Perfis"',
            description: 'Aqui você irá fazer o mapeamento dos perfis. Através dos filtros é possível gerar rankings de aproximação geral e por ciência.',
            position: 'right'
          }
        },
        {
          element: '#four-element-tour-a',
          popover: {
            title: 'Botão "Ver Perfil Clone"',
            description: 'Para ativar este botão é necessário selecionar alguma pessoa. Aqui você poderá visualizar o perfil clone gerado a partir dos perfis dos selecionados. Você poderá criar um cargo a partir deste novo perfil.',
            position: 'right'
          }
        },
        {
          element: '#four-element-tour',
          popover: {
            title: 'Botão "Comparar Perfis"',
            description: 'Para ativar este botão é necessário selecionar duas ou mais pessoas. Aqui você poderá visualizar os dados lado a lado, possibilitando a comparação rápida entre os avaliados.',
            position: 'right'
          }
        },
        {
          element: '#four-element-tour-b',
          popover: {
            title: 'Botão "Baixar"',
            description: 'Faça o download com os dados desta listagem em um planilha.',
            position: 'right'
          }
        },
        {
          element: '.dataTables_filter',
          popover: {
            title: 'Campo de pesquisa',
            description: 'Aqui você pode fazer uma busca pela listagem.',
            position: 'bottom'
          }
        },
        {
          element: '#six-element-tour',
          popover: {
            title: 'Check Box "Todos"',
            description: 'Ao clicar aqui você irá marcar ou desmarcar todos os avaliados para a comparação ou gerar um perfil clone.',
            position: 'right'
          }
        },
        {
          element: '#seven-element-tour',
          stageBackground: '#7a7a7a',
          popover: {
            title: 'Check Box "Individual"',
            description: 'Ao clicar aqui você irá selecionar esta pessoa para que ele seja comparada com outra ou seja usada como base para um perfil clone.',
            position: 'right'
          }
        },
        {
          element: '#eight-element-tour-a',
          stageBackground: '#7a7a7a',
          popover: {
            title: 'Campo "Avaliados"',
            description: 'Este é o nome do avaliado.',
            position: 'right'
          }
        },
        {
          element: '#nine-element-tour',
          stageBackground: '#7a7a7a',
          popover: {
            title: 'Campo "Média"',
            description: 'Esta é a média geral do avaliado. Esta média é calculada a partir das médias das competências, mostradas na 3ª ciência.',
            position: 'right'
          }
        },
        {
          element: '#ten-element-tour',
          stageBackground: '#7a7a7a',
          popover: {
            title: 'Campo "Classificação"',
            description: 'Esta é a classificação com base na média geral do avaliado.',
            position: 'left'
          }
        },
        {
          element: '#eleven-element-tour',
          popover: {
            title: 'Botão "Ver Perfil"',
            description: 'Aqui você tem acesso as informações do perfil comportamental do avaliado.',
            position: 'left'
          }
        },
        {
          element: '#twelve-element-tour',
          popover: {
            title: 'Botão "Relatório"',
            description: 'Aqui você poderá visualizar o Relatório Comportamental do avaliado, caso esteja disponível.',
            position: 'left'
          }
        }
      ]);
      // Start the introduction
      this.driver.start();
      this.disableTour = false;
    }, time);
  }

  setCookie() {
    this.cookieService.setItem( 'tourAvaDetalhes', '1' );
  }

  getAvaliacaoById(id: number): void {

    this.avaliadosList = [];
    let mediaDasCompetencias: number | string;
    let setClassificacao: string;

    this.avaliacao$ = this.avaliacaoService.getAvaliacaoByIdAsync(id).pipe(share(), take(1));
    this.avaliacao$.forEach(avaliacaoDetalhes => {

      this.avaliacao = avaliacaoDetalhes;

      this.setPrintListaAvaliados = avaliacaoDetalhes.nomeArquivoOrigem;
      avaliacaoDetalhes.avaliados.forEach(avaliados => {
        this.avaliadosCheck = avaliacaoDetalhes.avaliados.length;
              avaliados.resultadosCiencias.forEach(resultadosCiencias => {
                resultadosCiencias.resultados.forEach(resultados => {
                  if (resultados.nomeFator === 'Média das Competências') {
                    mediaDasCompetencias = resultados.valor;
                    setClassificacao = resultados.classificacao;
                  }
                });
              });
              
              this.avaliadosList.push({ // cria um novo array com as informações necessárias
                id: avaliados.id,
                nome: avaliados.nome,
                email: avaliados.email,
                sexo: avaliados.sexo,
                media: mediaDasCompetencias,
                classificacao: setClassificacao,
                avaliacaoId: avaliados.avaliacaoId,
                anexos: avaliados.anexos,
                aberturaQuestionario: avaliados.dataSolicitacaoPreenchimento,
                conclusaoQuestinario: avaliados.dataConclusaoPreenchimento,
                selected: false
              });
            
            });
      this.idServico = avaliacaoDetalhes.servicoId;
      this.getServicoById(this.idServico);

      if (this.avaliacao && this.checkCookie === false) {
        this.startTour(2000);
        this.setCookie();
      }
    });
  }

  gerarLinkRelatorio(ref: string, id: number) {
    let link = ref;
    this.linkRelatorio = null;
    this.linkRelatorio = this.sanitizer.bypassSecurityTrustResourceUrl(link);
    if (this.linkRelatorio) {
      setTimeout(() => { 
        document.getElementById('openModalRelatorio_'+ id).click();
      }, 200);
    }
  }

  gerarLinkCurriculo(ref: string) {
    let link = ref;
    this.linkCurriculo = this.sanitizer.bypassSecurityTrustResourceUrl(link);
  }

  getServicoById(idServico: number): void {
    this.servico$ = this.servicosService.getServicoByIdAsync(idServico);
  }

  onChangeAvaliados(event: any, id: number) {

    if (this.avaliadosList) {

      this.avaliadosList.forEach(avaliados => {

        if (avaliados.id === id) { // verifica o Id do avaliado selecionado
          if (event === true) {
            avaliados.selected = true;
          } else if (event === false) {
            avaliados.selected = false;
          } else {
            avaliados.selected = event.target.checked; // marca como true ou false
          }

          if (avaliados.selected === true) {
              this.avaliadosSelected.push(avaliados); // se estiver selecionado adiciona à lista
              this.avaliadosIds.push(avaliados.id); // Adiciona o Id na lista para gerar os Cookies
          }

          if (avaliados.selected === false) {
                  const position = this.avaliadosSelected.findIndex((v) => { // retorna a posição do Avaliado no array
                    return  v.id === id;
                });
                  this.avaliadosSelected.splice(position, 1); // remove o Avaliado da lista

                  // Remove o Id da lista
                  const indexId = this.avaliadosIds.indexOf(avaliados.id);
                  if (indexId > -1) {
                    this.avaliadosIds.splice(indexId, 1);
                  }
              }
        }
      });
    }

    this.getSelectedAvaliados(); // função para contar e manipular os selecionados na página

  }

  onSelectAllAvaliados(event) {

    const checkedAll = event.target.checked;

    this.avaliadosSelected = [];
    this.avaliadosIds = [];

    if (this.avaliadosList) {

      this.avaliadosList.forEach(avaliados => {

          if (checkedAll === true) {
            avaliados.selected = true;
          } else if (checkedAll === false) {
            avaliados.selected = false;
          } else {
            avaliados.selected = event.target.checked;
          }

          if (avaliados.selected === true) {

              this.avaliadosSelected.push(avaliados); // se estiver selecionado adiciona à lista
              this.avaliadosIds.push(avaliados.id); // Adiciona o Id na lista

          }

          if (avaliados.selected === false) {

            const totalSelecionados = this.avaliadosSelected.length;

            this.avaliadosSelected.splice(0, totalSelecionados); // esvazia a lista
            this.avaliadosIds.splice(0, totalSelecionados); // esvazia a lista
          }
      });
    }
    this.getSelectedAvaliados(); // função para contar e manipular os selecionados na página
  }

  getSelectedAvaliados() {

    this.selectedCount = 0;

    this.selectedAvaliados = this.avaliadosSelected
        .filter(f => {
            if (f.selected) {
              this.selectedCount++;
            }
            return (f.selected);
        });

    this.selectedCount = this.selectedAvaliados.length;

    this.getSelectAllChecked();
  }

  getSelectAllChecked() { // verifica se estão todos marcados e marca o checked 'All'
    if (this.avaliadosSelected.length > 0) {
      if (this.comparar === false) {
        if (this.avaliadosSelected.length === this.avaliadosList.length) {
        this.selectAllChecked = true;
        } else {
          this.selectAllChecked = false;
        }
      }
    }
  }

  goComparar(event) {
    this.comparar = event;
  }

  goClonar(event) {
    this.clonar = event;
  }

  goAtualizar(event:LucorApiModelsAvaliacao) {
    this.atualizar = true;
    this.idAvaliacao = event.id;
    this.avaliacaoByCliente = event;
  }

  exitAtualizar() {
    this.atualizar = false;
  }

  hiddenAtualizar(refresh?: boolean) {
    this.atualizar = ! refresh;
    location.reload();
  }

  getPrintDados() {

    this.setPrintDados = [];
    this.printWait = true; // desabilita o botão para evitar duplo click
  
    if (this.avaliadosList) {
  
      this.avaliadosList.forEach(dados => {

        let data = this.localDatePipe.transform(dados.conclusaoQuestinario);

        this.setPrintDados.push([ 
          dados.nome,
          dados.media,
          dados.classificacao,
          data,
          dados.conclusaoQuestinario
        ]);
 
      });
   
      this.setPrintDados = this.setPrintDados.sort((d1, d2) => {
        return <any>new Date(d1[4]) - <any>new Date(d2[4]);   
      });
   
      this.printExcel();
    
    }
  }
  
  printExcel() {
    this.excelExportService.generateExcelLista(this.setPrintDados, this.setPrintListaAvaliados, this.headerDados);
    this.printWait = false;
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.newFile.push(file);
          this.fileSend = file;
          this.fileDate = file.lastModified;
        });
      } else {
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  public fileOver(event) {
    console.log('FileOver: ' + event);
  }

  public fileLeave(event) {
    console.log('FileLeave: ' + event);
  }

  deleteFile(item: NgxFileDropEntry) {
    this.newFile = [];
    this.files = this.files.filter(newFile => newFile !== item);
    this.dropped(this.files);
  }

  enviarAnexos(emLote: boolean) {
      
      let count = 0;

      if (emLote) { // Envio em Lote

        this.submitted = true;

        if (this.newFile.length === 0) { 
          this.alertService.showAlertWarning('Ops!! Você precisa selecionar pelo menos um arquivo.');
          return;
        }

        this.uploading = true;
        this.avaliacaoService.incluirAnexosAvaliadosLote(
            this.idAvaliacao,
            this.newFile,
            this.sobrescreverExistentesValue.value
            ).subscribe(success => {
              this.uploadSendMulti = success;
              this.successUploadMulti = true;
              this.uploading = false;
              // this.alertService.showAlertSuccess('Arquivos enviados com sucesso!');
          }, (err) => {
            console.error('Erro ao enviar arquivos!', err);
            this.uploading = false;
            this.alertService.showAlertDanger('Ops!! ' + err, 5000);
          }),

          () => console.log('HTTP request completed.');

      } else {

        this.submitted = true;

        if (this.addAnexo.invalid) { return; }

        if (this.newFile.length === 0) { 
          this.alertService.showAlertWarning('Ops!! Você precisa selecionar um arquivo.');
          return;
        }

        this.uploading = true;

        for (const arquivo of this.newFile) {

          this.avaliacaoService.incluirNovoAnexoAvaliado(
            this.tipoAnexoValue.value,
            this.idAvaliacao,
            this.avaliadoIdValue.value,
            arquivo,
            this.sobrescreverExistenteValue.value)
            .subscribe(success => {
              this.uploadSend = success;
              count++;
              if (count === this.newFile.length) {
                this.successUpload = true;
                this.uploading = false;
                // this.alertService.showAlertSuccess('Arquivo enviado com sucesso!');
              }
            }, (err) => {
              console.error('Erro ao enviar arquivo!', err);
              this.uploading = false;
              this.alertService.showAlertDanger('Ops! ' + err);
            }),
          () => console.log('HTTP request completed.');
        }
      } 
  }

  deleteAnexo(anexo) {
    const result$ = this.alertService.showConfirm('Confirmação', 'Tem certeza que deseja excluir o ' + anexo.tipo +  ' de ' + anexo.nome + '?');
    result$.asObservable()
    .pipe(
      take(1),
      switchMap(result => result ? this.avaliacaoService.removerAnexoAvaliado(anexo.avaliacaoId, anexo.avaliadoId, anexo.id) : EMPTY)
    )
    .subscribe(
      success => {
        this.alertService.showAlertSuccess('Anexo excluído com sucesso!');
        this.updateListagem = true;
        if (anexo.tipo === 'RelatorioComportamental') {
          this.clickRelatorioDel = document.querySelector('#RelatorioComportamental_'+ anexo.id);
          this.clickRelatorioDel.setAttribute('class', 'd-none');
        }
        if (anexo.tipo === 'Curriculo') {
          this.clickCurriculoDel = document.querySelector('#Curriculo_'+ anexo.id);
          this.clickCurriculoDel.setAttribute('class', 'd-none');
        }
      },
      error => {
        console.error(error);
        this.alertService.showAlertDanger(error);
      }
    );
  }

  setValueAnexo(id: number | string) {
    this.addAnexo.setValue({
      tipoAnexo: null,
      avaliadoId: id,
      sobrescreverExistente: null
    });
  }

  resetFormAnexo() {

    this.submitted = false;
    this.newFile = [];
    this.files = [];

    this.dropped(this.files);

    this.addAnexo.setValue({
      tipoAnexo: null,
      avaliadoId:  null,
      sobrescreverExistente: null
    });

    this.addAnexosLote.setValue({
      sobrescreverExistentes: null
    });

    if (this.successUpload || this.successUploadMulti) {
      setTimeout(() => {
        // this.getAvaliacaoById(this.idAvaliacao);
        this.updateListagem = true;
        this.successUpload = false;
        this.successUploadMulti = false;
      }, 500);
    }
  }

  updateList() {
    if (this.updateListagem) {
      this.getAvaliacaoById(this.idAvaliacao);
      this.updateListagem = false;
    } 
  }

  goTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  goBack(): void {
    this.location.back();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
