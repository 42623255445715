/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.45
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 *
 */

export interface ILucorApiModelsNewPerfil {
    minimo?: number | null;
    ideal?: number | null;
    maximo?: number | null;
    fator?: string | null;
    fatorId?: string | null;
    selected?: boolean;

}


export class LucorApiModelsNewPerfil implements ILucorApiModelsNewPerfil {

      minimo?: number | null;
      ideal?: number | null;
      maximo?: number | null;
      fator?: string | null;
      fatorId?: string | null;
      selected?: boolean;

}
