import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';
import { LucorApiModelsPessoaJuridica } from 'app/model/lucorApiModelsPessoaJuridica';
import { ClienteApiService } from 'app/api/clienteApi.service';
import { AuditoriaApiService } from 'app/api/auditoriaApi.service';
import { ResultadoPesquisaLogsAuditoria } from 'app/model/resultadoPesquisaLogsAuditoria';
import { ExcelExportService } from 'app/shared/xlsx-download/excel-export.service';
import { LogAuditoria } from 'app/model/logAuditoria';

@Component({
  selector: 'app-auditoria-logs',
  templateUrl: './auditoria-logs.component.html',
  styleUrls: ['./auditoria-logs.component.css']
})

export class AuditoriaLogsComponent implements OnInit, OnDestroy {

  clientes$: Observable<LucorApiModelsPessoaJuridica[]>;
  log: ResultadoPesquisaLogsAuditoria[];
  logResults: LogAuditoria[] = [];
  
  setPrintDados = [];
  headerDados = [ 'Cliente', 'Usuário', 'E-mail', 'Dia e hora', 'Tipo Ação', 'Ação', 'Detalhes da Ação' ];
  printWait = false;
  
  logForm: FormGroup;
  dataInicio: Date = null;
  dataFim: Date = null;
  submitted = false;
  public itensPorPagina: number = 20;
  public paginaAtual: number = 0;
  public totalItems: number = 0;
  public itemsAtuais: number = 0;
  public totalPages: number = 0;
  public loading: boolean = false;

  private subs = new SubSink();

  constructor(
    private auditoriaService: AuditoriaApiService,
    private clientesService: ClienteApiService,
    private formBuilder: FormBuilder,
    private excelExportService: ExcelExportService
  ) {}

  ngOnInit(): void {
    this.getClientes();
    this.paginaAtual = 0;
    this.logForm = this.formBuilder.group({
      desde: [null, Validators.required],
      ate: [null, Validators.required],
      pessoaJuridicaId: [''],
      emailUsuario: ['', Validators.email],
      paginaAtual: [this.paginaAtual],
      itensPorPagina: [this.itensPorPagina],
    });
  }

  get f() { return this.logForm.controls; }

  getClientes() {
    this.clientes$ = this.clientesService.getClientesAsync();
  }

  onScrollDown() {
    if (this.dataInicio && this.dataFim) {
      if (this.paginaAtual === this.totalPages) {
        return;
      }
      this.pesquisarLogs();
    } else {
      return;
    }
  }

 submitPesquisarLogs() {
  this.dataInicio = this.logForm.value.desde;
  this.dataFim = this.logForm.value.ate;

  this.submitted = true;

  if (!this.dataInicio || !this.dataFim) {
    return;
  }
  this.logResults = [];
  this.paginaAtual = 0;
  this.totalPages = 0;
  this.totalItems = 0;
  this.itemsAtuais = 0;
  this.pesquisarLogs();
 }

  pesquisarLogs() {

    this.dataInicio = this.logForm.value.desde;
    this.dataFim = this.logForm.value.ate;

    this.subs.sink = this.auditoriaService.auditoriaGet(
      this.dataInicio,
      this.dataFim,
      this.logForm.value.pessoaJuridicaId,
      this.logForm.value.emailUsuario,
      this.paginaAtual,
      this.logForm.value.itensPorPagina,
      ).subscribe( logUsuarios => {
        this.logResults.push(...logUsuarios.logs);
        this.totalItems = logUsuarios.totalLogs;
        this.totalPages = logUsuarios.totalPaginas;

        this.totalItems > 0 ? this.loading = false : this.loading = true;

        if (this.paginaAtual != this.totalPages && this.totalItems > 0) {
          this.paginaAtual++;
          if (this.itensPorPagina < this.totalItems) {
            this.itemsAtuais += this.itensPorPagina;
            if (this.itemsAtuais > this.totalItems) {
              this.itemsAtuais = this.totalItems;
            }
          } else {
            this.itemsAtuais = this.totalItems;
          }
        } 

      });

  }

  getPrintLogs() {

    const datepipe: DatePipe = new DatePipe('pt-BR');

    this.setPrintDados = [];

    this.logResults.forEach(dados => {

      let diaHora = new Date(dados.quandoAcessou);
      let formattedDate = datepipe.transform(diaHora, 'd/M/yyyy HH:mm:ss')
      
      this.setPrintDados.push([ 
          dados.razaoSocial,
          dados.nomeUsuario,
          dados.quemAcessou,
          formattedDate,
          dados.tipoAcao,
          dados.acaoUsuario,
          dados.descricaoEntidade         
        ]) 
    });

    this.printWait = true; // desabilita o botão para evitar duplo click
   
    this.setPrintDados.length > 0 ? this.printExcel() : console.warn('Nenhum registro selecionado!');
    
  }
  
  private printExcel() {
    this.excelExportService.exportLogAsExcelFile(this.setPrintDados, 'Auditoria Acessos', this.headerDados);
    this.printWait = false;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}

