import { Observable, EMPTY, forkJoin } from 'rxjs';
import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { share, switchMap, take } from 'rxjs/operators';
import Driver from 'driver.js';
import { SubSink } from 'subsink';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { CargoApiService } from 'app/api/cargoApi.service';
import { ClienteApiService } from 'app/api/clienteApi.service';
import { RegisterService } from 'app/api/register.service';
import { FavoritosApiService } from 'app/api/favoritosApi.service';
import {
  LucorApiModelsNewNewListagemFavoritos,
  LucorApiModelsDetailsListagemFavoritos,
  LucorApiModelsPessoaJuridica,
  LucorApiModelsDetailsCargo } from 'app/model/models';
import { CookieService } from '@ngx-toolkit/cookie';
import { DataTableDirective } from 'angular-datatables';
import { LanguageDataTable } from 'app/shared/datatable/translate-datatable';

@Component({
  selector: 'app-selecionados',
  templateUrl: './selecionados.component.html',
  styleUrls: ['./selecionados.component.css']
})

export class SelecionadosComponent implements OnInit, OnDestroy {

  listagensSelecionados$: Observable<LucorApiModelsDetailsListagemFavoritos[]>;
  listagensSelecionadosAsync: LucorApiModelsDetailsListagemFavoritos[];
  clientes$: Observable<LucorApiModelsPessoaJuridica[]>;
  cargos$: Observable<LucorApiModelsDetailsCargo[]>;
  idCargo: number;
  newCargos = [];

  order = 'nome';
  criarNovaPasta = false;
  nomeNovaPasta: string;
  selecionadoActive = false;
  submitted = false;
  
  checkCookie = false;
  disableTour = false;
  driver = new Driver({
    className: 'tour-lucor',
    doneBtnText: 'Entendi', // Text on the final button
    closeBtnText: 'Fechar', // Text on the close button for this step
    nextBtnText: 'Próximo', // Next button text for this step
    prevBtnText: 'Anterior', // Previous button text for this step
  });

  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};

  private updateIdCliente: number;
  get idCliente(): number {
    return this.updateIdCliente;
  }
  @Input()
  set idCliente(idCliente: number) {
    this.updateIdCliente = idCliente;
    if (idCliente) {
      this.idCargo = null;
    }
  }

  private subs = new SubSink();

  constructor(
    private selecionadosService: FavoritosApiService,
    private cargosService: CargoApiService,
    private clientesService: ClienteApiService,
    private usuariosService: RegisterService,
    private alertService: AlertModalService,
    private cookieService: CookieService
    ) {}

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      processing: true,
      responsive: true,
      language: LanguageDataTable.portugues_datatables
    };
    this.goTop();
    this.subs.sink = this.usuariosService.getUsuarioLogadoAsync()
    .subscribe(
      usuario => {
        if (usuario?.pessoaJuridicaId !== null && typeof usuario?.pessoaJuridicaId !== 'undefined' ) {
          this.idCliente = usuario?.pessoaJuridicaId;
        } else {
          this.idCliente = null;
          this.getClientes();
        }
      }
    );
    this.getListagensSelecionados();
    this.checkCookie = this.cookieService.hasItem('tourSelecionados');
    if (this.checkCookie === false) {
      this.startTour();
      this.setCookie();
    }
  }

  startTour(): void {
    this.disableTour = true;
    // Define the steps for tour
    setTimeout(() => {
      this.driver.defineSteps([
        {
          element: '#one-element-tour',
          popover: {
            className: 'first-step-popover-class',
            title: 'Vamos fazer um tour?',
            description: 'Você pode usar as teclas <i class="fas fa-caret-left icon-tour"></i> e <i class="fas fa-caret-right icon-tour"></i> para avançar e voltar. Acesse aqui sempre que precisar.',
            position: 'left',
            closeBtnText: 'Eu vejo depois.',
            nextBtnText: 'Vamos lá!',
            prevBtnText: '<i class="fas fa-plane-departure"></i>'
          }
        },
        {
          element: '#two-element-tour',
          popover: {
            title: 'Botão "Criar Pasta"',
            description: 'Aqui é onde você irá criar as novas pastas de selecionados. Para criar uma pasta será necessário indicar um cargo para associação. Caso ele não exista, vá até a aba Cargos no menu para criar o novo cargo.',
            position: 'right'
          }
        },
        {
          element: '.dataTables_filter',
          popover: {
            title: 'Campo de pesquisa',
            description: 'Aqui você pode pesquisar pelo nome da pasta.',
            position: 'left'
          }
        },
        {
          element: '#four-element-tour',
          stageBackground: '#7a7a7a',
          popover: {
            title: 'Nome da Pasta',
            description: 'Este é o nome da pasta que você criou.',
            position: 'right'
          }
        },
        {
          element: '#five-element-tour',
          stageBackground: '#7a7a7a',
          popover: {
            title: 'Cargo',
            description: 'Este é o cargo que está associado a pasta.',
            position: 'left'
          }
        },
        {
          element: '#six-element-tour',
          stageBackground: '#7a7a7a',
          popover: {
            title: 'Selecionados',
            description: 'Indica o número de pessoas selecionadas na pasta.',
            position: 'left'
          }
        },
        {
          element: '#seven-element-tour',
          popover: {
            title: 'Botão "Ver Selecionados"',
            description: 'Clique para ver a lista de selecionados e a compatibilidade de cada um com o cargo associado.',
            position: 'left'
          }
        },
        {
          element: '#eight-element-tour',
          popover: {
            title: 'Botão Excluir',
            description: 'Aqui você exclui a pasta. Para excluir uma pasta é necessário remover os selecionados. Não há como reverter a exclusão, use com cuidado!',
            position: 'left'
          }
        },
      ]);
      // Start the introduction
      this.driver.start();
      this.disableTour = false;
    }, 2000);
  }

  setCookie() {
    this.cookieService.setItem( 'tourSelecionados', '1' );
  }

  getClientes() {
    this.idCargo = null;
    this.clientes$ = this.clientesService.getClientesAsync().pipe(share());
  }

  getListagensSelecionados() {
    this.listagensSelecionados$ = this.selecionadosService.getListagemAsync().pipe(share());
    this.listagensSelecionados$.forEach(listagensSelecionados => {
      this.listagensSelecionadosAsync = listagensSelecionados;
    });
  }

  getCargos() {
    const getCargos = [];
    const getListas = [];
    this.newCargos = [];
    this.cargos$ = this.cargosService.getCargosAsync().pipe(share());

    forkJoin([
    this.cargos$
    .forEach(cargos => {
      cargos.forEach(cargo => {
          getCargos.push({
            cargoId: cargo.id,
            tituloCargo: cargo.titulo,
            pessoaJuridicaId: cargo.pessoaJuridicaId,
            selected: false
          });
      });
    }),
    this.listagensSelecionadosAsync.map(listagemSelecionados => {
        getListas.push({
          cargoId: listagemSelecionados.cargoId,
          tituloCargo: listagemSelecionados.tituloCargo,
          pessoaJuridicaId: listagemSelecionados.pessoaJuridicaId,
          selected: true
        });
    })
  ]).subscribe(() => {
      getCargos.forEach(cargos => {
        if (getListas.filter(item => item.cargoId == cargos.cargoId).length == 0) {
          this.newCargos.push({
            cargoId: cargos.cargoId,
            tituloCargo: cargos.tituloCargo,
            pessoaJuridicaId: cargos.pessoaJuridicaId
          });
        }
      });
    });
  }

  criarPasta() {

    this.submitted = true;
    let novaPasta: LucorApiModelsNewNewListagemFavoritos;

    if (this.nomeNovaPasta && this.idCliente && this.idCargo) {
        novaPasta = ({nome: this.nomeNovaPasta, pessoaJuridicaId: this.idCliente, cargoId: this.idCargo});
        this.subs.sink = this.selecionadosService.addListagemAsync(novaPasta)
        .subscribe(success => {
          this.getListagensSelecionados();
          this.alertService.showAlertSuccess('Nova Pasta criada com sucesso.');
        }, error => {
          console.error(error);
          this.alertService.showAlertDanger('Não foi possível criar esta pasta! Tente novamente.');
        });
    } else {
      if (this.idCliente && this.idCargo) {
        this.alertService.showAlertWarning('Você precisa dar um nome para a pasta!', 4000);
      } else {
        this.alertService.showAlertWarning('Por favor, verifique se todos os campos estão preenchidos e tente novamente!', 4000);
      }
    }
  }

  onDelete(listagem: LucorApiModelsDetailsListagemFavoritos) {
    const result$ = this.alertService.showConfirm('Confirmação', 'Tem certeza que deseja excluir esta Pasta?', 'Excluir a Pasta');
    result$.asObservable()
    .pipe(
      take(1),
      switchMap(result => result ? this.selecionadosService.deleteListagemAsync(listagem.id) : EMPTY)
    )
    .subscribe(
      success => {
        this.alertService.showAlertSuccess('Pasta excluída com sucesso!');
        this.getListagensSelecionados();
      },
      error => {
        this.alertService.showAlertDanger('Erro ao excluir a Pasta. Tente novamente mais tarde.');
      }
    );
  }

  goTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
