/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.45
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MicrosoftExtensionsPrimitivesStringSegment } from './microsoftExtensionsPrimitivesStringSegment';

export interface IMicrosoftNetHttpHeadersEntityTagHeaderValue {
    tag?: MicrosoftExtensionsPrimitivesStringSegment | null;
    isWeak?: boolean | null;
}

export class MicrosoftNetHttpHeadersEntityTagHeaderValue implements IMicrosoftNetHttpHeadersEntityTagHeaderValue {

    constructor(data?: IMicrosoftNetHttpHeadersEntityTagHeaderValue) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) {
                    (this as any)[property] = (data as any)[property];
                }
            }
        }
    }
    readonly tag?: MicrosoftExtensionsPrimitivesStringSegment | null;
    readonly isWeak?: boolean | null;

    static fromJS(data: any): MicrosoftNetHttpHeadersEntityTagHeaderValue {
        data = typeof data === 'object' ? data : {};
        let result = new MicrosoftNetHttpHeadersEntityTagHeaderValue();
        result.init(data);
        return result;
    }

    init(data?: any) {
        if (data) {
            (this as any).tag = data["tag"] ? MicrosoftExtensionsPrimitivesStringSegment.fromJS(data["tag"]) : null as any;
            (this as any).isWeak = data["isWeak"] !== undefined ? data["isWeak"] : null as any;
        }
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["tag"] = this.tag ? this.tag.toJSON() : null as any;
        data["isWeak"] = this.isWeak !== undefined ? this.isWeak : null as any;
        return data;
    }
}


