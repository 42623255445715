/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.45
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { LucorApiModelsDetailsUser } from '../model/lucorApiModelsDetailsUser';
import { LucorApiModelsNewNewUser } from '../model/lucorApiModelsNewNewUser';
import { LucorApiModelsNewUpdUser } from '../model/lucorApiModelsNewUpdUser';
import { LucorApiModelsUpdUpdPassword } from '../model/lucorApiModelsUpdUpdPassword';
import { UpdResetPassword } from '../model/updResetPassword';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { environment } from 'environments/environment';


@Injectable()
export class RegisterService {

    protected basePath = environment.apiBasePath;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(
        protected httpClient: HttpClient,
        @Optional()@Inject(BASE_PATH) basePath: string,
        @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

     /**
     * Enviar E-mail de Esqueci Minha Senha
     * 
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * v1UsuariosEsqueciSenhaGet()
     */
      public esqueciSenha(email?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
      public esqueciSenha(email?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
      public esqueciSenha(email?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
      public esqueciSenha(email?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
  
          let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
          if (email !== undefined && email !== null) {
              queryParameters = queryParameters.set('email', email as any);
          }
  
          let headers = this.defaultHeaders;
  
          // to determine the Accept header
          let httpHeaderAccepts: string[] = [
              'text/plain',
              'application/json',
              'text/json'
          ];
          
          const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
          if (httpHeaderAcceptSelected != undefined) {
              headers = headers.set('Accept', httpHeaderAcceptSelected);
          }
  
          // to determine the Content-Type header
          const consumes: string[] = [
          ];
  
          return this.httpClient.request<string>('get',`${this.basePath}/Usuarios/EsqueciSenha`,
              {
                  params: queryParameters,
                  withCredentials: this.configuration.withCredentials,
                  headers: headers,
                  observe: observe,
                  reportProgress: reportProgress
              }
          );
      }

      /**
     * Resetar Senha
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * v1UsuariosResetarSenhaPut()
     */
    public resetarSenha(body?: UpdResetPassword, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public resetarSenha(body?: UpdResetPassword, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public resetarSenha(body?: UpdResetPassword, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public resetarSenha(body?: UpdResetPassword, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];

        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];

        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<string>('put',`${this.basePath}/Usuarios/ResetarSenha`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Alterar Senha
     *
     * @param userId
     * @param updPassword
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public atualizarSenhaAsync(userId: string, updPassword?: LucorApiModelsUpdUpdPassword, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public atualizarSenhaAsync(userId: string, updPassword?: LucorApiModelsUpdUpdPassword, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public atualizarSenhaAsync(userId: string, updPassword?: LucorApiModelsUpdUpdPassword, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public atualizarSenhaAsync(userId: string, updPassword?: LucorApiModelsUpdUpdPassword, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling atualizarSenhaAsync.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];

        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];

        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<string>(`${this.basePath}/Usuarios/${encodeURIComponent(String(userId))}/AlterarSenha`,
            updPassword,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Alterar Senha do Usuário Logado
     *
     * @param updPassword
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public atualizarSenhaUsuarioLogadoAsync(updPassword?: LucorApiModelsUpdUpdPassword, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public atualizarSenhaUsuarioLogadoAsync(updPassword?: LucorApiModelsUpdUpdPassword, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public atualizarSenhaUsuarioLogadoAsync(updPassword?: LucorApiModelsUpdUpdPassword, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public atualizarSenhaUsuarioLogadoAsync(updPassword?: LucorApiModelsUpdUpdPassword, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<string>(`${this.basePath}/Usuarios/Logado/AlterarSenha`,
            updPassword,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Atualizar Dados do Usuário
     *
     * @param userId
     * @param updUser
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public atualizarUsuarioAsync(userId: string, updUser?: LucorApiModelsNewUpdUser, observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsDetailsUser>;
    public atualizarUsuarioAsync(userId: string, updUser?: LucorApiModelsNewUpdUser, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsDetailsUser>>;
    public atualizarUsuarioAsync(userId: string, updUser?: LucorApiModelsNewUpdUser, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsDetailsUser>>;
    public atualizarUsuarioAsync(userId: string, updUser?: LucorApiModelsNewUpdUser, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling atualizarUsuarioAsync.');
        }


        let headers = this.defaultHeaders;


        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<LucorApiModelsDetailsUser>(`${this.basePath}/Usuarios/${encodeURIComponent(String(userId))}`,
            updUser,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Atualizar Dados do Usuário Logado
     *
     * @param updUser
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public atualizarUsuarioLogadoAsync(updUser?: LucorApiModelsNewUpdUser, observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsDetailsUser>;
    public atualizarUsuarioLogadoAsync(updUser?: LucorApiModelsNewUpdUser, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsDetailsUser>>;
    public atualizarUsuarioLogadoAsync(updUser?: LucorApiModelsNewUpdUser, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsDetailsUser>>;
    public atualizarUsuarioLogadoAsync(updUser?: LucorApiModelsNewUpdUser, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<LucorApiModelsDetailsUser>(`${this.basePath}/Usuarios/Logado`,
            updUser,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Excluir Usuário
     *
     * @param userId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public excluirUsuarioAsync(userId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public excluirUsuarioAsync(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public excluirUsuarioAsync(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public excluirUsuarioAsync(userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling excluirUsuarioAsync.');
        }

        let headers = this.defaultHeaders;


        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [];

        return this.httpClient.delete<any>(`${this.basePath}/Usuarios/${encodeURIComponent(String(userId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Obter Usuário pelo Id
     *
     * @param userId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsuarioByIdAsync(userId: string, observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsDetailsUser>;
    public getUsuarioByIdAsync(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsDetailsUser>>;
    public getUsuarioByIdAsync(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsDetailsUser>>;
    public getUsuarioByIdAsync(userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getUsuarioByIdAsync.');
        }

        let headers = this.defaultHeaders;


        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [];

        return this.httpClient.get<LucorApiModelsDetailsUser>(`${this.basePath}/Usuarios/${encodeURIComponent(String(userId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Obter Usuário Logado
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsuarioLogadoAsync(observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsDetailsUser>;
    public getUsuarioLogadoAsync(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsDetailsUser>>;
    public getUsuarioLogadoAsync(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsDetailsUser>>;
    public getUsuarioLogadoAsync(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [];

        return this.httpClient.get<LucorApiModelsDetailsUser>(`${this.basePath}/Usuarios/Logado`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Listar Usuários
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsuarios(observe?: 'body', reportProgress?: boolean): Observable<Array<LucorApiModelsDetailsUser>>;
    public getUsuarios(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LucorApiModelsDetailsUser>>>;
    public getUsuarios(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LucorApiModelsDetailsUser>>>;
    public getUsuarios(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [];

        return this.httpClient.get<Array<LucorApiModelsDetailsUser>>(`${this.basePath}/Usuarios`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Incluir Novo Usuário
     *
     * @param newUser
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public incluirUsuarioAsync(newUser?: LucorApiModelsNewNewUser, observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsDetailsUser>;
    public incluirUsuarioAsync(newUser?: LucorApiModelsNewNewUser, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsDetailsUser>>;
    public incluirUsuarioAsync(newUser?: LucorApiModelsNewNewUser, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsDetailsUser>>;
    public incluirUsuarioAsync(newUser?: LucorApiModelsNewNewUser, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LucorApiModelsDetailsUser>(`${this.basePath}/Usuarios`,
            newUser,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }


 /**
     * Bloquear conta
     * 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * 
     * v1UsuariosUserIdBloquearPut
     * 
     */
  public bloquearUsuario(userId: string, observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsDetailsUser>;
  public bloquearUsuario(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsDetailsUser>>;
  public bloquearUsuario(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsDetailsUser>>;
  public bloquearUsuario(userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

      if (userId === null || userId === undefined) {
          throw new Error('Required parameter userId was null or undefined when calling bloquearUsuario.');
      }

      let headers = this.defaultHeaders;

      // to determine the Accept header
      let httpHeaderAccepts: string[] = [
          'text/plain',
          'application/json',
          'text/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
      ];

      return this.httpClient.request<LucorApiModelsDetailsUser>('put',`${this.basePath}/Usuarios/${encodeURIComponent(String(userId))}/Bloquear`,
          {
              withCredentials: this.configuration.withCredentials,
              headers: headers,
              observe: observe,
              reportProgress: reportProgress
          }
      );
  }

   /**
    * Desbloquear conta
    * 
    * @param userId 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    * 
    * v1UsuariosUserIdDesbloquearPut
    * 
    */
    public desbloquearUsuario(userId: string, observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsDetailsUser>;
    public desbloquearUsuario(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsDetailsUser>>;
    public desbloquearUsuario(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsDetailsUser>>;
    public desbloquearUsuario(userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling desbloquearUsuario.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<LucorApiModelsDetailsUser>('put',`${this.basePath}/Usuarios/${encodeURIComponent(String(userId))}/Desbloquear`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


}

