import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { ClienteApiService } from 'app/api/clienteApi.service';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-adicionar-cliente',
  templateUrl: './adicionar-cliente.component.html',
  styleUrls: ['./adicionar-cliente.component.css']
})
export class AdicionarClienteComponent implements OnInit, OnDestroy {

  addForm: FormGroup;
  submitted = false;

  private subs = new SubSink();

  constructor(
    private clientesService: ClienteApiService,
    private router: Router,
    private location: Location,
    private formBuilder: FormBuilder,
    private alertService: AlertModalService
  ) { }

  ngOnInit() {

    this.addForm = this.formBuilder.group({
      razaoSocial: ['', Validators.required],
      cnpj: ['', Validators.required],
      emails: this.formBuilder.array([
        this.initEmails()
      ]),
      telefones: this.formBuilder.array([
        this.initTelefones()
      ]),
      enderecos: this.formBuilder.array([
        this.initEnderecos()
      ]),
    });
  }

  initEmails() {
    return this.formBuilder.group({
      endereco: ['', [Validators.required, Validators.email]],
      tipoContato:  ['', Validators.required]
    });
  }
  initTelefones() {
    return this.formBuilder.group({
      numero: ['', Validators.required],
      tipoTelefone: ['', Validators.required],
      tipoContato:  ['', Validators.required]
    });
  }
  initEnderecos() {
    return this.formBuilder.group({
      logradouro: ['', Validators.required],
      numero: ['', Validators.required],
      complemento: [''],
      bairro: ['', Validators.required],
      municipio: ['', Validators.required],
      estado: ['', [Validators.required, Validators.min(1)]],
      pais: ['', Validators.required],
      cep: ['', Validators.required]
    });
  }

  get f() { return this.addForm.controls; }

  get emails(): FormArray {
    return this.addForm.get('emails') as FormArray;
  } 

  get telefones(): FormArray {
    return this.addForm.get('telefones') as FormArray;
  } 

  get enderecos(): FormArray {
    return this.addForm.get('enderecos') as FormArray;
  } 

  saveCliente(): void {
    this.submitted = true;
    if (this.addForm.invalid) {
      return;
    }

    this.subs.sink = this.clientesService.addClienteAsync(this.addForm.value)
    .subscribe(success => {
      this.alertService.showAlertSuccess('Cliente salvo com sucesso!');
      setTimeout(() => {
        this.router.navigate(['clientes']);
      }, 2000);
    }, error => {
      console.error(error);
      this.alertService.showAlertDanger('Erro ao salvar cliente. | ' + error);
    });
  }

  goBack(): void {
    this.location.back();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
