/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.45
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface IMicrosoftExtensionsPrimitivesStringSegment {
    buffer?: string | null;
    offset?: number | null;
    length?: number | null;
    value?: string | null;
    hasValue?: boolean | null;
}

export class MicrosoftExtensionsPrimitivesStringSegment implements IMicrosoftExtensionsPrimitivesStringSegment {

    constructor(data?: IMicrosoftExtensionsPrimitivesStringSegment) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) {
                    (this as any)[property] = (data as any)[property];
                }
            }
        }
    }
    readonly buffer?: string | null;
    readonly offset?: number | null;
    readonly length?: number | null;
    readonly value?: string | null;
    readonly hasValue?: boolean | null;

    static fromJS(data: any): MicrosoftExtensionsPrimitivesStringSegment {
        data = typeof data === 'object' ? data : {};
        let result = new MicrosoftExtensionsPrimitivesStringSegment();
        result.init(data);
        return result;
    }

    init(data?: any) {
        if (data) {
            (this as any).buffer = data["buffer"] !== undefined ? data["buffer"] : null as any;
            (this as any).offset = data["offset"] !== undefined ? data["offset"] : null as any;
            (this as any).length = data["length"] !== undefined ? data["length"] : null as any;
            (this as any).value = data["value"] !== undefined ? data["value"] : null as any;
            (this as any).hasValue = data["hasValue"] !== undefined ? data["hasValue"] : null as any;
        }
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["buffer"] = this.buffer !== undefined ? this.buffer : null as any;
        data["offset"] = this.offset !== undefined ? this.offset : null as any;
        data["length"] = this.length !== undefined ? this.length : null as any;
        data["value"] = this.value !== undefined ? this.value : null as any;
        data["hasValue"] = this.hasValue !== undefined ? this.hasValue : null as any;
        return data;
    }
}


