import { AvaliacoesModule } from 'app/modules/avaliacoes/avaliacoes.module';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { OrderModule } from 'ngx-order-pipe';
import { MDBBootstrapModule, ChartsModule } from 'angular-bootstrap-md';
import { NgxFileDropModule } from 'ngx-file-drop';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { CargosComponent } from './components/cargos/cargos.component';
import { CargosRoutingModule } from './cargos.routing.module';
import { CargosDetalhesComponent } from './components/cargos-detalhes/cargos-detalhes.component';
import { CargosCadastrarComponent } from './components/cargos-cadastrar/cargos-cadastrar.component';
import { CargosAtualizarComponent } from './components/cargos-atualizar/cargos-atualizar.component';
import { CargosClonarComponent } from './components/cargos-clonar/cargos-clonar.component';
import { CargosSetFatoresComponent } from './components/cargos-set-fatores/cargos-set-fatores.component';
import { CargosSearchMotivadoresComponent } from './components/cargos-search-motivadores/cargos-search-motivadores.component';
import { CargosSearchComportamentoComponent } from './components/cargos-search-comportamento/cargos-search-comportamento.component';
import { CargosSearchAxiologiaComponent } from './components/cargos-search-axiologia/cargos-search-axiologia.component';
import { CargosFiltroComponent } from './components/cargos-filtro/cargos-filtro.component';
import { CargosDetalhesSelecionarComponent } from './components/cargos-detalhes-selecionar/cargos-detalhes-selecionar.component';
import { CargosTreinamentosComponent } from './components/cargos-treinamentos/cargos-treinamentos.component';
import { CargosFeedbackComponent } from './components/cargos-feedback/cargos-feedback.component';
import { CargosDetalhesExportPDFComponent } from './components/cargos-detalhes-export-pdf/cargos-detalhes-export-pdf.component';
import { CargosModelosComponent } from './components/cargos-modelos/cargos-modelos.component';

@NgModule({
  imports: [
    AvaliacoesModule,
    CargosRoutingModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    SharedModule,
    OrderModule,
    NgxFileDropModule,
    ChartsModule,
    MDBBootstrapModule.forRoot(),
    DragDropModule,
  ],
  declarations: [
    CargosComponent,
    CargosModelosComponent,
    CargosDetalhesComponent,
    CargosCadastrarComponent,
    CargosAtualizarComponent,
    CargosClonarComponent,
    CargosFiltroComponent,
    CargosSearchAxiologiaComponent,
    CargosSearchComportamentoComponent,
    CargosSearchMotivadoresComponent,
    CargosSetFatoresComponent,
    CargosDetalhesSelecionarComponent,
    CargosTreinamentosComponent,
    CargosFeedbackComponent,
    CargosDetalhesExportPDFComponent
  ],
  exports: [
    CargosFeedbackComponent
  ]
})

export class CargosModule {}
