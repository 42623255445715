import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LucorApiModelsCiencia } from 'app/model/lucorApiModelsCiencia';

@Component({
  selector: 'app-cargos-feedback',
  templateUrl: './cargos-feedback.component.html',
  styleUrls: ['./cargos-feedback.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CargosFeedbackComponent implements OnInit {

  @Input() ciencias$: Observable<LucorApiModelsCiencia[]>;
  @Input() avaliadosCargoComportamentoCalculo: Array<any>;
  @Input() avaliadosCargoMotivadoresCalculo: Array<any>;
  @Input() avaliadosCargoAxiologiaCalculo: Array<any>;
  @Input() balancoComportamento: Array<any>;
  @Input() cargoTitulo: string;
  firstName: string;

  private updatenewAvaliado;
  get newAvaliado() {
    return this.updatenewAvaliado;
  }
  @Input ()
  set newAvaliado(newAvaliado) {
    this.updatenewAvaliado = newAvaliado;
    if (newAvaliado) {
      this.firstName = this.updatenewAvaliado.nome.split(' ', 1);
    }
  }

  order = 'fatorId';
  orderModified = 'nomeFator';
  reverse = false;

  ngOnInit() { }

  setOrder(value: string) {
    if (this.order === value) { this.reverse = !this.reverse; }
    this.order = value;
  }
  setOrderModified(value: string) {
    if (this.orderModified === value) { this.reverse = !this.reverse; }
    this.orderModified = value;
  }

  customComparator(a: any, b: any) { return a > b ? 1 : -1; }

}
