export * from './auditoriaApi.service';
import { AuditoriaApiService } from './auditoriaApi.service';
export * from './avaliacaoApi.service';
import { AvaliacaoApiService } from './avaliacaoApi.service';
export * from './cargoApi.service';
import { CargoApiService } from './cargoApi.service';
export * from './cienciaApi.service';
import { CienciaApiService } from './cienciaApi.service';
export * from './clienteApi.service';
import { ClienteApiService } from './clienteApi.service';
export * from './favoritosApi.service';
import { FavoritosApiService } from './favoritosApi.service';
export * from './login.service';
import { LoginService } from './login.service';
export * from './mensagensApi.service';
import { MensagensApiService } from './mensagensApi.service';
export * from './register.service';
import { RegisterService } from './register.service';
export * from './servicoApi.service';
import { ServicoApiService } from './servicoApi.service';
export * from './tipoServicoApi.service';
import { TipoServicoApiService } from './tipoServicoApi.service';

export const APIS = [
    AuditoriaApiService,
    AvaliacaoApiService,
    CargoApiService,
    CienciaApiService,
    ClienteApiService,
    FavoritosApiService,
    LoginService,
    MensagensApiService,
    RegisterService,
    ServicoApiService,
    TipoServicoApiService
];
