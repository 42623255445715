import { Component, OnDestroy, OnInit } from '@angular/core';
import { EMPTY } from 'rxjs';
import { take, switchMap } from 'rxjs/operators';
import { TipoServicoApiService } from 'app/api/tipoServicoApi.service';
import { LucorDataModelsServicosTipoServico } from 'app/model/lucorDataModelsServicosTipoServico';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-tiposervico',
  templateUrl: './tiposServicos.component.html',
  styleUrls: ['./tiposServicos.component.css']
})
export class TiposServicosComponent implements OnInit, OnDestroy {

  tipoServico: LucorDataModelsServicosTipoServico[];

  private subs = new SubSink();

  constructor(
    private tiposServicosService: TipoServicoApiService,
    private alertService: AlertModalService) { }

  ngOnInit() {
    this.getTipoServico();
  }

  onDelete(servico: LucorDataModelsServicosTipoServico) {

    const result$ = this.alertService.showConfirm('Confirmação', 'Tem certeza que deseja excluir esse Tipo de Serviço?');
    result$.asObservable()
    .pipe(
      take(1),
      switchMap(result => result ? this.tiposServicosService.deleteTipoServicoAsync(servico.id) : EMPTY)
    )
    .subscribe(
      success => {
        this.alertService.showAlertSuccess('Tipo de Serviço excluído com sucesso!');
        // this.ngOnInit();
        this.getTipoServico();
      },
      error => {
        this.alertService.showAlertDanger('Erro ao excluir o Tipo de Serviço. Tente novamente mais tarde.');
      }
    );
  }

  private getTipoServico(): void {
    this.subs.sink = this.tiposServicosService.getTiposServicoAsync()
    .subscribe(
        tipoServico => {
        return this.tipoServico = tipoServico;
      });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
