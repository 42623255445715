import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { registerLocaleData, CurrencyPipe } from '@angular/common';
import { ApiModule } from '../api.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AppRoutingModule } from './app-routing.module';

import { AngularFileUploaderModule } from 'angular-file-uploader';
import { ChartsModule, PopoverModule } from 'angular-bootstrap-md';
import { CookieModule } from '@ngx-toolkit/cookie';
import { ModalModule } from 'ngx-bootstrap/modal';
import { OrderModule } from 'ngx-order-pipe';
import { PpBreadcrumbsModule } from 'pp-breadcrumbs';
import { SidebarModule } from 'ng-sidebar';
import { SidebarComponent } from '../sidebar/sidebar.component';

import { AlertModalComponent } from 'app/shared/alert-modal/alert-modal.component';
import { ConfirmModalComponent } from 'app/shared/confirm-modal/confirm-modal.component';
import { ExcelExportService } from 'app/shared/xlsx-download/excel-export.service';
import { HomeComponent } from 'app/modules/home/home.component';
import { AuditoriaLogsComponent } from 'app/modules/auditoria/components/auditoria-logs/auditoria-logs.component';
import { AvaliacoesModule } from 'app/modules/avaliacoes/avaliacoes.module';
import { CargosModule } from 'app/modules/cargos/cargos.module';
import { CienciasModule } from 'app/modules/ciencias/ciencias.module';
import { ClientesModule } from 'app/modules/clientes/clientes.module';
import { PageErrorComponent } from 'app/modules/page-error/page-error.component';
import { SelecionadosModule } from 'app/modules/selecionados/selecionados.module';
import { ServicosModule } from 'app/modules/servicos/servicos.module';
import { SharedModule } from 'app/shared/shared.module';
import { TiposServicosModule } from 'app/modules/tiposServicos/tipos-servicos.module';
import { UsuariosModule } from 'app/modules/usuarios/usuarios.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import ptBr from '@angular/common/locales/pt';

registerLocaleData(ptBr);

@NgModule({
  imports: [
    ApiModule,
    AuthModule,
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    ChartsModule,
    PopoverModule,
    AngularFileUploaderModule,
    OrderModule,
    PpBreadcrumbsModule,
    SidebarModule.forRoot(),
    ModalModule.forRoot(),
    CookieModule.forRoot(),
    AvaliacoesModule,
    CargosModule,
    CienciasModule,
    ClientesModule,
    SelecionadosModule,
    ServicosModule,
    TiposServicosModule,
    UsuariosModule,
    SharedModule,
    AppRoutingModule,
    InfiniteScrollModule,
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    AuditoriaLogsComponent,
    SidebarComponent,
    AlertModalComponent,
    ConfirmModalComponent,
    PageErrorComponent,
  ],
  exports: [
    AlertModalComponent
  ],
  providers: [
    CurrencyPipe,
    ExcelExportService,
    SharedModule,
    OrderModule,
    AvaliacoesModule,
    { provide: LOCALE_ID, useValue: 'pt' }
  ],
  entryComponents: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
