import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { RegisterService } from 'app/api/register.service';
import { AuthService } from '../../services/auth.service';
import { UserContextService } from '../../services/user-context.service';
import { AlertModalService } from './../../../../shared/alert-modal.service';
import { AppComponent } from './../../../app.component';
import { LucorApiModelsDetailsUser } from 'app/model/lucorApiModelsDetailsUser';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit, OnDestroy {

  systemVersion: string;
  year: number;
  loginForm: FormGroup;
  esqueciSenhaForm: FormGroup;
  submitted = false;
  submittedReset = false;
  send = false;
  sendError: string = null;

  verificaUsuario$: Observable<LucorApiModelsDetailsUser>;

  private btn: any;
  private input: any;

  private subs = new SubSink();

  constructor(
    private authService: AuthService,
    private userContext: UserContextService,
    private registerService: RegisterService,
    private formBuilder: FormBuilder,
    private router: Router,
    private appComponent: AppComponent,
    private alertService: AlertModalService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      userName: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(3)]]
    });

    this.esqueciSenhaForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });

    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/home']);
    }

    this.year = new Date().getFullYear();
    this.systemVersion = this.authService.sysVersion();
    this.btn = document.querySelector('.fa-eye');
    this.input = document.querySelector('#password');
  }

  get f() { return this.loginForm.controls; }
  get r() { return this.esqueciSenhaForm.controls; }

  login() {
    this.submitted = true;
    if (this.loginForm.invalid) { return; }
    this.subs.sink = this.authService.post({userName: this.f.userName.value, password: this.f.password.value})
    .subscribe(success => {
      if (success) {
        if (this.authService.checkTokenAccess) {
          this.getUsuarioLogado();
        } else {
          return false;
        }
      }
    });
  }

  viewPass() {
    this.btn.addEventListener('mouseenter', () => {
      if (this.input.getAttribute('type') === 'password') {
        this.input.setAttribute('type', 'text');
      }
    });
    this.btn.addEventListener('mouseleave', () => {
      this.input.setAttribute('type', 'password');
    });
  }

  esqueciSenha() {
    this.submittedReset = true;
    if (this.esqueciSenhaForm.invalid) { return; }

    let emailReset = this.esqueciSenhaForm.value.email;

    this.subs.sink = this.registerService.esqueciSenha(emailReset)
    .subscribe(success => {
      this.send = true;
      this.submittedReset = false;
    }, error => {
      this.sendError = error;
      this.submittedReset = false;
    })
  }

  resetFormEsqueci() {
    this.send = false;
    this.sendError = null;
    this.submittedReset = false;
    this.esqueciSenhaForm.setValue({
      email: null
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private getUsuarioLogado() {
    this.verificaUsuario$ = this.registerService.getUsuarioLogadoAsync().pipe(share());
    this.verificaUsuario$.forEach(usuarioLogado => {
      if (usuarioLogado) {
        localStorage.setItem('User', usuarioLogado.userName);
        usuarioLogado.pessoaJuridicaId === null ? localStorage.setItem('userRole', 'admin') : localStorage.setItem('userRole', usuarioLogado.pessoaJuridicaId.toString());
      } else {
        localStorage.setItem('User', null);
        localStorage.setItem('userRole', '0');
      }
      this.userContext.user = usuarioLogado;
      this.appComponent.ngOnInit();
    });
    this.router.navigate(['/home']);
  }

}

