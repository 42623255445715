import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SubSink } from 'subsink';
import { TipoServicoApiService } from 'app/api/tipoServicoApi.service';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { LucorApiModelsNewTipoServico } from 'app/model/lucorApiModelsNewTipoServico';

@Component({
  selector: 'app-tipos-servicos-cadastrar',
  templateUrl: './tipos-servicos-cadastrar.component.html',
  styleUrls: ['./tipos-servicos-cadastrar.component.css']
})
export class TiposServicosCadastrarComponent implements OnInit, OnDestroy {

  tipoServico: LucorApiModelsNewTipoServico[];
  addForm: FormGroup;
  submitted = false;

  private subs = new SubSink();

  constructor(
    private tiposServicosService: TipoServicoApiService,
    private alertService: AlertModalService,
    private formBuilder: FormBuilder,
    private router: Router,
    private location: Location) { }

  ngOnInit() {

    this.addForm = this.formBuilder.group({
      nome: ['' , Validators.required],
      codigo: ['' , Validators.required]
    });

  }

  get f() {
    return this.addForm.controls;
  }

  saveTipoServico(): void {
    
    this.submitted = true;
    if (this.f.codigo.status !== 'INVALID') {
      this.subs.sink = this.tiposServicosService.addTipoServicoAsync(this.addForm.value)
          .subscribe(success => {
            this.alertService.showAlertSuccess('Tipo de Serviço cadastrado com sucesso!');
            this.router.navigate(['tiposServicos']);
          }, error => {
            console.error(error);
            this.alertService.showAlertDanger(error);
          });
    }

  }

  goBack(): void {
    this.location.back();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
