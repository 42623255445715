/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.78
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { from, Observable }                                        from 'rxjs';
import { publishReplay, refCount, take } from 'rxjs/operators'; // ***

import { LucorApiModelsDetailsAvaliacaoCargo } from '../model/lucorApiModelsDetailsAvaliacaoCargo';
import { LucorApiModelsDetailsCargo } from '../model/lucorApiModelsDetailsCargo';
import { LucorApiModelsDetailsPessoaAderente } from '../model/lucorApiModelsDetailsPessoaAderente';
import { LucorApiModelsNewFiltroAderencia } from '../model/lucorApiModelsNewFiltroAderencia';
import { LucorApiModelsNewFiltroAderenciaCargos } from '../model/lucorApiModelsNewFiltroAderenciaCargos';
import { LucorApiModelsNewNewCargo } from '../model/lucorApiModelsNewNewCargo';
import { LucorApiModelsNewPerfil } from '../model/lucorApiModelsNewPerfil';
import { LucorApiModelsUpdUpdCargo } from '../model/lucorApiModelsUpdUpdCargo';
import { MicrosoftAspNetCoreMvcFileContentResult } from '../model/microsoftAspNetCoreMvcFileContentResult';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { environment } from 'environments/environment';


@Injectable()
export class CargoApiService {
 
    protected basePath = environment.apiBasePath;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    
    // ***Cache
    private cargoIdCache: number = null; 
    private aderencias$: Observable<LucorApiModelsDetailsPessoaAderente[] | HttpEvent<LucorApiModelsDetailsPessoaAderente[]>> = null;
    private perfisCargoAxiologia$: Observable<LucorApiModelsNewPerfil[] | HttpEvent<LucorApiModelsNewPerfil[]>> = null;
    private perfisCargoComportamento$: Observable<LucorApiModelsNewPerfil[] | HttpEvent<LucorApiModelsNewPerfil[]>> = null;
    private perfisCargoMotivadores$: Observable<LucorApiModelsNewPerfil[] | HttpEvent<LucorApiModelsNewPerfil[]>> = null;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    public clearCache() {
        this.aderencias$ = null;
        this.cargoIdCache = null;
        this.perfisCargoAxiologia$ = null;
        this.perfisCargoComportamento$ = null;
        this.perfisCargoMotivadores$ = null;
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    
    /**
     * Incluir Novo Cargo
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addCargoAsync(body?: LucorApiModelsNewNewCargo, observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsDetailsCargo>;
    public addCargoAsync(body?: LucorApiModelsNewNewCargo, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsDetailsCargo>>;
    public addCargoAsync(body?: LucorApiModelsNewNewCargo, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsDetailsCargo>>;
    public addCargoAsync(body?: LucorApiModelsNewNewCargo, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LucorApiModelsDetailsCargo>(`${this.basePath}/cargo`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Associar Avaliação ao Cargo
     *
     * @param cargoId
     * @param avaliacaoId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public associarAvaliacaoCargo(cargoId: number, avaliacaoId: number, observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsDetailsAvaliacaoCargo>;
    public associarAvaliacaoCargo(cargoId: number, avaliacaoId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsDetailsAvaliacaoCargo>>;
    public associarAvaliacaoCargo(cargoId: number, avaliacaoId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsDetailsAvaliacaoCargo>>;
    public associarAvaliacaoCargo(cargoId: number, avaliacaoId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        
        if (cargoId === null || cargoId === undefined) {
            throw new Error('Required parameter cargoId was null or undefined when calling associarAvaliacaoCargo.');
        }

        if (avaliacaoId === null || avaliacaoId === undefined) {
            throw new Error('Required parameter avaliacaoId was null or undefined when calling associarAvaliacaoCargo.');
        }

        this.clearCache();

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<LucorApiModelsDetailsAvaliacaoCargo>(`${this.basePath}/cargo/${encodeURIComponent(String(cargoId))}/avaliacoes/${encodeURIComponent(String(avaliacaoId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Atualizar Cargo
     *
     * @param cargoId
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public atualizarCargoAsync(cargoId: number, body?: LucorApiModelsUpdUpdCargo, observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsDetailsCargo>;
    public atualizarCargoAsync(cargoId: number, body?: LucorApiModelsUpdUpdCargo, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsDetailsCargo>>;
    public atualizarCargoAsync(cargoId: number, body?: LucorApiModelsUpdUpdCargo, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsDetailsCargo>>;
    public atualizarCargoAsync(cargoId: number, body?: LucorApiModelsUpdUpdCargo, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (cargoId === null || cargoId === undefined) {
            throw new Error('Required parameter cargoId was null or undefined when calling atualizarCargoAsync.');
        }

        this.clearCache();

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<LucorApiModelsDetailsCargo>(`${this.basePath}/cargo/${encodeURIComponent(String(cargoId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Excluir Cargo
     *
     * @param cargoId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCargoAsync(cargoId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteCargoAsync(cargoId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteCargoAsync(cargoId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteCargoAsync(cargoId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (cargoId === null || cargoId === undefined) {
            throw new Error('Required parameter cargoId was null or undefined when calling deleteCargoAsync.');
        }

        let headers = this.defaultHeaders;


        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/cargo/${encodeURIComponent(String(cargoId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Desassociar Avaliação do Cargo
     *
     * @param cargoId
     * @param avaliacaoId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public desassociarAvaliacaoCargo(cargoId: number, avaliacaoId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public desassociarAvaliacaoCargo(cargoId: number, avaliacaoId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public desassociarAvaliacaoCargo(cargoId: number, avaliacaoId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public desassociarAvaliacaoCargo(cargoId: number, avaliacaoId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (cargoId === null || cargoId === undefined) {
            throw new Error('Required parameter cargoId was null or undefined when calling desassociarAvaliacaoCargo.');
        }

        if (avaliacaoId === null || avaliacaoId === undefined) {
            throw new Error('Required parameter avaliacaoId was null or undefined when calling desassociarAvaliacaoCargo.');
        }

        this.clearCache();

        let headers = this.defaultHeaders;


        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/cargo/${encodeURIComponent(String(cargoId))}/avaliacoes/${encodeURIComponent(String(avaliacaoId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Incluir Novo Cargo com Base em Cargo Existente
     *
     * @param cargoId
     * @param novoTitulo
     * @param novaDescricao
     * @param novaArea
     * @param novoNivel
     * @param incluirAvaliacoesAssociadas
     * @param incluirPerfisAssociados
     * @param pessoaJuridicaId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public duplicarCargoAsync(
      cargoId: number,
      novoTitulo?: string,
      novaDescricao?: string,
      novaArea?: string,
      novoNivel?: string,
      incluirAvaliacoesAssociadas?: boolean,
      incluirPerfisAssociados?: boolean,
      pessoaJuridicaId?: number,
      observe?: 'body',
      reportProgress?: boolean): Observable<LucorApiModelsDetailsCargo>;
    public duplicarCargoAsync(
      cargoId: number,
      novoTitulo?: string,
      novaDescricao?: string,
      novaArea?: string, 
      novoNivel?: string,
      incluirAvaliacoesAssociadas?: boolean,
      incluirPerfisAssociados?: boolean,
      pessoaJuridicaId?: number,
      observe?: 'response',
      reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsDetailsCargo>>;
    public duplicarCargoAsync(
      cargoId: number,
      novoTitulo?: string,
      novaDescricao?: string,
      novaArea?: string, 
      novoNivel?: string,
      incluirAvaliacoesAssociadas?: boolean,
      incluirPerfisAssociados?: boolean,
      pessoaJuridicaId?: number,
      observe?: 'events',
      reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsDetailsCargo>>;
    public duplicarCargoAsync(
      cargoId: number,
      novoTitulo?: string,
      novaDescricao?: string,
      novaArea?: string,
      novoNivel?: string,
      incluirAvaliacoesAssociadas?: boolean,
      incluirPerfisAssociados?: boolean,
      pessoaJuridicaId?: number,
      observe: any = 'body',
      reportProgress: boolean = false ): Observable<any> {

        if (cargoId === null || cargoId === undefined) {
            throw new Error('Required parameter cargoId was null or undefined when calling duplicarCargoAsync.');
        }

        this.clearCache();

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (novoTitulo !== undefined && novoTitulo !== null) {
            queryParameters = queryParameters.set('novoTitulo', novoTitulo as any);
        }
        if (novaDescricao !== undefined && novaDescricao !== null) {
            queryParameters = queryParameters.set('novaDescricao', novaDescricao as any);
        }
        if (novaArea !== undefined && novaArea !== null) {
            queryParameters = queryParameters.set('novaArea', novaArea as any);
        }
        if (novoNivel !== undefined && novoNivel !== null) {
            queryParameters = queryParameters.set('novoNivel', novoNivel as any);
        }
        if (incluirAvaliacoesAssociadas !== undefined && incluirAvaliacoesAssociadas !== null) {
            queryParameters = queryParameters.set('incluirAvaliacoesAssociadas', incluirAvaliacoesAssociadas as any);
        }
        if (incluirPerfisAssociados !== undefined && incluirPerfisAssociados !== null) {
            queryParameters = queryParameters.set('incluirPerfisAssociados', incluirPerfisAssociados as any);
        }
        if (pessoaJuridicaId !== undefined && pessoaJuridicaId !== null) {
            queryParameters = queryParameters.set('pessoaJuridicaId', pessoaJuridicaId as any);
        }

        let headers = this.defaultHeaders;


        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<LucorApiModelsDetailsCargo>(`${this.basePath}/cargo/${encodeURIComponent(String(cargoId))}/duplicar`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }


    /**
     * Obter Planilha Excel dos Cargos Informados
     *
     * @param filtro
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gerarPlanilhaCargosAsync(
      filtro?: LucorApiModelsNewFiltroAderenciaCargos,
      observe?: 'body',
      reportProgress?: boolean): Observable<MicrosoftAspNetCoreMvcFileContentResult>;
    public gerarPlanilhaCargosAsync(
      filtro?: LucorApiModelsNewFiltroAderenciaCargos,
      observe?: 'response',
      reportProgress?: boolean): Observable<HttpResponse<MicrosoftAspNetCoreMvcFileContentResult>>;
    public gerarPlanilhaCargosAsync(
      filtro?: LucorApiModelsNewFiltroAderenciaCargos,
      observe?: 'events',
      reportProgress?: boolean): Observable<HttpEvent<MicrosoftAspNetCoreMvcFileContentResult>>;
    public gerarPlanilhaCargosAsync(
      filtro?: LucorApiModelsNewFiltroAderenciaCargos,
      observe: any = 'body',
      reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
          'application/octet-stream',
          'text/plain',
          'application/json',
          'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
          'application/octet-stream',
          'application/json-patch+json',
          'application/json',
          'text/json',
          'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<HttpResponse<MicrosoftAspNetCoreMvcFileContentResult>>(`${this.basePath}/cargo/obterplanilha`,
            filtro,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress,
                responseType: 'blob' as 'json'
            }
        );
    }

    /**
     * Listar Avaliações Associadas ao Cargo
     *
     * @param cargoId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAvaliacoesCargo(cargoId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<LucorApiModelsDetailsAvaliacaoCargo>>;
    public getAvaliacoesCargo(cargoId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LucorApiModelsDetailsAvaliacaoCargo>>>;
    public getAvaliacoesCargo(cargoId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LucorApiModelsDetailsAvaliacaoCargo>>>;
    public getAvaliacoesCargo(cargoId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (cargoId === null || cargoId === undefined) {
            throw new Error('Required parameter cargoId was null or undefined when calling getAvaliacoesCargo.');
        }

        let headers = this.defaultHeaders;


        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<LucorApiModelsDetailsAvaliacaoCargo>>(`${this.basePath}/cargo/${encodeURIComponent(String(cargoId))}/avaliacoes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Obter Cargo pelo Id
     *
     * @param cargoId
     * @param incluirExcluidos 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCargoByIdAsync(cargoId: number, incluirExcluidos?: boolean, observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsDetailsCargo>;
    public getCargoByIdAsync(cargoId: number, incluirExcluidos?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsDetailsCargo>>;
    public getCargoByIdAsync(cargoId: number, incluirExcluidos?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsDetailsCargo>>;
    public getCargoByIdAsync(cargoId: number, incluirExcluidos?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (cargoId === null || cargoId === undefined) {
            throw new Error('Required parameter cargoId was null or undefined when calling getCargoByIdAsync.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (incluirExcluidos !== undefined && incluirExcluidos !== null) {
            queryParameters = queryParameters.set('incluirExcluidos', incluirExcluidos as any);
        }

        let headers = this.defaultHeaders;


        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<LucorApiModelsDetailsCargo>(`${this.basePath}/cargo/${encodeURIComponent(String(cargoId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Listar Cargos
     *
     * @param withoutChildren
     * @param incluirExcluidos 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCargosAsync(withoutChildren?: boolean, incluirExcluidos?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<LucorApiModelsDetailsCargo>>;
    public getCargosAsync(withoutChildren?: boolean, incluirExcluidos?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LucorApiModelsDetailsCargo>>>;
    public getCargosAsync(withoutChildren?: boolean, incluirExcluidos?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LucorApiModelsDetailsCargo>>>;
    public getCargosAsync(withoutChildren?: boolean, incluirExcluidos?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (withoutChildren !== undefined && withoutChildren !== null) {
            queryParameters = queryParameters.set('withoutChildren', withoutChildren as any);
        }
        if (incluirExcluidos !== undefined && incluirExcluidos !== null) {
            queryParameters = queryParameters.set('incluirExcluidos', incluirExcluidos as any);
        }

        let headers = this.defaultHeaders;


        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<LucorApiModelsDetailsCargo>>(`${this.basePath}/cargo`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Listar Aderência dos Avaliados ao Cargo
     *
     * @param cargoId
     * @param filtro
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListarAderenciaAvaliadosAoCargo(cargoId: number, filtro?: LucorApiModelsNewFiltroAderencia, observe?: 'body', reportProgress?: boolean): Observable<Array<LucorApiModelsDetailsPessoaAderente>>;
    public getListarAderenciaAvaliadosAoCargo(cargoId: number, filtro?: LucorApiModelsNewFiltroAderencia, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LucorApiModelsDetailsPessoaAderente>>>;
    public getListarAderenciaAvaliadosAoCargo(cargoId: number, filtro?: LucorApiModelsNewFiltroAderencia, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LucorApiModelsDetailsPessoaAderente>>>;
    public getListarAderenciaAvaliadosAoCargo(cargoId: number, filtro?: LucorApiModelsNewFiltroAderencia, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (cargoId === null || cargoId === undefined) {
            throw new Error('Required parameter cargoId was null or undefined when calling getListarAderenciaAvaliadosAoCargo.');
        }

        if (!this.cargoIdCache || this.cargoIdCache !== cargoId) {
            this.cargoIdCache = cargoId;
            this.aderencias$ = null;
        }
        
        let headers = this.defaultHeaders;


        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (!this.aderencias$) {
            
            return this.aderencias$ = this.httpClient.put<Array<LucorApiModelsDetailsPessoaAderente>>(`${this.basePath}/cargo/${encodeURIComponent(String(cargoId))}/aderencia_avaliados`,
                filtro,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers,
                    observe,
                    reportProgress
                }
            ).pipe(publishReplay(1), refCount(), take(1));

        }

        return this.aderencias$;
    }

    /**
     * Listar Perfis Configurados para o Cargo
     *
     * @param cargoId
     * @param cienciaId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPerfisCargo(cargoId: number, cienciaId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<LucorApiModelsNewPerfil>>;
    public getPerfisCargo(cargoId: number, cienciaId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LucorApiModelsNewPerfil>>>;
    public getPerfisCargo(cargoId: number, cienciaId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LucorApiModelsNewPerfil>>>;
    public getPerfisCargo(cargoId: number, cienciaId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (cargoId === null || cargoId === undefined) {
            throw new Error('Required parameter cargoId was null or undefined when calling getPerfisCargo.');
        }

        if (cienciaId === null || cienciaId === undefined) {
            throw new Error('Required parameter cienciaId was null or undefined when calling getPerfisCargo.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (!this.cargoIdCache || this.cargoIdCache !== cargoId) {
            this.cargoIdCache = cargoId;
            this.aderencias$ = null;
            this.perfisCargoAxiologia$ = null;
            this.perfisCargoComportamento$ = null;
            this.perfisCargoMotivadores$ = null;
        }

        if (!this.perfisCargoAxiologia$ && cienciaId === 1) {
            
            return this.perfisCargoAxiologia$ = this.httpClient.get<Array<LucorApiModelsNewPerfil>>(`${this.basePath}/cargo/${encodeURIComponent(String(cargoId))}/ciencia/${encodeURIComponent(String(cienciaId))}/perfis`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(publishReplay(1), refCount(), take(1));

        } else if (this.perfisCargoAxiologia$ && cienciaId === 1) {
            return this.perfisCargoAxiologia$;
        }

        if (!this.perfisCargoComportamento$ && cienciaId === 2) {
            
            return this.perfisCargoComportamento$ = this.httpClient.get<Array<LucorApiModelsNewPerfil>>(`${this.basePath}/cargo/${encodeURIComponent(String(cargoId))}/ciencia/${encodeURIComponent(String(cienciaId))}/perfis`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(publishReplay(1), refCount(), take(1));

        } else if (this.perfisCargoComportamento$ && cienciaId === 2) {
            return this.perfisCargoComportamento$;
        }

        if (!this.perfisCargoMotivadores$ && cienciaId === 3) {
            
            return this.perfisCargoMotivadores$ = this.httpClient.get<Array<LucorApiModelsNewPerfil>>(`${this.basePath}/cargo/${encodeURIComponent(String(cargoId))}/ciencia/${encodeURIComponent(String(cienciaId))}/perfis`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(publishReplay(1), refCount(), take(1));

        } else if (this.perfisCargoMotivadores$ && cienciaId === 3) {
            return this.perfisCargoMotivadores$;
        }

    }

    /**
     * Atualizar Configuração de Perfis para o Cargo
     *
     * @param cargoId
     * @param cienciaId
     * @param perfis
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePerfisCargo(cargoId: number, cienciaId: number, perfis: Array<LucorApiModelsNewPerfil>, observe?: 'body', reportProgress?: boolean): Observable<Array<LucorApiModelsNewPerfil>>;
    public updatePerfisCargo(cargoId: number, cienciaId: number, perfis: Array<LucorApiModelsNewPerfil>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LucorApiModelsNewPerfil>>>;
    public updatePerfisCargo(cargoId: number, cienciaId: number, perfis: Array<LucorApiModelsNewPerfil>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LucorApiModelsNewPerfil>>>;
    public updatePerfisCargo(cargoId: number, cienciaId: number, perfis: Array<LucorApiModelsNewPerfil>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (cargoId === null || cargoId === undefined) {
            throw new Error('Required parameter cargoId was null or undefined when calling updatePerfisCargo.');
        }

        if (cienciaId === null || cienciaId === undefined) {
            throw new Error('Required parameter cienciaId was null or undefined when calling updatePerfisCargo.');
        }

        if (perfis === null || perfis === undefined) {
            throw new Error('Required parameter perfis was null or undefined when calling updatePerfisCargo.');
        }

        this.clearCache();

        let headers = this.defaultHeaders;


        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Array<LucorApiModelsNewPerfil>>(`${this.basePath}/cargo/${encodeURIComponent(String(cargoId))}/ciencia/${encodeURIComponent(String(cienciaId))}/perfis`,
            perfis,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

     /**
     * Restaurar Cargo Excluído
     * 
     * @param cargoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
      public restaurarCargoExcluido(cargoId: number, observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsDetailsCargo>;
      public restaurarCargoExcluido(cargoId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsDetailsCargo>>;
      public restaurarCargoExcluido(cargoId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsDetailsCargo>>;
      public restaurarCargoExcluido(cargoId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
  
          if (cargoId === null || cargoId === undefined) {
              throw new Error('Required parameter cargoId was null or undefined when calling restaurarCargoExcluido.');
          }

          this.clearCache();
  
          let headers = this.defaultHeaders;
  
          // to determine the Accept header
          let httpHeaderAccepts: string[] = [
          ];
          const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
          if (httpHeaderAcceptSelected != undefined) {
              headers = headers.set('Accept', httpHeaderAcceptSelected);
          }
  
          // to determine the Content-Type header
          const consumes: string[] = [
          ];
  
          return this.httpClient.put<LucorApiModelsDetailsCargo>(`${this.basePath}/cargo/${encodeURIComponent(String(cargoId))}/restaurar`,
              {
                  withCredentials: this.configuration.withCredentials,
                  headers: headers,
                  observe: observe,
                  reportProgress: reportProgress
              }
          );
      }


      /**
     * Listar Cargos do Cliente
     * 
     * @param pessoaJuridicaId 
     * @param withoutChildren 
     * @param incluirExcluidos 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * v1CargoClientePessoaJuridicaIdGet
     */
    public listarCargosByCliente(pessoaJuridicaId: number, withoutChildren?: boolean, incluirExcluidos?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<LucorApiModelsDetailsCargo>>;
    public listarCargosByCliente(pessoaJuridicaId: number, withoutChildren?: boolean, incluirExcluidos?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LucorApiModelsDetailsCargo>>>;
    public listarCargosByCliente(pessoaJuridicaId: number, withoutChildren?: boolean, incluirExcluidos?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LucorApiModelsDetailsCargo>>>;
    public listarCargosByCliente(pessoaJuridicaId: number, withoutChildren?: boolean, incluirExcluidos?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pessoaJuridicaId === null || pessoaJuridicaId === undefined) {
            throw new Error('Required parameter pessoaJuridicaId was null or undefined when calling v1CargoClientePessoaJuridicaIdGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (withoutChildren !== undefined && withoutChildren !== null) {
            queryParameters = queryParameters.set('withoutChildren', <any>withoutChildren);
        }
        if (incluirExcluidos !== undefined && incluirExcluidos !== null) {
            queryParameters = queryParameters.set('incluirExcluidos', <any>incluirExcluidos);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<LucorApiModelsDetailsCargo>>('get',`${this.basePath}/cargo/cliente/${encodeURIComponent(String(pessoaJuridicaId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
