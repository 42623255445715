import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { CienciasComponent } from './components/ciencias/ciencias.component';
import { DescricaoCadastrarComponent } from './components/descricao-cadastrar/descricao-cadastrar.component';
import { PalavrasChaveCadastrarComponent } from './components/palavras-chave-cadastrar/palavras-chave-cadastrar.component';
import { PalavrasChaveComponent } from './components/palavras-chave/palavras-chave.component';

const cienciasRoutes: Routes = [

    { path: 'ciencias',
        canActivate: [AuthGuard],
        data: { breadcrumbs: 'Ciências' },
        children: [
            { path: '', component: CienciasComponent, canActivate: [AuthGuard] },
            { path: ':Id/:Ci/fatores',
                data: { breadcrumbs: 'Fatores' },
                children: [
                    { path: '', component: PalavrasChaveComponent, canActivate: [AuthGuard]},
                    { path: 'adicionar-palavras', component: PalavrasChaveCadastrarComponent, canActivate: [AuthGuard],
                        data: { breadcrumbs: 'Adicionar Palavras-chave' }
                    },
                    { path: 'adicionar-descricao', component: DescricaoCadastrarComponent, canActivate: [AuthGuard],
                        data: { breadcrumbs: 'Adicionar descrição' },
                    },
                ],
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(cienciasRoutes)],
    exports: [RouterModule]
})

export class CienciasRoutingModule {}
