/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.51
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 *
 */
export interface LucorApiModelsDetailsAvaliacaoCargo {
    /**
     * Gets or Sets AvaliacaoId
     */
    avaliacaoId: number;
    /**
     * Gets or Sets nomeArquivo
     */
    nomeArquivo: string;
    /**
     * Gets or Sets PessoaJuridicaId
     */
    pessoaJuridicaId: number;
    /**
     * Gets or Sets Id
     */
    id?: number;
    /**
     * Gets or Sets CreatedAt
     */
    createdAt?: Date;
    /**
     * Gets or Sets UpdatedAt
     */
    updatedAt?: Date;
    /**
     * Gets or Sets ExcludedAt
     */
     excludedAt?: Date;
}
