import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { TiposServicosCadastrarComponent } from './components/tipos-servicos-cadastrar/tipos-servicos-cadastrar.component';
import { TiposServicosEditarComponent } from './components/tipos-servicos-editar/tipos-servicos-editar.component';
import { TiposServicosComponent } from './components/tiposServicos/tiposServicos.component';


const tiposServicosRoutes: Routes = [

    { path: 'tiposServicos', data: { breadcrumbs: 'Tipos de Serviços' },
        canActivate: [AuthGuard],
        children: [
            { path: '', component: TiposServicosComponent, canActivate: [AuthGuard] },
            { path: 'cadastrar', component: TiposServicosCadastrarComponent,
                data: { breadcrumbs: 'Cadastrar tipo de serviço' },
                canActivate: [AuthGuard]
            },
            { path: ':Id/editar', component: TiposServicosEditarComponent,
                data: { breadcrumbs: 'Editar tipo de serviço' },
                canActivate: [AuthGuard]
            }
        ]
    }

];

@NgModule({
    imports: [RouterModule.forChild(tiposServicosRoutes)],
    exports: [RouterModule]
})

export class TiposServicosRoutingModule {}
