import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { AvaliacaoApiService } from 'app/api/avaliacaoApi.service';
import { CargoApiService } from 'app/api/cargoApi.service';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { LucorApiModelsAvaliacao } from 'app/model/lucorApiModelsAvaliacao';
import { SubSink } from 'subsink';
import { Router } from '@angular/router';

@Component({
  selector: 'app-avaliacoes-atualizar',
  templateUrl: './avaliacoes-atualizar.component.html',
  styleUrls: ['./avaliacoes-atualizar.component.css']
})
export class AvaliacoesAtualizarComponent implements OnInit, OnDestroy {

  enviarEmailInconsistencia = false;
  uploading = false;
  successUpload = false;
  successCargos = false;
  searchCargos = false;
  searchAwait = false;
  cargosVinculados: Array<any> = new Array<any>();
  idCliente: number;
  @Input() idAvaliacao: number;
  @Input() avaliacaoByCliente: LucorApiModelsAvaliacao;
  @Output() mostraAtualizar = new EventEmitter();

  public files: NgxFileDropEntry[] = [];
  fileSend: File;
  fileDate: number;
  progressSend;

  private subs = new SubSink();

  constructor(
    private avaliacoesService: AvaliacaoApiService,
    private cargosService: CargoApiService,
    private alertService: AlertModalService) {}

  ngOnInit() {
    this.idCliente = this.avaliacaoByCliente.clienteId;
    this.goTop();
    this.getAvaliacaoById();
  }

  public dropped(files: NgxFileDropEntry[]) {

    this.files = files;
    for (const droppedFile of files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          // Here you can access the real file
          // console.log(droppedFile.relativePath, file);

          this.fileSend = file;
          this.fileDate = file.lastModified;
          // console.log('Progress', this.progressSend);

        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        // console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  public fileOver(event) {
    // console.log(event);
  }

  public fileLeave(event) {
    // console.log(event);
  }

  getCargos() {

    this.cargosVinculados = [];
    this.successCargos = true;
    this.searchCargos = true;
    this.searchAwait = true;

    if (this.idCliente) {

      this.subs.sink = this.cargosService.listarCargosByCliente(this.idCliente, true, false)
      .subscribe(cargos => {
        cargos.forEach(cargo => {
          this.subs.sink = this.cargosService.getAvaliacoesCargo(cargo.id)
          .subscribe(avaliacoes => {
            avaliacoes.forEach(avaliacao => {
              if (avaliacao.avaliacaoId === this.idAvaliacao) {
                this.cargosVinculados.push({
                  cargoNome: cargo.titulo,
                  cargoId: cargo.id,
                  avaliacaoNome: avaliacao.nomeArquivo,
                  avaliacaoId: avaliacao.avaliacaoId
                });
              };
            });  
  
            if (this.cargosVinculados.length === 0) {
              this.successCargos = false;
              this.searchAwait = false;
            } else {
              this.successCargos = true;
              this.searchAwait = false;
            };

          });
          
        });
      });

    } else {

      this.subs.sink = this.cargosService.getCargosAsync(true, false)
      .subscribe(cargos => {
        cargos.forEach(cargo => {
          this.subs.sink = this.cargosService.getAvaliacoesCargo(cargo.id)
          .subscribe(avaliacoes => {
            avaliacoes.forEach(avaliacao => {
              if (avaliacao.avaliacaoId === this.idAvaliacao) {
                this.cargosVinculados.push({
                  cargoNome: cargo.titulo,
                  cargoId: cargo.id,
                  avaliacaoNome: avaliacao.nomeArquivo,
                  avaliacaoId: avaliacao.avaliacaoId
                });
              };
            }) ;   
            if (this.cargosVinculados.length === 0) {
              this.successCargos = false;
            } else {
              this.successCargos = true;
            };

            this.searchAwait = false;
            
          });
        });
      });
    }

  }

  getAvaliacaoById() {
    return this.avaliacaoByCliente;
  }

  uploadPlanilha() {
    this.uploading = true;
    this.subs.sink = this.avaliacoesService.importarArquivoEAtualizarAvaliacao(this.idAvaliacao, this.enviarEmailInconsistencia, this.fileSend)
      .subscribe(upload => {

        if (upload.statusImportacao) {

          let checkUpload = setInterval(() => {

            this.subs.sink = this.avaliacoesService.obterImportacaoDaPlanilhaById(upload.id)
            .subscribe(importando => {

              switch (importando.statusImportacao) {

                case 'Processando':
                break;
                
                case 'Pendente':
                break;

                case 'Sucesso': 
                  this.uploading = false;
                  this.successUpload = true;
                  this.alertService.showAlertSuccess('Avaliação atualizada com sucesso!');
                  clearInterval(checkUpload);
                  setTimeout(() => {
                    this.goBack(true);
                  }, 1000);
                break;

                case 'Falha': 
                  this.alertService.showAlertDanger(`${"Erro ao enviar a planilha! " + importando.motivoFalha}`);
                  this.uploading = false;
                  clearInterval(checkUpload);
                break;

              }

            });
          }, 4000);

        };

      }, (err) => {
        console.error(err.error);
        this.uploading = false;
        this.alertService.showAlertDanger('Erro ao atualizar a Avaliação. Tente novamente mais tarde.');
      });
  }


  goBack(refresh?: boolean) {
    this.mostraAtualizar.emit(refresh);
    location.reload();
  }

  exitAtualizar() {
    this.mostraAtualizar.emit(true);
  }


  goTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
