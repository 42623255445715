/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.87
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type TipoAnexo = 'Curriculo' | 'RelatorioComportamental' | 'OutrosDocs';

export const TipoAnexo = {
    Curriculo: 'Curriculo' as TipoAnexo,
    RelatorioComportamental: 'RelatorioComportamental' as TipoAnexo,
    OutrosDocs: 'OutrosDocs' as TipoAnexo
};