import { Component, OnInit, Input, Renderer2, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { CargosSetFatores } from './cargos-set-fatores';
import { LucorApiModelsNewPerfil } from 'app/model/models';

@Component({
  selector: 'app-cargos-set-fatores',
  templateUrl: './cargos-set-fatores.component.html',
  styleUrls: ['./cargos-set-fatores.component.css'],
})
export class CargosSetFatoresComponent implements OnInit {

  @Input() fatores: CargosSetFatores;
  @Input() nomeCiencia: string;
  @Output() setReset = new EventEmitter();
  @Output() fatoresSelecionados = new EventEmitter();
  @Output() carregaPerfisSalvos = new EventEmitter();
  @Output() salvaFiltro = new EventEmitter();
  @ViewChild('div') div: ElementRef;

  perfilIdeal: string | number  = 'Ideal';
  perfilMinimo: string | number = 'Mínimo';
  perfilMaximo: string | number  = 'Máximo';
  conceitoDom: string;
  conceitoInf: string;
  conceitoEst: string;
  conceitoCaut: string;
  noCheck: boolean;

  private upReset: boolean;
  private upPerfisSalvos: LucorApiModelsNewPerfil[];

  constructor(private renderer: Renderer2) { }

  get reset(): boolean {
    return this.upReset;
  }
  @Input()
  set reset(reset: boolean) {
    this.upReset = reset;
    if (reset === true) {
      this.resetForm();
    } else {
      reset = false;
    }
  }

  get perfisSalvos(): LucorApiModelsNewPerfil[] {
    return this.upPerfisSalvos;
  }
  @Input()
  set perfisSalvos(perfisSalvos: LucorApiModelsNewPerfil[]) {
    this.upPerfisSalvos = perfisSalvos;
    if (perfisSalvos.length > 0) {
      this.getPerfisSalvos();
    }
  }

  ngOnInit() {

    if (this.fatores.nome === null) {
      this.addElement();
      this.noCheck = true;
      this.fatores.selected = true;
    } else { this.noCheck = false; }

  }

  getPerfisSalvos() {

    if (this.perfisSalvos) {

      this.perfisSalvos.forEach(perfil => {
        if (this.fatores.nome === perfil.fator) {
          this.fatores.nome = perfil.fator;
          this.fatores.minimo = perfil.minimo;
          this.fatores.ideal = perfil.ideal;
          this.fatores.maximo = perfil.maximo;
          this.fatores.selected = true;

          this.perfilIdeal = perfil.ideal;
          this.perfilMinimo = perfil.minimo;
          this.perfilMaximo = perfil.maximo;

          this.carregaPerfisSalvos.emit();

        }
      });

    }
  }

  addElement() {
    const E: HTMLParagraphElement = this.renderer.createElement('span');
    E.innerHTML = '<div style="color: #fff">Defina o Padrão para o Mínimo, Ideal e Máximo.</div>';
    this.renderer.appendChild(this.div.nativeElement, E);
  }

  onChangeFatores(event) {
    this.fatores.selected = event.target.checked;
    this.fatores.nome = event.target.value;
    this.fatoresSelecionados.emit();
    this.salvaFiltro.emit();
  }

  onChangeMin(event) {
    this.fatores.minimo = event;
    this.fatoresSelecionados.emit();
    this.salvaFiltro.emit();
  }

  onChangeIdeal(event) {
    this.fatores.ideal = event;
    this.fatoresSelecionados.emit();
    this.salvaFiltro.emit();
  }

  onChangeMax(event) {
    this.fatores.maximo = event;
    this.fatoresSelecionados.emit();
    this.salvaFiltro.emit();
  }

  resetForm(): void {
    this.fatores.selected = false;
    this.perfilIdeal = 'Ideal';
    this.perfilMinimo = 'Mínimo';
    this.perfilMaximo = 'Máximo';
    this.updateReset();
  }

  updateReset() {
    setTimeout(() => {
      this.setReset.emit(false);
    }, 1000);
  }

}
