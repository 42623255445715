import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ClienteApiService } from 'app/api/clienteApi.service';
import { RegisterService } from 'app/api/register.service';
import { LucorApiModelsPessoaJuridica } from 'app/model/lucorApiModelsPessoaJuridica';
import { LucorApiModelsDetailsUser } from 'app/model/lucorApiModelsDetailsUser';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-editar-cliente',
  templateUrl: './editar-cliente.component.html',
  styleUrls: ['./editar-cliente.component.css']
})
export class EditarClienteComponent implements OnInit, OnDestroy {

  usuarioLogado: LucorApiModelsDetailsUser;
  editarCliente: LucorApiModelsPessoaJuridica;
  cliente: LucorApiModelsPessoaJuridica;

  private subs = new SubSink();

  constructor(
    private usuarioLogadoService: RegisterService,
    private clientesService: ClienteApiService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private alertService: AlertModalService
  ) { }

  ngOnInit() {
    this.cliente = this.route.snapshot.data['clienteResolver'];
    this.getUsuarioLogado();
    this.getCliente();
  }

  getUsuarioLogado() {
    this.subs.sink = this.usuarioLogadoService.getUsuarioLogadoAsync()
    .subscribe(
      usuarioLogado => {
        this.usuarioLogado = usuarioLogado;
        if (this.usuarioLogado.pessoaJuridicaId) {
          this.router.navigate(['clientes/' + this.usuarioLogado.pessoaJuridicaId]);
        }
      }
    );
  }

  getCliente(): void {
    this.subs.sink = this.clientesService.getClienteById(this.cliente.id)
      .subscribe(editarCliente => this.editarCliente = editarCliente);
  }

  saveCliente(): void {
    this.subs.sink = this.clientesService.updateClienteAsync(this.cliente.id, this.editarCliente)
    .subscribe(success => {
      this.alertService.showAlertSuccess('Cliente salvo com sucesso!');
      setTimeout(() => { this.goBack(); }, 2000);
    }, error => {
      this.alertService.showAlertDanger('Erro ao salvar Cliente. Tente mais tarde.');
    });
  }

  goBack(): void {
    this.location.back();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
