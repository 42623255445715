import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filterSort'
})

// Para ordenar as colunas em tabelas
// Não está sendo usado ---

export class FilterSortPipe implements PipeTransform {

  transform(items: Array<any>, args?: any): any {

    return items.sort((a, b) => {
          if (a[args.propertyerty] < b[args.propertyerty]) {
            return -1 * args.direction;
          }
          else if ( a[args.propertyerty] > b[args.propertyerty]) {
            return 1 * args.direction;
          }
          else {
            return 0;
          }
        });
    }

}
