import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { FavoritosApiService } from './../../api/favoritosApi.service';
import { LucorApiModelsDetailsListagemFavoritos } from 'app/model/lucorApiModelsDetailsListagemFavoritos';


@Injectable({
  providedIn: 'root'
})
export class SelecionadosResolver implements Resolve<LucorApiModelsDetailsListagemFavoritos> {

  constructor(private selecionadosService: FavoritosApiService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LucorApiModelsDetailsListagemFavoritos> {
    if (route.params && route.params[`IdS`]) {
      return this.selecionadosService.getListagemByIdAsync(route.params[`IdS`]);
    }
  }

}
