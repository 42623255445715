import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { LucorApiModelsUpdFator } from 'app/model/lucorApiModelsUpdFator';
import { LucorApiModelsCiencia } from 'app/model/lucorApiModelsCiencia';
import { CienciaApiService } from 'app/api/cienciaApi.service';
import { LucorApiModelsFator } from 'app/model/lucorApiModelsFator';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-set-descricao',
  templateUrl: './set-descricao.component.html',
  styleUrls: ['./set-descricao.component.css']
})
export class SetDescricaoComponent implements OnInit, OnDestroy {

  ciencia: LucorApiModelsCiencia;
  filtroFator: LucorApiModelsUpdFator;
  fatorId: number;
  fatorNome: string;
  descricao: string;
  palavrasChave: string;
  @Input() idCiencia: number;
  @Input() fatores: LucorApiModelsFator;

  private subs = new SubSink();

  constructor(
    private cienciaService: CienciaApiService,
    private alertService: AlertModalService) { }

  ngOnInit() {
    this.fatorId = this.fatores.id;
    this.fatorNome = this.fatores.nome;
    this.descricao = this.fatores.descricao;
    this.palavrasChave = this.fatores.palavrasChave;
  }

  cadastrarFator(): void {

    if (this.fatorNome === '') {
      this.alertService.showAlertDanger('Ops! O nome do fator é obrigatório!');
      return;
    }

    if (this.descricao === '') { this.descricao = null; }

    if (this.palavrasChave === '') { this.palavrasChave = null; }

    this.filtroFator = { nome: this.fatorNome, descricao: this.descricao, palavrasChave: this.palavrasChave };

    this.subs.sink = this.cienciaService.updateFatorCiencia(this.idCiencia, this.fatorId, this.filtroFator)
    .subscribe(success => {
      this.alertService.showAlertSuccess('Fator salvo com sucesso!');
    }, error => {
      console.log(error.error);
      this.alertService.showAlertDanger('Erro ao salvar Descrição. Tente mais tarde.');
    });
    

  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }


}
