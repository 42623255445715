/**
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.110
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent } from '@angular/common/http';
    import { CustomHttpUrlEncodingCodec } from '../encoder';
    import { Observable } from 'rxjs';
    // import { publishReplay, refCount, take } from 'rxjs/operators'; // ***
    import { LucorApiModelsAvaliado } from '../model/lucorApiModelsAvaliado';
    import { LucorApiModelsAvaliacao } from '../model/lucorApiModelsAvaliacao';
    import { LucorApiModelsNewFiltroExportacao } from '../model/lucorApiModelsNewFiltroExportacao';
    import { LucorApiModelsNewPerfil } from '../model/lucorApiModelsNewPerfil';
    import { AnexoAvaliado } from '../model/anexoAvaliado';
    import { ResultadoAnexosAvaliadosLote } from '../model/resultadoAnexosAvaliadosLote';
    import { TipoAnexo } from '../model/tipoAnexo';
    import { ImportacaoPlanilha } from '../model/importacaoPlanilha';
    import { MicrosoftAspNetCoreMvcFileContentResult } from '../model/microsoftAspNetCoreMvcFileContentResult';
    import { CargoApiService } from './cargoApi.service';
    import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
    import { Configuration } from '../configuration';
    import { environment } from '../../environments/environment';
    
    
@Injectable()
export class AvaliacaoApiService {

    protected basePath = environment.apiBasePath;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    // avaliacoes$: Observable<LucorApiModelsAvaliacao[] | HttpEvent<LucorApiModelsAvaliacao[]>> = null; // ***Cache

    constructor(
        private cargosService: CargoApiService,
        protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    /*
    private clearCache() {
        this.avaliacoes$ = null; // Limpa a variável para excluir o Cache das avaliações. ###
    }
    */

    /**
     * Excluir Avaliação pelo Id
     *
     * @param avaliacaoId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAvaliacaoAsync(avaliacaoId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteAvaliacaoAsync(avaliacaoId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteAvaliacaoAsync(avaliacaoId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteAvaliacaoAsync(avaliacaoId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        if (avaliacaoId === null || avaliacaoId === undefined) {
            throw new Error('Required parameter avaliacaoId was null or undefined when calling deleteAvaliacaoAsync.');
        }

        // this.clearCache();

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [];

        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [];

        return this.httpClient.delete<any>(`${this.basePath}/avaliacao/${encodeURIComponent(String(avaliacaoId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }


    /**
     * Obter Avaliação pelo Id
     *
     * @param avaliacaoId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAvaliacaoByIdAsync(avaliacaoId: number, observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsAvaliacao>;
    public getAvaliacaoByIdAsync(avaliacaoId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsAvaliacao>>;
    public getAvaliacaoByIdAsync(avaliacaoId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsAvaliacao>>;
    public getAvaliacaoByIdAsync(avaliacaoId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        if (avaliacaoId === null || avaliacaoId === undefined) {
            throw new Error('Required parameter avaliacaoId was null or undefined when calling getAvaliacaoByIdAsync.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [];

        return this.httpClient.get<LucorApiModelsAvaliacao>(`${this.basePath}/avaliacao/${encodeURIComponent(String(avaliacaoId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            });

    }

    /**
     * Obter Avaliação pelo Id do Serviço
     *
     * @param servicoId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAvaliacaoByServicoAsync(servicoId: number, withoutChildren?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<LucorApiModelsAvaliacao>>;
    public getAvaliacaoByServicoAsync(servicoId: number, withoutChildren?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LucorApiModelsAvaliacao>>>;
    public getAvaliacaoByServicoAsync(servicoId: number, withoutChildren?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LucorApiModelsAvaliacao>>>;
    public getAvaliacaoByServicoAsync(servicoId: number, withoutChildren?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (servicoId === null || servicoId === undefined) {
            throw new Error('Required parameter servicoId was null or undefined when calling getAvaliacaoByServicoAsync.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (withoutChildren !== undefined && withoutChildren !== null) {
            queryParameters = queryParameters.set('withoutChildren', withoutChildren as any);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [];

        return this.httpClient.get<Array<LucorApiModelsAvaliacao>>(`${this.basePath}/avaliacao/servico/${encodeURIComponent(String(servicoId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Listar Avaliações do Cliente
     * 
     * @param pessoaJuridicaId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * // v1AvaliacaoClientePessoaJuridicaIdGet
     */
    public getAvaliacoesByCliente(pessoaJuridicaId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<LucorApiModelsAvaliacao>>;
    public getAvaliacoesByCliente(pessoaJuridicaId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LucorApiModelsAvaliacao>>>;
    public getAvaliacoesByCliente(pessoaJuridicaId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LucorApiModelsAvaliacao>>>;
    public getAvaliacoesByCliente(pessoaJuridicaId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pessoaJuridicaId === null || pessoaJuridicaId === undefined) {
            throw new Error('Required parameter pessoaJuridicaId was null or undefined when calling getAvaliacoesByCliente.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<LucorApiModelsAvaliacao>>('get',`${this.basePath}/avaliacao/cliente/${encodeURIComponent(String(pessoaJuridicaId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Listar Avaliações
     *
     * Ao atualizar é necessário fazer a implementação do .pipe(publishReplay(1), refCount()) na linha 227*****
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAvaliacoesAsync(observe?: 'body', reportProgress?: boolean): Observable<Array<LucorApiModelsAvaliacao>>;
    public getAvaliacoesAsync(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LucorApiModelsAvaliacao>>>;
    public getAvaliacoesAsync(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LucorApiModelsAvaliacao>>>;
    public getAvaliacoesAsync(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [];
        /*
        if (!this.avaliacoes$) {

            return this.avaliacoes$ = this.httpClient.get<Array<LucorApiModelsAvaliacao>>(`${this.basePath}/avaliacao`,
                    {
                        params: queryParameters,
                        withCredentials: this.configuration.withCredentials,
                        headers,
                        observe,
                        reportProgress
                    }
                ).pipe(publishReplay(1), refCount(), take(1));

        }

        return this.avaliacoes$;
        */
        return this.httpClient.get<Array<LucorApiModelsAvaliacao>>(`${this.basePath}/avaliacao`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers,
                        observe,
                        reportProgress
                    }
                );
    }

      /**
     * Listar Avaliados da Avaliação
     * 
     * @param avaliacaoId 
     * @param withoutChildren 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAvaliadosAvaliacaoAsync(avaliacaoId: number, withoutChildren?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<LucorApiModelsAvaliado>>;
    public getAvaliadosAvaliacaoAsync(avaliacaoId: number, withoutChildren?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LucorApiModelsAvaliado>>>;
    public getAvaliadosAvaliacaoAsync(avaliacaoId: number, withoutChildren?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LucorApiModelsAvaliado>>>;
    public getAvaliadosAvaliacaoAsync(avaliacaoId: number, withoutChildren?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (avaliacaoId === null || avaliacaoId === undefined) {
            throw new Error('Required parameter avaliacaoId was null or undefined when calling getAvaliadosAvaliacaoAsync.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (withoutChildren !== undefined && withoutChildren !== null) {
            queryParameters = queryParameters.set('withoutChildren', <any>withoutChildren);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<LucorApiModelsAvaliado>>(`${this.basePath}/avaliacao/${encodeURIComponent(String(avaliacaoId))}/avaliados`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Obter Planilha Excel da Avaliação pelo Id
     *
     * @param avaliacaoId
     * @param filtro
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */

   public getGerarPlanilhaAvaliacaoByIdAsync(
    avaliacaoId: number,
    filtro?: LucorApiModelsNewFiltroExportacao,
    observe?: 'body', reportProgress?: boolean): Observable<MicrosoftAspNetCoreMvcFileContentResult>;
    public getGerarPlanilhaAvaliacaoByIdAsync(
    avaliacaoId: number,
    filtro?: LucorApiModelsNewFiltroExportacao,
    observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MicrosoftAspNetCoreMvcFileContentResult>>;
    public getGerarPlanilhaAvaliacaoByIdAsync(
    avaliacaoId: number,
    filtro?: LucorApiModelsNewFiltroExportacao,
    observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MicrosoftAspNetCoreMvcFileContentResult>>;
    public getGerarPlanilhaAvaliacaoByIdAsync(
    avaliacaoId: number,
    filtro?: LucorApiModelsNewFiltroExportacao,
    observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (avaliacaoId === null || avaliacaoId === undefined) {
        throw new Error('Required parameter avaliacaoId was null or undefined when calling getGerarPlanilhaAvaliacaoByIdAsync.');
    }


    let headers = this.defaultHeaders;


    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
        'application/octet-stream',
        'text/plain',
        'application/json',
        'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
        'application/octet-stream',
        'application/json-patch+json',
        'application/json',
        'text/json',
        'application/_*+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<HttpResponse<MicrosoftAspNetCoreMvcFileContentResult>>(
        `${this.basePath}/avaliacao/${encodeURIComponent(String(avaliacaoId))}/obterplanilha`,
        filtro,
        {
            // body: filtro,
            withCredentials: this.configuration.withCredentials,
            headers,
            observe,
            reportProgress,
            responseType: 'blob' as 'json'
        }
    );
}


    /**
     * Listar Perfis da Ciência Configurados para a Avaliação
     *
     * @param avaliacaoId
     * @param cienciaId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPerfisAvaliacaoCiencia(avaliacaoId: number, cienciaId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<LucorApiModelsNewPerfil>>;
    public getPerfisAvaliacaoCiencia(avaliacaoId: number, cienciaId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LucorApiModelsNewPerfil>>>;
    public getPerfisAvaliacaoCiencia(avaliacaoId: number, cienciaId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LucorApiModelsNewPerfil>>>;
    public getPerfisAvaliacaoCiencia(avaliacaoId: number, cienciaId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (avaliacaoId === null || avaliacaoId === undefined) {
            throw new Error('Required parameter avaliacaoId was null or undefined when calling getPerfisAvaliacaoCiencia.');
        }

        if (cienciaId === null || cienciaId === undefined) {
            throw new Error('Required parameter cienciaId was null or undefined when calling getPerfisAvaliacaoCiencia.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [];

        return this.httpClient.get<Array<LucorApiModelsNewPerfil>>(`${this.basePath}/avaliacao/${encodeURIComponent(String(avaliacaoId))}/ciencia/${encodeURIComponent(String(cienciaId))}/perfis`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Atualizar Avaliação a Partir da Importação do Arquivo CSV
     *
     * @param avaliacaoId
     * @param enviarEmailInconsistencia
     * @param upfile
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importarArquivoEAtualizarAvaliacao(avaliacaoId: number, enviarEmailInconsistencia?: boolean, upfile?: Blob, observe?: 'body', reportProgress?: boolean): Observable<ImportacaoPlanilha>;
    public importarArquivoEAtualizarAvaliacao(avaliacaoId: number, enviarEmailInconsistencia?: boolean, upfile?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ImportacaoPlanilha>>;
    public importarArquivoEAtualizarAvaliacao(avaliacaoId: number, enviarEmailInconsistencia?: boolean, upfile?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ImportacaoPlanilha>>;
    public importarArquivoEAtualizarAvaliacao(avaliacaoId: number, enviarEmailInconsistencia?: boolean, upfile?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (avaliacaoId === null || avaliacaoId === undefined) {
            throw new Error('Required parameter avaliacaoId was null or undefined when calling importarArquivoEAtualizarAvaliacao.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (enviarEmailInconsistencia !== undefined && enviarEmailInconsistencia !== null) {
            queryParameters = queryParameters.set('enviarEmailInconsistencia', <any>enviarEmailInconsistencia);
        }

        this.cargosService.clearCache();
        // this.clearCache();

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        // let formParams: { append(param: string, value: any): void; };
        let formParams: { append(param: string, value: any): void | HttpParams; }; // Linha corrigida ###
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (upfile !== undefined) {
            formParams = formParams.append('upfile', upfile as any) || formParams;
        }

        return this.httpClient.put<ImportacaoPlanilha>(`${this.basePath}/avaliacao/${encodeURIComponent(String(avaliacaoId))}/importararquivo`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                // body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }


    /**
     * Listar Importações de Planilhas
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * v1AvaliacaoImportacaoGet
     */
     public listarImportacoesDePlanilhas(observe?: 'body', reportProgress?: boolean): Observable<Array<ImportacaoPlanilha>>;
     public listarImportacoesDePlanilhas(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ImportacaoPlanilha>>>;
     public listarImportacoesDePlanilhas(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ImportacaoPlanilha>>>;
     public listarImportacoesDePlanilhas(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<Array<ImportacaoPlanilha>>('get',`${this.basePath}/avaliacao/importacao`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * Obter Importação da Planilha pelo Id
      * 
      * @param importacaoId 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      * v1AvaliacaoImportacaoImportacaoIdGet
      */
     public obterImportacaoDaPlanilhaById(importacaoId: number, observe?: 'body', reportProgress?: boolean): Observable<ImportacaoPlanilha>;
     public obterImportacaoDaPlanilhaById(importacaoId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ImportacaoPlanilha>>;
     public obterImportacaoDaPlanilhaById(importacaoId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ImportacaoPlanilha>>;
     public obterImportacaoDaPlanilhaById(importacaoId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (importacaoId === null || importacaoId === undefined) {
             throw new Error('Required parameter importacaoId was null or undefined when calling v1AvaliacaoImportacaoImportacaoIdGet.');
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<ImportacaoPlanilha>('get',`${this.basePath}/avaliacao/importacao/${encodeURIComponent(String(importacaoId))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 

    /**
     * Incluir Nova Avaliação a Partir da Importação do Arquivo CSV
     *
     * @param servicoId
     * @param enviarEmailInconsistencia
     * @param upfile
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importarArquivoEIncluirAvaliacao(servicoId: number, enviarEmailInconsistencia?: boolean, upfile?: Blob, observe?: 'body', reportProgress?: boolean): Observable<ImportacaoPlanilha>;
    public importarArquivoEIncluirAvaliacao(servicoId: number, enviarEmailInconsistencia?: boolean, upfile?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ImportacaoPlanilha>>;
    public importarArquivoEIncluirAvaliacao(servicoId: number, enviarEmailInconsistencia?: boolean, upfile?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ImportacaoPlanilha>>;
    public importarArquivoEIncluirAvaliacao(servicoId: number, enviarEmailInconsistencia?: boolean, upfile?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (servicoId === null || servicoId === undefined) {
            throw new Error('Required parameter servicoId was null or undefined when calling importarArquivoEIncluirAvaliacao.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (enviarEmailInconsistencia !== undefined && enviarEmailInconsistencia !== null) {
            queryParameters = queryParameters.set('enviarEmailInconsistencia', enviarEmailInconsistencia as any);
        }

        // this.clearCache(); // ###

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        // let formParams: { append(param: string, value: any): void; };
        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (upfile !== undefined) {
            formParams = formParams.append('upfile', upfile as any) || formParams;
        }

        return this.httpClient.post<ImportacaoPlanilha>(`${this.basePath}/avaliacao/servico/${encodeURIComponent(String(servicoId))}/importararquivo`,
            convertFormParamsToString ? formParams.toString() : formParams, 
            {
                // body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Atualizar Configuração de Perfis da Ciência para Avaliação
     *
     * @param avaliacaoId
     * @param cienciaId
     * @param perfis
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePerfisCienciaAvaliacao(avaliacaoId: number, cienciaId: number, perfis: Array<LucorApiModelsNewPerfil>, observe?: 'body', reportProgress?: boolean): Observable<Array<LucorApiModelsNewPerfil>>;
    public updatePerfisCienciaAvaliacao(avaliacaoId: number, cienciaId: number, perfis: Array<LucorApiModelsNewPerfil>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LucorApiModelsNewPerfil>>>;
    public updatePerfisCienciaAvaliacao(avaliacaoId: number, cienciaId: number, perfis: Array<LucorApiModelsNewPerfil>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LucorApiModelsNewPerfil>>>;
    public updatePerfisCienciaAvaliacao(avaliacaoId: number, cienciaId: number, perfis: Array<LucorApiModelsNewPerfil>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (avaliacaoId === null || avaliacaoId === undefined) {
            throw new Error('Required parameter avaliacaoId was null or undefined when calling updatePerfisCienciaAvaliacao.');
        }

        if (cienciaId === null || cienciaId === undefined) {
            throw new Error('Required parameter cienciaId was null or undefined when calling updatePerfisCienciaAvaliacao.');
        }

        if (perfis === null || perfis === undefined) {
            throw new Error('Required parameter perfis was null or undefined when calling updatePerfisCienciaAvaliacao.');
        }

        // this.clearCache();

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Array<LucorApiModelsNewPerfil>>(`${this.basePath}/avaliacao/${encodeURIComponent(String(avaliacaoId))}/ciencia/${encodeURIComponent(String(cienciaId))}/perfis`,
            perfis,
            {
                // body: perfis,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }


    /**
     * Incluir Anexos dos Avaliados em Lote
     * 
     * @param avaliacaoId 
     * @param upfiles 
     * @param sobrescreverExistentes 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * // v1AvaliacaoAvaliacaoIdAvaliadosAnexosPostForm()
     */
     public incluirAnexosAvaliadosLote(avaliacaoId: number, upfiles?: Array<Blob>, sobrescreverExistentes?: boolean, observe?: 'body', reportProgress?: boolean): Observable<ResultadoAnexosAvaliadosLote>;
     public incluirAnexosAvaliadosLote(avaliacaoId: number, upfiles?: Array<Blob>, sobrescreverExistentes?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResultadoAnexosAvaliadosLote>>;
     public incluirAnexosAvaliadosLote(avaliacaoId: number, upfiles?: Array<Blob>, sobrescreverExistentes?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResultadoAnexosAvaliadosLote>>;
     public incluirAnexosAvaliadosLote(avaliacaoId: number, upfiles?: Array<Blob>, sobrescreverExistentes?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (avaliacaoId === null || avaliacaoId === undefined) {
             throw new Error('Required parameter avaliacaoId was null or undefined when calling v1AvaliacaoAvaliacaoIdAvaliadosAnexosPost.');
         }
 
 
         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (sobrescreverExistentes !== undefined && sobrescreverExistentes !== null) {
             queryParameters = queryParameters.set('sobrescreverExistentes', <any>sobrescreverExistentes);
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
             headers.append("Content-Type", ""); // Adicionado para corrigir bug do Angular
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'multipart/form-data'
         ];
 
         const canConsumeForm = this.canConsumeForm(consumes);
 
         // let formParams: { append(param: string, value: any): void; };
         let formParams: { append(param: string, value: any): void | HttpParams; };
         let useForm = false;
         let convertFormParamsToString = false;
         
         useForm = canConsumeForm; // Adicionado para corrigir bug do Angular

         if (useForm) {
             formParams = new FormData();
         } else {
             formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         }
         
         if (upfiles) {
             upfiles.forEach((element) => {
                 // formParams = formParams.append('upfiles', <any>element) as any || formParams;
                 formParams = formParams.append('upfiles', element as any) || formParams;
             })

         }

         return this.httpClient.post<ResultadoAnexosAvaliadosLote>(`${this.basePath}/avaliacao/${encodeURIComponent(String(avaliacaoId))}/avaliados/anexos`,   
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                 // body: convertFormParamsToString ? formParams.toString() : formParams,
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * Remover Anexo do Avaliado
      * 
      * @param avaliacaoId 
      * @param avaliadoId 
      * @param anexoId 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      * // v1AvaliacaoAvaliacaoIdAvaliadosAvaliadoIdAnexosAnexoIdDelete()
      */
     public removerAnexoAvaliado(avaliacaoId: number, avaliadoId: number, anexoId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public removerAnexoAvaliado(avaliacaoId: number, avaliadoId: number, anexoId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public removerAnexoAvaliado(avaliacaoId: number, avaliadoId: number, anexoId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public removerAnexoAvaliado(avaliacaoId: number, avaliadoId: number, anexoId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (avaliacaoId === null || avaliacaoId === undefined) {
             throw new Error('Required parameter avaliacaoId was null or undefined when calling v1AvaliacaoAvaliacaoIdAvaliadosAvaliadoIdAnexosAnexoIdDelete.');
         }
 
         if (avaliadoId === null || avaliadoId === undefined) {
             throw new Error('Required parameter avaliadoId was null or undefined when calling v1AvaliacaoAvaliacaoIdAvaliadosAvaliadoIdAnexosAnexoIdDelete.');
         }
 
         if (anexoId === null || anexoId === undefined) {
             throw new Error('Required parameter anexoId was null or undefined when calling v1AvaliacaoAvaliacaoIdAvaliadosAvaliadoIdAnexosAnexoIdDelete.');
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<any>('delete',`${this.basePath}/avaliacao/${encodeURIComponent(String(avaliacaoId))}/avaliados/${encodeURIComponent(String(avaliadoId))}/anexos/${encodeURIComponent(String(anexoId))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * Detalhes do Anexo do Avaliado
      * 
      * @param avaliacaoId 
      * @param avaliadoId 
      * @param anexoId 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      * // v1AvaliacaoAvaliacaoIdAvaliadosAvaliadoIdAnexosAnexoIdGet()
      */
     public detalhesAnexoAvaliado(avaliacaoId: number, avaliadoId: number, anexoId: number, observe?: 'body', reportProgress?: boolean): Observable<AnexoAvaliado>;
     public detalhesAnexoAvaliado(avaliacaoId: number, avaliadoId: number, anexoId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AnexoAvaliado>>;
     public detalhesAnexoAvaliado(avaliacaoId: number, avaliadoId: number, anexoId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AnexoAvaliado>>;
     public detalhesAnexoAvaliado(avaliacaoId: number, avaliadoId: number, anexoId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (avaliacaoId === null || avaliacaoId === undefined) {
             throw new Error('Required parameter avaliacaoId was null or undefined when calling v1AvaliacaoAvaliacaoIdAvaliadosAvaliadoIdAnexosAnexoIdGet.');
         }
 
         if (avaliadoId === null || avaliadoId === undefined) {
             throw new Error('Required parameter avaliadoId was null or undefined when calling v1AvaliacaoAvaliacaoIdAvaliadosAvaliadoIdAnexosAnexoIdGet.');
         }
 
         if (anexoId === null || anexoId === undefined) {
             throw new Error('Required parameter anexoId was null or undefined when calling v1AvaliacaoAvaliacaoIdAvaliadosAvaliadoIdAnexosAnexoIdGet.');
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<AnexoAvaliado>('get',`${this.basePath}/avaliacao/${encodeURIComponent(String(avaliacaoId))}/avaliados/${encodeURIComponent(String(avaliadoId))}/anexos/${encodeURIComponent(String(anexoId))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * Listar Anexos do Avaliado
      * 
      * @param avaliacaoId 
      * @param avaliadoId 
      * @param tipoAnexo 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      * // v1AvaliacaoAvaliacaoIdAvaliadosAvaliadoIdAnexosGet()
      */
     public listarAnexosAvaliado(avaliacaoId: number, avaliadoId: number, tipoAnexo?: TipoAnexo, observe?: 'body', reportProgress?: boolean): Observable<Array<AnexoAvaliado>>;
     public listarAnexosAvaliado(avaliacaoId: number, avaliadoId: number, tipoAnexo?: TipoAnexo, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AnexoAvaliado>>>;
     public listarAnexosAvaliado(avaliacaoId: number, avaliadoId: number, tipoAnexo?: TipoAnexo, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AnexoAvaliado>>>;
     public listarAnexosAvaliado(avaliacaoId: number, avaliadoId: number, tipoAnexo?: TipoAnexo, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (avaliacaoId === null || avaliacaoId === undefined) {
             throw new Error('Required parameter avaliacaoId was null or undefined when calling v1AvaliacaoAvaliacaoIdAvaliadosAvaliadoIdAnexosGet.');
         }
 
         if (avaliadoId === null || avaliadoId === undefined) {
             throw new Error('Required parameter avaliadoId was null or undefined when calling v1AvaliacaoAvaliacaoIdAvaliadosAvaliadoIdAnexosGet.');
         }
 
 
         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (tipoAnexo !== undefined && tipoAnexo !== null) {
             queryParameters = queryParameters.set('tipoAnexo', <any>tipoAnexo);
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<Array<AnexoAvaliado>>('get',`${this.basePath}/avaliacao/${encodeURIComponent(String(avaliacaoId))}/avaliados/${encodeURIComponent(String(avaliadoId))}/anexos`,
             {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * Incluir Novo Anexo do Avaliado
      * 
      * @param tipoAnexo 
      * @param avaliacaoId 
      * @param avaliadoId 
      * @param upfile 
      * @param sobrescreverExistente 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      * // v1AvaliacaoAvaliacaoIdAvaliadosAvaliadoIdAnexosPostForm()
      */
     public incluirNovoAnexoAvaliado(tipoAnexo: TipoAnexo, avaliacaoId: number, avaliadoId: number, upfile?: Blob, sobrescreverExistente?: boolean, observe?: 'body', reportProgress?: boolean): Observable<AnexoAvaliado>;
     public incluirNovoAnexoAvaliado(tipoAnexo: TipoAnexo, avaliacaoId: number, avaliadoId: number, upfile?: Blob, sobrescreverExistente?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AnexoAvaliado>>;
     public incluirNovoAnexoAvaliado(tipoAnexo: TipoAnexo, avaliacaoId: number, avaliadoId: number, upfile?: Blob, sobrescreverExistente?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AnexoAvaliado>>;
     public incluirNovoAnexoAvaliado(tipoAnexo: TipoAnexo, avaliacaoId: number, avaliadoId: number, upfile?: Blob, sobrescreverExistente?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (tipoAnexo === null || tipoAnexo === undefined) {
             throw new Error('Required parameter tipoAnexo was null or undefined when calling v1AvaliacaoAvaliacaoIdAvaliadosAvaliadoIdAnexosPost.');
         }
 
         if (avaliacaoId === null || avaliacaoId === undefined) {
             throw new Error('Required parameter avaliacaoId was null or undefined when calling v1AvaliacaoAvaliacaoIdAvaliadosAvaliadoIdAnexosPost.');
         }
 
         if (avaliadoId === null || avaliadoId === undefined) {
             throw new Error('Required parameter avaliadoId was null or undefined when calling v1AvaliacaoAvaliacaoIdAvaliadosAvaliadoIdAnexosPost.');
         }
 
         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (tipoAnexo !== undefined && tipoAnexo !== null) {
             queryParameters = queryParameters.set('tipoAnexo', tipoAnexo as any);
         }
         if (sobrescreverExistente !== undefined && sobrescreverExistente !== null) {
             queryParameters = queryParameters.set('sobrescreverExistente', sobrescreverExistente as any);
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'multipart/form-data'
         ];
 
         const canConsumeForm = this.canConsumeForm(consumes);
 
         // let formParams: { append(param: string, value: any): void; };
         let formParams: { append(param: string, value: any): void | HttpParams; };
         let useForm = false;
         let convertFormParamsToString = false;
         // use FormData to transmit files using content-type "multipart/form-data"
         // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
         useForm = canConsumeForm;
         if (useForm) {
             formParams = new FormData();
         } else {
             formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         }
 
         if (upfile !== undefined) {
             //formParams = formParams.append('upfile', <any>upfile) as any || formParams;
             formParams = formParams.append('upfile', upfile as any) || formParams;
         }

         return this.httpClient.request<AnexoAvaliado>('post',`${this.basePath}/avaliacao/${encodeURIComponent(String(avaliacaoId))}/avaliados/${encodeURIComponent(String(avaliadoId))}/anexos`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
         );
     }
 

}
