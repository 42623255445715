import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filterTable'
})
export class FilterTablePipe implements PipeTransform {

  transform(items: any[], newSearchText: string): any[] {
    if (!items) { return []; }
    if (!newSearchText) { return items; }

    newSearchText = newSearchText.toLowerCase();
    return items.filter(it => {
      return it.nomeArquivoOrigem.toLowerCase().includes(newSearchText);
    });
  }

}
