import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Observable, forkJoin, EMPTY, of} from 'rxjs';
import { take, switchMap, share } from 'rxjs/operators';
import { RegisterService } from 'app/api/register.service';
import { AvaliacaoApiService } from 'app/api/avaliacaoApi.service';
import { CargoApiService } from 'app/api/cargoApi.service';
import { CienciaApiService } from 'app/api/cienciaApi.service';
import { FavoritosApiService } from 'app/api/favoritosApi.service';
import {
  LucorApiModelsNewPerfil,
  LucorApiModelsNewFiltroAderencia,
  LucorApiModelsDetailsAvaliacaoCargo,
  LucorApiModelsDetailsPessoaAderente,
  LucorApiModelsAvaliado,
  LucorApiModelsDetailsListagemFavoritos,
  LucorApiModelsDetailsAvaliadoFavorito,
  LucorApiModelsCiencia
} from 'app/model/models';
import Driver from 'driver.js';
import { SubSink } from 'subsink';
import { CookieService } from '@ngx-toolkit/cookie';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { DataTableDirective } from 'angular-datatables';
import { LanguageDataTable } from 'app/shared/datatable/translate-datatable';

@Component({
  selector: 'app-selecionados-detalhes',
  templateUrl: './selecionados-detalhes.component.html',
  styleUrls: ['./selecionados-detalhes.component.css']
})

export class SelecionadosDetalhesComponent implements OnInit, OnDestroy {

  listagem: LucorApiModelsDetailsListagemFavoritos;
  ciencias$: Observable<LucorApiModelsCiencia[]>;
  selecionados$: Observable<LucorApiModelsDetailsAvaliadoFavorito[]>
  avaliacoesCargo$: Observable<LucorApiModelsDetailsAvaliacaoCargo[]>;
  aderenciaAvaliados$: Observable<LucorApiModelsDetailsPessoaAderente[]>;
  avaliadosdaAvaliacaoCargo: LucorApiModelsAvaliado[];
  perfisAxiologia$: Observable<LucorApiModelsNewPerfil[]>;
  perfisComportamento$: Observable<LucorApiModelsNewPerfil[]>;
  perfisMotivadores$: Observable<LucorApiModelsNewPerfil[]>;
  filtroAderencia: LucorApiModelsNewFiltroAderencia;
  filtroCiencias;
  perfilAxiologia = [];
  perfilComportamento = [];
  perfilMotivadores = [];

  balancoComportamento: Array<any> = new Array<any>();
  avaliadosCargoAxiologiaCalculo: Array<any> = new Array<any>();
  avaliadosCargoComportamentoCalculo: Array<any> = new Array<any>();
  avaliadosCargoMotivadoresCalculo: Array<any> = new Array<any>();

  tituloCargo: string;
  idCargo: number;
  idCliente: number;
  idListagem: number;
  mostraAdequacao = false;
  verificaAssociacao: number;

  avaliacoesAssociadas = [];
  selecionadosCount = 0;
  loadCompatibilidade = false;
  countAvaliados: number;

  usuarioLogado: number;
  order = 'ranking.posicao';
  reverse = false;

  newArrayAderencias: Array<any> = [];

  matchAxiologia = [];
  matchComportamento = [];
  matchMotivadores = [];

  checkCookie = false;
  disableTour = false;
  driver = new Driver({
    className: 'tour-lucor',
    doneBtnText: 'Entendi', // Text on the final button
    closeBtnText: 'Fechar', // Text on the close button for this step
    nextBtnText: 'Próximo', // Next button text for this step
    prevBtnText: 'Anterior', // Previous button text for this step
  });

  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};

  /**
   * Gráficos
   */

  avaliadosCargoAxiologia: Array<any> = new Array<any>();
  avaliadosCargoComportamento: Array<any> = new Array<any>();
  avaliadosCargoMotivadores: Array<any> = new Array<any>();

  newArrayAxiologia: Array<any> = new Array<any>();
  newArrayComportamento: Array<any> = new Array<any>();
  newArrayMotivadores: Array<any> = new Array<any>();

  setChartLabelsAxiologia: Array<any> = new Array<any>();
  setChartDatasetsAxiologia: Array<any> = [];

  // Gráfico Resultado Axiologia
  public chartTypeAxiologia = 'radar';
  public chartDatasetsAxiologia: Array<any> = [];
  public chartLabelsAxiologia: Array<any> = [];
  public chartColorsAxiologia: Array<any> = [
    {
      borderColor: 'rgba(255, 168, 5, 1)',
      backgroundColor: 'rgba(255, 168, 5, 0.0)',
      borderWidth: 2,
    },
    {
      borderColor: 'rgba(145, 206, 244, 1)',
      backgroundColor: 'rgba(145, 206, 244, 0.5)',
      borderWidth: 2,
    }
  ];
  public chartOptionsAxiologia: any = {
    responsive: true,
    scale: {
      angleLines: {
          display: true
      },
      /*
      gridLines: {
        color: ['#343A40', '#343A40', '#343A40', '#343A40', '#343A40', '#343A40', '#343A40', '#343A40', '#343A40', '#343A40']
      },
      */
      ticks: {
          fontSize: 10,
          suggestedMin: 0,
          suggestedMax: 100
      }
    }
  };

  setChartLabelsComportamento: Array<any> = new Array<any>();
  setChartDatasetsComportamento: Array<any> = [];

  // Gráfico Resultado Comportamento
  public chartTypeComportamento = 'radar';
  public chartDatasetsComportamento: Array<any> = [];
  public chartLabelsComportamento: Array<any> = [];
  public chartColorsComportamento: Array<any> = [
    {
      borderColor: 'rgba(255, 168, 5, 1)',
      backgroundColor: 'rgba(255, 168, 5, 0.0)',
      borderWidth: 2,
    },
    {
      borderColor: 'rgba(145, 206, 244, 1)',
      backgroundColor: 'rgba(145, 206, 244, 0.5)',
      borderWidth: 2,
    }
  ];
  public chartOptionsComportamento: any = {
    responsive: true,
    scale: {
      angleLines: {
          display: true
      },
      /*
      gridLines: {
        color: ['#343A40', '#343A40', '#343A40', '#343A40', '#343A40', '#343A40', '#343A40', '#343A40', '#343A40', '#343A40']
      },
      */
      ticks: {
          fontSize: 10,
          suggestedMin: 0,
          suggestedMax: 100
      }
    }
  };

  setChartLabelsMotivadores: Array<any> = new Array<any>();
  setChartDatasetsMotivadores: Array<any> = [];

  // Gráfico Resultado Motivadores
  public chartTypeMotivadores = 'radar';
  public chartDatasetsMotivadores: Array<any> = [];
  public chartLabelsMotivadores: Array<any> = [];
  public chartColorsMotivadores: Array<any> = [
    {
      borderColor: 'rgba(255, 168, 5, 1)',
      backgroundColor: 'rgba(255, 168, 5, 0.0)',
      borderWidth: 2,
    },
    {
      borderColor: 'rgba(145, 206, 244, 1)',
      backgroundColor: 'rgba(145, 206, 244, 0.5)',
      borderWidth: 2,
    }
  ];
  public chartOptionsMotivadores: any = {
    responsive: true,
    scale: {
      angleLines: {
          display: true
      },
      /*
      gridLines: {
        color: ['#343A40', '#343A40', '#343A40', '#343A40', '#343A40', '#343A40', '#343A40', '#343A40', '#343A40', '#343A40']
      },
      */
      ticks: {
          fontSize: 10,
          suggestedMin: 0,
          suggestedMax: 100
      }
    }/*,
    legend: {
      position: 'left'
    }*/
  };

/*
*  Mini Gráficos Adequação
*/

setChartDataAdequacao = [{ data: [80, 20], label: 'Adequação' }];

public chartTypeDoughnut = 'doughnut';

/*
* ---- Gráfico 01
*/
  public chartDatasets: Array<any> = [
    { data: [80, 20], label: 'Adequação' }
  ];

  public chartLabels: Array<any> = ['', ''];

  public chartColors: Array<any> = [
    {
      backgroundColor: ['#45BF55', '#445953'],
      hoverBackgroundColor: ['#97ED8A'],
      borderWidth: 0,
    }
  ];

  public chartOptions: any = {
    responsive: true,
    tooltips: { enabled: false },
    hover: { mode: null }
  };

  /*
   *** -- comum aos gráficos
  */
  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }

  private subs = new SubSink();


  constructor(
    private cargosService: CargoApiService,
    private avaliacoesService: AvaliacaoApiService,
    private cienciasService: CienciaApiService,
    private selecionadosService: FavoritosApiService,
    private registerService: RegisterService,
    private route: ActivatedRoute,
    private alertService: AlertModalService,
    private location: Location,
    private cookieService: CookieService
  ) {
    this.subs.sink = this.registerService.getUsuarioLogadoAsync()
    .subscribe(usuarioLogado => {
      this.usuarioLogado = usuarioLogado.pessoaJuridicaId;
    });
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 15,
      processing: true,
      responsive: true,
      language: LanguageDataTable.portugues_datatables
    };

    this.subs.sink = this.route.data.subscribe(
          (dados) => {
            this.listagem = dados.selecionadosResolver;
            this.idListagem = this.listagem.id;
            this.idCargo = this.listagem.cargoId;
            this.tituloCargo = this.listagem.tituloCargo;
            if (this.listagem) {
              this.getSelecionados();
            }
          }
        );

        forkJoin([
            this.perfisAxiologia$ = this.cargosService.getPerfisCargo(this.idCargo, 1).pipe(share()),
            this.perfisAxiologia$.forEach(perfil => {
              perfil.forEach(p => {
                if (p.fator !== null) {
                  this.perfilAxiologia.push(p.fator);
                  this.matchAxiologia.push(p);
                }
              });
            }),
            this.perfisComportamento$ = this.cargosService.getPerfisCargo(this.idCargo, 2).pipe(share()),
            this.perfisComportamento$.forEach(perfil => {
              perfil.forEach(p => {
                if (p.fator !== null) {
                  this.perfilComportamento.push(p.fator);
                  this.matchComportamento.push(p);
                }
              });
            }),
            this.perfisMotivadores$ = this.cargosService.getPerfisCargo(this.idCargo, 3).pipe(share()),
            this.perfisMotivadores$.forEach(perfil => {
              perfil.forEach(p => {
                if (p.fator !== null) {
                  this.perfilMotivadores.push(p.fator);
                  this.matchMotivadores.push(p);
                }
              });
            }),
          ]).subscribe(() => {
          this.getPerfis(this.perfilAxiologia, this.perfilComportamento, this.perfilMotivadores);
        });

        this.avaliacoesCargo$ = this.cargosService.getAvaliacoesCargo(this.idCargo).pipe(share());
          const newAvaliacao = [];
          const promiseInit = new Promise((resolve, reject) => {
            this.avaliacoesCargo$.forEach(avaliacaoCargo => {
              if (avaliacaoCargo.length > 0) {
                const resultadoLength = avaliacaoCargo.length;
                avaliacaoCargo.forEach(avaliacao => {
                  this.subs.sink = this.avaliacoesService.getAvaliadosAvaliacaoAsync(avaliacao.avaliacaoId)
                    .subscribe(avaliacoesAsync => {
                      newAvaliacao.push(avaliacoesAsync);
                      if (newAvaliacao.length === resultadoLength) {
                        resolve(newAvaliacao);
                      }
                    });
                })
              }
              else {
                this.alertService.showAlertDanger('Ops! Não há avaliação associada ao cargo! Você precisa associar novamente.', 6000);
                setTimeout(() => {
                  this.location.back();
                }, 3000);
              }
              }),
              error => {
              this.alertService.showAlertDanger(error);
              };
          });

          promiseInit.then((result: Array<any> = new Array<any>()) => {
            this.avaliadosdaAvaliacaoCargo = [].concat(...result);

            this.matchAvaliadosCalculos(
              this.matchAxiologia, this.matchComportamento, this.matchMotivadores
            );

          });

    this.checkCookie = this.cookieService.hasItem('tourSelDetalhes');
    if (this.checkCookie === false) {
      this.startTour();
      this.setCookie();
    }

    this.getCiencias();

  }

  private getCiencias() {
    this.ciencias$ = this.cienciasService.getCienciasAsync().pipe(share());
  }

  startTour(): void {
    this.disableTour = true;
    // Define the steps for tour
    setTimeout(() => {
      this.driver.defineSteps([
        {
          element: '#one-element-tour',
          popover: {
            className: 'first-step-popover-class',
            title: 'Vamos fazer um tour?',
            description: 'Você pode usar as teclas <i class="fas fa-caret-left icon-tour"></i> e <i class="fas fa-caret-right icon-tour"></i> para avançar e voltar. Acesse aqui sempre que precisar.',
            position: 'left',
            closeBtnText: 'Eu vejo depois.',
            nextBtnText: 'Vamos lá!',
            prevBtnText: '<i class="fas fa-plane-departure"></i>'
          }
        },
        {
          element: '#two-element-tour',
          popover: {
            title: 'Campo de pesquisa',
            description: 'Aqui você pode pesquisar pelo nome do selecionado. Atenção para a paginação, a pessoa que você procura pode estar em outra página.',
            position: 'bottom'
          }
        },
        {
          element: '#three-element-tour',
          stageBackground: '#7a7a7a',
          popover: {
            title: 'Selecionados',
            description: 'Clique sobre o nome para ver o perfil do selecionado.',
            position: 'right'
          }
        },
        {
          element: '#four-element-tour',
          stageBackground: '#7a7a7a',
          popover: {
            title: 'Compatibilidade',
            description: 'Clique para ver a compatibilidade do selecionado com o cargo.',
            position: 'left'
          }
        },
        {
          element: '#five-element-tour',
          stageBackground: '#7a7a7a',
          popover: {
            title: 'Seleção',
            description: 'Clique para remover o selecionado da pasta.',
            position: 'left'
          }
        },
      ]);
      // Start the introduction
      this.driver.start();
      this.disableTour = false;
    }, 2000);
  }

  setCookie() {
    this.cookieService.setItem( 'tourSelDetalhes', '1' );
  }

  getSelecionados() {
    this.selecionados$ = this.selecionadosService.listarAvaliadosListagemAsync(this.idListagem).pipe(share());
  }

  getPerfis(Axiologia, Comportamento, Motivadores) {

    this.filtroCiencias = [];

    const resultAxiologia = Axiologia;
    const resultComportamento = Comportamento;
    const resultMotivadores = Motivadores;

    if (resultAxiologia.length > 0) {
        this.filtroCiencias.push({ nomeCiencia: 'Axiologia', filtroFatores: resultAxiologia });
      }
    if (resultComportamento.length > 0) {
        this.filtroCiencias.push({ nomeCiencia: 'Comportamento Observado', filtroFatores: resultComportamento });
      }
    if (resultMotivadores.length > 0) {
        this.filtroCiencias.push({ nomeCiencia: 'Motivadores', filtroFatores: resultMotivadores });
      }
    this.filtroAderencia = { clienteId: this.idCliente, ciencias: this.filtroCiencias };
    this.aderenciaAvaliados$ = this.cargosService.getListarAderenciaAvaliadosAoCargo(this.idCargo, this.filtroAderencia).pipe(share());
    this.getAderenciaAvaliados();

  }

  getAderenciaAvaliados() {

    this.newArrayAderencias = [];

    this.aderenciaAvaliados$.forEach(aderenciaAvaliados => {

      aderenciaAvaliados.forEach(aderencia => {

        let idListaAvaliado: number;

        aderencia.idListagemFavoritos.forEach(idLista => {

          idListaAvaliado = idLista;

          if ( idListaAvaliado === this.idListagem) {
  
              const arrayAderenciaCiencias = [];
              aderencia.aderenciaCiencias.forEach(aderenciaCiencia => { arrayAderenciaCiencias.push(aderenciaCiencia); });
  
              this.selecionadosCount++;
  
              this.newArrayAderencias.push({
                      avaliacaoId: aderencia.avaliacaoId,
                      avaliadoId: aderencia.avaliadoId,
                      nome: aderencia.nome,
                      idListagemFavoritos: [
                        aderencia.idListagemFavoritos
                      ],
                      idAvaliadosListagensFavoritos: [
                        aderencia.idAvaliadoListagemFavoritos
                      ],
                      posicaoGeral: aderencia.ranking.posicao,
                      aproximacao: aderencia.ranking.percentualAproximacao,
                      ranking: [
                        {
                        descricao: aderencia.ranking.descricao,
                        percentualAproximacao: aderencia.ranking.percentualAproximacao,
                        posicao: aderencia.ranking.posicao
                        }
                      ],
                      aderenciaCiencias: arrayAderenciaCiencias,
                      setChartAdequacao: [
                          {
                          data: [aderencia.ranking.percentualAproximacao, (100 - aderencia.ranking.percentualAproximacao)],
                          label: 'Adequação'
                          }
                      ],
                      setMatchCargo: true,
                  });
          }
        }
        );
      });
    });
    this.loadCompatibilidade = true;

  }

  matchAvaliados(idSelecionado?: number) {

    this.chartLabelsAxiologia = [];
    this.chartDatasetsAxiologia = [];

    this.chartLabelsComportamento = [];
    this.chartDatasetsComportamento = [];

    this.chartLabelsMotivadores = [];
    this.chartDatasetsMotivadores = [];

    this.avaliadosCargoAxiologia = [];
    this.avaliadosCargoComportamento = [];
    this.avaliadosCargoMotivadores = [];

    if (idSelecionado) {

      forkJoin([of(

        this.avaliadosdaAvaliacaoCargo.forEach(avaliadosAsync => {

            if (idSelecionado === avaliadosAsync.id) {

            avaliadosAsync.resultadosCiencias.forEach(resultadoCienciasAsync => {

              // AXIOLOGIA

              if (this.matchAxiologia.length > 0 && resultadoCienciasAsync.cienciaId === 1) {
                this.matchAxiologia.map(fatorAxiologia => {
                  resultadoCienciasAsync.resultados
                      .filter(resultadosAsync => {

                        let nomeIgual = false;

                        if (fatorAxiologia.fator === resultadosAsync.nomeFator) {
                          if (this.avaliadosCargoAxiologia.length > 0) {
                            this.avaliadosCargoAxiologia.forEach(avaliadosCargoAxi => {
                              if (avaliadosCargoAxi.idAvaliado === avaliadosAsync.id) {
                                avaliadosCargoAxi.resultados.push(
                                      resultadosAsync
                                    );
                                nomeIgual = true;
                              }
                            });
                          }

                          if (!nomeIgual) {
                            this.avaliadosCargoAxiologia.push(
                                {
                                  idAvaliado: avaliadosAsync.id,
                                  nomeAvaliado: avaliadosAsync.nome,
                                  resultados: [
                                    resultadosAsync
                                  ]
                                }
                              );
                          }
                        }
                      });
                });
              }
                      
              // COMPORTAMENTO OBSERVADO

              if (this.matchComportamento.length > 0 && resultadoCienciasAsync.cienciaId === 2) {
               
                this.matchComportamento.map(fatorComportamento => {
                  resultadoCienciasAsync.resultados
                      .filter(resultadosAsync => {

                        let nomeIgual = false;

                        if (fatorComportamento.fator === resultadosAsync.nomeFator) {
                          if (this.avaliadosCargoComportamento.length > 0) {
                            this.avaliadosCargoComportamento.forEach(avaliadosCargoComp => {
                              if (avaliadosCargoComp.idAvaliado === avaliadosAsync.id) {
                                avaliadosCargoComp.resultados.push(
                                      resultadosAsync
                                    );
                                nomeIgual = true;
                              }
                            });
                          }

                          if (!nomeIgual) {
                            this.avaliadosCargoComportamento.push(
                                {
                                  idAvaliado: avaliadosAsync.id,
                                  nomeAvaliado: avaliadosAsync.nome,
                                  resultados: [
                                    resultadosAsync
                                  ]
                                }
                              );
                          }
                        }
                      });
                    });
              }

              // MOTIVADORES

              if (this.matchMotivadores.length > 0 && resultadoCienciasAsync.cienciaId === 3) {
                this.matchMotivadores.map(fatorMotivadores => {
                  resultadoCienciasAsync.resultados
                      .filter(resultadosAsync => {

                        let nomeIgual = false;

                        if (fatorMotivadores.fator === resultadosAsync.nomeFator) {
                          if (this.avaliadosCargoMotivadores.length > 0) {
                            this.avaliadosCargoMotivadores.forEach(avaliadosCargoMot => {
                              if (avaliadosCargoMot.idAvaliado === avaliadosAsync.id) {
                                avaliadosCargoMot.resultados.push(
                                      resultadosAsync
                                    );
                                nomeIgual = true;
                              }
                            });
                          }

                          if (!nomeIgual) {
                            this.avaliadosCargoMotivadores.push(
                                {
                                  idAvaliado: avaliadosAsync.id,
                                  nomeAvaliado: avaliadosAsync.nome,
                                  resultados: [
                                    resultadosAsync
                                  ]
                                }
                              );
                          }
                        }
                      });
                    });
              }
            });
           }
          })
       )]).subscribe(() => {

          // AXIOLOGIA

          if (this.avaliadosCargoAxiologia.length > 0) {
            this.avaliadosCargoAxiologia.push({
              idAvaliado: null,
              nomeAvaliado: 'Perfil Axiologia',
              resultados: []
            });

            this.avaliadosCargoAxiologia.forEach(avaliadosCargoAxi => {
                this.matchAxiologia.map(fatorAxiologia => {
                if (avaliadosCargoAxi.nomeAvaliado === 'Perfil Axiologia') {
                  avaliadosCargoAxi.resultados.push({
                    nomeFator: fatorAxiologia.fator,
                    valor: fatorAxiologia.ideal
                  });
                }
              });
            });
          }

          // COMPORTAMENTO OBSERVADO

          if (this.avaliadosCargoComportamento.length > 0) {
            this.avaliadosCargoComportamento.push({
              idAvaliado: null,
              nomeAvaliado: 'Perfil Comportamento',
              resultados: []
            });

            this.avaliadosCargoComportamento.forEach(avaliadosCargoComp => {
                this.matchComportamento.map(fatorComportamento => {
                if (avaliadosCargoComp.nomeAvaliado === 'Perfil Comportamento') {
                  avaliadosCargoComp.resultados.push({
                    nomeFator: fatorComportamento.fator,
                    valor: fatorComportamento.ideal
                  });
                }
              });
            });
          }

          // MOTIVADORES

          if (this.avaliadosCargoMotivadores.length > 0) {
            this.avaliadosCargoMotivadores.push({
              idAvaliado: null,
              nomeAvaliado: 'Perfil Motivadores',
              resultados: []
            });

            this.avaliadosCargoMotivadores.forEach(avaliadosCargoMot => {
                this.matchMotivadores.map(fatorMotivadores => {
                if (avaliadosCargoMot.nomeAvaliado === 'Perfil Motivadores') {
                  avaliadosCargoMot.resultados.push({
                    nomeFator: fatorMotivadores.fator,
                    valor: fatorMotivadores.ideal
                  });
                }
              });
            });
          }
          this.loadGraficos(idSelecionado);
        }
      );

    } else {
      this.alertService.showAlertWarning('Algo deu errado! Não foi possível carregar os dados desta pessoa. Tente novamente.');
    }
  }

  private matchAvaliadosCalculos( matchAxiologia: LucorApiModelsNewPerfil[],
                                  matchComportamento: LucorApiModelsNewPerfil[],
                                  matchMotivadores: LucorApiModelsNewPerfil[]) {

    this.countAvaliados = 0;
    let selecionadosSoma: number = null;

    const promiseInit = new Promise((resolve, reject) => {

      this.avaliadosdaAvaliacaoCargo.forEach(avaliadosAsync => {

        this.selecionados$.forEach(selecionados => {

          selecionadosSoma = selecionados.length;

          selecionados.forEach(selecionado => { 

            if (avaliadosAsync.id == selecionado.avaliadoId) {

              this.countAvaliados++;
        
              avaliadosAsync.resultadosCiencias.map(resultadoCienciasAsync => {
        
                // COMPORTAMENTO OBSERVADO
                if (matchComportamento.length > 0) {
                  if (resultadoCienciasAsync.cienciaId === 2) {
        
                    matchComportamento.map(perfilComportamento => {
                      resultadoCienciasAsync.resultados
                        .filter(resultados => {

                          let nomeIgual = false;

                          if (perfilComportamento.fator === resultados.nomeFator) {
        
                            if (this.avaliadosCargoComportamentoCalculo.length > 0) {
                              this.avaliadosCargoComportamentoCalculo.map(avaliadosCargoComp => {
        
                                if (perfilComportamento.fator === avaliadosCargoComp.nomeFator) {
        
                                  avaliadosCargoComp.resultados.push(
                                    {
                                      idAvaliado: avaliadosAsync.id,
                                      nomeAvaliado: avaliadosAsync.nome,
                                      sexo: avaliadosAsync.sexo,
                                      valor: +resultados.valor,
                                      classificacao: resultados.classificacao,
                                      diferenca: undefined,
                                      avaliacaoId: avaliadosAsync.avaliacaoId,
                                      status: undefined,
                                      fatores: [resultados],
                                    }
                                      );
                                  nomeIgual = true;
                                } else { // Condição para gerar dados para calcular diferença entre fatores Natural x Adaptável, usado em Feedback.
                                  avaliadosCargoComp.resultados.map(res => {
                                    if (avaliadosAsync.id === res.idAvaliado) {
                                      res.fatores.push(resultados);
                                    }
                                  });
                                }
                              });
                            }
        
                            if (!nomeIgual) {
                              this.avaliadosCargoComportamentoCalculo.push(
                                  {
                                    fatorId: resultados.fatorId,
                                    nomeFator: resultados.nomeFator,
                                    padrao: undefined,
                                    countAcima: undefined,
                                    countAbaixo: undefined,
                                    countExato: undefined,
                                    resultados: [
                                      {
                                        idAvaliado: avaliadosAsync.id,
                                        nomeAvaliado: avaliadosAsync.nome,
                                        sexo: avaliadosAsync.sexo,
                                        valor: +resultados.valor,
                                        classificacao: resultados.classificacao,
                                        diferenca: undefined,
                                        avaliacaoId: avaliadosAsync.avaliacaoId,
                                        status: undefined,
                                        fatores: [resultados],
                                      }
                                    ]
                                  }
                                );
                            }
                          }
        
                        });
        
        
                      });
                }
                }
        
                // MOTIVADORES
                if (matchMotivadores.length > 0) {
                if (resultadoCienciasAsync.cienciaId === 3) {
                  matchMotivadores.map(perfilMotivadores => {
                    resultadoCienciasAsync.resultados
                        .filter(resultados => {
        
                          let nomeIgual = false;
        
                          if (perfilMotivadores.fator === resultados.nomeFator) {
                            if (this.avaliadosCargoMotivadoresCalculo.length > 0) {
                              this.avaliadosCargoMotivadoresCalculo.map(avaliadosCargoComp => {
        
                                if (perfilMotivadores.fator === avaliadosCargoComp.nomeFator) {
                    
                                  avaliadosCargoComp.resultados.push(
                                    {
                                      idAvaliado: avaliadosAsync.id,
                                      nomeAvaliado: avaliadosAsync.nome,
                                      valor: +resultados.valor,
                                      classificacao: resultados.classificacao,
                                      diferenca: undefined,
                                      avaliacaoId: avaliadosAsync.avaliacaoId,
                                      status: undefined
                                    }
                                      );
                                  nomeIgual = true;
                                }
                              });
                            }
        
                            if (!nomeIgual) {
                              this.avaliadosCargoMotivadoresCalculo.push(
                                  {
                                    fatorId: resultados.fatorId,
                                    nomeFator: resultados.nomeFator,
                                    padrao: undefined,
                                    countAcima: undefined,
                                    countAbaixo: undefined,
                                    countExato: undefined,
                                    resultados: [
                                      {
                                        idAvaliado: avaliadosAsync.id,
                                        nomeAvaliado: avaliadosAsync.nome,
                                        valor: +resultados.valor,
                                        classificacao: resultados.classificacao,
                                        diferenca: undefined,
                                        avaliacaoId: avaliadosAsync.avaliacaoId,
                                        status: undefined
                                      }
                                    ]
                                  }
                                );
                            }
                          }
                        });
                      });
                }
                }
        
                // AXIOLOGIA
                if (matchAxiologia.length > 0) {
                  if (resultadoCienciasAsync.cienciaId === 1) {
                  matchAxiologia.map(perfilAxiologia => {
                    resultadoCienciasAsync.resultados
                      .filter(resultados => {
        
                        let nomeIgual = false;
        
                        if (perfilAxiologia.fator === resultados.nomeFator) {
                          if (this.avaliadosCargoAxiologiaCalculo.length > 0) {
                            this.avaliadosCargoAxiologiaCalculo.map(avaliadosCargoComp => {
        
                              if (perfilAxiologia.fator === avaliadosCargoComp.nomeFator) {
                                avaliadosCargoComp.resultados.push(
                                  {
                                    idAvaliado: avaliadosAsync.id,
                                    nomeAvaliado: avaliadosAsync.nome,
                                    valor: +resultados.valor,
                                    classificacao: resultados.classificacao,
                                    diferenca: undefined,
                                    avaliacaoId: avaliadosAsync.avaliacaoId,
                                    status: undefined
                                  }
                                    );
                                nomeIgual = true;
                              }
                            });
                          }
        
                          if (!nomeIgual) {
                            this.avaliadosCargoAxiologiaCalculo.push(
                                {
                                  fatorId: resultados.fatorId,
                                  nomeFator: resultados.nomeFator,
                                  padrao: undefined,
                                  countAcima: undefined,
                                  countAbaixo: undefined,
                                  countExato: undefined,
                                  resultados: [
                                    {
                                      idAvaliado: avaliadosAsync.id,
                                      nomeAvaliado: avaliadosAsync.nome,
                                      valor: +resultados.valor,
                                      classificacao: resultados.classificacao,
                                      diferenca: undefined,
                                      avaliacaoId: avaliadosAsync.avaliacaoId,
                                      status: undefined
                                    }
                                  ]
                                }
                              );
                          }
                        }
                      });
                    });
                }
                }

                if (selecionadosSoma == this.countAvaliados) {
                    resolve(this.avaliadosCargoAxiologiaCalculo);
                }

              });

            }
          });
        });

      });

    });
   
      promiseInit.then((result: Array<any> = new Array<any>()) => {

        this.setCalculoBalancoComportamento();
        this.setCalculoAxiologia(matchAxiologia);
        this.setCalculoComportamento(matchComportamento);
        this.setCalculoMotivadores(matchMotivadores);
        
      });  
  
  }


  private setCalculoBalancoComportamento() {

    this.balancoComportamento = [];
    let porcentagem: number;

    if (this.avaliadosdaAvaliacaoCargo.length > 0) {
      this.avaliadosdaAvaliacaoCargo.forEach(arrayComportamento => {

        this.balancoComportamento.push({
          fator: [],
          avaliado: arrayComportamento.nome,
          avaliadoId: arrayComportamento.id,
        });

        arrayComportamento.resultadosCiencias.forEach(ciencias => {

          if (ciencias.cienciaId === 2) {

            ciencias.resultados.sort((a, b) => a.fatorId - b.fatorId).forEach(fatores => {

                if (this.balancoComportamento) {
                  this.balancoComportamento.map(balanco => {

                    if (arrayComportamento.id === balanco.avaliadoId) {
                      if (fatores.fatorId === 82 || fatores.fatorId === 83 || fatores.fatorId === 84 || fatores.fatorId === 85) {
                      balanco.fator.push(
                          {
                            fatorId: fatores.fatorId,
                            nomeFator: fatores.nomeFator,
                            valorNatural: fatores.valor,
                            valorAdaptavel: null,
                            diferenca: null,
                            status: null
                          }
                      );
                        }
                    }

                    balanco.fator.map(fator => {

                      if (fatores.fatorId === 86 && fator.nomeFator === 'DISC NATURAL - Dominância'
                        && arrayComportamento.id === balanco.avaliadoId) {
                        fator.valorAdaptavel = fatores.valor;
                        porcentagem = (fator.valorAdaptavel * 100) / fator.valorNatural;
                        fator.diferenca = (100 - porcentagem);
                        fator.status = fator.valorNatural === fator.valorAdaptavel ? 'Exato' : fator.valorNatural < fator.valorAdaptavel ? 'Acima' : 'Abaixo';
                      }

                      if (fatores.fatorId === 87 && fator.nomeFator === 'DISC NATURAL - Influência'
                        && arrayComportamento.id === balanco.avaliadoId) {
                        fator.valorAdaptavel = fatores.valor;
                        porcentagem = (fator.valorAdaptavel * 100) / fator.valorNatural;
                        fator.diferenca = (100 - porcentagem);
                        fator.status = fator.valorNatural === fator.valorAdaptavel ? 'Exato' : fator.valorNatural < fator.valorAdaptavel ? 'Acima' : 'Abaixo';
                      }

                      if (fatores.fatorId === 88 && fator.nomeFator === 'DISC NATURAL - Estabilidade'
                        && arrayComportamento.id === balanco.avaliadoId) {
                        fator.valorAdaptavel = fatores.valor;
                        porcentagem = (fator.valorAdaptavel * 100) / fator.valorNatural;
                        fator.diferenca = (100 - porcentagem);
                        fator.status = fator.valorNatural === fator.valorAdaptavel ? 'Exato' : fator.valorNatural < fator.valorAdaptavel ? 'Acima' : 'Abaixo';
                      }

                      if (fatores.fatorId === 88 && fator.nomeFator === 'DISC NATURAL - Cautela'
                        && arrayComportamento.id === balanco.avaliadoId) {
                        fator.valorAdaptavel = fatores.valor;
                        porcentagem = (fator.valorAdaptavel * 100) / fator.valorNatural;
                        fator.diferenca = (100 - porcentagem);
                        fator.status = fator.valorNatural === fator.valorAdaptavel ? 'Exato' : fator.valorNatural < fator.valorAdaptavel ? 'Acima' : 'Abaixo';
                      }

                    });
                  });
                }
            });
          }
      });
      });
    }
  }


  private setCalculoComportamento(matchComportamento: LucorApiModelsNewPerfil[]) {

    let porcentagem: number;

    if (this.avaliadosCargoComportamentoCalculo.length > 0) {

      this.avaliadosCargoComportamentoCalculo.forEach(arrayComportamento => {

        const countComportamentoAcima = [];
        const countComportamentoAbaixo = [];
        const countComportamentoExato = [];

        arrayComportamento.resultados.forEach(comportamento => {

          matchComportamento.map(padrao => {

            if (arrayComportamento.nomeFator === padrao.fator) {
              arrayComportamento.padrao = padrao.ideal;
              porcentagem = (comportamento.valor * 100) / padrao.ideal; // Regra de 3 para chegar na equiparação da porcentagem
              comportamento.diferenca = (100 - porcentagem);
              // a ordem do 100 antes é para inverter o sinal.. a diferença que for positiva ficará com sinal negativo.. na lista isso representará que é necessário baixar para chegar no ideal.
              if (comportamento.diferenca < 0) {
                comportamento.status = 'ACIMA';
                countComportamentoAcima.push(comportamento.diferenca);
                arrayComportamento.countAcima = countComportamentoAcima.length;
              }
              if (comportamento.diferenca > 0) {
                comportamento.status = 'ABAIXO';
                countComportamentoAbaixo.push(comportamento.diferenca);
                arrayComportamento.countAbaixo = countComportamentoAbaixo.length;
              }
              if (comportamento.diferenca === 0) {
                comportamento.status = 'IDEAL';
                countComportamentoExato.push(comportamento.diferenca);
                arrayComportamento.countExato = countComportamentoExato.length;
              }
            }
          });
        });
      });
    }

    
  }

  private setCalculoMotivadores(matchMotivadores: LucorApiModelsNewPerfil[]) {

    let porcentagem: number;

    if (this.avaliadosCargoMotivadoresCalculo.length > 0) {

    this.avaliadosCargoMotivadoresCalculo.forEach(arrayMotivadores => {

      const countMotivadoresAcima = [];
      const countMotivadoresAbaixo = [];
      const countMotivadoresExato = [];

      arrayMotivadores.resultados.forEach(motivadores => {
        matchMotivadores.map(padrao => {
          if (arrayMotivadores.nomeFator === padrao.fator) {
            arrayMotivadores.padrao = padrao.ideal;
            porcentagem = (motivadores.valor * 100) / padrao.ideal; // Regra de 3 para chegar na equiparação da porcentagem
            motivadores.diferenca = (100 - porcentagem);
            // a ordem do 100 antes é para inverter o sinal.. a diferença que for positiva ficará com sinal negativo.. na lista isso representará que é necessário baixar para chegar no ideal.
            if (motivadores.diferenca < 0) {
              motivadores.status = 'ACIMA';
              countMotivadoresAcima.push(motivadores.diferenca);
              arrayMotivadores.countAcima = countMotivadoresAcima.length;
            }
            if (motivadores.diferenca > 0) {
              motivadores.status = 'ABAIXO';
              countMotivadoresAbaixo.push(motivadores.diferenca);
              arrayMotivadores.countAbaixo = countMotivadoresAbaixo.length;
            }
            if (motivadores.diferenca === 0) {
              motivadores.status = 'IDEAL';
              countMotivadoresExato.push(motivadores.diferenca);
              arrayMotivadores.countExato = countMotivadoresExato.length;
            }
          }
        });
      });
    });
    }

  }

  private setCalculoAxiologia(matchMotivadores: LucorApiModelsNewPerfil[]) {

    let porcentagem: number;

    if (this.avaliadosCargoAxiologiaCalculo.length > 0) {

    this.avaliadosCargoAxiologiaCalculo.forEach(arrayAxiologia => {

      const countAxiologiaAcima = [];
      const countAxiologiaAbaixo = [];
      const countAxiologiaExato = [];

      arrayAxiologia.resultados.forEach(axiologia => {

        matchMotivadores.map(padrao => {

          if (arrayAxiologia.nomeFator === padrao.fator) {
            arrayAxiologia.padrao = padrao.ideal;
            porcentagem = (axiologia.valor * 100) / padrao.ideal; // Regra de 3 para chegar na equiparação da porcentagem
            axiologia.diferenca = (100 - porcentagem);
            // a ordem do 100 antes é para inverter o sinal.. a diferença que for positiva ficará com sinal negativo.. na lista isso representará que é necessário baixar para chegar no ideal.
            if (axiologia.diferenca < 0) {
              axiologia.status = 'ACIMA';
              countAxiologiaAcima.push(axiologia.diferenca);
              arrayAxiologia.countAcima = countAxiologiaAcima.length;
            }
            if (axiologia.diferenca > 0) {
              axiologia.status = 'ABAIXO';
              countAxiologiaAbaixo.push(axiologia.diferenca);
              arrayAxiologia.countAbaixo = countAxiologiaAbaixo.length;
            }
            if (axiologia.diferenca === 0) {
              axiologia.status = 'IDEAL';
              countAxiologiaExato.push(axiologia.diferenca);
              arrayAxiologia.countExato = countAxiologiaExato.length;
            }
          }
        });
      });
    });
    }


  }

  loadGraficos(idAvaliado?: number) {

    if (this.avaliadosCargoAxiologia.length > 0) {
      this.setGraficoResultadoAxiologia(idAvaliado);
    }
    if (this.avaliadosCargoComportamento.length > 0) {
      this.setGraficoResultadoComportamento(idAvaliado);
    }
    if (this.avaliadosCargoMotivadores.length > 0) {
      this.setGraficoResultadoMotivadores(idAvaliado);
    }

  }

  setGraficoResultadoAxiologia(idAvaliado?: number) {

    const valuesChartAxiologia = this.avaliadosCargoAxiologia;

    this.setChartLabelsAxiologia = [];
    this.chartDatasetsAxiologia = [];
    this.chartLabelsAxiologia = [];

    valuesChartAxiologia.forEach((avaliadosCargo, index) => {
      avaliadosCargo.resultados.map(fatoresAxiologia => {
        if (index === 0) {
          this.setChartLabelsAxiologia.push(fatoresAxiologia.nomeFator);
        }

      });
    });

    let fatorIgual: boolean;
    valuesChartAxiologia.forEach(fatoresAxio => {

      fatoresAxio.resultados.forEach(fatorAxio => {

      fatorIgual = false;

      this.chartDatasetsAxiologia.forEach(chartData => {

        if (this.chartDatasetsAxiologia && fatoresAxio.nomeAvaliado === chartData.label) {
          chartData.data.push(
              fatorAxio.valor
          );
          fatorIgual = true;
        }
      });

      if (!fatorIgual && fatoresAxio.idAvaliado === idAvaliado) {
        this.chartDatasetsAxiologia.push({
          data: [fatorAxio.valor],
          label: fatoresAxio.nomeAvaliado
        });
      }
      if (!fatorIgual && fatoresAxio.nomeAvaliado === 'Perfil Axiologia') {
        this.chartDatasetsAxiologia.push({
          data: [fatorAxio.valor],
          label: fatoresAxio.nomeAvaliado
        });
      }
     });
    });

    this.chartLabelsAxiologia = this.setChartLabelsAxiologia;
    this.chartDatasetsAxiologia = this.chartDatasetsAxiologia;

  }

  setGraficoResultadoComportamento(idAvaliado?: number) {

    const valuesChartComportamento = this.avaliadosCargoComportamento;

    this.setChartLabelsComportamento = [];
    this.chartDatasetsComportamento = [];
    this.chartLabelsComportamento = [];
    let fatorIgual: boolean;

    valuesChartComportamento.forEach((avaliadosCargo, index) => {
      avaliadosCargo.resultados.map(fatoresComportamento => {
        if (index === 0) {
          this.setChartLabelsComportamento.push(fatoresComportamento.nomeFator);
        }

      });
    });

    valuesChartComportamento.forEach(fatoresComp => {

      fatoresComp.resultados.forEach(fatorComp => {

      fatorIgual = false;

      this.chartDatasetsComportamento.forEach(chartData => {

        if (this.chartDatasetsComportamento && fatoresComp.nomeAvaliado === chartData.label) {
          chartData.data.push(
              fatorComp.valor
          );
          fatorIgual = true;
        }
      });

      if (!fatorIgual && fatoresComp.idAvaliado === idAvaliado) {
        this.chartDatasetsComportamento.push({
          data: [fatorComp.valor],
          label: fatoresComp.nomeAvaliado
        });
      }
      if (!fatorIgual && fatoresComp.nomeAvaliado === 'Perfil Comportamento') {
        this.chartDatasetsComportamento.push({
          data: [fatorComp.valor],
          label: fatoresComp.nomeAvaliado
        });
      }
     });
    });

    this.chartLabelsComportamento = this.setChartLabelsComportamento;
    this.chartDatasetsComportamento = this.chartDatasetsComportamento;

  }

  setGraficoResultadoMotivadores(idAvaliado?: number) {

    const valuesChartMotivadores = this.avaliadosCargoMotivadores;

    this.setChartLabelsMotivadores = [];
    this.chartDatasetsMotivadores = [];
    this.chartLabelsMotivadores = [];

    valuesChartMotivadores.forEach((avaliadosCargo, index) => {
      avaliadosCargo.resultados.map(fatoresMotivadores => {
        if (index === 0) {
          this.setChartLabelsMotivadores.push(fatoresMotivadores.nomeFator);
        }

      });
    });

    let fatorIgual: boolean;
    valuesChartMotivadores.forEach(fatoresComp => {

      fatoresComp.resultados.forEach(fatorComp => {

      fatorIgual = false;

      this.chartDatasetsMotivadores.forEach(chartData => {

        if (this.chartDatasetsMotivadores && fatoresComp.nomeAvaliado === chartData.label) {
          chartData.data.push(
              fatorComp.valor
          );
          fatorIgual = true;
        }
      });

      if (!fatorIgual && fatoresComp.idAvaliado === idAvaliado) {
        this.chartDatasetsMotivadores.push({
          data: [fatorComp.valor],
          label: fatoresComp.nomeAvaliado
        });
      }
      if (!fatorIgual && fatoresComp.nomeAvaliado === 'Perfil Motivadores') {
        this.chartDatasetsMotivadores.push({
          data: [fatorComp.valor],
          label: fatoresComp.nomeAvaliado
        });
      }
     });
    });

    this.chartLabelsMotivadores = this.setChartLabelsMotivadores;
    this.chartDatasetsMotivadores = this.chartDatasetsMotivadores;

  }

  removerSelecionado(selecionado: number) {
    const result$ = this.alertService.showConfirm('Confirmação', 'Tem certeza que deseja remover essa pessoa?');
    result$.asObservable()
    .pipe(
      take(1),
      switchMap(result => result ? this.selecionadosService.deleteAvaliadoListagemAsync(this.idListagem, selecionado) : EMPTY)
      )
      .subscribe(
        () => {
          this.alertService.showAlertSuccess('Selecionado removido da pasta!');
          this.getSelecionados();
        },
        error => {
          this.alertService.showAlertDanger('Erro ao remover o selecionado. ' + error.error);
        }
      );
  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

  goBack(): void {
    this.location.back();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
