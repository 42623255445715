import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderModule } from 'ngx-order-pipe';
import { SharedModule } from 'app/shared/shared.module';
import { TiposServicosComponent } from './components/tiposServicos/tiposServicos.component';
import { TiposServicosEditarComponent } from './components/tipos-servicos-editar/tipos-servicos-editar.component';
import { TiposServicosCadastrarComponent } from './components/tipos-servicos-cadastrar/tipos-servicos-cadastrar.component';
import { TiposServicosRoutingModule } from './tipos-servicos.routing.module';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        SharedModule,
        OrderModule,
        TiposServicosRoutingModule
    ],
    declarations: [
        TiposServicosComponent,
        TiposServicosEditarComponent,
        TiposServicosCadastrarComponent,
    ],
    providers: [
        SharedModule,
        OrderModule
      ],
  })

  export class TiposServicosModule {}
