import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { AvaliacaoApiService } from 'app/api/avaliacaoApi.service';
import { ClienteApiService } from 'app/api/clienteApi.service';
import { ServicoApiService } from 'app/api/servicoApi.service';
import { RegisterService } from 'app/api/register.service';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { LucorApiModelsPessoaJuridica } from 'app/model/lucorApiModelsPessoaJuridica';
import { LucorApiModelsServico } from 'app/model/lucorApiModelsServico';
import { LucorApiModelsDetailsUser } from 'app/model/lucorApiModelsDetailsUser';
import { SubSink } from 'subsink';


@Component({
  selector: 'app-avaliacoes-cadastrar',
  templateUrl: './avaliacoes-cadastrar.component.html',
  styleUrls: ['./avaliacoes-cadastrar.component.css']
})
export class AvaliacoesCadastrarComponent implements OnInit {

  clientes$: Observable<LucorApiModelsPessoaJuridica[]>;
  servico$: Observable<LucorApiModelsServico[]>;
  usuarioLogado: LucorApiModelsDetailsUser;
  servicoSelect;

  public files: NgxFileDropEntry[] = [];
  fileSend: File;
  fileDate;
  newFile = [];
  idServico: number;
  idCliente: number;
  enviarEmailInconsistencia = false;
  uploading = false;
  successUpload = false;
  checkUpload;

  private subs = new SubSink();

  constructor(
    private avaliacoesService: AvaliacaoApiService,
    private servicosService: ServicoApiService,
    private clienteService: ClienteApiService,
    private registerService: RegisterService,
    private router: Router,
    private alertService: AlertModalService) {}

  ngOnInit() {
    this.verificaUsuario();
    this.goTop();
  }

  verificaUsuario() {
    this.registerService.getUsuarioLogadoAsync()
    .subscribe(
      usuarioLogado => {
        this.usuarioLogado = usuarioLogado;
        this.idCliente = this.usuarioLogado.pessoaJuridicaId;
        if (this.idCliente) {
          this.getServicoByCliente(this.idCliente);
        } else {
          this.getClientes();
        }
      });
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Here you can access the real file
          // console.log(droppedFile.relativePath, file);
          this.newFile.push(file);
          this.fileSend = file;
          this.fileDate = file.lastModified;
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        // console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  public fileOver(event) {
    // console.log('FileOver: ' + event);
  }

  public fileLeave(event) {
    // console.log('FileLeave: ' + event);
  }

  deleteFile(item: NgxFileDropEntry) {
    this.newFile = [];
    this.files = this.files.filter(newFile => newFile !== item);
    this.dropped(this.files);
  }

  uploadPlanilha() {

    let count = 0;

    if (this.newFile.length === 0) {
      this.goBack();
    } else {
      this.uploading = true;
      for (const arquivo of this.newFile) {

        this.avaliacoesService.importarArquivoEIncluirAvaliacao(this.idServico, this.enviarEmailInconsistencia, arquivo)
        .subscribe(upload => {

          count++;

          if (count === this.newFile.length) {

            this.checkUpload = setInterval(() => {

              this.subs.sink = this.avaliacoesService.obterImportacaoDaPlanilhaById(upload.id)
              .subscribe(importando => {
  
                switch (importando.statusImportacao) {
  
                  case 'Processando':
                  break;
                  
                  case 'Pendente':
                  break;
  
                  case 'Sucesso': 
                    this.uploading = false;
                    this.successUpload = true;
                    this.alertService.showAlertSuccess('Avaliação cadastrada com sucesso!');
                    clearInterval(this.checkUpload);
                    setTimeout(() => {
                      this.goBack();
                    }, 1000);
                  break;
  
                  case 'Falha': 
                    this.alertService.showAlertDanger(`${"Erro ao enviar a planilha! " + importando.motivoFalha}`);
                    this.uploading = false;
                    clearInterval(this.checkUpload);
                  break;
  
                }
  
              });
            }, 5000);

          }

        }, (err) => {
          console.error(err);
          this.uploading = false;
          this.alertService.showAlertDanger(err);
        }),
        () => console.log('HTTP request completed.');
      }
    } 
  }

  getClientes() {
    let clientes;
    this.clientes$ = this.clienteService.getClientesAsync();
    this.clientes$.forEach(cliente => {
      clientes = cliente;
      if (clientes.length === 1) {
        clientes.forEach(clienteId => {
          this.getServicoByCliente(clienteId.id);
        });
      }
    });
  }

  selectCliente(id: number) {
    this.idCliente = id;
    this.servico$ = undefined;
    this.idServico = undefined;
    this.servicoSelect = undefined;
    this.getServicoByCliente(this.idCliente);
  }

  getServicoByCliente(id: number) {
    this.servico$ = this.servicosService.getServicoByClienteAsync(id);
  }

  selectServico(id: number) {
    this.idServico = id;
  }

  goBack() {
    this.router.navigate(['avaliacoes']);
  }

  goTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  ngOnDestroy() {
    clearInterval(this.checkUpload);
    this.subs.unsubscribe();
  }

}
