import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AvaliacaoApiService } from './api/avaliacaoApi.service';
import { AuditoriaApiService } from './api/auditoriaApi.service';
import { CargoApiService } from './api/cargoApi.service';
import { CienciaApiService } from './api/cienciaApi.service';
import { ClienteApiService } from './api/clienteApi.service';
import { FavoritosApiService } from './api/favoritosApi.service';
import { LoginService } from './api/login.service';
import { MensagensApiService } from './api/mensagensApi.service';
import { RegisterService } from './api/register.service';
import { ServicoApiService } from './api/servicoApi.service';
import { TipoServicoApiService } from './api/tipoServicoApi.service';
import { Configuration } from './configuration';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AvaliacaoApiService,
    AuditoriaApiService,
    CargoApiService,
    CienciaApiService,
    ClienteApiService,
    FavoritosApiService,
    LoginService,
    MensagensApiService,
    RegisterService,
    ServicoApiService,
    TipoServicoApiService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
