import { Injectable } from '@angular/core';
import { LucorApiModelsPessoaJuridica } from '../../model/lucorApiModelsPessoaJuridica';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ClienteApiService } from 'app/api/clienteApi.service';

@Injectable({
  providedIn: 'root'
})
export class ClientesResolver implements Resolve<LucorApiModelsPessoaJuridica> {

  constructor(private clientesService: ClienteApiService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LucorApiModelsPessoaJuridica> {
    if (route.params && route.params[`Id`]) {
      return this.clientesService.getClienteById(route.params[`Id`]);
    }
  }

}
