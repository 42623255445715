import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/guards/auth.guard';
import { LoginComponent } from './auth/containers/login/login.component';
import { HomeComponent } from 'app/modules/home/home.component';
import { PageErrorComponent } from 'app/modules/page-error/page-error.component';
import { AuditoriaLogsComponent } from 'app/modules/auditoria/components/auditoria-logs/auditoria-logs.component';

const appRoutes: Routes = [
  { path: '', 
    pathMatch: 'full', 
    redirectTo: 'login' },
  { path: 'login',
    component: LoginComponent },
  { path: 'home',
    component: HomeComponent, 
    canActivate: [AuthGuard] },
  { path: 'auditoria',
    component: AuditoriaLogsComponent, 
    canActivate: [AuthGuard],
    data: { breadcrumbs: 'Auditoria' } },
  { path: 'page-error',
    component: PageErrorComponent, 
    canActivate: [AuthGuard] },
  { path: '**', 
    redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

