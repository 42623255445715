/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.45
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { MicrosoftNetHttpHeadersEntityTagHeaderValue } from './microsoftNetHttpHeadersEntityTagHeaderValue';


export interface MicrosoftAspNetCoreMvcFileContentResult {
    fileContents?: string;
    readonly contentType?: string;
    fileDownloadName?: string;
    lastModified?: Date;
    entityTag?: MicrosoftNetHttpHeadersEntityTagHeaderValue;
    enableRangeProcessing?: boolean;
}
