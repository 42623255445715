import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { LucorApiModelsDetailsCargo } from 'app/model/lucorApiModelsDetailsCargo';
import { LucorApiModelsCiencia } from 'app/model/lucorApiModelsCiencia';

@Component({
  selector: 'app-cargos-treinamentos',
  templateUrl: './cargos-treinamentos.component.html',
  styleUrls: ['./cargos-treinamentos.component.css'],
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CargosTreinamentosComponent implements OnInit {

  @Input() cargoDetalhe: LucorApiModelsDetailsCargo;
  @Input() countAvaliados: number;
  @Input() avaliadosCargoAxiologiaCalculo: Array<any>;
  @Input() avaliadosCargoComportamentoCalculo: Array<any>;
  @Input() avaliadosCargoMotivadoresCalculo: Array<any>;
  @Input() ciencias$: Observable<LucorApiModelsCiencia[]>;

  idCargo: number;
  idCliente: number;

  order = 'diferenca';
  reverse = false;

  constructor() {}

  ngOnInit() {
    if (this.cargoDetalhe) {
      this.idCargo = this.cargoDetalhe.id;
      this.idCliente = this.cargoDetalhe.pessoaJuridicaId;
    }
  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

  customComparator(itemA, itemB) { // Adicionado para customizar a função 'comparator' do OrderBy
    return  itemA - itemB; 
  }

  closeModalMedia(id: string) {
    document.getElementById(id).classList.remove('open-list');
  }

  openModalMedia(id: string) {
    document.getElementById(id).classList.add('open-list');
  }

}
