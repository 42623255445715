import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { CookieService } from '@ngx-toolkit/cookie';
import Driver from 'driver.js';
import { LucorApiModelsAvaliacao } from 'app/model/lucorApiModelsAvaliacao';

@Component({
  selector: 'app-avaliacoes-comparar',
  templateUrl: './avaliacoes-comparar.component.html',
  styleUrls: ['./avaliacoes-comparar.component.css']
})

export class AvaliacoesCompararComponent implements OnInit {

  avaliacoes: LucorApiModelsAvaliacao[];
  avaliacaoComparar: LucorApiModelsAvaliacao;

  @Input() avaliacaoById: LucorApiModelsAvaliacao;
  @Input() avaliadosIds: Array<number>;

  @Output() mostraComparar = new EventEmitter();
  @Output() removeIdAvaliadoComparar = new EventEmitter();
  @Output() selectAllChecked = new EventEmitter();

  selectAllFatoresChecked = false;
  nomeAvaliadoComparar: Array<any> = new Array<any>(); // Label dos Gráficos

  verificaMotivadores = false;
  verificaAxiologia = false;
  verificaComportamento = false;

  checkCookie = false;
  disableTour = false;
  driver = new Driver({
    className: 'tour-lucor',
    doneBtnText: 'Entendi', // Text on the final button
    closeBtnText: 'Fechar', // Text on the close button for this step
    nextBtnText: 'Próximo', // Next button text for this step
    prevBtnText: 'Anterior', // Previous button text for this step
  });

  searchText = '';

  excluindo = false; // Exibe o loading enquanto remove um avaliado através da função getRemoveAvaliado(id)

   /*
  * Gráficos
  */

  labelNomeAvaliado: Array<any> = new Array<any>(); // Label dos Gráficos
  selectAxiologia: Array<any> = new Array<any>(); // Array com todos os fatores
  selectFiltroAxiologia: Array<any> = new Array<any>(); // Novo array para a seleção de Fatores para o Gráfico
  chartDataAxiFatores: Array<any> = new Array<any>();
  chartDataAxiRanking: Array<any> = new Array<any>();
  colorAxiFatores: Array<any> = new Array<any>();
  colorAxiFatoresBar: Array<any> = new Array<any>();

  valorDomNatural: any | null;
  valorDomAdaptavel: any | null;
  valorInfNatural: any | null;
  valorInfAdaptavel: any | null;
  valorEstNatural: any | null;
  valorEstAdaptavel: any | null;
  valorCauNatural: any | null;
  valorCauAdaptavel: any | null;

  valorAltruista: any | null;
  valorEconomico: any | null;
  valorEstetica: any | null;
  valorIndividualista: any | null;
  valorPolitico: any | null;
  valorRegulador: any | null;
  valorTeorico: any | null;

  firstChartDataDom: Array<any> = new Array<any>();
  secondChartDataDom: Array<any> = new Array<any>();
  firstChartDataInf: Array<any> = new Array<any>();
  secondChartDataInf: Array<any> = new Array<any>();
  firstChartDataEst: Array<any> = new Array<any>();
  secondChartDataEst: Array<any> = new Array<any>();
  firstChartDataCau: Array<any> = new Array<any>();
  secondChartDataCau: Array<any> = new Array<any>();
  firstColorDataDom: Array<any> = new Array<any>();
  firstColorDataInf: Array<any> = new Array<any>();
  firstColorDataEst: Array<any> = new Array<any>();
  firstColorDataCau: Array<any> = new Array<any>();
  secondColorDataDom: Array<any> = new Array<any>();
  secondColorDataInf: Array<any> = new Array<any>();
  secondColorDataEst: Array<any> = new Array<any>();
  secondColorDataCau: Array<any> = new Array<any>();

  firstChartDataMot: Array<any> = new Array<any>();
  secondChartDataMot: Array<any> = new Array<any>();
  motivadorOpcao01 = 'Econômico';
  motivadorOpcao02 = 'Político';
  colorOpcao01: Array<any> = new Array<any>();
  colorOpcao02: Array<any> = new Array<any>();
  labelOpcao01;
  labelOpcao02;

  firstChartDataAxiMedias: Array<any> = new Array<any>();
  firstChartLabelsAxiMedias: Array<any> = new Array<any>();
  secondChartDataAxiMedias: Array<any> = new Array<any>();
  secondChartLabelsAxiMedias: Array<any> = new Array<any>();
  thirdChartDataAxiMedias: Array<any> = new Array<any>();
  thirdChartLabelsAxiMedias: Array<any> = new Array<any>();
  firstColorAxiMedias: Array<any> = new Array<any>();
  secondColorAxiMedias: Array<any> = new Array<any>();
  thirdColorAxiMedias: Array<any> = new Array<any>();

  /*
  * Gráficos Bar
  */

  // Gráfico Disc Dominância
  public chartTypeDiscDom = 'bar';

  public chartDatasetsDiscDom: Array<any> = [
    { data: this.firstChartDataDom, label: 'Dominância Natural' },
    { data: this.secondChartDataDom, label: 'Dominância Adaptado' }
  ];

  public chartLabelsDiscDom: Array<any> =
    this.labelNomeAvaliado;

  public chartColorsDiscDom: Array<any> = [
    {
      borderColor: [],
      backgroundColor: this.firstColorDataDom,
      borderWidth: 0,
    },
    {
      borderColor: [],
      backgroundColor: this.secondColorDataDom,
      borderWidth: 0,
    }
  ];

  public chartOptionsDiscDom: any = {
    responsive: true
  };

  // Gráfico Disc Influência
  public chartTypeDiscInf = 'bar';

  public chartDatasetsDiscInf: Array<any> = [
    { data: this.firstChartDataInf, label: 'Influência Natural' },
    { data: this.secondChartDataInf, label: 'Influência Adaptado' }
  ];

  public chartLabelsDiscInf: Array<any> =
    this.labelNomeAvaliado;

  public chartColorsDiscInf: Array<any> = [
    {
      borderColor: [],
      backgroundColor: this.firstColorDataInf,
      borderWidth: 0,
    },
    {
      borderColor: [],
      backgroundColor: this.secondColorDataInf,
      borderWidth: 0,
    }
  ];

  public chartOptionsDiscInf: any = {
    responsive: true
  };

  // Gráfico Disc Estabilidade
  public chartTypeDiscEst = 'bar';

  public chartDatasetsDiscEst: Array<any> = [
    { data: this.firstChartDataEst, label: 'Estabilidade Natural' },
    { data: this.secondChartDataEst, label: 'Estabilidade Adaptado' }
  ];

  public chartLabelsDiscEst: Array<any> =
    this.labelNomeAvaliado;

  public chartColorsDiscEst: Array<any> = [
    {
      borderColor: [],
      backgroundColor: this.firstColorDataEst,
      borderWidth: 0,
    },
    {
      borderColor: [],
      backgroundColor: this.secondColorDataEst,
      borderWidth: 0,
    }
  ];

  public chartOptionsDiscEst: any = {
    responsive: true
  };

  // Gráfico Disc Cautela
  public chartTypeDiscCau = 'bar';

  public chartDatasetsDiscCau: Array<any> = [
    { data: this.firstChartDataCau, label: 'Cautela Natural' },
    { data: this.secondChartDataCau, label: 'Cautela Adaptado' }
  ];

  public chartLabelsDiscCau: Array<any> =
    this.labelNomeAvaliado;

  public chartColorsDiscCau: Array<any> = [
    {
      borderColor: [],
      backgroundColor: this.firstColorDataCau,
      borderWidth: 0,
    },
    {
      borderColor: [],
      backgroundColor: this.secondColorDataCau,
      borderWidth: 0,
    }
  ];

  public chartOptionsDiscCau: any = {
    responsive: true
  };


  // Gráfico Motivadores
  public chartTypeMotivadores = 'bar';

  public chartDatasetsMotivadores: Array<any> = [
    { data: [20, 20, 20, 20], label: 'Valores Motivadores' },
    { data: [10, 10, 10, 10], label: 'Valores Motivadores' }
  ];

  public chartLabelsMotivadores: Array<any> =
    this.labelNomeAvaliado;

  public chartColorsMotivadores: Array<any> = [
    {
      borderColor: [],
      backgroundColor: this.colorOpcao01,
      borderWidth: 0,
    },
    {
      borderColor: [],
      backgroundColor: this.colorOpcao02,
      borderWidth: 0,
    }
  ];

  public chartOptionsMotivadores: any = {
    responsive: true
  };


  // Gráfico Axiologia - Médias das Competências
  public chartTypeAxiMedias = 'bar';

  public chartDatasetsAxiMedias: Array<any> = [
    { data: this.firstChartDataAxiMedias, label: 'Baixas' },
    { data: this.secondChartDataAxiMedias, label: 'Medianas' },
    { data: this.thirdChartDataAxiMedias, label: 'Altas' }
  ];

  public chartLabelsAxiMedias: Array<any> =
    this.labelNomeAvaliado;

  public chartColorsAxiMedias: Array<any> = [
    {
      borderColor: [],
      backgroundColor: this.firstColorAxiMedias,
      borderWidth: 0,
    },
    {
      borderColor: [],
      backgroundColor: this.secondColorAxiMedias,
      borderWidth: 0,
    }
    ,
    {
      borderColor: [],
      backgroundColor: this.thirdColorAxiMedias,
      borderWidth: 0,
    }
  ];

  public chartOptionsAxiMedias: any = {
    responsive: true
  };


  // Gráfico Axiologia - Fatores
  public typeChartAxiFatores = 'radar';
  public chartTypeAxiFatores: string = this.typeChartAxiFatores;

  public chartDatasetsAxiFatores = this.chartDataAxiFatores;

  public chartLabelsAxiFatores: Array<any> = [];

  public chartColorsAxiFatores = this.colorAxiFatores;

  public chartOptionsAxiFatores: any = {
    responsive: true
  };


  // Gráfico Axiologia - Ranking
  public chartTypeAxiRanking = 'bar';

  public chartDatasetsAxiRanking: Array<any> = [];

  public chartLabelsAxiRanking: Array<any> =
    this.labelNomeAvaliado;

  public chartColorsAxiRanking: Array<any> = [
      {
        borderColor: [],
        backgroundColor: 'rgba(105, 0, 132, 1)',
        borderWidth: 0,
      },
  ];

  public chartOptionsAxiRanking: any = {
    responsive: true
  };


  /*
   * -- comum aos gráficos
  */
  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }


  /*
   * #Graficos
  */

  constructor( private cookieService: CookieService ) {}

  ngOnInit() {
    this.checkCookie = this.cookieService.hasItem('tourAvaComparar');
    if (!this.checkCookie) {
      this.startTour();
      this.setCookie();
    }
    this.goTop();
    this.getAvaliacaoById();
  }

  startTour(): void {
    this.disableTour = true;
    // Define the steps for tour
    setTimeout(() => {
      this.driver.defineSteps([
        {
          element: '#one-element-tour',
          popover: {
            className: 'first-step-popover-class',
            title: 'Vamos fazer um tour?',
            description: 'Você pode usar as teclas <i class="fas fa-caret-left icon-tour"></i> e <i class="fas fa-caret-right icon-tour"></i> para avançar e voltar. Acesse aqui sempre que precisar.',
            position: 'left',
            closeBtnText: 'Eu vejo depois.',
            nextBtnText: 'Vamos lá!',
            prevBtnText: '<i class="fas fa-plane-departure"></i>'
          }
        },
        {
          element: '#two-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Botão "Voltar"',
            description: 'Atalho para voltar para a lista dos avaliados.',
            position: 'right'
          }
        },
        {
          element: '#three-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Comparando',
            description: 'Quantas e quais são as pessoas que estão sendo comparadas. Você pode clicar no "X" vermelho ao lado do nome para remover a pessoa desta análise.',
            position: 'bottom'
          }
        },
        {
          element: '#four-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Comportamento Observado',
            description: 'Esta ciência nos permite conhecer o <strong>estilo comportamental</strong> de cada respondente no seu <strong>estilo natural</strong> (inconsciente) e no seu <strong>estilo adaptável</strong> (consciente).<br>Você pode ver mais detalhes sobre esta ciência indo até o menu, na aba "Ciências".',
            position: 'top'
          }
        },
        {
          element: '#five-element-tour',
          popover: {
            title: 'Gráfico',
            description: 'Cada gráfico mostra a comparação de um fator da ciência, neste caso, em seus dois estilos: Natural e Adaptado. Você pode clicar no ícone no canto superior direito da tabela para ampliar a visualização.',
            position: 'right'
          }
        },
        {
          element: '#six-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Motivadores',
            description: 'Esta ciência nos permite conhecer o que gera motivação em cada respondente. Aqui é o comportamento <strong>semi-observável</strong>.<br>Você pode ver mais detalhes sobre esta ciência indo até o menu, na aba "Ciências".',
            position: 'top'
          }
        },
        {
          element: '#seven-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Selecionar as dimensões',
            description: 'Aqui você pode escolher entre duas dimensões para comparar lado a lado. O gráfico é atualizado em tempo real.',
            position: 'bottom'
          }
        },
        {
          element: '#eight-element-tour',
          stageBackground: '#7a7a7a',
          popover: {
            title: 'Gráfico',
            description: 'O gráfico mostra os avaliados lado a lado, exibindo os dois fatores selecionados.',
            position: 'top'
          }
        },
        {
          element: '#nine-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Axiologia',
            description: 'É a ciência do estudo de como as pessoas pensam. Como comparam e determinam valor para decisões e indivíduos.<br>Você pode ver mais detalhes sobre esta ciência indo até o menu, na aba "Ciências".',
            position: 'bottom'
          }
        },
        {
          element: '#ten-element-tour',
          stageBackground: '#7a7a7a',
          popover: {
            title: 'Ranking',
            description: 'Este ranking tem como base a média final das competências de cada avaliado.',
            position: 'top'
          }
        },
        {
          element: '#eleven-element-tour',
          stageBackground: '#7a7a7a',
          popover: {
            title: 'Tabela de Médias',
            description: 'Esta tabela mostra a disposição das médias do avaliado, considerando as "Altas, Médias e Baixas".',
            position: 'top'
          }
        },
        {
          element: '#twelve-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Valores das Competências',
            description: 'Abaixo você tem as médias de todos os fatores.',
            position: 'top'
          }
        },
        {
          element: '#thirteen-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Campo de pesquisa',
            description: 'Você pode fazer uma pesquisa pelo nome do fator ou palavra relacionada.',
            position: 'top'
          }
        },
        {
          element: '#tableSortAxiologia',
          popover: {
            title: 'Tabela',
            description: 'Nesta tabela estão listados todos os fatores desta ciência e suas respectivas médias.',
            position: 'top'
          }
        },
        {
          element: '#fiveteen-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Checkbox para seleção',
            description: 'Você pode selecionar alguns fatores e visualizar o resultado em um gráfico, que será atualizado logo após esta tabela. Ao selecionar algum fator um botão irá aparecer para que você possa atualizar o gráfico.',
            position: 'top'
          }
        },
        {
          element: '#sixteen-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Nome do Fator',
            description: 'Você pode selecionar o fator clicando no checkbox ao lado.',
            position: 'top'
          }
        },
        {
          element: '#seventeen-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Nome do Avaliado',
            description: 'Os nomes estão distribuidos lado a lado para facilitar a comparação.',
            position: 'top'
          }
        },
        {
          element: '#eighteen-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Média',
            description: 'Na mesma coluna do nome estão as médias de cada fator.',
            position: 'top'
          }
        },
        {
          element: '#nineteen-element-tour',
          stageBackground: '#7a7a7a',
          popover: {
            title: 'Gráfico dos fatores',
            description: 'Você pode alterar a visualização deste gráfico selecionando fatores específicos na tabela acima. Você também pode "apagar" um nome de avaliado se clicar sobre ele na legenda do gráfico.',
            position: 'top'
          }
        }
      ]);
      // Start the introduction
      this.driver.start();
      this.disableTour = false;
    }, 1000);
  }

  setCookie() {
    this.cookieService.setItem( 'tourAvaComparar', '1' );
  }

  getAvaliacaoById(): void {
    this.avaliacaoComparar = this.avaliacaoById;
    this.getNomesComparar();
    this.getGraficoDisc();
    this.getGraficoMotivadores(this.motivadorOpcao01, this.motivadorOpcao02);
    this.getFatoresAxiologia();
   this.getClassificacaoAxiologia();
         
    setTimeout(() => {
      this.excluindo = false; // reset da variável após um avaliado ser excluído da comparação através da função getRemoveAvaliado(id)
    }, 1500);
  }

  getNomesComparar() {

    this.nomeAvaliadoComparar = [];
    this.labelNomeAvaliado = [];

    this.avaliacaoComparar.avaliados.forEach(avaliado => {
      this.avaliadosIds.forEach(aIds => {
        if (avaliado.id === aIds) {
          this.nomeAvaliadoComparar.push({ nomeAvaliado: avaliado.nome, idAvaliado: avaliado.id });
          this.labelNomeAvaliado.push(avaliado.nome);
        }
      });
    });
  }

  getGraficoDisc() {

    this.firstChartDataDom = [];
    this.firstChartDataInf = [];
    this.firstChartDataEst = [];
    this.firstChartDataCau = [];

    this.secondChartDataDom = [];
    this.secondChartDataInf = [];
    this.secondChartDataEst = [];
    this.secondChartDataCau = [];

    if (this.avaliacaoComparar) {

      this.avaliacaoComparar.avaliados.forEach(avaliado => {
        this.avaliadosIds.forEach(aIds => {
        if (avaliado.id === aIds) {
          // this.nomeAvaliadoComparar.push(avaliado.nome);
          avaliado.resultadosCiencias.forEach(ciencia => {
            // Comportamento Observado
            if (ciencia.ciencia === 'Comportamento Observado') {
              this.verificaComportamento = true;
              ciencia.resultados.forEach(nomeFator => {
                if (nomeFator.nomeFator === 'DISC NATURAL - Dominância') {
                  this.valorDomNatural = nomeFator.valor;
                  this.firstChartDataDom.push(this.valorDomNatural);
                  this.firstColorDataDom.push('rgba(244, 67, 54, 1)');
                }
                if (nomeFator.nomeFator === 'DISC ADAPTÁVEL - Dominância') {
                  this.valorDomAdaptavel = nomeFator.valor;
                  this.secondChartDataDom.push(this.valorDomAdaptavel);
                  this.secondColorDataDom.push('rgba(244, 67, 54, 0.5)');
                }
                if (nomeFator.nomeFator === 'DISC NATURAL - Influência') {
                  this.valorInfNatural = nomeFator.valor;
                  this.firstChartDataInf.push(this.valorInfNatural);
                  this.firstColorDataInf.push('rgba(255, 235, 59, 1)');
                }
                if (nomeFator.nomeFator === 'DISC ADAPTÁVEL - Influência') {
                  this.valorInfAdaptavel = nomeFator.valor;
                  this.secondChartDataInf.push(this.valorInfAdaptavel);
                  this.secondColorDataInf.push('rgba(255, 235, 59, 0.5)');
                }
                if (nomeFator.nomeFator === 'DISC NATURAL - Estabilidade') {
                  this.valorEstNatural = nomeFator.valor;
                  this.firstChartDataEst.push(this.valorEstNatural);
                  this.firstColorDataEst.push('rgba(0, 200, 83, 1)');
                }
                if (nomeFator.nomeFator === 'DISC ADAPTÁVEL - Estabilidade') {
                  this.valorEstAdaptavel = nomeFator.valor;
                  this.secondChartDataEst.push(this.valorEstAdaptavel);
                  this.secondColorDataEst.push('rgba(0, 200, 83, 0.5)');
                }
                if (nomeFator.nomeFator === 'DISC NATURAL - Cautela') {
                  this.valorCauNatural = nomeFator.valor;
                  this.firstChartDataCau.push(this.valorCauNatural);
                  this.firstColorDataCau.push('rgba(3, 169, 244, 1)');
                }
                if (nomeFator.nomeFator === 'DISC ADAPTÁVEL - Cautela') {
                  this.valorCauAdaptavel = nomeFator.valor;
                  this.secondChartDataCau.push(this.valorCauAdaptavel);
                  this.secondColorDataCau.push('rgba(3, 169, 244, 0.5)');
                }

              });
            }
          });
        }
        });
      });

      this.firstChartDataDom.push(0);
      this.firstChartDataInf.push(0);
      this.firstChartDataEst.push(0);
      this.firstChartDataCau.push(0);

      this.secondChartDataDom.push(0);
      this.secondChartDataInf.push(0);
      this.secondChartDataEst.push(0);
      this.secondChartDataCau.push(0);

      this.chartDatasetsDiscDom = [
        { data: this.firstChartDataDom, label: 'Dominância Natural' },
        { data: this.secondChartDataDom, label: 'Dominância Adaptado' }
      ];
      this.chartLabelsDiscDom = this.labelNomeAvaliado;

      this.chartDatasetsDiscInf = [
        { data: this.firstChartDataInf, label: 'Influência Natural' },
        { data: this.secondChartDataInf, label: 'Influência Adaptado' }
      ];
      this.chartLabelsDiscInf = this.labelNomeAvaliado;

      this.chartDatasetsDiscEst = [
        { data: this.firstChartDataEst, label: 'Estabilidade Natural' },
        { data: this.secondChartDataEst, label: 'Estabilidade Adaptado' }
      ];
      this.chartLabelsDiscEst = this.labelNomeAvaliado;

      this.chartDatasetsDiscCau = [
        { data: this.firstChartDataCau, label: 'Cautela Natural' },
        { data: this.secondChartDataCau, label: 'Cautela Adaptado' }
      ];
      this.chartLabelsDiscCau = this.labelNomeAvaliado;

    }
  }

  getGraficoMotivadores(opcao01, opcao02) {
    this.motivadorOpcao01 = opcao01;
    this.motivadorOpcao02 = opcao02;
    this.firstChartDataMot = []; // reset nos dados
    this.secondChartDataMot = []; // reset nos dados
    this.colorOpcao01 = []; // reset nos dados
    this.colorOpcao02 = []; // reset nos dados

    if (this.avaliacaoComparar) {

      this.avaliacaoComparar.avaliados.forEach(avaliados => {

      this.avaliadosIds.forEach(aIds => {

        if (avaliados.id === aIds) {
          avaliados.resultadosCiencias.forEach(resultadosCiencias => {
            // Motivadores
            if (resultadosCiencias.ciencia === 'Motivadores') {
              this.verificaMotivadores = true;
              resultadosCiencias.resultados.forEach(nomeFator => {
                // ------ Altruísta
                if (nomeFator.nomeFator === 'Altruísta' && this.motivadorOpcao01 === 'Altruísta') {
                  this.valorAltruista = nomeFator.valor;
                  this.firstChartDataMot.push(this.valorAltruista);
                  this.labelOpcao01 = nomeFator.nomeFator;
                  this.colorOpcao01.push('rgba(189, 183, 107, 1)');
                }
                if (nomeFator.nomeFator === 'Altruísta' && this.motivadorOpcao02 === 'Altruísta') {
                  this.valorAltruista = nomeFator.valor;
                  this.secondChartDataMot.push(this.valorAltruista);
                  this.labelOpcao02 = nomeFator.nomeFator;
                  this.colorOpcao02.push('rgba(189, 183, 107, 1)');
                }
                // ------ Econômico
                if (nomeFator.nomeFator === 'Econômico' && this.motivadorOpcao01 === 'Econômico') {
                  this.valorEconomico = nomeFator.valor;
                  this.firstChartDataMot.push(this.valorEconomico);
                  this.labelOpcao01 = nomeFator.nomeFator;
                  this.colorOpcao01.push('rgba(0, 250, 154, 1)');
                }
                if (nomeFator.nomeFator === 'Econômico' && this.motivadorOpcao02 === 'Econômico') {
                  this.valorEconomico = nomeFator.valor;
                  this.secondChartDataMot.push(this.valorEconomico);
                  this.labelOpcao02 = nomeFator.nomeFator;
                  this.colorOpcao02.push('rgba(0, 250, 154, 1)');
                }
                // ------ Estética
                if (nomeFator.nomeFator === 'Estética' && this.motivadorOpcao01 === 'Estética') {
                  this.valorEstetica = nomeFator.valor;
                  this.firstChartDataMot.push(this.valorEstetica);
                  this.labelOpcao01 = nomeFator.nomeFator;
                  this.colorOpcao01.push('rgba(128, 0, 128, 1)');
                }
                if (nomeFator.nomeFator === 'Estética' && this.motivadorOpcao02 === 'Estética') {
                  this.valorEstetica = nomeFator.valor;
                  this.secondChartDataMot.push(this.valorEstetica);
                  this.labelOpcao02 = nomeFator.nomeFator;
                  this.colorOpcao02.push('rgba(128, 0, 128, 1)');
                }
                // ------ Individualista
                if (nomeFator.nomeFator === 'Individualista' && this.motivadorOpcao01 === 'Individualista') {
                  this.valorIndividualista = nomeFator.valor;
                  this.firstChartDataMot.push(this.valorIndividualista);
                  this.labelOpcao01 = nomeFator.nomeFator;
                  this.colorOpcao01.push('rgba(255, 235, 59, 1)');
                }
                if (nomeFator.nomeFator === 'Individualista' && this.motivadorOpcao02 === 'Individualista') {
                  this.valorIndividualista = nomeFator.valor;
                  this.secondChartDataMot.push(this.valorIndividualista);
                  this.labelOpcao02 = nomeFator.nomeFator;
                  this.colorOpcao02.push('rgba(255, 235, 59, 1)');
                }
                // ------ Político
                if (nomeFator.nomeFator === 'Político' && this.motivadorOpcao01 === 'Político') {
                  this.valorPolitico = nomeFator.valor;
                  this.firstChartDataMot.push(this.valorPolitico);
                  this.labelOpcao01 = nomeFator.nomeFator;
                  this.colorOpcao01.push('rgba(3, 169, 244, 1)');
                }
                if (nomeFator.nomeFator === 'Político' && this.motivadorOpcao02 === 'Político') {
                  this.valorPolitico = nomeFator.valor;
                  this.secondChartDataMot.push(this.valorPolitico);
                  this.labelOpcao02 = nomeFator.nomeFator;
                  this.colorOpcao02.push('rgba(3, 169, 244, 1)');
                }
                // ------ Regulador
                if (nomeFator.nomeFator === 'Regulador' && this.motivadorOpcao01 === 'Regulador') {
                  this.valorRegulador = nomeFator.valor;
                  this.firstChartDataMot.push(this.valorRegulador);
                  this.labelOpcao01 = nomeFator.nomeFator;
                  this.colorOpcao01.push('rgba(244, 67, 54, 1)');
                }
                if (nomeFator.nomeFator === 'Regulador' && this.motivadorOpcao02 === 'Regulador') {
                  this.valorRegulador = nomeFator.valor;
                  this.secondChartDataMot.push(this.valorRegulador);
                  this.labelOpcao02 = nomeFator.nomeFator;
                  this.colorOpcao02.push('rgba(244, 67, 54, 1)');
                }
                // ------ Teórico
                if (nomeFator.nomeFator === 'Teórico' && this.motivadorOpcao01 === 'Teórico') {
                  this.valorTeorico = nomeFator.valor;
                  this.firstChartDataMot.push(this.valorTeorico);
                  this.labelOpcao01 = nomeFator.nomeFator;
                  this.colorOpcao01.push('rgba(244, 164, 96, 1)');
                }
                if (nomeFator.nomeFator === 'Teórico' && this.motivadorOpcao02 === 'Teórico') {
                  this.valorTeorico = nomeFator.valor;
                  this.secondChartDataMot.push(this.valorTeorico);
                  this.labelOpcao02 = nomeFator.nomeFator;
                  this.colorOpcao02.push('rgba(244, 164, 96, 1)');
                }

              });
            }

          });
        }
      });

      });

      this.firstChartDataMot.push(0);
      this.secondChartDataMot.push(0);

      this.chartDatasetsMotivadores = [
        { data: this.firstChartDataMot, label: this.labelOpcao01 },
        { data: this.secondChartDataMot, label: this.labelOpcao02 }
      ];

      this.chartLabelsMotivadores = this.labelNomeAvaliado;

    }

    setTimeout(() => {
      this.updateMotivadores(this.colorOpcao01, this.colorOpcao02);
    }, 0);

  }

  updateMotivadores(colorOpcao01, colorOpcao02) {

  this.chartColorsMotivadores = [
      {
        borderColor: [],
        backgroundColor: colorOpcao01,
        borderWidth: 0,
      },
      {
       borderColor: [],
       backgroundColor: colorOpcao02,
       borderWidth: 0,
     }
    ];
  }

  getFatoresAxiologia() {

      let selectedFator = false;
      let selectFatoresAxiologia: Array<any> = new Array<any>();

      if (this.selectAxiologia.length > 0 && this.selectFiltroAxiologia.length > 0) {
      this.verificaAxiologia = true;

      this.selectAxiologia.forEach(selecionado => {
        selecionado.avaliados.forEach(avaliado => {

          this.avaliadosIds.forEach(aIds => {
            if (avaliado.idAvaliado === aIds) {
              // selectFatoresAxiologia = [];
              selectFatoresAxiologia.push({ // Cria um novo array com as variáveis necessárias.
                nomeFator: selecionado.nomeFator,
                idFator: selecionado.idFator,
                selected: selecionado.selected,
                nomeAvaliado: avaliado.nomeAvaliado,
                idAvaliado: avaliado.idAvaliado,
                valor: avaliado.valor
              });
            }
          });

        });

      });

    } else {

      if (this.avaliacaoComparar) {
          this.avaliacaoComparar.avaliados.forEach(avaliado => {

            avaliado.resultadosCiencias.forEach(ciencia => {
            if (ciencia.ciencia === 'Axiologia') {
              this.verificaAxiologia = true;
              ciencia.resultados.forEach(fator => {
                if (fator.nomeFator !== 'Externo - Consistência'
                 && fator.nomeFator !== 'Interno - Consistência'
                 && fator.nomeFator !== 'Média das Competências'
                 && fator.nomeFator !== 'Ranking') {
                  if (avaliado.id) {
                    this.avaliadosIds.forEach(aIds => {
                      if (avaliado.id === aIds) {
                        selectFatoresAxiologia.push({ // Cria um novo array com as variáveis necessárias.
                          nomeFator: fator.nomeFator,
                          idFator: fator.fatorId,
                          selected: selectedFator,
                          nomeAvaliado: avaliado.nome,
                          idAvaliado: avaliado.id,
                          valor: fator.valor
                        });
                      }
                    });
                   }
                  }
                });
              }
            });
          });

        }

    }

      // O filtro a seguir para cria um novo array (selectAxiologia) mesclando os valores de acordo com o nome do Fator
      /* Retorna objetos como o exemplo abaixo:
      ----
      nomeFator: "Autoconfiança"
      idFator: 18
      selected: false
      avaliados:
      0: {nomeAvaliado: "Flávio Rafael  Cardoso ", idAvaliado: 27, valor: "83.100"}
      1: {nomeAvaliado: "FLÁVIO CANDIDO", idAvaliado: 29, valor: "28.600"}
      2: {nomeAvaliado: "Cristiane S. Bussioli Jorge", idAvaliado: 30, valor: "79.800"}
      3: {nomeAvaliado: "annelise porto", idAvaliado: 37, valor: "77.900"}
      ----
      */
      this.selectAxiologia = []; // reinicia o filtro ao excluir algum avaliado.

      selectFatoresAxiologia.forEach(e => {
        let fatorIgual = false;
        this.selectAxiologia.forEach(s => {
          if (this.selectAxiologia && e.nomeFator === s.nomeFator) {
            s.avaliados.push({
              nomeAvaliado: e.nomeAvaliado,
              idAvaliado: e.idAvaliado,
              valor: e.valor
              });
            fatorIgual = true;
          }
        });
        if (!fatorIgual) {
          this.selectAxiologia.push({
            nomeFator: e.nomeFator,
            idFator: e.idFator,
            selected: e.selected,
            avaliados: [{
              nomeAvaliado: e.nomeAvaliado,
              idAvaliado: e.idAvaliado,
              valor: e.valor
            }]
          });
        }
      });

      this.selectFiltroAxiologia = this.selectAxiologia.filter(f => {
        if (f.selected === true) {
          return f;
        }
      });

      this.getGraficoAxiFatores();
      this.getGraficoAxiRanking();

}

  onSelectFatores(event) {

    if (this.selectAxiologia) {
      this.selectAxiologia.forEach(x => {
        if (x.idFator === +event.target.value) {
          x.selected = event.target.checked;
        }
      });
      this.selectFiltroAxiologia = this.selectAxiologia.filter(f => {
        if (f.selected === true) {
          return f;
        }
      });

    }

  }

  onSelectAllFatores(event) {

    if (this.selectAxiologia) {
      this.selectAxiologia.forEach(x => {
        if (x) {
          x.selected = event.target.checked;
          this.selectAllFatoresChecked = event.target.checked;
        }
      });
      this.selectFiltroAxiologia = this.selectAxiologia.filter(f => {
        if (f.selected === true) {
          return f;
        }
      });
    }

  }

  getGraficoAxiFatores() {

    let labelsAxiFatores: Array<any> = new Array<any>();

    if (this.selectFiltroAxiologia.length > 0) { // Verifica se existe algum fator selecionado

      this.chartDataAxiFatores = []; // reinicia a variável

      this.selectFiltroAxiologia.forEach(s => {
        let fatorIgual = false;
        s.avaliados.forEach(a => {
          this.chartDataAxiFatores.forEach(c => {
            if (this.chartDataAxiFatores && a.nomeAvaliado === c.label) {
              c.data.push(
                a.valor
                );
              fatorIgual = true;

              let cor = 'rgb(' + (Math.floor(Math.random() * 256)) +
                        ',' + (Math.floor(Math.random() * 256)) +
                          ',' + (Math.floor(Math.random() * 256)) + ',' + 0.7 + ')';

              this.colorAxiFatores.push({
                backgroundColor: 'rgba(105, 0, 132, 0)',
                borderColor: cor,
                borderWidth: 2,
              });
              this.colorAxiFatoresBar.push({
                backgroundColor: cor,
                borderColor: cor,
                borderWidth: 2,
              });
            }
          });

          if (!fatorIgual) {
            this.chartDataAxiFatores.push({
              data: [a.valor],
              label: a.nomeAvaliado
          });
      }
       });
        if (s.nomeFator) {
          labelsAxiFatores.push(s.nomeFator);
        // idealChartDataAxiFatores.push(80);
        // minimoChartDataAxiFatores.push(60);
        }
});

    } else { // Se não houver fator selecionado carrega toda a lista

      this.chartDataAxiFatores = []; // reinicia a variável

      this.selectAxiologia.forEach(s => {

          let fatorIgual = false; // Inicia com false para criar o chartDataAxiFatores

          s.avaliados.forEach(a => {
            this.chartDataAxiFatores.forEach(c => {
              if (this.chartDataAxiFatores && a.nomeAvaliado === c.label) {
                c.data.push(
                  a.valor
                  );
                fatorIgual = true;

                let cor = 'rgb(' + (Math.floor(Math.random() * 256)) +
                        ',' + (Math.floor(Math.random() * 256)) +
                          ',' + (Math.floor(Math.random() * 256)) + ',' + 0.7 + ')';

                this.colorAxiFatores.push({
                  backgroundColor: 'rgba(105, 0, 132, 0)',
                  borderColor: cor,
                  borderWidth: 2,
                });
                this.colorAxiFatoresBar.push({
                  backgroundColor: cor,
                  borderColor: cor,
                  borderWidth: 2,
                });
              }
            });

            if (!fatorIgual) {
              this.chartDataAxiFatores.push({
                data: [a.valor],
                label: a.nomeAvaliado
              });
            }
         });
          if (s.nomeFator) {
            labelsAxiFatores.push(s.nomeFator);
          // idealChartDataAxiFatores.push(80);
          // minimoChartDataAxiFatores.push(60);
          }
});
    }

    this.chartDatasetsAxiFatores = this.chartDataAxiFatores; // Valores para o gráfico

    this.chartLabelsAxiFatores = labelsAxiFatores; // Fatores selecionados;

    // this.chartColorsAxiFatores = this.colorAxiFatores; // Cores randomicas

    if (this.typeChartAxiFatores === 'bar') {
      this.chartColorsAxiFatores = this.colorAxiFatoresBar;
    } else {
      this.chartColorsAxiFatores = this.colorAxiFatores;
    }

  }


  getUpdateGraficoFatores() {

    if (this.selectFiltroAxiologia.length <= 2 && this.selectFiltroAxiologia.length > 0) {
      this.typeChartAxiFatores = 'bar';
    } else {
      this.typeChartAxiFatores = 'radar';
    }

    this.chartTypeAxiFatores = this.typeChartAxiFatores;
    this.chartDatasetsAxiFatores = this.chartDataAxiFatores;

    if (this.typeChartAxiFatores === 'bar') {
      this.chartColorsAxiFatores = this.colorAxiFatoresBar;
    } else {
      this.chartColorsAxiFatores = this.colorAxiFatores;
    }

    // this.getAvaliacaoById();
    this.getFatoresAxiologia();

    window.scroll({
      top: document.body.scrollHeight,
      left: 0,
      behavior: 'smooth'
    });

  }

  getGraficoAxiRanking() {
    let nomesRanking = [];
    let arrayMedia: Array<any> = new Array<any>();
    let media;

    let arrayLabel = [];
    let arrayData = [];
    let arrayOfObj;
    let sortedArrayOfObj;
    let newArrayLabel = [];
    let newArrayData = [];

    if (this.selectFiltroAxiologia.length > 0) { // Verifica se existe algum fator selecionado

      this.chartDataAxiRanking = []; // reinicia o array
      arrayMedia = []; // reinicia o array
      nomesRanking = [];

      this.selectFiltroAxiologia.forEach(s => {
        let fatorIgual = false;
        s.avaliados.forEach(a => {
          arrayMedia.forEach(c => {
            if (arrayMedia && c.nomeAvaliado === a.nomeAvaliado) {
              a.valor = Math.round(a.valor * 100) / 100;
              c.valorFator.push(
                a.valor
              );
              fatorIgual = true;
            }
           });
          if (!fatorIgual) { // cria o array de médias
            a.valor = Math.round(a.valor * 100) / 100;
            arrayMedia.push({valorFator: [a.valor], nomeAvaliado: a.nomeAvaliado});
          }
       });
    });

      arrayMedia.forEach(arr => {
         media = arr.valorFator.reduce((total, elemento) => { // soma os valores do array para calcular a média
                return total + elemento;
          }, 0);

         media = media / this.selectFiltroAxiologia.length;
         media = Math.round(media * 100) / 100;

         this.chartDataAxiRanking.push(media);

        });

    } else { // Se não houver fator selecionado carrega toda a lista

      this.chartDataAxiRanking = []; // reinicia a variável

      if (this.avaliacaoComparar) {

        this.avaliacaoComparar.avaliados.forEach(avaliado => {
          avaliado.resultadosCiencias.forEach(ciencia => {
            if (ciencia.ciencia === 'Axiologia') {
              ciencia.resultados.forEach(fator => {
                if (fator.nomeFator === 'Média das Competências') {
                  if (avaliado.id) {
                    this.avaliadosIds.forEach(aIds => {
                      if (avaliado.id === aIds) {
                          this.chartDataAxiRanking.push(
                            fator.valor
                          );
                      }
                    });
                   }
                 }
               });
            }
          });
        });
      }
    }

    // =========================== Ordenar a exibição do Ranking


    arrayLabel = this.labelNomeAvaliado;
    arrayData = this.chartDataAxiRanking;

    arrayOfObj = arrayLabel.map((d, i) => {
      return {
        label: d,
        data: arrayData[i] || 0
      };
    });

    sortedArrayOfObj = arrayOfObj.sort((a, b) => {
      return  b.data - a.data;
    });

    newArrayLabel = [];
    newArrayData = [];
    sortedArrayOfObj.forEach((d) => {
      newArrayLabel.push(d.label);
      newArrayData.push(d.data);
    });

    // ===========================

    this.chartDataAxiRanking = newArrayData;
    this.chartLabelsAxiRanking = newArrayLabel;

    this.chartDataAxiRanking.push(0);

    this.chartDatasetsAxiRanking = [
      { data: this.chartDataAxiRanking, label: 'Média das Competências' }
    ];

  }

  getClassificacaoAxiologia() {

    let pushAltas: Array<any>  = [];
    let pushMedias: Array<any>  = [];
    let pushBaixas: Array<any>  = [];

    let contagemBaixas: Array<any> = [];
    let contagemMedias: Array<any> = [];
    let contagemAltas: Array<any> = [];

    if (this.avaliacaoComparar) {
   
      this.avaliacaoComparar.avaliados.forEach(avaliado => {

          avaliado.resultadosCiencias.forEach(ciencia => {
            if (ciencia.ciencia === 'Axiologia') {

              this.avaliadosIds.forEach(n => {

              if (avaliado.id === n) {

              ciencia.resultados.forEach(nomeFator => {
                if (nomeFator.nomeFator !== 'Média das Competências' && nomeFator.nomeFator !== 'Ranking') {

                      if (nomeFator.classificacao === 'Alta') {
                        pushAltas.push({ nomeFator: nomeFator.nomeFator, idAvaliado: avaliado.id });
                      }

                      if (nomeFator.classificacao === 'Mediana') {
                        pushMedias.push({ nomeFator: nomeFator.nomeFator, idAvaliado: avaliado.id });
                      }

                      if (nomeFator.classificacao === 'Baixa') {
                        pushBaixas.push({ nomeFator: nomeFator.nomeFator, idAvaliado: avaliado.id });
                      }

                      // Verifica o id que não possui alguma das classificações e adiciona undefined para gerar o valor '0' no gráfico
                      // Isso é necessário para que haja quebra no index dos Ids, gerando erro na distribuição dos valores
                      let resultBaixas = pushBaixas.find( ausente => ausente.idAvaliado === n );
                      if (resultBaixas === undefined) {
                        pushBaixas.push({ nomeFator: undefined, idAvaliado: n });
                      }
                      let resultMedias = pushMedias.find( ausente => ausente.idAvaliado === n );
                      if (resultMedias === undefined) {
                        pushMedias.push({ nomeFator: undefined, idAvaliado: n });
                      }
                      let resultAltas = pushAltas.find( ausente => ausente.idAvaliado === n );
                      if (resultAltas === undefined) {
                        pushAltas.push({ nomeFator: undefined, idAvaliado: n });
                      }

                    }
              });
            }
            });
          }
        });
    });
  }

    // Une os valores de acordo com o id do avaliado
    pushBaixas.forEach(e => {
      let avaliadoIgual = false;
      contagemBaixas.forEach(b => {
      if (contagemBaixas && e.idAvaliado === b.idAvaliado) {
        if (e.nomeFator !== undefined) {
            b.fatores.push({ nomeFator: e.nomeFator });
        } else { b.fatores.push(0); }
        avaliadoIgual = true;
      }

      });
      if (!avaliadoIgual) {
        this.avaliadosIds.forEach(aIds => {
          if (e.idAvaliado === aIds) {
            if (e.nomeFator !== undefined) {
              contagemBaixas.push({
              idAvaliado: e.idAvaliado,
              fatores: [{ nomeFator: e.nomeFator }]
            });
            } else {
              contagemBaixas.push({
                idAvaliado: e.idAvaliado,
                fatores: []  // Necessário gerar esse valor vazio para o gráfico receber o valor '0'
              });
            }
          }
        });
      }
    });

    pushMedias.forEach(e => {
          let avaliadoIgual = false;
          contagemMedias.forEach(b => {
          if (contagemMedias && e.idAvaliado === b.idAvaliado) {
            if (e.nomeFator !== undefined) {
                b.fatores.push({ nomeFator: e.nomeFator });
            } else { b.fatores.push(0); }
            avaliadoIgual = true;
            }
          });
          if (!avaliadoIgual) {
            this.avaliadosIds.forEach(aIds => {
              if (e.idAvaliado === aIds) {
                if (e.nomeFator !== undefined) {
                  contagemMedias.push({
                  idAvaliado: e.idAvaliado,
                  fatores: [{ nomeFator: e.nomeFator }]
                });
                } else {
                  contagemMedias.push({
                    idAvaliado: e.idAvaliado,
                    fatores: [] // Necessário gerar esse valor vazio para o gráfico receber o valor '0'
                  });
                }
              }
            });

          }
        });

    pushAltas.forEach(e => {
      let avaliadoIgual = false;
      contagemAltas.forEach(b => {
      if (contagemAltas && e.idAvaliado === b.idAvaliado) {
        if (e.nomeFator !== undefined) {
            b.fatores.push({ nomeFator: e.nomeFator });
        } else { b.fatores.push(0); }
        avaliadoIgual = true;
        }
      });
      if (!avaliadoIgual) {
        this.avaliadosIds.forEach(aIds => {
          if (e.idAvaliado === aIds) {
            if (e.nomeFator !== undefined) {
              contagemAltas.push({
              idAvaliado: e.idAvaliado,
              fatores: [{ nomeFator: e.nomeFator }]
            });
            } else {
              contagemAltas.push({
                idAvaliado: e.idAvaliado,
                fatores: []  // Necessário gerar esse valor vazio para o gráfico receber o valor '0'
              });
            }
          }
        });
      }
    });

    this.firstChartDataAxiMedias = [];
    this.secondChartDataAxiMedias = [];
    this.thirdChartDataAxiMedias = [];

    // Gera os números e atualiza o gráfico
    contagemBaixas.forEach(c => {
      this.avaliadosIds.forEach(aIds => {
         if (c.idAvaliado === aIds) {
          this.firstChartDataAxiMedias.push(c.fatores.length);
          this.firstColorAxiMedias.push('rgba(244, 67, 54, 1)');
         }
      });
    });
    contagemMedias.forEach(c => {
      this.avaliadosIds.forEach(aIds => {
         if (c.idAvaliado === aIds) {
             this.secondChartDataAxiMedias.push(c.fatores.length);
             this.secondColorAxiMedias.push('rgba(255, 196, 0, 1)');
           }
      });
    });
    contagemAltas.forEach(c => {
      this.avaliadosIds.forEach(aIds => {
         if (c.idAvaliado === aIds) {
             this.thirdChartDataAxiMedias.push(c.fatores.length);
             this.thirdColorAxiMedias.push('rgba(0, 200, 83, 1)');
           }
      });
    });
    this.chartLabelsAxiMedias = this.labelNomeAvaliado;
    this.updateGraficoAxiMedias();
  }

  updateGraficoAxiMedias() {
    this.chartDatasetsAxiMedias = [
      { data: this.firstChartDataAxiMedias, label: 'Baixas' },
      { data: this.secondChartDataAxiMedias, label: 'Medianas' },
      { data: this.thirdChartDataAxiMedias, label: 'Altas' }
    ];
  }

  getRemoveAvaliado(id) {
    this.excluindo = true;
    this.removeIdAvaliadoComparar.emit(id);
    this.getAvaliacaoById();
  }

  setSearchTerm(term: string) {
    this.searchText = term;
  }

  getSearchTerm() {
    return this.searchText;
  }

  clearFilter() {
    this.searchText = '';
  }

  goTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  goBack(): void {
    this.mostraComparar.emit(false);
    this.selectAllChecked.emit();
  }

}
