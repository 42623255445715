import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { RegisterService } from 'app/api/register.service';
import { ClienteApiService } from 'app/api/clienteApi.service';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { LucorApiModelsPessoaJuridica } from 'app/model/lucorApiModelsPessoaJuridica';
import { LucorApiModelsNewNewUser } from 'app/model/lucorApiModelsNewNewUser';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-usuarios-cadastrar',
  templateUrl: './usuarios-cadastrar.component.html',
  styleUrls: ['./usuarios-cadastrar.component.css']
})
export class UsuariosCadastrarComponent implements OnInit, OnDestroy {

  usuario: LucorApiModelsNewNewUser;
  clientes: LucorApiModelsPessoaJuridica[];
  addForm: FormGroup;
  submitted = false;

  private subs = new SubSink();

  constructor(
    private usuariosService: RegisterService,
    private clienteService: ClienteApiService,
    private router: Router,
    private location: Location,
    private formBuilder: FormBuilder,
    private alertService: AlertModalService) { }

  ngOnInit() {
    this.getCliente() ;
    this.addForm = this.formBuilder.group({
      userName: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
      pessoaJuridicaId: ['']
    });

  }

  get f() { return this.addForm.controls; }

  getCliente() {
    this.subs.sink = this.clienteService.getClientesAsync()
    .subscribe(clientes => {
      this.clientes = clientes;
    });
  }

  cadastraUsuario(): void {
    this.submitted = true;
    if (this.addForm.invalid) { return; }
    this.subs.sink = this.usuariosService.incluirUsuarioAsync(this.addForm.value)
    .subscribe(success => {
      this.alertService.showAlertSuccess('Usuário cadastrado com sucesso!');
      this.router.navigate(['usuarios']);
    }, error => {
      console.error('Erro', error);
      this.alertService.showAlertDanger(`${error}`);
    });
  }

  goBack(): void {
    this.location.back();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }


}
