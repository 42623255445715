import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { CargoApiService } from 'app/api/cargoApi.service';
import { AvaliacaoApiService } from 'app/api/avaliacaoApi.service';
import {
  LucorApiModelsAvaliacao,
  LucorApiModelsDetailsCargo,
  LucorApiModelsNewFiltroCiencia,
  LucorApiModelsNewFiltroExportacao,
  LucorApiModelsNewPerfil,
  MicrosoftAspNetCoreMvcFileContentResult
} from 'app/model/models';
import { AlertModalService } from 'app/shared/alert-modal.service';
import Driver from 'driver.js';
import { SubSink } from 'subsink';
import { CookieService } from '@ngx-toolkit/cookie';

@Component({
  selector: 'app-avaliacoes-filtro',
  templateUrl: './avaliacoes-filtro.component.html',
  styleUrls: ['./avaliacoes-filtro.component.css']
})

export class AvaliacoesFiltroComponent implements OnInit, OnDestroy {

  avaliacao: LucorApiModelsAvaliacao;
  avaliacoes: LucorApiModelsAvaliacao[];
  resultadoFiltroServico: LucorApiModelsNewFiltroExportacao;
  cargos$: Observable<LucorApiModelsDetailsCargo[]>;
  cargoId: number;
  checkPerfisCargo: number;
  listaCargos: LucorApiModelsDetailsCargo[] = [];
  selectCargo = false;
  idAvaliacao: number;
  clienteAvaliacao: number;

  perfilCargoAxiologia$: Observable<LucorApiModelsNewPerfil[]>;
  perfilCargoComportamento$: Observable<LucorApiModelsNewPerfil[]>;
  perfilCargoMotivadores$: Observable<LucorApiModelsNewPerfil[]>;

  @ViewChild('openModalResultados') openModalResultados: ElementRef;
  @ViewChild('closeModal') closeModal: ElementRef;

  resultadoFiltroAxiologia: LucorApiModelsNewFiltroCiencia;
  resultadoFiltroComportamento: LucorApiModelsNewFiltroCiencia;
  resultadoFiltroMotivadores: LucorApiModelsNewFiltroCiencia;

  verificaResultadosCiencias: string; // Verifica se existe a ciência na avaliação
  botaoCienciaAxiologia = true; // Inicia com o botão desabilitado [disable]=true
  botaoCienciaComportamento = true; // Inicia com o botão desabilitado [disable]=true
  botaoCienciaMotivadores = true; // Inicia com o botão desabilitado [disable]=true
  mostraResultados = false;
  printWait = false;
  reverse = true;

  checkCookie = false;
  disableTour = false;
  driver = new Driver({
    className: 'tour-lucor',
    doneBtnText: 'Entendi', // Text on the final button
    closeBtnText: 'Fechar', // Text on the close button for this step
    nextBtnText: 'Próximo', // Next button text for this step
    prevBtnText: 'Anterior', // Previous button text for this step
  });

  marcaAvaliadoGrafico: Array<any> = new Array();
  checkedAvaliado = true;

  mediaGeralPorcentagem: Array<any> = new Array<any>(); // Média geral do fatores de cada ciência
  mediaFinalPorcentagem: number; // Média das 3 ciências

  /** COMPORTAMENTO OBSERVADO  */

  newAvaliacaoDisc: Array<any> = new Array<any>();
  newAvaliacaoDiscNatural: Array<any> = new Array<any>();
  newAvaliacaoDiscAdaptavel: Array<any> = new Array<any>();

  // trocaGraficoDisc: string;
  updateDataDisc: Array<any> = new Array<any>();
  aproximacaoDisc: Array<any> = new Array<any>();
  verificaAproximacaoDisc = false;
  porcentagemDisc: any;
  porcentagemAproximacaoDisc: any;
  statusDisc: string; // Exata, Alta, Média, Baixa, Muito Baixa

  // trocaGraficoDiscNatural: string;
  checkNatural = false;
  updateDataDiscNatural: Array<any> = new Array<any>();
  aproximacaoDiscNatural: Array<any> = new Array<any>();
  verificaAproximacaoDiscNatural = false;
  porcentagemDiscNatural: any;
  porcentagemAproximacaoDiscNatural: any;
  statusDiscNatural: string; // Exata, Alta, Média, Baixa, Muito Baixa

  // trocaGraficoDiscAdaptavel: string;
  checkAdaptavel = false;
  updateDataDiscAdaptavel: Array<any> = new Array<any>();
  aproximacaoDiscAdaptavel: Array<any> = new Array<any>();
  verificaAproximacaoDiscAdaptavel = false;
  porcentagemDiscAdaptavel: any;
  porcentagemAproximacaoDiscAdaptavel: any;
  statusDiscAdaptavel: string; // Exata, Alta, Média, Baixa, Muito Baixa

  /** MOTIVADORES  */

  newAvaliacaoMotivadores: Array<any> = new Array<any>();

  // trocaGraficoMotivadores: string;
  updateDataMotivadores: Array<any> = new Array<any>();
  aproximacaoMotivadores: Array<any> = new Array<any>();
  verificaAproximacaoMotivadores = false;
  porcentagemMotivadores: any;
  porcentagemAproximacaoMotivadores: any;
  statusMotivadores: string; // Exata, Alta, Média, Baixa, Muito Baixa

  /** AXIOLOGIA  */

  newAvaliacaoAxiologia: Array<any> = new Array<any>();

  // trocaGraficoAxiologia: string;
  updateDataAxiologia: Array<any> = new Array<any>();
  aproximacaoAxiologia: Array<any> = new Array<any>();
  verificaAproximacaoAxiologia = false;
  porcentagemAxiologia: any;
  porcentagemAproximacaoAxiologia: any;
  statusAxiologia: string; // Exata, Alta, Média, Baixa, Muito Baixa

  // Ranking Geral
  checkGraficoRanking = false;
  labelNomeAvaliado: Array<any> = new Array<any>(); // Label dos Gráficos
  chartDataFatores: Array<any> = new Array<any>();
  chartDataRanking: Array<any> = new Array<any>();


  /** GRÁFICOS  */

  setChartLabelsDISC: Array<any> = new Array<any>();
  setChartDatasetsDISC: Array<any> = [];

  setChartLabelsDISCNATURAL: Array<any> = new Array<any>();
  setChartDatasetsDISCNATURAL: Array<any> = [];

  setChartLabelsDISCADAPTAVEL: Array<any> = new Array<any>();
  setChartDatasetsDISCADAPTAVEL: Array<any> = [];

  // Gráfico Resultado DISC
  public chartTypeDISC = 'radar';
  public chartDatasetsDISC: Array<any> = [];
  public chartLabelsDISC: Array<any> = [];
  public chartColorsDISC: Array<any> = [];
  public chartOptionsDISC: any = {
    responsive: true,
    scale: {
      ticks: {
        beginAtZero: true,
        min: 0,
        stepSize: 10,
        suggestedMax: 100
      }
    }
  };

  
  // Gráfico Resultado DISC NATURAL
  public chartTypeDISCNATURAL = 'radar';
  public chartDatasetsDISCNATURAL: Array<any> = [];
  public chartLabelsDISCNATURAL: Array<any> = [];
  public chartColorsDISCNATURAL: Array<any> = [];
  public chartOptionsDISCNATURAL: any = {
    responsive: true,
    scale: {
      ticks: {
        beginAtZero: true,
        min: 0,
        stepSize: 10,
        suggestedMax: 100
      }
    }
  };


  // Gráfico Resultado DISC ADAPTAVEL
  public chartTypeDISCADAPTAVEL = 'radar';
  public chartDatasetsDISCADAPTAVEL: Array<any> = [];
  public chartLabelsDISCADAPTAVEL: Array<any> = [];
  public chartColorsDISCADAPTAVEL: Array<any> = [];
  public chartOptionsDISCADAPTAVEL: any = {
    responsive: true,
    scale: {
      ticks: {
        beginAtZero: true,
        min: 0,
        stepSize: 10,
        suggestedMax: 100
      }
    }
  };

  setChartLabelsMotivadores: Array<any> = new Array<any>();
  setChartDatasetsMotivadores: Array<any> = [];

  // Gráfico Resultado Motivadores
  public chartTypeMotivadores = 'radar';
  public chartDatasetsMotivadores: Array<any> = [];
  public chartLabelsMotivadores: Array<any> = [];
  public chartColorsMotivadores: Array<any> = [];
  public chartOptionsMotivadores: any = {
    responsive: true,
    scale: {
      ticks: {
        beginAtZero: true,
        min: 0,
        stepSize: 10,
        suggestedMax: 100
      }
    }
  };

  setChartLabelsAxiologia: Array<any> = new Array<any>();
  setChartDatasetsAxiologia: Array<any> = [];

  // Gráfico Resultado Axiologia
  public chartTypeAxiologia = 'radar';
  public chartDatasetsAxiologia: Array<any> = [];
  public chartLabelsAxiologia: Array<any> = [];
  public chartColorsAxiologia: Array<any> = [];
  public chartOptionsAxiologia: any = {
    responsive: true,
    scale: {
      ticks: {
        beginAtZero: true,
        min: 0,
        stepSize: 10,
        suggestedMax: 100
      }
    }
  };


  // Gráfico Ranking Geral
  public chartTypeRanking = 'bar';
  public chartDatasetsRanking: Array<any> = [];
  public chartLabelsRanking: Array<any> =
    this.labelNomeAvaliado;
  public chartColorsRanking: Array<any> = [
    {
      borderColor: [],
      backgroundColor: 'rgba(105, 0, 132, 1)',
      borderWidth: 0,
    },
  ];
  public chartOptionsRanking: any = {
    responsive: true,
    title: {
      display: true,
      text: 'Ranking Geral',
      fontSize: 18
    },
    legend: { display: false },
    scales: {
      yAxes: [{ticks: {max: 100}}]
    }

  };

  /*
 * -- comum aos gráficos
 */
  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }

  public displayLegend: boolean = true;

  private subs = new SubSink();

  constructor(
    private avaliacoesService: AvaliacaoApiService,
    private cargosService: CargoApiService,
    private alertService: AlertModalService,
    private route: ActivatedRoute,
    private location: Location,
    private cookieService: CookieService
  ) { this.idAvaliacao = +this.route.snapshot.paramMap.get('Id'); }

  ngOnInit() {
    this.getAvaliacaoById();
    this.checkCookie = this.cookieService.hasItem('tourAvaAnalise');
  }

  startTour(): void {
    this.disableTour = true;
    // Define the steps for tour
    setTimeout(() => {
      this.driver.defineSteps([
        {
          element: '#one-element-tour',
          popover: {
            className: 'first-step-popover-class',
            title: 'Vamos fazer um tour?',
            description: 'Você pode usar as teclas <i class="fas fa-caret-left icon-tour"></i> e <i class="fas fa-caret-right icon-tour"></i> para avançar e voltar. Acesse aqui sempre que precisar.',
            position: 'left',
            closeBtnText: 'Eu vejo depois.',
            nextBtnText: 'Vamos lá!',
            prevBtnText: '<i class="fas fa-plane-departure"></i>'
          }
        },
        {
          element: '#two-element-tour',
          popover: {
            title: 'Botão "Selecionar Perfil de Cargo"',
            description: 'Você pode selecionar um perfil de cargo para usá-lo como modelo na sua análise.',
            position: 'left'
          }
        },
        {
          element: '#three-element-tour',
          popover: {
            title: 'Botão "Baixar Planilha"',
            description: 'Você pode baixar uma planilha em Excel. Para baixar todos os dados da avaliação, não selecione nenhum fator das ciências. Sempre que você selecionar algo, a planilha irá ser preenchida com os dados selecionados.',
            position: 'left'
          }
        },
        {
          element: '#four-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Passo 1',
            description: 'Esta é a sequência ideal para que você possa fazer seu mapeamento. Primeiro selecione a ciência.',
            position: 'right'
          }
        },
        {
          element: '#five-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Passo 2',
            description: 'Se não tiver selecionado um cargo, você deverá marcar os fatores e selecionar os valor "Ideal" e, opcionalmente, os valores "Mínimo e Máximo".',
            position: 'right'
          }
        },
        {
          element: '#six-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Passo 3',
            description: 'Clique em "Ver Resultados".',
            position: 'right'
          }
        },
        {
          element: '#seven-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Passo 4',
            description: 'Caso você queira, poderá baixar uma planilha com o resultado dos dados que selecionou.',
            position: 'left'
          }
        },
        {
          element: '#eight-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Ciência',
            description: 'Clique nas abas para selecionar cada ciência.',
            position: 'bottom'
          }
        },
        {
          element: '#search-element-tour',
          popover: {
            title: 'Campo de pesquisa',
            description: 'Utilize para filtrar os fatores pelo nome.',
            position: 'bottom'
          }
        },
        {
          element: '#nine-element-tour',
          stageBackground: 'transparent',
          popover: {
            title: 'Botão "Ver Resultados"',
            description: 'Após selecionar as ciências e os fatores desejados, clique aqui para visualizar o resultado na tela.',
            position: 'right'
          }
        },
        {
          element: '#ten-element-tour',
          stageBackground: 'transparent',
          popover: {
            title: 'Botão "Salvar seleção"',
            description: 'Este botão salva a atual seleção. Caso você altere algo e clique aqui novamente, a atual seleção irá substituir a anterior.',
            position: 'right'
          }
        },
        {
          element: '#eleven-element-tour',
          stageBackground: 'transparent',
          popover: {
            title: 'Botão "Limpar seleção"',
            description: 'Desmarca os fatores selecionados.',
            position: 'right'
          }
        }
      ]);
      // Start the introduction
      this.driver.start();
      this.disableTour = false;
    }, 2000);
  }

  setCookie() {
    this.cookieService.setItem( 'tourAvaAnalise', '1' );
  }

  getAvaliacaoById() {
    this.subs.sink = this.avaliacoesService.getAvaliacaoByIdAsync(this.idAvaliacao)
      .subscribe(
        avaliacao => {
          this.avaliacao = avaliacao;
          this.clienteAvaliacao = avaliacao.clienteId;
          this.verificaCiencia(avaliacao);
          this.aproximacaoDisc = [];
          this.aproximacaoDiscNatural = [];
          this.aproximacaoDiscAdaptavel = [];
          this.aproximacaoMotivadores = [];
          this.aproximacaoAxiologia = [];
          this.mediaGeralPorcentagem = [];
          this.goResultados(false);
          if (this.avaliacao && this.checkCookie === false) {
            this.startTour();
            this.setCookie();
          }
        }
      );
  }

  verificaCiencia(avaliacao: LucorApiModelsAvaliacao) {

    if (avaliacao) {
      const avaliado = avaliacao.avaliados[0]; // Verifica apenas o primeiro avaliado, considerando que o padrão será igual para todos.
      avaliado.resultadosCiencias.forEach(ciencias => {
        this.verificaResultadosCiencias = ciencias.ciencia;

        switch (this.verificaResultadosCiencias) {
          case 'Axiologia': {
            this.botaoCienciaAxiologia = false; // Disabled = false
            break;
          }
          case 'Comportamento Observado': {
            this.botaoCienciaComportamento = false; // Disabled = false
            break;
          }
          case 'Motivadores': {
            this.botaoCienciaMotivadores = false; // Disabled = false
            break;
          }
        }
      });

    } else {
      this.alertService.showAlertDanger('Ops!! Algo deu errado! Por favor, tente novamente.');
    }
  }

  getCargos() {
    if (this.listaCargos.length === 0) {
      this.subs.sink = this.cargosService.listarCargosByCliente(this.clienteAvaliacao)
        .subscribe(cargos => { this.listaCargos = cargos; });
    }
  }

  getPerfisCargo() {

    forkJoin([
      this.perfilCargoAxiologia$ = this.cargosService.getPerfisCargo(this.checkPerfisCargo, 1),
      this.perfilCargoComportamento$ = this.cargosService.getPerfisCargo(this.checkPerfisCargo, 2),
      this.perfilCargoMotivadores$ = this.cargosService.getPerfisCargo(this.checkPerfisCargo, 3)
    ]).subscribe(resultado => {
 
      if (resultado[0].length > 1 || resultado[1].length > 1 || resultado[2].length > 1) {
        this.selectCargo = true;
        this.cargoId = this.checkPerfisCargo; 
      } else {
        this.alertService.showAlertWarning('Esta cargo ainda não possui um perfil configurado.');
      }

    });

  }

  resetCargo() {
    this.cargoId = undefined;
  }

  closeModalCargo() {
    setTimeout(() => {
      this.closeModal.nativeElement.click(); // fechar modal
    }, 500);
  }
  /*
   * Recebe o filtro gerado no avaliacoes-search-axiologia.component
   */
  recebeResultadoFiltroAxiologia(resultadoFiltroAxiologia: LucorApiModelsNewFiltroCiencia) {
    this.resultadoFiltroAxiologia = resultadoFiltroAxiologia;
  }

  /*
  * Recebe o filtro gerado no avaliacoes-search-comportamento.component
  */
  recebeResultadoFiltroComportamento(resultadoFiltroComportamento: LucorApiModelsNewFiltroCiencia) {
    this.resultadoFiltroComportamento = resultadoFiltroComportamento;
  }

  /*
  * Recebe o filtro gerado no avaliacoes-search-motivadores.component
  */
  recebeResultadoFiltroMotivadores(resultadoFiltroMotivadores: LucorApiModelsNewFiltroCiencia) {
    this.resultadoFiltroMotivadores = resultadoFiltroMotivadores;
  }

  marcaAvaliado(event, ciencia, avaliado) {
    if (event.target.checked === false) {
        this.removerAvaliadoGrafico(ciencia, avaliado);
    } else {
        this.incluirAvaliadoGrafico(ciencia, avaliado);
    }
  }

  removerAvaliadoGrafico(ciencia, avaliado) {

    if (ciencia === 'Comportamento Observado' && this.aproximacaoDisc) {
      this.aproximacaoDisc.forEach(m => {
        if (avaliado === m.idAvaliado) {
          m.selected = false;
        }
      });
      this.updateGraficoResultadoComportamento();
    }
    if (ciencia === 'NATURAL' && this.aproximacaoDiscNatural) {
      this.aproximacaoDiscNatural.forEach(m => {
        if (avaliado === m.idAvaliado) {
          m.selected = false;
        }
      });
      this.updateGraficoResultadoComportamentoNatural();
    }
    if (ciencia === 'ADAPTAVEL' && this.aproximacaoDiscAdaptavel) {
      this.aproximacaoDiscAdaptavel.forEach(m => {
        if (avaliado === m.idAvaliado) {
          m.selected = false;
        }
      });
      this.updateGraficoResultadoComportamentoAdaptavel();
    }
    if (ciencia === 'Motivadores' && this.aproximacaoMotivadores) {
      this.aproximacaoMotivadores.forEach(m => {
        if (avaliado === m.idAvaliado) {
          m.selected = false;
        }
      });
      this.updateGraficoResultadoMotivadores();
    }
    if (ciencia === 'Axiologia' && this.aproximacaoAxiologia) {
      this.aproximacaoAxiologia.forEach(m => {
        if (avaliado === m.idAvaliado) {
          m.selected = false;
        }
      });
      this.updateGraficoResultadoAxiologia();
    }

  }

  incluirAvaliadoGrafico(ciencia, avaliado) {

    if (ciencia === 'Comportamento Observado' && this.aproximacaoDisc) {
      this.aproximacaoDisc.forEach(m => {
        if (avaliado === m.idAvaliado) {
          m.selected = true;
        }
      });
      this.updateGraficoResultadoComportamento();
    }
    if (ciencia === 'NATURAL' && this.aproximacaoDiscNatural) {
      this.aproximacaoDiscNatural.forEach(m => {
        if (avaliado === m.idAvaliado) {
          m.selected = true;
        }
      });
      this.updateGraficoResultadoComportamentoNatural();
    }
    if (ciencia === 'ADAPTAVEL' && this.aproximacaoDiscAdaptavel) {
      this.aproximacaoDiscAdaptavel.forEach(m => {
        if (avaliado === m.idAvaliado) {
          m.selected = true;
        }
      });
      this.updateGraficoResultadoComportamentoAdaptavel();
    }
    if (ciencia === 'Motivadores' && this.aproximacaoMotivadores) {
      this.aproximacaoMotivadores.forEach(m => {
        if (avaliado === m.idAvaliado) {
          m.selected = true;
        }
      });
      this.updateGraficoResultadoMotivadores();
    }
    if (ciencia === 'Axiologia' && this.aproximacaoAxiologia) {
      this.aproximacaoAxiologia.forEach(m => {
        if (avaliado === m.idAvaliado) {
          m.selected = true;
        }
      });
      this.updateGraficoResultadoAxiologia();
    }

  }

  setGraficoResultadoComportamento() {

    const avaliadosPorcentagem = [];
    let arrayPorcentagem: Array<any> = [];
    let cor: string;
    let bkCor: string;

    this.setChartLabelsDISC = [];
    this.chartDatasetsDISC = [];
    this.chartLabelsDISC = [];
    this.chartColorsDISC = [];
    this.newAvaliacaoDisc = [];

    if (this.avaliacao) {

      this.resultadoFiltroComportamento.filtroFatores.map(resultado => {
        this.setChartLabelsDISC.push(resultado);
      });

      this.avaliacao.avaliados.forEach(avaliados => {
        avaliadosPorcentagem.push({
          nome: avaliados.nome,
          idAvaliado: avaliados.id,
          selected: true
        });

        if (this.verificaAproximacaoDisc === false) {
          this.aproximacaoDisc.push({
            nomeAvaliado: avaliados.nome,
            idAvaliado: avaliados.id,
            nomeFator: [],
            porcentagemDisc: [],
            aproximacao: [],
            statusDisc: [],
            selected: true
          });
        }

        if (avaliadosPorcentagem.length > 30) {
          this.displayLegend = false;
        }
      });

      this.resultadoFiltroComportamento.perfis.map(resultadoFiltro => {

        this.avaliacao.avaliados.forEach(avaliados => {

          this.aproximacaoDisc.forEach(aproximacaoD => {
          // if (avaliados.nome === a.nomeAvaliado && a.selected === true) {
          if (avaliados.id === aproximacaoD.idAvaliado && aproximacaoD.selected === true) {

          avaliados.resultadosCiencias.forEach(resultadosCiencias => {
            if (resultadosCiencias.ciencia === 'Comportamento Observado') {
              resultadosCiencias.resultados.forEach(resultados => {

                let verificaFator = false;
                let nomeIgual = false;
                let parcialDisc: number;

                if (resultados.nomeFator === resultadoFiltro.fator) {

                  this.newAvaliacaoDisc.forEach(newAvaliacao => {
                    if (this.newAvaliacaoDisc && avaliados.id === newAvaliacao.idAvaliado) {
                      newAvaliacao.data.push(+resultados.valor);
                      newAvaliacao.fatores.push(resultados.nomeFator);
                      verificaFator = true;
                    }
                  });

                  this.aproximacaoDisc.forEach(aprox => {


                    if (avaliados.id === aprox.idAvaliado) {

                      if (resultadoFiltro.ideal === undefined || resultadoFiltro.ideal === null || resultadoFiltro.ideal === 0) { // Verifica se foi selecionado valor ideal, se não, aplica o padrão 80
                        resultadoFiltro.ideal = 80;
                      }

                      parcialDisc = (+resultados.valor * 100) / +resultadoFiltro.ideal;

                      if (+resultados.valor > +resultadoFiltro.ideal) {
                        this.porcentagemDisc = 200 - parcialDisc;
                        this.porcentagemDisc = this.porcentagemDisc.toFixed(2);
                      } else {
                        this.porcentagemDisc = parcialDisc.toFixed(2);
                      }

                      aprox.nomeFator.push(
                        resultados.nomeFator
                      );

                      aprox.porcentagemDisc.push(
                        +this.porcentagemDisc
                      );
                      aprox.aproximacao.push(
                        // this.porcentagemAproximacaoDisc
                      );

                      aprox.statusDisc.push(
                        // this.statusDisc
                      );
                      nomeIgual = true;
                    }
                  });

                  if (!verificaFator) {
                    this.newAvaliacaoDisc.push({
                      label: avaliados.nome,
                      idAvaliado: avaliados.id,
                      data: [+resultados.valor],
                      fatores: [resultados.nomeFator]
                    });

                  }

                  if (!nomeIgual) {
                    // --- Verificação de porcentagem e status de aproximação

                    if (resultadoFiltro.ideal === undefined || resultadoFiltro.ideal === null || resultadoFiltro.ideal === 0) { // Verifica se foi selecionado valor ideal, se não, aplica o padrão 80
                      resultadoFiltro.ideal = 80;
                    }

                    parcialDisc = (+resultados.valor * 100) / +resultadoFiltro.ideal;
                    if (+resultados.valor > +resultadoFiltro.ideal) {
                      this.porcentagemDisc = 200 - parcialDisc;
                      this.porcentagemDisc = this.porcentagemDisc.toFixed(2);
                    } else {
                      this.porcentagemDisc = parcialDisc.toFixed(2);
                    }

                    this.aproximacaoDisc.push(
                      {
                        nomeAvaliado: avaliados.nome,
                        idAvaliado: avaliados.id,
                        nomeFator: [resultados.nomeFator],
                        porcentagemDisc: [+this.porcentagemDisc],
                        aproximacao: [+this.porcentagemAproximacaoDisc],
                        statusDisc: [this.statusDisc],
                        selected: true
                      }
                    );

                  }

              }

              }); // <-- resultadosCiencias.resultados.forEach
            }
          });
        }
      });

        }); // <-- this.avaliacao.avaliados.forEach
      }); // <--this.resultadoFiltroComportamento.perfis.map
    }


    this.aproximacaoDisc.forEach(aproximacaoD => {
      avaliadosPorcentagem.forEach(avaliadosPorc => {
        if (aproximacaoD.idAvaliado === avaliadosPorc.idAvaliado) {
          let calculoPorcentagem: any;

          arrayPorcentagem = [];

          arrayPorcentagem = aproximacaoD.porcentagemDisc;

          calculoPorcentagem = arrayPorcentagem.reduce((accum, curr) => accum + curr);

          this.porcentagemAproximacaoDisc = calculoPorcentagem / this.setChartLabelsDISC.length; // Média das porcentagens

          aproximacaoD.aproximacao = [];

          aproximacaoD.aproximacao.push(
            this.porcentagemAproximacaoDisc.toFixed(2)
          );

          // ------ ** ----
          // this.setMediaGeral(a.nome, +this.porcentagemAproximacaoDisc); // Adiciona ao array de Média Geral
          // ------ ** ----

          if (this.porcentagemAproximacaoDisc < 40) {
            this.statusDisc = 'Muito Baixa';
          }
          if (this.porcentagemAproximacaoDisc < 60 && this.porcentagemAproximacaoDisc >= 40) {
            this.statusDisc = 'Baixa';
          }
          if (this.porcentagemAproximacaoDisc < 80 && this.porcentagemAproximacaoDisc >= 60) {
            this.statusDisc = 'Média';
          }
          if (this.porcentagemAproximacaoDisc < 100 && this.porcentagemAproximacaoDisc >= 80) {
            this.statusDisc = 'Alta';
          }
          if (this.porcentagemAproximacaoDisc === 100) {
            this.statusDisc = 'Exata';
          }

          aproximacaoD.statusDisc = [];

          aproximacaoD.statusDisc.push(
            this.statusDisc
          );
        }
      });
    });

    let fatorIgual: boolean;
    this.resultadoFiltroComportamento.perfis.map(resultadoFiltro => {

        fatorIgual = false;

        this.newAvaliacaoDisc.forEach(newAvaliacao => {
          if (this.newAvaliacaoDisc && newAvaliacao.label === 'Perfil Ideal' && resultadoFiltro.fator !== null) {
            newAvaliacao.data.push(+resultadoFiltro.ideal);
            newAvaliacao.fatores.push(resultadoFiltro.fator);
            fatorIgual = true;
          }
        });
        if (!fatorIgual && resultadoFiltro.fator !== null) {
          this.newAvaliacaoDisc.push({
            data: [+resultadoFiltro.ideal],
            label: 'Perfil Ideal',
            fatores: [resultadoFiltro.fator]
          });
        }

      }); // <-- this.resultadoFiltroComportamento.perfis.map

    this.newAvaliacaoDisc.forEach(newAvaliacao => {

      fatorIgual = false;

      this.chartDatasetsDISC.forEach(chartData => {
        if (this.chartDatasetsDISC && newAvaliacao.idAvaliado === chartData.id) {
          chartData.data.push(newAvaliacao.data);
          fatorIgual = true;
        }
      });

      if (!fatorIgual) {
        this.chartDatasetsDISC.push({
          id: newAvaliacao.idAvaliado,
          data: newAvaliacao.data,
          label: newAvaliacao.label
        });

        cor = 'rgb(' + (Math.floor(Math.random() * 256)) +
            ',' + (Math.floor(Math.random() * 256)) +
            ',' + (Math.floor(Math.random() * 256)) + ',' + 0.7 + ')';
        bkCor = 'rgb(' + (Math.floor(Math.random() * 256)) +
            ',' + (Math.floor(Math.random() * 256)) +
            ',' + (Math.floor(Math.random() * 256)) + ',' + 0 + ')';
        this.chartColorsDISC.push({
            backgroundColor: bkCor,
            borderColor: cor,
            borderWidth: 2,
        });
      }
    });

    this.chartLabelsDISC = this.setChartLabelsDISC;
    this.chartDatasetsDISC = this.chartDatasetsDISC;

    this.chartColorsDISC.pop();

    this.chartColorsDISC.push({
      backgroundColor: 'rgba(18,21,24, .2)',
      borderColor: 'rgba(18,21,24, 0)',
      borderWidth: 0,
    });

  }

  setGraficoResultadoComportamentoNatural() {

    const avaliadosPorcentagem = [];
    let arrayPorcentagem: Array<any> = [];
    let cor: string;
    let bkCor: string;

    this.setChartLabelsDISCNATURAL = [];
    this.chartDatasetsDISCNATURAL = [];
    this.chartLabelsDISCNATURAL = [];
    this.chartColorsDISCNATURAL = [];
    this.newAvaliacaoDiscNatural = [];

    if (this.avaliacao) {

      this.resultadoFiltroComportamento.filtroFatores.map(resultado => {
        if (resultado.includes('NATURAL')) {
          this.setChartLabelsDISCNATURAL.push(resultado);
          this.checkNatural = true;
        }
      });

      if (this.checkNatural) {

        this.avaliacao.avaliados.forEach(avaliados => {
          avaliadosPorcentagem.push({
            nome: avaliados.nome,
            idAvaliado: avaliados.id,
            selected: true
          });

          if (this.verificaAproximacaoDiscNatural === false) {

            this.aproximacaoDiscNatural.push({
              nomeAvaliado: avaliados.nome,
              idAvaliado: avaliados.id,
              nomeFator: [],
              porcentagemDisc: [],
              aproximacao: [],
              statusDisc: [],
              selected: true
            });

          }

        });

        this.resultadoFiltroComportamento.perfis.map(resultadoFiltro => {

          this.avaliacao.avaliados.forEach(avaliados => {

            this.aproximacaoDiscNatural.forEach(aproximacaoD => {
              if (avaliados.id === aproximacaoD.idAvaliado && aproximacaoD.selected === true) {

                avaliados.resultadosCiencias.forEach(resultadosCiencias => {

                if (resultadosCiencias.ciencia === 'Comportamento Observado') {
                  resultadosCiencias.resultados.forEach(resultados => {

                    let nomeIgualNatural = false;
                    let verificaFatorNatural = false;
                    let parcialDiscNatural: number;

                    // Comportamento Natural
                    if (resultados.nomeFator === resultadoFiltro.fator && resultados.nomeFator.includes('NATURAL')) {

                      this.newAvaliacaoDiscNatural.forEach(newAvaliacao => {
                        if (this.newAvaliacaoDiscNatural && avaliados.id === newAvaliacao.idAvaliado) {
                          newAvaliacao.data.push(+resultados.valor);
                          newAvaliacao.fatores.push(resultados.nomeFator);
                          verificaFatorNatural = true;
                        }
                      });

                      this.aproximacaoDiscNatural.forEach(aprox => {

                        if (avaliados.id === aprox.idAvaliado) {

                          if (resultadoFiltro.ideal === undefined || resultadoFiltro.ideal === null || resultadoFiltro.ideal === 0) { // Verifica se foi selecionado valor ideal, se não, aplica o padrão 80
                            resultadoFiltro.ideal = 80;
                          }

                          parcialDiscNatural = (+resultados.valor * 100) / +resultadoFiltro.ideal;

                          if (+resultados.valor > +resultadoFiltro.ideal) {
                            this.porcentagemDiscNatural = 200 - parcialDiscNatural;
                            this.porcentagemDiscNatural = this.porcentagemDiscNatural.toFixed(2);
                          } else {
                            this.porcentagemDiscNatural = parcialDiscNatural.toFixed(2);
                          }

                          aprox.nomeFator.push(
                            resultados.nomeFator
                          );

                          aprox.porcentagemDisc.push(
                            +this.porcentagemDiscNatural
                          );
                          aprox.aproximacao.push(
                            // this.porcentagemAproximacaoDiscNatural
                          );

                          aprox.statusDisc.push(
                            // this.statusDiscNatural
                          );
                          nomeIgualNatural = true;
                        }
                      });

                      if (!verificaFatorNatural) {
                        this.newAvaliacaoDiscNatural.push({
                          label: avaliados.nome,
                          idAvaliado: avaliados.id,
                          data: [+resultados.valor],
                          fatores: [resultados.nomeFator]
                        });

                      }

                      if (!nomeIgualNatural) {
                        // --- Verificação de porcentagem e status de aproximação

                        if (resultadoFiltro.ideal === undefined || resultadoFiltro.ideal === null || resultadoFiltro.ideal === 0) { // Verifica se foi selecionado valor ideal, se não, aplica o padrão 80
                          resultadoFiltro.ideal = 80;
                        }

                        parcialDiscNatural = (+resultados.valor * 100) / +resultadoFiltro.ideal;
                        if (+resultados.valor > +resultadoFiltro.ideal) {
                          this.porcentagemDiscNatural = 200 - parcialDiscNatural;
                          this.porcentagemDiscNatural = this.porcentagemDiscNatural.toFixed(2);
                        } else {
                          this.porcentagemDiscNatural = parcialDiscNatural.toFixed(2);
                        }

                        this.aproximacaoDiscNatural.push(
                          {
                            nomeAvaliado: avaliados.nome,
                            idAvaliado: avaliados.id,
                            nomeFator: [resultados.nomeFator],
                            porcentagemDisc: [+this.porcentagemDiscNatural],
                            aproximacao: [+this.porcentagemAproximacaoDiscNatural],
                            statusDisc: [this.statusDiscNatural],
                            selected: true
                          }
                        );
                      }
                    }
                    }); // <-- resultadosCiencias.resultados.forEach
                  }
                });
              }
            });
          }); // <-- this.avaliacao.avaliados.forEach
        }); // <--this.resultadoFiltroComportamento.perfis.map


        this.aproximacaoDiscNatural.forEach(aproximacaoD => {
          avaliadosPorcentagem.forEach(avaliadosPorc => {
            if (aproximacaoD.idAvaliado === avaliadosPorc.idAvaliado) {
              let calculoPorcentagem: any;

              arrayPorcentagem = [];

              arrayPorcentagem = aproximacaoD.porcentagemDisc;

              calculoPorcentagem = arrayPorcentagem.reduce( (accum, curr) => accum + curr );

              this.porcentagemAproximacaoDiscNatural = calculoPorcentagem / this.setChartLabelsDISCNATURAL.length; // Média das porcentagens

              aproximacaoD.aproximacao = [];

              aproximacaoD.aproximacao.push(
                this.porcentagemAproximacaoDiscNatural.toFixed(2)
              );

              // ------ ** ----
              this.setMediaGeral(avaliadosPorc.nome, avaliadosPorc.idAvaliado, +this.porcentagemAproximacaoDiscNatural); // Adiciona ao array de Média Geral
              // ------ ** ----

              if (this.porcentagemAproximacaoDiscNatural < 40) {
                this.statusDiscNatural = 'Muito Baixa';
              }
              if (this.porcentagemAproximacaoDiscNatural < 60 && this.porcentagemAproximacaoDiscNatural >= 40) {
                this.statusDiscNatural = 'Baixa';
              }
              if (this.porcentagemAproximacaoDiscNatural < 80 && this.porcentagemAproximacaoDiscNatural >= 60) {
                this.statusDiscNatural = 'Média';
              }
              if (this.porcentagemAproximacaoDiscNatural < 100 && this.porcentagemAproximacaoDiscNatural >= 80) {
                this.statusDiscNatural = 'Alta';
              }
              if (this.porcentagemAproximacaoDiscNatural === 100) {
                this.statusDiscNatural = 'Exata';
              }

              aproximacaoD.statusDisc = [];

              aproximacaoD.statusDisc.push(
                this.statusDiscNatural
              );
            }
          });
        });

        // Comportamento Natural
        let fatorIgualNatural: boolean;

        this.resultadoFiltroComportamento.perfis.map(resultadoFiltro => {

            fatorIgualNatural = false;

            this.newAvaliacaoDiscNatural.forEach(newAvaliacao => {
              if (this.newAvaliacaoDiscNatural && newAvaliacao.label === 'Perfil Ideal' && resultadoFiltro.fator !== null && resultadoFiltro.fator.includes('NATURAL')) {
                newAvaliacao.data.push(+resultadoFiltro.ideal);
                newAvaliacao.fatores.push(resultadoFiltro.fator);
                fatorIgualNatural = true;
              }
            });
            if (!fatorIgualNatural && resultadoFiltro.fator !== null && resultadoFiltro.fator.includes('NATURAL')) {
              this.newAvaliacaoDiscNatural.push({
                data: [+resultadoFiltro.ideal],
                label: 'Perfil Ideal',
                fatores: [resultadoFiltro.fator]
              });
            }

        }); // <-- this.resultadoFiltroComportamento.perfis.map

        this.newAvaliacaoDiscNatural.forEach(newAvaliacao => {

          fatorIgualNatural = false;

          this.chartDatasetsDISCNATURAL.forEach(chartData => {

            if (this.chartDatasetsDISCNATURAL && newAvaliacao.idAvaliado === chartData.id) {
              chartData.data.push(newAvaliacao.data);
              fatorIgualNatural = true;
            }
          });

          if (!fatorIgualNatural) {
            this.chartDatasetsDISCNATURAL.push({
              id: newAvaliacao.idAvaliado,
              data: newAvaliacao.data,
              label: newAvaliacao.label
            });

            cor = 'rgb(' + (Math.floor(Math.random() * 256)) +
                  ',' + (Math.floor(Math.random() * 256)) +
                  ',' + (Math.floor(Math.random() * 256)) + ',' + 0.7 + ')';
            bkCor = 'rgb(' + (Math.floor(Math.random() * 256)) +
                  ',' + (Math.floor(Math.random() * 256)) +
                  ',' + (Math.floor(Math.random() * 256)) + ',' + 0 + ')';

            this.chartColorsDISCNATURAL.push({
                  backgroundColor: bkCor,
                  borderColor: cor,
                  borderWidth: 2,
            });
          }
        });

        this.chartLabelsDISCNATURAL = this.setChartLabelsDISCNATURAL;
        this.chartDatasetsDISCNATURAL = this.chartDatasetsDISCNATURAL;

        this.chartColorsDISCNATURAL.pop();

        this.chartColorsDISCNATURAL.push({
          backgroundColor: 'rgba(18,21,24, .2)',
          borderColor: 'rgba(18,21,24, 0)',
          borderWidth: 0,
        });

      }
    }
  }

  setGraficoResultadoComportamentoAdaptavel() {

    const avaliadosPorcentagem = [];
    let arrayPorcentagem: Array<any> = [];
    let cor: string;
    let bkCor: string;

    this.setChartLabelsDISCADAPTAVEL = [];
    this.chartDatasetsDISCADAPTAVEL = [];
    this.chartLabelsDISCADAPTAVEL = [];
    this.chartColorsDISCADAPTAVEL = [];
    this.newAvaliacaoDiscAdaptavel = [];

    if (this.avaliacao) {

      this.resultadoFiltroComportamento.filtroFatores.map(resultado => {
        if (resultado.includes('ADAPTÁVEL')) {
          this.setChartLabelsDISCADAPTAVEL.push(resultado);
          this.checkAdaptavel = true;
        }
      });

      if (this.checkAdaptavel) {
        this.avaliacao.avaliados.forEach(avaliados => {
          avaliadosPorcentagem.push({
            nome: avaliados.nome,
            idAvaliado: avaliados.id,
            selected: true
          });

          if (this.verificaAproximacaoDiscAdaptavel === false) {

            this.aproximacaoDiscAdaptavel.push({
              nomeAvaliado: avaliados.nome,
              idAvaliado: avaliados.id,
              nomeFator: [],
              porcentagemDisc: [],
              aproximacao: [],
              statusDisc: [],
              selected: true
            });

          }

          });

        this.resultadoFiltroComportamento.perfis.map(resultadoFiltro => {

          this.avaliacao.avaliados.forEach(avaliados => {

            this.aproximacaoDiscAdaptavel.forEach(aproximacaoD => {
              if (avaliados.id === aproximacaoD.idAvaliado && aproximacaoD.selected === true) {

                avaliados.resultadosCiencias.forEach(resultadosCiencias => {

                if (resultadosCiencias.ciencia === 'Comportamento Observado') {
                  resultadosCiencias.resultados.forEach(resultados => {

                    let nomeIgualAdaptavel = false;
                    let verificaFatorAdaptavel = false;
                    let parcialDiscAdaptavel: number;

                    // Comportamento Adaptavel
                    if (resultados.nomeFator === resultadoFiltro.fator && resultados.nomeFator.includes('ADAPTÁVEL')) {

                      this.newAvaliacaoDiscAdaptavel.forEach(newAvaliacao => {
                        if (this.newAvaliacaoDiscAdaptavel && avaliados.id === newAvaliacao.idAvaliado) {
                          newAvaliacao.data.push(+resultados.valor);
                          newAvaliacao.fatores.push(resultados.nomeFator);
                          verificaFatorAdaptavel = true;
                        }
                      });

                      this.aproximacaoDiscAdaptavel.forEach(aprox => {


                        if (avaliados.id === aprox.idAvaliado) {

                          if (resultadoFiltro.ideal === undefined || resultadoFiltro.ideal === null || resultadoFiltro.ideal === 0) { // Verifica se foi selecionado valor ideal, se não, aplica o padrão 80
                            resultadoFiltro.ideal = 80;
                          }

                          parcialDiscAdaptavel = (+resultados.valor * 100) / +resultadoFiltro.ideal;

                          if (+resultados.valor > +resultadoFiltro.ideal) {
                            this.porcentagemDiscAdaptavel = 200 - parcialDiscAdaptavel;
                            this.porcentagemDiscAdaptavel = this.porcentagemDiscAdaptavel.toFixed(2);
                          } else {
                            this.porcentagemDiscAdaptavel = parcialDiscAdaptavel.toFixed(2);
                          }

                          aprox.nomeFator.push(
                            resultados.nomeFator
                          );

                          aprox.porcentagemDisc.push(
                            +this.porcentagemDiscAdaptavel
                          );
                          aprox.aproximacao.push(
                            // this.porcentagemAproximacaoDiscAdaptavel
                          );

                          aprox.statusDisc.push(
                            // this.statusDiscAdaptavel
                          );
                          nomeIgualAdaptavel = true;
                        }
                      });

                      if (!verificaFatorAdaptavel) {
                        this.newAvaliacaoDiscAdaptavel.push({
                          label: avaliados.nome,
                          idAvaliado: avaliados.id,
                          data: [+resultados.valor],
                          fatores: [resultados.nomeFator]
                        });

                      }

                      if (!nomeIgualAdaptavel) {
                        // --- Verificação de porcentagem e status de aproximação

                        if (resultadoFiltro.ideal === undefined || resultadoFiltro.ideal === null || resultadoFiltro.ideal === 0) { // Verifica se foi selecionado valor ideal, se não, aplica o padrão 80
                          resultadoFiltro.ideal = 80;
                        }

                        parcialDiscAdaptavel = (+resultados.valor * 100) / +resultadoFiltro.ideal;
                        if (+resultados.valor > +resultadoFiltro.ideal) {
                          this.porcentagemDiscAdaptavel = 200 - parcialDiscAdaptavel;
                          this.porcentagemDiscAdaptavel = this.porcentagemDiscAdaptavel.toFixed(2);
                        } else {
                          this.porcentagemDiscAdaptavel = parcialDiscAdaptavel.toFixed(2);
                        }

                        this.aproximacaoDiscAdaptavel.push(
                          {
                            nomeAvaliado: avaliados.nome,
                            idAvaliado: avaliados.id,
                            nomeFator: [resultados.nomeFator],
                            porcentagemDisc: [+this.porcentagemDiscAdaptavel],
                            aproximacao: [+this.porcentagemAproximacaoDiscAdaptavel],
                            statusDisc: [this.statusDiscAdaptavel],
                            selected: true
                          }
                        );

                      }

                    }

                    }); // <-- resultadosCiencias.resultados.forEach
                  }
                });
              }
            });
          }); // <-- this.avaliacao.avaliados.forEach
        }); // <--this.resultadoFiltroComportamento.perfis.map

        this.aproximacaoDiscAdaptavel.forEach(aproximacaoD => {
          avaliadosPorcentagem.forEach(avaliadosPorc => {
            if (aproximacaoD.idAvaliado === avaliadosPorc.idAvaliado) {
              let calculoPorcentagem: any;

              arrayPorcentagem = [];

              arrayPorcentagem = aproximacaoD.porcentagemDisc;

              calculoPorcentagem = arrayPorcentagem.reduce( (accum, curr) => accum + curr );

              this.porcentagemAproximacaoDiscAdaptavel = calculoPorcentagem / this.setChartLabelsDISCADAPTAVEL.length; // Média das porcentagens

              aproximacaoD.aproximacao = [];

              aproximacaoD.aproximacao.push(
                this.porcentagemAproximacaoDiscAdaptavel.toFixed(2)
              );

              // ------ ** ----
              this.setMediaGeral(avaliadosPorc.nome, avaliadosPorc.idAvaliado, +this.porcentagemAproximacaoDiscAdaptavel); // Adiciona ao array de Média Geral
              // ------ ** ----

              if (this.porcentagemAproximacaoDiscAdaptavel < 40) {
                this.statusDiscAdaptavel = 'Muito Baixa';
              }
              if (this.porcentagemAproximacaoDiscAdaptavel < 60 && this.porcentagemAproximacaoDiscAdaptavel >= 40) {
                this.statusDiscAdaptavel = 'Baixa';
              }
              if (this.porcentagemAproximacaoDiscAdaptavel < 80 && this.porcentagemAproximacaoDiscAdaptavel >= 60) {
                this.statusDiscAdaptavel = 'Média';
              }
              if (this.porcentagemAproximacaoDiscAdaptavel < 100 && this.porcentagemAproximacaoDiscAdaptavel >= 80) {
                this.statusDiscAdaptavel = 'Alta';
              }
              if (this.porcentagemAproximacaoDiscAdaptavel === 100) {
                this.statusDiscAdaptavel = 'Exata';
              }

              aproximacaoD.statusDisc = [];

              aproximacaoD.statusDisc.push(
                this.statusDiscAdaptavel
              );

            }

          });
        });

        // Comportamento Adaptavel
        let fatorIgualAdaptavel: boolean;

        this.resultadoFiltroComportamento.perfis.map(resultadoFiltro => {

            fatorIgualAdaptavel = false;

            this.newAvaliacaoDiscAdaptavel.forEach(c => {

              if (this.newAvaliacaoDiscAdaptavel && c.label === 'Perfil Ideal' && resultadoFiltro.fator !== null && resultadoFiltro.fator.includes('ADAPTÁVEL')) {
                c.data.push(+resultadoFiltro.ideal);
                c.fatores.push(resultadoFiltro.fator);
                fatorIgualAdaptavel = true;
              }
            });
            if (!fatorIgualAdaptavel && resultadoFiltro.fator !== null && resultadoFiltro.fator.includes('ADAPTÁVEL')) {
              this.newAvaliacaoDiscAdaptavel.push({
                data: [+resultadoFiltro.ideal],
                label: 'Perfil Ideal',
                fatores: [resultadoFiltro.fator]
              });
            }

        }); // <-- this.resultadoFiltroComportamento.perfis.map

        this.newAvaliacaoDiscAdaptavel.forEach(newAvaliacao => {

          fatorIgualAdaptavel = false;

          this.chartDatasetsDISCADAPTAVEL.forEach(chartData => {

            if (this.chartDatasetsDISCADAPTAVEL && newAvaliacao.idAvaliado === chartData.id) {
              chartData.data.push(newAvaliacao.data);
              fatorIgualAdaptavel = true;
            }
          });

          if (!fatorIgualAdaptavel) {
            this.chartDatasetsDISCADAPTAVEL.push({
              id: newAvaliacao.idAvaliado,
              data: newAvaliacao.data,
              label: newAvaliacao.label
            });

            cor = 'rgb(' + (Math.floor(Math.random() * 256)) +
                  ',' + (Math.floor(Math.random() * 256)) +
                  ',' + (Math.floor(Math.random() * 256)) + ',' + 0.7 + ')';
            bkCor = 'rgb(' + (Math.floor(Math.random() * 256)) +
                  ',' + (Math.floor(Math.random() * 256)) +
                  ',' + (Math.floor(Math.random() * 256)) + ',' + 0 + ')';

            this.chartColorsDISCADAPTAVEL.push({
              backgroundColor: bkCor,
              borderColor: cor,
              borderWidth: 2,
            });
          }
        });

        this.chartLabelsDISCADAPTAVEL = this.setChartLabelsDISCADAPTAVEL;
        this.chartDatasetsDISCADAPTAVEL = this.chartDatasetsDISCADAPTAVEL;

        this.chartColorsDISCADAPTAVEL.pop();

        this.chartColorsDISCADAPTAVEL.push({
          backgroundColor: 'rgba(18,21,24, .2)',
          borderColor: 'rgba(18,21,24, 0)',
          borderWidth: 0,
        });
      }
    }

  }

  updateGraficoResultadoComportamento() {

    let cor;
    let bkCor;

    this.updateDataDisc = [];
    this.chartColorsDISC = [];

    this.aproximacaoDisc.forEach(a => {

          if (a.selected === true) {
          this.newAvaliacaoDisc.forEach(p => {
            if (p.idAvaliado === a.idAvaliado) {
              this.updateDataDisc.push({
              data: p.data,
              label: p.label
              });
            }
          });
          cor = 'rgb(' + (Math.floor(Math.random() * 256)) +
              ',' + (Math.floor(Math.random() * 256)) +
              ',' + (Math.floor(Math.random() * 256)) + ',' + 0.7 + ')';
          bkCor = 'rgb(' + (Math.floor(Math.random() * 256)) +
              ',' + (Math.floor(Math.random() * 256)) +
              ',' + (Math.floor(Math.random() * 256)) + ',' + 0 + ')';

          this.chartColorsDISC.push({
              backgroundColor: bkCor,
              borderColor: cor,
              borderWidth: 2,
          });
        }
        });


    setTimeout(() => {
      this.chartLabelsDISC = [];
      this.resultadoFiltroComportamento.filtroFatores.map(r => {
        this.chartLabelsDISC.push(r);
      });
      this.chartDatasetsDISC.forEach(d => {
        if (d.label === 'Perfil Ideal') {
          this.updateDataDisc.push({
            data: d.data,
            label: d.label
          });
        }
      });
      if (this.chartDatasetsDISC) {
        this.chartDatasetsDISC = this.updateDataDisc;
      }
      }, 10);

    this.chartColorsDISC.push({
      backgroundColor: 'rgba(18,21,24, .2)',
      borderColor: 'rgba(18,21,24, 0)',
      borderWidth: 0,
    });
  }

  updateGraficoResultadoComportamentoNatural() {

    let cor: string;
    let bkCor: string;

    this.updateDataDiscNatural = [];
    this.chartColorsDISCNATURAL = [];

    this.aproximacaoDiscNatural.forEach(a => {

          if (a.selected === true) {
          this.newAvaliacaoDiscNatural.forEach(p => {
            if (p.idAvaliado === a.idAvaliado) {
              this.updateDataDiscNatural.push({
              data: p.data,
              label: p.label
              });
            }
          });

          cor = 'rgb(' + (Math.floor(Math.random() * 256)) +
              ',' + (Math.floor(Math.random() * 256)) +
              ',' + (Math.floor(Math.random() * 256)) + ',' + 0.7 + ')';
          bkCor = 'rgb(' + (Math.floor(Math.random() * 256)) +
              ',' + (Math.floor(Math.random() * 256)) +
              ',' + (Math.floor(Math.random() * 256)) + ',' + 0 + ')';

          this.chartColorsDISCNATURAL.push({
              backgroundColor: bkCor,
              borderColor: cor,
              borderWidth: 2,
          });
        }
      });

    // this.chartDatasetsDISCNATURAL = this.updateDataDiscNatural;

    setTimeout(() => {
      this.chartLabelsDISCNATURAL = [];
      this.resultadoFiltroComportamento.filtroFatores.map(resultado => {
        if (resultado.includes('NATURAL')) {
          this.chartLabelsDISCNATURAL.push(resultado);
        }
      });
      this.chartDatasetsDISCNATURAL.forEach(data => {
        if (data.label === 'Perfil Ideal') {
          this.updateDataDiscNatural.push({
            data: data.data,
            label: data.label
          });
        }
      });
      if (this.chartDatasetsDISCNATURAL) {
        this.chartDatasetsDISCNATURAL = this.updateDataDiscNatural;
      }
    }, 10);

    this.chartColorsDISCNATURAL.push({
      backgroundColor: 'rgba(18,21,24, .2)',
      borderColor: 'rgba(18,21,24, 0)',
      borderWidth: 0,
    });
  }

  updateGraficoResultadoComportamentoAdaptavel() {

    let cor;
    let bkCor;

    this.updateDataDiscAdaptavel = [];
    this.chartColorsDISCADAPTAVEL = [];

    this.aproximacaoDiscAdaptavel.forEach(a => {

          if (a.selected === true) {
          this.newAvaliacaoDiscAdaptavel.forEach(p => {
            if (p.idAvaliado === a.idAvaliado) {
              this.updateDataDiscAdaptavel.push({
              data: p.data,
              label: p.label
              });
            }
          });

          cor = 'rgb(' + (Math.floor(Math.random() * 256)) +
              ',' + (Math.floor(Math.random() * 256)) +
              ',' + (Math.floor(Math.random() * 256)) + ',' + 0.7 + ')';
          bkCor = 'rgb(' + (Math.floor(Math.random() * 256)) +
              ',' + (Math.floor(Math.random() * 256)) +
              ',' + (Math.floor(Math.random() * 256)) + ',' + 0 + ')';

          this.chartColorsDISCADAPTAVEL.push({
              backgroundColor: bkCor,
              borderColor: cor,
              borderWidth: 2,
        });
        }
      });

    // this.chartDatasetsDISCADAPTAVEL = this.updateDataDiscAdaptavel;

    setTimeout(() => {
      this.chartLabelsDISCADAPTAVEL = [];
      this.resultadoFiltroComportamento.filtroFatores.map(r => {
        if (r.includes('ADAPTÁVEL')) {
          this.chartLabelsDISCADAPTAVEL.push(r);
        }

      });
      this.chartDatasetsDISCADAPTAVEL.forEach(d => {
        if (d.label === 'Perfil Ideal') {
          this.updateDataDiscAdaptavel.push({
            data: d.data,
            label: d.label
          });
        }
      });
      if (this.chartDatasetsDISCADAPTAVEL) {
        this.chartDatasetsDISCADAPTAVEL = this.updateDataDiscAdaptavel;
      }
      }, 10);

    this.chartColorsDISCADAPTAVEL.push({
      backgroundColor: 'rgba(18,21,24, .2)',
      borderColor: 'rgba(18,21,24, 0)',
      borderWidth: 0,
    });
  }

  setGraficoResultadoMotivadores() {

    const avaliacao = this.avaliacao;
    const avaliadosPorcentagem = [];
    let arrayPorcentagem: Array<any> = [];
    let cor: string;
    let bkCor: string;

    this.setChartLabelsMotivadores = [];
    this.chartDatasetsMotivadores = [];
    this.chartLabelsMotivadores = [];
    this.chartColorsMotivadores = [];
    this.newAvaliacaoMotivadores = [];

    if (avaliacao) {

      this.resultadoFiltroMotivadores.filtroFatores.map(resultado => {
        this.setChartLabelsMotivadores.push(resultado);
      });

      this.avaliacao.avaliados.forEach(avaliados => {
        avaliadosPorcentagem.push({
          nome: avaliados.nome,
          idAvaliado: avaliados.id,
          selected: true
        });

        if (this.verificaAproximacaoMotivadores === false) {
          this.aproximacaoMotivadores.push({
            nomeAvaliado: avaliados.nome,
            idAvaliado: avaliados.id,
            nomeFator: [],
            porcentagemMotivadores: [],
            aproximacao: [],
            statusMotivadores: [],
            selected: true
          });
        }

        if (avaliadosPorcentagem.length > 30) {
          this.displayLegend = false;
        }

      });

      this.resultadoFiltroMotivadores.perfis.map(resultadoFiltro => {

        this.avaliacao.avaliados.forEach(avaliados => {

          this.aproximacaoMotivadores.forEach(aproximacaoMoti => {
            if (avaliados.id === aproximacaoMoti.idAvaliado && aproximacaoMoti.selected === true) {

            avaliados.resultadosCiencias.forEach(resultadosCiencias => {
              if (resultadosCiencias.ciencia === 'Motivadores') {
                resultadosCiencias.resultados.forEach(resultados => {

                  let verificaFator = false;
                  let nomeIgual = false;
                  let parcialMotivadores: number;

                  if (resultados.nomeFator === resultadoFiltro.fator) {

                    this.newAvaliacaoMotivadores.forEach(newAvaliacao => {
                      if (this.newAvaliacaoMotivadores && avaliados.id === newAvaliacao.idAvaliado) {
                        newAvaliacao.data.push(
                          +resultados.valor
                        );
                        newAvaliacao.fatores.push(
                          resultados.nomeFator
                        );
                        verificaFator = true;
                      }
                    });

                    this.aproximacaoMotivadores.forEach(aprox => {

                      if (avaliados.id === aprox.idAvaliado) {

                        if (resultadoFiltro.ideal === undefined || resultadoFiltro.ideal === null || resultadoFiltro.ideal === 0) { // Verifica se foi selecionado valor ideal, se não, aplica o padrão 80
                          resultadoFiltro.ideal = 80;
                        }

                        parcialMotivadores = (+resultados.valor * 100) / +resultadoFiltro.ideal;

                        if (+resultados.valor > +resultadoFiltro.ideal) {
                          this.porcentagemMotivadores = 200 - parcialMotivadores;
                          this.porcentagemMotivadores = this.porcentagemMotivadores.toFixed(2);
                        } else {
                          this.porcentagemMotivadores = parcialMotivadores.toFixed(2);
                        }

                        aprox.nomeFator.push(
                          resultados.nomeFator
                        );

                        aprox.porcentagemMotivadores.push(
                          +this.porcentagemMotivadores
                        );
                        aprox.aproximacao.push(
                          // this.porcentagemAproximacaoMotivadores
                        );

                        aprox.statusMotivadores.push(
                          // this.statusMotivadores
                        );
                        nomeIgual = true;
                      }
                    });

                    if (!verificaFator) {
                      this.newAvaliacaoMotivadores.push({
                        label: avaliados.nome,
                        idAvaliado: avaliados.id,
                        data: [+resultados.valor],
                        fatores: [resultados.nomeFator]
                      });

                    }

                    if (!nomeIgual) {
                      // --- Verificação de porcentagem e status de aproximação

                      if (resultadoFiltro.ideal === undefined || resultadoFiltro.ideal === null || resultadoFiltro.ideal === 0) { // Verifica se foi selecionado valor ideal, se não, aplica o padrão 80
                        resultadoFiltro.ideal = 80;
                      }

                      parcialMotivadores = (+resultados.valor * 100) / +resultadoFiltro.ideal;
                      if (+resultados.valor > +resultadoFiltro.ideal) {
                        this.porcentagemMotivadores = 200 - parcialMotivadores;
                        this.porcentagemMotivadores = this.porcentagemMotivadores.toFixed(2);
                      } else {
                        this.porcentagemMotivadores = parcialMotivadores.toFixed(2);
                      }

                      this.aproximacaoMotivadores.push(
                        {
                          nomeAvaliado: avaliados.nome,
                          idAvaliado: avaliados.id,
                          nomeFator: [resultados.nomeFator],
                          porcentagemMotivadores: [+this.porcentagemMotivadores],
                          aproximacao: [+this.porcentagemAproximacaoMotivadores],
                          statusMotivadores: [this.statusMotivadores],
                          selected: true
                        }
                      );

                    }

                }

                }); // <-- resultadosCiencias.resultados.forEach
              }
            });

          }
        });

        }); // <-- this.avaliacao.avaliados.forEach
      }); // <--this.resultadoFiltroComportamento.perfis.map
    }


    this.aproximacaoMotivadores.forEach(aproximacaoMoti => {
      avaliadosPorcentagem.forEach(avaliadosPorc => {
        if (aproximacaoMoti.idAvaliado === avaliadosPorc.idAvaliado) {
          let calculoPorcentagem: any;

          arrayPorcentagem = [];

          arrayPorcentagem = aproximacaoMoti.porcentagemMotivadores;
          calculoPorcentagem = arrayPorcentagem.reduce( (accum, curr) => accum + curr );

          this.porcentagemAproximacaoMotivadores = calculoPorcentagem / this.setChartLabelsMotivadores.length; // Média das porcentagens

          aproximacaoMoti.aproximacao = [];

          aproximacaoMoti.aproximacao.push(
            this.porcentagemAproximacaoMotivadores.toFixed(2)
          );

          // ------ ** ----
          this.setMediaGeral(avaliadosPorc.nome, avaliadosPorc.idAvaliado, +this.porcentagemAproximacaoMotivadores); // Adiciona ao array de Média Geral
          // ------ ** ----

          if (this.porcentagemAproximacaoMotivadores < 40) {
            this.statusMotivadores = 'Muito Baixa';
          }
          if (this.porcentagemAproximacaoMotivadores < 60 && this.porcentagemAproximacaoMotivadores >= 40) {
            this.statusMotivadores = 'Baixa';
          }
          if (this.porcentagemAproximacaoMotivadores < 80 && this.porcentagemAproximacaoMotivadores >= 60) {
            this.statusMotivadores = 'Média';
          }
          if (this.porcentagemAproximacaoMotivadores < 100 && this.porcentagemAproximacaoMotivadores >= 80) {
            this.statusMotivadores = 'Alta';
          }
          if (this.porcentagemAproximacaoMotivadores === 100) {
            this.statusMotivadores = 'Exata';
          }

          aproximacaoMoti.statusMotivadores = [];

          aproximacaoMoti.statusMotivadores.push(
            this.statusMotivadores
          );

        }

      });
    });

    let fatorIgual: boolean;


    this.resultadoFiltroMotivadores.perfis.map(resultadoFiltro => {

      fatorIgual = false;

      this.newAvaliacaoMotivadores.forEach(newAvaliacao => {

        if (this.newAvaliacaoMotivadores && newAvaliacao.label === 'Perfil Ideal' && resultadoFiltro.fator !== null) {
          newAvaliacao.data.push(+resultadoFiltro.ideal);
          newAvaliacao.fatores.push(resultadoFiltro.fator);
          fatorIgual = true;
        }
      });
      if (!fatorIgual && resultadoFiltro.fator !== null) {
        this.newAvaliacaoMotivadores.push({
          data: [+resultadoFiltro.ideal],
          label: 'Perfil Ideal',
          fatores: [resultadoFiltro.fator]
        });

      }

    }); // <-- this.resultadoFiltroMotivadores.perfis.map

    this.newAvaliacaoMotivadores.forEach(newAvaliacao => {

      fatorIgual = false;

      this.chartDatasetsMotivadores.forEach(chartData => {

        if (this.chartDatasetsMotivadores && newAvaliacao.idAvaliado === chartData.id) {
          chartData.data.push(newAvaliacao.data);
          fatorIgual = true;
        }
      });

      if (!fatorIgual) {
        this.chartDatasetsMotivadores.push({
          id: newAvaliacao.idAvaliado,
          data: newAvaliacao.data,
          label: newAvaliacao.label
        });

        cor = 'rgb(' + (Math.floor(Math.random() * 256)) +
              ',' + (Math.floor(Math.random() * 256)) +
              ',' + (Math.floor(Math.random() * 256)) + ',' + 0.7 + ')';
        bkCor = 'rgb(' + (Math.floor(Math.random() * 256)) +
              ',' + (Math.floor(Math.random() * 256)) +
              ',' + (Math.floor(Math.random() * 256)) + ',' + 0 + ')';

        this.chartColorsMotivadores.push({
              backgroundColor: bkCor,
              borderColor: cor,
              borderWidth: 2,
        });

      }
    });

    this.chartLabelsMotivadores = this.setChartLabelsMotivadores;
    this.chartDatasetsMotivadores = this.chartDatasetsMotivadores;

    this.chartColorsMotivadores.pop();

    this.chartColorsMotivadores.push({
      backgroundColor: 'rgba(18,21,24, .2)',
      borderColor: 'rgba(18,21,24, 0)',
      borderWidth: 0,
    });

    // this.updateGraficoResultadoMotivadores();

  }

  updateGraficoResultadoMotivadores() {

    let cor;
    let bkCor;

    this.updateDataMotivadores = [];
    this.chartColorsMotivadores = [];

    this.aproximacaoMotivadores.forEach(a => {

        if (a.selected === true) {
          this.newAvaliacaoMotivadores.forEach(p => {
            if (p.idAvaliado === a.idAvaliado) {
              this.updateDataMotivadores.push({
                data: p.data,
                label: p.label
              });
            }
          });
          cor = 'rgb(' + (Math.floor(Math.random() * 256)) +
              ',' + (Math.floor(Math.random() * 256)) +
              ',' + (Math.floor(Math.random() * 256)) + ',' + 0.7 + ')';
          bkCor = 'rgb(' + (Math.floor(Math.random() * 256)) +
              ',' + (Math.floor(Math.random() * 256)) +
              ',' + (Math.floor(Math.random() * 256)) + ',' + 0 + ')';

          this.chartColorsMotivadores.push({
              backgroundColor: bkCor,
              borderColor: cor,
              borderWidth: 2,
        });

      }
    });

    // this.chartDatasetsMotivadores = this.updateDataMotivadores;

    setTimeout(() => {
      this.chartLabelsMotivadores = [];
      this.resultadoFiltroMotivadores.filtroFatores.map(r => {
        this.chartLabelsMotivadores.push(r);
      });
      this.chartDatasetsMotivadores.forEach(d => {
        if (d.label === 'Perfil Ideal') {
          this.updateDataMotivadores.push({
            data: d.data,
            label: d.label
          });
        }
      });

      if (this.chartDatasetsMotivadores) {
        this.chartDatasetsMotivadores = this.updateDataMotivadores;
      }
    }, 10);

    this.chartColorsMotivadores.push({
      backgroundColor: 'rgba(18,21,24, .2)',
      borderColor: 'rgba(18,21,24, 0)',
      borderWidth: 0,
    });
  }

  setGraficoResultadoAxiologia() {

    const avaliacao = this.avaliacao;
    const avaliadosPorcentagem = [];
    let arrayPorcentagem: Array<any> = [];
    let cor: string;
    let bkCor: string;

    this.setChartLabelsAxiologia = [];
    this.chartDatasetsAxiologia = [];
    this.chartLabelsAxiologia = [];
    this.chartColorsAxiologia = [];
    this.newAvaliacaoAxiologia = [];

    if (avaliacao) {
      this.resultadoFiltroAxiologia.filtroFatores.map(resultado => {
        this.setChartLabelsAxiologia.push(resultado);
      });

      this.avaliacao.avaliados.forEach(avaliados => {
        avaliadosPorcentagem.push({
          nome: avaliados.nome,
          idAvaliado: avaliados.id,
          selected: true
        });

        if (this.verificaAproximacaoAxiologia === false) {
          this.aproximacaoAxiologia.push({
            nomeAvaliado: avaliados.nome,
            idAvaliado: avaliados.id,
            nomeFator: [],
            porcentagemAxiologia: [],
            aproximacao: [],
            statusAxiologia: [],
            selected: true
          });
        }

        if (avaliadosPorcentagem.length > 30) {
          this.displayLegend = false;
        }

      });

      this.resultadoFiltroAxiologia.perfis.map(resultadoFiltro => {

        this.avaliacao.avaliados.forEach(avaliados => {

          this.aproximacaoAxiologia.forEach(aprox => {
          if (avaliados.id === aprox.idAvaliado && aprox.selected === true) {
          avaliados.resultadosCiencias.forEach(resultadosCiencias => {
            if (resultadosCiencias.ciencia === 'Axiologia') {
              resultadosCiencias.resultados.forEach(resultados => {

                let verificaFator = false;
                let nomeIgual = false;
                let parcialAxiologia: number;

                if (resultados.nomeFator === resultadoFiltro.fator) {

                  this.newAvaliacaoAxiologia.forEach(newAvaliacao => {
                    if (this.newAvaliacaoAxiologia && avaliados.id === newAvaliacao.idAvaliado) {
                      newAvaliacao.data.push(
                        +resultados.valor
                      );
                      newAvaliacao.fatores.push(
                        resultados.nomeFator
                      );
                      verificaFator = true;
                    }
                  });

                  this.aproximacaoAxiologia.forEach(aproxAxio => {

                    if (avaliados.id === aproxAxio.idAvaliado) {

                      if (resultadoFiltro.ideal === undefined || resultadoFiltro.ideal === null || resultadoFiltro.ideal === 0) { // Verifica se foi selecionado valor ideal, se não, aplica o padrão 80
                        resultadoFiltro.ideal = 80;
                      }

                      parcialAxiologia = (+resultados.valor * 100) / +resultadoFiltro.ideal;

                      if (+resultados.valor > +resultadoFiltro.ideal) {
                        this.porcentagemAxiologia = 200 - parcialAxiologia;
                        this.porcentagemAxiologia = this.porcentagemAxiologia.toFixed(2);
                      } else {
                        this.porcentagemAxiologia = parcialAxiologia.toFixed(2);
                      }

                      aproxAxio.nomeFator.push(
                        resultados.nomeFator
                      );

                      aproxAxio.porcentagemAxiologia.push(
                        +this.porcentagemAxiologia
                      );
                      aproxAxio.aproximacao.push(
                        // this.porcentagemAproximacaoAxiologia
                      );

                      aproxAxio.statusAxiologia.push(
                        // this.statusAxiologia
                      );
                      nomeIgual = true;
                    }
                  });

                  if (!verificaFator) {
                    this.newAvaliacaoAxiologia.push({
                      label: avaliados.nome,
                      idAvaliado: avaliados.id,
                      data: [+resultados.valor],
                      fatores: [resultados.nomeFator]
                    });

                  }

                  if (!nomeIgual) {
                    // --- Verificação de porcentagem e status de aproximação

                    if (resultadoFiltro.ideal === undefined || resultadoFiltro.ideal === null || resultadoFiltro.ideal === 0) { // Verifica se foi selecionado valor ideal, se não, aplica o padrão 80
                      resultadoFiltro.ideal = 80;
                    }

                    parcialAxiologia = (+resultados.valor * 100) / +resultadoFiltro.ideal;
                    if (+resultados.valor > +resultadoFiltro.ideal) {
                      this.porcentagemAxiologia = 200 - parcialAxiologia;
                      this.porcentagemAxiologia = this.porcentagemAxiologia.toFixed(2);
                    } else {
                      this.porcentagemAxiologia = parcialAxiologia.toFixed(2);
                    }

                    this.aproximacaoAxiologia.push(
                      {
                        nomeAvaliado: avaliados.nome,
                        idAvaliado: avaliados.id,
                        nomeFator: [resultados.nomeFator],
                        porcentagemAxiologia: [+this.porcentagemAxiologia],
                        aproximacao: [+this.porcentagemAproximacaoAxiologia],
                        statusAxiologia: [this.statusAxiologia],
                        selected: true
                      }
                    );

                  }

              }

              }); // <-- resultadosCiencias.resultados.forEach
            } // Resultado Axiologia
          });
        }
      });
        }); // <-- this.avaliacao.avaliados.forEach
      }); // <--this.resultadoFiltroComportamento.perfis.map
    }

    this.aproximacaoAxiologia.forEach(aprox => {
      avaliadosPorcentagem.forEach(avaliadosPorcent => {
        if (aprox.idAvaliado === avaliadosPorcent.idAvaliado) {

          let calculoPorcentagem: any;
          arrayPorcentagem = [];

          arrayPorcentagem = aprox.porcentagemAxiologia;

          // Inclui os fatores Externo - Consistência e Interno - Consistência para o cálculo da média geral
          this.avaliacao.avaliados.forEach(avaliados => {
            avaliados.resultadosCiencias.forEach(resultados => {
              if (resultados.ciencia === 'Axiologia') {
                resultados.resultados.forEach(resultado => {
                  if (resultado.nomeFator === 'Externo - Consistência' && aprox.idAvaliado === avaliados.id) {
                    const parcialConsistenciaExterno = +resultado.valor * 100 / 10;
                    arrayPorcentagem.push(parcialConsistenciaExterno);
                  }
                  if (resultado.nomeFator === 'Interno - Consistência' && aprox.idAvaliado === avaliados.id) {
                    const parcialConsistenciaInterno = +resultado.valor * 100 / 10;
                    arrayPorcentagem.push(parcialConsistenciaInterno);
                  }
                });
              }
            });
          });


          calculoPorcentagem = arrayPorcentagem.reduce( (accum, curr) => accum + curr );

          const fatorDivisao = +this.setChartLabelsAxiologia.length + 2; // Adiciona 2 que corresponde aos fatores Externo - Consistência e Interno - Consistência
          // this.porcentagemAproximacaoAxiologia = calculoPorcentagem / this.setChartLabelsAxiologia.length; // Média das porcentagens
          this.porcentagemAproximacaoAxiologia = calculoPorcentagem / fatorDivisao; // Média das porcentagens

          aprox.aproximacao = [];

          aprox.aproximacao.push(
            this.porcentagemAproximacaoAxiologia.toFixed(2)
          );

          // ------ ** ----
          this.setMediaGeral(avaliadosPorcent.nome, avaliadosPorcent.idAvaliado, +this.porcentagemAproximacaoAxiologia); // Adiciona ao array de Média Geral
          // ------ ** ----

          if (this.porcentagemAproximacaoAxiologia < 40) {
            this.statusAxiologia = 'Muito Baixa';
          }
          if (this.porcentagemAproximacaoAxiologia < 60 && this.porcentagemAproximacaoAxiologia >= 40) {
            this.statusAxiologia = 'Baixa';
          }
          if (this.porcentagemAproximacaoAxiologia < 80 && this.porcentagemAproximacaoAxiologia >= 60) {
            this.statusAxiologia = 'Média';
          }
          if (this.porcentagemAproximacaoAxiologia < 100 && this.porcentagemAproximacaoAxiologia >= 80) {
            this.statusAxiologia = 'Alta';
          }
          if (this.porcentagemAproximacaoAxiologia === 100) {
            this.statusAxiologia = 'Exata';
          }

          aprox.statusAxiologia = [];

          aprox.statusAxiologia.push(
            this.statusAxiologia
          );

        }

      });
    });

    let fatorIgual: boolean;

    this.resultadoFiltroAxiologia.perfis.map(resultadoFiltro => {

        fatorIgual = false;

        this.newAvaliacaoAxiologia.forEach(newAvaliacao => {

          if (this.newAvaliacaoAxiologia && newAvaliacao.label === 'Perfil Ideal' && resultadoFiltro.fator !== null) {
            newAvaliacao.data.push(+resultadoFiltro.ideal);
            newAvaliacao.fatores.push(resultadoFiltro.fator);
            fatorIgual = true;
          }
        });
        if (!fatorIgual && resultadoFiltro.fator !== null) {
          this.newAvaliacaoAxiologia.push({
            data: [+resultadoFiltro.ideal],
            label: 'Perfil Ideal',
            fatores: [resultadoFiltro.fator]
          });
        }

      }); // <-- this.resultadoFiltroAxiologia.perfis.map

    this.newAvaliacaoAxiologia.forEach(newAvaliacao => {

      fatorIgual = false;

      this.chartDatasetsAxiologia.forEach(dataChart => {

        if (this.chartDatasetsAxiologia && newAvaliacao.idAvaliado === dataChart.id) {
          dataChart.data.push(newAvaliacao.data);
          fatorIgual = true;
        }
      });

      if (!fatorIgual) {
        this.chartDatasetsAxiologia.push({
          id: newAvaliacao.idAvaliado,
          data: newAvaliacao.data,
          label: newAvaliacao.label
        });

        cor = 'rgb(' + (Math.floor(Math.random() * 256)) +
              ',' + (Math.floor(Math.random() * 256)) +
              ',' + (Math.floor(Math.random() * 256)) + ',' + 0.7 + ')';
        bkCor = 'rgb(' + (Math.floor(Math.random() * 256)) +
              ',' + (Math.floor(Math.random() * 256)) +
              ',' + (Math.floor(Math.random() * 256)) + ',' + 0 + ')';

        this.chartColorsAxiologia.push({
              backgroundColor: bkCor,
              borderColor: cor,
              borderWidth: 2,
        });
      }
    });

    this.chartLabelsAxiologia = this.setChartLabelsAxiologia;
    this.chartDatasetsAxiologia = this.chartDatasetsAxiologia;

    this.chartColorsAxiologia.pop();

    this.chartColorsAxiologia.push({
      backgroundColor: 'rgba(18,21,24, .2)',
      borderColor: 'rgba(18,21,24, 0)',
      borderWidth: 0,
    });

  }

  updateGraficoResultadoAxiologia() {

    let cor: string;
    let bkCor: string;

    this.updateDataAxiologia = [];
    this.chartColorsAxiologia = [];

    this.aproximacaoAxiologia.forEach(a => {

          if (a.selected === true) {
          this.newAvaliacaoAxiologia.forEach(p => {
            if (p.idAvaliado === a.idAvaliado) {
              this.updateDataAxiologia.push({
              data: p.data,
              label: p.label
              });
            }
          });

          cor = 'rgb(' + (Math.floor(Math.random() * 256)) +
              ',' + (Math.floor(Math.random() * 256)) +
              ',' + (Math.floor(Math.random() * 256)) + ',' + 0.7 + ')';
          bkCor = 'rgb(' + (Math.floor(Math.random() * 256)) +
              ',' + (Math.floor(Math.random() * 256)) +
              ',' + (Math.floor(Math.random() * 256)) + ',' + 0 + ')';

          this.chartColorsAxiologia.push({
              backgroundColor: bkCor,
              borderColor: cor,
              borderWidth: 2,
        });

        }
        });

    // this.chartDatasetsAxiologia = this.updateDataAxiologia;

    setTimeout(() => {
      this.chartLabelsAxiologia = [];
      this.resultadoFiltroAxiologia.filtroFatores.map(r => {
        this.chartLabelsAxiologia.push(r);
      });
      this.chartDatasetsAxiologia.forEach(d => {
        if (d.label === 'Perfil Ideal') {
          this.updateDataAxiologia.push({
            data: d.data,
            label: d.label
          });
        }
      });
      if (this.chartDatasetsAxiologia) {
        this.chartDatasetsAxiologia = this.updateDataAxiologia;
      }
      }, 10);

    this.chartColorsMotivadores.push({
      backgroundColor: 'rgba(18,21,24, .2)',
      borderColor: 'rgba(18,21,24, 0)',
      borderWidth: 0
    });
  }

  setMediaGeral(nome: string, id: number, valor: number) {
    let nomeIgual: boolean;
    let mediaParcial: any;
    let mediaFinal: any;

    nomeIgual = false;

    if (this.mediaGeralPorcentagem) {
        this.mediaGeralPorcentagem.forEach(m => {
          if (m.idAvaliado === id) {
            m.mediasAproximacao.push(valor);

            mediaParcial = m.mediasAproximacao;

            mediaFinal = mediaParcial.reduce( (accum: any, curr: any) => accum + curr );

            this.mediaFinalPorcentagem = mediaFinal / m.mediasAproximacao.length;

            m.mediaFinal = this.mediaFinalPorcentagem.toFixed(2);

            // ----
            if (this.mediaFinalPorcentagem < 40) {
              m.statusFinal = 'Muito Baixa';
            }
            if (this.mediaFinalPorcentagem < 60 && this.mediaFinalPorcentagem >= 40) {
              m.statusFinal = 'Baixa';
            }
            if (this.mediaFinalPorcentagem < 80 && this.mediaFinalPorcentagem >= 60) {
              m.statusFinal = 'Média';
            }
            if (this.mediaFinalPorcentagem < 100 && this.mediaFinalPorcentagem >= 80) {
              m.statusFinal = 'Alta';
            }
            if (this.mediaFinalPorcentagem === 100) {
              m.statusFinal = 'Exata';
            }

            // ----

            nomeIgual = true;

          }

        });
    }
    if (!nomeIgual) {
        let status: string;
        // ----
        if (valor < 40) {
          status = 'Muito Baixa';
        }
        if (valor < 60 && valor >= 40) {
          status = 'Baixa';
        }
        if (valor < 80 && valor >= 60) {
          status = 'Média';
        }
        if (valor < 100 && valor >= 80) {
          status = 'Alta';
        }
        if (valor === 100) {
          status = 'Exata';
        }
        // ----

        this.mediaGeralPorcentagem.push({ // Adiciona ao array da média geral
          nomeAvaliado: nome,
          idAvaliado: id,
          mediasAproximacao: [valor],
          mediaFinal: valor.toFixed(2),
          statusFinal: status
        });
        this.labelNomeAvaliado.push(
          nome
        );
        this.chartDataRanking.push(
          valor.toFixed(2)
        );
    }

    this.setGraficoRanking();

  }

  setGraficoRanking() {

    let arrayLabel = [];
    let arrayData = [];
    let arrayOfObj;
    let sortedArrayOfObj;
    let newArrayLabel = [];
    let newArrayData = [];

    this.chartDataRanking = []; // reinicia o array

    if (this.mediaGeralPorcentagem) {

        this.mediaGeralPorcentagem.forEach(avaliado => {
          this.chartDataRanking.push(
            avaliado.mediaFinal
          );
        });
    }

    // =========================== Ordenar a exibição do Ranking

    arrayLabel = this.labelNomeAvaliado;
    arrayData = this.chartDataRanking;

    arrayOfObj = arrayLabel.map((d, i) => {
      return {
        label: d,
        data: arrayData[i] || 0
      };
    });


    sortedArrayOfObj = arrayOfObj.sort((a: any, b: any) => {
      return  b.data - a.data;
    });

    newArrayLabel = [];
    newArrayData = [];
    sortedArrayOfObj.forEach((d: any) => {
      newArrayLabel.push(d.label);
      newArrayData.push(d.data);
    });

    // ===========================

    this.chartDataRanking = newArrayData;
    this.chartLabelsRanking = newArrayLabel;

    this.chartDataRanking.push(0);

    this.chartDatasetsRanking = [{
      data: this.chartDataRanking, label: 'Ranking Geral'
    }];

    this.checkGraficoRanking = true;

  }

  setColorPadrao() {
    this.chartColorsAxiologia.push({
      backgroundColor: 'rgb(250, 7, 7, 0)',
      borderColor: 'rgb(0, 0, 0, 1)',
      borderWidth: 2,
    });
  }

  goResultados(event: any) {

    this.labelNomeAvaliado = [];
    this.chartDataRanking = [];
    this.checkAdaptavel = false;
    this.checkNatural = false;

    this.mediaGeralPorcentagem = []; // reset array da Média Geral para incluir os novos valores

    if (event === false) {
      this.mostraResultados = false;

    } else if (event === 'Comportamento Observado') { // Variável vem do botão "Limpar Seleção"
      // this.mostraResultados = false;

      // reset dados
      this.setChartLabelsDISC = [];
      this.chartDatasetsDISC = [];
      this.chartLabelsDISC = [];
      this.chartColorsDISC = [];
      this.newAvaliacaoDisc = [];
      this.aproximacaoDisc = [];
      this.porcentagemAproximacaoDisc = [];

      // reset dados Disc Natural
      this.setChartLabelsDISCNATURAL = [];
      this.chartDatasetsDISCNATURAL = [];
      this.chartLabelsDISCNATURAL = [];
      this.chartColorsDISCNATURAL = [];
      this.newAvaliacaoDiscNatural = [];
      this.aproximacaoDiscNatural = [];
      this.porcentagemAproximacaoDiscNatural = [];

      // reset dados Disc Adaptavel
      this.setChartLabelsDISCADAPTAVEL = [];
      this.chartDatasetsDISCADAPTAVEL = [];
      this.chartLabelsDISCADAPTAVEL = [];
      this.chartColorsDISCADAPTAVEL = [];
      this.newAvaliacaoDiscAdaptavel = [];
      this.aproximacaoDiscAdaptavel = [];
      this.porcentagemAproximacaoDiscAdaptavel = [];

      // ---
      setTimeout(() => {
        this.limpaSelecao();
      }, 100);

    } else if (event === 'Motivadores') { // Variável vem do botão "Limpar Seleção"
      // this.mostraResultados = false;

      // reset dados
      this.setChartLabelsMotivadores = [];
      this.chartDatasetsMotivadores = [];
      this.chartLabelsMotivadores = [];
      this.chartColorsMotivadores = [];
      this.newAvaliacaoMotivadores = [];
      this.aproximacaoMotivadores = [];
      this.porcentagemAproximacaoMotivadores = [];
      // ---
      setTimeout(() => {
        this.limpaSelecao();
      }, 100);

    } else if (event === 'Axiologia') { // Variável vem do botão "Limpar Seleção"
    // this.mostraResultados = false;

    // reset dados
    this.setChartLabelsAxiologia = [];
    this.chartDatasetsAxiologia = [];
    this.chartLabelsAxiologia = [];
    this.chartColorsAxiologia = [];
    this.newAvaliacaoAxiologia = [];
    this.aproximacaoAxiologia = [];
    this.porcentagemAproximacaoAxiologia = [];
    // ---
    setTimeout(() => {
      this.limpaSelecao();
    }, 100);

  } else if (event === true && this.resultadoFiltroAxiologia || this.resultadoFiltroComportamento || this.resultadoFiltroMotivadores) {
      this.mostraResultados = event;

      if (this.resultadoFiltroAxiologia) { // Carrega o gráfico Axiologia e comparações
        // reset dados
        this.setChartLabelsAxiologia = [];
        this.chartDatasetsAxiologia = [];
        this.chartLabelsAxiologia = [];
        this.chartColorsAxiologia = [];
        this.newAvaliacaoAxiologia = [];
        this.aproximacaoAxiologia = [];
        this.porcentagemAproximacaoAxiologia = [];
        // ---
        this.setGraficoResultadoAxiologia();
      }

      if (this.resultadoFiltroComportamento) { // Carrega o gráfico Comportamento e comparações
        // reset dados Disc
        this.setChartLabelsDISC = [];
        this.chartDatasetsDISC = [];
        this.chartLabelsDISC = [];
        this.chartColorsDISC = [];
        this.newAvaliacaoDisc = [];
        this.aproximacaoDisc = [];
        this.porcentagemAproximacaoDisc = [];

        // reset dados Disc Natural
        this.setChartLabelsDISCNATURAL = [];
        this.chartDatasetsDISCNATURAL = [];
        this.chartLabelsDISCNATURAL = [];
        this.chartColorsDISCNATURAL = [];
        this.newAvaliacaoDiscNatural = [];
        this.aproximacaoDiscNatural = [];
        this.porcentagemAproximacaoDiscNatural = [];

        // reset dados Disc Adaptavel
        this.setChartLabelsDISCADAPTAVEL = [];
        this.chartDatasetsDISCADAPTAVEL = [];
        this.chartLabelsDISCADAPTAVEL = [];
        this.chartColorsDISCADAPTAVEL = [];
        this.newAvaliacaoDiscAdaptavel = [];
        this.aproximacaoDiscAdaptavel = [];
        this.porcentagemAproximacaoDiscAdaptavel = [];


        // ---
        this.setGraficoResultadoComportamento();
        this.setGraficoResultadoComportamentoNatural();
        this.setGraficoResultadoComportamentoAdaptavel();
      }

      if (this.resultadoFiltroMotivadores) { // Carrega o gráfico Motivadores e comparações
        // reset dados
        this.setChartLabelsMotivadores = [];
        this.chartDatasetsMotivadores = [];
        this.chartLabelsMotivadores = [];
        this.chartColorsMotivadores = [];
        this.newAvaliacaoMotivadores = [];
        this.aproximacaoMotivadores = [];
        this.porcentagemAproximacaoMotivadores = [];
        // ---
        this.setGraficoResultadoMotivadores();
      }

      this.openModalResultados.nativeElement.click();

    } else {
      this.alertService.showAlertWarning('Você precisa selecionar ao menos um fator ou competência.');
      this.mostraResultados = false;
    }
  }

  limpaSelecao() {

    this.mediaGeralPorcentagem = [];

    if (this.resultadoFiltroAxiologia) { // verifica filtro Axiologia
      // reset dados
      this.setChartLabelsAxiologia = [];
      this.chartDatasetsAxiologia = [];
      this.chartLabelsAxiologia = [];
      this.chartColorsAxiologia = [];
      this.newAvaliacaoAxiologia = [];
      this.aproximacaoAxiologia = [];
      this.porcentagemAproximacaoAxiologia = [];
      // ---
      this.setGraficoResultadoAxiologia();
    }

    if (this.resultadoFiltroComportamento) { // verifica filtro Comportamento
      // reset dados
      this.setChartLabelsDISC = [];
      this.chartDatasetsDISC = [];
      this.chartLabelsDISC = [];
      this.chartColorsDISC = [];
      this.newAvaliacaoDisc = [];
      this.aproximacaoDisc = [];
      this.porcentagemAproximacaoDisc = [];

      this.setChartLabelsDISCNATURAL = [];
      this.chartDatasetsDISCNATURAL = [];
      this.chartLabelsDISCNATURAL = [];
      this.chartColorsDISCNATURAL = [];
      this.newAvaliacaoDiscNatural = [];
      this.aproximacaoDiscNatural = [];
      this.porcentagemAproximacaoDiscNatural = [];

      this.setChartLabelsDISCADAPTAVEL = [];
      this.chartDatasetsDISCADAPTAVEL = [];
      this.chartLabelsDISCADAPTAVEL = [];
      this.chartColorsDISCADAPTAVEL = [];
      this.newAvaliacaoDiscAdaptavel = [];
      this.aproximacaoDiscAdaptavel = [];
      this.porcentagemAproximacaoDiscAdaptavel = [];
      // ---
      this.setGraficoResultadoComportamento();
      this.setGraficoResultadoComportamentoNatural();
      this.setGraficoResultadoComportamentoAdaptavel();
    }

    if (this.resultadoFiltroMotivadores) { // verifica filtro Motivadores
      // reset dados
      this.setChartLabelsMotivadores = [];
      this.chartDatasetsMotivadores = [];
      this.chartLabelsMotivadores = [];
      this.chartColorsMotivadores = [];
      this.newAvaliacaoMotivadores = [];
      this.aproximacaoMotivadores = [];
      this.porcentagemAproximacaoMotivadores = [];
      // ---
      this.setGraficoResultadoMotivadores();
    }
  }

  gerarPlanilha() {
    const filtroAxiologia = this.resultadoFiltroAxiologia;
    const filtroComportamento = this.resultadoFiltroComportamento;
    const filtroMotivadores = this.resultadoFiltroMotivadores;

    this.enviaFiltro(filtroAxiologia, filtroComportamento, filtroMotivadores); // Chama a função que gera a planilha
  }

  /*
  * Envia o Filtro para o serviço gerar a planilha e executar o download
  */
  enviaFiltro(filtroAxiologia, filtroComportamento, filtroMotivadores) {

    this.printWait = true;

    const arrayCiencias: Array<LucorApiModelsNewFiltroCiencia> = [
      // filtroAxiologia,
      // filtroComportamento,
      // filtroMotivadores
    ];

    if (filtroAxiologia !== undefined) {
      arrayCiencias.push(filtroAxiologia);
    }
    if (filtroComportamento !== undefined) {
      arrayCiencias.push(filtroComportamento);
    }
    if (filtroMotivadores !== undefined) {
      arrayCiencias.push(filtroMotivadores);
    }

    // console.log('Array Ciências', arrayCiencias);

    if (arrayCiencias.length === 0) {
      this.resultadoFiltroServico = {
          ciencias: [
            {
              nomeCiencia: 'Axiologia',
              filtroFatores: [],
              perfis: [
                {
                  minimo: 0,
                  ideal: 0,
                  maximo: 0,
                  fator: null
                }
              ]
            },
        {
              nomeCiencia: 'Comportamento Observado',
              filtroFatores: [],
              perfis: [
                {
                  minimo: 0,
                  ideal: 0,
                  maximo: 0,
                  fator: null
                }
              ]
            },
        {
              nomeCiencia: 'Motivadores',
              filtroFatores: [],
              perfis: [
                {
                  minimo: 0,
                  ideal: 0,
                  maximo: 0,
                  fator: null
                }
              ]
            }
          ],
          destacarFavoritos: true
      };
    } else {
      this.resultadoFiltroServico = {
        ciencias: arrayCiencias
      };
    }

    // console.log('Resultado Filtro', this.resultadoFiltroServico);

    this.subs.sink = this.avaliacoesService.getGerarPlanilhaAvaliacaoByIdAsync(this.idAvaliacao, this.resultadoFiltroServico, 'response')
    .subscribe(
      planilhaFile => {
        // debugger;
        const fileName = this.getFileNameFromHttpResponse(planilhaFile);
        this.downLoadFile(planilhaFile.body, 'application/ms-excel', fileName);
        this.alertService.showAlertSuccess('Sua planilha está pronta! Download concluído do arquivo ' + fileName + '.');
      }, (err) => {
        console.log(err);
        this.alertService.showAlertWarning('Algo deu errado! Por favor, verifique sua seleção e tente novamente.');
        this.printWait = false;
      });
  }

  downLoadFile(data: any, type: string, fileName: string) {
    const blob = new Blob([data], { type });
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    if (fileName) {
      downloadLink.setAttribute('download', fileName);
      downloadLink.setAttribute('id', 'buttomDownload');
    }
    document.body.appendChild(downloadLink);
    downloadLink.click();
    this.printWait = false;
  }

  getFileNameFromHttpResponse(httpResponse: HttpResponse<MicrosoftAspNetCoreMvcFileContentResult>): string {
    const contentDispositionHeader = httpResponse.headers.get('Content-Disposition');
    const result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
    return result.replace(/"/g, '');
  }

  /*
  customComparator(itemA, itemB) { // Adicionado para customizar a função 'comparator' do OrderBy
    return  itemA - itemB; // Corrige a situação que retorna algo assim: [1, 10, 12, 15, 5, 8, 9]
    // return itemA > itemB ? 1 : -1;
    // return itemA < itemB ? -1 : itemA > itemB ? 1 : 0;
  }
*/

  customComparator(a: any, b: any) {
    const nums1 = a;
    const nums2 = b;

    for (let i = 0; i < nums1.length; i++) {
        if (nums2[i]) { // assuming 5..2 is invalid
            if (nums1[i] !== nums2[i]) {
               return nums1[i] - nums2[i];
            } // else continue
        } else {
            return 1; // no second number in b
        }
    }
    return -1; // was missing case b.len > a.len
  }

  customComparatorMedia(a: any, b: any) {
    const nums1 = a.split('.');
    const nums2 = b.split('.');

    for (let i = 0; i < nums1.length; i++) {
        if (nums2[i]) { // assuming 5..2 is invalid
            if (nums1[i] !== nums2[i]) {
               return nums1[i] - nums2[i];
            } // else continue
        } else {
            return 1; // no second number in b
        }
    }
    return -1; // was missing case b.len > a.len
  }

  goBack(): void {
    this.location.back();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
function forkjoin(arg0: undefined[]) {
  throw new Error('Function not implemented.');
}

