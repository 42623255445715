import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { AvaliacaoApiService } from 'app/api/avaliacaoApi.service';
import { CargoApiService } from 'app/api/cargoApi.service';
import { ClienteApiService } from 'app/api/clienteApi.service';
import { ServicoApiService } from 'app/api/servicoApi.service';
import { RegisterService } from 'app/api/register.service';
import { MensagensApiService } from 'app/api/mensagensApi.service';
import { 
  LucorApiModelsAvaliacao,
  LucorApiModelsNewNewMensagemEmail,
  LucorApiModelsPessoaJuridica,
  LucorApiModelsServico } from 'app/model/models';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { DataTableDirective } from 'angular-datatables';
import { LanguageDataTable } from 'app/shared/datatable/translate-datatable';

@Component({
  selector: 'app-cargos-cadastrar',
  templateUrl: './cargos-cadastrar.component.html',
  styleUrls: ['./cargos-cadastrar.component.css']
})
export class CargosCadastrarComponent implements OnInit, OnDestroy {

  avaliacoesDrop: LucorApiModelsAvaliacao[];
  avaliacoesSelecionadas = [];
  clientes$: Observable<LucorApiModelsPessoaJuridica[]>;
  servico$: Observable<LucorApiModelsServico[]>;
  servicoSelect: string;
  addFormBasico: FormGroup;
  usuarioLogado: number;
  usuarioNome: string;
  dadosBasicosSalvos = false;
  initUnidades: number;
  unidades: number;
  
  checkAvaliacao = false;
  nomeCliente: string;
  idCliente: number;
  nomeCargo: string;
  idCargo: number;
  idAvaliacao: number;
  idServico: number;
  submitted = false;
  carregando = false;
  order = 'nomeArquivoOrigem';
  reverse = false;
  atualizando = false;

  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  orderCondition: number = 3;

  @ViewChild('linkBasicsData') linkBasicsData: ElementRef;
  @ViewChild('linkAssociation') linkAssociation: ElementRef;
  @ViewChild('linkProfile') linkProfile: ElementRef;
  @ViewChild('basicsData') basicsData: ElementRef;
  @ViewChild('association') association: ElementRef;
  @ViewChild('profile') profile: ElementRef;

  private subs = new SubSink();

  constructor(
    private cargosService: CargoApiService,
    private usuariosService: RegisterService,
    private avaliacoesService: AvaliacaoApiService,
    private clientesService: ClienteApiService,
    private servicosService: ServicoApiService,
    private mensagemService: MensagensApiService,
    private formBuilder: FormBuilder,
    private location: Location,
    private router: Router,
    private alertService: AlertModalService
  ) {
    this.avaliacoesDrop = [];
    this.avaliacoesSelecionadas = [];
    this.addFormBasico = this.formBuilder.group({
      titulo: ['', Validators.required],
      descricao: [''],
      area: [''],
      nivel: [''],
      pessoaJuridicaId: [''],
      servicoId: [null], 
      qtdeUnidades: [0],
      addUnidades: [0],
      urlLink: [''],
    });
  }

  ngOnInit() {
    this.subs.sink = this.usuariosService.getUsuarioLogadoAsync()
    .subscribe(usuario => {
        this.usuarioLogado = usuario.pessoaJuridicaId;
        this.usuarioNome = usuario.userName;
        if (this.usuarioLogado !== null && typeof this.usuarioLogado !== 'undefined' ) {
          this.idCliente = this.usuarioLogado;
          if (this.idCliente) {
            this.addFormBasico.setValue({
              titulo: null,
              descricao: null,
              area: null,
              nivel: null,
              pessoaJuridicaId: this.idCliente || 0,
              servicoId: null,
              qtdeUnidades: 0,
              addUnidades: 0,
              urlLink: null,
            });
            this.getAvaliacoesByCliente(this.idCliente);
            this.getServicoByCliente(this.idCliente);
          }
        } else {
          this.getClientes();
        }
      });
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        responsive: true,
        order: [],
        language: LanguageDataTable.portugues_datatables
      };
  }

  get f() { return this.addFormBasico.controls; }

  setIdCliente(event: number) {
    this.avaliacoesDrop = [];
    this.avaliacoesSelecionadas = [];
    this.idCliente = event;
    this.servico$ = undefined;
    this.idServico = undefined;
    this.servicoSelect = undefined;

    if (!this.idCliente) {
      return this.idCliente = undefined;
    } else {
      this.addFormBasico.patchValue({ pessoaJuridicaId: this.idCliente });
      this.getServicoByCliente(this.idCliente);
      this.getAvaliacoesByCliente(this.idCliente);
    }
  }

  getClientes() {
    let clientes;
    this.clientes$ = this.clientesService.getClientesAsync().pipe(share());
    this.clientes$.forEach(cliente => {
      clientes = cliente;
      if (clientes.length === 1) {clientes.forEach(clienteId =>  this.getServicoByCliente(clienteId.id));
      }
    });
  }

  getServicoByCliente(id: number) {
    this.servico$ = this.servicosService.getServicoByClienteAsync(id);
  }

  selectServico(id: number) {
    this.idServico = id;
    if (this.idServico) {
      this.addFormBasico.patchValue({ servicoId: this.idServico });
    }
  }

  getAvaliacoes() {
    this.carregando = true;
    this.subs.sink = this.avaliacoesService.getAvaliacoesAsync()
    .subscribe(
      avaliacoes => {
            avaliacoes.forEach(avaliacao => {
              if (+avaliacao.clienteId === +this.idCliente) {
                this.avaliacoesDrop.push({ 
                  nomeArquivoOrigem: avaliacao.nomeArquivoOrigem, 
                  createdAt: avaliacao.createdAt,
                  updatedAt: avaliacao.updatedAt,
                  id: avaliacao.id 
                });
              }
            });
            this.carregando = false;
      });
  }

  getAvaliacoesByCliente(id: number) {
    this.carregando = true;
    this.subs.sink = this.avaliacoesService.getAvaliacoesByCliente(id)
      .subscribe(
        avaliacoes => {
          avaliacoes.forEach(avaliacao => {
            this.avaliacoesDrop.push({ 
              nomeArquivoOrigem: avaliacao.nomeArquivoOrigem, 
              createdAt: avaliacao.createdAt,
              updatedAt: avaliacao.updatedAt,
              id: avaliacao.id 
            });
          });

          this.carregando = false;
      });
  }

  saveCargo(): void {

    this.submitted = true;

    if (this.addFormBasico.invalid) { return; }

    this.subs.sink = this.cargosService.addCargoAsync(this.addFormBasico.value)
    .subscribe(success => {
      this.dadosBasicosSalvos = true;
      this.alertService.showAlertSuccess('Dados Básicos cadastrados com sucesso!');
      this.idCargo = success.id;
      this.linkBasicsData.nativeElement.classList.remove('active');
      this.basicsData.nativeElement.classList.remove('active', 'show');
      this.linkProfile.nativeElement.classList.remove('disabled');
      this.linkAssociation.nativeElement.classList.remove('disabled');
      this.linkProfile.nativeElement.classList.add('active');
      this.profile.nativeElement.classList.add('active', 'show');

      this.nomeCargo = success.titulo;
      this.unidades = success.qtdeUnidades;

      if (!this.nomeCliente) {
        this.nomeCliente = "ID: " + success.pessoaJuridicaId;
      }
      
      if (this.usuarioLogado && this.unidades !== this.initUnidades) {
        this.enviarNotificacao(this.unidades, this.nomeCargo, this.idCargo, this.nomeCliente, false);
      }

    }, error => {
      this.alertService.showAlertDanger('Erro ao cadastrar Cargo. Por favor, verifique se existe um título ou tente mais tarde.');
    });
    this.goTop();

  }

  atualizarCargo(): void {

    this.submitted = true;

    if (this.addFormBasico.invalid) { return; }

    this.subs.sink = this.cargosService.atualizarCargoAsync(this.idCargo, this.addFormBasico.value)
    .subscribe(success => {
      this.dadosBasicosSalvos = true;
      this.alertService.showAlertSuccess('Dados Básicos atualizados com sucesso!');
      this.idCargo = success.id;
      this.linkBasicsData.nativeElement.classList.remove('active');
      this.basicsData.nativeElement.classList.remove('active', 'show');
      this.linkProfile.nativeElement.classList.remove('disabled');
      this.linkAssociation.nativeElement.classList.remove('disabled');
      this.linkProfile.nativeElement.classList.add('active');
      this.profile.nativeElement.classList.add('active', 'show');

      let cargo = success.titulo;
      let addUnidades = success.qtdeUnidades;

      if (!this.nomeCliente) {
        this.nomeCliente = "ID: " + success.pessoaJuridicaId;
      }
      
      if (this.usuarioLogado && addUnidades !== this.unidades) {
        this.enviarNotificacao(addUnidades, cargo, this.idCargo, this.nomeCliente, true);
      }

    }, error => {
      this.alertService.showAlertDanger('Erro ao atualizar Cargo. Por favor, verifique se existe um título ou tente mais tarde.');
    });
    this.goTop();
  }

  onChangeAvaliacao(event: any) {

    this.atualizando = true;

    if (event.target.checked === true) {
      this.avaliacoesDrop.forEach(avaliacao => {
        if (+event.target.value === +avaliacao.id) {
          this.avaliacoesSelecionadas.push(avaliacao);
          this.avaliacoesDrop = this.avaliacoesDrop.filter(drop => {
            if (+drop.id !== +event.target.value) {
              return drop;
            }
          });

          this.subs.sink = this.cargosService.associarAvaliacaoCargo(this.idCargo, avaliacao.id)
            .subscribe(success => {
              // this.alertService.showAlertSuccess('Avaliação associada ao cargo com sucesso!');
              this.atualizando = false;
            }, error => {
              this.alertService.showAlertDanger('Erro ao associar avaliação. Tente mais tarde.');
              console.error(error);
              this.atualizando = false;
            });
        }
      });
    } else {
      this.avaliacoesSelecionadas.forEach(avaliacao => {
        if (+avaliacao.id === +event.target.value) {
          this.avaliacoesDrop = this.avaliacoesDrop.filter(drop => +drop.id !== +avaliacao.id);
          this.avaliacoesDrop.push({
            nomeArquivoOrigem: avaliacao.nomeArquivoOrigem,
            createdAt: avaliacao.createdAt,
            updatedAt: avaliacao.updatedAt,
            id: avaliacao.id
          });
          this.subs.sink = this.cargosService.desassociarAvaliacaoCargo(this.idCargo, avaliacao.id)
              .subscribe(success => {
                console.log('Avaliação dessassociada: ', avaliacao.nomeArquivoOrigem);
                this.atualizando = false;
              }, error => {
                console.error(error);
                this.atualizando = false;
              });
        }

        this.avaliacoesSelecionadas = this.avaliacoesSelecionadas.filter(selecao => {
          if (+event.target.value !== +selecao.id) {
            return selecao;
          }
        });
      });
    }
    return this.avaliacoesSelecionadas;
  }

  saveAssociacao() {
    this.alertService.showAlertSuccess('Cargo cadastrado com sucesso!');
    setTimeout(() => {
      const link: any[] = ['/cargos'];
      this.router.navigate(link);
    }, 1000);
  }

  goTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  goBack(): void {
    this.location.back();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private enviarNotificacao(unidades, cargo, idCargo, cliente, reenvio) {

    let mensagem: string;
    let assunto = `[Novo Cargo] criado por ${this.usuarioNome}`;

    if (!reenvio) {
      mensagem = `<html> <head> <title>Notificações LUCOR</title> <meta http-equiv="Content-Type" content="text/html; charset=utf-8"> </head> <body bgcolor="#e4e4e4" leftmargin="0" topmargin="0" marginwidth="0" marginheight="0" style="width: 680px; margin: 0 auto;"> <table style="font-family: arial, sans serif; text-align: center;" id="Tabela_01" width="640" height="490" border="0" cellpadding="0" cellspacing="0"> <tr> <td> <img src="https://www.lucor.com.br/img/topo-email-lucor.jpg" width="680" height="141" alt="LUCOR"></td></tr><tr style="background-color: #FFFFFF;"> <td> <p style="padding-top: 20px; font-size: 24px; line-height: 24px; font-weight: bold;">Olá, Luciana! :)</p></td></tr><tr style="background-color: #FFFFFF;"> <td> <p style="font-size: 16px; line-height: 26px; padding: 10px 20px;"><br>Houve o cadastro de um novo cargo com a inclusão de ${unidades}unidades.<br><br>Cargo: <strong><a href="https://applucor.com.br/cargos/${idCargo}">${cargo}</a></strong>.<br>Cliente: <strong>${cliente}</strong><br>Usuário: <strong>${this.usuarioNome}</strong></p></td></tr><tr style="background-color: #FFFFFF;"> <td><a href="https://applucor.com.br/cargos/${idCargo}" target="_blank" type="button" style="background-color: #82d0f5; padding: 10px 20px; font-size: 22px; font-weight: bold; border-radius: 8px 8px; border: none; cursor: pointer; margin: 20px auto 20px; display: table; text-decoration: none; color: #373431">Ver o cargo</a></td></tr><tr> <td><br><small>Mensagem enviada automaticamente. Não é necessário responder. <br><br></small></td></tr><tr> <td> <p style="font-size: 16px; font-weight: bold; padding: 20px 10px;">www.lucor.com.br</p></td></tr></table> </body> </html>`;
    } else {
      mensagem = `<html> <head> <title>Notificações LUCOR</title> <meta http-equiv="Content-Type" content="text/html; charset=utf-8"> </head> <body bgcolor="#e4e4e4" leftmargin="0" topmargin="0" marginwidth="0" marginheight="0" style="width: 680px; margin: 0 auto;"> <table style="font-family: arial, sans serif; text-align: center;" id="Tabela_01" width="640" height="490" border="0" cellpadding="0" cellspacing="0"> <tr> <td> <img src="https://www.lucor.com.br/img/topo-email-lucor.jpg" width="680" height="141" alt="LUCOR"></td></tr><tr style="background-color: #FFFFFF;"> <td> <p style="padding-top: 20px; font-size: 24px; line-height: 24px; font-weight: bold;">Olá, Luciana! :)</p></td></tr><tr style="background-color: #FFFFFF;"> <td> <p style="font-size: 16px; line-height: 26px; padding: 10px 20px;"><br>Houve uma <strong>alteração</strong> no cadastro de um novo cargo com a inclusão de ${unidades}unidades.<br><br>Cargo: <strong><a href="https://applucor.com.br/cargos/${idCargo}">${cargo}</a></strong>.<br>Cliente: <strong>${cliente}</strong><br>Usuário: <strong>${this.usuarioNome}</strong></p></td></tr><tr style="background-color: #FFFFFF;"> <td><a href="https://applucor.com.br/cargos/${idCargo}" target="_blank" type="button" style="background-color: #82d0f5; padding: 10px 20px; font-size: 22px; font-weight: bold; border-radius: 8px 8px; border: none; cursor: pointer; margin: 20px auto 20px; display: table; text-decoration: none; color: #373431">Ver o cargo</a></td></tr><tr> <td><br><small>Porque você está recebendo esta mensagem?<br>Ao criar o novo cargo o cliente salvou os dados básicos e antes de encerrar fez alguma alteração.<br><br>Mensagem enviada automaticamente. Não é necessário responder. <br><br></small></td></tr><tr> <td> <p style="font-size: 16px; font-weight: bold; padding: 20px 10px;">www.lucor.com.br</p></td></tr></table> </body> </html>`;
    }

    let notificacao: LucorApiModelsNewNewMensagemEmail = {
      "destinatarios": [
        "luciana.orso@lucor.com.br"
      ],
      "assunto": assunto,
      "corpo": mensagem,
      "corpoEhHtml": true,
      "cc": ["fabricio.soares@lucor.com.br"],
      "cco": [],
      "tipoMensagem": null,
      "enviarImediatamente": true
    }
    
    this.mensagemService.addRacunhoEmailAsync(notificacao)
      .subscribe(enviado => { console.log(enviado)}, error => console.error(error));
    
  }

}
