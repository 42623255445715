import { publishReplay, refCount } from 'rxjs/operators';
/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.45
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { LucorApiModelsCiencia } from '../model/lucorApiModelsCiencia';
import { LucorApiModelsFator } from '../model/lucorApiModelsFator';
import { LucorApiModelsNewPerfil } from '../model/lucorApiModelsNewPerfil';
import { LucorApiModelsNivel } from '../model/lucorApiModelsNivel';
import { LucorApiModelsUpdFator } from '../model/lucorApiModelsUpdFator';
import { LucorApiModelsUpdFiltroFator } from '../model/lucorApiModelsUpdFiltroFator';
import { LucorApiModelsUpdNivel } from '../model/lucorApiModelsUpdNivel';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { environment } from 'environments/environment';


@Injectable()
export class CienciaApiService {

    protected basePath = environment.apiBasePath;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    _ciencias: Observable<any> = null; // Variável criada para utilizar o Cache

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    clearCache() {
        this._ciencias = null; // Limpa a variável para excluir o Cache das avaliações. ###
    }

    /**
     * Obter Ciência pelo Id
     *
     * @param cienciaId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCienciaByIdAsync(cienciaId: number, observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsCiencia>;
    public getCienciaByIdAsync(cienciaId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsCiencia>>;
    public getCienciaByIdAsync(cienciaId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsCiencia>>;
    public getCienciaByIdAsync(cienciaId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (cienciaId === null || cienciaId === undefined) {
            throw new Error('Required parameter cienciaId was null or undefined when calling getCienciaByIdAsync.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<LucorApiModelsCiencia>(`${this.basePath}/ciencia/${encodeURIComponent(String(cienciaId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Listar Ciências
     *
     * @param withoutChildren
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCienciasAsync(withoutChildren?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<LucorApiModelsCiencia>>;
    public getCienciasAsync(withoutChildren?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LucorApiModelsCiencia>>>;
    public getCienciasAsync(withoutChildren?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LucorApiModelsCiencia>>>;
    public getCienciasAsync(withoutChildren?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (withoutChildren !== undefined && withoutChildren !== null) {
            queryParameters = queryParameters.set('withoutChildren', <any>withoutChildren);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [];

        if (!this._ciencias) {
            this._ciencias = this.httpClient.get<Array<LucorApiModelsCiencia>>(`${this.basePath}/ciencia`,
                {
                    params: queryParameters,
                    withCredentials: this.configuration.withCredentials,
                    headers,
                    observe,
                    reportProgress
                }
            ).pipe(publishReplay(1), refCount());
        }
        return this._ciencias;
    }

    /**
     * Listar Níveis Configurados para a Ciência
     *
     * @param cienciaId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNiveisCiencia(cienciaId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<LucorApiModelsNivel>>;
    public getNiveisCiencia(cienciaId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LucorApiModelsNivel>>>;
    public getNiveisCiencia(cienciaId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LucorApiModelsNivel>>>;
    public getNiveisCiencia(cienciaId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (cienciaId === null || cienciaId === undefined) {
            throw new Error('Required parameter cienciaId was null or undefined when calling getNiveisCiencia.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [];

        return this.httpClient.get<Array<LucorApiModelsNivel>>(`${this.basePath}/ciencia/${encodeURIComponent(String(cienciaId))}/niveis`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Listar Perfis Configurados para a Ciência
     *
     * @param cienciaId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPerfisCiencia(cienciaId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<LucorApiModelsNewPerfil>>;
    public getPerfisCiencia(cienciaId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LucorApiModelsNewPerfil>>>;
    public getPerfisCiencia(cienciaId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LucorApiModelsNewPerfil>>>;
    public getPerfisCiencia(cienciaId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (cienciaId === null || cienciaId === undefined) {
            throw new Error('Required parameter cienciaId was null or undefined when calling getPerfisCiencia.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [];

        return this.httpClient.get<Array<LucorApiModelsNewPerfil>>(`${this.basePath}/ciencia/${encodeURIComponent(String(cienciaId))}/perfis`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Listar Perfis Configurados para a Ciência Especificamente para um Cliente
     *
     * @param cienciaId
     * @param clienteId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPerfisCienciaCliente(cienciaId: number, clienteId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<LucorApiModelsNewPerfil>>;
    public getPerfisCienciaCliente(cienciaId: number, clienteId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LucorApiModelsNewPerfil>>>;
    public getPerfisCienciaCliente(cienciaId: number, clienteId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LucorApiModelsNewPerfil>>>;
    public getPerfisCienciaCliente(cienciaId: number, clienteId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (cienciaId === null || cienciaId === undefined) {
            throw new Error('Required parameter cienciaId was null or undefined when calling getPerfisCienciaCliente.');
        }

        if (clienteId === null || clienteId === undefined) {
            throw new Error('Required parameter clienteId was null or undefined when calling getPerfisCienciaCliente.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<LucorApiModelsNewPerfil>>(`${this.basePath}/ciencia/${encodeURIComponent(String(cienciaId))}/perfis/cliente/${encodeURIComponent(String(clienteId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Atualizar Dados de um Fator
     *
     * @param cienciaId
     * @param fatorId
     * @param updFator
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateFatorCiencia(cienciaId: number, fatorId: number, updFator: LucorApiModelsUpdFator, observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsFator>;
    public updateFatorCiencia(cienciaId: number, fatorId: number, updFator: LucorApiModelsUpdFator, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsFator>>;
    public updateFatorCiencia(cienciaId: number, fatorId: number, updFator: LucorApiModelsUpdFator, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsFator>>;
    public updateFatorCiencia(cienciaId: number, fatorId: number, updFator: LucorApiModelsUpdFator, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (cienciaId === null || cienciaId === undefined) {
            throw new Error('Required parameter cienciaId was null or undefined when calling updateFatorCiencia.');
        }

        if (fatorId === null || fatorId === undefined) {
            throw new Error('Required parameter fatorId was null or undefined when calling updateFatorCiencia.');
        }

        if (updFator === null || updFator === undefined) {
            throw new Error('Required parameter updFator was null or undefined when calling updateFatorCiencia.');
        }

        this.clearCache();

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<LucorApiModelsFator>(`${this.basePath}/ciencia/${encodeURIComponent(String(cienciaId))}/fator/${encodeURIComponent(String(fatorId))}`,
            updFator,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Definir Palavras-Chave Associadas aos Fatores da Ciência
     *
     * @param cienciaId
     * @param filtroFator
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateFatoresCiencia(cienciaId: number, filtroFator: LucorApiModelsUpdFiltroFator, observe?: 'body', reportProgress?: boolean): Observable<Array<LucorApiModelsFator>>;
    public updateFatoresCiencia(cienciaId: number, filtroFator: LucorApiModelsUpdFiltroFator, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LucorApiModelsFator>>>;
    public updateFatoresCiencia(cienciaId: number, filtroFator: LucorApiModelsUpdFiltroFator, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LucorApiModelsFator>>>;
    public updateFatoresCiencia(cienciaId: number, filtroFator: LucorApiModelsUpdFiltroFator, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (cienciaId === null || cienciaId === undefined) {
            throw new Error('Required parameter cienciaId was null or undefined when calling updateFatoresCiencia.');
        }

        if (filtroFator === null || filtroFator === undefined) {
            throw new Error('Required parameter filtroFator was null or undefined when calling updateFatoresCiencia.');
        }

        this.clearCache();

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Array<LucorApiModelsFator>>(`${this.basePath}/ciencia/${encodeURIComponent(String(cienciaId))}/fator`,
            filtroFator,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Atualizar Configuração de Nivel para a Ciência
     *
     * @param nivelId
     * @param nivel
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateNivelCiencia(nivelId: number, nivel: LucorApiModelsUpdNivel, observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsNivel>;
    public updateNivelCiencia(nivelId: number, nivel: LucorApiModelsUpdNivel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsNivel>>;
    public updateNivelCiencia(nivelId: number, nivel: LucorApiModelsUpdNivel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsNivel>>;
    public updateNivelCiencia(nivelId: number, nivel: LucorApiModelsUpdNivel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (nivelId === null || nivelId === undefined) {
            throw new Error('Required parameter nivelId was null or undefined when calling updateNivelCiencia.');
        }

        if (nivel === null || nivel === undefined) {
            throw new Error('Required parameter nivel was null or undefined when calling updateNivelCiencia.');
        }

        this.clearCache();

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<LucorApiModelsNivel>(`${this.basePath}/ciencia/niveis/${encodeURIComponent(String(nivelId))}`,
            nivel,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Atualizar Configuração de Perfis para a Ciência
     *
     * @param cienciaId
     * @param perfis
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePerfisCiencia(cienciaId: number, perfis: Array<LucorApiModelsNewPerfil>, observe?: 'body', reportProgress?: boolean): Observable<Array<LucorApiModelsNewPerfil>>;
    public updatePerfisCiencia(cienciaId: number, perfis: Array<LucorApiModelsNewPerfil>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LucorApiModelsNewPerfil>>>;
    public updatePerfisCiencia(cienciaId: number, perfis: Array<LucorApiModelsNewPerfil>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LucorApiModelsNewPerfil>>>;
    public updatePerfisCiencia(cienciaId: number, perfis: Array<LucorApiModelsNewPerfil>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (cienciaId === null || cienciaId === undefined) {
            throw new Error('Required parameter cienciaId was null or undefined when calling updatePerfisCiencia.');
        }

        if (perfis === null || perfis === undefined) {
            throw new Error('Required parameter perfis was null or undefined when calling updatePerfisCiencia.');
        }

        this.clearCache();

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Array<LucorApiModelsNewPerfil>>(`${this.basePath}/ciencia/${encodeURIComponent(String(cienciaId))}/perfis`,
            perfis,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Atualizar Configuração de Perfis para a Ciência Especificamente para um Cliente
     *
     * @param cienciaId
     * @param clienteId
     * @param perfis
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePerfisCienciaCliente(cienciaId: number, clienteId: number, perfis: Array<LucorApiModelsNewPerfil>, observe?: 'body', reportProgress?: boolean): Observable<Array<LucorApiModelsNewPerfil>>;
    public updatePerfisCienciaCliente(cienciaId: number, clienteId: number, perfis: Array<LucorApiModelsNewPerfil>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LucorApiModelsNewPerfil>>>;
    public updatePerfisCienciaCliente(cienciaId: number, clienteId: number, perfis: Array<LucorApiModelsNewPerfil>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LucorApiModelsNewPerfil>>>;
    public updatePerfisCienciaCliente(cienciaId: number, clienteId: number, perfis: Array<LucorApiModelsNewPerfil>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (cienciaId === null || cienciaId === undefined) {
            throw new Error('Required parameter cienciaId was null or undefined when calling updatePerfisCienciaCliente.');
        }

        if (clienteId === null || clienteId === undefined) {
            throw new Error('Required parameter clienteId was null or undefined when calling updatePerfisCienciaCliente.');
        }

        if (perfis === null || perfis === undefined) {
            throw new Error('Required parameter perfis was null or undefined when calling updatePerfisCienciaCliente.');
        }

        this.clearCache();

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Array<LucorApiModelsNewPerfil>>(`${this.basePath}/ciencia/${encodeURIComponent(String(cienciaId))}/perfis/cliente/${encodeURIComponent(String(clienteId))}`,
            perfis,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

}
