import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';

@Pipe({
    name: 'localDateTime'
})
export class LocalDateTimePipe extends DatePipe implements PipeTransform {
    
    transform(value: Date|string|number): string|null;
    transform(value: null|undefined): null;
    transform(value: Date|string|number|null|undefined): string|null;
    transform(value: Date|string|number|null|undefined): string|null {
        
        const data = new Date(value);
        const dia = ("0" + data.getDate()).slice(-2);
        const mes = ("0" + (data.getMonth() + 1)).slice(-2);
        const ano = data.getFullYear();
        const hora = ("0" + data.getHours()).slice(-2);
        const minuto = ("0" + data.getMinutes()).slice(-2);
        // const segundo = ("0" + data.getSeconds()).slice(-2);
        // const milissegundo = ("00" + data.getMilliseconds()).slice(-3);
        const dataFormatada = `${dia}/${mes}/${ano} ${hora}:${minuto}`;
        return dataFormatada;

        // const format = 'dd/MM/yyyy HH:mm:ss';
        // const timezone = 'America/Sao_Paulo';
        // const convertUTC = moment.parseZone(value + '+00:00').utc().format();
        // const timezoneOffset = moment(convertUTC).tz(timezone).format('Z');
        // return super.transform(convertUTC, format, timezoneOffset);

        
    }
}

