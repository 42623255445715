import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { UsuariosCadastrarComponent } from './components/usuarios-cadastrar/usuarios-cadastrar.component';
import { UsuariosDetalhesComponent } from './components/usuarios-detalhes/usuarios-detalhes.component';
import { UsuariosEditarComponent } from './components/usuarios-editar/usuarios-editar.component';
import { UsuariosComponent } from './components/usuarios/usuarios.component';

const usuariosRoutes: Routes = [

    { path: 'usuarios', data: { breadcrumbs: 'Usuários' },
        canActivate: [AuthGuard],
        children: [
            { path: '', component: UsuariosComponent, canActivate: [AuthGuard] },
            { path: 'cadastrar', component: UsuariosCadastrarComponent,
                data: { breadcrumbs: 'Cadastrar usuário' },
                canActivate: [AuthGuard]
            },
            { path: ':Id',
                canActivate: [AuthGuard],
                children: [
                    { path: '', component: UsuariosDetalhesComponent, canActivate: [AuthGuard] },
                    { path: 'editar', component: UsuariosEditarComponent,
                        data: { breadcrumbs: 'Editar usuário' },
                        canActivate: [AuthGuard]
                    },
                ]
            },
        ]
    },
    { path: 'reset-password', component: ResetPasswordComponent },

];

@NgModule({
    imports: [RouterModule.forChild(usuariosRoutes)],
    exports: [RouterModule]
})

export class UsuariosRoutingModule {}
