import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { LucorApiModelsNewTipoServico } from 'app/model/lucorApiModelsNewTipoServico';
import { TipoServicoApiService } from 'app/api/tipoServicoApi.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-tipos-servicos-editar',
  templateUrl: './tipos-servicos-editar.component.html',
  styleUrls: ['./tipos-servicos-editar.component.css']
})
export class TiposServicosEditarComponent implements OnInit, OnDestroy {

  tipoServico: LucorApiModelsNewTipoServico;
  idCliente: number;
  idTipoServico;

  addForm: FormGroup;
  codigo;
  nome: string;

  addFormEnvia: FormGroup;
  submitted = false;

  private subs = new SubSink();

  constructor(
    private tiposServicosService: TipoServicoApiService,
    private alertService: AlertModalService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private location: Location) { }

  ngOnInit() {
    this.idTipoServico = this.route.snapshot.paramMap.get('Id');
    this.getTipoServico();

    this.addForm = this.formBuilder.group({
      codigo: [''],
      nome: ['']
    });

  }

  getTipoServico() {
    this.subs.sink = this.tiposServicosService.getTipoServicoByIdAsync(this.idTipoServico)
    .subscribe(
      tipoServico => {
        this.tipoServico = tipoServico;
        // Define as demais variáveis para o form
        this.nome = this.tipoServico.nome;
        this.codigo = this.tipoServico.codigo;

        this.setForm();
      }
    );

  }

  setForm() {
    this.addForm = this.formBuilder.group({
      codigo: [this.codigo],
      nome: [this.nome]
    });
  }

  atualizaTipoServico(): void {
    this.submitted = true;
    this.addForm = this.formBuilder.group({
      codigo: [this.codigo, Validators.required],
      nome: [this.nome, Validators.required]
    });

    if (!!this.codigo && !!this.nome) {
      this.saveTipoServico();
    }
  }


  private saveTipoServico() {

    this.subs.sink = this.tiposServicosService.updateTipoServicoAsync(this.idTipoServico, this.addForm.value)
    .subscribe(success => {
      this.alertService.showAlertSuccess('Tipo de Serviço atualizado com sucesso!');
      this.location.back();
    }, error => {
      this.alertService.showAlertDanger('Erro ao atualizar o Tipo de Serviço. Tente mais tarde.');
    });

  }

  goBack(): void {
    this.location.back();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
