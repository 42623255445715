import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { forkJoin, EMPTY, Observable } from 'rxjs';
import { take, switchMap, share } from 'rxjs/operators';
import { CookieService } from '@ngx-toolkit/cookie';
import Driver from 'driver.js';
import {
  LucorApiModelsDetailsAvaliacaoCargo,
  LucorApiModelsNewPerfil,
  LucorApiModelsDetailsCargo,
  LucorApiModelsNewFiltroAderencia,
  LucorApiModelsDetailsPessoaAderente,
  LucorApiModelsNewFiltroAderenciaCargos,
  MicrosoftAspNetCoreMvcFileContentResult,
  LucorApiModelsDetailsListagemFavoritos,
  LucorApiModelsDetailsAvaliadoFavorito,
  LucorApiModelsAvaliado,
  LucorApiModelsCiencia} from 'app/model/models';
import { CargoApiService } from 'app/api/cargoApi.service';
import { FavoritosApiService } from 'app/api/favoritosApi.service';
import { AvaliacaoApiService } from 'app/api/avaliacaoApi.service';
import { CienciaApiService } from 'app/api/cienciaApi.service';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { ServicoApiService } from 'app/api/servicoApi.service';
import { LanguageDataTable } from 'app/shared/datatable/translate-datatable';
import { SortingNumberDataTable } from 'app/shared/datatable/sorting-number-datatable';
import { SubSink } from 'subsink';

// import { CargosDetalhesExportPDFComponent } from '../cargos-detalhes-export-pdf/cargos-detalhes-export-pdf.component';

@Component({
  selector: 'app-cargos-detalhes',
  templateUrl: './cargos-detalhes.component.html',
  styleUrls: ['./cargos-detalhes.component.css'],
  preserveWhitespaces: false
})
export class CargosDetalhesComponent implements OnInit, OnDestroy {

  ciencias$: Observable<LucorApiModelsCiencia[]>;
  cargoDetalhe: LucorApiModelsDetailsCargo;
  avaliacoesCargo$: Observable<LucorApiModelsDetailsAvaliacaoCargo[]>;
  avaliadosAvaliacaoAsync: LucorApiModelsAvaliado[];
  perfisAxiologia$: Observable<LucorApiModelsNewPerfil[]>;
  perfisComportamento$: Observable<LucorApiModelsNewPerfil[]>;
  perfisMotivadores$: Observable<LucorApiModelsNewPerfil[]>;
  filtroAderencia: LucorApiModelsNewFiltroAderencia;
  filtroPlanilha: LucorApiModelsNewFiltroAderenciaCargos;
  aderenciaAvaliados$: Observable<LucorApiModelsDetailsPessoaAderente[]>;
  aderenciaAvaliadosAsync: LucorApiModelsDetailsPessoaAderente[];
  listagensSelecionados$: Observable<LucorApiModelsDetailsListagemFavoritos[]>;
  selecionadosListagem$: Observable<LucorApiModelsDetailsAvaliadoFavorito[]>;
  newArrayAderencias: Array<any> = new Array<any>();
  balancoComportamento: Array<any> = new Array<any>();
  idListaSelecionados: number;
  checkSelecionados: boolean;
  countAvaliados: number;

  idCliente: number;
  tituloCargo: string;
  idCargo: number;
  idServico: number;
  qtdeUnidades: number;
  unidadesUtilizadas: number;
  unidadesRestantes: number;
  nomeServico: string;
  mostraAdequacao: boolean;
  mostraAvaliacao: boolean;
  verificaAssociacao: number;
  printWait = false;
  checkingWait = false;
  buttonWait = true;
  addWait: number;
  linkCopiado: boolean = false;

  filtroCiencias = [];
  avaliacoesAssociadas = [];
  selecionadosCount = 0;

  avaliadoModal;
  tipoModal: string;

  linkCV: SafeResourceUrl;
  linkRelatorio: SafeResourceUrl;

  usuarioLogado: string;
  order = 'fator';
  orderComportamento = 'fatorId';
  reverse = false;

  resizedCompetencias = false;
  resizedComportamento = false;
  resizedMotivadores = false;
  resizeModal = false;

  checkCookie = false;
  disableTour = false;
  driver = new Driver({
    className: 'tour-lucor',
    doneBtnText: 'Entendi', // Text on the final button
    closeBtnText: 'Fechar', // Text on the close button for this step
    nextBtnText: 'Próximo', // Next button text for this step
    prevBtnText: 'Anterior', // Previous button text for this step
  });

  dtOptions: DataTables.Settings = {};
  dtOptionsCompetencias: DataTables.Settings = {};


  /**
   * Imprimir relatório em PDF 
   */ 
  activePDF =  false;
  cutoffLimit = 60;

  @ViewChild('closeModalClone') closeModalClone: ElementRef;
  @ViewChild('closeModalSelecionado') closeModalSelecionado: ElementRef;

  /**
   * Gráficos
   */

  avaliadosCargoAxiologia: Array<any> = new Array<any>();
  avaliadosCargoComportamento: Array<any> = new Array<any>();
  avaliadosCargoMotivadores: Array<any> = new Array<any>();

  avaliadosCargoAxiologiaCalculo: Array<any> = new Array<any>();
  avaliadosCargoComportamentoCalculo: Array<any> = new Array<any>();
  avaliadosCargoMotivadoresCalculo: Array<any> = new Array<any>();

  newArrayAxiologia: Array<any> = new Array<any>();
  newArrayComportamento: Array<any> = new Array<any>();
  newArrayMotivadores: Array<any> = new Array<any>();

  setChartDatasetsVisaoGrupo: Array<any> = new Array<any>();
  setChartLabelsVisaoGrupo: Array<any> = new Array<any>();

  mediaFinalGrupo;
  mediaFinalAxiologiaGrupo;
  mediaFinalMotivadoresGrupo;
  mediaFinalNaturalGrupo;
  mediaFinalAdaptavelGrupo;
  mediaFinalComportamentoGrupo;

  // Gráfico Visão do Grupo
  public chartTypeVisaoGrupo = 'bar';
  public chartDatasetsVisaoGrupo = [{ data: this.setChartDatasetsVisaoGrupo, label: 'Ranking' }];
  public chartLabelsVisaoGrupo: Array<any> = [];
  public chartColorsVisaoGrupo: Array<any> = [
     {
       borderColor: 'rgba(145, 206, 244, 1)',
       backgroundColor: 'rgba(145, 206, 244, 0.5)',
       borderWidth: 2,
     }
  ];
  public chartOptionsVisaoGrupo: any = {
    responsive: true,
    title: {
      display: true,
      text: 'Ranking de Porcentagem de Adequação',
      fontSize: 18
    },
    legend: { display: false },
    scales: {
      yAxes: [{ticks: {
        max: 100,
        beginAtZero: true,
      }}]
    }
  };

  public chartTypeMediaGrupo = 'doughnut';

  public chartDatasetsMediaGrupo: Array<any> = [
    { data: [80, 20], label: 'Adequação' }
  ];

  public chartLabelsMediaGrupo: Array<any> = ['', ''];

  public chartColorsMediaGrupo: Array<any> = [
    {
      backgroundColor: ['#45BF55', '#445953'],
      hoverBackgroundColor: ['#343a40'],
      borderWidth: 0,
    }
  ];
  public chartOptionsMediaGrupo: any = {
    responsive: true,
    tooltips: { enabled: false },
    hover: { mode: null }
  };

  public chartTypeAxiologiaGrupo = 'doughnut';

  public chartDatasetsAxiologiaGrupo: Array<any> = [
    { data: [80, 20], label: 'Adequação' }
  ];

  public chartLabelsAxiologiaGrupo: Array<any> = ['', ''];

  public chartColorsAxiologiaGrupo: Array<any> = [
    {
      backgroundColor: ['rgba(255, 168, 5, 1)', '#445953'],
      hoverBackgroundColor: ['#343a40'],
      borderWidth: 0,
    }
  ];
  public chartOptionsAxiologiaGrupo: any = {
    responsive: true,
    tooltips: { enabled: false },
    hover: { mode: null }
  };

  public chartTypeMotivadoresGrupo = 'doughnut';

  public chartDatasetsMotivadoresGrupo: Array<any> = [
    { data: [80, 20], label: 'Adequação' }
  ];

  public chartLabelsMotivadoresGrupo: Array<any> = ['', ''];

  public chartColorsMotivadoresGrupo: Array<any> = [
    {
      backgroundColor: ['rgba(255, 168, 5, 1)', '#445953'],
      hoverBackgroundColor: ['#343a40'],
      borderWidth: 0,
    }
  ];
  public chartOptionsMotivadoresGrupo: any = {
    responsive: true,
    tooltips: { enabled: false },
    hover: { mode: null }
  };

  public chartTypeComportamentoGrupo = 'doughnut';

  public chartDatasetsComportamentoGrupo: Array<any> = [
    { data: [80, 20], label: 'Adequação' }
  ];

  public chartLabelsComportamentoGrupo: Array<any> = ['', ''];

  public chartColorsComportamentoGrupo: Array<any> = [
    {
      backgroundColor: ['rgba(255, 168, 5, 1)', '#445953'],
      hoverBackgroundColor: ['#343a40'],
      borderWidth: 0,
    }
  ];
  public chartOptionsComportamentoGrupo: any = {
    responsive: true,
    tooltips: { enabled: false },
    hover: { mode: null }
  };

  public chartTypeNaturalGrupo = 'doughnut';

  public chartDatasetsNaturalGrupo: Array<any> = [
    { data: [80, 20], label: 'Adequação' }
  ];

  public chartLabelsNaturalGrupo: Array<any> = ['', ''];

  public chartColorsNaturalGrupo: Array<any> = [
    {
      backgroundColor: ['rgba(255, 168, 5, 1)', '#445953'],
      hoverBackgroundColor: ['#343a40'],
      borderWidth: 0,
    }
  ];
  public chartOptionsNaturalGrupo: any = {
    responsive: true,
    tooltips: { enabled: false },
    hover: { mode: null }
  };

  setChartColorsAdaptavelGrupo = [];

  public chartTypeAdaptavelGrupo = 'doughnut';

  public chartDatasetsAdaptavelGrupo: Array<any> = [
    { data: [80, 20], label: 'Adequação' }
  ];

  public chartLabelsAdaptavelGrupo: Array<any> = ['', ''];

  public chartColorsAdaptavelGrupo: Array<any> = [
    {
      backgroundColor: ['rgba(255, 168, 5, 1)', '#445953'],
      hoverBackgroundColor: ['#343a40'],
      borderWidth: 0,
    }
  ];
  public chartOptionsAdaptavelGrupo: any = {
    responsive: true,
    tooltips: { enabled: false },
    hover: { mode: null }
  };

  /** #Visão do Grupo */

  setChartLabelsAxiologia: Array<any> = new Array<any>();
  setChartDatasetsAxiologia: Array<any> = [];

   // Gráfico Padrão Perfil Competências
   public chartTypeCienciaAxiologia = 'radar';
   public chartDatasetsCienciaAxiologia = [{ data: [], label: 'Axiologia' }];
   public chartLabelsCienciaAxiologia: Array<any> = [];
   public chartColorsCienciaAxiologia: Array<any> = [
      {
        borderColor: 'rgba(145, 206, 244, 1)',
        backgroundColor: 'rgba(145, 206, 244, 0.5)',
        borderWidth: 2,
      }
   ];
   public chartOptionsCienciaAxiologia: any = {
     responsive: true,
     scale: {
       angleLines: {
           display: true
       },
       ticks: {
           suggestedMin: 0,
           suggestedMax: 100
       }
     }
   };

   // Gráfico Padrão Perfil Comportamento
   public chartTypeCienciaComportamento = 'radar';
   public chartDatasetsCienciaComportamento = [{ data: [], label: 'Comportamento Observado' }];
   public chartLabelsCienciaComportamento: Array<any> = [];
   public chartColorsCienciaComportamento: Array<any> = [
      {
        borderColor: 'rgba(145, 206, 244, 1)',
        backgroundColor: 'rgba(145, 206, 244, 0.5)',
        borderWidth: 2,
      }
   ];
   public chartOptionsCienciaComportamento: any = {
     responsive: true,
     scale: {
       angleLines: {
           display: true
       },
       /*
       gridLines: {
         color: ['#343A40', '#343A40', '#343A40', '#343A40', '#343A40', '#343A40', '#343A40', '#343A40', '#343A40', '#343A40']
       },
       */
       ticks: {
           fontSize: 10,
           suggestedMin: 0,
           suggestedMax: 100
       }
     }
   };

   // Gráfico Padrão Perfil Motivadores
   public chartTypeCienciaMotivadores = 'radar';
   public chartDatasetsCienciaMotivadores = [{ data: [], label: 'Motivadores' }];
   public chartLabelsCienciaMotivadores: Array<any> = [];
   public chartColorsCienciaMotivadores: Array<any> = [
      {
        borderColor: 'rgba(145, 206, 244, 1)',
        backgroundColor: 'rgba(145, 206, 244, 0.5)',
        borderWidth: 2,
      }
   ];
   public chartOptionsCienciaMotivadores: any = {
     responsive: true,
     scale: {
       angleLines: {
           display: true
       },
       ticks: {
           suggestedMin: 0,
           suggestedMax: 100
       }
     }
   };

   // Gráfico Resultado Axiologia Pessoa x Cargo
  public chartTypeAxiologia = 'radar';
  public chartDatasetsAxiologia: Array<any> = [];
  public chartLabelsAxiologia: Array<any> = [];
  public chartColorsAxiologia: Array<any> = [
    {
      borderColor: 'rgba(255, 168, 5, 1)',
      backgroundColor: 'rgba(255, 168, 5, 0.0)',
      borderWidth: 2,
    },
    {
      borderColor: 'rgba(145, 206, 244, 1)',
      backgroundColor: 'rgba(145, 206, 244, 0.5)',
      borderWidth: 2,
    }
  ];
  public chartOptionsAxiologia: any = {
    responsive: true,
    scale: {
      angleLines: {
          display: true
      },
      ticks: {
          suggestedMin: 0,
          suggestedMax: 100
      }
    }
  };

  setChartLabelsComportamento: Array<any> = new Array<any>();
  setChartDatasetsComportamento: Array<any> = [];

  // Gráfico Resultado Comportamento Pessoa x Cargo
  public chartTypeComportamento = 'radar';
  public chartDatasetsComportamento: Array<any> = [];
  public chartLabelsComportamento: Array<any> = [];
  public chartColorsComportamento: Array<any> = [
    {
      borderColor: 'rgba(255, 168, 5, 1)',
      backgroundColor: 'rgba(255, 168, 5, 0.0)',
      borderWidth: 2,
    },
    {
      borderColor: 'rgba(145, 206, 244, 1)',
      backgroundColor: 'rgba(145, 206, 244, 0.5)',
      borderWidth: 2,
    }
  ];
  public chartOptionsComportamento: any = {
    responsive: true,
    scale: {
      angleLines: {
          display: true
      },
      ticks: {
          suggestedMin: 0,
          suggestedMax: 100
      }
    }
  };

  setChartLabelsMotivadores: Array<any> = new Array<any>();
  setChartDatasetsMotivadores: Array<any> = [];

  // Gráfico Resultado Motivadores Pessoa x Cargo
  public chartTypeMotivadores = 'radar';
  public chartDatasetsMotivadores: Array<any> = [];
  public chartLabelsMotivadores: Array<any> = [];
  public chartColorsMotivadores: Array<any> = [
    {
      borderColor: 'rgba(255, 168, 5, 1)',
      backgroundColor: 'rgba(255, 168, 5, 0.0)',
      borderWidth: 2,
    },
    {
      borderColor: 'rgba(145, 206, 244, 1)',
      backgroundColor: 'rgba(145, 206, 244, 0.5)',
      borderWidth: 2,
    }
  ];
  public chartOptionsMotivadores: any = {
    responsive: true,
    scale: {
      angleLines: {
          display: true
      },
      ticks: {
          suggestedMin: 0,
          suggestedMax: 100
      }
    }
  };

  // Gráficos dos Grupos x Cargo (Médias dos Fatores das Ciências)

  // Gráfico Resultado Axiologia Grupo x Cargo

  setChartLabelsAxiologiaGeral: Array<any> = new Array<any>();

  chartTypeConfig: string = 'line';
  colorConfig: Array<any> = [{
    borderColor: 'rgba(255, 168, 5, 1)',
    backgroundColor: 'rgba(255, 168, 5, 0.0)',
    borderWidth: 2,
  },
  {
    borderColor: 'rgba(145, 206, 244, 1)',
    backgroundColor: 'rgba(145, 206, 244, 0.5)',
    borderWidth: 2,
  }];

  public chartTypeAxiologiaGeral = this.chartTypeConfig;
  public chartDatasetsAxiologiaGeral: Array<any> = [];
  public chartLabelsAxiologiaGeral: Array<any> = [];
  public chartColorsAxiologiaGeral = this.colorConfig;
  public chartOptionsAxiologiaGeral: any = {
    responsive: true,
    scales: {
      yAxes: [{ticks: {
        max: 100,
        beginAtZero: true
      }}],
      xAxes: [{ticks: {
        max: 100,
        beginAtZero: true
      }}]
    }
  };


  // Gráfico Resultado Comportamento Grupo x Cargo

  setChartLabelsComportamentoGeral: Array<any> = new Array<any>();

  public chartTypeComportamentoGeral = 'line';
  public chartDatasetsComportamentoGeral: Array<any> = [];
  public chartLabelsComportamentoGeral: Array<any> = [];
  public chartColorsComportamentoGeral = [
    {
      borderColor: 'rgba(255, 168, 5, 1)',
      backgroundColor: 'rgba(255, 168, 5, 0.0)',
      borderWidth: 2,
    },
    {
      borderColor: 'rgba(145, 206, 244, 1)',
      backgroundColor: 'rgba(145, 206, 244, 0.5)',
      borderWidth: 2,
    }
  ];
  public chartOptionsComportamentoGeral: any = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          max: 100,
          beginAtZero: true
        }
    }]
    }
  };

  setChartLabelsMotivadoresGeral: Array<any> = new Array<any>();

  // Gráfico Resultado Motivadores Grupo x Cargo
  public chartTypeMotivadoresGeral = 'line';
  public chartDatasetsMotivadoresGeral: Array<any> = [];
  public chartLabelsMotivadoresGeral: Array<any> = [];
  public chartColorsMotivadoresGeral = [
    {
      borderColor: 'rgba(255, 168, 5, 1)',
      backgroundColor: 'rgba(255, 168, 5, 0.0)',
      borderWidth: 2,
    },
    {
      borderColor: 'rgba(145, 206, 244, 1)',
      backgroundColor: 'rgba(145, 206, 244, 0.5)',
      borderWidth: 2,
    }
  ];
  public chartOptionsMotivadoresGeral: any = {
    responsive: true,
    scales: {
      yAxes: [{ticks: {
        max: 100,
        beginAtZero: true
      }}]
    }
  };

/*
*  Mini Gráficos Adequação
*/

setChartDataAdequacao = [{ data: [80, 20], label: 'Adequação' }];

public chartTypeDoughnut = 'doughnut';

/*
* ---- Gráfico 01
*/
  public chartDatasets: Array<any> = [
    { data: [80, 20], label: 'Adequação' }
  ];
  public chartLabels: Array<any> = ['', ''];
  public chartColors: Array<any> = [
    {
      backgroundColor: ['#45BF55', '#445953'],
      hoverBackgroundColor: ['#343a40'],
      borderWidth: 0,
    }
  ];
  public chartOptions: any = {
    responsive: true,
    tooltips: { enabled: false },
    hover: { mode: null }
  };

  /*
   *** -- comum aos gráficos
  */
  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }

  private subs = new SubSink();

  constructor(
    private cargosService: CargoApiService,
    private avaliacoesService: AvaliacaoApiService,
    private selecionadosService: FavoritosApiService,
    private cienciasService: CienciaApiService,
    private servicosService: ServicoApiService,
    private alertService: AlertModalService,
    private cookieService: CookieService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private sanitizer: DomSanitizer,
  ) { this.usuarioLogado = localStorage.getItem('userRole'); }

  ngOnInit() {
    SortingNumberDataTable.number_datatables.sortFunction();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 15,
      processing: true,
      responsive: true,
      order:[],
      columnDefs: [
        { type: 'natural', targets: [0, 2] },
        { type: 'html', targets: 1}
      ], 
      language: LanguageDataTable.portugues_datatables
    };

    this.dtOptionsCompetencias = {
      pagingType: 'numbers',
      pageLength: 40,
      processing: true,
      responsive: true,
      order:[0,"asc"],
      language: LanguageDataTable.portugues_datatables
    };
    
    this.goTop();
    this.getCiencias();
    this.avaliadosAvaliacaoAsync = [];
    this.newArrayAderencias = [];
    this.cargoDetalhe = null;
    this.qtdeUnidades = null;
    this.idListaSelecionados = null;
    this.checkSelecionados = false;
    this.linkCV = null;
    this.linkRelatorio = null;

    this.subs.sink = this.route.data.subscribe(
      dados => {
        this.cargoDetalhe = dados.cargosResolver;
        this.idCargo = this.cargoDetalhe.id;
        this.tituloCargo = this.cargoDetalhe.titulo;
        this.idCliente = this.cargoDetalhe.pessoaJuridicaId;
        this.idServico = this.cargoDetalhe.servicoId;
        this.qtdeUnidades = this.cargoDetalhe.qtdeUnidades;
        this.checkCookie = this.cookieService.hasItem('tourCargoDetalhes');

        if (this.idServico) {
          this.getServicoById(this.idServico);
        }

        forkJoin([
          this.perfisAxiologia$ = this.cargosService.getPerfisCargo(this.idCargo, 1).pipe(share()),
          this.perfisComportamento$ = this.cargosService.getPerfisCargo(this.idCargo, 2).pipe(share()),
          this.perfisMotivadores$ = this.cargosService.getPerfisCargo(this.idCargo, 3).pipe(share()),
          this.avaliacoesCargo$ = this.cargosService.getAvaliacoesCargo(this.idCargo).pipe(share())
        ])
        .subscribe(resultado => {

          const newAvaliacao = [];

          const resultadoLength = resultado[3].length;

          if (resultadoLength === 0) {
            this.getPerfis(resultado[0], resultado[1], resultado[2], false);
          }

          const promiseInit = new Promise((resolve, reject) => {

            resultado[3].map(avaliacaoCargo => {
              this.subs.sink = this.avaliacoesService.getAvaliadosAvaliacaoAsync(avaliacaoCargo.avaliacaoId)
                .subscribe(avaliacoesAsync => {
                  newAvaliacao.push(avaliacoesAsync);

                  if (newAvaliacao.length === resultadoLength) {
                    resolve(newAvaliacao);
                  }

                });
              });
          });

          promiseInit.then((result: Array<any> = new Array<any>()) => {

            // console.log(resultado[0]);

            this.avaliadosAvaliacaoAsync = [].concat(...result);
            this.getPerfis(resultado[0], resultado[1], resultado[2], true);

            if (this.qtdeUnidades) {
              this.calculoUnidades();
            }
            
          });

          this.verificaAssociacao = resultadoLength;
          if (this.verificaAssociacao === 0) {
              this.mostraAdequacao = false;
              this.mostraAvaliacao = false;
              this.buttonWait = false;
          } else {
              this.mostraAvaliacao = true;
          }

        });

        this.getListagemSelecionadosByCargo(+this.idCargo);

      });

  }

  downloadAsPDF(event?: boolean) {
    this.activePDF = !this.activePDF;
  }

  getServicoById(id: number) {
    this.subs.sink = this.servicosService.getServicoByIdAsync(id)
      .subscribe(servico => this.nomeServico = servico.tipoServico.nome);
  }

  calculoUnidades() {
    if (this.avaliadosAvaliacaoAsync) {
      this.unidadesUtilizadas = this.avaliadosAvaliacaoAsync.length;
      this.unidadesRestantes = this.qtdeUnidades - this.unidadesUtilizadas;
    }
  }

  startTour(): void {
    this.disableTour = true;
    // Define the steps for tour
    setTimeout(() => {
      this.driver.defineSteps([
        {
          element: '#one-element-tour',
          popover: {
            className: 'first-step-popover-class',
            title: 'Vamos fazer um tour?',
            description: 'Você pode usar as teclas <i class="fas fa-caret-left icon-tour"></i> e <i class="fas fa-caret-right icon-tour"></i> para avançar e voltar. Acesse aqui sempre que precisar.',
            position: 'left',
            closeBtnText: 'Eu vejo depois.',
            nextBtnText: 'Vamos lá!',
            prevBtnText: '<i class="fas fa-plane-departure"></i>'
          }
        },
        {
          element: '#two-element-tour',
          popover: {
            title: 'Botão "Editar"',
            description: 'Aqui você pode editar o cargo. É possível alterar todos os dados, inclusive associar outra avaliação e alterar o perfil comportamental por ciência.',
            position: 'left'
          }
        },
        {
          element: '#three-element-tour',
          popover: {
            title: 'Botão "Clonar Cargo"',
            description: 'Você pode criar um novo cargo a partir deste. É possível clonar totalmente ou escolher os itens que irá clonar.',
            position: 'left'
          }
        },
        {
          element: '#four-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Botão Excluir',
            description: 'Aqui você exclui o cargo. Use com cuidado!',
            position: 'left'
          }
        },
        {
          element: '#five-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Menu em abas',
            description: 'Clique sobre o item para visualizar os dados.',
            position: 'bottom'
          }
        },
        {
          element: '#six-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Adequeção com pessoas',
            description: 'Nesta aba é exibido o Ranking de adequação e compatibilidade com o cargo.',
            position: 'bottom'
          }
        },
        {
          element: '#seven-element-tour-0',
          stageBackground: '#7a7a7a',
          popover: {
            title: 'Visão do Grupo',
            description: 'Dados gerais de adequação do Grupo em relação ao Cargo.',
            position: 'right'
          }
        },
        {
          element: '#seven-element-tour-1',
          stageBackground: '#7a7a7a',
          popover: {
            title: 'Necessidades de Treinamento',
            description: 'Dados que darão base para o desenvolvimento de cada pessoa, com base no perfil do cargo.',
            position: 'right'
          }
        },
        {
          element: '#seven-element-tour-2',
          stageBackground: '#7a7a7a',
          popover: {
            title: 'Relatório em PDF',
            description: 'Baixe o relatório em PDF com a análise de adequação do grupo, com base no perfil do cargo. Você pode definir um valor mínimo para corte, onde apenas os avaliados com a porcentagem a partir deste valor serão listados no relatório (o valor padrão é 60).',
            position: 'right'
          }
        },
        {
          element: '#seven-element-tour',
          stageBackground: '#7a7a7a',
          popover: {
            title: 'Baixar Planilha',
            description: 'Você pode baixar os dados em uma planilha de Excel.',
            position: 'right'
          }
        },
        {
          element: '#nine-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Posição no Ranking',
            description: 'Esta é a posição da pessoa no Ranking de adequação.',
            position: 'right'
          }
        },
        {
          element: '#ten-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Nome do avaliado',
            description: 'Este é nome do avaliado. Você pode clicar sobre ele para ver o perfil completo da pessoa.',
            position: 'right'
          }
        },
        {
          element: '#eleven-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Porcentagem de Adequação',
            description: 'Esta é a porcentagem de adequação do avaliado com o cargo.',
            position: 'left'
          }
        },
        {
          element: '#twelve-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Botão "Compatibilidade"',
            description: 'Clique aqui para visualizar os gráficos de compatibilidade do perfil do avaliado em relação aos perfis de cada ciência.',
            position: 'left'
          }
        },
        {
          element: '#eleven-element-tour-0',
          stageBackground: '#343a40',
          popover: {
            title: 'FeedBack',
            description: 'Informações sobre a pessoa em relação ao cargo e seu estado atual.',
            position: 'left'
          }
        },
        {
          element: '#eleven-element-tour-1',
          stageBackground: '#343a40',
          popover: {
            title: 'Botão "Relatório"',
            description: 'Aqui você poderá visualizar o Relatório Comportamental do avaliado, caso esteja disponível.',
            position: 'left'
          }
        },
        {
          element: '#eleven-element-tour-2',
          stageBackground: '#343a40',
          popover: {
            title: 'Botão "Currículo"',
            description: 'Aqui você poderá visualizar o currículo do avaliado, caso esteja disponível.',
            position: 'left'
          }
        },
        {
          element: '#thirteen-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Botão "Selecionado"',
            description: 'Clique no ícone para Adicionar ou remover essa pessoa aos selecionados. No menu, você encontra o item "Selecionados", onde poderá editar, excluir ou criar novas listas.',
            position: 'left'
          }
        }
      ]);
      // Start the introduction
      this.driver.start();
      this.disableTour = false;
    }, 1000);
  }

  trackByFn(index: number, newAvaliado: any): string {
    return newAvaliado.setMatchCargo;
  }

  gerarLinkRelatorio(avaliado, tipo: string) {
    let link = avaliado.relatorio;
    this.linkRelatorio = this.sanitizer.bypassSecurityTrustResourceUrl(link);
    this.openModalAvaliado(avaliado, tipo);
  }

  gerarLinkCV(avaliado, tipo: string) {
    let link = avaliado.relatorio;
    this.linkCV = this.sanitizer.bypassSecurityTrustResourceUrl(link);
    this.openModalAvaliado(avaliado, tipo);
  }

  updateLista(event?: number) {
    const modalBack = document.getElementsByClassName('modal-backdrop')[0];
    if (modalBack) { modalBack.remove(); }
    if (event) {
      this.redirectTo('/cargos/' + event)
    } else {
      this.getListagemSelecionadosByCargo(+this.idCargo);
      this.getAderenciaAvaliados(this.filtroCiencias);
    }
  }

  redirectTo(uri:string){
    this.router.navigateByUrl('cargos', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
 }

  loadGraficos(avaliado?) {
    this.setGraficoResultadoAxiologia(avaliado.avaliadoId);
    this.setGraficoResultadoComportamento(avaliado.avaliadoId);
    this.setGraficoResultadoMotivadores(avaliado.avaliadoId);
    this.openModalAvaliado(avaliado, 'compatibilidade');
  }

  openModalAvaliado(avaliado, tipo?: string) {
    this.avaliadoModal = null;
    this.tipoModal = null;

    this.avaliadoModal = avaliado;
    this.tipoModal = tipo;

    setTimeout(() => {
      document.getElementById(`openModal_${this.tipoModal}_${avaliado.avaliadoId}`).click(); 
    }, 800);
    
  }

  adicionarSelecionado(avaliado, tipo: string) {
    let idListagem = avaliado.idListagemFavoritos;
    let idSelecionado = avaliado.avaliadoId;
    this.checkingWait = true;
    this.addWait = idSelecionado;
    let idListaCheck: number;
    if (idListagem) { idListaCheck = idListagem[0]; }

    if (this.idListaSelecionados) {
      this.newArrayAderencias.forEach(aderencia => {
        if (aderencia.avaliadoId === idSelecionado) {
          // if (idListaCheck && idListaCheck === this.idListaSelecionados && aderencia.setMatchCargo) {
          if (aderencia.setMatchCargo) {
            this.removerSelecionado(this.idListaSelecionados, idSelecionado);
            this.checkingWait = false;
            this.addWait = null;
          } else {
            this.subs.sink = this.selecionadosService.addAvaliadoListagemAsync(this.idListaSelecionados, idSelecionado)
              .subscribe(success => {
                this.subs.sink = this.selecionadosService.addCargoAvaliadoListagemAsync(success.id, this.idCargo)
                  .subscribe(res => {
                    if (res) {
                      this.newArrayAderencias.forEach(newAvaliado => {
                        if (newAvaliado.avaliadoId === idSelecionado) {
                          newAvaliado.setMatchCargo = true;
                        }
                      });
                      this.checkingWait = false;
                      this.addWait = null;
                      this.listarAvaliadosdaListagem(this.idListaSelecionados);
                    }
                  }, error => {
                    this.checkingWait = false;
                    this.addWait = null;
                    this.alertService.showAlertDanger('Não foi possível adicionar o cargo para esta pessoa. Tente novamente.');
                  });
              }, error => {
                this.checkingWait = false;
                this.addWait = null;
                this.alertService.showAlertDanger('Não foi possível adicionar essa pessoa. Tente novamente.');
              });
          }

        }

      });
    } else {
      if (idListaCheck) {
        this.subs.sink = this.selecionadosService.addAvaliadoListagemAsync(idListaCheck, idSelecionado)
          .subscribe(success => {
            this.subs.sink = this.selecionadosService.addCargoAvaliadoListagemAsync(success.id, this.idCargo)
              .subscribe(res => {
                if (res) {
                  this.newArrayAderencias.forEach(newAvaliado => {
                    if (newAvaliado.avaliadoId === idSelecionado) {
                      newAvaliado.setMatchCargo = true;
                    }
                  });
                  this.checkingWait = false;
                  this.addWait = null;
                  this.getListagemSelecionadosByCargo(this.idCargo);
                }
              }, error => {
                this.checkingWait = false;
                this.addWait = null;
                this.alertService.showAlertDanger('Não foi possível adicionar o cargo para esta pessoa. Tente novamente.');
              });
          }, error => {
            this.checkingWait = false;
            this.addWait = null;
            this.alertService.showAlertDanger('Não foi possível adicionar essa pessoa. Tente novamente.');
          });
      } else {
        this.openModalAvaliado(avaliado, tipo);
      }
    }
  }

  cancelAddSelecionado() {
    this.checkingWait = !this.checkingWait;
    this.addWait = null;
  }

  removerSelecionado(idLista: number, idAvaliado: number) {

    this.checkingWait = true;
    this.selecionadosService.deleteAvaliadoListagemAsync(idLista, idAvaliado)
      .pipe(
        take(1))
      .subscribe(
        success => {
          this.newArrayAderencias.forEach(avaliado => {
           if (avaliado.avaliadoId === idAvaliado) {
             avaliado.setMatchCargo = false;
           } 
          });
          this.checkingWait = false;
          this.listarAvaliadosdaListagem(idLista);
        },
        error => {
          this.checkingWait = false;
          this.alertService.showAlertDanger('Erro ao remover o selecionado. Tente novamente mais tarde.');
        }
      );
  }

  getDownloadPlanilhaCargos() {
    this.printWait = true;
    this.subs.sink = this.cargosService.gerarPlanilhaCargosAsync(this.filtroPlanilha, 'response')
    .subscribe(planilhaFile => {
        const fileName = this.getFileNameFromHttpResponse(planilhaFile);
        this.downLoadFile(planilhaFile.body, 'application/ms-excel', fileName);
        this.alertService.showAlertSuccess('Sua planilha está pronta!');
      }, (err) => {
        this.alertService.showAlertWarning('Algo deu errado! Tente novamente mais tarde.');
      });
  }

  onDelete(cargo: LucorApiModelsDetailsCargo) {
    const result$ = this.alertService.showConfirm('Confirmação', 'Tem certeza que deseja excluir esse cargo?');
    result$.asObservable()
    .pipe(
      take(1),
      switchMap(result => result ? this.cargosService.deleteCargoAsync(cargo.id) : EMPTY)
    )
    .subscribe(
      success => {
        this.alertService.showAlertSuccess('Cargo enviado para a Lixeira!');
        this.router.navigate(['/cargos']);
      },
      error => {
        this.alertService.showAlertDanger('Erro ao excluir cargo. Tente novamente mais tarde.');
      }
    );
  }

  customComparator(itemA, itemB) { // Adicionado para customizar a função 'comparator' do OrderBy
    const hasNumber = /\d/;
    const verificaTipo = hasNumber.test(itemA); // Verifica se a variável contém número
    if (verificaTipo === true) {
      return  itemA - itemB; // Corrige a situação que retorna algo assim: [1, 10, 12, 15, 5, 8, 9]
    } else {
      return itemA.localeCompare(itemB); // Corrige a situação da palavra acentuada ficar por último na lista
    }
  }

  goBack(): void {
    this.location.back();
  }

  modalClone() {
    setTimeout(() => {
      this.closeModalClone.nativeElement.click(); // fechar modal
    }, 500);
  }

  modalSelecionado() {
    setTimeout(() => {
      this.closeModalSelecionado.nativeElement.click(); // fechar modal
    }, 500);
  }

  cancelModal() {
    this.checkingWait = false;
    this.addWait = null;
  }

  modalResize() {
    this.resizeModal = !this.resizeModal;
  }

  graphResize(ciencia: string) {
    switch (ciencia) {
      case 'Competencias':
        this.resizedCompetencias = !this.resizedCompetencias;
        break;
      case 'Comportamento':
        this.resizedComportamento = !this.resizedComportamento;
        break;
      case 'Motivadores':
        this.resizedMotivadores = !this.resizedMotivadores;
        break;
    }
    
  }

  graphChange(ciencia: string) {

    if (ciencia === 'Competencias') {
      if (this.chartTypeConfig === 'horizontalBar') {
        this.chartTypeConfig = 'line';
        this.colorConfig = [{
          borderColor: 'rgba(255, 168, 5, 1)',
          backgroundColor: 'rgba(255, 168, 5, 0)',
          borderWidth: 2,
        },
        {
          borderColor: 'rgba(145, 206, 244, 1)',
          backgroundColor: 'rgba(145, 206, 244, 0.5)',
          borderWidth: 2,
        }];
      } else {
        this.chartTypeConfig = 'horizontalBar';
        this.colorConfig = [{
          borderColor: 'rgba(255, 168, 5, 0)',
          backgroundColor: 'rgba(255, 168, 5, 1)',
          borderWidth: 0,
        },
        {
          borderColor: 'rgba(145, 206, 244, 0)',
          backgroundColor: 'rgba(145, 206, 244, 1)',
          borderWidth: 0,
        }];
      }

      this.chartTypeAxiologiaGeral = this.chartTypeConfig;
      this.chartColorsAxiologiaGeral = this.colorConfig;

    }
  }

  copyInputMessage(inputElement){
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.linkCopiado = true;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    $.fn['dataTable'].ext.oSort;
  }

  private setCookie() {
    this.cookieService.setItem( 'tourCargoDetalhes', '1' );
  }

  private getCiencias() {
    this.ciencias$ = this.cienciasService.getCienciasAsync();
  }

  private getListagemSelecionadosByCargo(idCargo: number) {
    if (idCargo) {
      this.subs.sink = this.selecionadosService.getListagemAsync(true)
      .subscribe(listagensSelecionadosAsync => {
        listagensSelecionadosAsync.forEach(listaSelecionadosAsync => {
          if (+listaSelecionadosAsync.cargoId === +idCargo) {
            this.idListaSelecionados = listaSelecionadosAsync.id;
            this.listarAvaliadosdaListagem(this.idListaSelecionados);
          }
        });
      });
    }
  }

  private listarAvaliadosdaListagem(idListagem: number) {
    this.subs.sink = this.selecionadosService.listarAvaliadosListagemAsync(idListagem)
    .subscribe(avaliados => {
      if (avaliados.length > 0) {
        this.checkSelecionados = true;
      } else {
        this.checkSelecionados = false;
      }
    })
  }

  private getPerfis(perfisAxiologia, perfisComportamento, perfisMotivadores, avaliacoes?: boolean) {

    if (this.checkCookie === false) {
      this.startTour();
      this.setCookie();
    }

    const padraoAxiologiaLabel = [];
    const padraoComportamentoLabel = [];
    const padraoMotivadoresLabel = [];
    const padraoAxiologiaValue = [];
    const padraoComportamentoValue = [];
    const padraoMotivadoresValue = [];

    const resultAxiologia = [];
    const resultComportamento = [];
    const resultMotivadores = [];

    const matchAxiologia: LucorApiModelsNewPerfil[] = [];
    const matchComportamento: LucorApiModelsNewPerfil[] = [];
    const matchMotivadores: LucorApiModelsNewPerfil[] = [];

    perfisAxiologia.map(fatoresAxiologia => {
            if (fatoresAxiologia.fator !== null) {
               padraoAxiologiaLabel.push(fatoresAxiologia.fator);
               padraoAxiologiaValue.push(fatoresAxiologia.ideal);
               resultAxiologia.push(fatoresAxiologia.fator);
               matchAxiologia.push(fatoresAxiologia);
            }
      });

    perfisComportamento.map(fatoresComportamento => {
            if (fatoresComportamento.fator !== null) {

               // padraoComportamentoLabel.push(fatoresComportamento.fator);

               switch (fatoresComportamento.fator) {
                case 'DISC NATURAL - Dominância':
                  padraoComportamentoLabel.push('Dominância');
                break;
    
                case 'DISC NATURAL - Influência':
                  padraoComportamentoLabel.push('Influência');
                break;
    
                case 'DISC NATURAL - Estabilidade':
                  padraoComportamentoLabel.push('Estabilidade');
                break;
    
                case 'DISC NATURAL - Cautela':
                  padraoComportamentoLabel.push('Cautela');
                break;
    
                case 'DISC ADAPTÁVEL - Dominância':
                  padraoComportamentoLabel.push('Dominância (A)');
                break;
    
                case 'DISC ADAPTÁVEL - Influência':
                  padraoComportamentoLabel.push('Influência (A)');
                break;
    
                case 'DISC ADAPTÁVEL - Estabilidade':
                  padraoComportamentoLabel.push('Estabilidade (A)');
                break;
    
                case 'DISC ADAPTÁVEL - Cautela':
                  padraoComportamentoLabel.push('Cautela (A)');
                break;
              }

               padraoComportamentoValue.push(fatoresComportamento.ideal);
               resultComportamento.push(fatoresComportamento.fator);
               matchComportamento.push(fatoresComportamento);
            }
      });

    perfisMotivadores.map(fatoresMotivadores => {
            if (fatoresMotivadores.fator !== null) {
               padraoMotivadoresLabel.push(fatoresMotivadores.fator);
               padraoMotivadoresValue.push(fatoresMotivadores.ideal);
               resultMotivadores.push(fatoresMotivadores.fator);
               matchMotivadores.push(fatoresMotivadores);
            }
      });
      
    this.getGraficosPadroesCiencias(
          padraoAxiologiaValue, padraoAxiologiaLabel,
          padraoComportamentoValue, padraoComportamentoLabel,
          padraoMotivadoresValue, padraoMotivadoresLabel
      );

    if (avaliacoes) {
      this.getAderenciaCiencias(
          resultAxiologia, resultComportamento, resultMotivadores
        );

      this.matchAvaliados(
          matchAxiologia, matchComportamento, matchMotivadores
        );

      this.matchAvaliadosCalculos(
          matchAxiologia, matchComportamento, matchMotivadores
        );
    }
  }

  private getAderenciaCiencias(getPerfisAxiologia, getPerfisComportamento, getPerfisMotivadores) {

        this.filtroCiencias = [];

        if (getPerfisAxiologia.length > 0) {
          this.filtroCiencias.push({ nomeCiencia: 'Axiologia', filtroFatores: getPerfisAxiologia });
        }
        if (getPerfisComportamento.length > 0) {
          this.filtroCiencias.push({ nomeCiencia: 'Comportamento Observado', filtroFatores: getPerfisComportamento });
        }
        if (getPerfisMotivadores.length > 0) {
          this.filtroCiencias.push({ nomeCiencia: 'Motivadores', filtroFatores: getPerfisMotivadores });
        }

        if (this.filtroCiencias.length > 0 && this.verificaAssociacao > 0) {
          this.getAderenciaAvaliados(this.filtroCiencias);
        } else {
          this.buttonWait = false; // Habilita o botão Editar
        }

  }

  private getAderenciaAvaliados(filtroCiencias) {

    this.newArrayAderencias = [];
    this.filtroAderencia = undefined;
    this.filtroPlanilha = undefined;

    this.filtroPlanilha = { clienteId: this.idCliente, idCargos: [this.idCargo], destacarFavoritos: true, ciencias: filtroCiencias };
    this.filtroAderencia = { clienteId: this.idCliente, ciencias: filtroCiencias };

    // console.log(this.filtroAderencia);

    this.subs.sink = this.cargosService.getListarAderenciaAvaliadosAoCargo(this.idCargo, this.filtroAderencia)
      .subscribe(aderenciaAvaliados => {
      this.aderenciaAvaliadosAsync = aderenciaAvaliados;

      aderenciaAvaliados.forEach(aderencia => {

        const idLista: Array<number> = [];
        const arrayAderenciaCiencias = [];
        let selectSelecionado = false;

        if (aderencia.idListagemFavoritos && aderencia.idListagemFavoritos.length > 0 && this.idListaSelecionados) {
          aderencia.idListagemFavoritos.filter((idListagemAderencia) => {
            if (idListagemAderencia === this.idListaSelecionados) {
              selectSelecionado = true;
              idLista.push(this.idListaSelecionados);
            }
          });
        }

        aderencia.aderenciaCiencias.map(aderenciaCiencia => { arrayAderenciaCiencias.push(aderenciaCiencia); });

        if (aderencia.ranking.percentualAproximacao) {

          this.newArrayAderencias.push({
            avaliacaoId: aderencia.avaliacaoId,
            avaliadoId: aderencia.avaliadoId,
            nome: aderencia.nome,
            email: aderencia.email,
            idListagemFavoritos: idLista,
            idAvaliadosListagensFavoritos: [
              aderencia.idAvaliadoListagemFavoritos
            ],
            posicaoGeral: aderencia.ranking.posicao,
            aproximacao: aderencia.ranking.percentualAproximacao,
            ranking: [
              {
                descricao: aderencia.ranking.descricao,
                percentualAproximacao: aderencia.ranking.percentualAproximacao,
                posicao: aderencia.ranking.posicao
              }
            ],
            aderenciaCiencias: arrayAderenciaCiencias,
            setChartAdequacao: [
              {
                data: [aderencia.ranking.percentualAproximacao, (100 - aderencia.ranking.percentualAproximacao)],
                label: 'Adequação'
              }
            ],
            setMatchCargo: selectSelecionado,
            status: Math.sign(aderencia.ranking.percentualAproximacao),
            curriculo: aderencia.curriculo,
            relatorio: aderencia.relatorioComportamental,
            docs: aderencia.outrosDocs
          });
          // Gráfico Visão do Grupo Ranking
          this.setChartDatasetsVisaoGrupo.push(
            aderencia.ranking.percentualAproximacao?.toFixed(2)
          );

          this.setChartLabelsVisaoGrupo.push(aderencia.nome);

        } 

      });

      if (this.newArrayAderencias.length > 0 && this.verificaAssociacao > 0) {
        this.mostraAdequacao = true;
        this.buttonWait = false; // habilita os botões

      } else {
        this.mostraAdequacao = false;
        this.buttonWait = false; // habilita os botões
      }

      this.getGraficosAderencias();

    });

    this.chartLabelsVisaoGrupo = this.setChartLabelsVisaoGrupo;

  }

  private getGraficosPadroesCiencias(
    padraoAxiologiaValue: Array<number> = [],
    padraoAxiologiaLabel: Array<string> = [],
    padraoComportamentoValue: Array<number> = [],
    padraoComportamentoLabel: Array<string> = [],
    padraoMotivadoresValue: Array<number> = [],
    padraoMotivadoresLabel: Array<string> = []) {

    this.chartLabelsCienciaAxiologia = padraoAxiologiaLabel;
    this.chartDatasetsCienciaAxiologia = [{ data: padraoAxiologiaValue, label: 'Axiologia' }];

    this.chartLabelsCienciaComportamento = padraoComportamentoLabel;
    this.chartDatasetsCienciaComportamento = [{ data: padraoComportamentoValue, label: 'Comportamento Observado' }];

    this.chartLabelsCienciaMotivadores = padraoMotivadoresLabel;
    this.chartDatasetsCienciaMotivadores = [{ data: padraoMotivadoresValue, label: 'Motivadores' }];

  }

  private getGraficosAderencias() {

    const arrayAdequacaoGeral = [];
    let calculoPorcentagemGeral: number;

    const arrayAdequacaoAxiologia = [];
    let calculoPorcentagemAxiologia: number;

    const arrayAdequacaoMotivadores = [];
    let calculoPorcentagemMotivadores: number;

    const arrayAdequacaoNatural = [];
    let calculoPorcentagemNatural: number;

    const arrayAdequacaoAdaptavel = [];
    let calculoPorcentagemAdaptavel: number;

    const arrayAdequacaoComportamento = [];
    let calculoPorcentagemComportamento: number;

    this.aderenciaAvaliadosAsync.forEach((aderencia) => {
        aderencia.aderenciaCiencias.forEach(ciencia => {
          ciencia.rankings.map(ranking => {
            if (ranking.descricao !== 'Comportamento Observado') {
              arrayAdequacaoGeral.push(ranking.percentualAproximacao);
              calculoPorcentagemGeral = arrayAdequacaoGeral.reduce( (accum, curr) => accum + curr );
              this.mediaFinalGrupo = calculoPorcentagemGeral / arrayAdequacaoGeral.length;
            }
            if (ciencia.ciencia === 'Axiologia') {
              arrayAdequacaoAxiologia.push(ranking.percentualAproximacao);
              calculoPorcentagemAxiologia = arrayAdequacaoAxiologia.reduce( (accum, curr) => accum + curr );
              this.mediaFinalAxiologiaGrupo = calculoPorcentagemAxiologia / arrayAdequacaoAxiologia.length;
            }
            if (ciencia.ciencia === 'Motivadores') {
              arrayAdequacaoMotivadores.push(ranking.percentualAproximacao);
              calculoPorcentagemMotivadores = arrayAdequacaoMotivadores.reduce( (accum, curr) => accum + curr );
              this.mediaFinalMotivadoresGrupo = calculoPorcentagemMotivadores / arrayAdequacaoMotivadores.length;
            }
            if (ciencia.ciencia === 'Comportamento Observado' && ranking.descricao === 'NATURAL') {
              arrayAdequacaoNatural.push(ranking.percentualAproximacao);
              calculoPorcentagemNatural = arrayAdequacaoNatural.reduce( (accum, curr) => accum + curr );
              this.mediaFinalNaturalGrupo = calculoPorcentagemNatural / arrayAdequacaoNatural.length;
            }
            if (ciencia.ciencia === 'Comportamento Observado' && ranking.descricao === 'ADAPTÁVEL') {
              arrayAdequacaoAdaptavel.push(ranking.percentualAproximacao);
              calculoPorcentagemAdaptavel = arrayAdequacaoAdaptavel.reduce( (accum, curr) => accum + curr );
              this.mediaFinalAdaptavelGrupo = calculoPorcentagemAdaptavel / arrayAdequacaoAdaptavel.length;
            }
            if (ciencia.ciencia === 'Comportamento Observado' && ranking.descricao === 'Comportamento Observado') {
              arrayAdequacaoComportamento.push(ranking.percentualAproximacao);
              calculoPorcentagemComportamento = arrayAdequacaoComportamento.reduce( (accum, curr) => accum + curr );
              this.mediaFinalComportamentoGrupo = calculoPorcentagemComportamento / arrayAdequacaoComportamento.length;
            }
          });
        });
      });

    this.chartDatasetsMediaGrupo = [
        { data: [this.mediaFinalGrupo, (100 - this.mediaFinalGrupo)], label: 'Adequação Grupo' }
      ];

    this.chartDatasetsAxiologiaGrupo = [
        { data: [this.mediaFinalAxiologiaGrupo, (100 - this.mediaFinalAxiologiaGrupo)], label: 'Adequação Axiologia Grupo' }
      ];

    this.chartDatasetsMotivadoresGrupo = [
        { data: [this.mediaFinalMotivadoresGrupo, (100 - this.mediaFinalMotivadoresGrupo)], label: 'Adequação Motivadores Grupo' }
      ];

    this.chartDatasetsComportamentoGrupo = [
        { data: [this.mediaFinalComportamentoGrupo, (100 - this.mediaFinalComportamentoGrupo)], label: 'Adequação Comportamento Grupo' }
      ];

    this.chartDatasetsNaturalGrupo = [
        { data: [this.mediaFinalNaturalGrupo, (100 - this.mediaFinalNaturalGrupo)], label: 'Adequação Natural Grupo' }
      ];

    this.chartDatasetsAdaptavelGrupo = [
        { data: [this.mediaFinalAdaptavelGrupo, (100 - this.mediaFinalAdaptavelGrupo)], label: 'Adequação Adaptável Grupo' }
      ];
  }

  private matchAvaliados(matchAxiologia, matchComportamento, matchMotivadores) {

    this.avaliadosCargoComportamento = [];
    this.avaliadosCargoMotivadores = [];
    this.avaliadosCargoAxiologia = [];

    if (this.avaliadosAvaliacaoAsync) {

           this.avaliadosAvaliacaoAsync.map(avaliadosAsync => {

              avaliadosAsync.resultadosCiencias.map(resultadoCienciasAsync => {

                // AXIOLOGIA
                if (resultadoCienciasAsync.cienciaId === 1) {
                  matchAxiologia.map(fatorAxiologia => {
                    resultadoCienciasAsync.resultados
                        .map(resultados => {

                          let nomeIgualAxiologia = false;

                          if (fatorAxiologia.fator === resultados.nomeFator) {
                            if (this.avaliadosCargoAxiologia.length > 0) {
                              this.avaliadosCargoAxiologia.map(avaliadosCargoAxi => {
                                if (+avaliadosCargoAxi.idAvaliado === +avaliadosAsync.id) {
                                  avaliadosCargoAxi.resultados.push(
                                    resultados
                                  );
                                  nomeIgualAxiologia = true;
                                }
                              });
                            }

                            if (!nomeIgualAxiologia) {
                              this.avaliadosCargoAxiologia.push(
                                  {
                                    idAvaliado: avaliadosAsync.id,
                                    nomeAvaliado: avaliadosAsync.nome,
                                    resultados: [
                                      resultados
                                    ]
                                  }
                                );
                            }
                          }
                        });
                      });
                }

                // COMPORTAMENTO OBSERVADO
                if (resultadoCienciasAsync.cienciaId === 2) {
                  matchComportamento.map(fatorComportamento => {
                    resultadoCienciasAsync.resultados
                        .map(resultados => {

                          let nomeIgualComportamento = false;

                          if (fatorComportamento.fator === resultados.nomeFator) {
                            if (this.avaliadosCargoComportamento.length > 0) {
                              this.avaliadosCargoComportamento.map(avaliadosCargoComp => {
                                if (+avaliadosCargoComp.idAvaliado === +avaliadosAsync.id) {
                                  avaliadosCargoComp.resultados.push(
                                    resultados
                                  );
                                  nomeIgualComportamento = true;
                                }
                              });
                            }

                            if (!nomeIgualComportamento) {
                              this.avaliadosCargoComportamento.push(
                                  {
                                    idAvaliado: avaliadosAsync.id,
                                    nomeAvaliado: avaliadosAsync.nome,
                                    resultados: [
                                      resultados
                                    ]
                                  }
                                );
                            }
                          }
                        });
                      });
                }

                // MOTIVADORES
                if (resultadoCienciasAsync.cienciaId === 3) {
                  matchMotivadores.map(fatorMotivadores => {
                    resultadoCienciasAsync.resultados
                        .map(resultados => {

                          let nomeIgualMotivadores = false;

                          if (fatorMotivadores.fator === resultados.nomeFator) {
                            if (this.avaliadosCargoMotivadores.length > 0) {
                              this.avaliadosCargoMotivadores.map(avaliadosCargoMot => {
                                if (+avaliadosCargoMot.idAvaliado === +avaliadosAsync.id) {
                                  avaliadosCargoMot.resultados.push(
                                    resultados
                                  );
                                  nomeIgualMotivadores = true;
                                }
                              });
                            }

                            if (!nomeIgualMotivadores) {
                              this.avaliadosCargoMotivadores.push(
                                  {
                                    idAvaliado: avaliadosAsync.id,
                                    nomeAvaliado: avaliadosAsync.nome,
                                    resultados: [
                                      resultados
                                    ]
                                  }
                                );
                            }
                          }
                        });
                      });
                }
              });
           });

            // AXIOLOGIA
           this.avaliadosCargoAxiologia.push({
              idAvaliado: null,
              nomeAvaliado: 'Perfil de referência',
              resultados: [
                {
                  nomeFator: null,
                  valor: null
                }
              ]
            });

           this.avaliadosCargoAxiologia.map(avaliadosCargoAxi => {
                matchAxiologia.map(fatorAxiologia => {
                if (avaliadosCargoAxi.nomeAvaliado === 'Perfil de referência') {
                  avaliadosCargoAxi.resultados.push({
                    nomeFator: fatorAxiologia.fator,
                    valor: fatorAxiologia.ideal
                  });
                }
              });
            });

            // COMPORTAMENTO OBSERVADO
           this.avaliadosCargoComportamento.push({
              idAvaliado: null,
              nomeAvaliado: 'Perfil de referência',
              resultados: [
                {
                  nomeFator: null,
                  valor: null
                }
              ]
            });

           this.avaliadosCargoComportamento.map(avaliadosCargoComp => {
                matchComportamento.map(fatorComportamento => {
                if (avaliadosCargoComp.nomeAvaliado === 'Perfil de referência') {
                  avaliadosCargoComp.resultados.push({
                    nomeFator: fatorComportamento.fator,
                    valor: fatorComportamento.ideal
                  });
                }
              });
            });

            // MOTIVADORES
           this.avaliadosCargoMotivadores.push({
              idAvaliado: null,
              nomeAvaliado: 'Perfil de referência',
              resultados: [
                {
                  nomeFator: null,
                  valor: null
                }
              ]
            });

           this.avaliadosCargoMotivadores.map(avaliadosCargoMot => {
                matchMotivadores.map(fatorMotivadores => {
                if (avaliadosCargoMot.nomeAvaliado === 'Perfil de referência') {
                  avaliadosCargoMot.resultados.push({
                    nomeFator: fatorMotivadores.fator,
                    valor: fatorMotivadores.ideal
                  });
                }
              });
            });

           this.setPadraoAxiologia(this.avaliadosCargoAxiologia);
           this.setPadraoComportamento(this.avaliadosCargoComportamento);
           this.setPadraoMotivadores(this.avaliadosCargoMotivadores);

    }

  }

  private setPadraoAxiologia(axiologia) {

      this.newArrayAxiologia = axiologia;
      this.newArrayAxiologia.forEach(arrayAxiologia => {
        arrayAxiologia.resultados.map((resultadoArray, index) => {
          if (resultadoArray.nomeFator === null) {
            arrayAxiologia.resultados.splice(index, 1);
            this.setGraficoGeralAxiologia(); // Carrega os gráficos que comparam os valores do Grupo com os valores do Cargo
          }
        });
      });
  }

  private setPadraoComportamento(comportamento) {

    this.newArrayComportamento = comportamento;
    this.newArrayComportamento.forEach(arrayComportamento => {
      if (arrayComportamento.nomeAvaliado === 'Perfil de referência') {
        arrayComportamento.resultados.map((resultadoArray, index) => {
          if (resultadoArray.nomeFator === null) {
            arrayComportamento.resultados.splice(index, 1);
          }
        });
      }
    });
    this.setGraficoGeralComportamento(); // Carrega os gráficos que comparam os valores do Grupo com os valores do Cargo

  }

  private setPadraoMotivadores(motivadores) {
    this.newArrayMotivadores = motivadores;
    this.newArrayMotivadores.forEach(arrayMotivadores => {
      arrayMotivadores.resultados.map((resultadoArray, index) => {
        if (resultadoArray.nomeFator === null) {
          arrayMotivadores.resultados.splice(index, 1);
        }
      });
    });
    this.setGraficoGeralMotivadores(); // Carrega os gráficos que comparam os valores do Grupo com os valores do Cargo
  }

  private setGraficoResultadoAxiologia(idAvaliado?: number) {

    const valuesChartAxiologia = this.newArrayAxiologia;

    this.setChartLabelsAxiologia = [];
    this.chartDatasetsAxiologia = [];
    this.chartLabelsAxiologia = [];

    valuesChartAxiologia.forEach((avaliadosCargo, index) => {
      avaliadosCargo.resultados.map(fatoresAxiologia => {
        if (index === 0) {
          this.setChartLabelsAxiologia.push(fatoresAxiologia.nomeFator);
        }

      });
    });

    let fatorIgual: boolean;
    valuesChartAxiologia.forEach(fatoresAxio => {

      fatoresAxio.resultados.forEach(fatorAxio => {

      fatorIgual = false;

      this.chartDatasetsAxiologia.forEach(chartData => {

        if (this.chartDatasetsAxiologia
          && +fatoresAxio.idAvaliado === +idAvaliado
          && fatoresAxio.nomeAvaliado === chartData.label
          && fatoresAxio.nomeAvaliado !== 'Perfil de referência'
          || fatoresAxio.nomeAvaliado === 'Perfil de referência'
          && chartData.label === 'Perfil de referência') {
          chartData.data.push(
              fatorAxio.valor
          );
          fatorIgual = true;
        }
      });

      if (!fatorIgual && fatoresAxio.idAvaliado === idAvaliado) {
        this.chartDatasetsAxiologia.push({
          data: [fatorAxio.valor],
          label: fatoresAxio.nomeAvaliado
        });
      }
      if (!fatorIgual && fatoresAxio.nomeAvaliado === 'Perfil de referência') {
        this.chartDatasetsAxiologia.push({
          data: [fatorAxio.valor],
          label: fatoresAxio.nomeAvaliado
        });
      }
     });
    });

    this.chartLabelsAxiologia = this.setChartLabelsAxiologia;
    this.chartDatasetsAxiologia = this.chartDatasetsAxiologia;

  }

  private setGraficoResultadoComportamento(idAvaliado?: number) {

    const valuesChartComportamento = this.newArrayComportamento;

    this.setChartLabelsComportamento = [];
    this.chartDatasetsComportamento = [];
    this.chartLabelsComportamento = [];

    valuesChartComportamento.forEach((avaliadosCargo, index) => {
      avaliadosCargo.resultados.map(fatoresComportamento => {
        if (index === 0) {
          switch (fatoresComportamento.nomeFator) {
            case 'DISC NATURAL - Dominância':
              this.setChartLabelsComportamento.push('Dominância');
            break;

            case 'DISC NATURAL - Influência':
              this.setChartLabelsComportamento.push('Influência');
            break;

            case 'DISC NATURAL - Estabilidade':
              this.setChartLabelsComportamento.push('Estabilidade');
            break;

            case 'DISC NATURAL - Cautela':
              this.setChartLabelsComportamento.push('Cautela');
            break;

            case 'DISC ADAPTÁVEL - Dominância':
              this.setChartLabelsComportamento.push('Dominância (A)');
            break;

            case 'DISC ADAPTÁVEL - Influência':
              this.setChartLabelsComportamento.push('Influência (A)');
            break;

            case 'DISC ADAPTÁVEL - Estabilidade':
              this.setChartLabelsComportamento.push('Estabilidade (A)');
            break;

            case 'DISC ADAPTÁVEL - Cautela':
              this.setChartLabelsComportamento.push('Cautela (A)');
            break;
          }
          // this.setChartLabelsComportamento.push(fatoresComportamento.nomeFator);
        }

      });
    });

    let fatorIgual: boolean;
    valuesChartComportamento.forEach(fatoresComp => {

      fatoresComp.resultados.forEach(fatorComp => {

      fatorIgual = false;

      this.chartDatasetsComportamento.forEach(chartData => {

        if (this.chartDatasetsComportamento
          && +fatoresComp.idAvaliado === +idAvaliado
          && fatoresComp.nomeAvaliado === chartData.label
          && fatoresComp.nomeAvaliado !== 'Perfil de referência'
          || fatoresComp.nomeAvaliado === 'Perfil de referência'
          && chartData.label === 'Perfil de referência') {
          chartData.data.push(
              fatorComp.valor
          );
          fatorIgual = true;
        }
      });

      if (!fatorIgual && fatoresComp.idAvaliado === idAvaliado) {
        this.chartDatasetsComportamento.push({
          data: [fatorComp.valor],
          label: fatoresComp.nomeAvaliado
        });
      }
      if (!fatorIgual && fatoresComp.nomeAvaliado === 'Perfil de referência') {
        this.chartDatasetsComportamento.push({
          data: [fatorComp.valor],
          label: fatoresComp.nomeAvaliado
        });
      }
     });
    });

    this.chartLabelsComportamento = this.setChartLabelsComportamento;
    this.chartDatasetsComportamento = this.chartDatasetsComportamento;


  }

  private setGraficoResultadoMotivadores(idAvaliado?: number) {

    const valuesChartMotivadores = this.newArrayMotivadores;

    this.setChartLabelsMotivadores = [];
    this.chartDatasetsMotivadores = [];
    this.chartLabelsMotivadores = [];

    valuesChartMotivadores.forEach((avaliadosCargo, index) => {
      avaliadosCargo.resultados.map(fatoresMotivadores => {
        if (index === 0) {
          this.setChartLabelsMotivadores.push(fatoresMotivadores.nomeFator);
        }

      });
    });

    let fatorIgual: boolean;

    valuesChartMotivadores.forEach(fatoresMotivadores => {

      fatoresMotivadores.resultados.forEach(fatorMotivadores => {

      fatorIgual = false;

      this.chartDatasetsMotivadores.forEach(chartData => {

        if (this.chartDatasetsMotivadores
          && +fatoresMotivadores.idAvaliado === +idAvaliado
          && fatoresMotivadores.nomeAvaliado === chartData.label
          && fatoresMotivadores.nomeAvaliado !== 'Perfil de referência'
          || fatoresMotivadores.nomeAvaliado === 'Perfil de referência'
          && chartData.label === 'Perfil de referência') {
          chartData.data.push(
              fatorMotivadores.valor
          );
          fatorIgual = true;
        }

      });

      if (!fatorIgual && +fatoresMotivadores.idAvaliado === +idAvaliado) {
        this.chartDatasetsMotivadores.push({
          data: [fatorMotivadores.valor],
          label: fatoresMotivadores.nomeAvaliado
        });
      }
      if (!fatorIgual && fatoresMotivadores.nomeAvaliado === 'Perfil de referência') {
        this.chartDatasetsMotivadores.push({
          data: [fatorMotivadores.valor],
          label: fatoresMotivadores.nomeAvaliado
        });
      }

    });

  });

    this.chartLabelsMotivadores = this.setChartLabelsMotivadores;
    this.chartDatasetsMotivadores = this.chartDatasetsMotivadores;

  }

  private setGraficoGeralAxiologia() {

    const valuesChartAxiologia = this.newArrayAxiologia;
    const setAxiologiaGeral = [];

    this.setChartLabelsAxiologiaGeral = [];
    this.chartDatasetsAxiologiaGeral = [];
    this.chartLabelsAxiologiaGeral = [];

    valuesChartAxiologia.forEach((avaliadosCargo, index) => {
      avaliadosCargo.resultados.map(fatoresAxiologia => {
        if (index === 0) {
          this.setChartLabelsAxiologiaGeral.push(fatoresAxiologia.nomeFator);
        }
      });
    });

    let fatorIgual: boolean;
    let verificaPadrao: boolean;
    let sumValor: number;
    let mediaValor: number;

    valuesChartAxiologia.map(fatoresComp => {
      fatoresComp.resultados.map(fatorComp => {
        fatorIgual = false;
        setAxiologiaGeral.map(chartData => {
          if (setAxiologiaGeral && fatorComp.nomeFator === chartData.fator && chartData.label === 'Grupo' && fatoresComp.nomeAvaliado !== 'Perfil de referência') {
            chartData.valores.push( +fatorComp.valor );
            fatorIgual = true;
            verificaPadrao = true;
            sumValor = chartData.valores.reduce( (accum, curr) => accum + curr ); // soma dos valores dos fatores iguais
            mediaValor = sumValor / (valuesChartAxiologia.length - 1); // calculo da média da soma anterior
            chartData.data = mediaValor.toFixed(0);
          }
        });

        if (!fatorIgual && fatoresComp.nomeAvaliado !== 'Perfil de referência') {
          setAxiologiaGeral.push({
            data: '',
            fator: fatorComp.nomeFator,
            valores: [+fatorComp.valor],
            label: 'Grupo'
          });
        }

        if (verificaPadrao) {
          this.chartDatasetsAxiologiaGeral.map(chartData => {
            if (this.chartDatasetsAxiologiaGeral && chartData.label === 'Perfil de referência' && fatoresComp.nomeAvaliado === 'Perfil de referência') {
              chartData.data.push( fatorComp.valor );
              fatorIgual = true;
            }
          });
          if (!fatorIgual && fatoresComp.nomeAvaliado === 'Perfil de referência') {
            this.chartDatasetsAxiologiaGeral.push({
              data: [fatorComp.valor],
              label: 'Perfil de referência'
            });
          }

        }
      });
    });

    fatorIgual = false;

    setAxiologiaGeral.map(media => {
      this.chartDatasetsAxiologiaGeral.map(chartData => {
        if (this.chartDatasetsAxiologiaGeral && chartData.label === 'Grupo') {
          chartData.data.push( media.data );
          fatorIgual = true;
        }
      });
      if (!fatorIgual && media.label === 'Grupo') {
          this.chartDatasetsAxiologiaGeral.push({
            data: [media.data],
            label: 'Grupo'
          });
        }

    });

    this.chartLabelsAxiologiaGeral = this.setChartLabelsAxiologiaGeral;
    this.chartDatasetsAxiologiaGeral = this.chartDatasetsAxiologiaGeral.reverse();

  }

  private setGraficoGeralComportamento() {

    const valuesChartComportamento = this.newArrayComportamento;
    const setComportamentoGeral = [];

    this.setChartLabelsComportamentoGeral = [];
    this.chartDatasetsComportamentoGeral = [];
    this.chartLabelsComportamentoGeral = [];

    valuesChartComportamento.forEach((avaliadosCargo, index) => {
      avaliadosCargo.resultados.map(fatoresComportamento => {
        if (index === 0) {
          switch (fatoresComportamento.nomeFator) {
            case 'DISC NATURAL - Dominância':
              this.setChartLabelsComportamentoGeral.push('Dominância');
            break;

            case 'DISC NATURAL - Influência':
              this.setChartLabelsComportamentoGeral.push('Influência');
            break;

            case 'DISC NATURAL - Estabilidade':
              this.setChartLabelsComportamentoGeral.push('Estabilidade');
            break;

            case 'DISC NATURAL - Cautela':
              this.setChartLabelsComportamentoGeral.push('Cautela');
            break;

            case 'DISC ADAPTÁVEL - Dominância':
              this.setChartLabelsComportamentoGeral.push('Dominância (A)');
            break;

            case 'DISC ADAPTÁVEL - Influência':
              this.setChartLabelsComportamentoGeral.push('Influência (A)');
            break;

            case 'DISC ADAPTÁVEL - Estabilidade':
              this.setChartLabelsComportamentoGeral.push('Estabilidade (A)');
            break;

            case 'DISC ADAPTÁVEL - Cautela':
              this.setChartLabelsComportamentoGeral.push('Cautela (A)');
            break;
          }
          // this.setChartLabelsComportamentoGeral.push(fatoresComportamento.nomeFator);
        }
      });
    });

    let fatorIgual: boolean;
    let verificaPadrao: boolean;
    let sumValor: number;
    let mediaValor: number;

    let newFatoresComp;

    valuesChartComportamento.forEach(fatoresComp => {
      newFatoresComp = fatoresComp.resultados.sort((a, b) => a.fatorId - b.fatorId);
      newFatoresComp.forEach(fatorComp => {

        fatorIgual = false;
        setComportamentoGeral.forEach(chartData => {
          if (setComportamentoGeral && fatorComp.nomeFator === chartData.fator && chartData.label === 'Grupo' && fatoresComp.nomeAvaliado !== 'Perfil de referência') {
            chartData.valores.push( +fatorComp.valor );
            fatorIgual = true;
            verificaPadrao = true;
            sumValor = chartData.valores.reduce( (accum, curr) => accum + curr ); // soma dos valores dos fatores iguais
            mediaValor = sumValor / (valuesChartComportamento.length - 1); // calculo da média da soma anterior
            chartData.data = mediaValor.toFixed(0);
          }
        });

        if (!fatorIgual && fatoresComp.nomeAvaliado !== 'Perfil de referência') {
          setComportamentoGeral.push({
            data: '',
            fator: fatorComp.nomeFator,
            valores: [+fatorComp.valor],
            label: 'Grupo'
          });
        }

        if (verificaPadrao) {
          this.chartDatasetsComportamentoGeral.forEach(chartData => {
            if (this.chartDatasetsComportamentoGeral && chartData.label === 'Perfil de referência' && fatoresComp.nomeAvaliado === 'Perfil de referência') {
              chartData.data.push( fatorComp.valor );
              fatorIgual = true;
            }
          });
          if (!fatorIgual && fatoresComp.nomeAvaliado === 'Perfil de referência') {
            this.chartDatasetsComportamentoGeral.push({
              data: [fatorComp.valor],
              label: 'Perfil de referência'
            });
          }

        }
      });
    });

    fatorIgual = false;

    setComportamentoGeral.forEach(media => {
      this.chartDatasetsComportamentoGeral.forEach(chartData => {
        if (this.chartDatasetsComportamentoGeral && chartData.label === 'Grupo') {
          chartData.data.push( media.data );
          fatorIgual = true;
        }
      });
      if (!fatorIgual && media.label === 'Grupo') {
          this.chartDatasetsComportamentoGeral.push({
            data: [media.data],
            label: 'Grupo'
          });
        }
    });

    this.chartLabelsComportamentoGeral = this.setChartLabelsComportamentoGeral;
    this.chartDatasetsComportamentoGeral = this.chartDatasetsComportamentoGeral.reverse();

  }

  private setGraficoGeralMotivadores() {

    const valuesChartMotivadores = this.newArrayMotivadores;
    const setMotivadoresGeral = [];

    this.setChartLabelsMotivadoresGeral = [];
    this.chartDatasetsMotivadoresGeral = [];
    this.chartLabelsMotivadoresGeral = [];

    valuesChartMotivadores.forEach((avaliadosCargo, index) => {
      avaliadosCargo.resultados.map(fatoresMotivadores => {
        if (index === 0) {
          this.setChartLabelsMotivadoresGeral.push(fatoresMotivadores.nomeFator);
        }
      });
    });

    let fatorIgual: boolean;
    let verificaPadrao: boolean;
    let sumValor: number;
    let mediaValor: number;

    valuesChartMotivadores.forEach(fatoresComp => {
      fatoresComp.resultados.forEach(fatorComp => {
        fatorIgual = false;
        setMotivadoresGeral.forEach(chartData => {
          if (setMotivadoresGeral && fatorComp.nomeFator === chartData.fator && chartData.label === 'Grupo' && fatoresComp.nomeAvaliado !== 'Perfil de referência') {
            chartData.valores.push( +fatorComp.valor );
            fatorIgual = true;
            verificaPadrao = true;
            sumValor = chartData.valores.reduce( (accum, curr) => accum + curr ); // soma dos valores dos fatores iguais
            mediaValor = sumValor / (valuesChartMotivadores.length - 1); // calculo da média da soma anterior e o -1 é para excluir o "Perfil de referência"
            chartData.data = mediaValor.toFixed(0);
          }
        });

        if (!fatorIgual && fatoresComp.nomeAvaliado !== 'Perfil de referência') {
          setMotivadoresGeral.push({
            data: '',
            fator: fatorComp.nomeFator,
            valores: [+fatorComp.valor],
            label: 'Grupo'
          });
        }

        if (verificaPadrao) {
          this.chartDatasetsMotivadoresGeral.forEach(chartData => {
            if (this.chartDatasetsMotivadoresGeral && chartData.label === 'Perfil de referência' && fatoresComp.nomeAvaliado === 'Perfil de referência') {
              chartData.data.push( fatorComp.valor );
              fatorIgual = true;
            }
          });
          if (!fatorIgual && fatoresComp.nomeAvaliado === 'Perfil de referência') {
            this.chartDatasetsMotivadoresGeral.push({
              data: [fatorComp.valor],
              label: 'Perfil de referência'
            });
          }

        }
      });
    });

    fatorIgual = false;

    setMotivadoresGeral.forEach(media => {
      this.chartDatasetsMotivadoresGeral.forEach(chartData => {
        if (this.chartDatasetsMotivadoresGeral && chartData.label === 'Grupo') {
          chartData.data.push( media.data );
          fatorIgual = true;
        }
      });
      if (!fatorIgual && media.label === 'Grupo') {
          this.chartDatasetsMotivadoresGeral.push({
            data: [media.data],
            label: 'Grupo'
          });
        }

    });

    this.chartLabelsMotivadoresGeral = this.setChartLabelsMotivadoresGeral;
    this.chartDatasetsMotivadoresGeral = this.chartDatasetsMotivadoresGeral.reverse();

  }

  private matchAvaliadosCalculos( matchAxiologia: LucorApiModelsNewPerfil[],
                                  matchComportamento: LucorApiModelsNewPerfil[],
                                  matchMotivadores: LucorApiModelsNewPerfil[]) {

    this.countAvaliados = 0;

    this.avaliadosAvaliacaoAsync.map(avaliadosAsync => {

      this.countAvaliados++;

      avaliadosAsync.resultadosCiencias.map(resultadoCienciasAsync => {

        // COMPORTAMENTO OBSERVADO
        if (matchComportamento.length > 0) {
          if (resultadoCienciasAsync.cienciaId === 2) {

            matchComportamento.map(perfilComportamento => {
              resultadoCienciasAsync.resultados
                .filter(resultados => {
                  let nomeIgual = false;

                  // if (perfilComportamento.fator === resultados.nomeFator && resultados.nomeFator.includes('NATURAL')) {
                  if (perfilComportamento.fator === resultados.nomeFator) {

                    if (this.avaliadosCargoComportamentoCalculo.length > 0) {
                      this.avaliadosCargoComportamentoCalculo.map(avaliadosCargoComp => {

                        if (perfilComportamento.fator === avaliadosCargoComp.nomeFator) {

                          avaliadosCargoComp.resultados.push(
                            {
                              idAvaliado: avaliadosAsync.id,
                              nomeAvaliado: avaliadosAsync.nome,
                              sexo: avaliadosAsync.sexo,
                              valor: +resultados.valor,
                              classificacao: resultados.classificacao,
                              diferenca: undefined,
                              avaliacaoId: avaliadosAsync.avaliacaoId,
                              status: undefined,
                              fatores: [resultados],
                            }
                              );
                          nomeIgual = true;
                        } else { // Condição para gerar dados para calcular diferença entre fatores Natural x Adaptável, usado em Feedback.
                          avaliadosCargoComp.resultados.map(res => {
                            if (avaliadosAsync.id === res.idAvaliado) {
                              res.fatores.push(resultados);
                            }
                          });
                        }
                      });
                    }

                    if (!nomeIgual) {
                      this.avaliadosCargoComportamentoCalculo.push(
                          {
                            fatorId: resultados.fatorId,
                            nomeFator: resultados.nomeFator,
                            padrao: undefined,
                            countAcima: undefined,
                            countAbaixo: undefined,
                            countExato: undefined,
                            resultados: [
                              {
                                idAvaliado: avaliadosAsync.id,
                                nomeAvaliado: avaliadosAsync.nome,
                                sexo: avaliadosAsync.sexo,
                                valor: +resultados.valor,
                                classificacao: resultados.classificacao,
                                diferenca: undefined,
                                avaliacaoId: avaliadosAsync.avaliacaoId,
                                status: undefined,
                                fatores: [resultados],
                              }
                            ]
                          }
                        );
                    }
                  }

                });


              });
        }
        }

        // MOTIVADORES
        if (matchMotivadores.length > 0) {
        if (resultadoCienciasAsync.cienciaId === 3) {
          matchMotivadores.map(perfilMotivadores => {
            resultadoCienciasAsync.resultados
                .filter(resultados => {

                  let nomeIgual = false;

                  if (perfilMotivadores.fator === resultados.nomeFator) {
                    if (this.avaliadosCargoMotivadoresCalculo.length > 0) {
                      this.avaliadosCargoMotivadoresCalculo.map(avaliadosCargoComp => {

                        if (perfilMotivadores.fator === avaliadosCargoComp.nomeFator) {
                      // if (avaliadosCargoComp.resultados.nomeAvaliado === avaliadosAsync.nome) {
                          avaliadosCargoComp.resultados.push(
                            {
                              idAvaliado: avaliadosAsync.id,
                              nomeAvaliado: avaliadosAsync.nome,
                              valor: +resultados.valor,
                              classificacao: resultados.classificacao,
                              diferenca: undefined,
                              avaliacaoId: avaliadosAsync.avaliacaoId,
                              status: undefined
                            }
                              );
                          nomeIgual = true;
                        }
                      });
                    }

                    if (!nomeIgual) {
                      this.avaliadosCargoMotivadoresCalculo.push(
                          {
                            fatorId: resultados.fatorId,
                            nomeFator: resultados.nomeFator,
                            padrao: undefined,
                            countAcima: undefined,
                            countAbaixo: undefined,
                            countExato: undefined,
                            resultados: [
                              {
                                idAvaliado: avaliadosAsync.id,
                                nomeAvaliado: avaliadosAsync.nome,
                                valor: +resultados.valor,
                                classificacao: resultados.classificacao,
                                diferenca: undefined,
                                avaliacaoId: avaliadosAsync.avaliacaoId,
                                status: undefined
                              }
                            ]
                          }
                        );
                    }
                  }
                });
              });
        }
        }

        // AXIOLOGIA
        if (matchAxiologia.length > 0) {
          if (resultadoCienciasAsync.cienciaId === 1) {
          matchAxiologia.map(perfilAxiologia => {
            resultadoCienciasAsync.resultados
              .filter(resultados => {

                let nomeIgual = false;

                if (perfilAxiologia.fator === resultados.nomeFator) {
                  if (this.avaliadosCargoAxiologiaCalculo.length > 0) {
                    this.avaliadosCargoAxiologiaCalculo.map(avaliadosCargoComp => {

                      if (perfilAxiologia.fator === avaliadosCargoComp.nomeFator) {
                    // if (avaliadosCargoComp.resultados.nomeAvaliado === avaliadosAsync.nome) {
                        avaliadosCargoComp.resultados.push(
                          {
                            idAvaliado: avaliadosAsync.id,
                            nomeAvaliado: avaliadosAsync.nome,
                            valor: +resultados.valor,
                            classificacao: resultados.classificacao,
                            diferenca: undefined,
                            avaliacaoId: avaliadosAsync.avaliacaoId,
                            status: undefined
                          }
                            );
                        nomeIgual = true;
                      }
                    });
                  }

                  if (!nomeIgual) {
                    this.avaliadosCargoAxiologiaCalculo.push(
                        {
                          fatorId: resultados.fatorId,
                          nomeFator: resultados.nomeFator,
                          padrao: undefined,
                          countAcima: undefined,
                          countAbaixo: undefined,
                          countExato: undefined,
                          resultados: [
                            {
                              idAvaliado: avaliadosAsync.id,
                              nomeAvaliado: avaliadosAsync.nome,
                              valor: +resultados.valor,
                              classificacao: resultados.classificacao,
                              diferenca: undefined,
                              avaliacaoId: avaliadosAsync.avaliacaoId,
                              status: undefined
                            }
                          ]
                        }
                      );
                  }
                }
              });
            });
        }
        }
      });
    });

    this.setCalculoAxiologia(matchAxiologia);
    this.setCalculoComportamento(matchComportamento);
    this.setCalculoMotivadores(matchMotivadores);
    this.setCalculoBalancoComportamento();

  }

  private setCalculoBalancoComportamento() {

    this.balancoComportamento = [];
    let porcentagem: number;

    if (this.avaliadosAvaliacaoAsync.length > 0) {
      this.avaliadosAvaliacaoAsync.forEach(arrayComportamento => {

        this.balancoComportamento.push({
          fator: [],
          avaliado: arrayComportamento.nome,
          avaliadoId: arrayComportamento.id,
        });

        arrayComportamento.resultadosCiencias.forEach(ciencias => {

          if (ciencias.cienciaId === 2) {

            ciencias.resultados.sort((a, b) => a.fatorId - b.fatorId).forEach(fatores => {

                if (this.balancoComportamento) {
                  this.balancoComportamento.map(balanco => {

                    if (arrayComportamento.id === balanco.avaliadoId) {
                      if (fatores.fatorId === 82 || fatores.fatorId === 83 || fatores.fatorId === 84 || fatores.fatorId === 85) {
                      balanco.fator.push(
                          {
                            fatorId: fatores.fatorId,
                            nomeFator: fatores.nomeFator,
                            valorNatural: fatores.valor,
                            valorAdaptavel: null,
                            diferenca: null,
                            status: null
                          }
                      );
                        }
                    }

                    balanco.fator.map(fator => {

                      if (fatores.fatorId === 86 && fator.nomeFator === 'DISC NATURAL - Dominância'
                        && arrayComportamento.id === balanco.avaliadoId) {
                        fator.valorAdaptavel = fatores.valor;
                        porcentagem = (fator.valorAdaptavel * 100) / fator.valorNatural;
                        fator.diferenca = (100 - porcentagem);
                        fator.status = fator.valorNatural === fator.valorAdaptavel ? 'Exato' : fator.valorNatural < fator.valorAdaptavel ? 'Acima' : 'Abaixo';
                      }

                      if (fatores.fatorId === 87 && fator.nomeFator === 'DISC NATURAL - Influência'
                        && arrayComportamento.id === balanco.avaliadoId) {
                        fator.valorAdaptavel = fatores.valor;
                        porcentagem = (fator.valorAdaptavel * 100) / fator.valorNatural;
                        fator.diferenca = (100 - porcentagem);
                        fator.status = fator.valorNatural === fator.valorAdaptavel ? 'Exato' : fator.valorNatural < fator.valorAdaptavel ? 'Acima' : 'Abaixo';
                      }

                      if (fatores.fatorId === 88 && fator.nomeFator === 'DISC NATURAL - Estabilidade'
                        && arrayComportamento.id === balanco.avaliadoId) {
                        fator.valorAdaptavel = fatores.valor;
                        porcentagem = (fator.valorAdaptavel * 100) / fator.valorNatural;
                        fator.diferenca = (100 - porcentagem);
                        fator.status = fator.valorNatural === fator.valorAdaptavel ? 'Exato' : fator.valorNatural < fator.valorAdaptavel ? 'Acima' : 'Abaixo';
                      }

                      if (fatores.fatorId === 88 && fator.nomeFator === 'DISC NATURAL - Cautela'
                        && arrayComportamento.id === balanco.avaliadoId) {
                        fator.valorAdaptavel = fatores.valor;
                        porcentagem = (fator.valorAdaptavel * 100) / fator.valorNatural;
                        fator.diferenca = (100 - porcentagem);
                        fator.status = fator.valorNatural === fator.valorAdaptavel ? 'Exato' : fator.valorNatural < fator.valorAdaptavel ? 'Acima' : 'Abaixo';
                      }

                    });
                  });
                }
            });
          }
      });
      });
    }
  }

  private setCalculoComportamento(matchComportamento: LucorApiModelsNewPerfil[]) {

    let porcentagem: number;

    if (this.avaliadosCargoComportamentoCalculo.length > 0) {

      this.avaliadosCargoComportamentoCalculo.forEach(arrayComportamento => {

        const countComportamentoAcima = [];
        const countComportamentoAbaixo = [];
        const countComportamentoExato = [];

        arrayComportamento.resultados.forEach(comportamento => {

          matchComportamento.map(padrao => {

            if (arrayComportamento.nomeFator === padrao.fator) {
              arrayComportamento.padrao = padrao.ideal;
              porcentagem = (comportamento.valor * 100) / padrao.ideal; // Regra de 3 para chegar na equiparação da porcentagem
              comportamento.diferenca = (100 - porcentagem);
              // a ordem do 100 antes é para inverter o sinal.. a diferença que for positiva ficará com sinal negativo.. na lista isso representará que é necessário baixar para chegar no ideal.
              if (comportamento.diferenca < 0) {
                comportamento.status = 'ACIMA';
                countComportamentoAcima.push(comportamento.diferenca);
                arrayComportamento.countAcima = countComportamentoAcima.length;
              }
              if (comportamento.diferenca > 0) {
                comportamento.status = 'ABAIXO';
                countComportamentoAbaixo.push(comportamento.diferenca);
                arrayComportamento.countAbaixo = countComportamentoAbaixo.length;
              }
              if (comportamento.diferenca === 0) {
                comportamento.status = 'IDEAL';
                countComportamentoExato.push(comportamento.diferenca);
                arrayComportamento.countExato = countComportamentoExato.length;
              }
            }
          });
        });
      });
    }
  }

  private setCalculoMotivadores(matchMotivadores: LucorApiModelsNewPerfil[]) {

    let porcentagem: number;

    if (this.avaliadosCargoMotivadoresCalculo.length > 0) {

    this.avaliadosCargoMotivadoresCalculo.forEach(arrayMotivadores => {

      const countMotivadoresAcima = [];
      const countMotivadoresAbaixo = [];
      const countMotivadoresExato = [];

      arrayMotivadores.resultados.forEach(motivadores => {
        matchMotivadores.map(padrao => {
          if (arrayMotivadores.nomeFator === padrao.fator) {
            arrayMotivadores.padrao = padrao.ideal;
            porcentagem = (motivadores.valor * 100) / padrao.ideal; // Regra de 3 para chegar na equiparação da porcentagem
            motivadores.diferenca = (100 - porcentagem);
            // a ordem do 100 antes é para inverter o sinal.. a diferença que for positiva ficará com sinal negativo.. na lista isso representará que é necessário baixar para chegar no ideal.
            if (motivadores.diferenca < 0) {
              motivadores.status = 'ACIMA';
              countMotivadoresAcima.push(motivadores.diferenca);
              arrayMotivadores.countAcima = countMotivadoresAcima.length;
            }
            if (motivadores.diferenca > 0) {
              motivadores.status = 'ABAIXO';
              countMotivadoresAbaixo.push(motivadores.diferenca);
              arrayMotivadores.countAbaixo = countMotivadoresAbaixo.length;
            }
            if (motivadores.diferenca === 0) {
              motivadores.status = 'IDEAL';
              countMotivadoresExato.push(motivadores.diferenca);
              arrayMotivadores.countExato = countMotivadoresExato.length;
            }
          }
        });
      });
    });
    }

  }

  private setCalculoAxiologia(matchMotivadores: LucorApiModelsNewPerfil[]) {

    let porcentagem: number;

    if (this.avaliadosCargoAxiologiaCalculo.length > 0) {

    this.avaliadosCargoAxiologiaCalculo.forEach(arrayAxiologia => {

      const countAxiologiaAcima = [];
      const countAxiologiaAbaixo = [];
      const countAxiologiaExato = [];

      arrayAxiologia.resultados.forEach(axiologia => {
        matchMotivadores.map(padrao => {
          if (arrayAxiologia.nomeFator === padrao.fator) {
            arrayAxiologia.padrao = padrao.ideal;
            porcentagem = (axiologia.valor * 100) / padrao.ideal; // Regra de 3 para chegar na equiparação da porcentagem
            axiologia.diferenca = (100 - porcentagem);
            // a ordem do 100 antes é para inverter o sinal.. a diferença que for positiva ficará com sinal negativo.. na lista isso representará que é necessário baixar para chegar no ideal.
            if (axiologia.diferenca < 0) {
              axiologia.status = 'ACIMA';
              countAxiologiaAcima.push(axiologia.diferenca);
              arrayAxiologia.countAcima = countAxiologiaAcima.length;
            }
            if (axiologia.diferenca > 0) {
              axiologia.status = 'ABAIXO';
              countAxiologiaAbaixo.push(axiologia.diferenca);
              arrayAxiologia.countAbaixo = countAxiologiaAbaixo.length;
            }
            if (axiologia.diferenca === 0) {
              axiologia.status = 'IDEAL';
              countAxiologiaExato.push(axiologia.diferenca);
              arrayAxiologia.countExato = countAxiologiaExato.length;
            }
          }
        });
      });
    });
  }
  }

  private downLoadFile(data: any, type: string, fileName: string) {
    const blob = new Blob([data], { type });
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    if (fileName) {
      downloadLink.setAttribute('download', fileName);
      downloadLink.setAttribute('id', 'buttomDownload');
    }
    document.body.appendChild(downloadLink);
    downloadLink.click();
    this.printWait = false;

  }

  private getFileNameFromHttpResponse(httpResponse: HttpResponse<MicrosoftAspNetCoreMvcFileContentResult>): string {
    const contentDispositionHeader = httpResponse.headers.get('Content-Disposition');
    const result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
    return result.replace(/"/g, '');
  }

  private goTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

}
