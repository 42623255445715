import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { CienciasComponent } from './components/ciencias/ciencias.component';
import { PalavrasChaveComponent } from './components/palavras-chave/palavras-chave.component';
import { PalavrasChaveCadastrarComponent } from './components/palavras-chave-cadastrar/palavras-chave-cadastrar.component';
import { SetPalavrasComponent } from './components/set-palavras/set-palavras.component';
import { DescricaoCadastrarComponent } from './components/descricao-cadastrar/descricao-cadastrar.component';
import { SetDescricaoComponent } from './components/set-descricao/set-descricao.component';
import { CienciasRoutingModule } from './ciencias.routing.module';
import { SharedModule } from 'app/shared/shared.module';
import { OrderModule } from 'ngx-order-pipe';
import { PopoverModule } from 'angular-bootstrap-md';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        SharedModule,
        OrderModule,
        PopoverModule,
        CienciasRoutingModule
    ],
    declarations: [
        CienciasComponent,
        PalavrasChaveComponent,
        PalavrasChaveCadastrarComponent,
        SetPalavrasComponent,
        DescricaoCadastrarComponent,
        SetDescricaoComponent,
    ],
    providers: [
        SharedModule,
        OrderModule
      ],
  })

  export class CienciasModule {}
