/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.45
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { LucorApiModelsNewEmail } from './lucorApiModelsNewEmail';
import { LucorApiModelsNewEndereco } from './lucorApiModelsNewEndereco';
import { LucorApiModelsNewTelefone } from './lucorApiModelsNewTelefone';


/**
 *
 */
export interface LucorApiModelsNewPessoaJuridica {
    /**
     * Gets or Sets RazaoSocial
     */
    razaoSocial: string;
    /**
     * Gets or Sets Cnpj
     */
    cnpj: string;
    /**
     * Gets or Sets Enderecos
     */
    enderecos?: Array<LucorApiModelsNewEndereco>;
    /**
     * Gets or Sets Emails
     */
    emails?: Array<LucorApiModelsNewEmail>;
    /**
     * Gets or Sets Telefones
     */
    telefones?: Array<LucorApiModelsNewTelefone>;
}
