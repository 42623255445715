
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { FavoritosApiService } from 'app/api/favoritosApi.service';
import {
  LucorApiModelsNewNewListagemFavoritos,
  LucorApiModelsDetailsListagemFavoritos } from 'app/model/models';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-cargos-detalhes-selecionar',
  templateUrl: './cargos-detalhes-selecionar.component.html',
  styleUrls: ['./cargos-detalhes-selecionar.component.css']
})
export class CargosDetalhesSelecionarComponent implements OnInit, OnDestroy {

  @Input() idAvaliado: number;
  @Input() idCargo: number;
  @Input() idCliente: number;
  @Output() updateLista = new EventEmitter();
  @Output() adicionarSelecionado = new EventEmitter();
  @Output() modalSelecionado = new EventEmitter();
  @Output() cancelModal = new EventEmitter();
  idListaSelecionados: any | number = null;
  criarNovaPasta = false;
  nomeNovaPasta: string;
  submitted = false;

  listagensSelecionados$: Observable<LucorApiModelsDetailsListagemFavoritos[]>;

  private subs = new SubSink();

  constructor(
    private selecionadosService: FavoritosApiService,
    private alertService: AlertModalService) {}

  ngOnInit() {
    this.listagensSelecionados$ = this.selecionadosService.getListagemAsync(true);
  }

  criarPasta() {
    this.submitted = true;
    let novaPasta: LucorApiModelsNewNewListagemFavoritos;

    if (this.nomeNovaPasta) {
      novaPasta = ({nome: this.nomeNovaPasta, pessoaJuridicaId: this.idCliente, cargoId: this.idCargo});
      this.subs.sink = this.selecionadosService.addListagemAsync(novaPasta)
      .subscribe(success => {
        this.alertService.showAlertSuccess('Nova Pasta criada com sucesso. Pode adicionar a pessoa agora.');
        this.idListaSelecionados = success.id;
      }, error => {
        console.error(error);
        this.alertService.showAlertDanger('Não foi possível criar esta pasta! Tente novamente.');
      });
    }
  }

  addSelecionado() {
    const idLista: Array<any> = new Array<any>();
    if (this.idListaSelecionados) {
      idLista.push(+this.idListaSelecionados);
      this.adicionarSelecionado.emit([idLista]);
      this.modalSelecionado.emit();
    } else {
      this.alertService.showAlertWarning('Você precisa criar uma pasta para este cargo, para então poder adicionar pessoas.');
    }
  }

  cancelar() {
    this.cancelModal.emit();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
