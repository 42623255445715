import { Component, OnInit, Input, Output, Renderer2, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { SetFatores } from './set-fatores';

@Component({
  selector: 'app-set-fatores',
  templateUrl: './set-fatores.component.html',
  styleUrls: ['./set-fatores.component.css'],
})

export class SetFatoresComponent implements OnInit {

  perfilIdeal: string;
  perfilMinimo: string;
  perfilMaximo: string;
  noCheck: boolean;

  @Input() nomeCiencia: string;
  @Input() fatores: SetFatores;

  @Output() setReset = new EventEmitter();
  @Output() selectedFatores = new EventEmitter();
  @Output() confirmaFatores = new EventEmitter();

  @ViewChild('div') div: ElementRef;

  private _reset: boolean;

  constructor(private renderer: Renderer2) {}

  get reset(): boolean {
    // transform value for display
    return this._reset;
  }
  @Input()
  set reset(reset: boolean) {
    // console.log('got reset: ', reset);
    this._reset = reset;

    if (reset === true) {
      this.resetForm();
    } else {
      reset = false;
    }

  }

  ngOnInit() {
    if (this.fatores.nome === null) {
      this.addElement();
      this.noCheck = true;
      this.fatores.selected = true;
    } else { this.noCheck = false; }

    this.perfilIdeal = 'Ideal';
    this.perfilMinimo = 'Mínimo';
    this.perfilMaximo = 'Máximo';
  }

  onChangeFatores(event) {
   this.fatores.selected = event.target.checked;
   this.fatores.nome = event.target.value;
   // this.selectedFatores.emit();
   this.confirmaFatores.emit();
  }
  onChangeMin(event) {
   this.fatores.minimo = event;
   this.confirmaFatores.emit();
  }
  onChangeIdeal(event) {
    this.fatores.ideal = event;
    this.confirmaFatores.emit();
  }
  onChangeMax(event) {
    this.fatores.maximo = event;
    this.confirmaFatores.emit();
  }
  resetForm(): void {
      this.fatores.selected = false;
      this.perfilIdeal = 'Ideal';
      this.perfilMinimo = 'Mínimo';
      this.perfilMaximo = 'Máximo';
      this.updateReset();
  }

  private updateReset() {
    setTimeout(() => {
      this.setReset.emit(false);
    }, 1000);
  }

  private addElement() {
    const E: HTMLParagraphElement = this.renderer.createElement('span');
    E.innerHTML = '<div style="color: #fff">Defina o Padrão para o Mínimo, Ideal e Máximo.</div>';
    this.renderer.appendChild(this.div.nativeElement, E);
  }

}
