import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as ExcelProper from 'exceljs';
import * as fs from 'file-saver';

@Injectable()
export class ExcelExportService {

  constructor() { }

  // ============================================= //
  // Print planilha Auditoria

  exportLogAsExcelFile(dadosPlan, titlePlan, headerPlan) {
    const datepipe: DatePipe = new DatePipe('pt-BR');
    // Excel Title, Header, Data
    const title = titlePlan;
    const header = headerPlan;
    const data = dadosPlan;
    // Create workbook and worksheet
    let workbook: ExcelProper.Workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('Acessos Usuários');

    // Add Row and formatting
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Arial', family: 4, size: 13, bold: true };
    titleRow.alignment = {horizontal: 'center', vertical: 'middle'};
    titleRow.height = 30;
    worksheet.mergeCells(`A${titleRow.number}:G${titleRow.number}`);
    // let subTitleRow = worksheet.addRow(['Date : ' + this.datePipe.transform(new Date(), 'medium')]);
    // Blank Row
    worksheet.addRow([]);
    // Add Header Row
    let headerRow = worksheet.addRow(header);

    // Cell Style : Fill, Border and Alignment
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '91ccd9' },
        bgColor: { argb: '91ccd9' }
      };
      cell.alignment = {horizontal: 'left'};
      // cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      cell.border = { right: { style: 'thin', color: { argb: 'FFFFFF' } } };
    });
    // worksheet.addRows(data);
    // Add Data and Conditional Formatting
    data.forEach(d => {
      let row = worksheet.addRow(d);
      let val = row;
      let color = 'ffffff';
      val.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color }
      };
      val.border = { 
        bottom: { 
          style: 'thin', 
          color: { argb: 'CFCFCF' }
        } 
      };
      
      val.alignment = {horizontal: 'left'};
    }
    );
    worksheet.getColumn(1).width = 35;
    worksheet.getColumn(2).width = 25;
    worksheet.getColumn(3).width = 35;
    worksheet.getColumn(4).width = 25;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 45;
    worksheet.getColumn(7).width = 95;
    // worksheet.addRow([]);
    // Footer Row
    let footerRow = worksheet.addRow(['LUCOR - Tecnologia em Gestão de Pessoas.']);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'cfcfcf' }
    };
    // footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    footerRow.alignment = {horizontal: 'center', vertical: 'middle'};
    footerRow.height = 30;
    // Merge Cells
    worksheet.mergeCells(`A${footerRow.number}:G${footerRow.number}`);
    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      let dataNow = new Date();
      let dataDownload = dataNow.toUTCString();
      dataDownload = datepipe.transform(dataDownload, 'yyyy_MM_dd_HHmmss');
      fs.saveAs(blob, 'Auditoria_Acessos_by_LUCOR_' + dataDownload + '.xlsx');
    });
  }

  // ============================================== //
  // Print planilha do Avaliado

  generateExcel(dadosPlan, titlePlan, headerPlan) {
    // Excel Title, Header, Data
    const title = titlePlan;
    const header = headerPlan;
    const data = dadosPlan;
    // Create workbook and worksheet
    let workbook: ExcelProper.Workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('PERFIL COMPORTAMENTAL');

    // Add Row and formatting
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Arial', family: 4, size: 13, bold: true };
    titleRow.alignment = {horizontal: 'center', vertical: 'middle'};
    worksheet.mergeCells(`A${titleRow.number}:E${titleRow.number}`);
    // let subTitleRow = worksheet.addRow(['Date : ' + this.datePipe.transform(new Date(), 'medium')]);
    // Blank Row
    worksheet.addRow([]);
    // Add Header Row
    let headerRow = worksheet.addRow(header);

    // Cell Style : Fill, Border and Alignment
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '91ccd9' },
        bgColor: { argb: '91ccd9' }
      };
      cell.alignment = {horizontal: 'center'};
      // cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });
    // worksheet.addRows(data);
    // Add Data and Conditional Formatting
    data.forEach(d => {
      let row = worksheet.addRow(d);
      let cie = row.getCell(1);
      let val = row.getCell(2);
      let cla = row.getCell(3);
      let con = row.getCell(4);
      let sin = row.getCell(5);
      let color = 'cfcfcf';
      let colorCie = 'ffffff';
      if (cla.value === 'Muito Baixa') {
        color = 'd43c31';
      }
      if (cla.value === 'Baixa') {
        color = 'ed6d64';
      }
      if (cla.value === 'Mediana') {
        color = 'ffea4d';
      }
      if (cla.value === 'Alta') {
        color = '00E676';
      }
      if (cla.value === 'Muito Alta') {
        color = '27e653';
      }
      if (cla.value === 'Consistente') {
        color = '00E676';
      }
      if (cla.value === 'Inconsistente') {
        color = 'ed6d64';
      }
      if (cie.value === 'COMPETÊNCIAS' ||
      cie.value === 'BALANÇO DIMENSIONAL' ||
      cie.value === 'COMPORTAMENTO' ||
      cie.value === 'MOTIVADORES') {
        colorCie = 'ffffff';
        cie.font = { name: 'Arial', family: 4, size: 12, bold: true };
        cie.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: colorCie } };
        row.alignment = {horizontal: 'center', vertical: 'middle'};
        worksheet.mergeCells(`A${row.number}:E${row.number}`);
        row.height = 24;
      }
      if (cie.value === 'Média das Competências' ||
      cie.value === 'Interno - Consistência' ||
      cie.value === 'Externo - Consistência') {
        colorCie = 'cfcfcf';
        cie.font = { bold: true };
        cie.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: colorCie } };
      }
      val.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color }
      };
      val.alignment = {horizontal: 'center'};
      cla.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color }
      };
      cla.alignment = {horizontal: 'center'};
      con.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color }
      };
      con.alignment = {horizontal: 'center'};
      sin.border = { top: { style: 'thin', color: {argb: 'ffffff'} } };
      sin.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'f2f2f2' }
      };
      sin.alignment = {horizontal: 'center'};
    }
    );
    worksheet.getColumn(1).width = 45;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 15;
    // worksheet.addRow([]);
    // Footer Row
    let footerRow = worksheet.addRow(['LUCOR - Tecnologia em Gestão de Pessoas.']);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'cfcfcf' }
    };
    // footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    footerRow.alignment = {horizontal: 'center', vertical: 'middle'};
    footerRow.height = 40;
    // Merge Cells
    worksheet.mergeCells(`A${footerRow.number}:E${footerRow.number}`);
    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, titlePlan + '_by_LUCOR_' + new Date().getTime() + '.xlsx');
    });
  }

  // ============================================== //
  // Print planilha Detalhes da Avaliação

  generateExcelLista(dadosPlan, titlePlan, headerPlan) {
    // Excel Title, Header, Data
    const title = titlePlan;
    const header = headerPlan;
    const data = dadosPlan;
    // Create workbook and worksheet
    let workbook: ExcelProper.Workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('LISTA DOS AVALIADOS');

    // Add Row and formatting
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Arial', family: 4, size: 13, bold: true };
    titleRow.alignment = {horizontal: 'center', vertical: 'middle'};
    worksheet.mergeCells(`A${titleRow.number}:D${titleRow.number}`);
    // let subTitleRow = worksheet.addRow(['Date : ' + this.datePipe.transform(new Date(), 'medium')]);
    // Blank Row
    worksheet.addRow([]);
    // Add Header Row
    let headerRow = worksheet.addRow(header);

    // Cell Style : Fill, Border and Alignment
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'efefef' },
        bgColor: { argb: 'efefef' }
      };
      cell.alignment = {horizontal: 'center'};
    });
    data.forEach(d => {
      let row = worksheet.addRow(d);
      let ava = row.getCell(1);
      let val = row.getCell(2);
      let cla = row.getCell(3);
      let dat = row.getCell(4);
      
      let color = 'ffffff';

      val.alignment = {horizontal: 'center'};
      cla.alignment = {horizontal: 'center'};
      dat.alignment = {horizontal: 'center'};
      ava.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color }
      };
      val.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color }
      };
      dat.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color }
      };

      if (cla.value === 'Muito Baixa') {
        color = 'd43c31';
      };
      if (cla.value === 'Baixa') {
        color = 'ed6d64';
      };
      if (cla.value === 'Mediana') {
        color = 'ffea4d';
      };
      if (cla.value === 'Alta') {
        color = '00E676';
      };
      if (cla.value === 'Muito Alta') {
        color = '27e653';
      };
      if (cla.value === 'Consistente') {
        color = '00E676';
      };
      if (cla.value === 'Inconsistente') {
        color = 'ed6d64';
      };
      
      cla.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color }
      };
 
    });

    worksheet.getColumn(1).width = 45;
    worksheet.getColumn(2).width = 15;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 25;
    worksheet.spliceColumns(5, 1);
    // worksheet.addRow([]);
    // Footer Row
    let footerRow = worksheet.addRow(['LUCOR - Tecnologia em Gestão de Pessoas.']);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'efefef' }
    };
    // footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    footerRow.alignment = {horizontal: 'center', vertical: 'middle'};
    footerRow.height = 40;
    // Merge Cells
    worksheet.mergeCells(`A${footerRow.number}:D${footerRow.number}`);
    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, titlePlan + '_by_LUCOR_' + new Date().getTime() + '.xlsx');
    });
  }
}
