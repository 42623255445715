import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filterCargo'
})
export class FilterCargoPipe implements PipeTransform {

  transform(items: any[], newSearchText: string): any[] {
    if (!items) { return []; }
    if (!newSearchText) { return items; }

    newSearchText = newSearchText.toLowerCase();
    return items.filter(it => {
      return it.titulo.toLowerCase().includes(newSearchText);
    });
  }

}
