import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { CienciaApiService } from 'app/api/cienciaApi.service';
import { CargoApiService } from 'app/api/cargoApi.service';
import { LucorApiModelsCiencia } from 'app/model/lucorApiModelsCiencia';
import { LucorApiModelsFator } from 'app/model/lucorApiModelsFator';
import { LucorApiModelsNewPerfil } from 'app/model/lucorApiModelsNewPerfil';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-cargos-search-axiologia',
  templateUrl: './cargos-search-axiologia.component.html',
  styleUrls: ['./cargos-search-axiologia.component.css']

})
export class CargosSearchAxiologiaComponent implements OnInit, OnDestroy {

  ciencia: LucorApiModelsCiencia;
  fatores: Array<LucorApiModelsFator>;
  filtroAxiologia: LucorApiModelsNewPerfil[];
  filtroFatores = [];

  perfisSalvosCargo$: Observable<LucorApiModelsNewPerfil[]>;
  perfisSalvos: LucorApiModelsNewPerfil[] = [];
  perfilSalvo: boolean;

  idCiencia = 1; // (1) Axiologia / (2) Axiologia Observado / (3) Motivadores
  nomeCiencia = 'Axiologia';

  order = 'nome';
  reset = false;

  searchText = '';
  selectedCount = 0;

  @Input() idCargo: number;
  @Output() resultadoFiltroAxiologia = new EventEmitter();

  private updateSelectedFatores: LucorApiModelsFator[];

  get selectedFatores(): LucorApiModelsFator[] {
    return this.updateSelectedFatores;
  }

  @Input()
  set selectedFatores(selectedFatores: LucorApiModelsFator[]) {
    this.updateSelectedFatores = selectedFatores;
    if (!selectedFatores) {
      console.error('Nenhum fator foi selecionado!!!');
    }
  }

  private _reloadPerfis: boolean;

  get reloadPerfis(): boolean {
    return this._reloadPerfis;
  }

  @Input()
  set reloadPerfis(reloadPerfis: boolean) {
    this._reloadPerfis = reloadPerfis;
    if (this._reloadPerfis) {
      this.updatePerfisSalvos();
    }
  }

  private subs = new SubSink();

  constructor(
    private cienciaService: CienciaApiService,
    private cargosService: CargoApiService
  ) {}

  ngOnInit() {

    /***
     * 
     * 
    // Método 1
    if (this.idCargo && this.idCiencia) {
      this.perfisSalvosCargo$ = this.cargosService.getPerfisCargo(this.idCargo, this.idCiencia);
      const carregaPerfisSalvos = forkJoin(
      this.perfisSalvosCargo$.forEach(resultado => {
        if (resultado.length > 0) {
          resultado.forEach(perfisSelecao => {
            this.perfisSalvos.push(perfisSelecao);
            this.perfilSalvo = true;
          });
        }
      })
    );
      carregaPerfisSalvos.subscribe(() => {
        if (this.perfilSalvo) {
          this.carregaPerfisSalvos();
        }
      });
    }

    // Método 2
    if (this.idCargo && this.idCiencia) {
      this.perfisSalvosCargo$ = this.cargosService.getPerfisCargo(this.idCargo, this.idCiencia);
      this.perfisSalvosCargo$.forEach(resultado => {
        if (resultado.length > 0) {
          resultado.forEach(perfisSelecao => {
            this.perfisSalvos.push(perfisSelecao);
            if (this.perfisSalvos.length > 0) {
              this.perfilSalvo = true;
              this.carregaPerfisSalvos();

            }
          });
        }
      });
    }
    */

    // Método 3
    if (this.idCargo && this.idCiencia) {
      this.perfisSalvosCargo$ = this.cargosService.getPerfisCargo(this.idCargo, this.idCiencia);
      this.perfisSalvosCargo$.forEach(perfisSalvos => {
        this.perfisSalvos = perfisSalvos;
        if (this.perfisSalvos) {
          this.carregaPerfisSalvos();
        }
      });
    }

    this.getCiencia();
  }

  getCiencia(): void {

    this.subs.sink = this.cienciaService.getCienciaByIdAsync(this.idCiencia)
    .subscribe(
        ciencia => {this.ciencia = ciencia;}, 
        (err) => {
        console.log(err);
      });
  }

  carregaPerfisSalvos() {

    this.perfilSalvo = true;
    this.filtroFatores = [];

    this.perfisSalvos.forEach(perfil => {
      this.filtroFatores.push({
          nome: perfil.fator,
          minimo: perfil.minimo,
          ideal: perfil.ideal,
          maximo: perfil.maximo,
          selected: true 
        });
    });

    this.filtroFatores.sort((a, b) => {
       return a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0;
    });

    if (this.filtroFatores.length > 0) {
      this.fatoresSelecionadosSalvos();
    }

  }

  updatePerfisSalvos() {

    this.perfisSalvos = [];

    this.subs.sink = this.cargosService.getPerfisCargo(this.idCargo, this.idCiencia)
    .subscribe(perfisSalvos => {
      this.perfisSalvos = perfisSalvos;
      if (this.perfisSalvos) {
        this.carregaPerfisSalvos();
      }
      this.reloadPerfis = false;
    });

  }

  fatoresSelecionadosSalvos() {

    this.selectedCount = 0;

    if (this.perfilSalvo) {
        this.selectedFatores = this.filtroFatores
        .filter(fator => {
            return (fator.selected);
        });

    }

    this.selectedCount = this.selectedFatores.length;
    if (this.selectedCount === 0) {
      this.filtroAxiologia = undefined;
      this.resultadoFiltroAxiologia.emit(this.filtroAxiologia);
    } else {
      this.filtroAxiologia = this.selectedFatores;
      this.resultadoFiltroAxiologia.emit(this.filtroAxiologia);
    }
  }

  fatoresSelecionados() {

    this.selectedCount = 0;

    this.selectedFatores = this.ciencia.fatores.filter(fator => {
      return (fator.selected);
    });

    this.selectedCount = this.selectedFatores.length;
    
    if (this.selectedCount === 0) {
      this.filtroAxiologia = undefined;
      this.resultadoFiltroAxiologia.emit(this.filtroAxiologia);
    }

  }

  salvaFiltro() {

    this.filtroFatores = [];
    this.filtroFatores = this.ciencia.fatores.filter(v => v.nome !== null && v.selected === true)
      .map((v, i) => v ? v.nome : null);

    this.filtroFatores.sort((a, b) => {
      return a < b ? -1 : a > b ? 1 : 0;
    });

    const perfis: Array<any> = new Array<any>();

    this.ciencia.fatores.filter(v => v.selected === true)
        .map((v, i) => {
          if (v) {
            perfis.push({fator: v.nome, minimo: v.minimo, ideal: v.ideal, maximo: v.maximo});
          }
        });

    if (this.filtroFatores.length > 0) {
      perfis.push({fator: null, minimo: 60, ideal: 80, maximo: 100});
    }

    perfis.sort((a, b) => {
      return a.fator < b.fator ? -1 : a.fator > b.fator ? 1 : 0;
    });

    this.filtroAxiologia = perfis;
    this.resultadoFiltroAxiologia.emit(this.filtroAxiologia); // Envia o resultado do Filtro via Output

  }

  deleteFator(id: number, nome?: string) {
    if (!id) {
      this.selectedFatores = this.ciencia.fatores.filter(fator => {
        if (fator.nome === nome) {
          fator.selected = false;
        }
      });
      this.fatoresSelecionados();
      this.salvaFiltro();
    } else {

    if (this.selectedFatores?.length > 0) {
      this.selectedFatores = this.selectedFatores.filter(f => {
          if (f.id === id) {
              f.selected = false;
      }
          return true;
      });
      this.fatoresSelecionados();
      this.salvaFiltro();
    }

  }
}

  resetForm() {
    this.reset = true;
    this.resultadoFiltroAxiologia.emit();
    this.ciencia.fatores.map(f => {
        this.deleteFator(f.id);
    });
  }

  recebeSetReset($event) {
    this.reset = $event;
  }

/*
* Campo de pesquisa por nome do fator
*/
  setSearchTerm(term: string) {
    this.searchText = term;
  }

  getSearchTerm() {
      return this.searchText;
  }

  clearFilter() {
      this.searchText = '';
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
