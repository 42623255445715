/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.110
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type TipoMensagem = 'Inconsistencia' | 'EsqueciASenha';

export const TipoMensagem = {
    Inconsistencia: 'Inconsistencia' as TipoMensagem,
    EsqueciASenha: 'EsqueciASenha' as TipoMensagem
};