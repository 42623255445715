import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderModule } from 'ngx-order-pipe';
import { SharedModule } from 'app/shared/shared.module';
import { UsuariosRoutingModule } from './usuarios.routing.module';
import { UsuariosComponent } from './components/usuarios/usuarios.component';
import { UsuariosDetalhesComponent } from './components/usuarios-detalhes/usuarios-detalhes.component';
import { UsuariosEditarComponent } from './components/usuarios-editar/usuarios-editar.component';
import { UsuariosCadastrarComponent } from './components/usuarios-cadastrar/usuarios-cadastrar.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { DataTablesModule } from 'angular-datatables';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        SharedModule,
        OrderModule,
        NgxPaginationModule,
        UsuariosRoutingModule,
        DataTablesModule
    ],
    declarations: [
        UsuariosComponent,
        UsuariosDetalhesComponent,
        UsuariosEditarComponent,
        UsuariosCadastrarComponent,
        ResetPasswordComponent
    ],
    providers: [
        SharedModule,
        OrderModule
      ],
  })

  export class UsuariosModule {}

