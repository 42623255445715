import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Location, DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ServicoApiService } from 'app/api/servicoApi.service';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { LucorApiModelsServico } from 'app/model/lucorApiModelsServico';
import { SubSink } from 'subsink';
import { share } from 'rxjs/operators';

@Component({
  selector: 'app-servicos-editar',
  templateUrl: './servicos-editar.component.html',
  styleUrls: ['./servicos-editar.component.css']
})

export class ServicosEditarComponent implements OnInit, OnDestroy {

  servico$: Observable<LucorApiModelsServico>;
  servico: LucorApiModelsServico;
  idCliente: number;
  idServico: number;

  addForm: FormGroup;
  codigo: string | number;
  codigoTipoServico: string;
  unidadesContratadas: number;
  unidadesUtilizadas: number;
  unidadesRestantes: number;
  dataOrcamento: Date | string;
  dataExecucao: Date | string;
  valorOrcado;
  valorFaturado;
  nomeTipoServico: string;

  addFormEnvia: FormGroup;

  pipeData = new DatePipe('pt-BR'); // Use your own locale

  private subs = new SubSink();

  constructor(
    private servicosService: ServicoApiService,
    private alertService: AlertModalService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private location: Location) { }

  ngOnInit() {
    this.idServico = +this.route.snapshot.paramMap.get('Id');
    this.getServico();
    this.addForm = this.formBuilder.group({
      codigo: [''],
      codigoTipoServico: [''],
      unidadesContratadas: [''],
      unidadesUtilizadas: [''],
      unidadesRestantes: [''],
      dataOrcamento: [''],
      dataExecucao: [''],
      valorOrcado: [''],
      valorFaturado: ['']
    });
  }

  getServico() {
    this.servico$ = this.servicosService.getServicoByIdAsync(this.idServico).pipe(share());
    this.servico$.forEach(servico => {
      this.servico = servico;
      // Define as demais variáveis para o form
      this.idCliente = this.servico.clienteId;
      this.codigo = this.servico.codigo;
      this.codigoTipoServico = this.servico.tipoServico.codigo;
      this.unidadesContratadas = this.servico.unidadesContratadas;
      this.unidadesUtilizadas = this.servico.unidadesUtilizadas;
      this.unidadesRestantes = this.servico.unidadesRestantes;
      this.dataOrcamento = this.servico.dataOrcamento;
      this.dataOrcamento = this.pipeData.transform(this.dataOrcamento, 'dd/MM/yyyy');
      this.dataExecucao = this.servico.dataExecucao;
      this.dataExecucao = this.pipeData.transform(this.dataExecucao, 'dd/MM/yyyy');
      this.valorOrcado = this.servico.valorOrcado;
      this.valorFaturado = this.servico.valorFaturado;
      this.nomeTipoServico = this.servico.tipoServico.nome;
      this.setForm();
    });
  }

  setForm() {
    this.addForm = this.formBuilder.group({
      codigo: [this.codigo],
      codigoTipoServico: [this.codigoTipoServico],
      unidadesContratadas: [this.unidadesContratadas],
      unidadesUtilizadas: [this.unidadesUtilizadas],
      unidadesRestantes: [this.unidadesRestantes],
      dataOrcamento: [this.dataOrcamento],
      dataExecucao: [this.dataExecucao],
      valorOrcado: [this.valorOrcado],
      valorFaturado: [this.valorFaturado]
    });
  }

  atualizaServico(): void {

    if (this.dataOrcamento === null || this.dataOrcamento === "") {
      this.dataOrcamento = this.dataOrcamento = null;
    } else {
      this.dataOrcamento = new Date(this.dataOrcamento).toISOString();
    }
    if (this.dataExecucao === null || this.dataExecucao === "") {
      this.dataExecucao = this.dataExecucao = null;
    } else {
      this.dataExecucao = new Date(this.dataExecucao).toISOString();
    }

    this.addForm = this.formBuilder.group({
      codigo: [this.codigo],
      codigoTipoServico: [this.codigoTipoServico],
      unidadesContratadas: [this.unidadesContratadas],
      unidadesUtilizadas: [this.unidadesUtilizadas],
      unidadesRestantes: [this.unidadesRestantes],
      dataOrcamento: [this.dataOrcamento],
      dataExecucao: [this.dataExecucao],
      valorOrcado: [this.valorOrcado],
      valorFaturado: [this.valorFaturado]
    });
    // console.log(this.addForm.value);
    this.saveServico();
  }

  private saveServico() {
    this.subs.sink = this.servicosService.updateServicoAsync(this.idServico, this.idCliente, this.addForm.value)
    .subscribe(success => {
      this.alertService.showAlertSuccess('Serviço atualizado com sucesso!');
      this.location.back();
    }, error => {
      this.alertService.showAlertDanger('Erro ao atualizar o serviço. Tente novamente mais tarde.');
    });

  }

  goBack(): void {
    this.location.back();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
