/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.52
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { LucorApiModelsDetailsAvaliadoFavorito } from '../model/lucorApiModelsDetailsAvaliadoFavorito';
import { LucorApiModelsDetailsCargoAvaliadoFavorito } from '../model/lucorApiModelsDetailsCargoAvaliadoFavorito';
import { LucorApiModelsDetailsListagemFavoritos } from '../model/lucorApiModelsDetailsListagemFavoritos';
import { LucorApiModelsNewNewListagemFavoritos } from '../model/lucorApiModelsNewNewListagemFavoritos';
import { LucorApiModelsUpdUpdListagemFavoritos } from '../model/lucorApiModelsUpdUpdListagemFavoritos';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { environment } from 'environments/environment';


@Injectable()
export class FavoritosApiService {

  protected basePath = environment.apiBasePath;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Adicionar Avaliado à Listagem de Favoritos
     *
     * @param listagemId
     * @param avaliadoId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addAvaliadoListagemAsync(listagemId: number, avaliadoId: number, observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsDetailsAvaliadoFavorito>;
    public addAvaliadoListagemAsync(listagemId: number, avaliadoId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsDetailsAvaliadoFavorito>>;
    public addAvaliadoListagemAsync(listagemId: number, avaliadoId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsDetailsAvaliadoFavorito>>;
    public addAvaliadoListagemAsync(listagemId: number, avaliadoId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (listagemId === null || listagemId === undefined) {
            throw new Error('Required parameter listagemId was null or undefined when calling addAvaliadoListagemAsync.');
        }

        if (avaliadoId === null || avaliadoId === undefined) {
            throw new Error('Required parameter avaliadoId was null or undefined when calling addAvaliadoListagemAsync.');
        }

        let headers = this.defaultHeaders;


        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<LucorApiModelsDetailsAvaliadoFavorito>(`${this.basePath}/favoritos/listagens/${encodeURIComponent(String(listagemId))}/avaliados/${encodeURIComponent(String(avaliadoId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adicionar Cargo ao Avaliado da Listagem de Favoritos
     *
     * @param avaliadoListagemId
     * @param cargoId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addCargoAvaliadoListagemAsync(avaliadoListagemId: number, cargoId: number, observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsDetailsCargoAvaliadoFavorito>;
    public addCargoAvaliadoListagemAsync(avaliadoListagemId: number, cargoId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsDetailsCargoAvaliadoFavorito>>;
    public addCargoAvaliadoListagemAsync(avaliadoListagemId: number, cargoId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsDetailsCargoAvaliadoFavorito>>;
    public addCargoAvaliadoListagemAsync(avaliadoListagemId: number, cargoId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (avaliadoListagemId === null || avaliadoListagemId === undefined) {
            throw new Error('Required parameter avaliadoListagemId was null or undefined when calling addCargoAvaliadoListagemAsync.');
        }

        if (cargoId === null || cargoId === undefined) {
            throw new Error('Required parameter cargoId was null or undefined when calling addCargoAvaliadoListagemAsync.');
        }

        let headers = this.defaultHeaders;


        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<LucorApiModelsDetailsCargoAvaliadoFavorito>(`${this.basePath}/favoritos/listagens/avaliados/${encodeURIComponent(String(avaliadoListagemId))}/cargos/${encodeURIComponent(String(cargoId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Incluir Nova Listagem de Favoritos
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addListagemAsync(body?: LucorApiModelsNewNewListagemFavoritos, observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsDetailsListagemFavoritos>;
    public addListagemAsync(body?: LucorApiModelsNewNewListagemFavoritos, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsDetailsListagemFavoritos>>;
    public addListagemAsync(body?: LucorApiModelsNewNewListagemFavoritos, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsDetailsListagemFavoritos>>;
    public addListagemAsync(body?: LucorApiModelsNewNewListagemFavoritos, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;


        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LucorApiModelsDetailsListagemFavoritos>(`${this.basePath}/favoritos/listagens`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Atualizar Listagem de Favoritos
     *
     * @param listagemId
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public atualizarListagemAsync(listagemId: number, body?: LucorApiModelsUpdUpdListagemFavoritos, observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsDetailsListagemFavoritos>;
    public atualizarListagemAsync(listagemId: number, body?: LucorApiModelsUpdUpdListagemFavoritos, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsDetailsListagemFavoritos>>;
    public atualizarListagemAsync(listagemId: number, body?: LucorApiModelsUpdUpdListagemFavoritos, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsDetailsListagemFavoritos>>;
    public atualizarListagemAsync(listagemId: number, body?: LucorApiModelsUpdUpdListagemFavoritos, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (listagemId === null || listagemId === undefined) {
            throw new Error('Required parameter listagemId was null or undefined when calling atualizarCargoAsync.');
        }


        let headers = this.defaultHeaders;


        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<LucorApiModelsDetailsListagemFavoritos>(`${this.basePath}/favoritos/listagens/${encodeURIComponent(String(listagemId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remover Avaliado da Listagem de Favoritos
     *
     * @param listagemId
     * @param avaliadoId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAvaliadoListagemAsync(listagemId: number, avaliadoId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteAvaliadoListagemAsync(listagemId: number, avaliadoId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteAvaliadoListagemAsync(listagemId: number, avaliadoId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteAvaliadoListagemAsync(listagemId: number, avaliadoId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (listagemId === null || listagemId === undefined) {
            throw new Error('Required parameter listagemId was null or undefined when calling deleteAvaliadoListagemAsync.');
        }

        if (avaliadoId === null || avaliadoId === undefined) {
            throw new Error('Required parameter avaliadoId was null or undefined when calling deleteAvaliadoListagemAsync.');
        }

        let headers = this.defaultHeaders;


        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/favoritos/listagens/${encodeURIComponent(String(listagemId))}/avaliados/${encodeURIComponent(String(avaliadoId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remover Cargo do Avaliado da Listagem de Favoritos
     *
     * @param avaliadoListagemId
     * @param cargoId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCargoAvaliadoListagemAsync(avaliadoListagemId: number, cargoId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteCargoAvaliadoListagemAsync(avaliadoListagemId: number, cargoId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteCargoAvaliadoListagemAsync(avaliadoListagemId: number, cargoId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteCargoAvaliadoListagemAsync(avaliadoListagemId: number, cargoId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (avaliadoListagemId === null || avaliadoListagemId === undefined) {
            throw new Error('Required parameter avaliadoListagemId was null or undefined when calling deleteCargoAvaliadoListagemAsync.');
        }

        if (cargoId === null || cargoId === undefined) {
            throw new Error('Required parameter cargoId was null or undefined when calling deleteCargoAvaliadoListagemAsync.');
        }

        let headers = this.defaultHeaders;


        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/favoritos/listagens/avaliados/${encodeURIComponent(String(avaliadoListagemId))}/cargos/${encodeURIComponent(String(cargoId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Excluir Listagem de Favoritos
     *
     * @param listagemId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteListagemAsync(listagemId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteListagemAsync(listagemId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteListagemAsync(listagemId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteListagemAsync(listagemId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (listagemId === null || listagemId === undefined) {
            throw new Error('Required parameter listagemId was null or undefined when calling deleteListagemAsync.');
        }

        let headers = this.defaultHeaders;


        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/favoritos/listagens/${encodeURIComponent(String(listagemId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Obter Listagem de Favoritos pelo Id
     *
     * @param listagemId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListagemByIdAsync(listagemId: number, observe?: 'body', reportProgress?: boolean): Observable<LucorApiModelsDetailsListagemFavoritos>;
    public getListagemByIdAsync(listagemId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LucorApiModelsDetailsListagemFavoritos>>;
    public getListagemByIdAsync(listagemId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LucorApiModelsDetailsListagemFavoritos>>;
    public getListagemByIdAsync(listagemId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (listagemId === null || listagemId === undefined) {
            throw new Error('Required parameter listagemId was null or undefined when calling getCargoByIdAsync.');
        }

        let headers = this.defaultHeaders;


        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<LucorApiModelsDetailsListagemFavoritos>(`${this.basePath}/favoritos/listagens/${encodeURIComponent(String(listagemId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Listar Listagens de Favoritos
     *
     * @param withoutChildren
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListagemAsync(withoutChildren?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<LucorApiModelsDetailsListagemFavoritos>>;
    public getListagemAsync(withoutChildren?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LucorApiModelsDetailsListagemFavoritos>>>;
    public getListagemAsync(withoutChildren?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LucorApiModelsDetailsListagemFavoritos>>>;
    public getListagemAsync(withoutChildren?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (withoutChildren !== undefined && withoutChildren !== null) {
            queryParameters = queryParameters.set('withoutChildren', <any>withoutChildren);
        }

        let headers = this.defaultHeaders;


        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<LucorApiModelsDetailsListagemFavoritos>>(`${this.basePath}/favoritos/listagens`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Listar Avaliados da Listagem de Favoritos
     *
     * @param listagemId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listarAvaliadosListagemAsync(listagemId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<LucorApiModelsDetailsAvaliadoFavorito>>;
    public listarAvaliadosListagemAsync(listagemId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LucorApiModelsDetailsAvaliadoFavorito>>>;
    public listarAvaliadosListagemAsync(listagemId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LucorApiModelsDetailsAvaliadoFavorito>>>;
    public listarAvaliadosListagemAsync(listagemId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (listagemId === null || listagemId === undefined) {
            throw new Error('Required parameter listagemId was null or undefined when calling listarAvaliadosListagemAsync.');
        }

        let headers = this.defaultHeaders;


        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<LucorApiModelsDetailsAvaliadoFavorito>>(`${this.basePath}/favoritos/listagens/${encodeURIComponent(String(listagemId))}/avaliados`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Listar Cargos do Avaliado da Listagem de Favoritos
     *
     * @param avaliadoListagemId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listarCargosAvaliadoListagemAsync(avaliadoListagemId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<LucorApiModelsDetailsCargoAvaliadoFavorito>>;
    public listarCargosAvaliadoListagemAsync(avaliadoListagemId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LucorApiModelsDetailsCargoAvaliadoFavorito>>>;
    public listarCargosAvaliadoListagemAsync(avaliadoListagemId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LucorApiModelsDetailsCargoAvaliadoFavorito>>>;
    public listarCargosAvaliadoListagemAsync(avaliadoListagemId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (avaliadoListagemId === null || avaliadoListagemId === undefined) {
            throw new Error('Required parameter avaliadoListagemId was null or undefined when calling listarCargosAvaliadoListagemAsync.');
        }

        let headers = this.defaultHeaders;


        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<LucorApiModelsDetailsCargoAvaliadoFavorito>>(`${this.basePath}/favoritos/listagens/avaliados/${encodeURIComponent(String(avaliadoListagemId))}/cargos`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
