import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EMPTY, Subject, Observable } from 'rxjs';
import { take, switchMap } from 'rxjs/operators';
import { LucorApiModelsFator } from 'app/model/lucorApiModelsFator';
import { LucorApiModelsNewPerfil } from 'app/model/lucorApiModelsNewPerfil';
import { LucorApiModelsNewFiltroCiencia } from 'app/model/lucorApiModelsNewFiltroCiencia';
import { LucorApiModelsCiencia } from 'app/model/lucorApiModelsCiencia';
import { CienciaApiService } from 'app/api/cienciaApi.service';
import { AvaliacaoApiService } from 'app/api/avaliacaoApi.service';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-avaliacoes-search-comportamento',
  templateUrl: './avaliacoes-search-comportamento.component.html',
  styleUrls: ['./avaliacoes-search-comportamento.component.css']

})
export class AvaliacoesSearchComportamentoComponent implements OnInit, OnDestroy {

  ciencia: LucorApiModelsCiencia;
  ciencias: LucorApiModelsCiencia[];
  perfisAvaliacaoCiencia: Array<LucorApiModelsNewPerfil>;
  perfisCargo: Array<LucorApiModelsNewPerfil>;
  fatores: Array<LucorApiModelsFator>;
  filtroComportamento: LucorApiModelsNewFiltroCiencia;
  salvaSelecao = false;
  filtroFatores = []; // Usado na função salvaFiltro
  perfis: Array<any> = new Array<any>();

  idCliente: number;
  idCiencia = 2; // (1) Axiologia / (2) Comportamento Observado / (3) Motivadores
  nomeCiencia = 'Comportamento Observado';
  perfilSalvo = false;

  order = 'nomeFator';
  reverse = false;
  reset = false;

  searchText = '';
  selectedCount = 0;
  selectedFatores: Array<any> = new Array<any>();

  @Input() idAvaliacao: number; // recebe o valor do avaliacoes-filtro.component, que recebe do avalicoes.component
  @Input() perfilCargo$: Observable<LucorApiModelsNewPerfil[]>
  @Output() resetCargo = new EventEmitter();
  @Output() resultadoFiltroComportamento = new EventEmitter();
  @Output() goResultados = new EventEmitter();


  fatoresSort = [];

  private updateCargoId: number;
  get cargoId(): number {
    return this.updateCargoId;
  }
  @Input ()
  set cargoId(cargoId: number) {
    this.updateCargoId = cargoId;
    if (cargoId) {
      this.selectCargo = true;
      this.getPerfisCargo();
    }
  }

  private updateSelectCargo: boolean;
  get selectCargo(): boolean {
    return this.updateSelectCargo;
  }
  @Input()
  set selectCargo(selectCargo: boolean) {
    this.updateSelectCargo = selectCargo;
  }

  private subs = new SubSink();
  
  constructor(
    private cienciaService: CienciaApiService,
    private avaliacoesService: AvaliacaoApiService,
    private alertService: AlertModalService,
    private route: ActivatedRoute
    ) {
      this.idCliente = +this.route.snapshot.paramMap.get('IdCl');
     }

  ngOnInit() {
    this.getCiencia();
    this.getPerfisAvaliacao();
  }

  getCiencia(): void {
    this.subs.sink = this.cienciaService.getCienciaByIdAsync(this.idCiencia)
    .subscribe(
        ciencia => {
        this.ciencia = ciencia;
        this.cienciaSort(ciencia.fatores);
      }, (err) => {
        console.log(err);
      });

  }

  cienciaSort(fatores) {
    this.fatoresSort = fatores.sort((a, b) => a.id - b.id);
  }

  getPerfisAvaliacao() {
    this.subs.sink = this.avaliacoesService.getPerfisAvaliacaoCiencia(this.idAvaliacao, this.idCiencia)
    .subscribe(
      perfisAvaliacaoCiencia => {
        this.perfisAvaliacaoCiencia = perfisAvaliacaoCiencia;
      }, (err) => {
        console.log(err);
      });
  }

  getPerfisCargo() {

    if (this.perfilCargo$) {

      this.subs.sink = this.perfilCargo$
      .subscribe(
        perfisCargo => {
          if (perfisCargo.length > 1) {
            this.perfisCargo = perfisCargo;
            this.perfilSalvo = false;
            this.salvaFiltro();
            setTimeout(() => {
              this.resetCargo.emit();
            }, 2000);
          } else {
            setTimeout(() => { 
              this.resetForm('Comportamento Observado');
            }, 1000);
          }
        }, (err) => {
          console.log(err);
        });

    } 

  }

  carregaPerfilSalvo() {
    this.selectCargo = false;
    this.salvaSelecao = false;
    this.perfilSalvo = true;
    setTimeout(() => {
      this.salvaFiltro();
    }, 100);
  }

  salvaFiltroSelecao() { // Salvar Perfil Configurado para Ciência & Avaliação
    this.perfilSalvo = false;
    this.salvaSelecao = true;

    if (this.salvaSelecao === true) {
      let result$: Subject<boolean>;
      if (this.perfisAvaliacaoCiencia.length > 0 && this.perfis.length > 0) {
        result$ = this.alertService.showConfirm('Atenção!', 'Essa seleção ficará salva temporariamente como última seleção utilizada. A seleção salva anterior a esta será substituída.');
      } else if (this.perfis.length === 0) {
        result$ = this.alertService.showConfirm('Atenção!', 'Você NÃO selecionou nenhuma competência. Isso irá apagar a última seleção salva.');
      } else {
        result$ = this.alertService.showConfirm('Atenção!', 'Essa seleção ficará salva temporariamente como última seleção utilizada nesta ciência.');
      }

      result$.asObservable()
      .pipe(
        take(1),
        switchMap(result => result ? this.avaliacoesService.updatePerfisCienciaAvaliacao(this.idAvaliacao, this.idCiencia, this.perfis) : EMPTY)
      )
      .subscribe(
        success => {
          if (this.perfis.length === 0) {
            this.alertService.showAlertWarning('A última seleção foi apagada.', 3000);
          } else {
            this.alertService.showAlertSuccess('Seleção salva com sucesso!');
          }
          this.salvaSelecao = false;
          setTimeout(() => {
            this.getPerfisAvaliacao();
            this.carregaPerfilSalvo();
          }, 1000);
        },
        error => {
          this.alertService.showAlertDanger('Erro ao salvar seleção. Tente novamente mais tarde.');
        }
      );
      this.salvaSelecao = false;
    }

  }

  salvaFiltro() {

    this.filtroFatores = [];
    this.perfis = [];

    if (this.perfisAvaliacaoCiencia && this.perfisAvaliacaoCiencia.length > 0 && this.perfilSalvo) { // Perfil cadastrado para Ciência & Cliente
      this.filtroFatores = this.perfisAvaliacaoCiencia
      .filter(v => v.fator !== null && v.selected === true)
      .map((v, i) => v ? v.fator : null);

    } else if (this.perfisCargo && this.perfisCargo.length > 0 && this.selectCargo) {
      this.filtroFatores = this.perfisCargo
      .filter(v => v.fator !== null)
      .map((v, i) => v ? v.fator : null);
    } else {
      this.filtroFatores = this.ciencia.fatores
      .filter(v => v.nome !== null && v.selected === true)
      .map((v, i) => v ? v.nome : null);
    }

    this.filtroFatores.sort((a, b) => {
      return a < b ? -1 : a > b ? 1 : 0;
    });

    // let perfis: Array<any> = new Array<any>();

    if (this.perfisAvaliacaoCiencia.length > 0  && this.perfilSalvo) {
      this.perfis = this.perfisAvaliacaoCiencia
        .filter(v => v.selected === true)
        .map((v, i) => {
         return (v);
        });
    } else if (this.perfisCargo && this.perfisCargo.length > 0  && this.selectCargo) {
        this.perfis = this.perfisCargo
          .filter(v => v)
          .map((v, i) => {
          return (v);
        });
    } else {
      this.ciencia.fatores
        .filter(v => v.selected === true)
        .map((v, i) => {
          if (v) {
            this.perfis.push({fator: v.nome, minimo: v.minimo, ideal: v.ideal, maximo: v.maximo});
          }
        });
      if (this.filtroFatores.length > 0) {
        this.perfis.push({fator: null, minimo: '60', ideal: '80', maximo: '100'});
      }
    }

    this.perfis.sort((a, b) => {
      return a.fator < b.fator ? -1 : a.fator > b.fator ? 1 : 0;
    });

    // -----------------------

    this.filtroComportamento = {
      nomeCiencia: this.nomeCiencia,
      filtroFatores: this.filtroFatores,
      perfis: this.perfis
    };

    if (this.filtroFatores.length > 0) {
      this.resultadoFiltroComportamento.emit(this.filtroComportamento); // Envia o resultado do Filtro via Output
    } else {
      this.selectCargo = false;
    }

    this.fatoresSelecionados();

  }

  fatoresSelecionados() {

    this.selectedCount = 0;

    if (this.perfisAvaliacaoCiencia.length > 0  && this.perfilSalvo) {
      this.selectedFatores = this.perfisAvaliacaoCiencia
        .filter(f => {
            if (f.selected === true && f.fator !== null) {
                this.selectedCount++;
                return (f.selected);
            }
        });
    } else if (this.perfisCargo && this.perfisCargo.length > 0  && this.selectCargo) {
      this.perfis
        .filter(f => {
            if (f.fator !== null) {
                this.selectedCount++;
            }
        });
    } else {
      this.selectedFatores = this.ciencia.fatores
        .filter(f => {
            if (f.selected === true) {
                this.selectedCount++;
            }
            return (f.selected);
        });
    }

    if (this.selectedCount === 0) {
      this.filtroComportamento = undefined;
      this.resultadoFiltroComportamento.emit(this.filtroComportamento);
    }

  }

  deleteFator(fator) {
    this.searchText = '';
    if (this.selectedFatores && this.selectedFatores.length > 0) {
      this.selectedFatores = this.selectedFatores
        .filter(f => {
            if (fator.nome && f.nome === fator.nome) {
              f.selected = false;
            }
            if (fator.fator && f.fator === fator.fator) {
              f.selected = false;
            }
            if (fator.id && f.id === fator.id) {
              f.selected = false;
            }
            return true;
        });
      // this.fatoresSelecionados();
      this.salvaFiltro();
    }
  }

  mostraResultados() {
    if (this.filtroFatores.length > 0) {
      this.goResultados.emit(true);
    } else {
      this.alertService.showAlertWarning('Você precisa selecionar ao menos uma competência.');
    }
  }

  scrollTopFiltro() {
    window.scroll({ top: 160, behavior: 'smooth' });
  }

  resetForm(event) {
    this.reset = true;
    this.perfilSalvo = false;
    this.selectCargo = false;
    this.goResultados.emit(event);
    this.resultadoFiltroComportamento.emit();
    this.ciencia.fatores.map(f => {
      this.deleteFator(f);
    });
    this.filtroFatores = [];
    this.selectedFatores = [];
    this.selectedCount = 0;
    this.perfisCargo = [];
  }

  recebeSetReset($event) {
    this.reset = $event;
  }

  /*
  * Campo de pesquisa por nome do fator
  */
  setSearchTerm(term: string) {
    this.searchText = term;
  }

  getSearchTerm() {
      return this.searchText;
  }

  clearFilter() {
      this.searchText = '';
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
