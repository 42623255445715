/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.45
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 *
 */
export interface LucorApiModelsValorFator {
  /**
   * Gets or Sets FatorId
   */
  fatorId: number;
  /**
   * Gets or Sets NomeFator
   */
  nomeFator?: string;
  /**
   * Gets or Sets Valor
   */
  valor?: string;
  /**
   * Gets or Sets Sinal
   */
  sinal?: string;
  /**
   * Gets or Sets GrupoClassificacao
   */
  grupoClassificacao?: string;
  /**
   * Gets or Sets Classificacao
   */
  classificacao?: string;
  /**
   * Gets or Sets CorClassificacao
   */
  corClassificacao?: string;
  /**
   * Gets or Sets GrupoClassificacao2
   */
  grupoClassificacao2?: string;
  /**
   * Gets or Sets Classificacao2
   */
  classificacao2?: string;
  /**
   * Gets or Sets CorClassificacao2
   */
  corClassificacao2?: string;
  /**
   * Gets or Sets Id
   */
  id?: number;
  /**
   * Gets or Sets CreatedAt
   */
  createdAt?: Date;
  /**
   * Gets or Sets UpdatedAt
   */
  updatedAt?: Date;
}
