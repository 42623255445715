import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Observable, EMPTY } from 'rxjs';
import { share, switchMap, take } from 'rxjs/operators';
import { CookieService } from '@ngx-toolkit/cookie';
import Driver from 'driver.js';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { AvaliacaoApiService } from 'app/api/avaliacaoApi.service';
import { ExcelExportService } from 'app/shared/xlsx-download/excel-export.service';
import { LucorApiModelsAvaliado, LucorApiModelsAvaliacao } from 'app/model/models';
import * as moment from 'moment-timezone';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-avaliacoes-avaliados',
  templateUrl: './avaliacoes-avaliados.component.html',
  styleUrls: ['./avaliacoes-avaliados.component.css']
})

export class AvaliacoesAvaliadosComponent implements OnInit, OnDestroy {

  avaliacaoDetalhes$: Observable<LucorApiModelsAvaliado[]>;
  idAvaliacao: number;
  idAvaliado: number;
  avaliado: LucorApiModelsAvaliado;
  userRole: string;
  clickAnexoDel = null;
  tempoResposta: number;

  padraoInterno = [];
  sinaisTendenciaInterna = [];
  tendenciaInterna = [];
  padraoExterno = [];
  sinaisTendenciaExterna = [];
  tendenciaExterna = [];
  conceitoMotivadores = [];

  // printDados$: Observable<LucorApiModelsAvaliacao>;
  setPrintDados = [];
  setPrintNomeAvaliado: string;
  headerDados = [ 'Fator', 'Valor', 'Classificação', 'Conceito', 'Sinais' ];
  printWait = false;

  order = 'valor';
  reverse = true;
  searchText = '';

  checkCookie = false;
  disableTour = false;
  driver = new Driver({
    className: 'tour-lucor',
    doneBtnText: 'Entendi', // Text on the final button
    closeBtnText: 'Fechar', // Text on the close button for this step
    nextBtnText: 'Próximo', // Next button text for this step
    prevBtnText: 'Anterior', // Previous button text for this step
  });

  // Variáveis para criação do gráfico DISC
  avaliacoes: LucorApiModelsAvaliacao[];
  avaliados = new Array<any>();

  valorDomNatural: any | null;
  valorDomAdaptavel: any | null;
  valorInfNatural: any | null;
  valorInfAdaptavel: any | null;
  valorEstNatural: any | null;
  valorEstAdaptavel: any | null;
  valorCauNatural: any | null;
  valorCauAdaptavel: any | null;

  valorAltruista: any | null;
  valorEconomico: any | null;
  valorEstetica: any | null;
  valorIndividualista: any | null;
  valorPolitico: any | null;
  valorRegulador: any | null;
  valorTeorico: any | null;

  fatoresAxiAltaCount;
  fatoresAxiMedianaCount;
  fatoresAxiBaixaCount;


  /*
  * Gráficos
  */

 firstChartDataCom: Array<any> = new Array<any>();
 secondChartDataCom: Array<any> = new Array<any>();

 firstChartDataMot: Array<any> = new Array<any>();
 secondChartDataMot: Array<any> = new Array<any>();

 firstChartDataAxi: Array<any> = new Array<any>();
 firstChartLabelsAxi: Array<any> = new Array<any>();

 countChartLabelsAxiAlta: Array<any> = new Array<any>();
 countChartLabelsAxiMediana: Array<any> = new Array<any>();
 countChartLabelsAxiBaixa: Array<any> = new Array<any>();


 /*
* Gráficos Bar
*/


// Gráfico Disc

toggleDom = false;
toggleDomADP = false;
toggleInf = false;
toggleInfADP = false;
toggleEst = false;
toggleEstADP = false;
toggleCau = false;
toggleCauADP = false;

private colorDominancia: string = 'rgba(244, 67, 54, 1)';
private colorDominanciaADP: string = 'rgba(244, 67, 54, 0.5)';
private colorInfluencia: string = 'rgba(255, 235, 59, 1)';
private colorInfluenciaADP: string = 'rgba(255, 235, 59, 0.5)';
private colorEstabilidade: string = 'rgba(0, 200, 83, 1)';
private colorEstabilidadeADP: string = 'rgba(0, 200, 83, 0.5)';
private colorCautela: string = 'rgba(3, 169, 244, 1)';
private colorCautelaADP: string = 'rgba(3, 169, 244, 0.5)';

get valueDom(): string {
    if (!this.colorDominancia) {
        return this.colorDominancia.substring(0, this.colorDominancia.length - 2);
    } else {
        return this.colorDominancia;
    }
}

@Input('valueDom')
set valueDom(value: string) {
    this.colorDominancia = value;
    this.updateColorsDisc();
}

/* */

get valueDomADP(): string {
  if (!this.colorDominanciaADP) {
      return this.colorDominanciaADP.substring(0, this.colorDominanciaADP.length - 2);
  } else {
      return this.colorDominanciaADP;
  }
}

@Input('valueDomADP')
set valueDomADP(value: string) {
  this.colorDominanciaADP = value;
  this.updateColorsDisc();
}


/* */

get valueInf(): string {
  if (!this.colorInfluencia) {
      return this.colorInfluencia.substring(0, this.colorInfluencia.length - 2);
  } else {
      return this.colorInfluencia;
  }
}

@Input('valueInf')
set valueInf(value: string) {
  this.colorInfluencia = value;
  this.updateColorsDisc();
}

/* */

get valueInfADP(): string {
  if (!this.colorInfluenciaADP) {
      return this.colorInfluenciaADP.substring(0, this.colorInfluenciaADP.length - 2);
  } else {
      return this.colorInfluenciaADP;
  }
}

@Input('valueInfADP')
set valueInfADP(value: string) {
  this.colorInfluenciaADP = value;
  this.updateColorsDisc();
}

/* */

get valueEst(): string {
  if (!this.colorEstabilidade) {
      return this.colorEstabilidade.substring(0, this.colorEstabilidade.length - 2);
  } else {
      return this.colorEstabilidade;
  }
}

@Input('valueEst')
set valueEst(value: string) {
  this.colorEstabilidade = value;
  this.updateColorsDisc();
}

/* */

get valueEstADP(): string {
  if (!this.colorEstabilidadeADP) {
      return this.colorEstabilidadeADP.substring(0, this.colorEstabilidadeADP.length - 2);
  } else {
      return this.colorEstabilidadeADP;
  }
}

@Input('valueEstADP')
set valueEstADP(value: string) {
  this.colorEstabilidadeADP = value;
  this.updateColorsDisc();
}


/* */

get valueCau(): string {
  if (!this.colorCautela) {
      return this.colorCautela.substring(0, this.colorCautela.length - 2);
  } else {
      return this.colorCautela;
  }
}

@Input('valueCau')
set valueCau(value: string) {
  this.colorCautela = value;
  this.updateColorsDisc();
}

/* */

get valueCauADP(): string {
  if (!this.colorCautelaADP) {
      return this.colorCautelaADP.substring(0, this.colorCautelaADP.length - 2);
  } else {
      return this.colorCautelaADP;
  }
}

@Input('valueCauADP')
set valueCauADP(value: string) {
  this.colorCautelaADP = value;
  this.updateColorsDisc();
}


 public chartTypeDisc = 'bar';

 public chartDatasetsDisc: Array<any> = [
   { data: this.firstChartDataCom, label: 'Natural' },
   { data: this.secondChartDataCom, label: 'Adaptado' }
 ];

 public chartLabelsDisc: Array<any> = [
   'Dominância', 'Influência', 'Estabilidade', 'Cautela'
 ];

 public chartColorsDisc: Array<any> = [
   {
     borderColor: [],
     backgroundColor: [
      this.colorDominancia,
      this.colorInfluencia,
      this.colorEstabilidade,
      this.colorCautela,
       ],
     borderWidth: 0,
   },
   {
    borderColor: [],
    backgroundColor: [
      this.colorDominanciaADP,
      this.colorInfluenciaADP,
      this.colorEstabilidadeADP,
      this.colorCautelaADP,
      ],
    borderWidth: 0,
  }
 ];

 public chartOptionsDisc: any = {
   responsive: true,
   scales: {
    yAxes: [{
        ticks: {
          max: 100,
          beginAtZero: true,
          stepSize: 10
        }
    }]
  }
 };

 // Gráfico Motivadores
 public chartTypeMotivadores = 'bar';

 public chartDatasetsMotivadores: Array<any> = [
   { data: this.firstChartDataMot, label: 'Valores Motivadores' }
 ];

 public chartLabelsMotivadores: Array<any> = [
   'Altruísta', 'Econômico', 'Estética', 'Individualista', 'Político', 'Regulador', 'Teórico'
 ];

 public chartColorsMotivadores: Array<any> = [
   {
     borderColor: [],
     backgroundColor: [
      'rgba(189, 183, 107, 1)',
      'rgba(0, 250, 154, 1)',
      'rgba(128, 0, 128, 1)',
      'rgba(255, 235, 59, 1)',
      'rgba(3, 169, 244, 1)',
      'rgba(244, 67, 54, 1)',
      'rgba(244, 164, 96, 1)'
       ],
     borderWidth: 0,
   }
 ];

 public chartOptionsMotivadores: any = {
   responsive: true,
   scales: {
    yAxes: [{
        ticks: {
          max: 100,
          beginAtZero: true,
          stepSize: 10
        }
    }]
  }
 };


// Gráfico Competências
public chartTypeAxi = 'bar';

public chartDatasetsAxi: Array<any> = [
  // { data: this.firstChartDataAxi, label: 'Altas' }
   { data: this.countChartLabelsAxiAlta , label: 'Altas' },
   { data: this.countChartLabelsAxiMediana , label: 'Medianas' },
   { data: this.countChartLabelsAxiBaixa , label: 'Baixas' }
];

// public chartLabelsAxi: Array<any> = this.firstChartLabelsAxi;
public chartLabelsAxi: Array<any> = ['Número de Competências'];

public chartColorsAxi: Array<any> = [
  {
    backgroundColor: 'rgba(29, 233, 182, 1)',
    borderColor: [],
    borderWidth: 0,
  },
  {
    backgroundColor: 'rgba(255, 234, 0, 1)',
    borderColor: [],
    borderWidth: 0,
  },
  {
    backgroundColor: 'rgba(245, 91, 71, 1)',
    borderColor: [],
    borderWidth: 0,
  }
];

public chartOptionsAxi: any = {
  responsive: true,
  scales: {
    yAxes: [{
        ticks: {
          max: 80,
          beginAtZero: true,
          stepSize: 10
        }
    }]
  }
};

datasetsBalancoExterno: Array<any> = [];
datasetsBalancoInterno: Array<any> = [];

// Gráfico Balanço Dimensional
public chartTypeBalancoExterno = 'bar';

public chartDatasetsBalancoExterno: Array<any> = [
   { data: this.datasetsBalancoExterno , label: 'Padrão Externo' }
];
// public chartLabelsAxi: Array<any> = this.firstChartLabelsAxi;
public chartLabelsBalancoExterno: Array<any> = ['PE', 'PP', 'JS'];

public chartColorsBalancoExterno: Array<any> = [
  {
    backgroundColor: 'rgba(89, 149, 187, 1)',
    borderColor: [],
    borderWidth: 0,
  }
];

public chartOptionsBalancoExterno: any = {
  responsive: true,
  scales: {
    yAxes: [{
        ticks: {
          max: 100,
          beginAtZero: true,
          stepSize: 10
        }
    }]
  }
};

public chartTypeBalancoInterno = 'bar';

public chartDatasetsBalancoInterno: Array<any> = [
   { data: this.datasetsBalancoInterno , label: 'Padrão Interno' }
];
// public chartLabelsAxi: Array<any> = this.firstChartLabelsAxi;
public chartLabelsBalancoInterno: Array<any> = ['AE', 'CP', 'AD'];

public chartColorsBalancoInterno: Array<any> = [
  {
    backgroundColor: 'rgba(145, 206, 244, 1)',
    borderColor: [],
    borderWidth: 0,
  }
];

public chartOptionsBalancoInterno: any = {
  responsive: true,
  scales: {
    yAxes: [{
        ticks: {
          max: 100,
          beginAtZero: true,
          stepSize: 10
        }
    }]
  }
};

/*
* -- comum aos gráficos
*/
 public chartClicked(e: any): void { }
 public chartHovered(e: any): void { }

  /*
  * #Graficos
  */

  private subs = new SubSink();

  constructor(
    private avaliacaoService: AvaliacaoApiService,
    private excelExportService: ExcelExportService,
    private route: ActivatedRoute,
    private location: Location,
    private alertService: AlertModalService,
    private cookieService: CookieService
  ) {
    this.idAvaliacao = +this.route.snapshot.paramMap.get('Id');
    this.idAvaliado = +this.route.snapshot.paramMap.get('Av');
  }

  ngOnInit() {
    this.userRole = localStorage.getItem('userRole');
    this.goTop();
    this.getAvaliados();
    this.checkCookie = this.cookieService.hasItem('tourAvaAvaliado');
  }

  startTour(): void {
    this.disableTour = true;
    // Define the steps for tour
    setTimeout(() => {
      this.driver.defineSteps([
        {
          element: '#one-element-tour',
          popover: {
            className: 'first-step-popover-class',
            title: 'Vamos fazer um tour?',
            description: 'Você pode usar as teclas <i class="fas fa-caret-left icon-tour"></i> e <i class="fas fa-caret-right icon-tour"></i> para avançar e voltar. Acesse aqui sempre que precisar.',
            position: 'left',
            closeBtnText: 'Eu vejo depois.',
            nextBtnText: 'Vamos lá!',
            prevBtnText: '<i class="fas fa-plane-departure"></i>'
          }
        },
        {
          element: '#two-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Dados pessoais',
            description: 'Estes são os dados pessoais do avaliado.',
            position: 'right'
          }
        },
        {
          element: '#three-element-tour',
          popover: {
            title: 'Consistência',
            description: 'Este item indica a veracidade das respostas.<br>Caso esteja como <strong>Inconsistente</strong> será um resultado que, de acordo com o mundo (externo ou interno), deveremos prestar mais atenção. Possíveis motivos para o resultado Inconsistente:<br>a) Interrupção na hora do preenchimento;<br>b) Tentativa de burlar o resultado, mesmo que inconscientemente;<br>c) Medo, insegurança ou receio de se mostrar como é, de ser conhecido pelo outro.<br> d) Demora para preenchimento ocorrendo possível perda do raciocínio.',
            position: 'left'
          }
        },
        {
          element: '#four-element-tour',
          popover: {
            title: 'Baixar o relatório',
            description: 'Clique no botão "Baixar" para fazer o download dos dados em uma planilha do Excel.',
            position: 'left'
          }
        },
        {
          element: '#five-element-tour',
          stageBackground: '#343a40',
          popover: {
            title: 'Ciência',
            description: 'Você pode alternar a visualização por ciência clicando sobre a aba correspondente.',
            position: 'bottom'
          }
        },
        {
          element: '#six-element-tour',
          stageBackground: '#7a7a7a',
          popover: {
            title: 'Dados númericos e gráficos',
            description: 'Aqui é onde você encontra os resultados de acordo com a ciência selecionada.',
            position: 'top'
          }
        }
      ]);
      // Start the introduction
      this.driver.start();
      this.disableTour = false;
    }, 2000);
  }

  setCookie() {
    this.cookieService.setItem( 'tourAvaAvaliado', '1' );
  }

  getAvaliados(): void {

    this.avaliacaoDetalhes$ = this.avaliacaoService.getAvaliadosAvaliacaoAsync(this.idAvaliacao)
    .pipe(share(),take(1));
    this.avaliacaoDetalhes$.forEach(avaliados => {
      avaliados.forEach(avaliado => {
        if (+avaliado.id === this.idAvaliado) {
          this.avaliado = avaliado;

          const a = moment(avaliado.dataSolicitacaoPreenchimento);
          const b = moment(avaliado.dataConclusaoPreenchimento);
          this.tempoResposta = b.diff(a);
          this.tempoResposta = this.tempoResposta / 60000;
          this.setConceitosMotivadores();
          if (this.avaliado && this.checkCookie === false) {
            this.startTour();
            this.setCookie();
          }

          if (this.avaliado) {
            this.getGraficoDisc();
            this.getGraficoMotivadores();
            this.getGraficoAxiologia();
            this.getClassificacaoAxiologia();
            this.getPadroes();
          }
        }
     });
    });
    
  }
  
  getPrintDados() {
    const setPrintDadosTwo = []; // Array que irá separar os valores (Externo, Interno e Média)
    this.printWait = true; // desabilita o botão para evitar duplo click

    if (this.avaliado) {
          this.avaliado.resultadosCiencias.forEach(printCiencia => {

              switch (printCiencia.ciencia) {
                case 'Axiologia':
                  this.setPrintDados.push(['COMPETÊNCIAS']);
                  break;
                case 'Balanço Dimensional':
                  this.setPrintDados.push(['BALANÇO DIMENSIONAL']);
                  break;
                case 'Comportamento Observado':
                  this.setPrintDados.push(['COMPORTAMENTO']);
                  break;
                case 'Motivadores':
                  this.setPrintDados.push(['MOTIVADORES']);
                  break;
              }

              printCiencia.resultados.forEach(printResultados => {

                  if (printResultados.nomeFator !== 'Externo - Consistência' &&
                      printResultados.nomeFator !== 'Interno - Consistência' &&
                      printResultados.nomeFator !== 'Média das Competências' &&
                      printResultados.nomeFator !== 'Ranking') {

                    this.setPrintNomeAvaliado = this.avaliado.nome;
                    this.setPrintDados.push([ 
                      printResultados.nomeFator,
                      +printResultados.valor,
                      printResultados.classificacao,
                      printResultados.classificacao2,
                      printResultados.sinal 
                    ]);

                  }

                  if (printResultados.nomeFator === 'Externo - Consistência' ||
                  printResultados.nomeFator === 'Interno - Consistência' ||
                  printResultados.nomeFator === 'Média das Competências') {
                    // Separamos esses valores para ordenarmos na lista
                    setPrintDadosTwo.push([ 
                      printResultados.nomeFator,
                      +printResultados.valor,
                      printResultados.classificacao,
                      printResultados.sinal 
                    ]);

                  }

                });

          });
          this.setPrintDados = setPrintDadosTwo.concat(this.setPrintDados); // Unimos os arrays
          this.printExcel();

    } else {
      this.alertService.showAlertWarning('Não foi possível gerar a planilha. Atualize a página (F5) e tente novamente. Se o problema persistir, entre em contato com o suporte.')
      this.printWait = false;
    }
  }

  printExcel() {
    this.excelExportService.generateExcel(this.setPrintDados, this.setPrintNomeAvaliado, this.headerDados);
    this.printWait = false;
  }

  getGraficoDisc() {
    
          this.avaliado.resultadosCiencias.forEach(ciencia => {
              // Comportamento Observado
              if (ciencia.ciencia === 'Comportamento Observado') {
                ciencia.resultados.forEach(nomeFator => {
                  if (nomeFator.nomeFator === 'DISC NATURAL - Influência') {
                    this.valorInfNatural = nomeFator.valor;
                  }
                  if (nomeFator.nomeFator === 'DISC NATURAL - Cautela') {
                    this.valorCauNatural = nomeFator.valor;
                  }
                  if (nomeFator.nomeFator === 'DISC NATURAL - Dominância') {
                    this.valorDomNatural = nomeFator.valor;
                  }
                  if (nomeFator.nomeFator === 'DISC NATURAL - Estabilidade') {
                    this.valorEstNatural = nomeFator.valor;
                  }
                  if (nomeFator.nomeFator === 'DISC ADAPTÁVEL - Influência') {
                    this.valorInfAdaptavel = nomeFator.valor;
                  }
                  if (nomeFator.nomeFator === 'DISC ADAPTÁVEL - Cautela') {
                    this.valorCauAdaptavel = nomeFator.valor;
                  }
                  if (nomeFator.nomeFator === 'DISC ADAPTÁVEL - Dominância') {
                    this.valorDomAdaptavel = nomeFator.valor;
                  }
                  if (nomeFator.nomeFator === 'DISC ADAPTÁVEL - Estabilidade') {
                    this.valorEstAdaptavel = nomeFator.valor;
                  }
                });
              }
            });

      // Comportamento Observado
      this.firstChartDataCom.push(this.valorDomNatural);
      this.firstChartDataCom.push(this.valorInfNatural);
      this.firstChartDataCom.push(this.valorEstNatural);
      this.firstChartDataCom.push(this.valorCauNatural);
      this.firstChartDataCom.push(0);

      this.secondChartDataCom.push(this.valorDomAdaptavel);
      this.secondChartDataCom.push(this.valorInfAdaptavel);
      this.secondChartDataCom.push(this.valorEstAdaptavel);
      this.secondChartDataCom.push(this.valorCauAdaptavel);
      this.secondChartDataCom.push(0);

      this.chartDatasetsDisc = [
        { data: this.firstChartDataCom, label: 'Natural' },
        { data: this.secondChartDataCom, label: 'Adaptado' },
      ];
    // }
  }

  private updateColorsDisc() {
    this.chartColorsDisc = [
      {
        borderColor: [],
        backgroundColor: [
         this.colorDominancia,
         this.colorInfluencia,
         this.colorEstabilidade,
         this.colorCautela,
          ],
        borderWidth: 0,
      },
      {
       borderColor: [],
       backgroundColor: [
         this.colorDominanciaADP,
         this.colorInfluenciaADP,
         this.colorEstabilidadeADP,
         this.colorCautelaADP,
         ],
       borderWidth: 0,
     }
    ];
  }

  getGraficoMotivadores() {

          this.avaliado.resultadosCiencias.forEach(ciencia => {
            // Motivadores
            if (ciencia.ciencia === 'Motivadores') {
              ciencia.resultados.forEach(nomeFator => {
                if (nomeFator.nomeFator === 'Altruísta') {
                  this.valorAltruista = nomeFator.valor;
                }
                if (nomeFator.nomeFator === 'Econômico') {
                  this.valorEconomico = nomeFator.valor;
                }
                if (nomeFator.nomeFator === 'Estética') {
                  this.valorEstetica = nomeFator.valor;
                }
                if (nomeFator.nomeFator === 'Individualista') {
                  this.valorIndividualista = nomeFator.valor;
                }
                if (nomeFator.nomeFator === 'Político') {
                  this.valorPolitico = nomeFator.valor;
                }
                if (nomeFator.nomeFator === 'Regulador') {
                  this.valorRegulador = nomeFator.valor;
                }
                if (nomeFator.nomeFator === 'Teórico') {
                  this.valorTeorico = nomeFator.valor;
                }
              });
            }

          });

      // Motivadores
      this.firstChartDataMot.push(this.valorAltruista);
      this.firstChartDataMot.push(this.valorEconomico);
      this.firstChartDataMot.push(this.valorEstetica);
      this.firstChartDataMot.push(this.valorIndividualista);
      this.firstChartDataMot.push(this.valorPolitico);
      this.firstChartDataMot.push(this.valorRegulador);
      this.firstChartDataMot.push(this.valorTeorico);
      this.firstChartDataMot.push(0);

      this.chartDatasetsMotivadores = [
        { data: this.firstChartDataMot, label: 'Motivadores' }
      ];

   // }
  }

  getGraficoAxiologia() {
    let nomeFatorAxiologia: any | null;
    let valorFatorAxiologia: any | null;

            this.avaliado.resultadosCiencias.forEach(ciencia => {
              // Axiologia
              if (ciencia.ciencia === 'Axiologia') {
                ciencia.resultados.forEach(fator => {
                  const valor = +fator.valor;
                  if (fator.nomeFator !== 'Externo - Consistência'
                  && fator.nomeFator !== 'Interno - Consistência'
                  && fator.nomeFator !== 'Média das Competências'
                  && fator.nomeFator !== 'Ranking'
                  && valor > 79.999) {
                    nomeFatorAxiologia = fator.nomeFator;
                    valorFatorAxiologia = fator.valor;
                    this.firstChartLabelsAxi.push(nomeFatorAxiologia); // Label
                    this.firstChartDataAxi.push(valorFatorAxiologia); // Data
                  }
                });
              }
            });

  }

  getClassificacaoAxiologia() {
    let pushAltas = new Array<any>();
    let pushMedias = new Array<any>();
    let pushBaixas = new Array<any>();

          this.avaliado.resultadosCiencias.forEach(ciencia => {
            // Axiologia
            if (ciencia.ciencia === 'Axiologia') {
              ciencia.resultados.forEach(nomeFator => {
                if (nomeFator.nomeFator !== 'Externo - Consistência'
                 && nomeFator.nomeFator !== 'Interno - Consistência'
                 && nomeFator.nomeFator !== 'Média das Competências'
                 && nomeFator.nomeFator !== 'Ranking') {

                    if (nomeFator.classificacao === 'Alta') {
                      pushAltas.push(nomeFator.classificacao);
                    }
                    if (nomeFator.classificacao === 'Mediana') {
                      pushMedias.push(nomeFator.classificacao);
                    }
                    if (nomeFator.classificacao === 'Baixa') {
                      pushBaixas.push(nomeFator.classificacao);
                    }
              }
            });
          }
        });

    // Contagem das médias
    this.fatoresAxiAltaCount = pushAltas.length;
    this.fatoresAxiMedianaCount = pushMedias.length;
    this.fatoresAxiBaixaCount = pushBaixas.length;

    this.countChartLabelsAxiAlta.push(this.fatoresAxiAltaCount);
    this.countChartLabelsAxiMediana.push(this.fatoresAxiMedianaCount);
    this.countChartLabelsAxiBaixa.push(this.fatoresAxiBaixaCount);

  }

  getPadroes() {

    let sinalEmpatia97: string;
    let sinalPratico98: string;
    let sinalJulgamento99: string;
    let sinalAutoestima100: string;
    let sinalConsciencia101: string;
    let sinalAutodirecao102: string;

    let valorEmpatia;
    let valorPratico;
    let valorJulgamento;
    let valorAutoestima;
    let valorConsciencia;
    let valorAutodirecao;

            this.avaliado.resultadosCiencias.forEach(ciencias => {
              if (ciencias.cienciaId === 4) {
                ciencias.resultados.forEach(fator => {
                      if (fator.fatorId === 97) {
                        sinalEmpatia97 = fator.sinal;
                        valorEmpatia = fator.valor;
                      }
                      if (fator.fatorId === 98) {
                        sinalPratico98 = fator.sinal;
                        valorPratico = fator.valor;
                      }
                      if (fator.fatorId === 99) {
                        sinalJulgamento99 = fator.sinal;
                        valorJulgamento = fator.valor;
                      }

                      if (fator.fatorId === 100) {
                        sinalAutoestima100 = fator.sinal;
                        valorAutoestima = fator.valor;
                      }
                      if (fator.fatorId === 101) {
                        sinalConsciencia101 = fator.sinal;
                        valorConsciencia = fator.valor;
                      }
                      if (fator.fatorId === 102) {
                        sinalAutodirecao102 = fator.sinal;
                        valorAutodirecao = fator.valor;
                      }


                      if (
                        sinalAutoestima100 !== undefined && 
                        sinalConsciencia101 !== undefined && 
                        sinalAutodirecao102 !== undefined &&
                        sinalEmpatia97 !== undefined && 
                        sinalPratico98 !== undefined && 
                        sinalJulgamento99) {
                          this.sinaisTendenciaInterna.push(sinalAutoestima100, sinalConsciencia101, sinalAutodirecao102);
                          this.sinaisTendenciaExterna.push(sinalEmpatia97, sinalPratico98, sinalJulgamento99);
                          this.datasetsBalancoExterno.push(valorEmpatia, valorPratico, valorJulgamento);
                          this.datasetsBalancoInterno.push(valorAutoestima, valorConsciencia, valorAutodirecao);
                          this.setTendencias();
                      }

                });
            }
          })

  }

  setTendencias() {
    const setTendenciaInterna = [];
    const setTendenciaExterna = [];

    // Externo
    const pessimista = ['-', '-', '-'];
    const vigilante = ['-', '-', '+'];
    const reativo = ['-', '-', '='];
    const comerciante = ['-', '+', '-'];
    const conformista = ['-', '+', '+'];
    const operador = ['-', '+', '='];
    const cetico = ['-', '=', '-'];
    const cientifico = ['-', '=', '+'];
    const jornalista = ['-', '=', '='];
    const acolhedor = ['+', '-', '-'];
    const reformador = ['+', '-', '+'];
    const compassivo = ['+', '-', '='];
    const facilitador = ['+', '+', '-'];
    const colaborador = ['+', '=', '-'];
    const independente = ['=', '-', '-'];
    const juiz = ['=', '-', '+'];
    const humorista = ['=', '-', '='];
    const consultor = ['=', '+', '-'];
    const opositor = ['=', '=', '-'];
    const virtuoso = ['=', '=', '='];

    if (JSON.stringify(pessimista) === JSON.stringify(this.sinaisTendenciaExterna)) {
      setTendenciaExterna.push({
        padrao: 'Pessimista',
        descricao: 'Seu temperamento externo sugere que você pode se tornar muito negativo em relação às emoções. Talvez você sinta que você tem uma falta de compreensão ou distância emocional em relação ao mundo ao seu redor (ou seja, outros, sociedade, etc.). Há uma visão pessimista de todas as três dimensões do pensamento neste exato momento em sua vida. A dimensão Pessoas é vista de forma negativa e isso pode indicar alguma relutância, medo ou pessimismo em relação aos outros. Talvez você defina uma distância emocional entre você e os demais sob o pretexto do profissionalismo. A dimensão Tarefas também é vista negativamente, o que revela uma distância semelhante da sociedade e uma provável falta de motivação por coisas materiais. A dimensão Sistemas também apresenta um foco pessimista e aponta para pontos de vista negativos em relação aos sistemas e a ordem estabelecidos. Isso até mesmo pode indicar certos níveis de rebeldia. Este foco geral expressa uma negatividade generalizada em relação ao mundo, o que pode resultar em respostas isoladas ou desfavoráveis a fatores externos como pessoas, tarefas ou sistemas.'
      });
    }
    if (JSON.stringify(vigilante) === JSON.stringify(this.sinaisTendenciaExterna)) {
      setTendenciaExterna.push({
        padrao: 'Vigilante',
        descricao: 'Seu temperamento mostra que você é uma pessoa que protege ou observa a fim de manter e preservar a ordem. Seu temperamento poderia ser interpretado como o “verdadeiro seguidor" porque você pode se tornar muito dogmático sobre a ordem ou estrutura as quais apoia. Em casos extremos, você pode até mesmo apresentar lealdade cega em relação a ordem preferida e, portanto, dependerá de suas regras para orientar seu próprio comportamento. A dimensão Pessoas é vista de forma negativa, o que demonstra alguma distância dos outros, ou problemas para compreendê-los e, possivelmente, até mesmo desconfiança sobre eles. A dimensão Tarefas também é vista negativamente, o que pode resultar em sentimentos de se sentir “fora do grupo” ou tendência a estar excessivamente focado em problemas dentro do mesmo. Já na dimensão Sistemas, a supervalorização das regras revela uma forte preferência por ordem ou estrutura externas. Em situações estressantes, você pode se tornar dogmático para que as coisas sejam feitas "do jeito certo", mesmo à custa de pessoas e suas necessidades ou perda de prazos objetivos. No entanto, você normalmente prefere ter planos que são claramente definidos e tem um forte desejo de cumprir todas as regras.'
      });
    }
    if (JSON.stringify(reativo) === JSON.stringify(this.sinaisTendenciaExterna)) {
      setTendenciaExterna.push({
        padrao: 'Reativo',
        descricao: 'Seu temperamento revela uma tendência para reagir à experiência externa referente ao sistema e a ordem. Devido as suas visões negativas sobre as dimensões Pessoas e Tarefas, você pode responder a percepção de autoridade de maneira mais forte. Você tem uma visão muito boa e equilibrada da dimensão Sistemas, sem vê-la de forma excessivamente positiva ou negativa. Seu foco nesta dimensão é genuíno, sincero e preciso. Como resultado, você tem uma compreensão clara e adequada para a necessidade de sistemas, não se tornando excessivamente dependente deles, mas também não se rebelando contra eles. Seu foco na dimensão Pessoas indica algum medo em aproximar-se demais dos outros, enquanto a dimensão Tarefas indica algum ceticismo sobre a convenção socialmente aceita. Em geral, você é alguém muito capaz de manter o controle de uma situação para alcançar seus objetivos, embora ter uma visão um pouco mais positiva das relações pessoais e a necessidade de prazos concretos, mesmo com o custo de fazer isso de maneira perfeita, pode ser recompensador para você.'
      });
    }
    if (JSON.stringify(comerciante) === JSON.stringify(this.sinaisTendenciaExterna)) {
      setTendenciaExterna.push({
        padrao: 'Comerciante',
        descricao: 'Seu temperamento descreve uma pessoa que vive principalmente no plano de valor social (comercial). Você vê o mundo em termos de coisas e seu valor comparativo, ou como tarefas a serem completadas. Outras pessoas também podem ser vistas, por você, como coisas, ou melhor, como um instrumento para atingir o objetivo. Você possui uma boa apreciação pelas habilidades organizacionais lógicas e pode gastar grande esforço na busca das suas metas, não costumando hesitar em exercitar-se nessa busca, e considerando isso energizante, às vezes. Você pode ter alguma predisposição para ser mais reativo do que proativo. Pode até haver uma tendência para algum foco excessivo nos resultados à custa do quadro geral. Um exemplo disto, é poder quebrar alguma regra para obter uma venda. Sua visão ligeiramente negativa da dimensão Pessoas pode indicar alguém mais capaz de manipular os outros (de forma positiva ou negativa) do que outros padrões apresentam. A dimensão Sistemas revela a visão negativa das regras, estrutura ou ordem estabelecidas, indicando uma pessoa que pode tender a ignorá-las mais do que alguém com uma atitude mais positiva em relação a esta dada dimensão. A combinação de seus temperamentos fala sobre uma pessoa que pode ser ideal para muitas posições de vendas ou papéis que exijam pouca supervisão, liberdade e persuasão dos demais de uma maneira muito específica.'
      });
    }
    if (JSON.stringify(conformista) === JSON.stringify(this.sinaisTendenciaExterna)) {
      setTendenciaExterna.push({
        padrao: 'Conformista',
        descricao: 'Seu temperamento indica uma pessoa que busca relacionamentos sociais ou profissionais acima de relacionamentos pessoais. Você aprecia mais as coisas práticas e detalhadas, e espera que as coisas sejam feitas do jeito certo e estejam de acordo com as regras estabelecidas pela instituição ou organização.  Você pode ser adepto a transformar conceitos em ação. Você tem uma visão um tanto negativa da dimensão Pessoas, no entanto, isso indica que pode ver os outros mais como partes do todo do que como indivíduos únicos fora da estrutura social dentro da qual há interação. Você coloca alguma ênfase sobre as dimensões do pensamento referentes às Tarefas e Sistemas, o que indica uma preferência a se conformar com uma norma ou padrão social e uma maior capacidade de resposta à autoridade, organização ou sistema impostos. Você é alguém que vai trabalhar extremamente duro para fazer as coisas bem feitas e do jeito certo. A qualidade é muito importante para você.'
      });
    }
    if (JSON.stringify(operador) === JSON.stringify(this.sinaisTendenciaExterna)) {
      setTendenciaExterna.push({
        padrao: 'Operador',
        descricao: 'Seu temperamento indica uma pessoa que coloca toda a sua energia no trabalho ou no objetivo. Pessoas desse padrão são, muitas vezes, praticantes de todos os tipos de trabalhos manuais, artesãos e artistas, ou seja, pessoas que dominam a questão de como certas tarefas devem ser feitas detalhadamente. Você tem uma visão um pouco negativa da dimensão Pessoas, o que revela alguma distância emocional para com os outros, ou talvez uma falta de compreensão em comparação ao quão bem você vê a tarefa em questão. Você vê a dimensão Sistemas de uma maneira neutra agradável, nem excessivamente otimista e nem excessivamente pessimista. Como resultado, você tem uma boa atitude em relação à autoridade e pode trabalhar igualmente bem sob supervisão ou por conta própria. Você tende a apreciar coisas materiais e pode encontrar um bom equilíbrio entre seguir as regras ou quebrá-las quando necessário.'
      });
    }
    if (JSON.stringify(cetico) === JSON.stringify(this.sinaisTendenciaExterna)) {
      setTendenciaExterna.push({
        padrao: 'Cético',
        descricao: 'Seu temperamento indica uma cautela ao envolvimento com o ambiente externo imediato. Você vê a dimensão Tarefas de uma maneira equilibrada, o que resulta em uma harmonia com o mundo social ao seu redor. Você nem valoriza excessivamente e nem desvaloriza a importância do objetivo, e pode se concentrar claramente no que precisa ser feito para alcançar o sucesso. Sua visão negativa das dimensões Pessoas e Sistemas, no entanto, pode fazer com que você se afaste de ambientes altamente estruturados em favor da mudança. Você pode deixar de valorizar as pessoas com a devida importância que elas têm, favorecendo, ao invés disso, o trabalho com elas mais em uma base profissional.'
      });
    }
    if (JSON.stringify(cientifico) === JSON.stringify(this.sinaisTendenciaExterna)) {
      setTendenciaExterna.push({
        padrao: 'Científico',
        descricao: 'Seu temperamento é muito comumente encontrado. Ele indica a qualidade da avaliação geralmente encontrada em cientistas e pesquisadores de todos os tipos. Sua visão da dimensão Sistemas revela uma preferência pela orientação formal de padrões estabelecidos, autoridade ou estrutura existente de algum tipo (por exemplo, regras, procedimentos, sistemas, leis, etc.). Isto indica uma tendência para a conformidade com os sistemas e a ordem, enquanto que a dimensão Pessoas indica uma menor preferência por envolvimentos emocionais com os outros em favor do foco na ordem do que está sendo feito. Você alcançou uma visão equilibrada da dimensão Tarefas, o que mostra que não há nem muita e nem pouca ênfase na importância da realização das mesmas. Ao invés disso, você dá apenas a atenção adequada para obter resultados sem ficar obcecado por eles. Você pode ver os outros como "parte do plano", ou elementos do sistema, mais do que pessoas com seu próprio pensamento, necessidades e desejos.'
      });
    }
    if (JSON.stringify(jornalista) === JSON.stringify(this.sinaisTendenciaExterna)) {
      setTendenciaExterna.push({
        padrao: 'Jornalista',
        descricao: 'Seu temperamento mostra uma pequena distância emocional das pessoas com uma visão bem equilibrada do mundo social e da sua ordem. Jornalistas e repórteres muitas vezes desenvolvem esse temperamento para o mundo devido à demanda de objetividade nestas profissões. Algo para se alertar é a respeito da possibilidade de se viver uma vida excessivamente objetiva, um tanto quanto impessoal. Sua visão neutra das dimensões do pensamento Tarefas e Sistemas indica a compreensão muita clara do que precisa ser feito, na maioria dos casos. Você provavelmente não se perde nos detalhes, permanece objetivo e profissional (mesmo às vezes as custas das emoções pessoais), e tem uma apreciação saudável por regras e ordens, mas não a ponto de as seguir cegamente sem motivo.'
      });
    }
    if (JSON.stringify(acolhedor) === JSON.stringify(this.sinaisTendenciaExterna)) {
      setTendenciaExterna.push({
        padrao: 'Acolhedor',
        descricao: 'Seu temperamento identifica uma qualidade de serviço acolhedora para a humanidade, como o "bom samaritano" que ajudou o homem a sair da vala, simplesmente porque ele era um ser humano necessitado. Você tem pontos de vista um pouco negativos sobre as dimensões Tarefas e Sistemas, o que indica pouca preocupação com a convenção social e uma preferência para trabalhar fora do sistema de regras ou ordem estabelecidas. Isto não quer dizer que você quebre as regras de maneira excessiva, apenas que prefere trabalhar com menos estrutura. Você pode ter uma ligeira tendência para enfatizar excessivamente a dimensão do pensamento referente às pessoas, o que pode levar a decisões muito otimistas, onde os outros estão envolvidos. Conflito com os demais é algo muito ruim para este perfil e, provavelmente, você trabalha duro para evitar que ele ocorra. Você também pode pegar os problemas dos outros para si mesmo, o que talvez lhe cause um aumento de estresse e ansiedade, se não houver o devido cuidado.'
       });
    }
    if (JSON.stringify(reformador) === JSON.stringify(this.sinaisTendenciaExterna)) {
      setTendenciaExterna.push({
        padrao: 'Reformador',
        descricao: 'Seu temperamento identifica alguém que tem uma grande preocupação com as outras pessoas e uma aceitação aberta às regras e estrutura estabelecidas. A sua visão excessivamente positiva das dimensões Pessoas e Sistemas deixa a dimensão Tarefas subestimada em importância. Como resultado, você pode colocar maior ênfase nas necessidades e problemas dos outros, e também em fazer as coisas de acordo com o plano estabelecido, do que em colocar na qualidade do trabalho. Você provavelmente gosta de trabalhar com pessoas em um ambiente estruturado em oposição ao trabalho solitário e em um lugar muito bagunçado.'
      });
    }
    if (JSON.stringify(compassivo) === JSON.stringify(this.sinaisTendenciaExterna)) {
      setTendenciaExterna.push({
        padrao: 'Compassivo',
        descricao: 'Seu temperamento é caracterizado pelo alto nível de preferência para as pessoas e pelo menor foco nos objetivos ou na convenção social. Você geralmente é gentil e generoso. Sua pontuação neutra na dimensão Sistemas revela o equilíbrio entre os aspectos positivos e negativos da autoridade. Isso indica que você avaliará as regras de forma objetiva, vendo as normas que são irrelevantes como realmente irrelevantes e as efetivas como efetivas. As regras e os sistemas são vistos em termos de como afetam as pessoas, e não são adotados obstinadamente ou cegamente. Você vê claramente a necessidade de tal ordem, mas não está tão fascinado pela necessidade de fazer as coisas a sua própria maneira ou a maneira da empresa, quando isso interferir na sua capacidade de ser adaptar e mudar conforme o necessário para obter resultados. Você pode ter uma pequena tendência para confiar demais nos outros com o tempo e, provavelmente, não gosta de confrontos, mais do que a maioria das outras pessoas.'
      });
    }
    if (JSON.stringify(facilitador) === JSON.stringify(this.sinaisTendenciaExterna)) {
      setTendenciaExterna.push({
        padrao: 'Facilitador',
        descricao: 'Seu temperamento é caracterizado como alguém que gosta de mostrar às pessoas como fazer as coisas por si próprias. As pessoas desse temperamento não funcionam bem sob forte supervisão direta devido a visão negativa da dimensão Sistemas. Você prefere a liderança casual que lhe dá espaço para se mover e tomar decisões por conta própria, sendo que prefere ser quem supervisiona e não o supervisionado. Sua visão das dimensões Pessoas e Tarefas indica que você coloca mais importância nas necessidades das pessoas e nos objetivos do que nas regras que os governam. Você pode tender, no entanto, a confiar um pouco demais nos outros.'
      });
    }
    if (JSON.stringify(colaborador) === JSON.stringify(this.sinaisTendenciaExterna)) {
      setTendenciaExterna.push({
        padrao: 'Colaborador',
        descricao: 'Seu temperamento mostra que você é alguém que está muito envolvido com as pessoas no contexto empresarial e social, sendo menos preocupado com as regras. Pessoas com esse temperamento de valor são bons amigos e companheiros, e fazem excelentes companheiros de equipe e colegas de trabalho. A dimensão Pessoas é um tanto quanto supervalorizada, o que pode resultar em que você invista mais tempo e esforço a fim de construir relacionamentos pessoais do que para obter as coisas feitas. Você é bastante altruísta, mas isso também pode ser uma falha, às vezes. Sua visão negativa da dimensão Sistemas revela cautela ou até mesmo desrespeito às regras e à autoridade estabelecidas. Você pode recusar as relações mais formais (profissionais) com outras pessoas a favor de uma interação mais pessoal.'
      });
    }
    if (JSON.stringify(independente) === JSON.stringify(this.sinaisTendenciaExterna)) {
      setTendenciaExterna.push({
        padrao: 'Independente',
        descricao: 'Seu temperamento mostra que você é alguém um pouco discordante do sistema, ordem e convenção social estabelecidos. Isto não é necessariamente algo ruim. A criatividade pode estimular você a deixar o caminho mais conhecido para encontrar novas maneiras de fazer as coisas. Você tem um alto nível de foco em pessoas no geral, e está aberto a aceitar os outros. Sua visão negativa da dimensão Tarefas pode causar problemas quando alinhada ao menor interesse pela convenção social estabelecida e a disposição de derrubar ou tolerar a perda dos sistemas de ordem existentes, mostrada pelo seu baixo foco na dimensão Sistemas. Você pode ser muito intuitivo, visionário e, muitas vezes, não convencional.'
      });
    }
    if (JSON.stringify(juiz) === JSON.stringify(this.sinaisTendenciaExterna)) {
      setTendenciaExterna.push({
        padrao: 'Juiz',
        descricao: 'Seu temperamento indica que você é alguém que se relaciona com o mundo principalmente em termos do sistema e ordem estabelecidos (por exemplo, regras, normas e lei). Muitas das suas energias estão focadas em fazer as coisas caber dentro do sistema ou da ordem. A sua dimensão Tarefas revela uma distância consciente da convenção social, enquanto a sua dimensão Pessoas indica uma objetividade equilibrada em relação aos outros, sendo que você nem confia demais e nem é tão frio. Você consegue um bom equilíbrio entre a valorização das pessoas sem ficar tão envolvido com elas a ponto de perder a objetividade. Este temperamento não é diferente do encontrado em juízes, os quais são encarregados de aplicar a lei escrita às pessoas com equidade objetiva, e sem considerar o status social.'
       });
    }
    if (JSON.stringify(humorista) === JSON.stringify(this.sinaisTendenciaExterna)) {
      setTendenciaExterna.push({
        padrao: 'Humorista',
        descricao: 'Seu temperamento indica que você é alguém que pode encontrar humor e ironia nas convenções sociais. Você tem prazer na brincadeira com os outros, podendo levar a vida em um tom mais leve e, possivelmente, até mesmo desfrutar de uma boa piada prática. Você aceita tanto as pessoas como elas são quanto o sistema e a ordem estabelecidos. Tal equilíbrio em relação à autoridade e a outras pessoas torna mais fácil para alguém como você rir da vida, ou não levar tudo tão a sério. Se houvesse um nome para este temperamento, seria “humorista”. Não ultrapassando ou subestimando pessoas ou regras, você exerce menos esforço em lidar com ambos, portanto, menor ansiedade. Sua visão de detalhes, organização e constância, no entanto, é mais pessimista.'
       });
    }
    if (JSON.stringify(consultor) === JSON.stringify(this.sinaisTendenciaExterna)) {
      setTendenciaExterna.push({
        padrao: 'Consultor',
        descricao: 'Seu temperamento caracteriza uma pessoa levemente mais focada na dimensão social do que em pessoas como indivíduos únicos ou em regras organizacionais ou ordem. Considerando que sua visão da dimensão Pessoas é equilibrada, o que significa que você nem supervaloriza e nem subestima os demais, você tende a ver estrutura e ordem que são muito bem definidas como algo ruim. A questão importante é: "seu trabalho exige que você se conforme às regras ou se esforce para mudá-las?". Você pode ser muito criativo quando estiver no ambiente certo, além de entender bem as pessoas.'
      });
    }
    if (JSON.stringify(opositor) === JSON.stringify(this.sinaisTendenciaExterna)) {
      setTendenciaExterna.push({
        padrao: 'Opositor',
        descricao: 'Seu temperamento descreve alguém que se opõe ao sistema e à ordem estabelecidos. Pessoas que têm esse temperamento podem ser manifestantes e críticos com base em uma avaliação objetiva de outras pessoas e do mundo social. Você se dá bem com os outros, geralmente não é excessivamente simpático, e geralmente entende o que precisa ser feito para alcançar o sucesso. Você tenderá a encontrar novas maneiras ao redor, ou evitar, controles existentes.'
      });
    }
    if (JSON.stringify(virtuoso) === JSON.stringify(this.sinaisTendenciaExterna)) {
      setTendenciaExterna.push({
        padrao: 'Virtuoso',
        descricao: 'Seu temperamento revela uma pessoa que alcançou equilíbrio dinâmico em todas as dimensões do pensamento. Você tem um excelente nível de foco em todas as três dimensões, o que lhe permite sentir-se à vontade na maioria das situações, ser livre de controles, adaptável, versátil e aberto a coisas novas. Você tem uma sensação elevada sobre si mesmo.'
      });
    }

    // Interno
    const negativo = ['-', '-', '-'];
    const dirigido = ['-', '-', '+'];
    const passivo = ['-', '-', '='];
    const influenciavel = ['-', '+', '-'];
    const compulsivo = ['-', '+', '+'];
    const trabalhador = ['-', '+', '='];
    const relutante = ['-', '=', '-'];
    const assertivo = ['-', '=', '+'];
    const introspectivo = ['-', '=', '='];
    const centrado = ['+', '-', '-'];
    const dedicado = ['+', '-', '+'];
    const indulgente = ['+', '-', '='];
    const elevado = ['+', '+', '-'];
    const sensivel = ['+', '=', '-'];
    const dinamico = ['=', '-', '-'];
    const expressivo = ['=', '-', '+'];
    const dependente = ['=', '-', '='];
    const discriminador = ['=', '+', '-'];
    const flexivel = ['=', '=', '-'];
    const aberto = ['=', '=', '='];

    if (JSON.stringify(negativo) === JSON.stringify(this.sinaisTendenciaInterna)) { setTendenciaInterna.push({
      padrao: 'Negativo',
      descricao: 'Seu conceito sobre si mesmo fornece muito pouca ou nenhuma autoestima. Você provavelmente sente uma tremenda sensação de culpa e dúvida. Você é extremamente difícil para si próprio, nunca se sente satisfeito com os resultados obtidos e provavelmente tem dificuldade em ficar entusiasmado com qualquer coisa. Você vai postergar as coisas devido à incapacidade de tomar decisões e se comprometer.' });
    }
    if (JSON.stringify(dirigido) === JSON.stringify(this.sinaisTendenciaInterna)) { setTendenciaInterna.push({
      padrao: 'Dirigido',
      descricao: 'Você é uma pessoa que pode ter uma preocupação excessiva com os objetivos que você estabeleceu para si mesmo. Você subestima seu próprio valor e, ao contrário, baseia sua autoestima em como você segue suas próprias regras, as quais você aderiu tenazmente, e tende a supervalorizar a auto-organização. Você é extremamente autodisciplinado e, como resultado, provavelmente é muito confiável, pontual e meticuloso. No entanto, provavelmente, é bastante dogmático sobre suas crenças pessoais e não é susceptível a mudar ou ceder às interpretações dos outros, mesmo quando contrárias e elas. Você sente-se frustrado com mais frequência do que deveria, e mais frequentemente, ainda, consigo mesmo.' });
    }
    if (JSON.stringify(passivo) === JSON.stringify(this.sinaisTendenciaInterna)) { setTendenciaInterna.push({
      padrao: 'Passivo',
      descricao: 'Você é alguém um pouco incerto e inseguro sobre a sua própria identificação, neste momento, tendo problemas para agir enquanto se encontra assim. Você passa por alguma dúvida pessoal e insegurança, e pode exibir uma falta de confiança em seu trabalho. Como alternativa, age, principalmente, na direção de garantir que as regras sejam cumpridas enquanto você decide quem deve ser. Embora esteja mais confiante no que tem valor para você e no que deseja fazer, é a direção ou a melhor maneira de toma-la que é indefinida para você, neste momento.' });
    }
    if (JSON.stringify(influenciavel) === JSON.stringify(this.sinaisTendenciaInterna)) { setTendenciaInterna.push({
      padrao: 'Influenciável',
      descricao: 'Você é uma pessoa que facilmente se envolve em atividades de forma experimental ou impulsiva, enquanto você tenta cristalizar sua própria direção ou objetivos. Você não está claro quanto à melhor maneira de alcançar seus objetivos e tem dúvidas sobre si mesmo. Isso pode fazer com que coloque mais importância no que os outros pensam sobre você e suas realizações, já que seus próprios guias não estão claros. Você ainda não estabeleceu regras certas e definitivas para a auto-organização e pode estar se questionando como quer prosseguir na vida, neste exato momento. Devido a esses fatores, você é mais propenso a ser influenciado pelo seu entorno imediato e pode ser considerado mais suscetível como resultado.' });
    }
    if (JSON.stringify(compulsivo) === JSON.stringify(this.sinaisTendenciaInterna)) { setTendenciaInterna.push({
      padrao: 'Compulsivo',
      descricao: 'Você é motivado por conquistar objetivos. Você tende a subvalorizar sua própria autoestima e, ao invés disso, colocar muita ênfase na realização de ter as coisas feitas de forma correta e em acordo às suas expectativas, ou de outras pessoas. Apesar da subestimação que apresenta por si mesmo, você ainda tende a mostrar autoconfiança e pode estar muito confortável em situações sociais. Normalmente você é bem organizado.' });
    }
    if (JSON.stringify(trabalhador) === JSON.stringify(this.sinaisTendenciaInterna)) { setTendenciaInterna.push({
      padrao: 'Trabalhador',
      descricao: 'Você é trabalhador. Você se identifica fortemente com seu papel social/trabalho primário. Você se subvaloriza um pouco, o que é compensado pela supervalorização colocada no seu trabalho. Sua função é quem você é e você coloca um alto valor nesse papel. Embora haja tamanha colocação do seu próprio valor em seu trabalho, você está um quanto tanto confuso sobre onde quer estar no futuro. Você não é nem rígido e nem influenciável em relação à forma como você faz as coisas e, como resultado, tende a pesar adequadamente as consequências das ações antes de tomá-las. Diante disso, no entanto, você ainda é mais reativo do que você é proativo.' });
    }
    if (JSON.stringify(relutante) === JSON.stringify(this.sinaisTendenciaInterna)) { setTendenciaInterna.push({
      padrao: 'Relutante',
      descricao: 'Você é alguém que não se identifica demais com seus papéis sociais/de trabalho primários na vida. Você está relutante em envolver-se completamente no papel escolhido ou na carreira, pois não sente um sentimento de conquista pessoal a partir dessas coisas. Você não tem certeza sobre o que quer fazer e a melhor maneira de prosseguir. Há muita confusão dentro de você neste exato momento. Quem você é, como você deve ser, é algo que você está tentando descobrir. Como resultado de tudo isso, você pode não ter uma visão tão positiva de você como deveria.' });
    }
    if (JSON.stringify(assertivo) === JSON.stringify(this.sinaisTendenciaInterna)) { setTendenciaInterna.push({
      padrao: 'Assertivo',
      descricao: 'Você identifica fortemente sua autoimagem com sua crença de como você deve fazer as coisas e, portanto, pode ser bastante assertivo quando se trata de agir de uma certa maneira. Há mais em jogo do que simplesmente "fazer isso do meu jeito". Para você, a conformidade com as regras que você define para como as coisas devem ser feitas está diretamente ligada a sua autoestima. Os resultados tornam-se secundários à forma de como você seguiu as diretrizes. Não fazer as coisas da maneira correta, mesmo que o trabalho seja feito, equivale a um fracasso em sua mente. Você não se valoriza o suficiente neste momento, ao invés disso, procura dar valor a si mesmo através do quanto segue às regras que você considera orientar suas ações e a sua vida. Para você é mais importante como você faz isso do que o que você faz. Você tem alguma incerteza e medo da mudança.' });
    }
    if (JSON.stringify(introspectivo) === JSON.stringify(this.sinaisTendenciaInterna)) { setTendenciaInterna.push({
      padrao: 'Introspectivo',
      descricao: 'Você tem uma atitude objetiva em relação aos seus próprios guias ou crenças sobre como você deve fazer as coisas e como deve viver a vida. Você vê seu trabalho como uma maneira de se expressar mais completamente, mas não como a coisa mais importante em sua vida por qualquer meio. Você se vê como mais do que a soma de seu trabalho, mas ainda assim, não se dá tanto crédito como deveria. Você é um pouco severo demais consigo mesmo.' });
    }
    if (JSON.stringify(centrado) === JSON.stringify(this.sinaisTendenciaInterna)) { setTendenciaInterna.push({
      padrao: 'Centrado',
      descricao: 'Você tem uma consciência apurada do seu próprio valor, e valoriza esse aspecto em si próprio mais do que em relação ao que você faz ou o quão bem você faz (seu cargo ou desempenho). Você pode tender a sentir, às vezes, que os outros não te dão crédito suficiente ou apreciam sua contribuição, no entanto, você tende a permanecer no controle de si mesmo e sabe quando ser assertivo e quando permanecer reservado. Sobre o que acredita, você tenderá a crer nisso completamente, podendo se tornar muito obstinado por tais crenças concretas.' });
    }
    if (JSON.stringify(dedicado) === JSON.stringify(this.sinaisTendenciaInterna)) { setTendenciaInterna.push({
      padrao: 'Dedicado',
      descricao: 'Você tem clareza sobre quem você é como pessoa e aceita as suas verdadeiras limitações e pontos fortes, sendo realista sobre si mesmo, no geral. No entanto, tende a ver o seu trabalho ou os principais papéis da sua vida como algo que nunca poderá verdadeiramente cumprir como pessoa. Tais coisas são vistas por você como meramente meios para um fim que permita atender outras necessidades internas de autoexpressão. Devido a esta atitude, é muito improvável que você fique “queimado” em um emprego ou que se sacrificasse demais.' });
    }
    if (JSON.stringify(indulgente) === JSON.stringify(this.sinaisTendenciaInterna)) { setTendenciaInterna.push({
      padrao: 'Indulgente',
      descricao: 'Você alcançou uma apreciação objetiva para você enquanto pessoa. Você não subestima seu próprio valor. Ao invés disso, é mais provável que você subvalorize a capacidade de qualquer trabalho que venha a realizar que faça-o sentir-se completo ou totalmente satisfeito. Você coloca mais valor em ser disciplinado e seguir seu próprio sentido, direção ou suas próprias regras internas.' });
    }
    if (JSON.stringify(elevado) === JSON.stringify(this.sinaisTendenciaInterna)) { setTendenciaInterna.push({
      padrao: 'Elevado',
      descricao: 'Você tende a colocar muito foco em seu papel e aparência, e tem uma visão muito positiva sobre si próprio. Você pode não ser tão motivado quanto outros por já ter uma visão muito maior sobre si mesmo, o que pode levá-lo, realmente, a ter uma leve falta de ambição, já que, de certa forma, sentir que precisa melhorar serve como motivador. Você sente que já alcançou níveis significativos de desenvolvimento em muitas áreas e terá que olhar a fundo para encontrar sua motivação, ao invés de simplesmente sentir que precisa provar quem é em qualquer lugar. Você também pode colocar importância demais em seu trabalho em relação ao quanto isso define você como pessoa. Você está um pouco confuso quanto à melhor maneira de avançar um nível mais alto de sucesso na sua vida neste momento.' });
    }
    if (JSON.stringify(sensivel) === JSON.stringify(this.sinaisTendenciaInterna)) { setTendenciaInterna.push({
      padrao: 'Sensível',
      descricao: 'Você se valoriza por quem você é como uma pessoa acima do seu trabalho ou a maneira pela qual você o faz. Você tem muita confiança interna em suas habilidades, mas às vezes essa confiança pode ser injustificada. Você pode ter dificuldade de julgar de forma realista suas próprias limitações e, como resultado, pode acabar sentindo que os outros não apreciam seu verdadeiro valor. Você não coloca nem muita ou nem pouca importância em seu trabalho, mas tem dúvidas sobre a melhor maneira de fazê-lo neste exato momento. Talvez algo tenha mudado sobre o caminho (por exemplo, técnicas, produtos, clientela, etc.) no qual você faz seu trabalho, causando em você esta confusão.' });
    }
    if (JSON.stringify(dinamico) === JSON.stringify(this.sinaisTendenciaInterna)) { setTendenciaInterna.push({
      padrao: 'Dinâmico',
      descricao: 'Seu senso de autoestima é muito suscetível e pode ser facilmente influenciado pelas opiniões dos outros, mas no momento você tem um senso realista das suas próprias habilidades. Você tende a ser dinâmico, enérgico e possuir boa identidade. Você subestima um dos seus principais papéis de vida neste momento, e, como resultado, pode apresentar uma falta de motivação e persistência. Isso pode torná-lo propenso a alternar de um lado para o outro entre sentimentos de satisfação e insatisfação em relação ao seu papel do dia-a-dia.' });
    }
    if (JSON.stringify(expressivo) === JSON.stringify(this.sinaisTendenciaInterna)) { setTendenciaInterna.push({
      padrao: 'Expressivo',
      descricao: 'Seu senso de autovalor é sólido e bastante preciso. Você nem subestima e nem supervaloriza as suas próprias habilidades, e define limitações apropriadas a si mesmo. No entanto, você também é muito orientado a seguir seu próprio senso de direção. Você acredita que as coisas devem ser feitas de uma certa maneira e você se esforçará para garantir que elas sejam feitas, porque isso, para você, é a chave para o sucesso. Você pode ser determinado quando se trata de aderir a estas regras. Na maior parte do tempo, você é pontual, consciente e bem organizado.' });
    }
    if (JSON.stringify(dependente) === JSON.stringify(this.sinaisTendenciaInterna)) { setTendenciaInterna.push({
      padrao: 'Dependente',
      descricao: 'Sua atitude sobre você mesmo pode ser facilmente influenciada pelas opiniões dos outros. Você não avalia supervaloriza ou nem subvaloriza seu próprio valor, mas permite que as opiniões aos outros lhe influenciem de uma maneira ou de outra. Neste momento da sua vida, você hesita em exercer muita energia em um dos seus principais papéis e está buscando a aprovação externa dos outros antes de fazer tal investimento. Você pode se distrair mais facilmente e evitar o compromisso devido a esse fato.' });
    }
    if (JSON.stringify(discriminador) === JSON.stringify(this.sinaisTendenciaInterna)) { setTendenciaInterna.push({
      padrao: 'Discriminador',
      descricao: 'Você se aceita por quem você é e tem um senso claro das suas próprias habilidades. Você é bastante confortável consigo mesmo, em geral, mas escolhe aqueles papéis que você sente que elevarão a sua autoestima. Talvez você coloque demasiadamente uma grande importância em seu trabalho e a sua capacidade de fazê-lo feliz como pessoa por dentro. Você também tem alguma dúvida sobre a melhor maneira de prosseguir ou qual direção seguir no futuro.' });
    }
    if (JSON.stringify(flexivel) === JSON.stringify(this.sinaisTendenciaInterna)) { setTendenciaInterna.push({
      padrao: 'Flexível',
      descricao: 'Você é alguém fácil de lidar e flexível. Você está confortável com você mesmo e sente que está bem no que faz, mas está incerto sobre o que deseja fazer ou ser no futuro. O futuro não está bem claro para você agora, sendo que tende a concentrar-se mais no aqui e agora como resultado. Talvez você possa ter dificuldade em cumprir os horários que definiu e estipular prazos devido a esta incerteza.' });
    }
    if (JSON.stringify(aberto) === JSON.stringify(this.sinaisTendenciaInterna)) { setTendenciaInterna.push({
      padrao: 'Aberto',
      descricao: 'Você está muito aberto a todos os pontos de vista. Você nem valoriza demais e nem desvaloriza qualquer dimensão do pensamento e, como resultado, tem uma capacidade muito boa para julgar seu próprio valor e habilidades, mantendo o seu trabalho em boa perspectiva, ou seja, não dando nem pouca e nem atenção demais a ele. Você tem clareza sobre o que quer fazer e tem um plano para como atingir isso.' });
    }

    if (setTendenciaInterna) {
      this.tendenciaInterna = setTendenciaInterna;
    }
    if (setTendenciaExterna) {
      this.tendenciaExterna = setTendenciaExterna;
    }

  }

  setConceitosMotivadores() {
    const arrayConceitosMotivadores = [];
    this.avaliado.resultadosCiencias.forEach(resultado => {
      if (resultado.ciencia === 'Motivadores') {
        resultado.resultados.forEach( data => {
          arrayConceitosMotivadores.push({
            fator: data.nomeFator,
            valor: data.valor
          });
        });
      }
    });
    const orderConceitos = arrayConceitosMotivadores.sort((a, b) => b.valor - a.valor);
    const conceito = orderConceitos.splice(0, 2);

    const EsAl = 'Uma pessoa que acredita na eliminação de doenças sociais para alcançar maior saúde social e harmonia. Ajuda os outros a encontrarem beleza, harmonia e equilíbrio em sua vida. “Paz e harmonia para todos”.';
    const EsEc = 'Alguém que tem como crença que alcançar harmonia, forma e equilíbrio oferece os retornos mais altos. O uso mais prático dos recursos é aquele que é sustentável e não prejudicial para a fonte.';
    const EsIn = 'Uma pessoa conduzida para alcançar o equilíbrio e a harmonia de sua própria e única maneira. “Eu crio minha própria definição de beleza”. Alguém que prefere ser livre para fazer coisas que trazem beleza e equilíbrio para sua vida.';
    const EsPo = 'Alguém que gosta de liderar os outros na busca de formas superiores de beleza e harmonia na vida, e que quer passar aos outros que as coisas mais finas podem melhorar a posição, o status ou a influência.';
    const EsRe = 'Alguém que valoriza a beleza, a forma e a função, mas sente que há definições ou meios tradicionais que devem ser respeitados e seguidos. Existem normas aceitas para o que é lindo: “A beleza está no olho da população”.';
    const EsTe = 'Alguém que procura compreender melhor o que é bonito e bom, e esclarecer, definir e classificar.';
    const AlEc = 'Considera que as pessoas que são bem apoiadas tornam-se pessoas mais produtivas. “Você deve dar para receber”. Há doação, mas com a expectativa de algum retorno.';
    const AlIn = 'Alguém que pensa “quando dou aos outros, sou beneficiada. Eu gosto de ajudar os outros da minha própria maneira”.';
    const AlPo = 'Acredita em liderar outros em uma causa ou missão digna. Preferem ocupar cargos em programas de ajuda social ou benefícios a comunidade.';
    const AlRe = 'Alguém que considera importante criar projetos e regulamentos que governem a ajuda social, para que a mesma seja ordenada e estruturada.';
    const AlTe = 'Uma pessoa que prefere usar o conhecimento a fim de ajudar os outros. “Ensinar os outros a pescar.”';
    const EcIn = 'Alguém que se vê como mercadoria ou marca. Ele é o seu melhor investimento. Celebridade.';
    const EcPo = 'Considera que estar a cargo assegura os melhores resultados; Levando a busca de lucros.';
    const EcRe = 'Uma pessoa que sente que os melhores resultados vêm de ter um plano e aderir a ele. Um planejamento cuidadoso e processos estruturados asseguram ótimos resultados.';
    const EcTe = 'Alguém que acredita que “o conhecimento é valioso em um sentido monetário”. Aprender e entender são os melhores investimentos que existem. Citando Ben Franklin: “O investimento em conhecimento sempre paga ao melhor interesse”.';
    const InPo = 'Estar no comando garante que se consiga fazer as coisas do seu próprio jeito. Acredita que é seu próprio chefe.';
    const InRe = 'Acredita que há uma maneira certa de fazer tudo, e seu caminho é o caminho certo. Pensa que é importante seguir as regras e regulamentos, mas tipicamente sua própria versão.';
    const InTe = '“Quanto mais eu sei, mais eu me distancio e me diferencio do resto”. Alguém que procura possuir um nível de especialização ou habilidade rara.';
    const PoRe = 'Uma pessoa que liderará outros no avanço de uma causa ou missão comum. Prefere se encarregar da conformidade para manter a ordem e criar regulamentos.';
    const PoTe = 'Acredita que o conhecimento é a fonte de poder. Vê a aquisição de conhecimento e compreensão como a chave para controlar e influenciar.';
    const ReTe = 'Procura identificar, compreender e analisar a melhor maneira de fazer as coisas para que as políticas e os sistemas corretos possam ser postos em prática.';

    const checkEsAl = (arr) => { if ('fator' in arr && arr.fator === 'Estética' || arr.fator === 'Altruísta') { return arr.fator; }};
    const trueEsAl = conceito.filter(checkEsAl);
    if (trueEsAl.length === 2) { this.conceitoMotivadores.push({ conceito: 'O Doador da Paz', descricao: EsAl }); }

    const checkEsEc = (arr) => { if ('fator' in arr && arr.fator === 'Estética' || arr.fator === 'Econômico') { return arr.fator; }};
    const trueEsEc = conceito.filter(checkEsEc);
    if (trueEsEc.length === 2) { this.conceitoMotivadores.push({ conceito: 'O Conservacionista Prático', descricao: EsEc }); }

    const checkEsIn = (arr) => { if ('fator' in arr && arr.fator === 'Estética' || arr.fator === 'Individualista') { return arr.fator; }};
    const trueEsIn = conceito.filter(checkEsIn);
    if (trueEsIn.length === 2) { this.conceitoMotivadores.push({ conceito: 'O Artista Independente', descricao: EsIn }); }

    const checkEsPo = (arr) => { if ('fator' in arr && arr.fator === 'Estética' || arr.fator === 'Político') { return arr.fator; }};
    const trueEsPo = conceito.filter(checkEsPo);
    if (trueEsPo.length === 2) { this.conceitoMotivadores.push({ conceito: 'O Líder Estética', descricao: EsPo }); }

    const checkEsRe = (arr) => { if ('fator' in arr && arr.fator === 'Estética' || arr.fator === 'Regulador') { return arr.fator; }};
    const trueEsRe = conceito.filter(checkEsRe);
    if (trueEsRe.length === 2) { this.conceitoMotivadores.push({ conceito: 'O Artista Tradicional', descricao: EsRe }); }

    const checkEsTe = (arr) => { if ('fator' in arr && arr.fator === 'Estética' || arr.fator === 'Teórico') { return arr.fator; }};
    const trueEsTe = conceito.filter(checkEsTe);
    if (trueEsTe.length === 2) { this.conceitoMotivadores.push({ conceito: 'O Especialista em Arte', descricao: EsTe }); }

    const checkAlEc = (arr) => { if ('fator' in arr && arr.fator === 'Altruísta' || arr.fator === 'Econômico') { return arr.fator; }};
    const trueAlEc = conceito.filter(checkAlEc);
    if (trueAlEc.length === 2) { this.conceitoMotivadores.push({ conceito: 'O Investidor Social', descricao: AlEc }); }

    const checkAlIn = (arr) => { if ('fator' in arr && arr.fator === 'Altruísta' || arr.fator === 'Individualista') { return arr.fator; }};
    const trueAlIn = conceito.filter(checkAlIn);
    if (trueAlIn.length === 2) { this.conceitoMotivadores.push({ conceito: 'O Líder de Causa Própria', descricao: AlIn }); }

    const checkAlPo = (arr) => { if ('fator' in arr && arr.fator === 'Altruísta' || arr.fator === 'Político') { return arr.fator; }};
    const trueAlPo = conceito.filter(checkAlPo);
    if (trueAlPo.length === 2) { this.conceitoMotivadores.push({ conceito: 'O Líder Humanitário', descricao: AlPo }); }

    const checkAlRe = (arr) => { if ('fator' in arr && arr.fator === 'Altruísta' || arr.fator === 'Regulador') { return arr.fator; }};
    const trueAlRe = conceito.filter(checkAlRe);
    if (trueAlRe.length === 2) { this.conceitoMotivadores.push({ conceito: 'O Assistente Social', descricao: AlRe }); }

    const checkAlTe = (arr) => { if ('fator' in arr && arr.fator === 'Altruísta' || arr.fator === 'Teórico') { return arr.fator; }};
    const trueAlTe = conceito.filter(checkAlTe);
    if (trueAlTe.length === 2) { this.conceitoMotivadores.push({ conceito: 'O Mentor', descricao: AlTe }); }

    const checkEcIn = (arr) => { if ('fator' in arr && arr.fator === 'Econômico' || arr.fator === 'Individualista') { return arr.fator; }};
    const trueEcIn = conceito.filter(checkEcIn);
    if (trueEcIn.length === 2) { this.conceitoMotivadores.push({ conceito: 'A estrela', descricao: EcIn }); }

    const checkEcPo = (arr) => { if ('fator' in arr && arr.fator === 'Econômico' || arr.fator === 'Político') { return arr.fator; }};
    const trueEcPo = conceito.filter(checkEcPo);
    if (trueEcPo.length === 2) { this.conceitoMotivadores.push({ conceito: 'O CEO', descricao: EcPo }); }

    const checkEcRe = (arr) => { if ('fator' in arr && arr.fator === 'Econômico' || arr.fator === 'Regulador') { return arr.fator; }};
    const trueEcRe = conceito.filter(checkEcRe);
    if (trueEcRe.length === 2) { this.conceitoMotivadores.push({ conceito: 'O Gerente', descricao: EcRe }); }

    const checkEcTe = (arr) => { if ('fator' in arr && arr.fator === 'Econômico' || arr.fator === 'Teórico') { return arr.fator; }};
    const trueEcTe = conceito.filter(checkEcTe);
    if (trueEcTe.length === 2) { this.conceitoMotivadores.push({ conceito: 'O Consultor', descricao: EcTe }); }

    const checkInPo = (arr) => { if ('fator' in arr && arr.fator === 'Individualista' || arr.fator === 'Político') { return arr.fator; }};
    const trueInPo = conceito.filter(checkInPo);
    if (trueInPo.length === 2) { this.conceitoMotivadores.push({ conceito: 'O Empreendedor', descricao: InPo }); }

    const checkInRe = (arr) => { if ('fator' in arr && arr.fator === 'Individualista' || arr.fator === 'Regulador') { return arr.fator; }};
    const trueInRe = conceito.filter(checkInRe);
    if (trueInRe.length === 2) { this.conceitoMotivadores.push({ conceito: 'O Chefe de Cozinha', descricao: InRe }); }

    const checkInTe = (arr) => { if ('fator' in arr && arr.fator === 'Individualista' || arr.fator === 'Teórico') { return arr.fator; }};
    const trueInTe = conceito.filter(checkInTe);
    if (trueInTe.length === 2) { this.conceitoMotivadores.push({ conceito: 'O Especialista', descricao: InTe }); }

    const checkPoRe = (arr) => { if ('fator' in arr && arr.fator === 'Político' || arr.fator === 'Regulador') { return arr.fator; }};
    const truePoRe = conceito.filter(checkPoRe);
    if (truePoRe.length === 2) { this.conceitoMotivadores.push({ conceito: 'O Juiz', descricao: PoRe }); }

    const checkPoTe = (arr) => { if ('fator' in arr && arr.fator === 'Político' || arr.fator === 'Teórico') { return arr.fator; }};
    const truePoTe = conceito.filter(checkPoTe);
    if (truePoTe.length === 2) { this.conceitoMotivadores.push({ conceito: 'A Autoridade', descricao: PoTe }); }

    const checkReTe = (arr) => { if ('fator' in arr && arr.fator === 'Regulador' || arr.fator === 'Teórico') { return arr.fator; }};
    const trueReTe = conceito.filter(checkReTe);
    if (trueReTe.length === 2) { this.conceitoMotivadores.push({ conceito: 'Pessoa de Garantia de Qualidade', descricao: ReTe }); }

  }

  deleteAnexo(anexo) {
    const result$ = this.alertService.showConfirm('Confirmação', 'Tem certeza que deseja excluir o ' + anexo.tipo +  ' de ' + anexo.nome + '?');
    result$.asObservable()
    .pipe(
      take(1),
      switchMap(result => result ? this.avaliacaoService.removerAnexoAvaliado(anexo.avaliacaoId, anexo.avaliadoId, anexo.id) : EMPTY)
    )
    .subscribe(
      success => {
        this.alertService.showAlertSuccess('Anexo excluído com sucesso!');
        this.clickAnexoDel = document.querySelector('#anexo_'+ anexo.id);
        this.clickAnexoDel.classList.add('d-none');
      },
      error => {
        console.error(error);
        this.alertService.showAlertDanger(error);
      }
    );
  }

  goBack(): void {
    this.location.back();
  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

  setSearchTerm(term: string) {
    this.searchText = term;
  }

  getSearchTerm() {
      return this.searchText;
  }

  clearFilter() {
    this.searchText = '';
  }

  customComparator(itemA, itemB) { // Adicionado para customizar a função 'comparator' do OrderBy
  const hasNumber = /\d/;
  const verificaTipo = hasNumber.test(itemA); // Verifica se a variável contém número
  if (verificaTipo === true) {
        return  itemA - itemB; // Corrige a situação que retorna algo assim: [1, 10, 12, 15, 5, 8, 9]
        // return itemA > itemB ? 1 : -1;
        // return itemA < itemB ? -1 : itemA > itemB ? 1 : 0;
      } else {
        return itemA.localeCompare(itemB); // Corrige a situação da palavra acentuada ficar por último na lista
      }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private goTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

}
