/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.110
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { LucorApiModelsCiencia } from './lucorApiModelsCiencia';
import { LucorApiModelsNewTipoServico } from './lucorApiModelsNewTipoServico';
import { LucorApiModelsNotaFiscal } from './lucorApiModelsNotaFiscal';


/**
 *
 */
export interface LucorApiModelsServico {
    codigo: string;
    tipoServico: LucorApiModelsNewTipoServico;
    dataOrcamento?: Date;
    dataExecucao?: Date;
    clienteId?: number;
    valorOrcado?: number;
    valorFaturado?: number;
    notasEmitidas?: Array<LucorApiModelsNotaFiscal>;
    ciencias: Array<LucorApiModelsCiencia>;
    id?: number;
    createdAt?: Date;
    updatedAt?: Date;
    unidadesContratadas?: number;
    unidadesUtilizadas?: number;
    unidadesRestantes?: number;
}
