import { Injectable } from '@angular/core';
import { LucorApiModelsDetailsUser } from 'app/model/lucorApiModelsDetailsUser';

@Injectable({ providedIn: 'root'})

export class UserContextService {

    private _user: LucorApiModelsDetailsUser;

    get user(): LucorApiModelsDetailsUser {
        return this._user;
    }

    set user(usuarioLogado: LucorApiModelsDetailsUser) {
        this._user = usuarioLogado;
    }

}
