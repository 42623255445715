import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../core/auth/guards/auth.guard';
import { SelecionadosResolver } from '../../core/resolvers/selecionados.resolver';
import { SelecionadosComponent } from './components/selecionados/selecionados.component';
import { SelecionadosDetalhesComponent } from './components/selecionados-detalhes/selecionados-detalhes.component';
import { AvaliacoesAvaliadosComponent } from '../avaliacoes/components/avaliacoes-avaliados/avaliacoes-avaliados.component';

const selecionadosRoutes: Routes = [

  { path: 'selecionados',
    canActivate: [AuthGuard],
    data: { breadcrumbs: 'Selecionados' },
    children: [
      { path: '', component: SelecionadosComponent,
        canActivate: [AuthGuard]
      },
      { path: ':IdS', canActivate: [AuthGuard],
        resolve: { selecionadosResolver: SelecionadosResolver },
        data: { breadcrumbs: '{{ selecionadosResolver.nome }}' },
        children: [
          { path: '', component: SelecionadosDetalhesComponent, canActivate: [AuthGuard] },
          { path: ':Id/avaliado/:Av', component: AvaliacoesAvaliadosComponent, canActivate: [AuthGuard],
            resolve: { selecionadosResolver: SelecionadosResolver },
            data: { breadcrumbs: 'Detalhes do Avaliado' } }
        ]

      }
    ]
  }

];


@NgModule({
  imports: [RouterModule.forChild(selecionadosRoutes)],
  exports: [RouterModule]
})
export class SelecionadosRoutingModule { }
