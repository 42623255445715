import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filterFator'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], searchText: string): any[] {
    if (!items) { return []; }
    if (!searchText) { return items; }

    searchText = searchText.toLowerCase();
    return items.filter(it => {
      if (it.palavrasChave && it.palavrasChave !== null) { // verifica se existe Palavra-chave
        return (it.palavrasChave.toLowerCase().includes(searchText) || it.nome.toLowerCase().includes(searchText)); // retorna tanto para Palavra-chave quanto para nome do Fator
      } else if (it.fator || it.fator === null) { // verifica se existe Perfil configurado
         if (it.fator === null) {
           return false;
         } else {
           it.fator = it.fator;
         }
         return it.fator.toLowerCase().includes(searchText);
      } else {
        return it.nome.toLowerCase().includes(searchText);
      }
    });
  }

}
