/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.45
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 *
 */
export interface LucorApiModelsNewTelefone {
    /**
     * Gets or Sets Numero
     */
    numero: string;
    /**
     * Gets or Sets TipoTelefone
     */
    tipoTelefone: LucorApiModelsNewTelefone.TipoTelefoneEnum;
    /**
     * Gets or Sets TipoContato
     */
    tipoContato?: string;
}

export namespace LucorApiModelsNewTelefone {
    export type TipoTelefoneEnum = 'Fixo' | 'Celular';
    export const TipoTelefoneEnum = {
        Fixo: 'Fixo' as TipoTelefoneEnum,
        Celular: 'Celular' as TipoTelefoneEnum
    };
}
