import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ClienteApiService } from 'app/api/clienteApi.service';
import { ServicoApiService } from 'app/api/servicoApi.service';
import { RegisterService } from 'app/api/register.service';
import { LucorApiModelsPessoaJuridica } from 'app/model/lucorApiModelsPessoaJuridica';
import { LucorApiModelsServico } from 'app/model/lucorApiModelsServico';
import { LucorApiModelsDetailsUser } from 'app/model/lucorApiModelsDetailsUser';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { SubSink } from 'subsink';


@Component({
  selector: 'app-detalhes-cliente',
  templateUrl: './detalhes-cliente.component.html',
  styleUrls: ['./detalhes-cliente.component.css']
})

export class DetalhesClienteComponent implements OnInit, OnDestroy {

  cliente: LucorApiModelsPessoaJuridica;
  servico: LucorApiModelsServico[];
  usuarioLogado: LucorApiModelsDetailsUser;
  idCliente: LucorApiModelsPessoaJuridica;
  alterarSenhaForm: FormGroup;
  submitted = false;

  private subs = new SubSink();

  constructor(
    private clientesService: ClienteApiService,
    private servicosService: ServicoApiService,
    private usuariosService: RegisterService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private alertService: AlertModalService,
    private location: Location
  ) {
    this.getUsuario();
    this.idCliente = this.route.snapshot.data['clienteResolver'];
  }

  ngOnInit() {
    this.getCliente();
    this.getServicosCliente();

    this.alterarSenhaForm = this.formBuilder.group({
      senhaAtual: ['', Validators.required],
      novaSenha: ['', Validators.required],
    });
  }

  get f() { return this.alterarSenhaForm.controls; }

  getUsuario() {
    this.subs.sink = this.usuariosService.getUsuarioLogadoAsync()
    .subscribe(
      usuarioLogado => {
        this.usuarioLogado = usuarioLogado;
    });
  }

  getCliente(): void {
    this.subs.sink = this.clientesService.getClienteById(this.idCliente.id)
      .subscribe(
        cliente => {
          return this.cliente = cliente;
        });
  }

  getServicosCliente(): void {
    this.subs.sink = this.servicosService.getServicoByClienteAsync(this.idCliente.id)
    .subscribe(
        servico => {
          return this.servico = servico;
      });
  }

  alterarSenha(): void {

      this.submitted = true;

      if (this.alterarSenhaForm.invalid) {
        return;
      }

      this.subs.sink = this.usuariosService.atualizarSenhaUsuarioLogadoAsync(this.alterarSenhaForm.value)
      .subscribe(success => {
        this.alertService.showAlertSuccess('Senha alterada com sucesso!');
      }, error => {
        console.log(error);
        this.alertService.showAlertDanger(error);
      });
    
  }

  resetFormSenha() {
    setTimeout(() => {
      return this.alterarSenhaForm = this.formBuilder.group({
        senhaAtual: ['', Validators.required],
        novaSenha: ['', Validators.required],
      });
    }, 1000);
    
  }

  goBack(): void {
    this.location.back();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
