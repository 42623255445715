import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-usuarios-detalhes',
  templateUrl: './usuarios-detalhes.component.html',
  styleUrls: ['./usuarios-detalhes.component.css']
})
export class UsuariosDetalhesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
