import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CargoApiService } from 'app/api/cargoApi.service';
import { LucorApiModelsAvaliacao } from 'app/model/lucorApiModelsAvaliacao';
import { LucorApiModelsNewPerfil } from 'app/model/lucorApiModelsNewPerfil';
import { AlertModalService } from 'app/shared/alert-modal.service';
import { take } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-avaliacoes-clonar',
  templateUrl: './avaliacoes-clonar.component.html',
  styleUrls: ['./avaliacoes-clonar.component.css']
})
export class AvaliacoesClonarComponent implements OnInit, OnDestroy {

  avaliadosSelect: Array<any> = new Array<any>();
  axiologiaSelect: Array<any> = new Array<any>();
  setAxiologiaGeral: Array<any> = new Array<any>();
  setComportamentoGeral: Array<any> = new Array<any>();
  setMotivadoresGeral: Array<any> = new Array<any>();
  
  selectAllFatoresChecked = false;
  idCargo: number;
  idCliente: number;

  addFormBasico: FormGroup;
  submitted = false;
  cadastrando = false;
  
  order = 'ideal';
  reverse = true;
  searchText = '';

  @Input() avaliacaoById: LucorApiModelsAvaliacao;
  @Input() avaliadosIds: Array<number>;

  @Output() mostraClonar = new EventEmitter();
  @Output() removeIdAvaliadoClonar = new EventEmitter();
  @Output() selectAllChecked = new EventEmitter();

  // Variaveis para os gráficos

  valorDomNatural: any | null;
  valorDomAdaptavel: any | null;
  valorInfNatural: any | null;
  valorInfAdaptavel: any | null;
  valorEstNatural: any | null;
  valorEstAdaptavel: any | null;
  valorCauNatural: any | null;
  valorCauAdaptavel: any | null;

  valorAltruista: any | null;
  valorEconomico: any | null;
  valorEstetica: any | null;
  valorIndividualista: any | null;
  valorPolitico: any | null;
  valorRegulador: any | null;
  valorTeorico: any | null;

  firstChartDataCom: Array<any> = new Array<any>();
  secondChartDataCom: Array<any> = new Array<any>();

  firstChartDataMot: Array<any> = new Array<any>();
  secondChartDataMot: Array<any> = new Array<any>();

  firstChartDataAxi: Array<any> = new Array<any>();
  firstChartLabelsAxi: Array<any> = new Array<any>();

   /*
  * Gráficos Bar
  */

  // Gráfico Disc
  public chartTypeDisc = 'bar';

  public chartDatasetsDisc: Array<any> = [
    { data: this.firstChartDataCom, label: 'Natural' },
    { data: this.secondChartDataCom, label: 'Adaptado' }
  ];

  public chartLabelsDisc: Array<any> = [
    'Dominância', 'Influência', 'Estabilidade', 'Cautela'
  ];

  public chartColorsDisc: Array<any> = [
    {
      borderColor: [],
      backgroundColor: [
        'rgba(244, 67, 54, 1)',
        'rgba(255, 235, 59, 1)',
        'rgba(0, 200, 83, 1)',
        'rgba(3, 169, 244, 1)',
        ],
      borderWidth: 0,
    },
    {
      borderColor: [],
      backgroundColor: [
        'rgba(244, 67, 54, 0.5)',
        'rgba(255, 235, 59, 0.5)',
        'rgba(0, 200, 83, 0.5)',
        'rgba(3, 169, 244, 0.5)'
        ],
      borderWidth: 0,
    }
  ];

  public chartOptionsDisc: any = {
    responsive: true,
    scales: {
      yAxes: [{
          ticks: {
            max: 100,
            beginAtZero: true,
            stepSize: 10
          }
      }]
    }
  };

  // Gráfico Motivadores
  public chartTypeMotivadores = 'bar';

  public chartDatasetsMotivadores: Array<any> = [
    { data: this.firstChartDataMot, label: 'Valores Motivadores' }
  ];

  public chartLabelsMotivadores: Array<any> = [
    'Altruísta', 'Econômico', 'Estética', 'Individualista', 'Político', 'Regulador', 'Teórico'
  ];

  public chartColorsMotivadores: Array<any> = [
    {
      borderColor: [],
      backgroundColor: [
        'rgba(189, 183, 107, 1)',
        'rgba(0, 250, 154, 1)',
        'rgba(128, 0, 128, 1)',
        'rgba(255, 235, 59, 1)',
        'rgba(3, 169, 244, 1)',
        'rgba(244, 67, 54, 1)',
        'rgba(244, 164, 96, 1)'
        ],
      borderWidth: 0,
    }
  ];

  public chartOptionsMotivadores: any = {
    responsive: true,
    scales: {
      yAxes: [{
          ticks: {
            max: 100,
            beginAtZero: true,
            stepSize: 10
          }
      }]
    }
  };

  /*
  * -- comum aos gráficos
  */
  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }


  private subs = new SubSink();

  constructor(
    private cargosService: CargoApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertModalService) { 
      this.addFormBasico = this.formBuilder.group({
        titulo: ['', Validators.required],
        descricao: [''],
        area: [''],
        nivel: [''],
        pessoaJuridicaId: ['']
      });
    }

  ngOnInit(): void {
    this.goTop();
    this.getAvaliacaoById();
  }

  get f() { return this.addFormBasico.controls; }

  getAvaliacaoById(): void {
         if (this.avaliacaoById) {
          this.idCliente = this.avaliacaoById.clienteId;
          this.filtroAxiologia();
          this.filtroComportamento();
          this.filtroMotivadores();
          this.getAvaliados();
          this.resetForm(); 
         }
  }

  resetForm() {
    this.idCargo = null;
    this.submitted = false;
    this.addFormBasico.setValue({
      titulo: null,
      descricao: null,
      area: null,
      nivel: null,
      pessoaJuridicaId: this.avaliacaoById.clienteId
    });
  }

  goCargoById() {
    const link: any[] = ['/cargos/' + this.idCargo];
    this.router.navigate(link);
  }

  getAvaliados() {
    this.avaliacaoById.avaliados.forEach(avaliados => {
      this.avaliadosIds.forEach(avaliadoId => {
        if (avaliadoId === avaliados.id) {
          this.avaliadosSelect.push({nome: avaliados.nome, avalidoId: avaliados.id});
        }
      });
    });
  }

  filtroAxiologia() {

      const cienciaId = 1;
      let valuesAxiologia = this.avaliacaoById;
      let avaliadosLength: number = this.avaliadosIds.length;

      this.setAxiologiaGeral = [];

      let fatorIgual: boolean;
      let sumValor: number;
      let mediaValor: number;
 
      valuesAxiologia.avaliados.forEach(avaliados => {
        this.avaliadosIds.map(avaliadoId => {
          if (avaliados.id === avaliadoId) {
            avaliados.resultadosCiencias.map(ciencias => { 
              if (ciencias.cienciaId === cienciaId) {   
                ciencias.resultados.forEach(resultados => {
      
                fatorIgual = false;
                
                this.setAxiologiaGeral.map(dataClone => {
                  if (this.setAxiologiaGeral 
                    && resultados.nomeFator === dataClone.fator
                    && resultados.nomeFator !== "Média das Competências"
                    && resultados.nomeFator !== "Interno - Consistência"
                    && resultados.nomeFator !== "Externo - Consistência"
                    && resultados.nomeFator !== "Ranking"
                    ) {
                    dataClone.valores.push( +resultados.valor );
    
                    sumValor = dataClone.valores.reduce( (accum, curr) => accum + curr ); // soma dos valores dos fatores iguais
                    mediaValor = sumValor / (avaliadosLength); // calculo da média da soma anterior
                    
                    dataClone.ideal = +mediaValor.toFixed(0);

                    if (mediaValor >= 80) {
                      dataClone.classificacao = "Alta"
                    }
                    if (mediaValor >= 60 && mediaValor < 80) {
                      dataClone.classificacao = "Mediana"
                    }
                    if (mediaValor < 60) {
                      dataClone.classificacao = "Baixa"
                    }
  
                    fatorIgual = true;
    
                  }
                });
        
                if (!fatorIgual 
                  && resultados.nomeFator !== "Média das Competências"
                  && resultados.nomeFator !== "Interno - Consistência"
                  && resultados.nomeFator !== "Externo - Consistência"
                  && resultados.nomeFator !== "Ranking") {
                  let newValue: number = +resultados.valor;
                  this.setAxiologiaGeral.push({
                    fatorId: resultados.fatorId.toString(),
                    fator: resultados.nomeFator,
                    minimo: 0,
                    ideal: +newValue.toFixed(0),
                    maximo: 0,
                    valores: [+resultados.valor],
                    classificacao: resultados.classificacao
                  });
                  
                }
              });
             }
            });
          }
        })
      });

      this.axiologiaSelect = this.setAxiologiaGeral;

  }

  filtroComportamento() {

    const cienciaId = 2;
    let valuesComportamento = this.avaliacaoById;
    let avaliadosLength: number = this.avaliadosIds.length;

    let fatorIgual: boolean;
    let sumValor: number;
    let mediaValor: number;
    
    this.setComportamentoGeral = [];

    valuesComportamento.avaliados.forEach(avaliados => {
      this.avaliadosIds.map(avaliadoId => {
        if (avaliados.id === avaliadoId) {
          avaliados.resultadosCiencias.map(ciencias => { 
            if (ciencias.cienciaId === cienciaId) {   
              ciencias.resultados.forEach(resultados => {
    
              fatorIgual = false;
              
              this.setComportamentoGeral.map(dataClone => {
                if (this.setComportamentoGeral 
                  && resultados.nomeFator === dataClone.fator) {
                  dataClone.valores.push( +resultados.valor );
  
                  sumValor = dataClone.valores.reduce( (accum, curr) => accum + curr ); // soma dos valores dos fatores iguais
                  mediaValor = sumValor / (avaliadosLength); // calculo da média da soma anterior
                  
                  dataClone.ideal = +mediaValor.toFixed(0);

                  if (mediaValor >= 50) {
                    dataClone.classificacao = "Alta"
                  }
                  if (mediaValor < 50) {
                    dataClone.classificacao = "Baixa"
                  }

                  fatorIgual = true;

                  if (resultados.nomeFator === 'DISC NATURAL - Influência') {
                    this.valorInfNatural = +mediaValor.toFixed(0);
                  }
                  if (resultados.nomeFator === 'DISC NATURAL - Cautela') {
                    this.valorCauNatural = +mediaValor.toFixed(0);
                  }
                  if (resultados.nomeFator === 'DISC NATURAL - Dominância') {
                    this.valorDomNatural = +mediaValor.toFixed(0);
                  }
                  if (resultados.nomeFator === 'DISC NATURAL - Estabilidade') {
                    this.valorEstNatural = +mediaValor.toFixed(0);
                  }
                  if (resultados.nomeFator === 'DISC ADAPTÁVEL - Influência') {
                    this.valorInfAdaptavel = +mediaValor.toFixed(0);
                  }
                  if (resultados.nomeFator === 'DISC ADAPTÁVEL - Cautela') {
                    this.valorCauAdaptavel = +mediaValor.toFixed(0);
                  }
                  if (resultados.nomeFator === 'DISC ADAPTÁVEL - Dominância') {
                    this.valorDomAdaptavel = +mediaValor.toFixed(0);
                  }
                  if (resultados.nomeFator === 'DISC ADAPTÁVEL - Estabilidade') {
                    this.valorEstAdaptavel = +mediaValor.toFixed(0);
                  }
  
                }
              });
      
              if (!fatorIgual) {
                this.setComportamentoGeral.push({
                  fatorId: resultados.fatorId.toString(),
                  fator: resultados.nomeFator,
                  minimo: 0,
                  ideal: +resultados.valor,
                  maximo: 0,
                  valores: [+resultados.valor],
                  classificacao: resultados.classificacao
                });

                if (resultados.nomeFator === 'DISC NATURAL - Influência') {
                  this.valorInfNatural = resultados.valor;
                }
                if (resultados.nomeFator === 'DISC NATURAL - Cautela') {
                  this.valorCauNatural = resultados.valor;
                }
                if (resultados.nomeFator === 'DISC NATURAL - Dominância') {
                  this.valorDomNatural = resultados.valor;
                }
                if (resultados.nomeFator === 'DISC NATURAL - Estabilidade') {
                  this.valorEstNatural = resultados.valor;
                }
                if (resultados.nomeFator === 'DISC ADAPTÁVEL - Influência') {
                  this.valorInfAdaptavel = resultados.valor;
                }
                if (resultados.nomeFator === 'DISC ADAPTÁVEL - Cautela') {
                  this.valorCauAdaptavel = resultados.valor;
                }
                if (resultados.nomeFator === 'DISC ADAPTÁVEL - Dominância') {
                  this.valorDomAdaptavel = resultados.valor;
                }
                if (resultados.nomeFator === 'DISC ADAPTÁVEL - Estabilidade') {
                  this.valorEstAdaptavel = resultados.valor;
                }
              }
            });
           }
          });
        }
      })
    });

    // Comportamento Observado
    this.firstChartDataCom.push(this.valorDomNatural);
    this.firstChartDataCom.push(this.valorInfNatural);
    this.firstChartDataCom.push(this.valorEstNatural);
    this.firstChartDataCom.push(this.valorCauNatural);
    this.firstChartDataCom.push(0);

    this.secondChartDataCom.push(this.valorDomAdaptavel);
    this.secondChartDataCom.push(this.valorInfAdaptavel);
    this.secondChartDataCom.push(this.valorEstAdaptavel);
    this.secondChartDataCom.push(this.valorCauAdaptavel);
    this.secondChartDataCom.push(0);

    this.chartDatasetsDisc = [
     { data: this.firstChartDataCom, label: 'Natural' },
     { data: this.secondChartDataCom, label: 'Adaptado' },
    ];

  }

  filtroMotivadores() {

    const cienciaId = 3;
    let valuesMotivadores = this.avaliacaoById;
    let avaliadosLength: number = this.avaliadosIds.length;

    this.setMotivadoresGeral = [];

    let fatorIgual: boolean;
    let sumValor: number;
    let mediaValor: number;

    valuesMotivadores.avaliados.forEach(avaliados => {
      this.avaliadosIds.map(avaliadoId => {
        if (avaliados.id === avaliadoId) {
          avaliados.resultadosCiencias.map(ciencias => { 
            if (ciencias.cienciaId === cienciaId) {   
              ciencias.resultados.forEach(resultados => {
    
              fatorIgual = false;
              
              this.setMotivadoresGeral.map(dataClone => {
                if (this.setMotivadoresGeral 
                  && resultados.nomeFator === dataClone.fator) {
                  dataClone.valores.push( +resultados.valor );
  
                  sumValor = dataClone.valores.reduce( (accum, curr) => accum + curr ); // soma dos valores dos fatores iguais
                  mediaValor = sumValor / (avaliadosLength); // calculo da média da soma anterior
                  
                  dataClone.ideal = +mediaValor.toFixed(0);

                  if (mediaValor >= 80) {
                    dataClone.classificacao = "Muito Alta"
                  }
                  if (mediaValor >= 60 && mediaValor < 80) {
                    dataClone.classificacao = "Alta"
                  }
                  if (mediaValor >= 40 && mediaValor < 60) {
                    dataClone.classificacao = "Mediana"
                  }
                  if (mediaValor >= 20 && mediaValor < 40) {
                    dataClone.classificacao = "Baixa"
                  }
                  if (mediaValor < 20) {
                    dataClone.classificacao = "Muito Baixa"
                  }

                  fatorIgual = true;

                  if (resultados.nomeFator === 'Altruísta') {
                    this.valorAltruista = mediaValor.toFixed(0);
                  }
                  if (resultados.nomeFator === 'Econômico') {
                    this.valorEconomico = mediaValor.toFixed(0);
                  }
                  if (resultados.nomeFator === 'Estética') {
                    this.valorEstetica = mediaValor.toFixed(0);
                  }
                  if (resultados.nomeFator === 'Individualista') {
                    this.valorIndividualista = mediaValor.toFixed(0);
                  }
                  if (resultados.nomeFator === 'Político') {
                    this.valorPolitico = mediaValor.toFixed(0);
                  }
                  if (resultados.nomeFator === 'Regulador') {
                    this.valorRegulador = mediaValor.toFixed(0);
                  }
                  if (resultados.nomeFator === 'Teórico') {
                    this.valorTeorico = mediaValor.toFixed(0);
                  }
                  
  
                }
              });
      
              if (!fatorIgual) {
                this.setMotivadoresGeral.push({
                  fatorId: resultados.fatorId.toString(),
                  fator: resultados.nomeFator,
                  minimo: 0,
                  ideal: +resultados.valor,
                  maximo: 0,
                  valores: [+resultados.valor],
                  classificacao: resultados.classificacao
                });

                if (resultados.nomeFator === 'Altruísta') {
                  this.valorAltruista = resultados.valor;
                }
                if (resultados.nomeFator === 'Econômico') {
                  this.valorEconomico = resultados.valor;
                }
                if (resultados.nomeFator === 'Estética') {
                  this.valorEstetica = resultados.valor;
                }
                if (resultados.nomeFator === 'Individualista') {
                  this.valorIndividualista = resultados.valor;
                }
                if (resultados.nomeFator === 'Político') {
                  this.valorPolitico = resultados.valor;
                }
                if (resultados.nomeFator === 'Regulador') {
                  this.valorRegulador = resultados.valor;
                }
                if (resultados.nomeFator === 'Teórico') {
                  this.valorTeorico = resultados.valor;
                }
              }
            });
           }
          });
        }
      })
    });

    // Motivadores
    this.firstChartDataMot.push(this.valorAltruista);
    this.firstChartDataMot.push(this.valorEconomico);
    this.firstChartDataMot.push(this.valorEstetica);
    this.firstChartDataMot.push(this.valorIndividualista);
    this.firstChartDataMot.push(this.valorPolitico);
    this.firstChartDataMot.push(this.valorRegulador);
    this.firstChartDataMot.push(this.valorTeorico);
    this.firstChartDataMot.push(0);

    this.chartDatasetsMotivadores = [
      { data: this.firstChartDataMot, label: 'Valores Motivadores' }
    ];

  }

  saveCargo(): void {

    this.submitted = true;
    this.cadastrando = true;
    if (this.addFormBasico.invalid) { return; }

    this.cargosService.addCargoAsync(this.addFormBasico.value)
    .pipe(take(1))
    .subscribe(success => {
      this.idCargo = success.id;
      setTimeout(() => {
        this.savePerfil();
      }, 500);
    }, error => {
      this.cadastrando = false;
      this.alertService.showAlertDanger('Erro ao cadastrar Cargo. Por favor, verifique sua conexão ou tente mais tarde.');
    });


  }

  savePerfil() {
    this.setAxiologiaGeral.map(setResultado => {
      delete setResultado.valores;
      delete setResultado.classificacao;
    });
    this.setAxiologiaGeral.push({ fator: null, minimo: 60, ideal: 80, maximo: 100 });

    this.setMotivadoresGeral.map(setResultado => {
      delete setResultado.valores;
      delete setResultado.classificacao;
    });
    this.setMotivadoresGeral.push({fator: null, minimo: 60, ideal: 80, maximo: 100});

    this.setComportamentoGeral.map(setResultado => {
      delete setResultado.valores;
      delete setResultado.classificacao;
    });
    this.setComportamentoGeral.push({fator: null, minimo: 60, ideal: 80, maximo: 100});
    
    this.enviaFiltro(this.setAxiologiaGeral, this.setComportamentoGeral, this.setMotivadoresGeral);

  }

  enviaFiltro(
    filtroAxiologia: Array<LucorApiModelsNewPerfil>,
    filtroComportamento: Array<LucorApiModelsNewPerfil>,
    filtroMotivadores: Array<LucorApiModelsNewPerfil>) {

        let erroAxio = false;
        let erroComp = false;
        let erroMoti = false;

          if (filtroAxiologia !== undefined) {
            filtroAxiologia;
            const idCiencia = 1;
            this.cargosService.updatePerfisCargo(this.idCargo, idCiencia, filtroAxiologia, 'response')
            .pipe(take(1))
              .subscribe(
                success => {
                }, error => {
                  console.log('Erro Perfil Axiologia', error);
                  erroAxio = true;
                });
          }
  
          if (filtroComportamento !== undefined) {
            const idCiencia = 2;
            this.cargosService.updatePerfisCargo(this.idCargo, idCiencia, filtroComportamento, 'response')
            .pipe(take(1))
              .subscribe(
                success => {
                }, error => {
                  console.log('Erro Perfil Comportamento', error);
                  erroComp = true;
                });
          }
  
          if (filtroMotivadores !== undefined) {
            const idCiencia = 3;
            this.cargosService.updatePerfisCargo(this.idCargo, idCiencia, filtroMotivadores, 'response')
            .pipe(take(1))
              .subscribe(
                success => {
                }, error => {
                  console.log('Erro Perfil Motivadores', error);
                  erroMoti = true;
                });
          }

          if (erroAxio || erroComp || erroMoti) {
            this.cadastrando = false;
            this.alertService.showAlertDanger('Erro ao cadastrar Perfis do Cargo. Por favor, tente mais tarde.');
          } else {
            this.cadastrando = false;
            this.alertService.showAlertSuccess('Cargo cadastrado com sucesso!');
          }


  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

  setSearchTerm(term: string) {
    this.searchText = term;
  }

  getSearchTerm() {
      return this.searchText;
  }

  clearFilter() {
    this.searchText = '';
  }

  customComparator(itemA, itemB) { // Adicionado para customizar a função 'comparator' do OrderBy
  const hasNumber = /\d/;
  const verificaTipo = hasNumber.test(itemA); // Verifica se a variável contém número
  if (verificaTipo === true) {
        return  itemA - itemB; // Corrige a situação que retorna algo assim: [1, 10, 12, 15, 5, 8, 9]
      } else {
       return itemA.localeCompare(itemB); // Corrige a situação da palavra acentuada ficar por último na lista
      }
  }

  goBack(): void {
    this.mostraClonar.emit(false);
    this.selectAllChecked.emit();
  }
  
  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private goTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

}
